import styled from '@emotion/styled';
import { Button, Typography, Box, TableCell } from '@mui/material';

export const ButtonWrapper = styled(Button)({
  marginTop: '16px',
  whiteSpace: 'nowrap',
  '.MuiButton-root': {
    '&.Mui-disabled': {
      opacity: '.6',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
});

export const LabelName = styled(Typography)({
  position: 'absolute',
  padding: '0 4px',
  top: '-10px',
  left: '10px',
  background: 'white',
  fontSize: '13px',
  color: '#757d85',
  zIndex: '1',
});

export const SupportLabel = styled(Typography)({
  position: 'absolute',
  top: '10px',
  left: '30px',
  background: 'white',
  fontSize: '13px',
  color: '#757d85',
  zIndex: '1',
});

export const ButtonsWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export const ReportTypeTitle = styled(Box)({
  fontWeight: 600,
  fontSize: '18px',
});

export const CustomTableCell = styled(TableCell)(({ theme, isLastColumn }) => ({
  fontWeight: 700,
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  borderRight: isLastColumn ? 'none' : `1px solid ${theme.palette.grey[300]}`,
  borderColor: (theme) => theme.palette.grey[300],
}));

export const CustomTableCellWithBorder = styled(CustomTableCell)(({ theme, isLastColumn }) => ({
  borderRight: !isLastColumn ? 'none' : `1px solid ${theme.palette.grey[300]}`,
  fontWeight: 500,
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  borderColor: theme.palette.grey[300],
}));