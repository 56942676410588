import { useState } from 'react';

export function useAutoConfirmModal() {
  const [openAutoConfirmModal, setOpenAutoConfirmModal] = useState(false);
  const handleCloseAutoConfirmModal = () => setOpenAutoConfirmModal(false);
  const handleOpenAutoConfirmModal = () => setOpenAutoConfirmModal(true);
  return {
    handleCloseAutoConfirmModal,
    handleOpenAutoConfirmModal,
    openAutoConfirmModal,
  };
}
