import ErrorIcon from 'assets/images/accountError.png';
import MiddleIcon from 'assets/images/accountMiddle.png';
import SuccessIcon from 'assets/images/accountSuccess.png';

export const mode = {
  EDIT: 'edit',
  UPDATE: 'update',
};

export const InputMode = {
  standard: 'standard',
  outlined: 'outlined',
  standard_Basic: 'standard-basic',
  outlined_Basic: 'outlined_Basic',
};

export const VARIABLES = {
  HEIGHT_DEFAULT: '54px',
  HEIGHT_DISABLED_MODE: '40px',
  PADDING_SMALL: '8px',
  PADDING_ZERO: '0px',
  PADDING_MIDDLE: '16px',
  PADDING_LARGE: '24px',
  MARGIN_LARGE: '24px',
  MARGIN_MIDDLE: '16px',
  MARGIN_ZERO: '0px',
  MARGIN_SMALL: '8px',
  MARGIN_MINUS_SMALL: '-8px',
  WIDTH: '100%',
  MAX_WIDTH: '606px',
  MAX_WIDTH_SMALL: '295px',
  MAX_WIDTH_MIDDLE: '300px',
  WEBKIT_COLOR: 'rgba(0, 0, 0, 0.87)',
  LABEL_COLOR: 'rgba(0, 0, 0, 0.38)',
};

export const DisabledStylesForSocial = {
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
    paddingTop: '8px',
  },
  '& .MuiOutlinedInput-root': {
    WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
  },
  width: '100%',
  maxWidth: '606px',
  '& .MuiInputLabel-root': {
    marginLeft: '24px',
    marginTop: '3px',
  },
  '& .MuiInputAdornment-root': {
    marginTop: '-50px',
  },
  '& .MuiInputBase-input': {
    marginLeft: '-26px',
  },
};

export const SwitcherActiveStyles = {
  '& .MuiSwitch-switchBase': {
    '&:not(.Mui-checked)': {
      '& + .MuiSwitch-track': {
        opacity: 0.5,
        backgroundColor: '#FF8A00',
      },
    },
    '&.Mui-checked + .MuiSwitch-track': {
      opacity: 0.5,
      backgroundColor: '#FF8A00',
    },
  },
  '& .MuiSwitch-thumb': {
    color: '#FF8A00',
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#FF8A00',
  },
};

export const SwitcherInActiveStyles = {
  '& .MuiSwitch-switchBase': {
    '&:not(.Mui-checked)': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#929398',
      },
    },
    '&.Mui-checked + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#929398',
    },
  },
  '& .MuiSwitch-thumb': {
    color: '#FAFAFA',
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#929398',
  },
};

export const StylesActiveMode = {
  width: VARIABLES.WIDTH,
  maxWidth: VARIABLES.MAX_WIDTH,
  '& .MuiOutlinedInput-root': {
    WebkitTextFillColor: VARIABLES.WEBKIT_COLOR,
    marginBottom: VARIABLES.MARGIN_LARGE,
  },
};

export const Styles = {
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: VARIABLES.WEBKIT_COLOR,
    paddingTop: VARIABLES.PADDING_SMALL,
    paddingBottom: VARIABLES.PADDING_MIDDLE,
  },
  '& .MuiOutlinedInput-root': {
    WebkitTextFillColor: VARIABLES.WEBKIT_COLOR,
  },
  width: VARIABLES.WIDTH,
  maxWidth: VARIABLES.MAX_WIDTH,
  height: VARIABLES.HEIGHT_DEFAULT,
};

export const StylesSmall = {
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: VARIABLES.WEBKIT_COLOR,
    paddingTop: VARIABLES.PADDING_SMALL,
    paddingBottom: VARIABLES.PADDING_MIDDLE,
  },
  '& .MuiOutlinedInput-root': {
    WebkitTextFillColor: VARIABLES.WEBKIT_COLOR,
  },
  width: VARIABLES.WIDTH,
  maxWidth: VARIABLES.MAX_WIDTH_SMALL,
  height: VARIABLES.HEIGHT_DEFAULT,
};

export const accountConfig = {
  ACCOUNT_INFO: {
    title: 'Account Information',
    hideEditButton: false,
    mode: mode.UPDATE,
    key: 'ACCOUNT_INFO',
    expandedPanel: true,
  },
  LOGIN_INFO: {
    title: 'Login Info',
    hideEditButton: true,
    mode: mode.UPDATE,
    key: 'LOGIN_INFO',
    expandedPanel: true,
  },
};

export const businessConfig = {
  QR: { title: 'QR Code', hideEditButton: true, mode: mode.EDIT, key: 'QR', expandedPanel: true },
  LOCATION_INFO: {
    title: 'Business Location Information',
    hideEditButton: true,
    mode: mode.UPDATE,
    key: 'LOCATION_INFO',
    expandedPanel: true,
  },
  COMM_EMAIL: {
    title: 'Communication Information',
    hideEditButton: false,
    mode: mode.UPDATE,
    key: 'COMM_EMAIL',
    expandedPanel: true,
  },
  BUSINESS_DETAIL: {
    title: 'Business Details',
    hideEditButton: false,
    mode: mode.UPDATE,
    key: 'BUSINESS_DETAIL',
    expandedPanel: true,
  },
  SOCIAL_MEDIA: {
    title: 'Links',
    hideEditButton: false,
    mode: mode.UPDATE,
    key: 'SOCIAL_MEDIA',
    expandedPanel: true,
  },
  BUSINESS_TAX: {
    title: 'Tax',
    hideEditButton: false,
    mode: mode.UPDATE,
    key: 'BUSINESS_TAX',
    expandedPanel: true,
  },
  BUSINESS_PREPARATION: {
    title: 'Preparation Time',
    hideEditButton: false,
    mode: mode.UPDATE,
    key: 'BUSINESS_PREPARATION',
    expandedPanel: true,
  },
  BUSINESS_PINE_CODE: {
    title: 'Tablet PIN Code',
    hideEditButton: true,
    mode: mode.EDIT,
    key: 'BUSINESS_PINE_CODE',
    expandedPanel: true,
  },
  WORKING_HOURS: {
    title: 'settings.working_hours',
    hideEditButton: false,
    mode: mode.UPDATE,
    key: 'WORKING_HOURS',
    expandedPanel: true,
  },
  SPECIAL_HOURS: {
    title: 'settings.special_hours',
    hideEditButton: false,
    mode: mode.UPDATE,
    key: 'SPECIAL_HOURS',
    expandedPanel: true,
  },
  GOOGLE_BUSINESS_SETTINGS: {
    title: 'Google Business Settings',
    hideEditButton: true,
    mode: mode.UPDATE,
    key: 'GOOGLE_BUSINESS_SETTINGS',
    expandedPanel: true,
  },
};

export const validationKeys = {
  MIN: 'min',
  INCLUDE_KEY: 'includeValidation',
  MATCH_KEY: 'matchValidation',
  ONLY_LETTERS: 'letters',
  ONLY_SYMBOLS: 'symbols',
  ONLY_LETTERS_LOWERCASE: 'letters_lower',
};

export const checkMarkValidationConfig = [
  {
    key: validationKeys.MIN,
    error: false,
    success: false,
    middle: true,
    message: 'At least 8 characters long',
    errorIcon: ErrorIcon,
    successIcon: SuccessIcon,
    middleIcon: MiddleIcon,
  },
  {
    key: validationKeys.ONLY_LETTERS_LOWERCASE,
    error: false,
    success: false,
    middle: true,
    message: 'Include at least one lowercase letter (a-z)',
    errorIcon: ErrorIcon,
    successIcon: SuccessIcon,
    middleIcon: MiddleIcon,
  },
  {
    key: validationKeys.ONLY_LETTERS,
    error: false,
    success: false,
    middle: true,
    message: 'Include at least one uppercase letter (A-Z)',
    errorIcon: ErrorIcon,
    successIcon: SuccessIcon,
    middleIcon: MiddleIcon,
  },
  {
    key: validationKeys.ONLY_SYMBOLS,
    error: false,
    success: false,
    middle: true,
    message: 'Include at least one symbol (!, @, #, etc.)',
    errorIcon: ErrorIcon,
    successIcon: SuccessIcon,
    middleIcon: MiddleIcon,
  },
  {
    key: validationKeys.INCLUDE_KEY,
    error: false,
    success: false,
    middle: true,
    message: 'Includes number and special character (+,3)',
    errorIcon: ErrorIcon,
    successIcon: SuccessIcon,
    middleIcon: MiddleIcon,
  },
  {
    key: validationKeys.MATCH_KEY,
    error: false,
    success: false,
    middle: true,
    message: 'Passwords must match',
    errorIcon: ErrorIcon,
    successIcon: SuccessIcon,
    middleIcon: MiddleIcon,
  },
];
