import moment from 'moment/moment';

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export function calculateTotalSales(salesData) {
  let totalSales = 0;
  salesData?.forEach((item) => {
    // Remove the dollar sign and commas, then convert to float
    const salesValue = parseFloat(item?.total_sales.replace(/[$,]/g, ''));
    totalSales += salesValue;
  });
  return totalSales;
}

export function calculateTotalOrders(ordersData) {
  let totalOrders = 0;
  ordersData.forEach((item) => {
    totalOrders += item.total_orders;
  });
  return totalOrders;
}

export function getPreviousPeriod(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in days between the start and end dates
  const diffInDays = (end - start) / (1000 * 60 * 60 * 24);

  // Calculate the previous period end date
  const prevEndDate = new Date(start);
  prevEndDate.setDate(start.getDate() - 1);

  // Calculate the previous period start date
  const prevStartDate = new Date(prevEndDate);
  prevStartDate.setDate(prevEndDate.getDate() - diffInDays);

  // Format the dates as YYYY-MM-DD
  const formatDate = (date) => date.toISOString().split('T')[0];

  return {
    prevStartDate: formatDate(prevStartDate),
    prevEndDate: formatDate(prevEndDate),
  };
}

function removeUndefined(arr) {
  return arr.filter((item) => item !== 'undefined');
}
export function prepareChartDataForOrdersAndSales(dataGroups) {
  // Prepare the labels from the hour fields.
  const labels = dataGroups[0].data.map((item) =>
    item?.hour ? `${item?.hour}:00` : `${item?.date}` ? `${item?.date}` : ''
  );
  // Prepare data groups for orders and sales separately.
  const ordersData = {
    labels: removeUndefined(labels),
    datasets: [],
  };
  const salesData = {
    labels: removeUndefined(labels),
    datasets: [],
  };

  dataGroups.forEach((group) => {
    const orderDataset = {
      label: `${group.groupName} - Orders`,
      data: group.data.map((item) => item.total_orders),
      borderColor: group.groupName.includes('Previous') ? '#A9A9A9' : '#FF8A00',
      backgroundColor: group.groupName.includes('Previous') ? '#A9A9A9' : '#FF8A00',
      yAxisID: 'y-axis-orders',
      type: 'line',
    };
    ordersData.datasets.push(orderDataset);

    // Sales data extraction and dataset creation
    const salesDataset = {
      label: `${group.groupName} - Sales`,
      data: group.data.map((item) => parseFloat(item.total_sales.replace('$', '').replace(',', ''))),
      borderColor: group.groupName.includes('Previous') ? '#A9A9A9' : '#FF8A00',
      backgroundColor: group.groupName.includes('Previous') ? '#A9A9A9' : '#FF8A00',
      yAxisID: 'y-axis-sales',
      type: 'line',
    };
    salesData.datasets.push(salesDataset);
  });

  // Return both orders and sales data groups separately.
  return {
    ordersData: ordersData,
    salesData: salesData,
  };
}
export const QUERY_TYPE = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_7_DAYS: 'Last 7 days',
  LAST_30_DAYS: 'Last 30 days',
};
export const processSelectFilter = (key, timeZoneId, otherFilter) => {
  switch (key) {
    case QUERY_TYPE.TODAY:
      return {
        start_date: moment.tz(timeZoneId || DEFAULT_TIMEZONE).format('YYYY-MM-DD'),
        end_date: moment.tz(timeZoneId || DEFAULT_TIMEZONE).format('YYYY-MM-DD'),
        ...otherFilter,
      };
    case QUERY_TYPE.YESTERDAY:
      return {
        start_date: moment
          .tz(timeZoneId || DEFAULT_TIMEZONE)
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        end_date: moment
          .tz(timeZoneId || DEFAULT_TIMEZONE)
          .subtract(1, 'days')
          .format('YYYY-MM-DD'),
        ...otherFilter,
      };
    case QUERY_TYPE.LAST_7_DAYS:
      return {
        start_date: moment
          .tz(timeZoneId || DEFAULT_TIMEZONE)
          .subtract(6, 'days')
          .format('YYYY-MM-DD'),
        end_date: moment.tz(timeZoneId || DEFAULT_TIMEZONE).format('YYYY-MM-DD'),
        ...otherFilter,
      };
    case QUERY_TYPE.LAST_30_DAYS:
      return {
        start_date: moment
          .tz(timeZoneId || DEFAULT_TIMEZONE)
          .subtract(29, 'days')
          .format('YYYY-MM-DD'),
        end_date: moment.tz(timeZoneId || DEFAULT_TIMEZONE).format('YYYY-MM-DD'),
        ...otherFilter,
      };
    default:
      return null;
  }
};
