import instance from 'services/api';
import { toast } from 'react-toastify';
import {
  ADD_SECTION_TO,
  DELETE_SECTION_TO,
  GET_SECTION_TO,
  LAST_CREATED_SECTION_TO,
  SET_LOADING_TO,
  UPDATE_SECTION_TO,
} from '../types';
import { getTableOrderingTO } from './tables';

export const createSectionTO = (body) => (dispatch, getState) => {
  const sections = getState().tableOrdering.sections;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .post(`/table-ordering/sections`, body)
    .then((response) => {
      const data = response?.data?.data?.data;
      const lastCreatedSectionId = response?.data?.data?.data?._id || null;
      const filterSections = sections.filter((section) => section._id !== data._id);
      filterSections.push(data);
      dispatch({ type: ADD_SECTION_TO, payload: filterSections?.reverse() });
      dispatch({ type: LAST_CREATED_SECTION_TO, payload: lastCreatedSectionId });
      toast.success('Create section has been successfully!');
      dispatch({ type: SET_LOADING_TO, payload: false });
      dispatch(getTableOrderingTO(body.businessId));
      dispatch(getTableOrderingSectionTO(body.businessId));
      return filterSections;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const getTableOrderingSectionTO = () => (dispatch, getState) => {
  const lastCreatedSectionId = getState().tableOrdering.lastCreatedSection;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .get(`/table-ordering/sections`)
    .then((response) => {
      const data = response?.data?.data?.data;
      if (lastCreatedSectionId) {
        const index = data.findIndex((table) => table._id === lastCreatedSectionId);
        if (index !== -1) {
          const [lastCreatedSection] = data.splice(index, 1);
          data.unshift(lastCreatedSection);
        }
      }
      dispatch({ type: GET_SECTION_TO, payload: data });
      dispatch({ type: SET_LOADING_TO, payload: false });
      return data;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const updateSectionTO = (dataReq) => (dispatch, getState) => {
  dispatch({ type: SET_LOADING_TO, payload: true });
  const sections = getState().tableOrdering.sections;
  return instance
    .patch(`/table-ordering/sections//${dataReq.sectionId}`, dataReq.body)
    .then((response) => {
      const data = response?.data?.data?.data;
      const findIndex = sections.findIndex((section) => {
        return section._id === dataReq.sectionId;
      });
      if (findIndex !== -1) {
        sections[findIndex] = data;
      }
      dispatch({ type: UPDATE_SECTION_TO, payload: sections });
      toast.success('Update section has been successfully!');
      dispatch({ type: SET_LOADING_TO, payload: false });
      return data;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const deleteSectionTO = (sectionId) => (dispatch, getState) => {
  const sections = getState().tableOrdering.sections;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .delete(`/table-ordering/sections/${sectionId}`)
    .then(() => {
      const filterTabled = sections.filter((section) => section._id !== sectionId);
      dispatch({ type: DELETE_SECTION_TO, payload: filterTabled });
      toast.success('Delete section has been successfully!');
      dispatch({ type: SET_LOADING_TO, payload: false });
      return filterTabled;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};
