import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Paper } from '@mui/material';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import StyledDataGrid from 'components/DataGridTable';
import { SET_CHARGE_SELECTED } from 'components/Chargeback/constants';
import { getChargeBackById } from 'redux/actions/chargeBack';

const TableList = ({ name, columns, rows }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { chargeBack } = useSelector((store) => store);
  const [pageSize, setPageSize] = useState(5);

  const handleRowClick = useCallback(
    ({ row }) => {
      if (chargeBack.selected?._id === row.id) {
        dispatch({ type: SET_CHARGE_SELECTED, payload: null });
      } else {
        dispatch(getChargeBackById(row.id));
      }
    },
    [chargeBack.selected?._id, dispatch]
  );

  if (!chargeBack.loading && !chargeBack.states[name.toLowerCase()]?.length) {
    <Box width="100%">
      <Box sx={{ mt: '100px' }}>
        <EmptyMenu text={t('chargeBack.there_are_chargeback_yet')} />
      </Box>
    </Box>;
  }

  return (
    <Box width="100%">
      <Paper>
        <Box
          sx={{
            height: 'calc(100vh - 136px)',
            width: '100%',
          }}
        >
          <StyledDataGrid
            hideFooterPagination={false}
            hideFooterSelectedRowCount={false}
            isEditable={false}
            disableSelectionOnClick={true}
            disableVirtualization={true}
            rows={chargeBack.loading ? [] : rows}
            loading={chargeBack.loading}
            columns={chargeBack.loading ? [] : columns}
            rowHeight={72}
            pageSize={pageSize}
            onRowClick={handleRowClick}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 15, 50, 100]}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default TableList;
