import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Private from 'components/Private';
import { useTranslation } from 'react-i18next';

const FormFooter = ({ handleCancel, isDisable, isInOverview, isInItemMotAdd, mode }) => {
  const { t } = useTranslation();
  const disabled = isInItemMotAdd ? !isInItemMotAdd : mode === 'edit' && !isInOverview ? !isDisable : false;
  return (
    <Box
      sx={{
        py: 3,
        px: 4,
        borderTop: 1,
        borderColor: 'divider',
      }}
    >
      <Private permission="MENU_EDIT">
        <Button id="formFooterSave" disabled={disabled} type="submit" variant="contained" sx={{ mr: 1 }}>
          {isInOverview || isInItemMotAdd
            ? t('menu.save_continue')
            : mode === 'edit'
            ? t('website_settings.update')
            : t('settings.save')}
        </Button>
      </Private>
      <Button id="formFooterBack" sx={{ color: '#333333' }} onClick={handleCancel}>
        {t('orders.cancel')}
      </Button>
    </Box>
  );
};

export default FormFooter;
