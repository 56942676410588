// import axios from 'axios';
export const osoPatchUrl = '';
export const saveFormDataWindowLayer = (formData) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(formData);
  ocoPost(formData);
};

export const ocoPost = (paramsData) => {
  console.debug(">>>> ~ ocoPost ~ paramsData:", paramsData);
  //   var bodyFormData = new FormData();
  //   for (const property in paramsData) {
  //     bodyFormData.append(property, paramsData[property]);
  //   }
  //   const urlJson = 'https://webhook.site/oco-main-website-hooks';
  //   const urlForm = 'https://webhook.site/oco-main-website-hooks-form';
  //   axios.post(urlJson, JSON.stringify(paramsData));
  //   axios.post(urlForm, bodyFormData);
};

export const ocoPatch = (paramsData, oco_profile_id = '') => {
  console.debug(">>>> ~ ocoPatch ~ paramsData:", paramsData);
  if (!oco_profile_id) {
    // const dataFromStorage = localStorage.getItem('ocoProfileId');
    // if (dataFromStorage) {
    //   ocoProfileId = JSON.parse(dataFromStorage || '');
    // }
    // }
    // // var bodyFormData = new FormData();
    // // for (const property in paramsData) {
    // //   bodyFormData.append(property, paramsData[property]);
    // // }
    // const urlJson = `${osoPatchUrl}/${ocoProfileId}`;
    // // const urlForm = 'https://webhook.site/oco-main-website-hooks-form';
    // axios.post(urlJson, JSON.stringify(paramsData));
    // // axios.post(urlForm, bodyFormData);
  }
}
