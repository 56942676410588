// import { USER_LOADING, USER_LOADED, USER_LOGOUT } from '../../constants/user';
// import { SET_CURRENT_BUSINESSES, SET_CURRENT_BUSINESSES_DISABLE, SET_CURRENT_BUSINESSES_ENABLE } from './types';
// import { getMenusV2 } from './menuV2';
// import { getMenusV2 } from './Menu/menuV2';
import instance from '../../services/api';
import { saveFormDataWindowLayer, ocoPatch } from 'helpers/googleTagManager';

export const signupUserV2 = (body) => {
  try {
    if (body) {
      const saveFormDataWindowLayerModel = {
        event: 'signupSubmit',
        firstName: body.firstName || '',
        lastName: body.lastName || '',
        email: body.email || '',
        phone: body.phone || '',
        businessPhone: body.phone || '',
      };
      ocoPatch(saveFormDataWindowLayerModel);
      saveFormDataWindowLayer(saveFormDataWindowLayerModel);
    }
    return instance.post('/auth-v2/signup/user', body);
  } catch (e) {
    throw new Error(e);
  }
};

export const signupUserV2External = (body) => {
  try {
    if (body) {
      const saveFormDataWindowLayerModel = {
        event: 'signupSubmit',
        firstName: body.firstName || '',
        lastName: body.lastName || '',
        email: body.email || '',
        phone: body.phone || '',
        businessPhone: body.phone || '',
      };
      ocoPatch(saveFormDataWindowLayerModel);
      saveFormDataWindowLayer(saveFormDataWindowLayerModel);
    }
    return instance.post('/auth-v2/signup/external-user', body);
  } catch (e) {
    throw new Error(e);
  }
};

export const updateUserV2External = (body, userId) => {
  try {
    if (body && userId) {
      const saveFormDataWindowLayerModel = {
        event: 'signupSubmit',
        firstName: body.firstName || '',
        lastName: body.lastName || '',
        email: body.email || '',
        phone: body.phone || '',
        restaurantName: body.companyName || '',
        businessPhone: body.phone || '',
      };
      ocoPatch(saveFormDataWindowLayerModel);
      saveFormDataWindowLayer(saveFormDataWindowLayerModel);
    }
    return instance.patch(`/auth-v2/signup/update/external-user/${userId}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

export const updateUserV2 = (body, userId) => {
  try {
    if (body && userId) {
      const saveFormDataWindowLayerModel = {
        event: 'signupSubmit',
        firstName: body.firstName || '',
        lastName: body.lastName || '',
        email: body.email || '',
        phone: body.phone || '',
        restaurantName: body.companyName || '',
        businessPhone: body.phone || '',
      };
      ocoPatch(saveFormDataWindowLayerModel);
      saveFormDataWindowLayer(saveFormDataWindowLayerModel);
    }
    return instance.patch(`/auth-v2/signup/update/user/${userId}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

export const signupLegalInfoV2 = (body) => {
  try {
    const acountInfo = localStorage.getItem('acountInfo');
    if (acountInfo) {
      const parsedData = JSON.parse(acountInfo);
      if (parsedData && parsedData.userId) {
        body.userId = parsedData.userId;
      }
    }
    return instance.post('/auth-v2/signup/legal-info', body);
  } catch (e) {
    throw new Error(e);
  }
};

export const signupLegalInfoV2External = (body) => {
  try {
    const acountInfo = localStorage.getItem('acountInfo');
    if (acountInfo) {
      const parsedData = JSON.parse(acountInfo);
      if (parsedData && parsedData.userId) {
        body.userId = parsedData.userId;
      }
    }
    return instance.post('/auth-v2/signup/external-legal-info', body);
  } catch (e) {
    throw new Error(e);
  }
};

export const updateLegalInfoV2 = (body) => {
  try {
    const acountInfo = localStorage.getItem('acountInfo');
    if (acountInfo) {
      const parsedData = JSON.parse(acountInfo);
      if (parsedData && parsedData.userId) {
        body.userId = parsedData.userId;
      }
    }
    return instance.patch('/auth-v2/signup/update/legal-info', body);
  } catch (e) {
    throw new Error(e);
  }
};

export const updateLegalInfoV2External = (body) => {
  try {
    const acountInfo = localStorage.getItem('acountInfo');
    if (acountInfo) {
      const parsedData = JSON.parse(acountInfo);
      if (parsedData && parsedData.userId) {
        body.userId = parsedData.userId;
      }
    }
    return instance.patch('/auth-v2/signup/update/external-legal-info', body);
  } catch (e) {
    throw new Error(e);
  }
};

export const signupBillingAfterCallStripeV2External = (body) => {
  try {
    return instance.post('/auth-v2/signup/external-billing-info', body);
  } catch (e) {
    throw new Error(e);
  }
};

export const signupBillingAfterCallStripeV2 = (body) => {
  try {
    return instance.post('/auth-v2/signup/billing-info', body);
  } catch (e) {
    throw new Error(e);
  }
};

export const updateBillingAfterCallStripeV2 = (body, id) => {
  try {
    return instance.patch(`/auth-v2/signup/update/billing-info/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

export const sendEmailV2 = (body) => {
  try {
    return instance.post(`/auth-v2/signup/service-information`, body);
  } catch (e) {
    throw new Error(e);
  }
};

export const generateLink = (body) => {
  try {
    return instance.post(`/location/invitation/generate-link`, body);
  } catch (e) {
    throw new Error(e);
  }
};

export const legalInfoForInvitation = (body, id) => {
  try {
    return instance.post(`/auth-v2/signup/legal-info/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};
export const updateLegalInfoForInvitation = (body, id) => {
  try {
    return instance.patch(`/auth-v2/signup/update/legal-info/${id}`, body);
  } catch (e) {
    throw new Error(e);
  }
};

export const getSubscriptionForBrand = (isSpecialBrand) => {
  try {
    return instance.get(`/brand/plans?isSpecialBrand=${isSpecialBrand}`);
  } catch (e) {
    throw new Error(e);
  }
};

export const getSubscriptionForSpecialLocation = (isSpecialLocation) => {
  try {
    return instance.get(`/location/plans?isSpecialLocation=${isSpecialLocation}`);
  } catch (e) {
    throw new Error(e);
  }
};

export const createBrand = (body) => {
  try {
    return instance.post(`/brand/servicing`, body);
  } catch (e) {
    throw new Error(e);
  }
};

export const applyCouponCode = (body) => {
  try {
    return instance.post(`/auth-v2/validate-one-time-setup-fee-coupon-code`, body);
  } catch (e) {
    throw new Error(e);
  }
};
