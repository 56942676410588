import { memo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mode } from 'redesign/AccountTab/config';
import TabWrapper from 'redesign/Shared/TabWrapper';
import LoginInfo from 'redesign/AccountTab/LoginInfo';
import { useAccount } from 'redesign/AccountTab/hooks/useAccount';
import AccountInformation from 'redesign/AccountTab/AccountInformation';

const Account = () => {
  const { t } = useTranslation();
  const { onCancel, handleEdit, sectionConfig } = useAccount();

  return (
    <Box sx={{ maxWidth: '998px', mt: '24px' }}>
      <TabWrapper
        children={
          <AccountInformation
            disabled={Boolean(sectionConfig.ACCOUNT_INFO.mode === mode.UPDATE)}
            onCancel={onCancel}
            id={sectionConfig.ACCOUNT_INFO.key}
          />
        }
        title={t(sectionConfig.ACCOUNT_INFO.title)}
        expandedPanel={sectionConfig.ACCOUNT_INFO.expandedPanel}
        hideEditButton={sectionConfig.ACCOUNT_INFO.hideEditButton}
        id={sectionConfig.ACCOUNT_INFO.key}
        handleEdit={handleEdit}
      />
      <TabWrapper
        children={
          <LoginInfo
            disabled={Boolean(sectionConfig.LOGIN_INFO.mode === mode.UPDATE)}
            onCancel={onCancel}
            id={sectionConfig.LOGIN_INFO.key}
            handleEdit={handleEdit}
          />
        }
        title={t(sectionConfig.LOGIN_INFO.title)}
        expandedPanel={sectionConfig.LOGIN_INFO.expandedPanel}
        hideEditButton={true}
        id={sectionConfig.LOGIN_INFO.key}
        handleEdit={handleEdit}
      />
    </Box>
  );
};
export default memo(Account);
