export const linkedWithProvider = (connectedProviders, providers) => {
  const result = Object.keys(connectedProviders)?.filter((item) => {
    return item != 'main' && connectedProviders[item];
  })?.length;
  return Boolean(result);
  // const moreThenOneConnected = Object.values(menu.connectedProviders).filter((item) => item);
};

export const checkLinkedProviderWebsite = (connectedProviders, providers) => {
  const result = Object.keys(connectedProviders)?.filter((item) => {
    return item != 'main' && connectedProviders[item];
  })?.length;
  return Boolean(result);
};
