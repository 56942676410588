import { useTranslation } from 'react-i18next';
import useTowFactor from 'hooks/useTowFactor';
import { FormProvider } from 'react-hook-form';
import CountDownTimer from './CountDownTimer';
import CloseIcon from '@mui/icons-material/Close';
import { SaveButton } from 'pages/TableOrdering/styles';
import Authentication from 'assets/icons/authentication.png';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import {
  TowFactorContainer,
  TowFactorContainerText,
  TowFactorImageBlock,
  TowFactorLink,
  TowFactorResendContainer,
  TowFactorText,
} from './styles';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, DialogActions } from '@mui/material';

const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ ml: 1, p: 2, fontSize: '24px', fontWeight: 400 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: '#929398',
          }}
        >
          <CloseIcon sx={{ color: '#929398' }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const TowFactorDialog = ({
  open,
  handleClose,
  towFactorKey,
  timerFinished,
  resetDuration,
  onTimerEnd,
  handleResetTimer,
  loadingResend,
}) => {
  const { t } = useTranslation();
  const { loginInMethod, handleSubmit, loading, block } = useTowFactor(open, towFactorKey, handleClose);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={handleClose}
      PaperProps={{ sx: { width: '454px', maxWidth: '100%', background: '#FAFAFA' } }}
    >
      <FormProvider {...loginInMethod}>
        <form onSubmit={loginInMethod.handleSubmit(handleSubmit)}>
          <Box>
            <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
              {t('Verify')}
            </CustomDialogTitle>
            <DialogContent sx={{ width: '100%', padding: 0, cursor: 'auto' }}>
              <TowFactorImageBlock>
                <img src={Authentication} alt={t('Authentication')} />
              </TowFactorImageBlock>
              <TowFactorContainerText>
                <TowFactorText>{t(`Please enter login code to verify authentication`)}</TowFactorText>
              </TowFactorContainerText>
              <TowFactorContainer>
                <CustomTextInput
                  sx={{ width: '100%', minHeight: '64px' }}
                  name="twoFactorAuthenticationCode"
                  label={<LabelRequired>{t('Enter authentication code')}</LabelRequired>}
                />
              </TowFactorContainer>
              <TowFactorResendContainer>
                <TowFactorLink
                  variant="text"
                  disabled={!timerFinished || loadingResend}
                  loading={loadingResend}
                  onClick={handleResetTimer}
                >
                  {timerFinished ? t('resend') : t('resend code in')}
                </TowFactorLink>
                <CountDownTimer duration={30} onTimerEnd={onTimerEnd} resetDuration={resetDuration} />
              </TowFactorResendContainer>
            </DialogContent>
            <DialogActions sx={{ borderTop: '1px solid #E7E7E7' }}>
              <SaveButton
                sx={{ mr: 2 }}
                variant="contained"
                type="submit"
                loading={loading}
                disabled={!loginInMethod.formState.isValid || loading || block}
              >
                {t('Verify')}
              </SaveButton>
            </DialogActions>
          </Box>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default TowFactorDialog;
