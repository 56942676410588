export const SignUpPathEnum = {
  ACCOUNT_INFO: '/signup/accountInfo',
  LEGAL_INFO: '/signup/legalInfo',
  BILLING_INFO: '/signup/billingInfo',
  SIGN_UP_SUCCESS: '/signup/success',
};

export const SignUpBillingMessages = {
  DOESNT_LOAD: 'Stripe.js has not loaded yet.',
  REQUIRE_CARD: 'Credit card info is required!',
};

export const SIGNUP_STEPS = {
  user_account: 'user_account',
  legal_business: 'legal_business',
  billing: 'billing',
};
