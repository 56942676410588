import { useCallback } from 'react';
import { useSelector } from 'react-redux';

function usePermissionPlan() {
  const { business } = useSelector(({ business }) => {
    return {
      business,
    };
  });
  const checkPermissionPlan = useCallback(
    (key) => {
      if (!key) return true;
      if (!(business && business?.currentBusiness?.plan)) return false;
      return (business?.currentBusiness?.plan?.value & key) === key;
    },
    [business]
  );

  return checkPermissionPlan;
}
export default usePermissionPlan;
