import { useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import { GOOGLE_API_KEY } from 'constants/config';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';

export default function GooglAutocomplete({
  name,
  required,
  readOnly = false,
  initialValue = '',
  getAddressFields = () => {},
  formSubmitted = false,
  onChangeInput = () => {},
  inputValue = '',
  label,
  setHasBusinessPlaceId = () => {},
  ...props
}) {
  const [value, setValue] = useState(inputValue || initialValue);
  const [error, handleError] = useState('');
  const { t } = useTranslation();

  const handleChangeInput = (e) => {
    const { value } = e.currentTarget;
    setHasBusinessPlaceId(false);
    onChangeInput(value);
    if (required) {
      if (!value) {
        handleError(t('field_is_required_'));
      } else {
        handleError('');
      }
    }
    setValue(value);
  };

  useEffect(() => {
    if (required) {
      if (formSubmitted) {
        if (!value) {
          handleError(t('field_is_required_'));
        } else {
          handleError('');
        }
      }
    }
  }, [readOnly, formSubmitted, value, required]);

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: (places) => {
      const { formatted_address, geometry, name, formatted_phone_number, place_id } = places;
      const components = places.address_components;

      const addressInfo = {
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        address: formatted_address,
        fullAddress: formatted_address,
        placeId: place_id,
        geoLocation: {
          lat: geometry?.location?.lat(),
          lng: geometry?.location?.lng(),
          debug: {
            ...places,
          },
        },
      };
      setHasBusinessPlaceId(Boolean(addressInfo?.placeId));
      if (components) {
        for (var i = 0, l = components.length; i < l; i++) {
          var component = components[i];
          if (component.types && component.types.indexOf('route') !== -1) {
            const streetNumber = components.find((item) => item.types.indexOf('street_number') !== -1);
            addressInfo.address1 = streetNumber
              ? `${streetNumber?.long_name} ${component.long_name}`
              : component.long_name;
          }
          if (component.types && component.types.indexOf('locality') !== -1) {
            addressInfo.city = component.long_name;
          }
          if (component.types && component.types.indexOf('administrative_area_level_1') !== -1) {
            addressInfo.state = component.short_name;
          }
          if (component.types && component.types.indexOf('country') !== -1) {
            addressInfo.country = component.long_name;
          }
          if (component.types && component.types.indexOf('postal_code') !== -1) {
            addressInfo.zipCode = component.long_name;
          }
          if (component.types && component.types.indexOf('subpremise') !== -1) {
            addressInfo.address2 = component.long_name;
          }
        }
      }
      addressInfo.fullAddress = '';
      if (addressInfo.address1 || addressInfo.address2) {
        addressInfo.fullAddress = `${addressInfo.address1} ${addressInfo.address2}, `;
      }
      if (addressInfo.city) {
        addressInfo.fullAddress += `${addressInfo.city}, `;
      }
      if (addressInfo.state || addressInfo.zipCode) {
        addressInfo.fullAddress += `${addressInfo.state} ${addressInfo.zipCode} ${addressInfo.country ? ',' : ''} `;
      }
      if (addressInfo.country) {
        addressInfo.fullAddress += `${addressInfo.country}`;
      }
      if (addressInfo?.address1 && addressInfo?.address1 !== '') {
        addressInfo.address = addressInfo?.address1;
      }
      getAddressFields({
        addressInfo,
        name,
        businessPhone: formatted_phone_number
          ? formatted_phone_number
              .replaceAll('(', '')
              .replaceAll(')', '')
              .replaceAll('-', '')
              .replaceAll(' ', '')
              .replaceAll('+', '')
          : '',
      });
      setValue(name);
    },
    options: {
      types: ['food'],
      fields: [
        'name',
        'rating',
        'formatted_phone_number',
        'formatted_address',
        'address_components',
        'geometry',
        'place_id',
      ],
    },
  });

  useEffect(() => {
    setValue(inputValue || initialValue);
  }, [inputValue, initialValue, readOnly]);

  return (
    <Box sx={{ position: 'relative', opacity: readOnly ? 0.5 : 1 }}>
      <TextField
        error={Boolean(error) && !readOnly}
        helperText={!readOnly ? error : ''}
        fullWidth
        inputRef={ref}
        id="companyName"
        size="small"
        name={name}
        label={label}
        onChange={handleChangeInput}
        disabled={readOnly}
        inputProps={{
          sx: { width: '100%' },
          value: value,
        }}
        // defaultValue={value}
        InputLabelProps={{ shrink: true }}
        {...props}
      />
    </Box>
  );
}
