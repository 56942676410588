import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { PADDING_MODAL_ACTION_LEFT } from 'pages/TableOrdering/config';

const CreateOrEditForm = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6} lg={6} xl={6}>
            <Box sx={PADDING_MODAL_ACTION_LEFT}>
              <CustomTextInput
                sx={{ width: '100%', height: '64px' }}
                name="name"
                label={<LabelRequired> {t('table_ordering.section_name')}</LabelRequired>}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default CreateOrEditForm;
