import { useState, useEffect } from 'react';
import { inviteUser } from 'redux/actions/user';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Dialog, DialogContent, DialogActions, DialogTitle, Typography, MenuItem, Button } from '@mui/material';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { useTranslation } from 'react-i18next';

import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
const InviteUsers = ({ onSuccess, opts }) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const invitationFormSchema = yup.object().shape({
    role: yup.string().required(t('field_is_required_')),
    email: yup.string().email(t('field_is_required_')).required(t('field_is_required_')),
  });
  // const findIndex = opts.findIndex((role) => role.id === userData?.role);
  // const filterDataRoles = opts.filter((_, index) => index >= findIndex);
  const invitationFormMethods = useForm({
    mode: 'all',
    defaultValues: { roles: '', email: '' },
    resolver: yupResolver(invitationFormSchema),
  });

  const toggle = () => {
    invitationFormMethods.reset({ role: '', email: '' });
    setOpen(!isOpen);
  };

  const handleSubmit = (val) => {
    setLoading(true);
    val.role = [val.role];
    inviteUser(val)
      .then(() => {
        setLoading(false);
        toggle();
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (opts.length) {
      invitationFormMethods.reset({ role: '', email: '' });
    }
  }, [opts]);

  return (
    <>
      <Button id="inviteUsersAdd" size="small" variant="contained" color="primary" onClick={toggle}>
        {t('settings.add_invite_user')}
      </Button>
      <FormProvider {...invitationFormMethods}>
        <Dialog
          open={isOpen}
          onClose={toggle}
          aria-describedby="alert-dialog-slide-description"
          PaperComponent={DraggableDialogWrapper}
        >
          <form onSubmit={invitationFormMethods.handleSubmit(handleSubmit)}>
            <DialogTitle sx={{ cursor: 'grab' }}>{t('settings.send_invitation')}</DialogTitle>
            <DialogContent>
              <CustomTextInput
                sx={{ mt: 2, width: '100%', height: '72px' }}
                select={true}
                // inputProps={{
                //   select: true,
                // }}
                name="role"
                label={<LabelRequired>{t('settings.role')}</LabelRequired>}
              >
                {opts.map((item) => {
                  return (
                    <MenuItem key={item._id} value={item._id}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </CustomTextInput>
              <Typography sx={{ color: 'grey', fontSize: '13px' }}>
                {t('settings.location_admin_location')}
                <br />
                {t('settings.Manager_full_dashboard_billing_section.')}
                <br />
                {t('settings.User_read_only_access_access_to_Orders.')}
              </Typography>

              <CustomTextInput
                sx={{ mt: 2, width: '100%', height: '72px' }}
                inputProps={{
                  type: 'email',
                }}
                name="email"
                label={<LabelRequired>{t('sign_in.email_address')}</LabelRequired>}
              />
            </DialogContent>
            <DialogActions sx={{ cursor: 'grab' }}>
              <Button
                id="inviteUsersSend"
                size="small"
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
              >
                {t('settings.send_invite')}
              </Button>
              <Button
                id="inviteUsersCancel"
                onClick={toggle}
                size="small"
                variant="outlined"
                color="primary"
                disabled={loading}
              >
                {t('orders.cancel')}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default InviteUsers;
