import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateUserPassword } from 'redux/actions/user';
import { checkMarkValidationConfig, validationKeys } from 'redesign/AccountTab/config';
import { schemaAccountCredentials, userDefaultData } from 'redesign/AccountTab/schema/schema';

export const useLoginInfo = (disabled) => {
  const MIN_LENGTH = 8;
  const MIN_LENGTH_OLD_PASSWORD = 1;
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [oldPasswordIsValid, setOldPasswordIsValid] = useState(false);
  const [validation, setValidation] = useState(checkMarkValidationConfig);
  const userEmail = localStorage.getItem('email');

  const accountCredentials = useForm({
    defaultValues: userDefaultData,
    mode: 'all',
    resolver: yupResolver(schemaAccountCredentials),
  });

  const { control, getValues, reset } = accountCredentials;
  const { password, confirmPassword, oldPassword } = useWatchPasswords(control);

  useEffect(() => {
    reset(userDefaultData, { keepDirty: false, keepErrors: false });
  }, [disabled]);

  useEffect(() => {
    updateValidation(getValues());
  }, [password, confirmPassword]);

  useEffect(() => {
    const currentOldPassword = getValues().oldPassword;
    setOldPasswordIsValid(currentOldPassword.length >= MIN_LENGTH_OLD_PASSWORD);
  }, [oldPassword]);

  function useWatchPasswords(control) {
    return {
      password: useWatch({ control, name: 'password' }),
      confirmPassword: useWatch({ control, name: 'confirmPassword' }),
      oldPassword: useWatch({ control, name: 'oldPassword' }),
    };
  }

  function updateValidation(currentModel) {
    const passwordCriteria = [
      { key: validationKeys.MIN, isValid: currentModel.password.length >= MIN_LENGTH },
      {
        key: validationKeys.MATCH_KEY,
        isValid: currentModel.password === currentModel.confirmPassword && currentModel.password !== '',
      },
      { key: validationKeys.INCLUDE_KEY, isValid: /[0-9]/.test(currentModel.password) },
      { key: validationKeys.ONLY_LETTERS_LOWERCASE, isValid: /[a-z]/.test(currentModel.password) },
      { key: validationKeys.ONLY_LETTERS, isValid: /[A-Z]/.test(currentModel.password) },
      { key: validationKeys.ONLY_SYMBOLS, isValid: /[^\w\s]/.test(currentModel.password) },
    ];

    passwordCriteria.forEach((criteria) => {
      setValidation((prevState) =>
        prevState.map((item) =>
          item.key === criteria.key
            ? { ...item, error: false, middle: !criteria.isValid, success: criteria.isValid }
            : item
        )
      );
    });
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await updateUserPassword(values);
      reset();
    } catch (error) {
      console.error(error); // Preferably replace with a more robust error handling strategy
    } finally {
      setLoading(false);
    }
  };

  const handleInvalidSubmit = (errors) => {
    ['password', 'confirmPassword'].forEach((field) => {
      if (errors[field]) {
        setValidation((prevState) =>
          prevState.map((item) => ({ ...item, error: true, middle: false, success: false }))
        );
      }
    });
  };

  return {
    handleInvalidSubmit,
    handleSubmit,
    handleClickShowNewPassword: () => setShowNewPassword((prev) => !prev),
    handleClickShowCurrentPassword: () => setShowCurrentPassword((prev) => !prev),
    handleClickShowPassword: () => setShowConfirmPassword((prev) => !prev),
    accountCredentials,
    userEmail,
    validation,
    showNewPassword,
    showCurrentPassword,
    showConfirmPassword,
    loading,
    updateHasError: () => validation.some((item) => item.middle || item.error),
    oldPasswordIsValid,
  };
};