import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import { t } from 'i18next';
const ConnectModal = ({ onSubmit, loading, progress, name, error }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (event, values) => {
      onSubmit(values);
      dispatch({ type: 'PROVIDER_CONNECT_CONVENTIONAL_ERROR', payload: '' });
    },
    [onSubmit]
  );
  return (
    <div className="provider-modal">
      <div className="provider-modal-content">
        <div className="provider-form">
          <AvForm onValidSubmit={handleSubmit}>
            <AvField
              name="username"
              label={t('providers_settings.username')}
              validate={{
                required: { value: true, errorMessage: t('field_is_required_') },
              }}
            />
            <AvField
              name="password"
              label={t('sign_in.password')}
              type="password"
              validate={{
                required: { value: true, errorMessage: t('field_is_required_') },
              }}
            />
            {error && <Typography sx={{ color: 'red', mt: 1, mb: 1 }}>{error}</Typography>}
            <Button id="connectModal" color="primary" type="submit">
              {t('website_settings.connect')}
            </Button>
          </AvForm>
        </div>
      </div>
      {/* {loading && <Preloader />} */}
      {!error && loading && progress !== 100 && (
        <div className={`preloader ${true ? 'overlay' : ''} `} style={{ background: 'rgb(255 255 255 / 88%)' }}>
          <div className="loader_block">
            <Box sx={{ width: '100%', mt: -6, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              {/* <CircularProgress variant="determinate" value={90} /> */}
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" value={progress} />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(progress)}%`}
                  </Typography>
                </Box>
              </Box>
              <Typography sx={{ mt: 2 }}>{`Getting ${name} stores list...`}</Typography>
              <Typography>{t('header.it_may_take_while_please_wait')}</Typography>
              {/* <LinearProgress variant="determinate" value={progress} /> */}
            </Box>

            {/* <div className="dot loader_dot_1"></div>
          <div className="dot loader_dot_2"></div>
          <div className="dot loader_dot_3"></div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ConnectModal);
