import { SET_ACTIVE_COUPONS, SET_EXPIRED_COUPONS, SET_COUPON_LOADING } from 'redux/actions/types';
const initialState = {
  activeCoupons: [],
  expiredCoupons: [],
  loading: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_COUPONS:
      return {
        ...state,
        activeCoupons: payload,
      };
    case SET_EXPIRED_COUPONS:
      return {
        ...state,
        expiredCoupons: payload,
      };
    case SET_COUPON_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};
