import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { memo, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import instance from 'services/api';
import * as yup from 'yup';

const ProviderCredential = ({ credential, provider }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [buttonName, setButtonName] = useState(t('orders_history.apply'));
  const [disabledButton, setDisabledButton] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const doordashSchema = yup.object().shape({
    username: yup.string().required('help.field_is_required'),
    password: yup.string().required('help.field_is_required'),
  });
  const doordashForm = useForm({
    mode: 'all',
    defaultValues: { username: '', password: '' },
    resolver: yupResolver(doordashSchema),
  });
  useEffect(() => {
    if (credential && credential?.username && credential?.password) {
      setButtonName('Update');
      doordashForm.setValue('username', credential?.username || '');
      doordashForm.setValue('password', credential?.password || '');
    }
  }, [credential]);
  const handleDoorDashFormSubmit = (values) => {
    setLoading(true);
    return instance
      .put(`/providers/auth/${provider?.srv}`, values)
      .then((res) => {
        let userName = res?.data?.data?.provider?.tabletAuth?.username;
        let password = res?.data?.data?.provider?.tabletAuth?.password;
        doordashForm.setValue('username', userName || '', {
          shouldDirty: false,
        });
        doordashForm.setValue('password', password || '', {
          shouldDirty: false,
        });
        setIsSubmit(true);
        setLoading(false);
        buttonName === 'Save'
          ? toast.success('Successfully Saved')
          : toast.success('Successfully Updated');
        if (userName && password) {
          setButtonName('Update');
        }
      })
      .catch(() => setLoading(false));
  };

  const handleChange = (event) => {
    if (event.target.value) {
      doordashForm.setValue(event.target.name, event.target.value, {
        shouldDirty: true,
      });
      setDisabledButton(false);
      setIsSubmit(false);
    } else {
      doordashForm.setValue(event.target.name, '', { shouldDirty: true });
    }
  };
  return (
    <Box sx={{ width: '100%', padding: '16px' }}>
      <FormProvider {...doordashForm}>
        <form onSubmit={doordashForm.handleSubmit(handleDoorDashFormSubmit)}>
          <Box display="flex" gap={2}>
            <Box sx={{ pt: 2, width: '100%', maxWidth: '295px' }}>
              <CustomTextInput
                onChange={handleChange}
                name="username"
                label={t('providers_settings.username')}
                sx={{ width: '100%', maxWidth: '295px' }}
              />
            </Box>
            <Box sx={{ pt: 2, width: '100%', maxWidth: '295px' }}>
              <CustomTextInput
                onChange={handleChange}
                name="password"
                label={t('sign_in.password')}
                sx={{ width: '100%', maxWidth: '295px' }}
              />
            </Box>
          </Box>
          <Box sx={{ textAlign: 'end' }}>
            <LoadingButton
              disabled={
                disabledButton ||
                isSubmit ||
                loading ||
                !doordashForm.formState.isValid
              }
              variant="contained"
              color="primary"
              type="submit"
              loading={loading}
            >
              {t('orders_history.apply')}
            </LoadingButton>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};
export default memo(ProviderCredential);
