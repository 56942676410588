import { createContext, useContext, useState } from 'react';

import { useDispatch } from 'react-redux';

export const initialFormDataValues = {
  updateFormData: () => {},
  formData: {},
  lenguage: '',
  setLenguage: () => {},
};

export const FormContext = createContext(initialFormDataValues);

export const FormTranslateProvider = ({ children }) => {
  const dispatch = useDispatch();
  //   const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [lenguage, setLenguage] = useState('en');

  const updateFormData = (data) => {
    const newData = { ...formData, ...data };
    setFormData(newData);
  };

  return (
    <FormContext.Provider
      value={{
        updateFormData,
        formData,
        lenguage,
        setLenguage,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormTranslateContext = () => {
  return useContext(FormContext);
};
