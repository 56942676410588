import { useEffect, useState } from 'react';
import ActionButtons from './actions';
import Grid from '@mui/material/Grid';
import { FormControlLabel, FormGroup, MenuItem, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { SIGNUP_STEPS, SignUpPathEnum } from 'constants/signUpPath';
import { removeItemAfterSignUp } from 'utils/soldOut';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyTypes, companyTypesEnum } from 'constants/companyTypes';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { businessInfoFormValidationSchema } from './signupSchemas';
import GooglAutocomplete from 'components/FormFields/GoogleAutocomplate';
import {
  signupLegalInfoV2,
  signupLegalInfoV2External,
  updateLegalInfoV2,
  updateLegalInfoV2External,
} from 'redux/actions/userAuthV2';
import GoogleAutocomplateBusiness from 'components/FormFields/GoogleAutocomplateBusiness';
import { PaymentPeriodicityEnum } from 'constants/config';
import CustomMobilePhone from 'components/MobilePhone/CustomMobilePhone';
import { useTranslation } from 'react-i18next';
import LabelRequired from 'components/FormFields/LabelRequired';
import Checkbox from '@mui/material/Checkbox';
import { patchSignUpProgress } from 'redux/actions/user';
import useQuery from 'hooks/useQuery';
import useTabClose from 'hooks/useTabClose';

const data = localStorage.getItem('legalInfo');
const parsedData = data && JSON.parse(data);

const initialLegalInfo = {
  sameAsDefault: false,
  companyType: '',
  businessInfo: {
    phone: '',
    companyName: '',
    type: '',
    metadata: { communicationEmail: '' },
    addressInfo: { address: '', city: '', state: '', zipCode: '', placeId: '' },
  },
  legalInfo: {
    legalName: '',
    legalPhone: '',
    addressInfo: { address: '', city: '', state: '', zipCode: '', placeId: '' },
  },
};

const LegaInfo = ({
  active,
  companyType,
  setCompanyType,
  externalToken = '',
  externalPlanId = '',
  corporateBusiness = '',
}) => {
  const { t } = useTranslation();
  const query = useQuery();
  const userId = query.get('userId');
  const email = query.get('email');
  const commEmail = query.get('communicationEmail');
  const [isChecked, setIsChecked] = useState(false);
  const [addressPlaceId, setAddressPlaceId] = useState('');
  const [hasBusinessPlaceId, setHasBusinessPlaceId] = useState(false);
  const progressUrl = `${window.location.origin}`;
  const history = useHistory();
  const businessInfoFormMethods = useForm({
    defaultValues: parsedData ? parsedData : initialLegalInfo,
    mode: 'all',
    resolver: yupResolver(businessInfoFormValidationSchema),
  });
  useTabClose();
  const [loading, setLoading] = useState(false);
  const {
    formState: { isSubmitted },
    getValues,
    setValue,
    reset,
    control,
    watch,
  } = businessInfoFormMethods;

  const companyTypeWatcher = useWatch({ control, name: 'companyType' });

  const navigationAndSetQueries = (responseData) => {
    const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
    if (externalToken !== null && externalPlanId !== null && corporateBusiness !== null) {
      history.push(
        `${SignUpPathEnum.BILLING_INFO}?businessId=${responseData?.data?.data?.businessId}&legalEntityId=${
          responseData?.data?.data?.legalEntityId
        }&userId=${accountInfo?.userId || userId}&term=${
          PaymentPeriodicityEnum.yearly
        }&token=${externalToken}&plan=${externalPlanId}&corporateBusiness=${corporateBusiness}`
      );
    } else {
      history.push(
        `${SignUpPathEnum.BILLING_INFO}?businessId=${responseData?.data?.data?.businessId}&legalEntityId=${
          responseData?.data?.data?.legalEntityId
        }&userId=${accountInfo?.userId || userId}&term=${
          PaymentPeriodicityEnum.yearly
        }`
      );
    }
  };

  const handleSubmit = (values) => {
    setLoading(true);
    values.businessInfo.type = companyType;
    const account = JSON.parse(localStorage.getItem('accountInfo'));
    const data = localStorage.getItem('legalInfo');
    const parsedLegalInfo = data && JSON.parse(data);
    values.businessInfo.metadata = {
      addressPlaceId: addressPlaceId ?? '',
      communicationEmail: account?.communicationEmail || commEmail,
    };
    const currentCreateUrl =
      externalPlanId && externalToken && corporateBusiness ? signupLegalInfoV2External : signupLegalInfoV2;
    const currentUpdateUrl =
      externalPlanId && externalToken && corporateBusiness ? updateLegalInfoV2External : updateLegalInfoV2;
    if (externalPlanId && externalToken && corporateBusiness) {
      values.businessInfo.metadata = {
        ...values.businessInfo.metadata,
        plan: externalPlanId,
        externalToken: externalToken,
        corporateBusiness: corporateBusiness,
      };
    }
    if (companyType !== companyTypesEnum.SINGLE) {
      values.sameAsDefault = true;
      values.businessInfo['email'] = account?.email || email;
      values.businessInfo.companyName = values?.legalInfo?.legalName;
      values.businessInfo['brandName'] = values?.legalInfo?.legalName;
      values.businessInfo.phone = values?.legalInfo?.legalPhone;
      values.businessInfo.addressInfo = values?.legalInfo?.addressInfo;
      values.userId = account?.userId || userId;
      return currentCreateUrl(values)
        .then(() => {
          removeItemAfterSignUp();
          history.push(SignUpPathEnum.SIGN_UP_SUCCESS);
        })
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    }
    if (companyType === companyTypesEnum.SINGLE && parsedLegalInfo) {
      const legalResponseInfo = JSON.parse(localStorage.getItem('legalResponseInfo'));
      values.legalInfo['legalEntityId'] = legalResponseInfo?.legalEntityId;
      values.businessInfo['businessId'] = legalResponseInfo?.businessId;
      values.businessInfo['email'] = account?.email || email;
      if (values.sameAsDefault) {
        values.businessInfo.companyName = values?.legalInfo.legalName;
        values.businessInfo['brandName'] = values?.legalInfo?.legalName;
        values.businessInfo.phone = values?.legalInfo.legalPhone;
        values.businessInfo.addressInfo = values?.legalInfo.addressInfo;
      } else {
        values.businessInfo['brandName'] = values?.businessInfo?.companyName;
      }
      const businessID = values?.businessInfo?.businessId;
      const legalInfoID = values?.legalInfo?.legalEntityId;
      if (parsedLegalInfo && businessID && legalInfoID) {
        delete parsedLegalInfo.legalEntityId;
        delete parsedLegalInfo.businessId;
        return currentUpdateUrl(values)
          .then((responseData) => {
            localStorage.setItem('legalInfo', JSON.stringify({ ...values }));
            localStorage.setItem('legalResponseInfo', JSON.stringify({ ...responseData?.data?.data }));
            navigationAndSetQueries(responseData);
            patchSignUpProgress({
              step: SIGNUP_STEPS.legal_business,
              url: `${progressUrl}${SignUpPathEnum.BILLING_INFO}?businessId=${
                responseData?.data?.data?.businessId
              }&legalEntityId=${responseData?.data?.data?.legalEntityId}&userId=${account?.userId || userId}&term=${
                PaymentPeriodicityEnum.yearly
              }&plan=${externalPlanId}&token=${externalToken}&corporateBusiness=${corporateBusiness}`,
              businessId: responseData?.data?.data?.businessId,
            });
          })
          .catch(() => setLoading(false))
          .finally(() => setLoading(false));
      }
    } else {
      if (values.sameAsDefault) {
        values.businessInfo.companyName = values?.legalInfo?.legalName;
        values.businessInfo['brandName'] = values?.legalInfo?.legalName;
        values.businessInfo.phone = values?.legalInfo?.legalPhone;
        values.businessInfo.addressInfo = values?.legalInfo?.addressInfo;
      } else {
        values.businessInfo['brandName'] = values?.businessInfo?.companyName;
      }
      values.userId = account?.userId || userId;
      values.businessInfo['email'] = account?.email || email;
      return currentCreateUrl(values)
        .then((responseData) => {
          localStorage.setItem('legalInfo', JSON.stringify(values));
          localStorage.setItem('legalResponseInfo', JSON.stringify({ ...responseData?.data?.data }));
          navigationAndSetQueries(responseData);
          patchSignUpProgress({
            step: SIGNUP_STEPS.legal_business,
            url: `${progressUrl}${SignUpPathEnum.BILLING_INFO}?businessId=${
              responseData?.data?.data?.businessId
            }&legalEntityId=${responseData?.data?.data?.legalEntityId}&userId=${account?.userId || userId}&term=${
              PaymentPeriodicityEnum.yearly
            }&plan=${externalPlanId}&token=${externalToken}&corporateBusiness=${corporateBusiness}`,
            businessId: responseData?.data?.data?.businessId,
          });
        })
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    const data = localStorage.getItem('legalInfo');
    const parsedData = data && JSON.parse(data);
    if (parsedData) {
      businessInfoFormMethods.reset(parsedData);
    } else {
      if (externalToken && externalPlanId) {
        businessInfoFormMethods.reset({
          ...initialLegalInfo,
          companyType: companyTypesEnum.SINGLE,
        });
        setCompanyType(companyTypesEnum.SINGLE);
      } else {
        businessInfoFormMethods.reset(initialLegalInfo);
        setCompanyType('');
      }
    }
  }, [businessInfoFormMethods, externalToken, externalPlanId]);

  const setDataModel = (dataModel) => {
    let checkAddress = Boolean(
      dataModel?.legalInfo?.addressInfo.address1 !== dataModel?.businessInfo?.addressInfo.address
    );
    let checkCity = Boolean(dataModel?.legalInfo?.addressInfo.city !== dataModel?.businessInfo?.addressInfo.city);
    let checkState = Boolean(dataModel?.legalInfo?.addressInfo.state !== dataModel?.businessInfo?.addressInfo.state);
    let checkZipCode = Boolean(
      dataModel?.legalInfo?.addressInfo.zipCode !== dataModel?.businessInfo?.addressInfo.zipCode
    );
    let checkAddress2 = Boolean(
      dataModel?.legalInfo?.addressInfo.address2 !== dataModel?.businessInfo?.addressInfo.address2
    );
    if (checkAddress) {
      dataModel.businessInfo.addressInfo.address = dataModel?.legalInfo?.addressInfo.address1;
    }
    if (checkCity) {
      dataModel.businessInfo.addressInfo.city = dataModel?.legalInfo?.addressInfo.city;
    }
    if (checkState) {
      dataModel.businessInfo.addressInfo.state = dataModel?.legalInfo?.addressInfo.state;
    }
    if (checkZipCode) {
      dataModel.businessInfo.addressInfo.zipCode = dataModel?.legalInfo?.addressInfo.zipCode;
    }
    if (checkAddress2) {
      dataModel.businessInfo.addressInfo['address2'] = dataModel?.legalInfo?.addressInfo.address2;
    }

    return dataModel;
  };

  const setLegalORBusinessInfoData = (data, type) => {
    const { addressInfo, name, businessPhone } = data;
    if (addressInfo?.placeId) {
      setAddressPlaceId(addressInfo?.placeId);
    }
    type === 'legalInfo' ? setValue(`${type}.legalName`, name || '') : setValue(`${type}.companyName`, name || '');
    setValue(`${type}.businessPhone`, businessPhone || '');
    setValue(
      `${type}.addressInfo.address`,
      addressInfo?.address1 ||
        addressInfo?.address ||
        addressInfo?.fullAddress ||
        getValues()[type].addressInfo?.address ||
        ''
    );
    setValue(`${type}.addressInfo.state`, addressInfo?.state || getValues()[type].addressInfo?.state || '');
    setValue(`${type}.addressInfo.fullAddress`, addressInfo?.fullAddress || addressInfo?.address || '');
    setValue(`${type}.addressInfo.address2`, addressInfo?.address2 || getValues()[type].addressInfo?.address2 || '');
    setValue(
      `${type}.addressInfo.zipCode`,
      addressInfo?.zipCode || getValues()[type].legalInfo?.addressInfo?.zipCode || ''
    );
    setValue(
      `${type}.addressInfo.address1`,
      addressInfo?.address1 ||
        addressInfo?.address ||
        addressInfo?.fullAddress ||
        getValues()[type].addressInfo?.address1 ||
        addressInfo?.address ||
        getValues()[type].addressInfo?.address ||
        ''
    );

    let newDataModel = {
      ...getValues(),
      [type]: {
        ...getValues()[type],
        addressInfo: {
          ...getValues()[type].addressInfo,
          ...addressInfo,
        },
      },
    };
    if (newDataModel.sameAsDefault && newDataModel?.companyType === companyTypesEnum.SINGLE) {
      newDataModel = setDataModel(newDataModel);
    }

    businessInfoFormMethods.reset(newDataModel, {
      keepIsSubmitted: true,
      keepDirty: true,
      keepDefaultValues: false,
    });
  };
  const getAddressFieldsHandle = (data, type) => {
    const { addressInfo } = data;
    if (addressInfo && getValues()?.sameAsDefault && getValues()?.companyType === companyTypesEnum.SINGLE) {
      setLegalORBusinessInfoData({ addressInfo }, 'legalInfo');
    }
    setValue(
      `${type}.addressInfo.address`,
      addressInfo?.address || addressInfo?.fullAddress || getValues()[type].addressInfo?.address || ''
    );
    setValue(`${type}.addressInfo.state`, addressInfo?.state || getValues()[type].addressInfo?.state || '');
    setValue(`${type}.addressInfo.fullAddress`, addressInfo?.fullAddress || addressInfo?.address || '');
    setValue(`${type}.addressInfo.address2`, addressInfo?.address2 || getValues()[type].addressInfo?.address2 || '');
    setValue(
      `${type}.addressInfo.zipCode`,
      addressInfo?.zipCode || getValues()[type].legalInfo?.addressInfo?.zipCode || ''
    );
    setValue(
      `${type}.addressInfo.address1`,
      addressInfo?.address1 ||
        addressInfo?.address ||
        addressInfo?.fullAddress ||
        getValues()[type].addressInfo?.address1 ||
        ''
    );

    let newDataModel = {
      ...getValues(),
      [type]: {
        ...getValues()[type],
        addressInfo: {
          ...getValues()[type].addressInfo,
          ...addressInfo,
        },
      },
    };
    if (newDataModel.sameAsDefault && companyType === companyTypesEnum.SINGLE) {
      newDataModel = setDataModel(newDataModel);
    }
    reset(newDataModel, {
      keepIsSubmitted: true,
      keepDirty: true,
      keepDefaultValues: false,
    });
  };
  const onChangeLegalInfoNameInput = (value) => {
    businessInfoFormMethods.setValue('legalInfo.legalName', value || '');
    reset(getValues(), { keepErrors: true });
  };
  const onChangeBusinessInfoNameInput = (value) => {
    businessInfoFormMethods.setValue('businessInfo.companyName', value || '');
    reset(getValues(), { keepErrors: true });
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        return false;
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const currentModel = getValues();

  useEffect(() => {
    const model = getValues();
    if (
      model.sameAsDefault &&
      currentModel?.legalInfo?.legalPhone &&
      currentModel?.companyType === companyTypesEnum.SINGLE
    ) {
      setValue('businessInfo.phone', currentModel?.legalInfo?.legalPhone, { shouldDirty: true });
    }
  }, [currentModel?.legalInfo?.legalPhone]);

  useEffect(() => {
    const model = getValues();
    setCompanyType(model?.companyType);
  }, [companyTypeWatcher]);

  useEffect(() => {
    if (companyType === companyTypesEnum.SINGLE) {
      const currentModel = getValues();
      reset(
        {
          ...currentModel,
          sameAsDefault: false,
          businessInfo: {
            addressInfo: {
              ...initialLegalInfo.businessInfo.addressInfo,
              address2: '',
            },
            companyName: initialLegalInfo.businessInfo.companyName,
            phone: initialLegalInfo.businessInfo.phone,
          },
        },
        { keepDirty: true }
      );
      setIsChecked(false);
    } else {
      if (companyType) {
        reset(
          {
            ...currentModel,
            sameAsDefault: true,
            businessInfo: {
              addressInfo: {
                ...currentModel?.legalInfo?.addressInfo,
                address: currentModel?.legalInfo?.addressInfo?.address1,
              },
              companyName: currentModel?.legalInfo?.legalName,
              phone: currentModel?.legalInfo?.legalPhone,
            },
          },
          { keepDirty: true }
        );
        setIsChecked(true);
      }
    }
  }, [companyType]);

  const handleChange = (event) => {
    const currentModel = getValues();
    if (event.target.checked) {
      setIsChecked(event.target.checked);
      reset(
        {
          ...currentModel,
          sameAsDefault: event.target.checked,
          businessInfo: {
            addressInfo: {
              ...currentModel?.legalInfo?.addressInfo,
              address: currentModel?.legalInfo?.addressInfo?.address1,
            },
            companyName: currentModel?.legalInfo?.legalName,
            phone: currentModel?.legalInfo?.legalPhone,
          },
        },
        { keepDirty: true }
      );
    } else {
      setIsChecked(event.target.checked);
      reset(
        {
          ...currentModel,
          sameAsDefault: event.target.checked,
          businessInfo: {
            addressInfo: {
              ...initialLegalInfo.businessInfo.addressInfo,
              address2: '',
            },
            companyName: initialLegalInfo.businessInfo.companyName,
            phone: initialLegalInfo.businessInfo.phone,
          },
        },
        { keepDirty: true }
      );
    }
  };

  watch((data, { name }) => {
    if (data.sameAsDefault && data.companyType === companyTypesEnum.SINGLE) {
      const currentModel = getValues();
      if (name === 'legalInfo.legalName') {
        setValue('businessInfo.companyName', currentModel?.legalInfo?.legalName);
      }
      if (name === 'legalInfo.legalPhone') {
        setValue('businessInfo.phone', currentModel?.legalInfo?.legalPhone);
      }
      if (name === 'legalInfo.addressInfo.address2') {
        setValue('businessInfo.addressInfo.address2', currentModel?.legalInfo?.addressInfo?.address2);
      }
      if (name === 'legalInfo.addressInfo.city') {
        setValue('businessInfo.addressInfo.city', currentModel?.legalInfo?.addressInfo?.city);
      }
      if (name === 'legalInfo.addressInfo.state') {
        setValue('businessInfo.addressInfo.state', currentModel?.legalInfo?.addressInfo?.state);
      }
      if (name === 'legalInfo.addressInfo.zipCode') {
        setValue('businessInfo.addressInfo.zipCode', currentModel?.legalInfo?.addressInfo?.zipCode);
      }
    }
  });

  const addOpacityInInput = (isDisabled) => (isDisabled ? 0.5 : 1);

  const handleCheckButtonTitle = () =>
    companyType !== companyTypesEnum.SINGLE ? t('order_view.submit') : t('settings.next');

  return (
    <FormProvider {...businessInfoFormMethods}>
      <form onSubmit={businessInfoFormMethods.handleSubmit(handleSubmit, (err) => console.log(err))}>
        <>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Typography sx={{ fontSize: '20px', mt: 2, fontWeight: 'bold', mb: 1 }}>
                {t('sign_up.legal_business_information')}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12} sx={{ height: '72px' }}>
              <GoogleAutocomplateBusiness
                getAddressFields={(data) => setLegalORBusinessInfoData(data, 'legalInfo')}
                onChangeInput={onChangeLegalInfoNameInput}
                inputValue={currentModel.legalInfo?.legalName || ''}
                initialValue={currentModel.legalInfo?.legalName || ''}
                name="legalInfo.legalName"
                label={<LabelRequired>{t('sign_up.legal_business_name')}</LabelRequired>}
                placeholder=""
                required={true}
                formSubmitted={isSubmitted}
                setHasBusinessPlaceId={setHasBusinessPlaceId}
              />
            </Grid>
            <Grid item md={6} xs={12} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%' }}
                select={true}
                name="companyType"
                size="small"
                label={<LabelRequired>{t('sign_up.company_type')}</LabelRequired>}
                disabled={Boolean(externalToken && externalPlanId)}
              >
                {companyTypes.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {t(label)}
                  </MenuItem>
                ))}
              </CustomTextInput>
            </Grid>
            <Grid item md={6} xs={12} sx={{ height: '72px' }}>
              <CustomMobilePhone
                defaultValue={currentModel?.legalInfo.legalPhone || ''}
                sx={{ width: '100%' }}
                name="legalInfo.legalPhone"
                label={<LabelRequired>{t('settings.phone_number')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ height: '72px' }}>
              <GooglAutocomplete
                isSignUp={true}
                getAddressFields={(data) => getAddressFieldsHandle(data, 'legalInfo')}
                name="legalInfo.addressInfo.address1"
                initialValue={currentModel?.legalInfo.addressInfo?.address1 || ''}
                inputValue={currentModel?.legalInfo.addressInfo?.address1 || ''}
                label={<LabelRequired>{t('order_view.address')}</LabelRequired>}
                placeholder=""
                required={true}
                formSubmitted={isSubmitted}
                setAddressPlaceId={setAddressPlaceId}
              />
            </Grid>
            <Grid item md={12} xs={12} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%' }}
                defaultValue={currentModel?.legalInfo.addressInfo?.address2 || ''}
                name="legalInfo.addressInfo.address2"
                label={t('order_view.address2')}
              />
            </Grid>
            <Grid item md={4} xs={12} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%' }}
                defaultValue={currentModel?.legalInfo.addressInfo?.city || ''}
                name="legalInfo.addressInfo.city"
                label={<LabelRequired>{t('settings.city')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={4} xs={12} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%' }}
                defaultValue={currentModel?.legalInfo.addressInfo?.state || ''}
                name="legalInfo.addressInfo.state"
                label={<LabelRequired>{t('settings.state')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={4} xs={12} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%' }}
                inputProps={{ type: 'number' }}
                defaultValue={currentModel?.legalInfo.addressInfo?.zipCode || ''}
                name="legalInfo.addressInfo.zipCode"
                label={<LabelRequired>{t('settings.zip_code')}</LabelRequired>}
              />
            </Grid>
            {companyType === companyTypesEnum?.SINGLE && (
              <>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={isChecked} name="sameAsDefault" onChange={handleChange} />}
                      label={t('sign_up.restaurant_legal_business')}
                    />
                  </FormGroup>
                </Grid>
                <>
                  <Grid item md={12} sx={{ mb: 1 }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
                      {t('sign_up.restaurant_information')}
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={12} sx={{ height: '72px' }}>
                    <GoogleAutocomplateBusiness
                      readOnly={currentModel?.sameAsDefault}
                      getAddressFields={(data) => setLegalORBusinessInfoData(data, 'businessInfo')}
                      onChangeInput={onChangeBusinessInfoNameInput}
                      inputValue={currentModel?.businessInfo.companyName || ''}
                      initialValue={currentModel?.businessInfo.companyName || ''}
                      name="businessInfo.companyName"
                      label={<LabelRequired>{t('sign_up.restaurant_name')}</LabelRequired>}
                      placeholder=""
                      required={!currentModel.sameAsDefault}
                      formSubmitted={isSubmitted}
                    />
                  </Grid>
                  <Grid item md={4} xs={12} sx={{ height: '72px' }}>
                    <CustomMobilePhone
                      disabled={currentModel?.sameAsDefault}
                      defaultValue={currentModel?.businessInfo.phone || ''}
                      sx={{ width: '100%', opacity: addOpacityInInput(currentModel?.sameAsDefault) }}
                      name="businessInfo.phone"
                      label={<LabelRequired>{t('settings.phone_number')}</LabelRequired>}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} sx={{ height: '72px' }}>
                    <GooglAutocomplete
                      readOnly={currentModel?.sameAsDefault}
                      getAddressFields={(data) => getAddressFieldsHandle(data, 'businessInfo')}
                      isSignUp={true}
                      name="businessInfo.addressInfo.address"
                      initialValue={currentModel?.businessInfo.addressInfo?.address || ''}
                      inputValue={currentModel?.businessInfo.addressInfo?.address || ''}
                      label={<LabelRequired>{t('order_view.address')}</LabelRequired>}
                      placeholder=""
                      required={!currentModel.sameAsDefault}
                      formSubmitted={isSubmitted}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} sx={{ height: '72px' }}>
                    <CustomTextInput
                      disabled={currentModel?.sameAsDefault}
                      sx={{ width: '100%', opacity: addOpacityInInput(currentModel?.sameAsDefault) }}
                      defaultValue={currentModel?.businessInfo?.addressInfo?.address2 || ''}
                      name="businessInfo.addressInfo.address2"
                      label={t('order_view.address2')}
                    />
                  </Grid>
                  <Grid item md={4} xs={12} sx={{ height: '72px' }}>
                    <CustomTextInput
                      disabled={currentModel?.sameAsDefault}
                      sx={{ width: '100%', opacity: addOpacityInInput(currentModel?.sameAsDefault) }}
                      defaultValue={currentModel?.businessInfo?.addressInfo?.city || ''}
                      name="businessInfo.addressInfo.city"
                      label={<LabelRequired>{t('settings.city')}</LabelRequired>}
                    />
                  </Grid>
                  <Grid item md={4} xs={12} sx={{ height: '72px' }}>
                    <CustomTextInput
                      disabled={currentModel?.sameAsDefault}
                      sx={{ width: '100%', opacity: addOpacityInInput(currentModel?.sameAsDefault) }}
                      defaultValue={currentModel?.businessInfo?.addressInfo?.state || ''}
                      name="businessInfo.addressInfo.state"
                      label={<LabelRequired>{t('settings.state')}</LabelRequired>}
                    />
                  </Grid>
                  <Grid item md={4} xs={12} sx={{ height: '72px' }}>
                    <CustomTextInput
                      disabled={currentModel?.sameAsDefault}
                      sx={{ width: '100%', opacity: addOpacityInInput(currentModel?.sameAsDefault) }}
                      inputProps={{ type: 'number' }}
                      defaultValue={currentModel?.businessInfo?.addressInfo?.zipCode || ''}
                      name="businessInfo.addressInfo.zipCode"
                      label={<LabelRequired>{t('settings.zip_code')}</LabelRequired>}
                    />
                  </Grid>
                </>
              </>
            )}
            <Grid item xs={12}>
              <ActionButtons
                id="legaInfoTitle"
                title={handleCheckButtonTitle()}
                active={active}
                loading={loading}
                disabled={!businessInfoFormMethods.formState.isValid}
                externalPlanId={externalPlanId}
                externalToken={externalToken}
                corporateBusiness={corporateBusiness}
              />
            </Grid>
          </Grid>
        </>
      </form>
    </FormProvider>
  );
};

export default LegaInfo;
