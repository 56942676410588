import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Typography, Box } from '@mui/material';
import { Button } from 'reactstrap';
import { SET_IS_BEEPING, SET_PAYMENT_NOTIFICATION } from 'redux/actions/types';
import { useTranslation } from 'react-i18next';

import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
const lavels = {
  yellow: '#FFC94D',
  red: '#E03131',
  green: '#008000',
};

const PaymentNotificationDialog = () => {
  const { t } = useTranslation();
  const { data } = useSelector(({ paymentNotification }) => paymentNotification);
  const { message, title, period, showOkButton, level, paid } = data;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: SET_PAYMENT_NOTIFICATION, payload: { ...data, paid: true } });
    dispatch({ type: SET_IS_BEEPING, payload: false });
  };

  const warningLavel = useMemo(() => {
    return lavels[level];
  }, [level]);

  return (
    <Dialog
      open={!paid}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={() => {}}
      sx={{ zIndex: '9999 !important' }}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{ sx: { width: '500px', maxWidth: '100%', background: '#FAFAFA', cursor: 'grab' } }}
    >
      <DialogContent sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex' }}>
          <WarningRoundedIcon sx={{ fontSize: '50px', color: warningLavel }} />
          <Box sx={{ maxWidth: '555px', ml: 3, mt: 1 }}>
            <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }} variant="h4">
              {t(title)}
            </Typography>
            <Typography sx={{ fontSize: '18px', mt: 2 }}>{t(message)}</Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', p: 2, cursor: 'grab' }}>
        {showOkButton && (
          <Button id="paymentNotifivationModalOk" color="primary" variant="contained" onClick={handleClose}>
            {t('settings.ok')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PaymentNotificationDialog;
