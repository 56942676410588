import useProviderConfig from 'hooks/useProviderConfig';
import { useMemo } from 'react';

import { Pie } from 'react-chartjs-2';

const PieChart = ({ data }) => {
  const { providers } = useProviderConfig();
  const chartData = useMemo(() => {
    const keys = Object.keys(data);
    const colors = keys.map((key) => providers[key]?.options?.backgroundColor);
    if (providers)
      return {
        labels: keys.map((key) => providers[key]?.label),
        datasets: [
          {
            data: Object.values(data),
            backgroundColor: colors,
            hoverBackgroundColor: colors,
          },
        ],
      };
  }, [data, providers]);

  return <Pie data={chartData} options={options} />;
};
const options = {
  legend: {
    position: 'bottom',
  },
};

// const providers = {
//   website: {
//     label: 'Website',
//     color: '#DF7159',
//   },
//   postmates: {
//     label: 'Postmates',
//     color: '#36A2EB',
//   },
//   ubereats: {
//     label: 'UberEats',
//     color: '#78B94D',
//   },
//   doordash: {
//     label: 'Doordash',
//     color: '#FC3320',
//   },
//   grubhub: {
//     label: 'Grubhub',
//     color: 'rgba(75,192,192, 1)',
//   },
//   doocado: {
//     label: 'Doocado',
//     color: 'rgba(75,192,192, 1)',
//   },
//   deliverycom: {
//     label: 'Deliverycom',
//     color: '#2196f3',
//   },
//   grubhubv2: {
//     label: 'GrubHub v2',
//     color: '#219678',
//   },
//   table_ordering: {
//     label: 'Table Ordering',
//     color: '#F08E35',
//   },
//   ezcater: {
//     label: 'EzCater',
//     color: '#148e34',
//   },
//   fetchme: {
//     label: 'FetchMe',
//     color: '#999346',
//   },
//   food2: {
//     label: 'WebsiteV2',
//     color: '#999346',
//   },
//   grubhub_cartwheel: {
//     label: 'Grubhub',
//     color: 'rgba(75,192,192, 1)',
//   },

//   doordash_cartwheel: {
//     label: 'Doordash',
//     color: '#FC3320',
//   },
// };
export default PieChart;
