import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateWebsitePrepTime } from 'redux/actions/providers';
import { isAsapSwitcherTurnOff, isAsapSwitcherTurnOn } from 'redesign/BusinessTab/BusinessPrepTime/config';
import {
  prepTimeInitialProvider,
  prepTimeSchemaProvider,
} from 'redesign/BusinessTab/BusinessPrepTime/schema/prepTimeSchema';

const usePrepTimeWebsite = (providerPrepTime) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const prepTimeMethod = useForm({
    defaultValues: prepTimeInitialProvider,
    mode: 'all',
    resolver: yupResolver(prepTimeSchemaProvider),
  });

  const currentModel = prepTimeMethod.formState;

  const resetPrepTimeAsap = (currentModel) => {
    if (currentModel?.asap?.disableAsap) {
      const findPrepTimeSchedule = isAsapSwitcherTurnOn.find(
        (item) => item?.value === Number(providerPrepTime?.asap?.qty || 0)
      );
      return {
        orderBufferTime: {
          ...currentModel?.orderBufferTime,
        },
        asap: {
          ...currentModel.asap,
          qty: Number(findPrepTimeSchedule?.value) ?? isAsapSwitcherTurnOn[0]?.value,
        },
        prepTime: currentModel?.prepTime ?? '',
      };
    } else {
      const findPrepTimeOff = isAsapSwitcherTurnOff.find((item) => item?.value === Number(currentModel?.prepTime));
      return {
        orderBufferTime: {
          ...currentModel?.orderBufferTime,
        },
        asap: {
          ...currentModel.asap,
        },
        prepTime: findPrepTimeOff?.value ?? '',
      };
    }
  };

  useEffect(() => {
    if (currentModel?.isDirty) {
      setDisabled(false);
    }
  }, [currentModel]);

  useEffect(() => {
    if (providerPrepTime?.asap && providerPrepTime?.orderBufferTime) {
      const model = resetPrepTimeAsap(providerPrepTime);
      prepTimeMethod.reset(
        {
          ...model,
        },
        { keepDirty: false }
      );
    }
  }, [providerPrepTime]);

  const handleChangeAsap = (event) => {
    const currentModel = prepTimeMethod.getValues();
    setDisabled(false);
    const updatedAsapSettings = {
      orderBufferTime: {
        ...(currentModel?.orderBufferTime || currentModel?.orderBufferTime),
      },
      asap: {
        ...currentModel?.asap,
        disableAsap: event.target.checked,
      },
      prepTime: currentModel?.prepTime ?? '',
    };
    const model = resetPrepTimeAsap(updatedAsapSettings);
    prepTimeMethod.reset(
      {
        ...model,
      },
      { keepDirty: true }
    );
  };

  const handleDeliveryOrderBufferTime = (event) => {
    const currentModel = prepTimeMethod.getValues();
    setDisabled(false);
    prepTimeMethod.reset(
      {
        ...prepTimeMethod.getValues(),
        orderBufferTime: {
          ...prepTimeMethod?.getValues()?.orderBufferTime,
          deliveryOrderBufferTime: {
            startTimeMin: currentModel.orderBufferTime?.deliveryOrderBufferTime?.startTimeMin,
            endTimeMin: currentModel.orderBufferTime?.deliveryOrderBufferTime?.endTimeMin,
            enabled: event.target.checked,
          },
        },
      },
      { keepDirty: true }
    );
  };

  const handlePickUpOrderBufferTime = (event) => {
    const currentModel = prepTimeMethod.getValues();
    setDisabled(false);
    prepTimeMethod.reset(
      {
        ...prepTimeMethod.getValues(),
        orderBufferTime: {
          ...prepTimeMethod?.getValues()?.orderBufferTime,
          pickUpOrderBufferTime: {
            startTimeMin: currentModel?.orderBufferTime?.pickUpOrderBufferTime?.startTimeMin,
            endTimeMin: currentModel?.orderBufferTime?.pickUpOrderBufferTime?.endTimeMin,
            enabled: event.target.checked,
          },
        },
      },
      { keepDirty: true }
    );
  };

  const handleValidSubmit = (values) => {
    setLoading(true);
    values['defaultPrepTime'] = Number(values.prepTime);
    prepTimeMethod.reset(values, { keepDirty: false });
    delete values.prepTime;
    dispatch(updateWebsitePrepTime(values))
      .then(() => {
        setLoading(false);
        setDisabled(true);
        toast.success('Successfully updated.');
      })
      .finally(() => setLoading(false));
  };

  return {
    prepTimeMethod,
    handleValidSubmit,
    handleChangeAsap,
    handleDeliveryOrderBufferTime,
    handlePickUpOrderBufferTime,
    loading,
    disabled,
  };
};

export default usePrepTimeWebsite;
