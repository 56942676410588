import Box from '@mui/material/Box';
import useQuery from 'hooks/useQuery';
import CategoryForm from './CategoryForm/index';
import MenuEmpty from 'components/MenuNew/MenuFormEmpty';
import ProductForm from './ProductForm/index';

const FormView = () => {
  let query = useQuery();
  const entityType = query.get('entityType');

  const showCategory = entityType === 'category';
  // const showModifier = entityType === 'modifier';
  const showProduct = entityType === 'item' || entityType === 'modifier';

  if (!showCategory && !showProduct) return <MenuEmpty />;

  return (
    <Box sx={{ height: '100%' }}>
      {showCategory && <CategoryForm />}
      {/* {showModifier && <ModifierGroupForm />} */}
      {showProduct && <ProductForm />}
    </Box>
  );
};

export default FormView;
