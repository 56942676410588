import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox } from '@mui/material';
import useProviderConfig from 'hooks/useProviderConfig';
import {
  StyledCheckboxWrapperProvider,
  StyledCheckboxWrapperWithTitle,
  StyledList,
  StyledListItem,
  StyledListItemIcon,
  StyledListItemText,
  StyledProviderStatusSubTitle,
  StyledProviderStatusTitle,
} from 'components/PauseResume/ProviderStatusPopover/styles';
import { PauseResumeContext } from 'context/pauseResumeContext';

const PauseResumeSelectProvider = () => {
  const { t } = useTranslation();
  const { providers } = useProviderConfig();
  const {
    filteredProviders,
    selectAllObject,
    checkedProvider,
    selectAll,
    setCheckedProvider,
    setSelectAll,
  } = useContext(PauseResumeContext);

  const handleToggle = (value, providerStatusSupported) => () => {
    if (!providerStatusSupported) return;
    if (value === selectAllObject.provider) {
      if (!selectAll) {
        setSelectAll(true);
        const newChecked = [...checkedProvider];
        filteredProviders.forEach(({ provider }) => {
          if (providers[provider]?.providerStatusSupported) {
            newChecked.push(providers[provider]?.id);
          }
        });
        setCheckedProvider(newChecked);
      } else {
        setSelectAll(false);
        setCheckedProvider([]);
      }
    } else {
      const currentIndex = checkedProvider.indexOf(value);
      const newChecked = [...checkedProvider];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setSelectAll(newChecked.length === filteredProviders.length);
      setCheckedProvider(newChecked);
    }
  };

  return (
    <StyledCheckboxWrapperWithTitle>
      <StyledProviderStatusTitle>{t('pause_resume.operation_status_provider')}</StyledProviderStatusTitle>
      <StyledProviderStatusSubTitle>{t('pause_resume.multiple_status_provider')}</StyledProviderStatusSubTitle>
      <StyledCheckboxWrapperProvider>
        <StyledList>
          <StyledListItem disableGutters>
            <Box onClick={handleToggle(selectAllObject.provider, true)}>
              <StyledListItemIcon sx={{ minWidth: 'auto' }}>
                <Checkbox
                  edge="start"
                  disableRipple
                  indeterminate={!selectAll}
                  checked={selectAll}
                  inputProps={{ 'aria-labelledby': selectAllObject.provider }}
                />
              </StyledListItemIcon>
            </Box>
            <StyledListItemText primary={selectAllObject.label} />
          </StyledListItem>
          {filteredProviders.map(({ provider }, index) => {
            const { label, providerStatusSupported } = providers[provider];
            return (
              <Box key={`${index}-${label}`}>
                <StyledListItem disableGutters sx={{ opacity: providerStatusSupported ? 1 : 0.4 }}>
                  <Box onClick={handleToggle(providers[provider]?.id, providerStatusSupported)}>
                    <StyledListItemIcon sx={{ minWidth: 'auto' }}>
                      <Checkbox
                        disabled={!providerStatusSupported}
                        edge="start"
                        disableRipple
                        checked={checkedProvider.indexOf(providers[provider]?.id) !== -1}
                        inputProps={{ 'aria-labelledby': label }}
                      />
                    </StyledListItemIcon>
                  </Box>
                  <StyledListItemText primary={label} />
                </StyledListItem>
              </Box>
            );
          })}
        </StyledList>
      </StyledCheckboxWrapperProvider>
    </StyledCheckboxWrapperWithTitle>
  );
};
export default PauseResumeSelectProvider;
