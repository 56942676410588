import * as Statuses from 'constants/providerStatuses';

export const checkAccessDenied = (history) => {
  switch (history.location.pathname) {
    case '/settings':
      history.push('/orders');
      break;
    case '/webiste':
      history.push('/orders');
      break;
    case '/history':
      history.push('/orders');
      break;
    case '/menu':
      history.push('/orders');
      break;
    case '/reports':
      history.push('/orders');
      break;
    case '/providers':
      history.push('/orders');
      break;
    case '/schedule':
      history.push('/orders');
      break;
    default:
      break;
  }
};

export const isTableOrderingProvider = (providers) => {
  const tableOrdering = providers?.find((item) => item.srv === 'table_ordering');
  const isConnected =
    tableOrdering?.status === Statuses.PROVIDER_CONNECTED && tableOrdering?.state === Statuses.PROVIDER_STATE_ACTIVE;
  return isConnected;
};

export const isWebsiteConnected = (providers) => {
  const website = providers?.find((item) => item.srv === 'food2');
  const isConnected =
    website?.status === Statuses.PROVIDER_CONNECTED && website?.state === Statuses.PROVIDER_STATE_ACTIVE;
  return isConnected;
};
export function checkHasAnyConnectedProvider(providers) {
  return providers?.some(
    (provider) => provider?.status === Statuses.PROVIDER_CONNECTED && provider?.state === Statuses.PROVIDER_STATE_ACTIVE
  );
}
