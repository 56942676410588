import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateBusinessSettingsTO } from 'redux/actions/TableOrdering/settings';

const settingsDineInSchema = yup.object().shape({
  isEnable: yup.boolean(),
});

const settingsTakeOutSchema = yup.object().shape({
  isEnable: yup.boolean(),
});

const settingsDeliverySchema = yup.object().shape({
  isEnable: yup.boolean(),
});

export const SETTINGS_TABLE_OPTIONS = {
  DINE_IN: 'dine_in',
  TAKE_OUT: 'take_out',
  DELIVERY: 'delivery',
};

export const useTableOrderingSettings = () => {
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState(null);
  const { settings = {}, loadingSettings, loading } = useSelector(({ tableOrdering }) => tableOrdering);

  //Dine In
  const settingsDineIn = useForm({
    defaultValues: { isEnable: false },
    mode: 'all',
    resolver: yupResolver(settingsDineInSchema),
  });

  //Take Out
  const settingsTakeOut = useForm({
    defaultValues: { isEnable: false },
    mode: 'all',
    resolver: yupResolver(settingsTakeOutSchema),
  });

  //Delivery
  const settingsDelivery = useForm({
    defaultValues: { isEnable: false },
    mode: 'all',
    resolver: yupResolver(settingsDeliverySchema),
  });


  useEffect(() => {
    if (!settings?.serviceOptions?.length) return;
    const resetSettings = (setting, schema, resetFunc) => {
      resetFunc(
        {
          isEnable: setting?.configs?.isEnable ?? schema.isEnable,
        },
        { keepDirty: false }
      );
    };

    settings.serviceOptions.forEach((setting) => {
      switch (setting?.type) {
        case SETTINGS_TABLE_OPTIONS.DINE_IN:
          resetSettings(setting, settingsDineInSchema, settingsDineIn.reset);
          break;
        case SETTINGS_TABLE_OPTIONS.TAKE_OUT:
          resetSettings(setting, settingsTakeOut, settingsTakeOut.reset);
          break;
        case SETTINGS_TABLE_OPTIONS.DELIVERY:
          resetSettings(setting, settingsDelivery, settingsDelivery.reset);
          break;
        default:
          break;
      }
    });
  }, [settings]);

  const updateSettings = (configs, type) => {
    dispatch(updateBusinessSettingsTO(configs, type))
      .then(() => {}).finally(() =>  setSelectedOptions(null));
  };

  const handleTakeOutSubmit = () => {};

  const handleDeliverySubmit = () => {};

  const handleDineInSubmit = () => {};

  const handleDeliveryChange = (event) => {
    const configs = {
      isEnable: event.target.checked,
    };
    setSelectedOptions(SETTINGS_TABLE_OPTIONS.DELIVERY);
    updateSettings(configs, SETTINGS_TABLE_OPTIONS.DELIVERY);
  }

  const handleDineInChange = (event) => {
    const configs = {
      isEnable: event.target.checked,
    };
    setSelectedOptions(SETTINGS_TABLE_OPTIONS.DINE_IN);
    updateSettings(configs, SETTINGS_TABLE_OPTIONS.DINE_IN);
  }

  const handleTakeOutChange = (event) => {
    const configs = {
      isEnable: event.target.checked,
    };
    setSelectedOptions(SETTINGS_TABLE_OPTIONS.TAKE_OUT);
    updateSettings(configs, SETTINGS_TABLE_OPTIONS.TAKE_OUT);
  }

  return {
    settingsDineIn,
    handleDineInSubmit,
    settingsTakeOut,
    handleTakeOutSubmit,
    handleDeliverySubmit,
    settingsDelivery,
    loading,
    loadingSettings,
    selectedOptions,
    handleDeliveryChange,
    handleDineInChange,
    handleTakeOutChange,
  };
};
