import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ReadOnlyFooter = ({ saveTitle, cancelTitle, handleClose, handleSave }) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClose}
        sx={{
          mr: 1,
          color: '#626A7A',
          borderColor: '#626A7A',
          '&:hover': { color: '#626A7A', borderColor: '#626A7A', background: 'white' },
        }}
      >
        {t(cancelTitle)}
      </Button>
      <Button variant="contained" onClick={handleSave}>
        {t(saveTitle)}
      </Button>
    </>
  );
};
export default ReadOnlyFooter;
