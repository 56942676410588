import { memo } from 'react';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import LocalizationDialog from './LocalozationDialog';
import { useTranslation } from 'react-i18next';
import { StyledBoxLocInnerWrapper, StyledBoxLocWrapper } from './styled';
import { useSelector } from 'react-redux';

const LocalizationPopover = ({
  id,
  field = 'name',
  label,
  required = false,
  values,
  open,
  handleClose,
  languageLimit,
  currentSelectedField,
  productFormMethods = null,
  handleChangeField = null,
  isModifier = false,
  index = null,
  isSizeTypeModifier = null,
}) => {
  const { currentBusiness } = useSelector(({ business }) => business);
  const { t } = useTranslation();
  if (currentBusiness?.languages?.length < languageLimit) {
    return <Box />;
  }
  return (
    <StyledBoxLocWrapper>
      <StyledBoxLocInnerWrapper>
        <Icon
          sx={{
            fontSize: '14px',
          }}
          component={GTranslateIcon}
        />
      </StyledBoxLocInnerWrapper>
      {open && (
        <LocalizationDialog
          handleClose={handleClose}
          open={open}
          id={id}
          field={currentSelectedField}
          label={t(label)}
          required={required}
          values={values}
          productFormMethods={productFormMethods}
          handleChangeField={handleChangeField}
          isModifier={isModifier}
          index={index}
          isSizeTypeModifier={isSizeTypeModifier}
        />
      )}
    </StyledBoxLocWrapper>
  );
};

export default memo(LocalizationPopover);
