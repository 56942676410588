import React from 'react';
import 'date-fns';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import ReportTotal from 'pages/Report/components/ReportTotal';
import ExportButton from 'pages/Report/components/ExportButton';
import { ButtonsWrapper, ReportTypeTitle } from 'pages/Report/styles';
import useTableReport, { REPORT_TYPE_FOR_TOTAL_REPORT, styles } from 'pages/Report/hooks/useTableReport';

const TableReport = ({
  reportList,
  loadingExport,
  tableTypeName,
  currentReport,
  filterSubmitted,
  handleExport,
  loading,
  headerKeys,
}) => {
  const { t } = useTranslation();
  const {
    pageSize,
    setPageSize,
    transformTableType,
    headers,
    rows,
    totals,
    handleFilterChange
  } = useTableReport(reportList, headerKeys, currentReport, tableTypeName);

  if (!reportList.length) {
    return (
      <Box sx={{ my: 4 }}>
        <EmptyMenu text={filterSubmitted && !loading ? t('reports_settings.there_is_no_data_filters') : ''} />
      </Box>
    );
  }

  return (
    <Box className="report-list">
      <div className="mt-3" />
      <Box>
        <ButtonsWrapper>
          <ReportTypeTitle>{transformTableType}</ReportTypeTitle>
          <ExportButton
            loadingExport={loadingExport}
            handleExport={handleExport}
          />
        </ButtonsWrapper>
        <Box sx={{ ...styles }}>
          <DataGrid
            getRowId={() => uuidv4()}
            autoHeight={true}
            pageSize={pageSize}
            hideFooterSelectedRowCount={true}
            isEditable={false}
            columns={headers}
            rows={rows}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            onFilterModelChange={handleFilterChange}
            disableColumnMenu={false}
          />
        </Box>
        {REPORT_TYPE_FOR_TOTAL_REPORT.includes(transformTableType) && (
          <ReportTotal headers={headers} totals={totals} transformTableType={transformTableType} />
        )}
      </Box>
    </Box>
  );
};

export default TableReport;
