import instance, { gatewayInstance } from 'services/api';
import { ORDERS_FILTER_STATUS } from 'constants/config';
import {
  ORDERS_HISTORY,
  SET_ORDERS,
  SET_ORDERS_PAGINATION,
  SET_ORDERS_REPORT,
  UPDATE_MODIFIED_ORDERS,
  SET_DISABLED_ACTIONS,
  ORDERS_HISTORY_LOADING,
} from 'redux/actions/types';
import { toast } from 'react-toastify';

// Get Unconfirmed Order.
export const getUnconfirmedOrders = () => (dispatch) => {
  const status = ORDERS_FILTER_STATUS.unconfirmed.value;
  dispatch({ type: 'ORDERS_REQ', status });
  return instance.get(`/orders/list/${status}`).then(({ data: { data } }) => {
    const notifyLength = data.reduce((acc, { notify }) => (notify ? acc + 1 : acc), 0);
    dispatch({ type: 'ORDERS_RES', payload: data, status });
    return notifyLength;
  });
};

// Get Orders.
export const getOrders = () => (dispatch, getState) => {
  const {
    orders: { paginationConfig, params },
    providers: { providerConfig },
  } = getState();
  const { skip = 0, limit = 100 } = paginationConfig;
  const { status, start, end } = params;
  return instance
    .get(`/orders/v2/list/${status}`, { params: { start, end, skip } })
    .then(({ data: { data } }) => {
      const { orders, count } = data;
      const pageCount = Math.ceil(count / limit);
      orders.forEach((orderData) => {
        orderData.providerName = providerConfig.providers[orderData.provider].label.toLowerCase();
      });
      dispatch({ type: SET_ORDERS, payload: orders });
      dispatch({ type: SET_ORDERS_PAGINATION, payload: { count: pageCount } });
      return count;
    })
    .finally(() => {
      dispatch({ type: SET_DISABLED_ACTIONS, payload: false });
    });
};

export const orderChangedInProvider = (data) => (dispatch) => {
  dispatch({ type: UPDATE_MODIFIED_ORDERS, payload: data });
};

// Update Orders Status.
export const updateOrderStatus = (data) => (dispatch, getState) => {
  const {
    orders: { orderList },
  } = getState();
  const ordersListCopy = [...orderList];
  const newDataIndex = orderList.findIndex((item) => item.id === data.id);
  if (newDataIndex != -1) {
    ordersListCopy[newDataIndex] = data;
    dispatch({ type: SET_ORDERS, payload: ordersListCopy });
  }
};

// Get Reports.
export const getReports = (uuid, start, end, period, businessId) => {
  if (businessId) {
    instance.defaults.headers.business = businessId;
  }
  return instance.get(`/orders/reports/${uuid}`, { params: { start, end, period } });
};

// get Order.
export const getOrder = (id) => {
  return instance.get(`/orders/${id}`);
};

//reject order
export const rejectOrder = (id, provider, owner, body) => {
  return instance
    .put(`/orders/${provider}/order/cancel`, { id, ...body })
    .then((res) => {
      if (res?.data?.data.error) {
        toast.error(res?.data?.data?.message);
      } else {
        toast.success(res?.data?.message || 'Success');
      }
      return res;
    })
    .catch((err) => {
      console.log('====================================');
      console.log(err);
      console.log('====================================');
      toast.error(err?.response?.message || 'Oops! Something went wrong');
      return Promise.reject(err);
    });
};

// Put Change Order Status.
export const changeOrderStatus = (id, body) => (dispatch) => {
  dispatch({ type: SET_DISABLED_ACTIONS, payload: true });
  return instance
    .put(`/orders/v2/${id}`, body)
    .then((data) => data)
    .catch((err) => {
      if (err) {
        dispatch({ type: SET_DISABLED_ACTIONS, payload: false });
      }
    });
};

export const changeOrderStatusBySuperAdmin = (id, body) => {
  return instance.put(`orders/status/change/${id}`, body);
};

export const cancelDeliveryOrderDispatch = (deliveryId, data) => () => {
  return instance
    .post(`/delivery/cancel/dispatch/${deliveryId}`, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

function capitalizeWords(str) {
  // Split the string into an array of words
  const words = str.split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back into a string
  const result = capitalizedWords.join(' ');

  return result;
}

export const getOrdersHistory = (body, page, sizePageChange = false) => (dispatch, getState) => {
  const {
    orders: { orderHistoryList },
  } = getState();
    const request = JSON.parse(localStorage.getItem('requestData'));
    body.providers = request.providers;
    dispatch({ type: ORDERS_HISTORY_LOADING, payload: true });
    return instance
      .post(`/orders/history/v2`, body)
      .then(({ data: { data } }) => {
        dispatch({ type: ORDERS_HISTORY_LOADING, payload: false });
        const { orders, count } = data;
        if (sizePageChange) {
          return dispatch({
            type: ORDERS_HISTORY,
            payload: {
              data: {
                count: count,
                orders: orders,
              },
            },
          });
        }
        if (orderHistoryList.length / Number(page + 1) > body.limit) {
          return;
        } else {
          if (
            (Number(page + 1) > 1 && Number(page + 1) * Number(body.limit) <= orderHistoryList.length) ||
            orderHistoryList.length === count
          ) {
            return;
          }
          dispatch({
            type: ORDERS_HISTORY,
            payload: {
              data: {
                count: count,
                orders: !sizePageChange ? orderHistoryList.concat(orders) : orders,
              },
            },
          });
        }
        return data;
      })
      .catch((err) => {
        dispatch({ type: ORDERS_HISTORY_LOADING, payload: false });
        if (err) {
          toast.error(err?.response?.message);
        }
      });
  };

//  Put Confirm Order.
export const confirmOrder = (id, provider, owner, body) => {
  return gatewayInstance
    .post(`/${provider}/order/confirm/`, { id, ...body })
    .then((res) => res)
    .catch((err) => {
      if (err) {
        toast.error(err?.response?.message || 'Oops! Something went wrong');
      }
    });
};

// Get Print Order.
export const printOrder = (id, type) =>
  instance.get(`/orders/${id}/print`, { params: { type } }).then(({ data: { data } }) => data);

export const setShowReport = (value) => {
  return {
    type: SET_ORDERS_REPORT,
    payload: value,
  };
};

export const getDeliveryDetailsByOrderId = (orderId) => () => {
  return instance.get(`/orders/delivery-details/${orderId}`).catch((err) => {
    if (err && err?.response?.data?.message) {
      toast.error(err?.response?.data?.message);
    }
  });
};
