import { memo, useMemo } from 'react';
import packageInfo from '../../../package.json';
import { useTranslation } from 'react-i18next';

const PackageVersion = () => {
  const { t } = useTranslation();
  const loadPackageJson = useMemo(() => {
    return packageInfo.version;
  }, []);
  return (
    <>
      {t('Version')}&nbsp;
      {loadPackageJson}
    </>
  );
};
export default memo(PackageVersion);
