import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import CustomSwitch from 'components/Forms/CustomSwitch';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CustomTextInput from 'components/Forms/CustomTextInput';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import { useCustomerReactivation } from 'pages/WebsiteSettings/Rewards/hooks/useCustomerReactivation';
import { CUSTOMER_REWARDS_EXPIRATION_DATES, CUSTOMER_REWARDS_AMOUNTS } from 'pages/WebsiteSettings/Rewards/config';
import { Box, Card, CardActions, CardContent, Divider, InputAdornment, MenuItem, Tooltip, Button } from '@mui/material';

const CustomerRewards = () => {
  const { t } = useTranslation();
  const { handleValidSubmit, customerRewardsMethods, loading } = useCustomerReactivation();

  return (
    <Box sx={{ position: 'relative' }}>
      <ConfirmPromptModal
        handleConfirm={customerRewardsMethods.handleSubmit(handleValidSubmit)}
        submitData={customerRewardsMethods.getValues()}
        hasUnsavedChanges={customerRewardsMethods.formState.isDirty}
        formState={customerRewardsMethods}
      />
      <FormProvider {...customerRewardsMethods}>
        <form onSubmit={customerRewardsMethods.handleSubmit(handleValidSubmit)}>
          {loading && <Preloader overlay />}
          <Card>
            <CardContent sx={{ p: 0 }}>
              <Box
                sx={{
                  p: 3,
                  '& label': {
                    margin: 0,
                  },
                }}
              >
                <CustomSwitch
                  name="enabled"
                  label={
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      {t('Customer reactivation credits')}
                      <Tooltip sx={{ ml: 0.5 }} title={t(' Rewards to encourage customer re-engagement')}>
                        <HelpOutlineIcon fontSize={'small'} />
                      </Tooltip>
                    </Box>
                  }
                />
              </Box>
              <Divider />
              <Box sx={{ p: 3 }}>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    select={true}
                    name="marketingRewardAmount"
                    label={'Bonus amount *'}
                    helperText={t('The bonus customers receive for re-engaging.')}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  >
                    {CUSTOMER_REWARDS_AMOUNTS.map((option) => (
                      <MenuItem key={option._id} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </CustomTextInput>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    select={true}
                    name="marketingPointsValidityInDays"
                    label={'Expiration details *'}
                    helperText={t('Days until the reward expires after activation.')}
                  >
                    {CUSTOMER_REWARDS_EXPIRATION_DATES.map((option) => (
                      <MenuItem key={option._id} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </CustomTextInput>
                </Box>
              </Box>
            </CardContent>
            <CardActions sx={{ p: 3, justifyContent: 'flex-end' }}>
              <Button
                size="large"
                variant="contained"
                type="submit"
                disabled={!customerRewardsMethods.formState.isDirty}
              >
                {t('save')}
              </Button>
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CustomerRewards;
