import { useState } from 'react';
import { accountConfig, mode } from 'redesign/AccountTab/config';

export const useAccount = () => {
  const [sectionConfig, setSectionConfig] = useState(accountConfig);

  const handleEdit = (event, currentSection) => {
    setSectionConfig((prevState) => ({
      ...prevState,
      [currentSection]: {
        ...prevState[currentSection],
        hideEditButton: true,
        mode: mode.EDIT,
        expandedPanel: true,
      },
    }));
    event.stopPropagation();
  };

  const onCancel = (event, currentSection) => {
    setSectionConfig((prevState) => ({
      ...prevState,
      [currentSection]: {
        ...prevState[currentSection],
        hideEditButton: false,
        mode: mode.UPDATE,
      },
    }));
    event.stopPropagation();
  };

  return {
    onCancel,
    handleEdit,
    sectionConfig,
  };
};
