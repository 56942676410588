import { forwardRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Slide,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BusinessDialog({ title, description, icon, open, handleClose, isAdmin = false }) {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '538px',
            },
          },
        }}
      >
        <DialogTitle sx={{ pt: 3 }}>
          <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <img src={icon} alt="opt-description" />
            <Typography sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '32px', color: '#1D1B20' }}>
              {title}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '20px', color: '#49454F', ml: isAdmin ? 6.5 : 5.5 }}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ pt: 2, pb: 2, pl: 3, pr: 3 }}>
          <Button
            sx={{
              background: isAdmin ? '#2D7FF9' : 'primary',
              '&:hover': { background: isAdmin ? '#2D7FF9' : 'primary' },
              width: '54px',
            }}
            onClick={handleClose}
            variant="contained"
            color="primary"
          >
            {t('settings.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
