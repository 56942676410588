import { memo } from 'react';
import {
  StyledButton,
  StyledDialogFieldBox,
  StyledDialogFooterBox,
  StyledDialogFooterInnerBox,
  StyledDialogTitle,
  StyledSaveButton,
} from './styled';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import CircleIcon from '@mui/icons-material/Circle';
import CustomTextInput from 'components/Forms/CustomTextInput';
import {
  a11yProps,
  useLocalizationDialog,
} from 'components/LocalizationPopover/hooks/useLocalizationDialog';
import { Dialog, DialogContent, Divider, Typography, Box, Tabs, Tab } from '@mui/material';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import Icon from '@mui/material/Icon';

const LocalizationDialog = ({
  handleClose,
  open,
  id,
  field = 'name',
  label,
  required = false,
  values,
  productFormMethods,
  handleChangeField,
  isModifier,
  index,
  isSizeTypeModifier,
}) => {
  const { t } = useTranslation();
  const {
    onSubmit,
    handleChange,
    formMethods,
    loading,
    currentBusiness,
    value,
    translations,
    handleSubmitForm,
    styles,
    handleInvalidSubmit,
    handleTranslateByIA,
    privateInfo,
  } = useLocalizationDialog(
    field,
    required,
    id,
    values,
    productFormMethods,
    handleClose,
    handleChangeField,
    isModifier,
    index,
    isSizeTypeModifier
  );
  const CancelButton = () => {
    return (
      <StyledButton id="localozationDialogClose" onClick={handleClose} variant="outlined">
        {t('orders.cancel')}
      </StyledButton>
    );
  };
  return (
    <Box sx={{ position: 'relative' }}>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: styles }}
      >
        {loading && <Preloader overlay />}
        <StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {t('settings_advanced.translations')}
        </StyledDialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="icon label tabs"
            variant="scrollable"
            scrollButtons="true"
            sx={{
              px: 2,
              '& .MuiTabs-flexContainer': {
                display: 'flex',
              },
            }}
          >
            {translations.map((lngItem, index) => {
              return (
                <Tab
                  {...a11yProps(index)}
                  sx={{ position: 'relative' }}
                  label={
                    <Box>
                      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        {lngItem?.name || lngItem.lng}{' '}
                        <CircleIcon
                          sx={{
                            fontSize: '10px',
                            ml: 1,
                            color: (theme) =>
                              !lngItem.reviewer.userId && lngItem?.lng !== 'en'
                                ? theme.palette.warning.main
                                : theme.palette.success.main,
                          }}
                        />
                      </Typography>
                    </Box>
                  }
                />
              );
            })}
          </Tabs>
          <Divider sx={{ ml: 3, mr: 3 }} />

          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(handleSubmitForm, handleInvalidSubmit)}>
              <StyledDialogFieldBox>
                {field === 'description' || field === 'kitchenInstruction' ? (
                  <>
                    <CustomTextInput
                      name={field}
                      label={label}
                      InputProps={{
                        endAdornment: !privateInfo ? (
                          <Icon
                            onClick={handleTranslateByIA}
                            sx={{
                              fontSize: '14px',
                            }}
                            component={GTranslateIcon}
                          />
                        ) : null,
                      }}
                      helperText={
                        !privateInfo
                          ? 'You can click "Translate" in the text field for automatic  translation using AI.'
                          : ''
                      }
                      sx={{
                        width: '100%',
                      }}
                      multiline={true}
                      rows={6}
                      size="small"
                    />
                  </>
                ) : (
                  <CustomTextInput
                    InputProps={{
                      endAdornment: !privateInfo ? (
                        <Icon
                          onClick={handleTranslateByIA}
                          sx={{
                            fontSize: '14px',
                          }}
                          component={GTranslateIcon}
                        />
                      ) : null,
                    }}
                    helperText={
                      !privateInfo
                        ? 'You can click "Translate" in the text field for automatic  translation using AI.'
                        : ''
                    }
                    name={field}
                    size="small"
                    label={label}
                    sx={{ width: '100%' }}
                  />
                )}
              </StyledDialogFieldBox>
              <StyledDialogFooterBox>
                <Divider />
                <StyledDialogFooterInnerBox>
                  <CancelButton />
                  <StyledSaveButton id="localozationDialogSave" onClick={onSubmit} variant="contained">
                    {t('orders.save')}
                  </StyledSaveButton>
                </StyledDialogFooterInnerBox>
              </StyledDialogFooterBox>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default memo(LocalizationDialog);
