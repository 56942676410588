import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import Visibility from '@mui/icons-material/Visibility';
import ActionFooter from 'redesign/Shared/ActionFooter';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { InputMode, StylesSmall } from 'redesign/AccountTab/config';
import { Box, Grid, IconButton, InputAdornment } from '@mui/material';
import { useLoginInfo } from 'redesign/AccountTab/hooks/useLoginInfo';
import RealTimeValidation from 'redesign/AccountTab/RealTimeValidation';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import { ChangePassword, UserEmail, UserEmailName } from 'redesign/AccountTab/styles';

const LoginInfo = ({ disabled, id, onCancel, handleEdit }) => {
  const { t } = useTranslation();
  const {
    handleInvalidSubmit,
    handleSubmit,
    handleClickShowNewPassword,
    handleClickShowCurrentPassword,
    handleClickShowPassword,
    accountCredentials,
    userEmail,
    validation,
    showNewPassword,
    showCurrentPassword,
    showConfirmPassword,
    loading,
    updateHasError,
    oldPasswordIsValid,
  } = useLoginInfo(disabled);

  return (
    <Box sx={{ m: 3 }}>
      <ConfirmPromptModal
        handleConfirm={handleSubmit}
        submitData={accountCredentials.getValues()}
        hasUnsavedChanges={accountCredentials.formState.isDirty}
        formState={accountCredentials}
      />
      <FormProvider {...accountCredentials}>
        <form onSubmit={accountCredentials.handleSubmit(handleSubmit, handleInvalidSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <UserEmailName>{t('Email')}</UserEmailName>
              <UserEmail>{userEmail}</UserEmail>
            </Grid>
            <Grid item xs={4}>
              {disabled ? (
                <>
                  <UserEmailName sx={{ ml: '6px' }}>{t('Password')}</UserEmailName>
                  <ChangePassword variant={'text'} onClick={(event) => handleEdit(event, id)}>
                    {t('Change password')}
                  </ChangePassword>
                </>
              ) : (
                <Box sx={{ mt: 2 }}>
                  <CustomTextInput
                    sx={StylesSmall}
                    InputProps={{
                      type: showCurrentPassword ? 'text' : 'password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCurrentPassword}
                            edge="end"
                          >
                            {showCurrentPassword ? (
                              <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                            ) : (
                              <Visibility sx={{ fontSize: '1.2rem' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    name="oldPassword"
                    label={<LabelRequired>{t('Current Password')} </LabelRequired>}
                    disabled={disabled}
                    variant={InputMode.outlined}
                    id={InputMode.outlined_Basic}
                  />
                  <Box>
                    <CustomTextInput
                      sx={StylesSmall}
                      InputProps={{
                        type: showNewPassword ? 'text' : 'password',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              edge="end"
                            >
                              {showNewPassword ? (
                                <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                              ) : (
                                <Visibility sx={{ fontSize: '1.2rem' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name="password"
                      label={<LabelRequired>{t('New Password')}</LabelRequired>}
                      disabled={disabled}
                      variant={InputMode.outlined}
                      id={InputMode.outlined_Basic}
                    />
                  </Box>
                  <Box>
                    <CustomTextInput
                      style={{ paddingTop: '32px' }}
                      sx={StylesSmall}
                      InputProps={{
                        type: showConfirmPassword ? 'text' : 'password',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                              ) : (
                                <Visibility sx={{ fontSize: '1.2rem' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name="confirmPassword"
                      label={<LabelRequired>{t('Confirm Password')}</LabelRequired>}
                      disabled={disabled}
                      variant={InputMode.outlined}
                      id={InputMode.outlined_Basic}
                    />
                  </Box>
                  <RealTimeValidation validation={validation} />
                </Box>
              )}
            </Grid>
            {!disabled && (
              <Grid item xs={12}>
                <ActionFooter
                  id={id}
                  onCancel={onCancel}
                  loading={loading}
                  disabled={
                    (!accountCredentials.formState.isValid && !accountCredentials.formState.isDirty) ||
                    loading ||
                    updateHasError() ||
                    !oldPasswordIsValid
                  }
                />
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};
export default memo(LoginInfo);
