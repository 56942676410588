import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import NotAvailableImage from 'assets/icons/chargeBack/notAvaliable.png';
import {
  NotAvailableBlock,
  NotAvailableContent,
  NotBlock,
  NotOrderNumberContent,
} from 'components/Chargeback/Modal/styles';
import { useSelector } from 'react-redux';

const EmptyOrder = () => {
  const { t } = useTranslation();
  const { chargeBack } = useSelector((store) => store);
  const { selected } = chargeBack;

  return (
    <Box sx={{ mt: 2, pt: 2 }}>
      <NotAvailableBlock>
        {selected?.orderNumber && (
          <NotOrderNumberContent>
            {t('chargeBack.headerTable.order')} {`#${selected?.orderNumber ?? ''}`}
          </NotOrderNumberContent>
        )}
        <NotBlock>
          <img src={NotAvailableImage} alt="not avaliable" />
          <NotAvailableContent>{t('chargeBack.information_is_not_available')}</NotAvailableContent>
        </NotBlock>
      </NotAvailableBlock>
    </Box>
  );
};
export default EmptyOrder;
