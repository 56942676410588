import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Pickup from 'pages/Providers/Pickup';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import Delivery from 'pages/Providers/Delivery';
import IconButton from '@mui/material/IconButton';
import PhoneImage from 'assets/images/PhoneIcon.png';
import { yupResolver } from '@hookform/resolvers/yup';
import useProviderConfig from 'hooks/useProviderConfig';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom';
import { useAutoConfirmModal } from 'hooks/useAutoConfirmModal';
import AccordionSection from 'pages/Providers/AccordionSection';
import { useProviderSettings } from 'hooks/useProviderSettings';
import ProviderCredential from 'pages/Providers/ProviderCredential';
import { Typography, Box, Paper, Grid, Divider } from '@mui/material';
import { PROVIDER_LIST } from 'components/NewHome/constants/constatnts';
import { invitationFormSchema } from 'pages/Providers/schemaServiceFee';
import ConfirmProviderAction from 'components/Confirm/ConfirmProviderAction';
import { getBusinessSettingsTO } from 'redux/actions/TableOrdering/settings';
import DineInAutoConfirm from 'pages/Providers/TableOrdering/DineInAutoConfirm';
import TakeOutAutoConfirm from 'pages/Providers/TableOrdering/TakeOutAutoConfirm';
import { useProviderConfirm } from 'components/Providers/hooks/useProviderConfirm';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import { ProviderStatusTitle } from 'components/PauseResume/styles';
import PrepTimeWebsite from 'pages/Providers/ProviderPrepTime/PrepTimeWebsite';
import PrepTimeAllProviders from 'pages/Providers/ProviderPrepTime/PrepTimeAllProviders';
import ResumePauseButtonForProvider from 'components/PauseResume/ResumePauseButtonForProvider';
import DeliveryAutoConfirm from 'pages/Providers/TableOrdering/DeliveryAutoConfirm';
import ProviderAutoConfirmSettings from 'pages/Providers/ProviderAutoConfirmSettings';

const ProviderSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const data = location?.state?.data;
  const { loading, items, providersSettings } = useSelector(({ providers }) => providers);
  const { providers } = useProviderConfig();
  const { deliveryAutoConfirm, pickUpAutoConfirm, setPickUpAutoConfirm, setDeliveryAutoConfirm } = useProviderConfirm(
    data,
    items
  );
  const { currentBusiness } = useSelector(({ business }) => business);
  const [credentialTablet, setCredentialTablet] = useState({});
  const formMethods = useForm({
    mode: 'all',
    defaultValues: providersSettings[location?.state?.data?.srv]?.feeInfo || { enabled: false },
    resolver: yupResolver(invitationFormSchema),
  });
  const { isTypeSingle } = isPermissionWithCompanyType();
  const { handleOpenAutoConfirmModal, openAutoConfirmModal, handleCloseAutoConfirmModal } = useAutoConfirmModal();

  const {
    isDisableAutoConfirm,
    handleConfirmOrderChange,
    // showConfirmButton,
    id,
    loadingAutoConfirm,
  } = useProviderSettings(
    formMethods,
    'all',
    deliveryAutoConfirm,
    pickUpAutoConfirm,
    setPickUpAutoConfirm,
    setDeliveryAutoConfirm
  );

  useEffect(() => {
    if (currentBusiness?._id && providers[data?.srv]?.srv === PROVIDER_LIST.tableOrdering) {
      dispatch(getBusinessSettingsTO());
    }
  }, [currentBusiness?._id, providers, data?.srv]);

  useEffect(() => {
    if (location?.state?.data && location?.state?.id) {
      items.forEach((provider) => {
        if (provider?.srv === providers.doordash.srv) {
          setCredentialTablet(provider?.tabletAuth || {});
        }
      });
    } else {
      Object.keys(providers).forEach((item) => {
        if (location.pathname.includes(item)) {
          if (items.length > 0) {
            items.forEach((provider) => {
              if (provider?.srv === item) {
                setCredentialTablet(provider?.tabletAuth || {});
              }
            });
          }
        }
      });
    }
  }, [location?.state]);

  const findProviders = useMemo(() => {
    return items.find((item) => {
      return item?.srv === data?.srv;
    });
  }, [items, data?.srv]);

  return (
    <>
      {(loading || loadingAutoConfirm) && <Preloader className="provider-settings-loading" overlay={true} />}
      <Paper sx={{ mb: 3 }}>
        <Box sx={{ my: 2, display: 'flex', pt: 2 }}>
          <IconButton aria-label="delete" size="small" sx={{ mr: 2 }}>
            <ArrowBackIcon onClick={() => history.goBack()} />
          </IconButton>
          <Typography variant="h5">{providers[data?.srv]?.label}</Typography>
        </Box>
        <Divider />
        <ConfirmProviderAction
          handleClose={handleCloseAutoConfirmModal}
          showModal={openAutoConfirmModal}
          title={'website_settings.auto_confirm'}
          description={'website_settings.auto_confirm_desc'}
          label={''}
          icon={PhoneImage}
          iconName={'support phone number'}
          phoneNumber={'website_settings.phoneNumber'}
        />
      </Paper>
      <Grid container sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Grid item xs={12}>
          {isTypeSingle && (
            <>
              <ProviderStatusTitle>{t('providers_settings.provider_status')}</ProviderStatusTitle>
              <ResumePauseButtonForProvider
                provider={providers[data?.srv]?.srv}
                disabled={!providers[data?.srv]?.providerStatusSupported}
              />
            </>
          )}
        </Grid>
        <Grid item my={3} xs={12}>
          <AccordionSection title={t(`Preparation Time`)}>
            {data?.srv !== PROVIDER_LIST.food2 ? (
              <PrepTimeAllProviders providerPrepTime={findProviders?.prepTime} srv={providers[data?.srv]?.srv} />
            ) : (
              <PrepTimeWebsite providerPrepTime={findProviders?.prepTime} />
            )}
          </AccordionSection>
        </Grid>
        {data?.storId ? (
          <Grid item my={3} xs={12}>
            <AccordionSection title={t(`providers_settings.store_id`)}>
              <Typography
                sx={{
                  padding: '16px',
                }}
              >
                {data?.storId}
              </Typography>
            </AccordionSection>
          </Grid>
        ) : (
          <></>
        )}
        <Box width="100%">
          <Grid item mb={3} xs={12}>
            <AccordionSection title={t(`providers_settings.pick_up`)}>
              {providers[data?.srv]?.srv !== PROVIDER_LIST.tableOrdering ? (
                <>
                  <ProviderAutoConfirmSettings
                    isDisableAutoConfirm={isDisableAutoConfirm}
                    handleOpenAutoConfirmModal={handleOpenAutoConfirmModal}
                    id={id}
                    checked={pickUpAutoConfirm}
                    handleConfirmOrderChange={handleConfirmOrderChange}
                    type={'pick-up'}
                  />
                  <Divider />
                </>
              ) : (
                <></>
              )}
              <Pickup />
            </AccordionSection>
          </Grid>
          <Grid item mb={3} xs={12}>
            <AccordionSection title={t(`providers_settings.delivery`)}>
              {providers[data?.srv]?.srv !== PROVIDER_LIST.tableOrdering ? (
                <>
                  <ProviderAutoConfirmSettings
                    isDisableAutoConfirm={isDisableAutoConfirm}
                    handleOpenAutoConfirmModal={handleOpenAutoConfirmModal}
                    id={id}
                    checked={deliveryAutoConfirm}
                    handleConfirmOrderChange={handleConfirmOrderChange}
                    type={'delivery'}
                  />
                  <Divider />
                </>
              ) : (
               <>
                 <DeliveryAutoConfirm
                   items={items}
                   srv={providers[data?.srv]?.srv}
                   isDisableAutoConfirm={isDisableAutoConfirm}
                   handleOpenAutoConfirmModal={handleOpenAutoConfirmModal}
                   type={'delivery'}
                 />
                 <Divider />
               </>
              )}
              <Delivery />
            </AccordionSection>
          </Grid>
          {providers[data?.srv]?.srv === PROVIDER_LIST.tableOrdering && (
            <Grid item mb={3} xs={12}>
              <AccordionSection title={t(`Dine In`)}>
                <DineInAutoConfirm
                  items={items}
                  srv={providers[data?.srv]?.srv}
                  isDisableAutoConfirm={isDisableAutoConfirm}
                  handleOpenAutoConfirmModal={handleOpenAutoConfirmModal}
                  type={'dine in'}
                />
              </AccordionSection>
            </Grid>
          )}
          {providers[data?.srv]?.srv === PROVIDER_LIST.tableOrdering && (
            <Grid item mb={3} xs={12} sx={{ mt: 2 }}>
              <AccordionSection title={t(`Take Out`)}>
                <TakeOutAutoConfirm
                  items={items}
                  srv={providers[data?.srv]?.srv}
                  isDisableAutoConfirm={isDisableAutoConfirm}
                  handleOpenAutoConfirmModal={handleOpenAutoConfirmModal}
                  type={'take out'}
                />
              </AccordionSection>
            </Grid>
          )}
        </Box>
        {data?.srv === providers.doordash.srv && currentBusiness?.roles?.name === 'super' ? (
          <Grid item mb={3} xs={12}>
            <AccordionSection title={t('providers_settings.tablet_credential')}>
              <ProviderCredential credential={credentialTablet} provider={data} />
            </AccordionSection>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default ProviderSettings;
