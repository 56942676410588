import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';
import { useIsWidthDown } from 'hooks/mediaQuery';
export const routes = [
  { id: 'TABLE_ORDERING_TABLES', plan: '', path: '/tables', name: 'Tables' },
  { id: 'TABLE_ORDERING_SECTIONS', plan: '', path: '/sections', name: ' Sections' },
];
const TabsTable = () => {
  const history = useHistory();
  const mdDown = useIsWidthDown('md');
  const [value, setValue] = useState(history.location.pathname);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(newValue);
  };

  return (
    <Hidden mdDown={mdDown}>
      <Box sx={{ width: '800px' }}>
        <Box sx={{ width: '100%' }}>
          <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: '#2D7FF9',
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            selectionFollowsFocus
            value={value}
            onChange={handleChange}
          >
            {routes.map(({ id, path, name }) => (
              <Tab
                sx={{
                  '&.Mui-selected': { color: '#2D7FF9' },
                }}
                key={id || path}
                value={`/table-ordering${path}`}
                label={name}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </Hidden>
  );
};
export default TabsTable;
