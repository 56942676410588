import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateProviderPrepTime } from 'redux/actions/providers';

export const prepTimeSchema = yup.object().shape({
  prepTime: yup.string(),
});

const prepTimeInitial = {
  prepTime: 0,
};

const usePrepTimeAllProviders = (providerPrepTime, srv) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hasDirty, setHasDirty] = useState(false);
  const prepTimeMethod = useForm({
    defaultValues: prepTimeInitial,
    mode: 'all',
    resolver: yupResolver(prepTimeSchema),
  });

  useEffect(() => {
    if (providerPrepTime) {
      prepTimeMethod.reset({
        prepTime: providerPrepTime?.prepTime || 0,
      });
    }
  }, [providerPrepTime]);

  const handleValidSubmit = (values) => {
    setLoading(true);
    values['defaultPrepTime'] = Number(values.prepTime);
    delete values.prepTime;
    dispatch(updateProviderPrepTime(values, srv))
      .then(() => {
        setLoading(false);
        setHasDirty(false);
        toast.success('Successfully updated.');
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (event) => {
    prepTimeMethod.reset(
      {
        prepTime: event.target.value,
      },
      { keepDirty: true }
    );
    setHasDirty(true);
  };
  return {
    prepTimeMethod,
    handleValidSubmit,
    loading,
    handleChange,
    hasDirty,
  };
};

export default usePrepTimeAllProviders;
