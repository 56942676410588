import Autocomplete from '@mui/material/Autocomplete';

import { Box, ListItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getAvailablaLanguages } from 'redux/actions/business';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const LanguageSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});

  const handleChange = (value) => {
    setSelectedValue(value);
  };
  useEffect(() => {
    dispatch(getAvailablaLanguages()).then(({ data }) => {
      setLanguages(data.data);
    });
  }, []);
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={languages}
      getOptionLabel={(option) => `${option.name}`}
      renderOption={(props, option, state) => (
        <ListItem {...props}>
          {option.image.url && (
            <Box sx={{ width: '32px', mr: 2 }}>
              <img style={{ width: '100%' }} src={option.image.url} alt={option.name} />
            </Box>
          )}
          {`${option.name} (${option.code})`}
        </ListItem>
      )}
      // getOptionDisabled={(option) => option === timeSlots[0] || option === timeSlots[2]}
      renderInput={(params) => (
        <TextField
          error={submitted && !selectedValue?._id}
          helperText={!selectedValue?._id && submitted ? t('help.field_is_required') : null}
          {...params}
          label={t('settings_advanced.languages')}
        />
      )}
      onChange={handleChange}
    />
  );
};
export default LanguageSettings;
