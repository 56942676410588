import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemText, ListItemIcon, Typography, Box, Button } from '@mui/material';

export const StyledList = styled(List)(({ theme }) => {
  return {
    width: '100%',
  };
});

export const StyledListItem = styled(ListItem)(({ theme }) => {
  return {
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '450px',
  };
});

export const StyledButtonBox = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(1, 2, 2),
    width: '100%',
    cursor: 'pointer',
  };
});

export const StyledButton = styled(Button)(({ theme }) => {
  return {
    width: '100%',
    color: '#626A7A',
    borderColor: '#626A7A',
    cursor: 'pointer',
    '&:hover': {
      color: '#626A7A',
      borderColor: '#626A7A',
      cursor: 'pointer',
    },
  };
});

export const StyledListItemText = styled(ListItemText)(({ theme }) => {
  return {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  };
});

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  };
});

export const StyledProviderTitle = styled(Typography)(({ theme }) => {
  return {
    color: 'rgba(15, 18, 20, 1)',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  };
});

export const StyledCheckboxWrapperProvider = styled(Box)(({ theme }) => {
  return {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    width: '304px',
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(4),
  };
});

export const StyledCheckboxWrapperWithTitle = styled(Box)(({ theme }) => {
  return {};
});

export const StyledProviderStatusTitle = styled(Typography)(({ theme }) => {
  return {
    color: 'rgba(15, 18, 20, 1)',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    marginBottom: theme.spacing(4),
  };
});

export const StyledProviderStatusSubTitle = styled(Typography)(({ theme }) => {
  return {
    color: 'rgba(15, 18, 20, 1)',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginBottom: theme.spacing(2),
  };
});
