export const companyTypes = [
  { label: 'single_location_', value: 'single' },
  { label: 'corporate_', value: 'corporate' },
  { label: 'franchise_', value: 'franchise' },
  { label: 'virtual_kitchen_', value: 'virtual' },
];

export const companyTypesEnum = {
  SINGLE: 'single',
  CORPORATE: 'corporate',
  FRANCHISE: 'franchise',
  VIRTUAL_KITCHEN: 'virtual',
  BRAND: 'brand',
};
