import { useState } from 'react';
import Grid from '@mui/material/Grid';
import LoyaltyRewards from './LoyaltyRewards';
import SignupRewards from './SignupRewards';
import OrderRewards from './OrderRewards';
import CustomerRewards from './CustomerRewards';

const Rewards = () => {
  const [rewardEnabled, setRewardEnabled] = useState(false);
  return (
    <Grid container spacing={3}>
      <Grid item md={12} sm={12} lg={7}>
        <LoyaltyRewards setRewardEnabled={setRewardEnabled} />
      </Grid>
      {rewardEnabled && (
        <>
          <Grid item md={12} sm={12} lg={7}>
            <OrderRewards rewardEnabled={rewardEnabled} />
          </Grid>
          <Grid item md={12} sm={12} lg={7}>
            <SignupRewards rewardEnabled={rewardEnabled} />
          </Grid>
          <Grid item md={12} sm={12} lg={7}>
            <CustomerRewards />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Rewards;
