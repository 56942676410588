import { useEffect, useState, memo } from 'react';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { patchProductSoldOutById } from 'redux/actions/Menu/products';
import { patchCategorySoldOutById } from 'redux/actions/Menu/categories';
import { availabilityOptions } from 'constants/availabilityOptions';
import { confirm } from 'components/MenuNew/ConfirmModal';
import { SOLD_OUT_STATUS } from 'constants/enumSoldOut';
import { useTranslation } from 'react-i18next';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
};

const UpdateAvailability = (props) => {
  const { t } = useTranslation();

  const { isOpen, register, setIsOpen = () => {}, availability, title, dispatch, formProduct, reset } = props;
  const [value, setValue] = useState('');
  const [isDisable, setIsDisable] = useState(true);
  const { onBlur, name } = register('soldOut');

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (e) => {
    setIsDisable(false);
    setValue(e.target.value);
  };

  useEffect(() => {
    if (availability.soldOut?.status) {
      availabilityOptions.map((item) => {
        if (availability.soldOut.status === item.value) {
          setValue(item.value);
        }
      });
    }
  }, [availability.soldOut?.status]);

  const handleClickOpenConfirm = async () => {
    setIsDisable(true);
    setIsOpen(false);
    if (reset) {
      reset(formProduct, { keepDirty: false });
    }
    const confirmed = await confirm({
      title: 'confirm_modal.update_availability',
      message: `${t('menu.are_you_sure_change')} ${title} ${t('menu.availability_')} `,
      cancelText: t('order_view.cancel'),
      confirmText: t('menu.yes_change'),
      hideCloseIcon: true,
    });
    if (confirmed) {
      switch (value) {
        case SOLD_OUT_STATUS.SOLD_OUT_TODAY:
          availability.soldOut = {
            status: SOLD_OUT_STATUS.SOLD_OUT_TODAY,
          };
          break;
        case SOLD_OUT_STATUS.SOLD_OUT_PERMANENT:
          availability.soldOut = {
            status: SOLD_OUT_STATUS.SOLD_OUT_PERMANENT,
          };
          break;
        case SOLD_OUT_STATUS.AVAILABILITY:
          availability.soldOut = {
            status: SOLD_OUT_STATUS.AVAILABILITY,
          };
          break;
      }
      switch (title) {
        case 'category':
          dispatch(patchCategorySoldOutById(availability._id, availability.soldOut));
          break;
        case 'item':
          dispatch(patchProductSoldOutById(availability._id, availability.soldOut));
          break;
      }
    } else {
      if (formProduct) {
        setValue(formProduct.soldOut?.status);
      } else {
        setValue(availability?.soldOut?.status);
      }
    }
  };

  const MUIDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} PaperComponent={DraggableDialogWrapper}>
        <MUIDialogTitle onClose={handleClose} sx={{ cursor: 'grab' }}>
          {t('website_settings.update')} {title} {t('menu.availability')}
        </MUIDialogTitle>
        <DialogContent
          dividers
          sx={{
            padding: '15px 20px 5px 24px',
          }}
        >
          <RadioGroup name={name} onBlur={onBlur} value={value} onChange={handleChange}>
            {availabilityOptions.map((item) => {
              return (
                <FormControlLabel key={item.label} value={t(item.value)} control={<Radio />} label={t(item.label)} />
              );
            })}
          </RadioGroup>
        </DialogContent>
        <DialogActions sx={{ cursor: 'grab' }}>
          <Button
            id="updateAvailabilityDone"
            disabled={isDisable}
            sx={{ width: '100%', cursor: 'pointer' }}
            variant="contained"
            onClick={handleClickOpenConfirm}
          >
            {t('menu.done')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(UpdateAvailability);
