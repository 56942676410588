import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import CustomTextInput from 'components/Forms/CustomTextInput';
import usePrepTimeWebsite from 'pages/Providers/ProviderPrepTime/hooks/usePrepTimeWebsite';
import { Divider, FormControlLabel, FormGroup, Grid, MenuItem, Switch } from '@mui/material';
import PickUpBufferForProvider from 'pages/Providers/ProviderPrepTime/PickUpBufferForProvider';
import DeliveryBufferForProvider from 'pages/Providers/ProviderPrepTime/DeliveryBufferForProvider';
import { isAsapSwitcherTurnOff, isAsapSwitcherTurnOn } from 'redesign/BusinessTab/BusinessPrepTime/config';

const PrepTimeWebsite = ({ providerPrepTime }) => {
  const { t } = useTranslation();
  const {
    prepTimeMethod,
    handleValidSubmit,
    handleChangeAsap,
    handleDeliveryOrderBufferTime,
    handlePickUpOrderBufferTime,
    loading,
    disabled,
  } = usePrepTimeWebsite(providerPrepTime);

  return (
    <FormProvider {...prepTimeMethod}>
      <form onSubmit={prepTimeMethod.handleSubmit(handleValidSubmit)}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ pl: 2 }}>
            <FormGroup>
              <FormControlLabel
                sx={{ width: 'max-content' }}
                control={
                  <Switch
                    onChange={(event) => handleChangeAsap(event)}
                    checked={prepTimeMethod.getValues('asap.disableAsap')}
                  />
                }
                label={t('settings.disabled_asap')}
              />
            </FormGroup>
          </Grid>
          {prepTimeMethod.getValues('asap.disableAsap') && (
            <Grid item xs={12} sx={{ pl: 2 }}>
              <CustomTextInput
                sx={{ width: '100%', maxWidth: '280px', mt: 2, mb: 2 }}
                select={true}
                name={'asap.qty'}
                label={t('settings.preparation_time')}
              >
                {isAsapSwitcherTurnOn.map((option) => {
                  return (
                    <MenuItem key={option.key} value={option.value}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </CustomTextInput>
            </Grid>
          )}
          {!prepTimeMethod.getValues('asap.disableAsap') && (
            <Grid item xs={12} sx={{ pl: 2 }}>
              <CustomTextInput
                sx={{ width: '100%', maxWidth: '280px', mt: 2, mb: 2 }}
                select={true}
                name="prepTime"
                label={t('settings.preparation_time')}
              >
                {isAsapSwitcherTurnOff.map((option) => {
                  return (
                    <MenuItem key={option.key} value={option.value}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </CustomTextInput>
            </Grid>
          )}
          <Grid item xs={12} sx={{ pt: '8px !important', pb: 0 }}>
            <Divider sx={{ width: '100%', height: '1px' }} />
          </Grid>
          <DeliveryBufferForProvider
            prepTimeMethod={prepTimeMethod}
            handleDeliveryOrderBufferTime={handleDeliveryOrderBufferTime}
          />
          <Grid item xs={12} sx={{ pt: '8px !important', pb: 0, mt: 1.5 }}>
            <Divider sx={{ width: '100%', height: '1px' }} />
          </Grid>
          <PickUpBufferForProvider
            prepTimeMethod={prepTimeMethod}
            handlePickUpOrderBufferTime={handlePickUpOrderBufferTime}
          />
          <Grid item xs={12} sx={{ mr: 3, mt: 3, mb: 2, textAlign: 'end', width: '100%' }}>
            <LoadingButton variant="contained" type="submit" loading={loading} disabled={loading || disabled}>
              {t('Save')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default memo(PrepTimeWebsite);
