import { Box, Divider } from '@mui/material';
const ReportHeader = (props) => {
  const { title, subtitle, description } = props;
  return (
    <Box>
      <h2
        style={{
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '20px',
          color: '#0F1214',
          letterSpacing: '0.25px',
          margin: 0,
        }}
      >
        {title}
      </h2>
      <b style={{ color: '#000' }}>{subtitle}</b>
      <Divider sx={{ margin: '16px 0' }} />
    </Box>
  );
};
export default ReportHeader;
