import styled from '@emotion/styled';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

export const DialogTitleWrap = styled(DialogTitle)(() => ({
  color: '#3C4350',
  fontSize: 32,
  lineHeight: '32px',
  fontWeight: 500,
  textAlign: 'center',
  marginBottom: 32,
  marginTop: 16,
}));

export const DialogContentWrap = styled(DialogContent)(() => ({
  p: {
    color: '#3C4350',
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: '0.25px',
    textAlign: 'center',
    marginBottom: 32,
  },
  borderBottom: '1px solid rgb(0 0 0 / 12%)',
  minHeight: 200,
}));

export const DialogActionsWrap = styled(DialogActions)(() => ({
  justifyContent: 'center',
  padding: 25,
}));

export const DialogActionsButtonWrap = styled(Button)(() => ({
  '&#cancel': {
    color: '#3C4350',
  },
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '18.75px',
  padding: '14px 30px',
}));

export const DialogTitleBlock = styled(DialogTitle)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(4),
  '& > img': {
    width: '68px',
    height: '68px',
  }
}));

export const MainText = styled(Typography)(() => ({}));
export const SecondText = styled(Typography)(() => ({}));
