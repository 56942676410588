import store from 'store';
import { deleteProductSocket, getCategoryProductsV2, createProductSocket } from 'redux/actions/Menu/products';
import { SOCKET_EVENT_TYPES } from 'constants/sockets';
import {
  createCategory,
  updateCategory,
  deleteCategory,
  setCategoryFromSocket,
  sortCategory,
} from 'redux/actions/Menu/categories';
// import { getProductsAll } from 'redux/actions/Menu/products';
import { getProviders, getProvidersStatuses } from 'redux/actions/providers';
import { getOrders, orderChangedInProvider } from './actions/orders';
import { updateUserSession } from 'redux/actions/user';
import { getMenusV2, setMenuFromSocket } from 'redux/actions/Menu/menuV2';
import { setProductsFromSocket, updateProductSocket } from 'redux/actions/Menu/products';
import { handleUpdatePinCodeData } from 'redux/actions/business';
// import { getModifiersAll } from 'redux/actions/Menu/modifiers';
import {
  ORDERS_PUSH,
  SET_LAST_UPDATED_ORDER,
  RESET_ORDERS_PAGINATION,
  UPDATE_CANCELED_ORDERS,
  SET_LAST_CANCELED_ORDER,
} from 'redux/actions/types';
import { handleProviderConnectData } from 'redux/actions/providers';
import { addInModifiersAll, createModifier, updateModifierById } from './actions/Menu/modifiers';
import { callWaitStaff, handleUpdatePaymentNotification } from './actions/paymentNotification';
import { ORDER_GROUP } from 'constants/config';

const { dispatch } = store;

export const handleSocketsEventsByType = ({ eventType, business, data }) => {
  const businessId = JSON.parse(localStorage.getItem('businessInfo'))?.id;
  if (
    business !== businessId &&
    eventType !== SOCKET_EVENT_TYPES.newOrder &&
    eventType !== SOCKET_EVENT_TYPES.orderStatusChange &&
    eventType !== SOCKET_EVENT_TYPES.orderCancel &&
    eventType !== SOCKET_EVENT_TYPES.orderChangedInProvider
  ) {
    return;
  }
  switch (eventType) {
    // Providers.
    case SOCKET_EVENT_TYPES.createProvider:
      // Need to implement in the feature
      return null;
    case SOCKET_EVENT_TYPES.updateProviderStatus: // return dispatch(getMenusV2());
      dispatch(getProviders()).then();
      dispatch(getProvidersStatuses()).then();
      return;
    case SOCKET_EVENT_TYPES.updateProviderState:
      dispatch(getProviders()).then();
      dispatch(getProvidersStatuses()).then();
      return;
    // Menu Provider.
    // case SOCKET_EVENT_TYPES.importMenuForProvider:
    //   return dispatch(getMenusV2());
    case SOCKET_EVENT_TYPES.pushMenuForProvider:
      return dispatch(setMenuFromSocket(data));
    case SOCKET_EVENT_TYPES.clearMenuForProvider:
      return dispatch(getMenusV2());
    case SOCKET_EVENT_TYPES.deleteMenuForProvider:
      return dispatch(getMenusV2());
    case SOCKET_EVENT_TYPES.updateMenuForProvider:
    // Need to implement in the feature
    // return null;
    // Categories.
    case SOCKET_EVENT_TYPES.createCategory:
      return dispatch(createCategory(data));
    case SOCKET_EVENT_TYPES.updateCategory:
      return dispatch(updateCategory(data));
    case SOCKET_EVENT_TYPES.deleteCategory:
      return dispatch(deleteCategory(data));
    case SOCKET_EVENT_TYPES.sortCategories:
      return dispatch(sortCategory(data));
    case SOCKET_EVENT_TYPES.importCategory:
      return dispatch(getMenusV2());
    case SOCKET_EVENT_TYPES.pushCategory:
      return dispatch(setCategoryFromSocket(data));
    // Products.
    case SOCKET_EVENT_TYPES.pushProduct:
      return dispatch(setProductsFromSocket(data));
    case SOCKET_EVENT_TYPES.createProduct:
      return dispatch(createProductSocket(data));
    case SOCKET_EVENT_TYPES.updateProduct:
      // const checkCategory = data?.category && typeof data?.category === 'object' && !data?.category?._id;
      // dispatch(getProductsAll());
      if (!data?.category || data?.category && typeof data?.category === 'object' && !data?.category?._id) {
        return;
      }
      return dispatch(updateProductSocket(data));
    case SOCKET_EVENT_TYPES.deleteProduct:
      return dispatch(deleteProductSocket(data));
    case SOCKET_EVENT_TYPES.sortProduct:
      // dispatch(getProductsAll());
      return dispatch(getCategoryProductsV2(data.category));
    // Modifiers.
    case SOCKET_EVENT_TYPES.createModifier:
      dispatch(createModifier(data));
      return dispatch(addInModifiersAll(data));
    // return dispatch(getModifiersAll());
    case SOCKET_EVENT_TYPES.updateModifier:
      // return dispatch({ type: SET_CURRENT_MODIFIER, payload: data });
      return dispatch(updateModifierById(data));
    case SOCKET_EVENT_TYPES.sortModifiers:
    case SOCKET_EVENT_TYPES.deleteModifier:
    case SOCKET_EVENT_TYPES.groupModifier:
    case SOCKET_EVENT_TYPES.sortModifierList:
    case SOCKET_EVENT_TYPES.updateModifierOrder:
      // return dispatch(getModifiersAll())
      return;

    // Orders.
    case SOCKET_EVENT_TYPES.newOrder:
      dispatch({ type: SET_LAST_UPDATED_ORDER, payload: data.id });
      dispatch({ type: RESET_ORDERS_PAGINATION });
      if (data?.orderGroup === ORDER_GROUP.group) {
        return;
      }

      return dispatch({ type: ORDERS_PUSH, payload: data });
    case SOCKET_EVENT_TYPES.orderStatusChange:
      return dispatch(getOrders());
    case SOCKET_EVENT_TYPES.orderChangedInProvider:
      dispatch({ type: RESET_ORDERS_PAGINATION });
      dispatch({ type: SET_LAST_UPDATED_ORDER, payload: data.id });
      if (data?.orderGroup === ORDER_GROUP.group) {
        return;
      }
      return dispatch(orderChangedInProvider(data));
    case SOCKET_EVENT_TYPES.orderCancel:
      dispatch(getOrders());

      dispatch({ type: RESET_ORDERS_PAGINATION });
      dispatch({ type: SET_LAST_CANCELED_ORDER, payload: data.id });
      if (data?.orderGroup === ORDER_GROUP.group) {
        return;
      }
      return dispatch({ type: UPDATE_CANCELED_ORDERS, payload: data });
    case SOCKET_EVENT_TYPES.updateSession:
      return dispatch(updateUserSession());
    case SOCKET_EVENT_TYPES.updateResumePauseProvider:
      dispatch(getProvidersStatuses()).then();
      break;
    case SOCKET_EVENT_TYPES.providerConnectResponse:
      dispatch(handleProviderConnectData(data));
      break;
    case SOCKET_EVENT_TYPES.pinCodeUpdate:
      dispatch(handleUpdatePinCodeData(data));
      break;
    case SOCKET_EVENT_TYPES.paymentNotification:
      dispatch(handleUpdatePaymentNotification(data));
      if (data?.isBeeping && data?.type === 'table_ordering') {
        callWaitStaff(data);
      }
      break;
    default:
      return null;
  }
};
