import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, FormControl, FormHelperText, Grid } from '@mui/material';
import SubscriptionPlanChargeDateMessage from '../SubscriptionPlanChargeDateMessage';
import { useTranslation } from 'react-i18next';

const AcceptRequirements = ({
  handleAcceptAgreeToReceiveOrderCommunication,
  acceptAgreeToReceiveOrderCommunication,
  acceptAgreeToReceiveOrderCommunicationError,
  selectedPlan,
  handleAcceptAgreeToPayRecurringCharges,
  agreeToPayRecurringCharges,
  agreeToPayRecurringChargesError,
  selectedPlanData,
  selectedPlanPrice,
  checkNewPlans,
  isSpecialLocation = false,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      {selectedPlan && selectedPlan !== 'null' && checkNewPlans ? (
        <Grid item xs={12}>
          <FormControl name="accept" component="fieldset" error={!!agreeToPayRecurringChargesError} variant="standard">
            <FormGroup style={{ height: '10px', display: 'flow' }}>
              <FormControlLabel
                sx={{ marginRight: 0 }}
                control={
                  <Checkbox
                    onChange={handleAcceptAgreeToPayRecurringCharges}
                    checked={agreeToPayRecurringCharges}
                    error={true}
                    name="accept"
                  />
                }
                label={
                  <SubscriptionPlanChargeDateMessage
                    selectedPlanData={selectedPlanData}
                    selectedPlanPrice={selectedPlanPrice}
                    selectedPlan={selectedPlan}
                    isBrand={isSpecialLocation}
                  />
                }
              />
              {agreeToPayRecurringChargesError && (
                <Box>
                  <FormHelperText sx={{ marginTop: '-18px' }}>{t('help.field_is_required')}</FormHelperText>
                </Box>
              )}
            </FormGroup>
          </FormControl>
        </Grid>
      ) : (
        ''
      )}
      <Grid item xs={12}>
        <FormControl
          name="accept"
          component="fieldset"
          error={!!acceptAgreeToReceiveOrderCommunicationError}
          variant="standard"
        >
          <FormGroup style={{ height: '50px', display: 'flow' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleAcceptAgreeToReceiveOrderCommunication}
                  checked={acceptAgreeToReceiveOrderCommunication}
                  error={true}
                  name="accept"
                />
              }
              label={t('settings.i_agree_grid')}
            />
            {acceptAgreeToReceiveOrderCommunicationError && (
              <FormHelperText sx={{ marginTop: '-15px' }}>{t('help.field_is_required')}</FormHelperText>
            )}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default AcceptRequirements;
