import { availabilityOptions } from 'constants/availabilityOptions';
import { SOLD_OUT_STATUS } from 'constants/enumSoldOut';
import moment from 'moment/moment';

export const filteringSoldOut = (data) => {
  let value = '';
  if (data.soldOut?.status) {
    availabilityOptions.map((item) => {
      if (data.soldOut.status === item.value) {
        value = item.value;
      }
    });
  }
  return value;
};

export const renderPermanently = (data, moment) => {
  const isToday = filteringSoldOut(data) === SOLD_OUT_STATUS.SOLD_OUT_TODAY;
  const isUntil = filteringSoldOut(data) === SOLD_OUT_STATUS.SOLD_OUT_PERMANENT;
  let result = '';
  if (isToday) {
    result = moment(data.soldOut.soldOutDate)?.format('MMMM D, YYYY');
  } else if (isUntil) {
    result = 'menu.unavailable';
  }
  return result;
};

export const removeItemAfterSignUp = () => {
  localStorage.removeItem('accountInfo');
  localStorage.removeItem('legalInfo');
  localStorage.removeItem('currentPlan');
  localStorage.removeItem('billingInfo');
  localStorage.removeItem('billingId');
  localStorage.removeItem('serviceInfo');
  localStorage.removeItem('companyType');
  localStorage.removeItem('accountResponse');
  localStorage.removeItem('business');
  localStorage.removeItem('currentInvitationService');
  localStorage.removeItem('approvedInvitationInfo');
  localStorage.removeItem('legalResponseInfo');
  localStorage.removeItem('accountInfoAuth');
  localStorage.removeItem('currentSetupFee');
  localStorage.removeItem('enabledSetupFee');
  localStorage.removeItem('freeTrialEnabled');
};

export const trialVersionDate = (freeTrailPeriod) => {
  const timeZone = 'America/Los_Angeles';
  // const currentDate = new Date(moment().tz(timeZone));
  const timeZoneDate = moment(new Date()).tz(timeZone);
  // if (currentDate.getDate() >= 25 || currentDate.getDate() <= 10) {
  //   if (currentDate.getDate() < 10) {
  //     return timeZoneDate.add(1, 'M').set('date', '1');
  //   } else if (currentDate.getDate() >= 25) {
  //     return timeZoneDate.add(2, 'M').set('date', '1');
  //   } else {
  //     return timeZoneDate.add(1, 'M').set('date', '15');
  //   }
  // } else {
  //   return timeZoneDate.add(1, 'M').set('date', '15');
  // }
  return timeZoneDate.add(freeTrailPeriod || 30, 'd');
};

/* temporary version */
export const handleDeleteValues = (values) => {
  delete values?.address;
  delete values?.address1;
  delete values?.address2;
  delete values?.city;
  delete values?.country;
  delete values?.county;
  delete values?.fullAddress;
  delete values?.geoLocation;
  delete values?.name;
  delete values?.state;
  delete values?.zipCode;
  return values;
};

export const collectGoogleQueryData = (places) => {
  const { formatted_address, geometry, place_id } = places;
  const components = places.address_components;
  const addressInfo = {
    address1: formatted_address || '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    address: formatted_address,
    fullAddress: formatted_address,
    placeId: place_id,
    geoLocation: {
      lat: geometry?.location?.lat(),
      lng: geometry?.location?.lng(),
      debug: {
        ...places,
      },
    },
  };
  if (components) {
    for (var i = 0, l = components.length; i < l; i++) {
      var component = components[i];
      if (component.types && component.types.indexOf('route') !== -1) {
        const streetNumber = components.find((item) => item.types.indexOf('street_number') !== -1);
        addressInfo.address1 = streetNumber ? `${streetNumber?.long_name} ${component.long_name}` : component.long_name;
      }
      if (component.types && component.types.indexOf('locality') !== -1) {
        addressInfo.city = component.long_name;
      }
      if (component.types && component.types.indexOf('administrative_area_level_1') !== -1) {
        addressInfo.state = component.short_name;
      }
      if (component.types && component.types.indexOf('country') !== -1) {
        addressInfo.country = component.long_name;
      }
      if (component.types && component.types.indexOf('postal_code') !== -1) {
        addressInfo.zipCode = component.long_name;
      }
      if (component.types && component.types.indexOf('subpremise') !== -1) {
        addressInfo.address2 = component.long_name;
      }
    }
  }
  addressInfo.fullAddress = '';
  if (addressInfo.address1 || addressInfo.address2) {
    addressInfo.fullAddress = `${addressInfo.address1} ${addressInfo.address2}, `;
  }
  if (addressInfo.city) {
    addressInfo.fullAddress += `${addressInfo.city}, `;
  }
  if (addressInfo.state || addressInfo.zipCode) {
    addressInfo.fullAddress += `${addressInfo.state} ${addressInfo.zipCode} ${addressInfo.country ? ',' : ''} `;
  }
  if (addressInfo.country) {
    addressInfo.fullAddress += `${addressInfo.country}`;
  }
  return addressInfo;
};
