import { memo, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

const OfficeHours = ({
  day,
  onChangeStart,
  onChangeEnd,
  times,
  removeTime,
  checked,
  disabledTime = {},
  handleOpenFullDay,
  fullDayChecked,
  nextDayLabel = '',
}) => {
  const [open24, setOpen24] = useState(fullDayChecked);
  const { t } = useTranslation();

  useEffect(() => {
    setOpen24(fullDayChecked);
  }, [fullDayChecked]);
  // const [playAnimation, setPlayAnimation] = useState(false);
  // function generateOptions(length, excludedOptions) {
  //   const arr = [];
  //   for (let value = 0; value < length; value++) {
  //     if (excludedOptions.indexOf(value) < 0) {
  //       arr.push(value);
  //     }
  //   }
  //   return arr;
  // }

  // const formattedTimes = useMemo(() => {
  //   const copyTimes = [...times];
  //   // copyTimes.map((time) => {
  //   //   if (!checked) {
  //   //     time.start = '00:00';
  //   //     time.end = '23:59';
  //   //   }
  //   // });

  //   return copyTimes;
  // }, [checked, day, times]);

  const handleChangeFullDay = (e) => {
    if (e.target.checked) {
      handleOpenFullDay(e.target.checked, day);
      setOpen24(true);
    } else {
      handleOpenFullDay(false, day);
      setOpen24(false);
    }
  };

  const TimePickerAddOn = ({ day, index, position }) => {
    return (
      <Box>
        <FormControlLabel
          disableTypography={true}
          sx={{ margin: 0, fontSize: '14px', width: '100%', borderTop: '1px solid #e0e0e0' }}
          control={<Checkbox checked={open24} size="small" onChange={handleChangeFullDay} />}
          label={t('settings.open_24_hours')}
        />
      </Box>
    );
  };
  if (!times) return null;

  return (
    <Box>
      {times.map((time, index) => {
        // let showDay = true;
        // if (times.length > 1 && index !== 1) {
        //   showDay = false;
        // }
        // let startErrorMessage = '';
        // let endErrorMessage = '';
        const splittedStart = time.start.split(':'); // ['00', '00'];
        const splittedEnd = time.end.split(':'); // ['23', '59'];

        const defaultValueStart = moment().day(day).hour(splittedStart[0]).minute(splittedStart[1]);
        const defaultValueEnd = moment().day(day).hour(splittedEnd[0]).minute(splittedEnd[1]);

        // const errStart =
        //   splittedStart[0] === 'Invalid date' || moment(defaultValueStart).isAfter(moment(defaultValueEnd));
        // const errEnd = splittedEnd[0] === 'Invalid date' || moment(defaultValueEnd).isBefore(moment(defaultValueStart));

        // if (errStart) {
        //   startErrorMessage = (
        //     <Typography sx={{ fontSize: '12px' }} color="error">
        //       Opening time can’t be <br /> after the Closing time
        //     </Typography>
        //   );
        // }
        // if (errEnd) {
        //   endErrorMessage = (
        //     <Typography sx={{ fontSize: '12px' }} color="error">
        //       Opening time can’t be <br /> after the Closing time
        //     </Typography>
        //   );
        // }

        // if (splittedStart[0] === 'Invalid date') {
        //   startErrorMessage = (
        //     <Typography sx={{ fontSize: '12px' }} color="error">
        //       This field is required
        //     </Typography>
        //   );
        // }
        // if (splittedEnd[0] === 'Invalid date') {
        //   endErrorMessage = (
        //     <Typography sx={{ fontSize: '12px' }} color="error">
        //       This field is required
        //     </Typography>
        //   );
        // }

        return (
          <Box key={index} sx={{ display: 'flex' }}>
            {times.length !== 1 && (
              <IconButton variant="outlined" size="sm" onClick={() => removeTime(day, index)}>
                <i className="icon icon-trash-2"></i>
              </IconButton>
            )}

            <Box
              flexDirection="column"
              sx={{
                position: 'relative',
                '& .rc-time-picker-clear': {
                  display: 'none',
                },
              }}
            >
              <TimePicker
                use12Hours
                // disabledHours={() => disabledTime[day]?.hours}
                // disabledMinutes={() => disabledTime[day]?.mins}
                style={{ width: '120px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                showSecond={false}
                value={defaultValueStart}
                onChange={(value) => onChangeStart(day, index, value)}
                minuteStep={15}
                onAmPmChange={() => {}}
                inputReadOnly
                disabled={!checked}
                addon={() => <TimePickerAddOn day={day} index={index} position={'start'} />}
                allowEmpty={true}
                inputIcon={
                  open24 ? (
                    <Box
                      sx={{
                        width: '110px',
                        position: 'absolute',
                        left: 5,
                        fontSize: '12px',
                        margin: 0,
                        color: checked ? '#666' : '#9e9e9eb8',
                        background: !checked ? '#f7f7f7' : 'white',
                      }}
                    >
                      {!checked ? t('orders.closed') : t('settings.open_24_hours')}
                    </Box>
                  ) : (
                    <></>
                  )
                }
              />
            </Box>
            <Box
              flexDirection="column"
              ml={2}
              sx={{
                position: 'relative',
                '& .rc-time-picker-clear': {
                  display: 'none',
                },
              }}
            >
              <TimePicker
                use12Hours
                style={{
                  width: '120px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
                showSecond={false}
                value={defaultValueEnd}
                onAmPmChange={() => {}}
                onChange={(value) => onChangeEnd(day, index, value)}
                minuteStep={15}
                inputReadOnly
                disabled={!checked}
                inputIcon={
                  !open24 ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        left: '60px',
                        fontSize: '12px',
                        margin: 0,
                        color: checked ? '#666' : '#9e9e9eb8',
                        background: !checked ? '#f7f7f7' : '',
                      }}
                    >
                      {nextDayLabel[`label${index}`] ||
                        (disabledTime[day]?.[`label${index}`] && `(${disabledTime[day]?.[`label${index}`]})`) ||
                        ''}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        position: 'absolute',
                        left: 5,
                        fontSize: '12px',
                        width: '110px',
                        // color: '#666',
                        margin: 0,
                        // background: 'white',
                        color: checked ? '#666' : '#9e9e9eb8',
                        background: !checked ? '#f7f7f7' : 'white',
                      }}
                    >
                      {!checked ? t('orders.closed') : t('settings.open_24_hours')}
                    </Box>
                  )
                }
                addon={() => <TimePickerAddOn checked={checked} day={day} index={index} position={'end'} />}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(OfficeHours);
