import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ExpoReport from 'assets/icons/report-export.svg';
import { Button, CircularProgress } from '@mui/material';

const ExportButton = ({ loadingExport,  handleExport}) => {
  const { t } = useTranslation();

  return (
    <Button
      id="tableExport"
      disabled={loadingExport}
      color="primary"
      className="banner-btn banner-filter"
      onClick={handleExport}
    >
      {loadingExport ? (
        <CircularProgress color="inherit" size={16} />
      ) : (
        <>
          <img src={ExpoReport} alt="REPORT-EXPORT" />
          <span className="ml-1">{t('reports_settings.export')}</span>
        </>
      )}
    </Button>
  )
}
export default memo(ExportButton)