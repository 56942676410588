import { Box, Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BusinessSettingsAllTitle,
  BusinessSettingsSubTitle,
  TextFieldWrap,
} from 'pages/Settings/BusinessSettings/styles';
import CopyIcon from './CopyIcon';
import LinkIconButton from './LinkIcon';
import { useSelector } from 'react-redux';

const ResumeSetUpLink = () => {
  const { t } = useTranslation();
  const { currentBusiness } = useSelector((state) => state.business);

  return (
    <>
      <Divider />
      <Box sx={{ mt: 3 }}>
        <Grid container>
          <Grid item xs={12}>
            <BusinessSettingsAllTitle>{t('settings.resume_set_up_link')}</BusinessSettingsAllTitle>
            <BusinessSettingsSubTitle>{t('settings.resume_set_up_link_desc')}</BusinessSettingsSubTitle>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ position: 'relative', width: '368px' }}>
              <TextFieldWrap
                value={currentBusiness?.signUpProgress?.url}
                InputProps={{
                  startAdornment: <LinkIconButton />,
                  endAdornment: <CopyIcon currentBusiness={currentBusiness} />,
                }}
                inputProps={{ readOnly: true }}
                size="small"
                label={t('settings.resume_set_up_link_label')}
                name="billingContinueLink"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ResumeSetUpLink;
