import {
  SET_CHARGE_BACK,
  SET_CHARGE_BACK_LOADING,
  SET_CHARGE_SELECTED,
  SET_CHARGE_SELECTED_LOADING,
} from 'components/Chargeback/constants';

const initialState = {
  states: {
    new: [],
    processing: [],
    hold: [],
    completed: [],
  },
  loading: false,
  loadingSelected: false,
  selected: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CHARGE_BACK:
      return { ...state, ...payload };
    case SET_CHARGE_BACK_LOADING:
      return { ...state, loading: payload };
    case SET_CHARGE_SELECTED:
      return { ...state, selected: payload };
    case SET_CHARGE_SELECTED_LOADING:
      return { ...state, loadingSelected: payload };
    default:
      return state;
  }
};
