import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getReportListForHome } from 'redux/actions/reports';
import {
  calculateTotalOrders,
  calculateTotalSales,
  getPreviousPeriod,
  prepareChartDataForOrdersAndSales,
  processSelectFilter,
} from 'components/NewHome/helpers/helpers';
import { useReportPeriods } from 'components/NewHome/hooks/useReportPeriods';
import { ReportingFilterDefaultValues, ReportType } from 'components/NewHome/constants/constatnts';

const initialData = [
  {
    label: 'Orders',
    chartData: {
      labels: [
        '0:00',
        '1:00',
        '2:00',
        '3:00',
        '4:00',
        '5:00',
        '6:00',
        '7:00',
        '8:00',
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ],
      datasets: [
        {
          label: 'This Period - Sales',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: '#FF8A00',
          backgroundColor: '#FF8A00',
          yAxisID: 'y-axis-sales',
          type: 'line',
        },
        {
          label: 'Previous Period - Sales',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: '#A9A9A9',
          backgroundColor: '#A9A9A9',
          yAxisID: 'y-axis-sales',
          type: 'line',
        },
      ],
    },
  },
  {
    label: 'Sales',
    chartData: {
      labels: [
        '0:00',
        '1:00',
        '2:00',
        '3:00',
        '4:00',
        '5:00',
        '6:00',
        '7:00',
        '8:00',
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ],
      datasets: [
        {
          label: 'This Period - Sales',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: '#FF8A00',
          backgroundColor: '#FF8A00',
          yAxisID: 'y-axis-sales',
          type: 'line',
        },
        {
          label: 'Previous Period - Sales',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: '#A9A9A9',
          backgroundColor: '#A9A9A9',
          yAxisID: 'y-axis-sales',
          type: 'line',
        },
      ],
    },
  },
];

export const useSales = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [totalOne, setTotalOne] = useState(0);
  const [totalTwo, setTotalTwo] = useState(0);
  const [totalCountOne, setTotalCountOne] = useState(0);
  const [totalCountTwo, setTotalCountTwo] = useState(0);
  const { periods, query, currentBusiness } = useReportPeriods();

  const [reportSales, setReportSales] = useState(initialData || []);

  const handleGetReports = useCallback(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const selectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));
        const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
        const currentType =
          periods[query.period] === periods.today || periods[query.period] === periods.yesterday
            ? ReportType.SALES_BY_HOUR
            : ReportType.SALES_BY_DATE;
        const data = {
          days_of_week: ReportingFilterDefaultValues.days_of_week,
          providers: ReportingFilterDefaultValues.providers,
          type: currentType,
          brandIds: [selectedBrand?.value?._id],
          locationIds: [selectedLocation?.value?._id || selectedBrand?.value?.locations[0]?._id],
        };
        const homeReportFilters = processSelectFilter(
          periods[query.period]?.label,
          currentBusiness?.timezone.timeZoneId,
          data
        );
        const previousPeriod = getPreviousPeriod(homeReportFilters.start_date, homeReportFilters.end_date);
        const previousData = {
          days_of_week: ReportingFilterDefaultValues.days_of_week,
          providers: ReportingFilterDefaultValues.providers,
          type: currentType,
          start_date: previousPeriod.prevStartDate,
          end_date: previousPeriod.prevEndDate,
          brandIds: [selectedBrand?.value?._id],
          locationIds: [selectedLocation?.value?._id || selectedBrand?.value?.locations[0]?._id],
        };
        const response = await dispatch(getReportListForHome(homeReportFilters));
        const previousResponse = await dispatch(getReportListForHome(previousData));
        const total1 = calculateTotalSales(response);
        const total2 = calculateTotalSales(previousResponse);
        const totalCount1 = calculateTotalOrders(response);
        const totalCount2 = calculateTotalOrders(previousResponse);
        setTotalCountOne(totalCount1);
        setTotalCountTwo(totalCount2);
        setTotalOne(total1);
        setTotalTwo(total2);
        let dataGroup = [
          {
            groupName: 'This Period',
            data: response,
          },
          {
            groupName: 'Previous Period',
            data: previousResponse,
          },
        ];
        dataGroup = prepareChartDataForOrdersAndSales(dataGroup);
        const newData = [
          { label: 'Orders', chartData: dataGroup.ordersData },
          { label: 'Sales', chartData: dataGroup.salesData },
        ];
        if (isMounted) {
          setReportSales(newData);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [dispatch, currentBusiness, query.period, periods]);

  useEffect(() => {
    handleGetReports();
  }, [handleGetReports]);

  return {
    loading,
    reportSales,
    totalOne,
    totalTwo,
    totalCountOne,
    totalCountTwo,
  };
};
