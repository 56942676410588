export const priceTransform = (value) => {
  if (isNaN(value)) {
    return null;
  } else {
    const clean = value.replace(/\D/g, '');
    const result = String(+clean)
      .padStart(3, '0')
      .replace(/(..)$/, '.$1');
    return result;
  }
};

export const formatPrice = (value) => {
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
};

export const formatNumber = (value) => {
  // Ensure that the value is displayed with trailing zeros only when necessary
  const floatValue = parseFloat(value);
  return floatValue.toFixed(2);
};
