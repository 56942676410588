import { useEffect, useState } from 'react';
import instance from 'services/api';
import { Box } from '@mui/material';
import AddLocationSuccess from './AddLocationSuccess';
import AddLocationFirstStep from './AddLocationFirstStep';
import { SIGN_UP_FLOW_ADD_LOCATION } from 'constants/availableButtons';
import { CreateLocationSubTitle, CreateLocationTitle } from './styles';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const AddLocation = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const isSpecialLocation = Boolean(JSON.parse(searchParams.get('isSpecialLocation')));
  const [active, setActive] = useState(SIGN_UP_FLOW_ADD_LOCATION.ADD_LOCATION);
  const [loading, setLoading] = useState(false);
  const [addressInfo, handleAddressInfo] = useState({ address1: '', address2: '', city: '', state: '', zipCode: '' });
  const [currentBusiness] = useState(null);
  const [data, setData] = useState([]);
  const [currentSelectedBrandId, setCurrentBrandId] = useState([]);

  useEffect(() => {
    instance
      .get(`/brand/all?isServicingBrand=1`)
      .then((res) => {
        setLoading(true);
        setData(res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getAddressFields = (addressInfo) => {
    handleAddressInfo(addressInfo);
  };

  useEffect(() => {
    if (active === SIGN_UP_FLOW_ADD_LOCATION.ADD_LOCATION) {
      setLoading(false);
    }
  }, [active]);

  const handleSubmit = (values) => {
    setLoading(true);
    values.addressInfo = addressInfo;
    values.addressInfo.address1 = values.address1;
    values.addressInfo.address2 = values.address2;
    values.addressInfo.state = values.state;
    values.addressInfo.zipCode = values.zipCode;
    values.addressInfo.city = values.city;
    values.addressInfo.placeId = '';
    values.addressInfo.fullAddress = '';
    if (values.address1 || values.address2) {
      values.addressInfo.fullAddress += `${values.address1} ${values.address2}, `;
    }
    if (values.city) {
      values.addressInfo.fullAddress += `${values.city}, `;
    }
    if (values.state || values.zipCode) {
      values.addressInfo.fullAddress += `${values.state} ${values.zipCode} ${values.country ? ',' : ''} `;
    }
    if (values.country) {
      values.addressInfo.fullAddress += `${values.country}`;
    }

    const brandId = data.find((item) => item?.brandName === values?.brandName)?._id || '';
    const userEmail = localStorage.getItem('email');
    const body = {
      ...values,
      addressInfo: addressInfo,
      locationName: values.locationName,
      brandId: currentSelectedBrandId || brandId,
      cloneMenu: values.cloneMenu,
      businessId: values.businessId,
      planId: values.planId,
      paymentTerm: values.paymentTerm,
      oneTimeSetupFeeCouponCode: values?.oneTimeSetupFeeCouponCode || '',
      employeeNumber: values?.employeeNumber || '',
      agreeToPaySetupFee: values?.agreeToPaySetupFee,
      agreeToPayRecurringCharges: values?.agreeToPayRecurringCharges,
      agreeToReceiveOrderCommunication: values?.agreeToReceiveOrderCommunication,
      email: userEmail || '',
    };
    if (body) {
      instance
        .post(`/location`, body)
        .then(() => {
          setActive(SIGN_UP_FLOW_ADD_LOCATION.ADD_LOCATION_SUCCESS);
          localStorage.removeItem('currentPlan');
          localStorage.removeItem('currentSetupFee');
          localStorage.removeItem('enabledSetupFee');
        })
        .catch(() => {
          setLoading(false);
          localStorage.removeItem('currentPlan');
          localStorage.removeItem('currentSetupFee');
          localStorage.removeItem('enabledSetupFee');
        });
    }
  };
  return (
    <Box className="signup-wizard my-5">
      {active === SIGN_UP_FLOW_ADD_LOCATION.ADD_LOCATION && (
        <Box className="wizard-header">
          <CreateLocationTitle>{t('add_location.location_onboarding_wizard')}</CreateLocationTitle>
          <CreateLocationSubTitle>{t('add_location.please_fill_onboarding_process')}</CreateLocationSubTitle>
        </Box>
      )}
      {active === SIGN_UP_FLOW_ADD_LOCATION.ADD_LOCATION && (
        <AddLocationFirstStep
          addressInfo={addressInfo}
          address={currentBusiness?.addressInfo.name || currentBusiness?.address}
          address2={currentBusiness?.address2}
          loading={loading}
          getAddressFields={getAddressFields}
          setActive={setActive}
          handleSubmit={handleSubmit}
          data={data}
          setData={setData}
          setCurrentBrandId={setCurrentBrandId}
          isSpecialLocation={isSpecialLocation}
        />
      )}
      {active === SIGN_UP_FLOW_ADD_LOCATION.ADD_LOCATION_SUCCESS && <AddLocationSuccess />}
    </Box>
  );
};

export default AddLocation;
