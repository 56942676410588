import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
export const ReportProviderWrapper = styled(Box)({
  padding: '8px 0',
  display: 'flex',
  gap: '20px',
});

export const ReportProviderImageWrapper = styled(Box)({
  '& > img': {
    width: '58px',
    height: '58px',
  },
});

export const ReportProviderInfoWrapper = styled(Box)({});

export const TopSellingItemWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  marginTop: '24px',
  backgroundColor: '#FFF',
  borderRadius: '4px',
});

export const TopSellingItemTitle = styled(Typography)({
  fontSize: '18px',
  color: '#000',
  lineHeight: '24px',
  fontWeight: 600,
});

export const TopSellingItemSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: '#000',
  fontWeight: 500,
  lineHeight: '24px',
  marginBottom: theme.spacing(2),
}));

export const TopSellingInnerBlock = styled(Box)(({ theme }) => ({
  maxHeight: '500px',
  overflowX: 'hidden',
  padding: theme.spacing(2),
}));

export const TopSellingInnerListItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5, 0),
}));

export const TopSellingInnerListItemName = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0),
  color: 'rgba(15, 18, 20, 1)',
}));

export const TopSellingAmountWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const TopSellingAmountQTY = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 1, 0),
  color: 'rgba(15, 18, 20, 1)',
}));

export const TopSellingAmount = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0),
  color: 'rgba(146, 147, 152, 1)',
}));

export const TopSellingEmptyMessage = styled(Box)(() => ({
  textAlign: 'center',
  margin: '0, auto',
}));

export const TopSellingEmptyMessageText = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '20px',
}));

export const ProviderInfoWrapper = styled(Box)(() => ({
  backgroundColor: '#FFF',
  padding: '16px',
  borderRadius: '4px',
}));

export const ProviderInfoHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: theme.spacing(1.5),
  '&: img': {
    width: '54px',
    height: '54px',
    margin: theme.spacing(0, 1.5, 0, 0),
  },
}));

export const ProviderInfoHeaderWrapperTitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  color: '#0F1214',
  letterSpacing: '0.25px',
  margin: theme.spacing(0),
  paddingLeft: theme.spacing(1.5),
}));

export const ProviderInfoStatusWrapper = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: '1fr 1fr',
  gap: '10px',
  width: 'auto',
}));

export const ProviderInfoStatusInnerBlock = styled(Box)(({ theme }) => ({
  borderRadius: '6px',
  padding: theme.spacing(2),
  boxSizing: 'border-box',
}));

export const ProviderInfoStatusItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > img': {
    width: '16px',
    height: '16px',
    margin: theme.spacing(0, 0.7, 0, 0),
  },
}));

export const ProviderInfoStatusItemTitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  color: '#000000',
  letterSpacing: '0.25px',
  margin: theme.spacing(0),
}));

export const ProviderInfoStatusItemPrice = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0),
}));
