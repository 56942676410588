import React, { useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { extractUniqueKeys } from 'redux/actions/reports';
import { handleTypeName, objEnum } from 'pages/Report/utils';
import { convertCamelCaseToTitleCase } from 'helpers/convertCamelCaseToTitleCase';

export const ReportType = {
  SalesByDate: 'Sales by Date',
  DetailedSalesByProvider: 'Detailed Sales by Provider',
  SalesByHour: 'Sales by Hour',
  DetailedOrderReport: 'Detailed Order Report',
  ChargebackDisputeReport: 'Chargeback/Dispute Report',
};

export const REPORT_TYPE_FOR_TOTAL_REPORT = Object.values(ReportType);

export const ReportFilterType = {
  contains: 'contains',
  equals: 'equals',
  starts_with: 'starts with',
  ends_with: 'ends with',
  is_empty: 'is empty',
  is_not_empty: 'is not empty',
}

export const DETAILED_ORDER_REPORT_FIELDS = [
  'Brand Name',
  'Location Name',
  'Address1',
  'Address2',
  'City',
  'State',
  'Zip Code',
  'Order Number',
  'Be Ready Time',
  'Employee Id',
  'Employee Name',
  'Employee Last Name',
  'Employee Initials',
];

export const styles= {
  my: 2,
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: (theme) => theme.palette.primary.main,
    color: 'white',
  },
  '& .MuiDataGrid-row	': {
    '&:nth-of-type(odd)': {
      backgroundColor: (theme) => theme.palette.action.hover,
    },
  },
  '& .MuiDataGrid-sortIcon': {
    color: 'white',
  },
  '& .MuiDataGrid-filterIcon': {
    color: 'white',
  },
  '& .MuiDataGrid-menuIcon	': {
    '& .MuiButtonBase-root': { color: 'white' },
  },
  '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
    margin: 0,
  },
}

export const numberFields = [
  'total',
  'sub_total',
  'tax',
  'tip',
  'delivery',
  'total_sales',
  'total_amount',
  'average_sale',
  'total_tax',
  'total_tips',
  'total_discounts',
  'subtotal_sales',
  'total_delivery',
  'driver_tip',
  'kitchen_tip',
  'total_driver_tips',
  'total_driver_tip',
  'total_kitchen_tips',
  'total_kitchen_tip',
  'total_coupon',
  'coupon',
  'total_delivery_fee',
];

const parseNumber = (value) => {
  if (typeof value === 'number') return value;
  if (typeof value === 'string') {
    const parsed = parseFloat(value.replace(/[^0-9.-]+/g, ''));
    return isNaN(parsed) ? 0 : parsed;
  }
  return 0;
};

const useTableReport = (reportListData, headerKeys, currentReport, tableTypeName) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(100);
  const [hasFilterData, setHasFilterData] = useState(reportListData);

  const tableType = currentReport?.type || tableTypeName;
  const tableTypeUp = tableType?.toUpperCase() || '';

  const transformTableType = t(handleTypeName(tableTypeUp));

  const handelRowData = (reportList, headerKeysData) => {
    let headers = [];
    let rows = reportList;
    if (Array.isArray(headerKeysData) && !headerKeysData?.length && reportList && reportList[0]) {
      headerKeysData = extractUniqueKeys(reportList);
    }
    for (let hName of headerKeysData) {
      const headerName = convertCamelCaseToTitleCase(hName.replaceAll('_', ' '));
      headers.push({
        field: hName,
        headerName: t(headerName),
        hide: Boolean(
          transformTableType === t(`${objEnum.DETAILED_ORDER_REPORT}`) &&
          DETAILED_ORDER_REPORT_FIELDS.includes(headerName)
        ),
        flex: 1,
        renderCell: ({ value, field }) => {
          if (field === 'reason') {
            return (
              <Typography
                fontSize="14px"
                className={'report--vertical'}
                textAlign={numberFields.includes(field) ? 'right' : 'left'}
              >
                {value}
              </Typography>
            );
          } else {
            return (
              <Typography fontSize="14px" textAlign={numberFields.includes(field) ? 'right' : 'left'} width="100%">
                {value}
              </Typography>
            );
          }
        },
      });
    }
    return { headers, rows };
  };

  const rowData = useMemo(() => {
    const currentList = hasFilterData?.length > 0 ? hasFilterData : reportListData;
    return handelRowData(currentList, headerKeys);
  }, [reportListData, headerKeys, hasFilterData]);

  const totals = useMemo(() => {
    let totalsObj = {};
    const currentList = hasFilterData?.length > 0 ? hasFilterData : reportListData;
    numberFields.forEach((field) => {
      totalsObj[field] = currentList.reduce((sum, row) => {
        const value = parseNumber(row[field]);
        return sum + (isNaN(value) ? 0 : value); // Safeguard for invalid numbers
      }, 0);
    });
    return totalsObj;
  }, [hasFilterData, reportListData, numberFields]);

  const handleFilterChange = (newFilterModel) => {
    const { items, linkOperator } = newFilterModel;
    const applyFilter = (row) => {
      return items.every((filter) => {
        const { columnField, operatorValue, value } = filter;
        const fieldValue = row[columnField]?.toString()?.toLowerCase();

        switch (operatorValue) {
          case ReportFilterType.contains:
            return fieldValue?.includes(value?.toLowerCase());
          case ReportFilterType.equals:
            return fieldValue === value?.toLowerCase();
          case ReportFilterType.starts_with:
            return fieldValue?.startsWith(value?.toLowerCase());
          case ReportFilterType.ends_with:
            return fieldValue?.endsWith(value?.toLowerCase());
          case ReportFilterType.is_empty:
            return !fieldValue;
          case ReportFilterType.is_not_empty:
            return !!fieldValue;
          default:
            return true;
        }
      });
    };

    const result =
      linkOperator === 'and'
        ? rows.filter(applyFilter)
        : rows.filter((row) => items.some(() => applyFilter(row)));
    setHasFilterData(result || []);
  };

  const { headers, rows } = rowData;

  return {
    pageSize,
    setPageSize,
    transformTableType,
    headers,
    rows,
    totals,
    handleFilterChange,
  }
}

export default useTableReport;