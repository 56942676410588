import { createContext, useState } from 'react';

export const initialSubscriptionConfigDataValues = {
  setSetupFee: () => {},
  setSetupFeeEnabled: () => {},
  setFreeTrialPeriod: () => {},
  setFreeTrialIsEnabled: () => {},
  setUpFeeEnabled: 0,
  setUpFee: 0,
  freeTrialPeriod: 30,
  freeTrialIsEnabled: false,
};
const SubscriptionContext = createContext(initialSubscriptionConfigDataValues);

const SubscriptionConfigProvider = ({ children }) => {
  const localSetupFee = localStorage.getItem('currentSetupFee');
  const localSetupFeeEnabled = localStorage.getItem('enabledSetupFee');
  const freeTrialEnabled = localStorage.getItem('freeTrialEnabled');
  const [setUpFee, setSetupFee] = useState(localSetupFee ? Number(localSetupFee) : 0);
  const [setUpFeeEnabled, setSetupFeeEnabled] = useState(localSetupFeeEnabled ? Number(localSetupFeeEnabled) : 1);
  const [freeTrialPeriod, setFreeTrialPeriod] = useState(30);
  const [freeTrialIsEnabled, setFreeTrialIsEnabled] = useState(freeTrialEnabled || false);
  return (
    <SubscriptionContext.Provider
      value={{
        setUpFee,
        setSetupFee,
        setUpFeeEnabled,
        setSetupFeeEnabled,
        freeTrialPeriod,
        setFreeTrialPeriod,
        setFreeTrialIsEnabled,
        freeTrialIsEnabled,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

const SubscriptionConfigConsumer = ({ children }) => {
  return <SubscriptionContext.Consumer>{children}</SubscriptionContext.Consumer>;
};

export { SubscriptionContext, SubscriptionConfigProvider, SubscriptionConfigConsumer };
