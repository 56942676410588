import { SET_PAYMENT_NOTIFICATION } from 'redux/actions/types';

const initialState = {
  data: {
    loading: false,
    title: 'settings.your_action_is_needed',
    message: 'settings.your_payment_to_update_it',
    level: 'green',
    period: null,
    showOkButton: true,
    paid: true,
  },
};
const paymentNotification = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PAYMENT_NOTIFICATION:
      return {
        ...state,
        data: payload,
      };

    default:
      return state;
  }
};

export default paymentNotification;
