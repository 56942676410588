import instance from '../../services/api';
import { toast } from 'react-toastify';

export const updateAvailabilitySettings = (body, id) => () => {
  return instance
    .put(`/availability/${id}`, body)
    .then(({ data: { data } }) => {
      return data;
    })
    .catch((err) => {
      toast.error('Updated failed!');
      return Promise.reject(err);
    });
};
export const createAvailabilitySettings = (body) => () => {
  return instance
    .post(`/availability`, body)
    .then(({ data: { data } }) => {
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const getAvailabilitySettings = () => {
  return instance.get('/availability').then(({ data: { data } }) => {
    return data;
  });
};
export const getAvailabilityById = (id) => {
  return instance.get(`/availability/${id}`).then(({ data: { data } }) => {
    return data;
  });
};
export const deleteAvailability = (id, body) => {
  return instance
    .delete(`/availability/${id}`, { data: body })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err && err.response;
    });
};
export const getAvailabilityCustom = () => () => {
  return instance.get('/availability/custom').then(({ data: { data } }) => {
    return data;
  });
};
