import Private from 'components/Private';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Divider, Paper } from '@mui/material';
import { StyledPosActionWrapper, StyledPosTitle, StyledPosTitleWrapper, StyledPosWrapper } from './styles';

const PosItem = ({ data }) => {
  const { t } = useTranslation();
  const { currentBusiness } = useSelector(({ business }) => business);
  const { pos } = currentBusiness;

  const renderActions = () => {
    if (data.id === pos.posId) {
      if (pos.status === 'connected') {
        return (
          <Private permission="SETTINGS_ADMIN">
            <Button disabled={true} id="providerItemDisconect" color="error" variant="outlined" size="sm" outline>
              {t('website_settings.disconnect')}
            </Button>
          </Private>
        );
      }
    }
    return (
      <Button disabled={true} id="providerItemConnect" color="primary" variant="outlined" size="sm">
        {t('website_settings.connect')}
      </Button>
    );
  };

  return (
    <Paper>
      <StyledPosTitleWrapper>
        <StyledPosTitle>
          <Typography variant="h6">{data.name}</Typography>
        </StyledPosTitle>
      </StyledPosTitleWrapper>
      <Divider />
      <StyledPosActionWrapper>
        <StyledPosWrapper>{renderActions()}</StyledPosWrapper>
      </StyledPosActionWrapper>
    </Paper>
  );
};

export default PosItem;
