// src/components/Currency.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

/**
 * Currency Component
 * Formats a numeric value into a currency string with optional styling.
 *
 * Props:
 * - value (number): The numeric value to format.
 * - currency (string): The currency code (e.g., 'USD', 'EUR'). Defaults to 'USD'.
 * - sx (object): Custom styles to apply to the Typography component.
 * - warning (boolean): If true, applies warning styles to indicate negative values.
 * - showParentheses (boolean): If true, negative values are wrapped in parentheses.
 */
const Currency = React.memo(
  ({
    value = 0,
    currency = 'USD',
    sx = {},
    warning = false,
    showParentheses = true,
    target = '',
    ...rest
  }) => {
    // Determine if the value is negative
    const isNegative = value < -0.00001;

    // Format the absolute value to currency
    const formattedValue = Math.abs(value).toLocaleString('en-US', {
      style: 'currency',
      currency,
    });

    // Conditionally wrap the value in parentheses if negative
    const displayValue = isNegative && showParentheses || target === 'brackets'
      ? `(${formattedValue})` : formattedValue;

    // Define conditional styles
    const conditionalStyles = warning
      ? {
        color: 'error.main', // Red color for warnings
        ...sx,
      }
      : {
        color: 'text.primary', // Default text color
        ...sx,
      };

    return (
      <Typography sx={conditionalStyles} {...rest}>
        <strong>{displayValue}</strong>
      </Typography>
    );
  }
);

// Define PropTypes for type checking
Currency.propTypes = {
  value: PropTypes.number,
  currency: PropTypes.string,
  sx: PropTypes.object,
  warning: PropTypes.bool,
  showParentheses: PropTypes.bool,
};

// Set display name for debugging purposes
Currency.displayName = 'Currency';

export default Currency;
