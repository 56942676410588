import { useEffect, useState } from 'react';
import Hidden from '@mui/material/Hidden';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';
import { useIsWidthDown, useIsWidthUp } from 'hooks/mediaQuery';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { menuAvailableStyles } from 'utils/menu';
const tabRoutes = [
  { label: 'overview_', path: 'overview' },
  { label: 'categories_', path: 'categories' },
  { label: 'items_', path: 'items' },
  { label: 'popular_items', path: 'popularItems' },
  { label: 'modifier_groups', path: 'modifierGroups' },
];
const MasterMenuTabs = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = history.location;
  const mdDown = useIsWidthDown('md');
  const mdUp = useIsWidthUp('md');
  const [value, setValue] = useState(pathname || '/menu/overview');
  const { menu: mainMenu } = useSelector((store) => store);

  const handleChange = (event, newValue) => {
    history.push(newValue);
    localStorage.setItem('currentTab', newValue.slice(9, newValue.length));
  };

  const handleChangeMenuItem = (event) => {
    history.push(event.target.value);
    localStorage.setItem('currentTab', event.target.value.slice(9, event.target.value?.length));
  };
  useEffect(() => {
    setValue(history.location.pathname);

    return () => {
      !history.location.pathname.includes('/settings') && localStorage.removeItem('currentTab');
    };
  }, [history.location]);
  return (
    <Box sx={menuAvailableStyles(mainMenu?.menuAvailable)}>
      <Hidden mdDown={mdDown}>
        <Tabs value={value} onChange={handleChange}>
          {tabRoutes.map(({ path, label }, index) => (
            <Tab
              sx={{
                pl: 2,
                pr: 2,
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
              }}
              key={uuidv4()}
              value={`/menu/${path}`}
              label={t(label)}
            />
          ))}
        </Tabs>
      </Hidden>
      <Hidden mdUp={mdUp}>
        <FormControl sx={{ mb: 1, border: 'none', pl: 2 }}>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            size="small"
            variant="outlined"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={handleChangeMenuItem}
          >
            {tabRoutes.map(({ path, label }, index) => (
              <MenuItem key={uuidv4()} value={`/menu/${path}`}>
                {t(label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Hidden>
    </Box>
  );
};

export default MasterMenuTabs;
