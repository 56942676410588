import {
  ProviderInfoHeaderWrapper,
  ProviderInfoHeaderWrapperTitle,
  ProviderInfoStatusInnerBlock,
  ProviderInfoStatusItem,
  ProviderInfoStatusItemPrice,
  ProviderInfoStatusItemTitle,
  ProviderInfoStatusWrapper,
  ProviderInfoWrapper,
} from 'components/NewHome/styles';

const ProviderInfo = ({ data }) => {
  return (
    <ProviderInfoWrapper>
      <ProviderInfoHeaderWrapper>
        <img src={data?.img?.src} alt={data?.img?.alt} />
        <ProviderInfoHeaderWrapperTitle>{data?.title}</ProviderInfoHeaderWrapperTitle>
      </ProviderInfoHeaderWrapper>
      <ProviderInfoStatusWrapper>
        {data?.statuses.map((item, index) => {
          return (
            <ProviderInfoStatusInnerBlock
              key={index + 1}
              sx={{
                backgroundColor: item?.backgroundColor,
              }}
            >
              <ProviderInfoStatusItem>
                <img src={item?.img?.src} alt={item?.img?.alt} />
                <ProviderInfoStatusItemTitle>{item?.title}</ProviderInfoStatusItemTitle>
              </ProviderInfoStatusItem>
              <ProviderInfoStatusItemPrice>{`$${item?.price}`}</ProviderInfoStatusItemPrice>
            </ProviderInfoStatusInnerBlock>
          );
        })}
      </ProviderInfoStatusWrapper>
    </ProviderInfoWrapper>
  );
};

export default ProviderInfo;
