import { useCallback, useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import ActionButtons from './actions';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import SubscriptionPlans from 'components/SubscriptionPlans';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { billingInfoFormValidationSchema, billingInfoFormValidationSchemaForWix } from './signupSchemas';
import { removeItemAfterSignUp } from 'utils/soldOut';
import { Box, Typography } from '@mui/material';
import GooglAutocomplete from 'components/FormFields/GoogleAutocomplate';
import { signupBillingAfterCallStripeV2, signupBillingAfterCallStripeV2External } from 'redux/actions/userAuthV2';
import CreditCardDetail from 'components/CreditCardDetails/CreditCardDetail';
import { SIGNUP_STEPS, SignUpBillingMessages, SignUpPathEnum } from 'constants/signUpPath';
import useQuery from 'hooks/useQuery';
import { PaymentPeriodicityContext } from 'context/paymentPeriodicityContext';
import { SubscriptionContext } from 'context/subscriptionPlanConfigContext';
import ApplyCoupon from 'components/ApplyCoupon/ApplyCoupon';
import AcceptRequirements from 'components/ApplyCoupon/AcceptRequirements';
import { useTranslation } from 'react-i18next';
import LabelRequired from 'components/FormFields/LabelRequired';
import { patchSignUpProgress } from 'redux/actions/user';
import useTabClose from 'hooks/useTabClose';
import { PaymentPeriodicityEnum } from '../../../constants/config';

const data = localStorage.getItem('billingInfo');
const parsedData = data && JSON.parse(data);
const billingInfoInitialData = parsedData ? parsedData : { addressInfo: {} };

const BillingInfo = ({ active, steps, externalToken = '', externalPlanId = '', corporateBusiness = '' }) => {
  const { t } = useTranslation();

  const stripe = useStripe();
  const history = useHistory();
  const query = useQuery();
  const businessId = query.get('businessId');
  const legalEntityId = query.get('legalEntityId');
  const userId = query.get('userId');
  const planId = query.get('planId');
  const couponValueCode = query.get('couponValue');
  const isApplyValue = query.get('isApply');
  const element = useElements();
  const [loading, setLoading] = useState(false);
  const [isApply, setIsApplay] = useState(Boolean(isApplyValue) || false);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [cardError, handleCardError] = useState(false);
  const [selectedPlan, handleSelectedPlan] = useState(planId);
  const [selectedPlanPrice, getSelectedPlanPrice] = useState('');
  const { periodicity } = useContext(PaymentPeriodicityContext);
  const { setUpFeeEnabled } = useContext(SubscriptionContext);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [couponValue, setCouponValue] = useState(setUpFeeEnabled ? couponValueCode : '' || '');

  const [checkNewPlans, setCheckNewPlans] = useState(false);
  const [acceptAgreeToReceiveOrderCommunication, setAcceptAgreeToReceiveOrderCommunication] = useState(false);
  const [acceptAgreeToReceiveOrderCommunicationError, setAcceptAgreeToReceiveOrderCommunicationError] = useState(false);
  const [agreeToPayRecurringCharges, setAgreeToPayRecurringCharges] = useState(false);
  const [agreeToPayRecurringChargesError, setAgreeToPayRecurringChargesError] = useState(false);
  const [agreeToPaySetupFee, setAgreeToPaySetupFee] = useState(false);
  const [agreeToPaySetupFeeError, setAgreeToPaySetupFeeError] = useState(false);
  const progressUrl = `${window.location.origin}${window.location.pathname}${window.location.search}`;
  useTabClose();
  const currentSchema =
    externalPlanId && externalToken && corporateBusiness
      ? billingInfoFormValidationSchemaForWix
      : billingInfoFormValidationSchema;

  const billingFormMethods = useForm({
    defaultValues: billingInfoInitialData,
    mode: 'all',
    resolver: yupResolver(currentSchema),
  });

  const {
    formState: { isSubmitted },
  } = billingFormMethods;
  const currentModel = billingFormMethods.getValues();

  useEffect(() => {
    const data = localStorage.getItem('billingInfo');
    const parsedData = data && JSON.parse(data);
    if (parsedData) {
      billingFormMethods.reset(parsedData);
    }
  }, [billingFormMethods]);

  const getAddressFieldsHandle = (addressInfo) => {
    const model = { ...currentModel, addressInfo: { ...currentModel.addressInfo, ...addressInfo } };
    billingFormMethods.reset(model, {
      keepIsSubmitted: true,
      keepDirty: true,
      keepDefaultValues: true,
    });
  };

  useEffect(() => {
    if (
      !checkNewPlans &&
      billingFormMethods.formState.isValid &&
      selectedPlan &&
      acceptAgreeToReceiveOrderCommunication
    ) {
      setNextDisabled(false);
    }
    if (
      billingFormMethods.formState.isValid &&
      selectedPlan &&
      acceptAgreeToReceiveOrderCommunication &&
      agreeToPayRecurringCharges &&
      agreeToPaySetupFee &&
      checkNewPlans
    ) {
      //acceptCheckBox,
      setNextDisabled(false);
    }
  }, [
    billingFormMethods.formState.isValid,
    selectedPlan,
    agreeToPayRecurringCharges,
    acceptAgreeToReceiveOrderCommunication,
    nextDisabled,
    agreeToPaySetupFee,
    checkNewPlans,
  ]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const openWix = (providerId) => {
    let APP_ID = process.env.REACT_APP_WIX_APP_ID;
    let redirect_url = process.env.REACT_APP_API_STAGE_URL;
    let wixURL = `https://www.wix.com/installer/install`;
    if (externalToken) {
      localStorage.setItem('providerIdWix', providerId);
      window.open(`${wixURL}?appId=${APP_ID}&redirectUrl=${redirect_url}&token=${externalToken}`, '_blank');
    }
  };

  const stripeFormSubmit = useCallback(
    (values) => {
      values['oneTimeSetupFeeCouponCode'] = couponValue || '';
      const stripeAddress = {
        address_city: values?.addressInfo?.city || '',
        address_line1: values?.addressInfo?.address1 || '',
        address_line2: values?.addressInfo?.address2 || '',
        address_country: values?.addressInfo?.country || '',
        address_state: values?.addressInfo?.state || '',
        address_zip: values?.addressInfo?.zipCode || '',
      };
      if (cardError) {
        setLoading(false);
        return toast.error(cardError);
      }
      if (!stripe) {
        setLoading(false);
        return toast.error(SignUpBillingMessages.DOESNT_LOAD);
      }
      setLoading(true);
      const ids = JSON.parse(localStorage.getItem('legalResponseInfo'));
      const account = JSON.parse(localStorage.getItem('accountInfo'));

      if (externalToken && externalPlanId && corporateBusiness) {
        let data = {
          plan: externalPlanId,
          corporateBusiness: corporateBusiness,
          legalEntityId: legalEntityId || ids?.legalEntityId,
          businessId: businessId || ids?.businessId,
          userId: userId || account?.userId,
          paymentTerm: PaymentPeriodicityEnum.monthly,
          oneTimeSetupFeeCouponCode: isApply ? values.oneTimeSetupFeeCouponCode || '' : '',
          employeeNumber: isApply ? values.oneTimeSetupFeeCouponCode || '' : '',
          agreeToPaySetupFee: Number(setUpFeeEnabled) === 1 ? agreeToPaySetupFee : false,
          agreeToPayRecurringCharges: agreeToPayRecurringCharges,
          agreeToReceiveOrderCommunication: acceptAgreeToReceiveOrderCommunication,
          metadata: {
            addressPlaceId: currentModel.addressInfo?.placeId,
            externalToken: externalToken,
            corporateBusiness: corporateBusiness,
          },
          isExternalSubscription: Boolean(externalToken && externalPlanId),
        };
        return signupBillingAfterCallStripeV2External(data)
          .then(() => {
            patchSignUpProgress({
              step: SIGNUP_STEPS.billing,
              url: progressUrl,
              businessId: businessId || ids?.businessId,
            })
              .then(() => {
                removeItemAfterSignUp();
                openWix(corporateBusiness);
              })
              .catch(() => {
                setLoading(false);
                setNextDisabled(false);
              });
          })
          .finally(() => {
            setNextDisabled(false);
            setLoading(false);
          });
      } else {
        stripe
          .createToken(element.getElement('cardNumber'), stripeAddress)
          .then((res) => {
            if (res.error) {
              setLoading(false);
              return toast.error(res.error.message);
            }
            const {
              token: { id, created },
              token,
            } = res;
            if (token && id && created) {
              let data = {
                stripeToken: token.id,
                plan: selectedPlan,
                legalEntityId: legalEntityId || ids?.legalEntityId,
                businessId: businessId || ids?.businessId,
                userId: userId || account?.userId,
                paymentTerm: periodicity,
                oneTimeSetupFeeCouponCode: isApply ? values.oneTimeSetupFeeCouponCode || '' : '',
                employeeNumber: isApply ? values.oneTimeSetupFeeCouponCode || '' : '',
                agreeToPaySetupFee: Number(setUpFeeEnabled) === 1 ? agreeToPaySetupFee : false,
                agreeToPayRecurringCharges: agreeToPayRecurringCharges,
                agreeToReceiveOrderCommunication: acceptAgreeToReceiveOrderCommunication,
                metadata: {
                  addressPlaceId: currentModel.addressInfo?.placeId,
                },
              };

              return signupBillingAfterCallStripeV2(data)
                .then(() => {
                  const valuesWithStripe = {
                    ...values,
                    token: token.id,
                    card: {
                      ...token?.card,
                    },
                  };
                  valuesWithStripe.customerId = token.id;
                  patchSignUpProgress({
                    step: SIGNUP_STEPS.billing,
                    url: progressUrl,
                    businessId: businessId || ids?.businessId,
                  })
                    .then(() => {
                      history.push(SignUpPathEnum.SIGN_UP_SUCCESS);
                      removeItemAfterSignUp();
                    })
                    .catch(() => {
                      setLoading(false);
                      setNextDisabled(false);
                    });
                })
                .finally(() => {
                  setNextDisabled(false);
                });
            } else {
              setLoading(false);
              return toast.error(SignUpBillingMessages.REQUIRE_CARD);
            }
          })
          .catch(() => {
            setLoading(false);
            setNextDisabled(false);
            // return toast.error(err.message);
          })
          .finally(() => {
            setNextDisabled(false);
          });
      }
    },
    [
      data,
      stripe,
      cardError,
      selectedPlan,
      couponValue,
      agreeToPayRecurringCharges,
      acceptAgreeToReceiveOrderCommunication,
      agreeToPaySetupFee,
      isApply,
      checkNewPlans,
      periodicity,
      setUpFeeEnabled,
      externalToken,
      externalPlanId,
      corporateBusiness,
    ]
  );

  const isValidGoogleValue = useCallback((isValid) => {
    return setNextDisabled(isValid);
  }, []);

  const hadlePlanChoose = (plandIds) => {
    handleSelectedPlan(plandIds);
    console.log('>>>>>>>>>>');
    let url =
      window.origin +
      history.location.pathname +
      `?businessId=${businessId}&legalEntityId=${legalEntityId}&userId=${userId}&term=${periodicity}`;
    if (plandIds) {
      url += `&planId=${plandIds}`;
    }
    window.history.pushState({ path: url }, '', url);
  };

  //newFunctions For accepting
  const handleAcceptAgreeToReceiveOrderCommunication = ({ target }) => {
    setAcceptAgreeToReceiveOrderCommunication(target.checked);
    !target.checked
      ? setAcceptAgreeToReceiveOrderCommunicationError(true)
      : setAcceptAgreeToReceiveOrderCommunicationError(false);
  };

  const handleAcceptAgreeToPayRecurringCharges = ({ target }) => {
    setAgreeToPayRecurringCharges(target.checked);
    !target.checked ? setAgreeToPayRecurringChargesError(true) : setAgreeToPayRecurringChargesError(false);
  };

  const handleAcceptSetupFree = ({ target }) => {
    setAgreeToPaySetupFee(target.checked);
    !target.checked ? setAgreeToPaySetupFeeError(true) : setAgreeToPaySetupFeeError(false);
  };

  return (
    <>
      <FormProvider {...billingFormMethods}>
        <form autoComplete="off" onSubmit={billingFormMethods.handleSubmit(stripeFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {externalToken && externalPlanId && corporateBusiness ? (
                <></>
              ) : (
                <>
                  <Typography sx={{ mb: 2, mt: 2, fontSize: 20, fontWeight: 'bold' }}>
                    {t('sign_up.choose_subscription_plan')}
                  </Typography>
                  <Box sx={{ minHeight: 400 }}>
                    <SubscriptionPlans
                      required={true}
                      isSubmitted={isSubmitted}
                      getSelectedPlanPrice={getSelectedPlanPrice}
                      selectedPlanPrice={selectedPlanPrice}
                      selectedPlan={selectedPlan}
                      target="signup"
                      hadlePlanChoose={hadlePlanChoose}
                      setSelectedPlanData={setSelectedPlanData}
                      setCheckNewPlans={setCheckNewPlans}
                      couponValue={couponValue}
                    />
                  </Box>
                </>
              )}
            </Grid>
            {externalToken && externalPlanId && corporateBusiness ? (
              <></>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography sx={{ mt: 2, mb: 1, fontSize: 20, fontWeight: 'bold' }}>
                    {t('sign_up.payment_details')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CreditCardDetail handleCardError={handleCardError} />
                </Grid>
              </>
            )}
            {externalToken && externalPlanId && corporateBusiness ? (
              <></>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography sx={{ mt: 2, fontSize: 20, fontWeight: 'bold' }}>
                    {t('settings.billing_address')}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12} sx={{ height: '74px' }}>
                  <GooglAutocomplete
                    getAddressFields={(data) => getAddressFieldsHandle(data)}
                    name="addressInfo.address1"
                    isValidGoogleValue={isValidGoogleValue}
                    initialValue={currentModel?.addressInfo?.address1 || ''}
                    inputValue={currentModel?.addressInfo?.address1 || ''}
                    label={<LabelRequired>{t('batch_report.address')}</LabelRequired>}
                    placeholder=""
                    required={true}
                    formSubmitted={billingFormMethods.isSubmitted}
                  />
                </Grid>
                <Grid item md={12} xs={12} sx={{ height: '74px' }}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    defaultValue={currentModel.addressInfo.address2 || ''}
                    name="addressInfo.address2"
                    label={t('order_view.address2')}
                  />
                </Grid>
                <Grid item md={4} xs={12} sx={{ height: '74px' }}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    defaultValue={currentModel.city || ''}
                    name="addressInfo.city"
                    label={<LabelRequired>{t('settings.city')}</LabelRequired>}
                  />
                </Grid>
                <Grid item md={4} xs={12} sx={{ height: '74px' }}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    defaultValue={currentModel.state || ''}
                    name="addressInfo.state"
                    label={<LabelRequired>{t('settings.state')}</LabelRequired>}
                  />
                  <CustomTextInput hidden name="addressInfo.country" />
                </Grid>
                <Grid item md={4} xs={12} sx={{ height: '74px' }}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    defaultValue={currentModel.zipCode || ''}
                    name="addressInfo.zipCode"
                    label={<LabelRequired>{t('settings.zip_code')}</LabelRequired>}
                  />
                </Grid>
                {selectedPlan && selectedPlan !== 'null' && checkNewPlans ? (
                  <Grid item xs={12}>
                    <ApplyCoupon
                      couponValue={couponValue}
                      setCouponValue={setCouponValue}
                      setIsApplay={setIsApplay}
                      handleAcceptSetupFree={handleAcceptSetupFree}
                      acceptAgreeToPaySetupFeeCharges={agreeToPaySetupFee}
                      acceptAgreeToPaySetupFee={agreeToPaySetupFeeError}
                      checkNewPlans={checkNewPlans}
                      selectedPlan={selectedPlan}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </>
            )}
            <Grid item xs={12}>
              <AcceptRequirements
                handleAcceptAgreeToReceiveOrderCommunication={handleAcceptAgreeToReceiveOrderCommunication}
                acceptAgreeToReceiveOrderCommunication={acceptAgreeToReceiveOrderCommunication}
                acceptAgreeToReceiveOrderCommunicationError={acceptAgreeToReceiveOrderCommunicationError}
                selectedPlan={selectedPlan}
                handleAcceptAgreeToPayRecurringCharges={handleAcceptAgreeToPayRecurringCharges}
                agreeToPayRecurringCharges={agreeToPayRecurringCharges}
                agreeToPayRecurringChargesError={agreeToPayRecurringChargesError}
                selectedPlanData={selectedPlanData}
                selectedPlanPrice={selectedPlanPrice}
                checkNewPlans={checkNewPlans}
              />
            </Grid>
            {externalToken && externalPlanId && corporateBusiness ? (
              <Grid item xs={12}>
                <ActionButtons
                  id="billingInfoViewSubmit"
                  title={t('order_view.submit')}
                  active={active}
                  steps={steps}
                  loading={loading}
                  disabled={!acceptAgreeToReceiveOrderCommunication}
                  hideBackButton={true}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                {!setUpFeeEnabled ? (
                  <ActionButtons
                    id="billingInfoViewSubmit"
                    title={t('order_view.submit')}
                    active={active}
                    steps={steps}
                    loading={loading}
                    disabled={
                      !billingFormMethods.formState.isValid ||
                      !selectedPlan ||
                      (checkNewPlans && (!acceptAgreeToReceiveOrderCommunication || !agreeToPayRecurringCharges)) ||
                      !acceptAgreeToReceiveOrderCommunication
                    }
                    hideBackButton={true}
                  />
                ) : (
                  <ActionButtons
                    id="billingInfoViewSubmit"
                    title={t('order_view.submit')}
                    active={active}
                    steps={steps}
                    loading={loading}
                    disabled={
                      !billingFormMethods.formState.isValid ||
                      !selectedPlan ||
                      nextDisabled ||
                      (checkNewPlans &&
                        (!acceptAgreeToReceiveOrderCommunication ||
                          !agreeToPayRecurringCharges ||
                          !agreeToPaySetupFee)) ||
                      !acceptAgreeToReceiveOrderCommunication
                    }
                    hideBackButton={true}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default BillingInfo;
