import { memo } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import ActionFooter from 'redesign/Shared/ActionFooter';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import CustomMobilePhone from 'components/MobilePhone/CustomMobilePhone';
import { InputMode, Styles, StylesSmall } from 'redesign/AccountTab/config';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import { useAccountInformation } from 'redesign/AccountTab/hooks/useAccountInformation';

const AccountInformation = ({ disabled, onCancel, id }) => {
  const { t } = useTranslation();
  const { handleSubmit, loading, accountInformationFormMethods } = useAccountInformation(disabled);

  return (
    <Box sx={{ m: 3 }}>
      <ConfirmPromptModal
        handleConfirm={accountInformationFormMethods.handleSubmit(handleSubmit)}
        submitData={accountInformationFormMethods.getValues()}
        hasUnsavedChanges={accountInformationFormMethods.formState.isDirty}
        formState={accountInformationFormMethods}
      />
      <FormProvider {...accountInformationFormMethods}>
        <form onSubmit={accountInformationFormMethods.handleSubmit(handleSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: !disabled ? 'flex' : '', gap: !disabled ? '16px' : '' }}>
              <CustomTextInput
                sx={StylesSmall}
                name="firstName"
                label={<LabelRequired hide={disabled}>{t('account_information.first_name')}</LabelRequired>}
                disabled={disabled}
                variant={disabled ? InputMode.standard : InputMode.outlined}
                id={disabled ? InputMode.standard_Basic : InputMode.outlined_Basic}
              />
              <CustomTextInput
                sx={StylesSmall}
                name="lastName"
                label={<LabelRequired hide={disabled}>{t('account_information.last_name')}</LabelRequired>}
                disabled={disabled}
                variant={disabled ? InputMode.standard : InputMode.outlined}
                id={disabled ? InputMode.standard_Basic : InputMode.outlined_Basic}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomMobilePhone
                sx={Styles}
                name="phone"
                label={<LabelRequired hide={disabled}>{t('Phone')}</LabelRequired>}
                disabled={disabled}
                variant={disabled ? InputMode.standard : InputMode.outlined}
                id={disabled ? InputMode.standard_Basic : InputMode.outlined_Basic}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
            {!disabled && (
              <Grid item xs={12}>
                <ActionFooter
                  onCancel={onCancel}
                  id={id}
                  loading={loading}
                  disabled={
                    !accountInformationFormMethods.formState.isValid ||
                    !accountInformationFormMethods.formState.isDirty ||
                    loading
                  }
                />
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};
export default memo(AccountInformation);
