import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useMatchMobile = (key = 'md') => {
  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down(key || 'md'));

  return { isMatchMobile: matchesMobile };
};

export const useMatchTablet = () => {
  const theme = useTheme();
  const matchestablet = useMediaQuery(theme.breakpoints.down('lg'));

  return { isMatchTablet: matchestablet };
};
