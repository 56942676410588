import {
  SET_CHARGE_BACK,
  SET_CHARGE_BACK_LOADING,
  SET_CHARGE_SELECTED,
  SET_CHARGE_SELECTED_LOADING,
} from 'components/Chargeback/constants';

const { default: instance } = require('services/api');

export const getChargeBackList = (state) => (dispatch) => {
  dispatch({
    type: SET_CHARGE_BACK_LOADING,
    payload: true,
  });
  return instance
    .get(`/chargeback?state=${state}`)
    .then(({ data: { data } }) => {
      dispatch({
        type: SET_CHARGE_BACK,
        payload: {
          states: {
            [state]: data,
          },
        },
      });
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({
        type: SET_CHARGE_BACK_LOADING,
        payload: false,
      });
    });
};

export const getChargeBackById = (id) => (dispatch) => {
  dispatch({ type: SET_CHARGE_SELECTED_LOADING, payload: true });
  return instance
    .get(`/chargeback/${id}`)
    .then(({ data: { data } }) => {
      dispatch({ type: SET_CHARGE_SELECTED, payload: data });
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({ type: SET_CHARGE_SELECTED_LOADING, payload: false });
    });
};

export const acceptChargeBack = (id) => {
  return instance
    .put(`/chargeback/accept/${id}`)
    .then(({ data: { data } }) => {
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const disputeChargeBack = (body) => {
  return instance
    .put(`/chargeback/dispute/${body.id}`, body.data)
    .then(({ data: { data } }) => {
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getChargeBackReport = (printingData) => {
  return instance
    .post(`/reports/chargeback`, printingData)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
