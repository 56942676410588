import {
  FullModeInnerWrapper,
  FullModeWrapper,
  PaymentStyledRadioGroup,
  TypeTitle,
  TypeTitleSecond,
} from '../../Dialog/styles';
import { PROVIDER_MODE } from 'pages/Providers/Providers';
import UncheckIcon from 'assets/icons/uncheck.png';
import CheckIcon from 'assets/icons/check.png';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';

const ReadOnlyContent = ({ handleChange, value, providerName }) => {
  const { t } = useTranslation();
  return (
    <>
      <TypeTitle>{t('providers_settings.type_message')}</TypeTitle>
      <TypeTitleSecond>{t('providers_settings.type_message_sub', { provider: providerName })}</TypeTitleSecond>
      <TypeTitleSecond>{t('providers_settings.type_message_inner_sub')}</TypeTitleSecond>
      <FullModeWrapper
        onClick={() => handleChange(PROVIDER_MODE.FULL)}
        sx={{
          cursor: 'pointer',
          border: `1px solid ${value === PROVIDER_MODE.FULL ? '#2D7FF9' : 'rgba(0, 0, 0, 0.12)'}`,
        }}
      >
        <FullModeInnerWrapper>
          <FormControl>
            <PaymentStyledRadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="full"
              name="radio-buttons-group"
              value={value}
            >
              <FormControlLabel
                value="full"
                control={
                  <Radio
                    icon={<img src={UncheckIcon} alt="uncheck" />}
                    checkedIcon={<img src={CheckIcon} alt="checked" />}
                  />
                }
                label="Full"
                sx={{ '& .MuiFormControlLabel-label ': { fontWeight: 700, ml: 1 } }}
              />
            </PaymentStyledRadioGroup>
          </FormControl>
          <TypeTitleSecond sx={{ ml: 5.5 }}>{t('providers_settings.full_text')}</TypeTitleSecond>
        </FullModeInnerWrapper>
      </FullModeWrapper>
      <FullModeWrapper
        onClick={() => handleChange(PROVIDER_MODE.READONLY)}
        sx={{
          mt: 2,
          cursor: 'pointer',
          border: `1px solid ${value === PROVIDER_MODE.READONLY ? '#2D7FF9' : 'rgba(0, 0, 0, 0.12)'}`,
        }}
      >
        <FullModeInnerWrapper>
          <PaymentStyledRadioGroup>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="readonly"
              name="radio-buttons-group"
              value={value}
            >
              <FormControlLabel
                value="readonly"
                control={
                  <Radio
                    icon={<img src={UncheckIcon} alt="uncheck" />}
                    checkedIcon={<img src={CheckIcon} alt="checked" />}
                  />
                }
                label="Read-Only"
                sx={{ '& .MuiFormControlLabel-label ': { fontWeight: 700, ml: 1 } }}
              />
            </RadioGroup>
          </PaymentStyledRadioGroup>
          <TypeTitleSecond sx={{ ml: 5.5 }}>{t('providers_settings.readonly_text')}</TypeTitleSecond>
        </FullModeInnerWrapper>
      </FullModeWrapper>
    </>
  );
};

export default ReadOnlyContent;
