import Sales from 'components/NewHome/Sales/Sales';
import TopSellingItems from 'components/NewHome/TopSellingItems';
import ChargeBackReport from 'components/NewHome/ChargeBack/ChargeBackReport';
import ProviderReport from 'components/NewHome/ProviderReport/ProviderReport';

const NewHome = () => {
  return (
    <>
      <ChargeBackReport />
      <Sales />
      <ProviderReport />
      <TopSellingItems />
    </>
  );
};

export default NewHome;
