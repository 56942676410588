import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import { DAYS } from 'constants/hours';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import OfficeHours from './OfficeHours';
import { useTranslation } from 'react-i18next';

const initialDisableTime = {
  mon: { hours: [], mins: [] },
  tue: { hours: [], mins: [] },
  wed: { hours: [], mins: [] },
  thu: { hours: [], mins: [] },
  fri: { hours: [], mins: [] },
  sat: { hours: [], mins: [] },
  sun: { hours: [], mins: [] },
};

const Hours = ({ businessModel, data, onChange }) => {
  const [disabledTime, setDisabledTime] = useState(initialDisableTime);
  const { t } = useTranslation();

  const removeTime = (k, i) => {
    const newArr = [...data[k].hours];
    newArr.splice(i, 1);
    const model = {
      ...data,
      [k]: {
        ...data[k],
        hours: newArr,
      },
    };
    onChange(model);
    businessModel.setValue('workingHours', model, { shouldDirty: true });
  };

  const checkDay = (day, value) => {
    onChange((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        hours: [{ start: '00:00', end: '23:59' }],
        opened: value,
      },
    }));
    businessModel.setValue(
      'workingHours',
      {
        ...data,
        [day]: {
          ...data[day],
          hours: [{ start: '00:00', end: '23:59' }],
          opened: value,
        },
      },
      { shouldDirty: true }
    );
  };

  const addTime = (day) => {
    const time = data[day].hours[data[day].hours.length - 1];

    if (time.start || time.end) {
      onChange((prev) => ({
        ...prev,
        [day]: {
          ...prev[day],
          hours: [...prev[day].hours, { start: '00:00', end: '23:59' }],
        },
      }));
      businessModel.setValue(
        'workingHours',
        {
          ...data,
          [day]: {
            ...data[day],
            hours: [...data[day].hours, { start: '00:00', end: '23:59' }],
          },
        },
        { shouldDirty: true }
      );
    }
  };

  const setClose = (day, index, value) => {
    onChange((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        hours: prev[day].hours.map((item, i) => (i === index ? { ...item, end: value } : item)),
      },
    }));
    businessModel.setValue(
      'workingHours',
      {
        ...data,
        [day]: {
          ...data[day],
          hours: data[day].hours.map((item, i) => (i === index ? { ...item, end: value } : item)),
        },
      },
      { shouldDirty: true }
    );
  };

  const setOpen = (day, index, value) => {
    onChange((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        hours: prev[day].hours.map((item, i) => (i === index ? { ...item, start: value } : item)),
      },
    }));
    businessModel.setValue(
      'workingHours',
      {
        ...data,
        [day]: {
          ...data[day],
          hours: data[day].hours.map((item, i) => (i === index ? { ...item, start: value } : item)),
        },
      },
      { shouldDirty: true }
    );
  };

  const diffByHoursDuration = (start, end) => {
    const timeByMomentDate = moment(start, 'HH:mm:ss a');
    const endime = moment(end, 'HH:mm:ss a');

    const startIsBefore = timeByMomentDate.isBefore(endime);
    return startIsBefore;
  };

  function onChangeStart(day, index, value) {
    if (value) {
      const time = moment(value).format('HH:mm');

      setOpen(day, index, time);
    }
  }

  function onChangeEnd(day, index, value) {
    const time = moment(value).format('HH:mm');

    setClose(day, index, time);
  }

  useEffect(() => {
    Object.keys(DAYS).forEach((dayItem, indexDay) => {
      const { hours, opened } = data[dayItem];
      const nextDay = Object.keys(DAYS)[indexDay + 1] || Object.keys(DAYS)[0];
      if (!opened) {
        data[dayItem].hours = [{ start: '00:00', end: '23:59' }];
        onChange(data);
      }
      if (hours.length && data[nextDay].hours.length && opened) {
        hours.forEach((itemHour, index) => {
          if (opened) {
            const itemHourStart = itemHour.start;
            const itemHourEnd = itemHour.end;
            const lastRangeEndHour = hours[hours.length - 1].end.split(':')[0];

            const nexDayHours = data[nextDay].hours;
            const splittedNextDayHour = nexDayHours[nexDayHours.length - 1].start.split(':')[0];

            const durationByHours = diffByHoursDuration(itemHourStart, itemHourEnd);

            if (!durationByHours) {
              if (Number(splittedNextDayHour) <= Number(lastRangeEndHour)) {
                onChange(data);
              }
              const nextDayCapitalize = nextDay.charAt(0).toUpperCase() + nextDay.slice(1);
              setDisabledTime((prev) => ({
                ...prev,
                [dayItem]: { ...prev[dayItem], [`label${index}`]: nextDayCapitalize },
              }));
            } else {
              setDisabledTime((prev) => {
                return {
                  ...prev,
                  [dayItem]: { ...prev[dayItem], [`label${index}`]: '' },
                };
              });
            }
          }
        });
      }
    });
  }, [data]);

  const handleOpenFullDay = useCallback(
    (value, day) => {
      const workingHours = { ...data };
      Object.keys(DAYS).forEach((dayItem, indexDay) => {
        if (day === dayItem && value) {
          workingHours[dayItem].isOpen24 = value;
          if (workingHours[dayItem].hours.length > 1) {
            workingHours[dayItem].hours.splice(1, 1);
          }
          workingHours[dayItem].hours[0].start = '00:00';
          workingHours[dayItem].hours[0].end = '23:59';
        } else {
          workingHours[dayItem].isOpen24 = value;
        }
      });
      onChange(workingHours);
    },
    [data]
  );
  return (
    <div className="mt-4">
      <Typography variant="h5">
        <LabelTooltip id="printerSettings" value={t('settings.working_hours')} tooltip="" />
      </Typography>
      <Card className="working-hours">
        <CardContent className="p-0">
          <Table className="hours-table working-hours">
            <tbody>
              {Object.keys(DAYS).map((day) => (
                <tr key={day}>
                  <td className="days-col w-85">{t(`settings.${day}`)}</td>
                  <td className="days-col">
                    <Stack direction="row">
                      <Box sx={{ width: '50px', display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="body2"
                          sx={{ color: (theme) => theme.palette.text.secondary, mr: 1, fontWeight: 800 }}
                        >
                          {data[day].opened ? t('settings.open') : t('orders.closed')}
                        </Typography>
                      </Box>

                      <Switch
                        checked={data[day].opened}
                        onChange={({ target: { checked } }) => checkDay(day, checked)}
                        color="primary"
                      />
                    </Stack>
                  </td>
                  <td className="hours-col">
                    <OfficeHours
                      day={day}
                      disabledTime={disabledTime}
                      onChangeStart={onChangeStart}
                      onChangeEnd={onChangeEnd}
                      removeTime={removeTime}
                      handleOpenFullDay={handleOpenFullDay}
                      times={data[day].hours}
                      checked={data[day].opened}
                      fullDayChecked={
                        data[day].hours.length < 2 &&
                        data[day].hours[0]?.start == '00:00' &&
                        data[day].hours[0]?.end == '23:59'
                      }
                    />
                  </td>
                  <td className="action-col">
                    <div className="d-flex justify-content-end">
                      <Button
                        id="hoursAdd"
                        variant="contained"
                        color="primary"
                        onClick={() => addTime(day)}
                        disabled={!data[day].opened || data[day].hours.length >= 2}
                      >
                        <i className="icon icon-plus"></i> {t('settings.add')}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default Hours;
