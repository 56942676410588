import * as yup from 'yup';

export const prepTimeSchema = yup.object().shape({
  settings: yup.object().shape({
    asap: yup.object().shape({
      disableAsap: yup.boolean(),
      qty: yup.string(),
    }),
  }),
  defaultPrepTime: yup.string(),
  orderBufferTime: yup.object().shape({
    deliveryOrderBufferTime: yup.object().shape({
      enabled: yup.boolean(),
      startTimeMin: yup.number(),
      endTimeMin: yup.number(),
    }),
    pickUpOrderBufferTime: yup.object().shape({
      enabled: yup.boolean(),
      startTimeMin: yup.number(),
      endTimeMin: yup.number(),
    }),
  }),
});

export const prepTimeSchemaProvider = yup.object().shape({
  asap: yup.object().shape({
    disableAsap: yup.boolean(),
    qty: yup.string(),
  }),
  prepTime: yup.string(),
  orderBufferTime: yup.object().shape({
    deliveryOrderBufferTime: yup.object().shape({
      enabled: yup.boolean(),
      startTimeMin: yup.number(),
      endTimeMin: yup.number(),
    }),
    pickUpOrderBufferTime: yup.object().shape({
      enabled: yup.boolean(),
      startTimeMin: yup.number(),
      endTimeMin: yup.number(),
    }),
  }),
});

export const prepTimeInitialProvider = {
  asap: {
    disableAsap: false,
    qty: '',
  },
  prepTime: '',
  orderBufferTime: {
    deliveryOrderBufferTime: {
      enabled: true,
      startTimeMin: 0,
      endTimeMin: 0,
    },
    pickUpOrderBufferTime: {
      enabled: true,
      startTimeMin: 0,
      endTimeMin: 0,
    },
  },
};

export const prepTimeInitial = {
  settings: {
    asap: {
      disableAsap: false,
      qty: '',
    },
  },
  defaultPrepTime: '',
  orderBufferTime: {
    deliveryOrderBufferTime: {
      enabled: true,
      startTimeMin: 0,
      endTimeMin: 0,
    },
    pickUpOrderBufferTime: {
      enabled: true,
      startTimeMin: 0,
      endTimeMin: 0,
    },
  },
};
