
const LabelRequired = ({ children, hide = false }) => {
  return (
    <>
      {children}
      {!hide && <span className="text-danger ml-1">*</span>}
    </>
  );
};

export default LabelRequired;
