import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';

const GrubHubV2Footer = ({ handleBack, disabled, loading }) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        variant="outlined"
        onClick={handleBack}
        sx={{
          mr: 1,
          color: '#626A7A',
          borderColor: '#626A7A',
          '&:hover': { color: '#626A7A', borderColor: '#626A7A', background: 'white' },
        }}
      >
        {t('Back')}
      </Button>
      <LoadingButton loading={loading} variant="contained" type={'submit'} disabled={disabled || loading}>
        {t('Connect')}
      </LoadingButton>
    </>
  );
};
export default GrubHubV2Footer;
