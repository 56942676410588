import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';

const CustomCheckbox = ({ name, id, checked, defaultChecked, sx, label, inputProps, disabled }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControlLabel
            sx={sx}
            control={
              <Checkbox
                disabled={disabled}
                onBlur={field.onBlur}
                onChange={field.onChange}
                checked={field.name === 'onItsOwn' ? checked : field.value}
                inputRef={field.ref}
                // onChange={(e) => field.onChange(e.target.checked)}
                // checked={field.name === 'onItsOwn' ? checked : field.value}
              />
            }
            label={label}
          />
        );
      }}
    />
  );
};

export default CustomCheckbox;
