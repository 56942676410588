// export const MENU_TYPES = {
//   main: 'main',
//   ubereats: 'ubereats',
//   doordash: 'doordash',
//   grubhub: 'grubhub',
//   grubhubv2: 'grubhubv2',
//   ezcater: 'ezcater',
//   fetchme: 'fetchme',
//   website: 'website',
// };

export const CONTEXT_TYPES = {
  menu_item: 'MENU_ITEM',
  modifierItem: 'MOD_ITEM',
  provider: 'PROVIDER',
};

// export const PROVIDERS = {
//   ubereats: 'Uber Eats',
//   doordash: 'Doordash',
//   grubhub: 'GrubHub',
//   website: 'Website',
//   deliverycom: 'DELIVERY COM',
//   grubhubv2: 'GrubHub v2 ',
//   table_ordering: 'Table Ordering',
//   ezcater: 'EzCater',
//   fetchme: 'FetchMe',
// };
