export const SOCKET_EVENT_TYPES = {
  createProduct: 0,
  updateProduct: 1,
  deleteProduct: 2,
  pushProduct: 3,
  sortProduct: 4,

  createCategory: 10,
  updateCategory: 11,
  deleteCategory: 12,
  importCategory: 13,
  pushCategory: 14,
  sortCategories: 15,

  createProvider: 20,
  updateProviderStatus: 21,
  updateProviderState: 22,
  importMenuForProvider: 23,
  pushMenuForProvider: 24,
  clearMenuForProvider: 25,
  updateMenuForProvider: 26,
  deleteMenuForProvider: 27,
  updateResumePauseProvider: 28,
  providerConnectResponse: 29,
  createModifier: 30,
  updateModifier: 31,
  deleteModifier: 32,
  updateModifierOrder: 33,
  groupModifier: 34,
  createModifierList: 35,
  sortModifierList: 36,

  newOrder: 40,
  orderStatusChange: 41,
  orderChangedInProvider: 43,
  orderCancel: 44,

  updateSession: 50,
  createAvailability: 60,
  updateAvailability: 61,
  deleteAvailability: 62,

  pinCodeUpdate: 74,
  paymentNotification: 75,
};
