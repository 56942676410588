import { Box, Typography } from '@mui/material';
import PinInput from 'react-pin-input';
import { useTranslation } from 'react-i18next';

const PinCode = ({ onCompleteCode, pinRef }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ height: '100%', overflow: 'hidden' }}>
      <Typography>{t('store_pin_code.subtitle')}</Typography>
      <Box sx={{ height: '100%' }}>
        <PinInput
          ref={pinRef}
          length={4}
          initialValue=""
          type="numeric"
          inputMode="number"
          style={{
            padding: '10px',
            mt: '122px',
            textAlign: 'center',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            height: '100%',
            display: 'flex',
          }}
          inputStyle={{
            borderColor: '#AAAAAA',
            width: '88px',
            height: '104px',
            // marginLeft: '40px',
            fontSize: '48px',
            boxShadow: ' 0px 0px 16px #F0F0F029',
            border: '1px solid #1373E6',
            borderRadius: '8px',
          }}
          inputFocusStyle={{ borderColor: '#1373E6' }}
          onComplete={onCompleteCode}
          autoSelect={true}
          focus={true}
        />
      </Box>
    </Box>
  );
};

export default PinCode;
