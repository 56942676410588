import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getModifierDataById } from 'redux/actions/Menu/modifiers';

const useModifierById = (id, sizeTypeModifiers) => {
  const dispatch = useDispatch();
  const [currentModifier, setCurrentModifier] = useState(false);
  const [loadingModOpt, setLoadingModOpt] = useState(false);

  useEffect(() => {
    setLoadingModOpt(true);
    dispatch(getModifierDataById(id))
      .then((res) => {
        setCurrentModifier(res);
        setLoadingModOpt(false);
      })
      .catch(() => {
        setLoadingModOpt(false);
      });
  }, [id]);

  return { currentModifier, loadingModOpt, setCurrentModifier, setLoadingModOpt };
};

export default useModifierById;
