import { useMemo } from 'react';
import { Box, Divider } from '@mui/material';
import {
  TopSellingAmount,
  TopSellingAmountQTY,
  TopSellingAmountWrapper,
  TopSellingEmptyMessage,
  TopSellingEmptyMessageText,
  TopSellingInnerBlock,
  TopSellingInnerListItem,
  TopSellingInnerListItemName,
  TopSellingItemSubTitle,
  TopSellingItemTitle,
  TopSellingItemWrapper,
} from 'components/NewHome/styles';
import { useTopSelling } from 'components/NewHome/TopSellingItems/hooks/useTopSelling';

const TopSellingItems = () => {
  const { topSellerList, loading } = useTopSelling();
  const topSellingItems = useMemo(() => topSellerList?.body?.slice(0, 20) || [], [topSellerList?.body]);
  return (
    <>
      {!loading && topSellingItems?.length > 0 ? (
        <TopSellingItemWrapper>
          <TopSellingItemTitle>Top Selling Items</TopSellingItemTitle>
          <TopSellingItemSubTitle>These are the most popular items</TopSellingItemSubTitle>
          <TopSellingInnerBlock>
            {topSellingItems?.map((item, index) => (
              <Box key={index + 1}>
                <TopSellingInnerListItem >
                  <Box>
                    {item?.img ? <img src={item.img.src} alt={item.item_name} /> : null}
                    <TopSellingInnerListItemName>{item.item_name}</TopSellingInnerListItemName>
                  </Box>
                  <TopSellingAmountWrapper>
                    <TopSellingAmountQTY>{`${item.total_qty} sold`}</TopSellingAmountQTY>
                    <TopSellingAmount>{item.total_amount}</TopSellingAmount>
                  </TopSellingAmountWrapper>
                </TopSellingInnerListItem>
                {index !== topSellingItems?.length - 1 ? <Divider /> : null}
              </Box>
            ))}
          </TopSellingInnerBlock>
        </TopSellingItemWrapper>
      ) : (
        <TopSellingItemWrapper>
          <TopSellingItemTitle>Top Selling Items</TopSellingItemTitle>
          <TopSellingItemSubTitle>These are the most popular items</TopSellingItemSubTitle>
          <TopSellingInnerBlock>
            {!loading && topSellingItems?.length === 0 && (
              <TopSellingEmptyMessage>
                <TopSellingEmptyMessageText> There are no Top Selling Items at this time.</TopSellingEmptyMessageText>
              </TopSellingEmptyMessage>
            )}
          </TopSellingInnerBlock>
        </TopSellingItemWrapper>
      )}
    </>
  );
};
export default TopSellingItems;
