import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ConnectModal } from 'components/Providers';
import { useHistory, useLocation } from 'react-router-dom';
import Preloader from 'components/Preloader';
import {
  connectProviderWix,
  connectStoreProviderWix,
  getPosData,
  getProviders,
  getProvidersStatuses,
} from 'redux/actions/providers';
import { withDebounce } from 'helpers/withDeboucne';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import POS from 'pages/Providers/Pos';
// import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import {
  // connectProvider,
  disconnectProvider,
  updateProviderState,
  updateProviderConfirmOrder,
  connectProviderLocation,
  connectProviderUber,
  connectStoreProviderUber,
  connectStoreProvider,
} from 'redux/actions/providers';
import { PROVIDER_CONNECTED } from 'constants/providerStatuses';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { loadOptions } from './loadOptions';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Draggable from 'react-draggable';
import { confirm } from 'components/MenuNew/ConfirmModal';
import useProviderConfig from 'hooks/useProviderConfig';
import { sortableProvidersByStatus } from 'utils/globalUtils';
import ReadOnlyDialog from 'components/Dialog/ReadOnlyDialog';
import ProviderList from 'components/Providers/ProviderList';

export const PROVIDER_MODE = {
  FULL: 'full',
  READONLY: 'readonly',
};
export const PROVIDER_MODAL_STEP = {
  READONLY: 'READONLY',
  CONNECT: 'CONNECT',
};

const Providers = () => {
  const { t } = useTranslation();
  const [mode, setMode] = useState(PROVIDER_MODE.FULL);
  const [providerReadOnlyStep, setProviderReadOnlyStep] = useState(PROVIDER_MODAL_STEP.READONLY);
  const [collectProviderData, setCollectProviderData] = useState(null);
  const { items, providerId, stopResumeOrders, loading, error } = useSelector(({ providers }) => providers);
  const { currentBusiness } = useSelector(({ business }) => business);
  const { providerConnectData } = useSelector(({ providers }) => providers);
  const [selectedStoreId, onChangeSelectedStore] = useState('');
  const [isSelectedStore, setStoreSelected] = useState(false);
  const [targetIsUber, setTargetIsUber] = useState(false);
  const { providers } = useProviderConfig();
  const [modalVisible, setModalVisible] = useState(false);
  const [connectStoresProvider, handleConnectStoreProvide] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [locationsModalVisible, setLocationsModalVisible] = useState(false);
  const [locationsList, handleLocationsList] = useState([]);
  const [userAuthData, handleUserAuthData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [target, setTarget] = useState({});
  const [progress, setProgress] = useState(0);
  const [isDisabled, setDisabled] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const instanceId = searchParams.get('instanceId');
  const token = searchParams.get('token');
  localStorage.removeItem('redirectUrl');
  const dispatch = useDispatch();
  const history = useHistory();
  const [openUberModal, setOpenUberModal] = useState(false);

  const queryStringProviderParam = searchParams.get('provider');


  const handleChange = (item) => setMode(item);

  const handleOpen = () => {
    localStorage.removeItem('mode');
    setOpenUberModal(true);
  };
  const handleClose = () => {
    setOpenUberModal(false);
    setTimeout(() => {
      setMode(PROVIDER_MODE.FULL);
      setProviderReadOnlyStep(PROVIDER_MODAL_STEP.READONLY);
      setCollectProviderData(null);
    }, 350);
  };

  const toggleModal = useCallback((provider) => {
    setProgress(0);
    handleConnectStoreProvide(provider.srv);
    if (provider.srv === 'ubereats') {
      const { restaurantMailbox } = currentBusiness;
      const state = restaurantMailbox.substring(0, restaurantMailbox.indexOf('@'));
      return window.open(
        `https://login.uber.com/oauth/v2/authorize?response_type=code&client_id=68wyGB67xhu1DyxPTjUe6DvJJkTTGt5b&scope=eats.pos_provisioning&state=${state}&redirect_uri=${process.env.REACT_APP_API_PROD_URL}`,
        '_blank'
      );
    }
    if (provider.srv === 'wix') {
      let redirect_url = process.env.REACT_APP_API_STAGE_URL;
      let wix_url = process.env.REACT_APP_WIX_URL;
      return window.open(`${wix_url}?redirectUrl=${redirect_url}`, '_blank');
    }

    if (provider.srv !== providers?.food2?.srv && provider.srv !== providers?.table_ordering?.srv) {
      provider?.name ? setTarget(provider || {}) : setTimeout(() => setTarget({}), 1000);

      dispatch({ type: 'PROVIDER_CONNECTION_STARTED', payload: false });
      dispatch({ type: 'TARGET_PROVIDER_CONNECT', payload: provider.srv });
      handleClose();
      setModalVisible((prev) => !prev);
    } else {
      dispatch(connectProviderLocation({ provider: provider.srv, data: {} }));
    }
  }, []);
  const handleContinue = () => {
    localStorage.setItem('mode', mode);
    setOpenUberModal(false);
    toggleModal(collectProviderData);
  };
  useEffect(() => {
    if (error) {
      setSubmitted(false);
    }
  }, [error]);

  useEffect(() => {
    if (modalVisible) {
      dispatch({ type: 'PROVIDER_CONNECT_CONVENTIONAL_ERROR', payload: '' });
    }
  }, [modalVisible]);

  const toggleLocationsModal = useCallback(() => {
    if (!locationsModalVisible) {
      setLocationsModalVisible(false);
      dispatch({ type: 'EMPTY_PROVIDER_CONNECT_DATA_RESPONSE' });
    }

    setLocationsModalVisible((prev) => !prev);
  }, []);

  const handleConnect = (data) => {
    setSubmitted(true);

    dispatch({ type: 'PROVIDER_CONNECTION_STARTED', payload: true });

    dispatch(connectProviderLocation({ provider: target.srv, data }));
  };

  useEffect(() => {
    let timer;
    // if (progress === 100) {
    //   setSubmitted(false);
    // }
    if (error) {
      setProgress(0);
      clearInterval(timer);
      return;
    }
    if (providerConnectData.connectionStarted) {
      if (providerConnectData.data?.multiStore || providerConnectData.status == 'error') {
        setProgress(100);
        setTimeout(() => {
          handleUserAuthData(providerConnectData.data);
          setLocationsModalVisible(true);
          handleLocationsList(providerConnectData.data.stores);
          if (providerConnectData.data.providers.ubereats) {
            setTargetIsUber(true);
          } else {
            setTargetIsUber(false);
          }
          setModalVisible(false);
          setSubmitted(false);
          dispatch({ type: 'PROVIDER_CONNECTION_STARTED', payload: false });
          clearInterval(timer);
        }, 1000);
      } else {
        if (!providerConnectData.data?.multiStore && progress != 100) {
          timer = setInterval(() => {
            setProgress((oldProgress) => {
              if (oldProgress >= 100) {
                return 0;
              }
              return Math.min(oldProgress + 1, 100);
            });
          }, 1500);
        }
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [providerConnectData, error]);

  const handleConnectLocation = () => {
    setStoreSelected(true);
    if (!selectedStoreId.value) return;
    setLoading(true);
    if (connectStoresProvider === 'wix') {
      const wixCode = localStorage.getItem('wixCode');
      return dispatch(
        connectStoreProviderWix({
          storeId: selectedStoreId.value,
          code: wixCode,
          state: currentBusiness?.restaurantMailbox,
          defaultPrepTime: currentBusiness.defaultPrepTime,
        })
      )
        .then(() => {
          setLoading(false);
          setLocationsModalVisible(false);
          setModalVisible(false);
          localStorage.removeItem('wixCode');
          dispatch(getProviders());
          dispatch(getProvidersStatuses());
          setDisabled(true);
        })
        .catch((err) => {
          setLoading(false);
          setLocationsModalVisible(false);
          setModalVisible(false);
          setDisabled(false);
          localStorage.removeItem('wixCode');
          toast.error(err?.response?.data?.errorMessage);
        })
        .finally(() => {
          setProgress(0);
          setSubmitted(false);
          setLoading(false);
          setLocationsModalVisible(false);
          setModalVisible(false);
          dispatch({ type: 'EMPTY_PROVIDER_CONNECT_DATA_RESPONSE' });
          history.push('/providers');
        });
    }

    if (connectStoresProvider !== 'ubereats') {
      const selectedMode = localStorage.getItem('mode');
      return dispatch(
        connectStoreProvider({
          provider: target.srv,
          data: { ...userAuthData, storeId: selectedStoreId.value },
          readonly: Boolean(selectedMode === PROVIDER_MODE.READONLY),
        })
      )
        .then(() => {
          localStorage.removeItem('mode');
          toast.success('Successfully connected.');
          setLocationsModalVisible(false);
          setModalVisible(false);
          dispatch(getProvidersStatuses());
        })
        .catch(() => {
          localStorage.removeItem('mode');
          setLocationsModalVisible(false);
          setModalVisible(false);
        })
        .finally(() => {
          setProgress(0);
          setSubmitted(false);
          localStorage.removeItem('mode');
          setLoading(false);
          dispatch({ type: 'EMPTY_PROVIDER_CONNECT_DATA_RESPONSE' });
        });
    }
    const codePr = localStorage.getItem('codePr');
    const statePr = localStorage.getItem('statePr');
    const selectedMode = localStorage.getItem('mode');
    return dispatch(
      connectStoreProviderUber({
        storeId: selectedStoreId.value,
        code: codePr,
        state: statePr,
        redirectUri: process.env.REACT_APP_API_PROD_URL,
        defaultPrepTime: currentBusiness.defaultPrepTime,
        readonly: Boolean(selectedMode === PROVIDER_MODE.READONLY),
      })
    )
      .then(() => {
        localStorage.removeItem('mode');
        setLoading(false);
        setCollectProviderData(null);
        setLocationsModalVisible(false);
        setModalVisible(false);
        localStorage.removeItem('codePr');
        localStorage.removeItem('statePr');
        dispatch(getProviders());
        dispatch(getProvidersStatuses());
        setDisabled(true);
      })
      .catch((err) => {
        console.log('err: ', err.response);
        setLoading(false);
        setLocationsModalVisible(false);
        setCollectProviderData(null);
        setModalVisible(false);
        setDisabled(false);
        localStorage.removeItem('codePr');
        localStorage.removeItem('statePr');
        localStorage.removeItem('mode');
        toast.error(err?.response.data.body);
        // toast.error(err.body);
      })
      .finally(() => {
        setProgress(0);
        setSubmitted(false);
        setLoading(false);
        setLocationsModalVisible(false);
        setModalVisible(false);
        dispatch({ type: 'EMPTY_PROVIDER_CONNECT_DATA_RESPONSE' });
        history.push('/providers');
      });
  };

  const handleDisconnect = useCallback(
    async (data) => {
      const confirmed = await confirm({
        title: t('providers_settings.are_you_sure'),
        message: t('providers_settings.do_you_want'),
        cancelText: t('orders.cancel'),
        confirmText: t('providers_settings.yes_disconnect'),
        confirmColor: 'primary',
      });
      if (confirmed) {
        setProgress(0);
        setSubmitted(false);
        dispatch(disconnectProvider(data));
      }
    },
    [dispatch]
  );

  const handleStateChange = useCallback(
    (data) => {
      dispatch(updateProviderState({ id: providerId, ...data }));
    },
    [dispatch, providerId]
  );

  const handleConfirmOrderChange = useCallback(
    (data) => {
      dispatch(updateProviderConfirmOrder({ id: providerId, ...data }));
    },
    [dispatch, providerId]
  );
  useEffect(() => {
    localStorage.removeItem('redirectUrl');
    let APP_ID = process.env.REACT_APP_WIX_APP_ID;
    let redirect_url = process.env.REACT_APP_API_STAGE_URL;
    let wixURL = `https://www.wix.com/installer/install`;
    if (token && !code && !instanceId) {
      window.open(`${wixURL}?appId=${APP_ID}&redirectUrl=${redirect_url}&token=${token}`, '_blank');
    }
  }, [token, code, instanceId]);

  useEffect(() => {
    if (queryStringProviderParam === "wix" || (code && instanceId)) {
      setLoading(true);
      handleConnectStoreProvide('wix');
      localStorage.setItem('wixCode', code);
      const wixCode = localStorage.getItem('wixCode');
      if (wixCode === code) {
        dispatch(connectProviderWix({ code: code }))
          .then((res) => {
            setLoading(false);
            if (!res?.stores) {
              return toast.error('ops_something_try_again_later');
            }
            if (res?.multiStore) {
              setLocationsModalVisible(true);
              handleLocationsList(res?.stores);
              setTargetIsUber(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err);
          })
          .finally(() => {
            setLoading(false);
            setSubmitted(false);
          });
      }
    } else if (queryStringProviderParam === "ubereats" || (!queryStringProviderParam && code && state)) { //Check if queryStringProviderParam is ubereats or if queryStringProviderParam is empty and code and state are present
      setLoading(true);
      handleConnectStoreProvide('ubereats');
      localStorage.setItem('codePr', code);
      localStorage.setItem('statePr', state);
      const uberCode = localStorage.getItem('codePr');
      if (uberCode === code) {
        dispatch(connectProviderUber(code, state))
          .then((res) => {
            setLoading(false);
            if (!res.stores) {
              return toast.error('ops_something_try_again_later');
            }
            if (res.multiStore) {
              setLocationsModalVisible(true);
              handleLocationsList(res.stores);
              if (res.providers.ubereats) {
                setTargetIsUber(true);
              } else {
                setTargetIsUber(false);
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err);
          })
          .finally(() => {
            setLoading(false);
            setSubmitted(false);
          });
      } else {
        setLoading(false);
      }
      history.replace(location.pathname);
    }
  }, [code, state, instanceId, queryStringProviderParam]);

  useEffect(() => {
    dispatch(getProviders());
    dispatch(getPosData());
  }, []);

  // const filteredList = useMemo(() => {
  //   const withFiltered = items.filter((item) => {
  //     return (
  //       item.srv !== providers?.postmates?.srv &&
  //       // item.srv !== 'food2' &&
  //       (item.srv !== providers?.doocado?.srv || item.status === PROVIDER_CONNECTED) &&
  //       (item.srv !== providers?.deliverycom?.srv || item.status === PROVIDER_CONNECTED) &&
  //       !(
  //         (item.srv === providers?.doordash_cartwheel?.srv || item.srv === providers?.grubhub_cartwheel?.srv) &&
  //         item.status !== PROVIDER_CONNECTED &&
  //         currentBusiness?.roles?.name !== 'super'
  //       )
  //     );
  //   });

  //   return withFiltered;
  // }, [items]);

  const filteredList = useMemo(() => {
    const withFiltered = items.filter((item) => {
      if (item.status === PROVIDER_CONNECTED || currentBusiness?.roles?.name === 'super') {
        return true;
      }

      return !providers[item.srv]?.hidden;
    });

    return sortableProvidersByStatus(withFiltered);
  }, [currentBusiness?.roles?.name, items, providers]);

  const closeModal = () => {
    setLocationsModalVisible(false);
    // localStorage.removeItem('mode');
  };
  return loading || isLoading ? (
    <Preloader overlay />
  ) : (
    <div>
      <ReadOnlyDialog
        open={openUberModal}
        handleClose={handleClose}
        title={`providers_settings.provider_readOnly`}
        providerName={`${collectProviderData?.label}`}
        cancelTitle={'Cancel'}
        saveTitle={'Continue'}
        handleChange={handleChange}
        value={mode}
        handleContinue={handleContinue}
        currentStep={providerReadOnlyStep}
        providerSRV={collectProviderData?.srv}
        setProviderReadOnlyStep={setProviderReadOnlyStep}
      />
      <Grid spacing={2} container>
        {filteredList?.map((item) => {
          return (
            <Grid item key={item.srv} md={12} sm={12} lg={6}>
              <ProviderList
                isDisabled={isDisabled}
                target={target}
                data={item}
                stopResumeOrders={stopResumeOrders}
                id={uuidv4()}
                onConnect={toggleModal}
                onDisconnect={handleDisconnect}
                onStateChange={handleStateChange}
                onChangeConfirm={handleConfirmOrderChange}
                handleOpen={handleOpen}
                setCollectProviderData={setCollectProviderData}
              />
            </Grid>
          );
        })}
      </Grid>
      <POS />
      <Draggable cancel={'[class*="modal-provider-body"]'}>
        <Modal centered={true} isOpen={modalVisible} toggle={!submitted ? toggleModal : () => { }} size="md">
          <ModalHeader toggle={!submitted ? toggleModal : () => { }} style={{ cursor: 'grab' }}>
            {t('providers_settings.connect_to')}
            {` ${target?.name}`}
          </ModalHeader>
          <ModalBody className={'modal-provider-body'}>
            <ConnectModal
              name={target?.name}
              onSubmit={handleConnect}
              loading={submitted}
              progress={progress}
              error={error}
            />
          </ModalBody>
        </Modal>
      </Draggable>
      <Draggable cancel={'[class*="modal-provider-body"]'}>
        <Modal
          centered={true}
          isOpen={locationsModalVisible}
          toggle={toggleLocationsModal}
          size="md"
          className="locationsModal"
        >
          <ModalHeader toggle={closeModal} style={{ cursor: 'grab' }}>
            {t('providers_settings.stores_list')}
          </ModalHeader>
          <ModalBody className={'modal-provider-body'}>
            <Box>
              <AsyncPaginate
                placeholder="Please select a store"
                value={selectedStoreId}
                loadOptions={loadOptions(locationsList, targetIsUber)}
                onChange={onChangeSelectedStore}
              />
              {isSelectedStore && !selectedStoreId.value && (
                <Typography sx={{ color: (theme) => theme.palette.error.main, fontSize: '14px', margin: 0.5 }}>
                  {t('help.field_is_required')}
                </Typography>
              )}
              <Button
                disabled={!selectedStoreId}
                sx={{ mt: 2 }}
                color="primary"
                variant="contained"
                onClick={withDebounce(handleConnectLocation)}
              >
                {t('order_view.submit')}
              </Button>
            </Box>
          </ModalBody>
        </Modal>
      </Draggable>
    </div>
  );
};

export default Providers;
