import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Private from 'components/Private';
import { useTranslation } from 'react-i18next';

const FormFooter = ({
  isEdit,
  handleClickOpen,
  disabled,
  isDisable,
  tabValue,
  handleSaveModifierGroup = () => {},
  mode,
  handleCancel,
}) => {
  const { t } = useTranslation();
  const disabledUpdate = mode === 'edit' ? !isDisable : false;

  return (
    <Box
      sx={{
        px: 4,
        paddingTop: 'calc(67.5px / 4.5)',
        paddingBottom: 'calc(67.5px / 4.5)',
        borderTop: 1,
        borderColor: 'divider',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box>
        {tabValue === 1 ? (
          <Private permission="MENU_EDIT">
            <Button
              id="formFooterUpdate"
              disabled={disabled || disabledUpdate}
              onClick={handleSaveModifierGroup}
              variant="contained"
              sx={{ mr: 1 }}
            >
              {mode === 'edit' ? t('website_settings.update') : t('settings.save')}
            </Button>
          </Private>
        ) : (
          <Private permission="MENU_EDIT">
            <Button
              id="formFooterOrdersUpdate"
              disabled={disabled || disabledUpdate}
              type="submit"
              variant="contained"
              sx={{ mr: 1 }}
            >
              {mode === 'edit' ? t('website_settings.update') : t('settings.save')}
            </Button>
          </Private>
        )}

        <Button id="formFooterOrdersCancel" sx={{ color: '#333333' }} onClick={handleCancel}>
          {t('orders.cancel')}
        </Button>
      </Box>
      <Box>
        {isEdit && (
          <Button
            id="formFooterAvailability"
            onClick={handleClickOpen}
            sx={{
              padding: '6px 16px',
              width: '100%',
              background: '#F2F2F2',
              color: '#333333',
              '&:hover': {
                color: '#fff',
              },
            }}
            name="isActive"
            variant="contained"
          >
            {t('menu.update_availability')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FormFooter;
