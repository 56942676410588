import { Box, Skeleton, Stack, styled } from '@mui/material';

export const SkeletonWrap = styled(Skeleton)({
  //   backgroundColor: 'rgb(34, 41, 51)',
  backgroundColor: 'rgb(169 172 176 / 35%)',
  borderRadius: 8,
});

const BoxWrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 16,
  paddingRight: 16,
  height: '30px',
  marginTop: '8px !important',
  marginBottom: '8px !important',
});

const BoxChildWrap = styled(Box)({
  minWidth: '56px',
});

const SkeletonSidebarList = () => {
  return (
    <Stack spacing={1}>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
      <BoxWrap>
        <BoxChildWrap>
          <SkeletonWrap variant="rounded" width={20} height={20} />
        </BoxChildWrap>
        <SkeletonWrap variant="rounded" width={151} height={24} />
      </BoxWrap>
    </Stack>
  );
};

export default SkeletonSidebarList;
