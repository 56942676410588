import instance from 'services/api';
import { SET_IS_BEEPING, SET_PAYMENT_NOTIFICATION } from 'redux/actions/types';

import { toast } from 'react-toastify';

export const handleUpdatePaymentNotification = (data) => (dispatch, getState) => {
  const { user } = getState();
  if (user?.data?.roles?.name !== 'super') {
    dispatch({ type: SET_PAYMENT_NOTIFICATION, payload: data });
  } else {
    if (!data.paid) {
      dispatch({ type: SET_PAYMENT_NOTIFICATION, payload: data });
    }
  }
};

export const callWaitStaff = (data) => (dispatch) => {
  dispatch({ type: SET_PAYMENT_NOTIFICATION, payload: data });
  dispatch({ type: SET_IS_BEEPING, payload: data.isBeeping });
};

export const getPaymentInfo = (businessId) => (dispatch, getState) => {
  instance
    .get(`/billing/payment-status/validate/${businessId}`)
    .then(({ data }) => {
      dispatch({ type: SET_PAYMENT_NOTIFICATION, payload: data.data });
    })
    .catch((err) => {
      return toast.error(err?.response?.message || 'Oops! Something went wrong. Please try again later.');
    });
};

export const handelPaymentMarkAsPaid = () => {
  return instance.post(`/billing/payment-status/mark-as-paid`).catch((err) => {
    return toast.error(err?.response?.message || 'Oops! Something went wrong. Please try again later.');
  });
};

export const handleSuspendAccount = (data) => {
  return instance.post(`/billing/payment-status/process/`, data).catch((err) => {
    return toast.error(err?.response?.message || 'Oops! Something went wrong. Please try again later.');
  });
};
