import React, { memo } from 'react';
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import CustomSwitch from 'components/Forms/CustomSwitch';
import { SwitcherWrapper } from 'pages/TableOrdering/styles';
import useDineInAutoConfirm from 'pages/Providers/TableOrdering/hooks/useDineInAutoConfirm';
import { SETTINGS_TABLE_OPTIONS, useTableOrderingSettings } from 'pages/TableOrdering/hooks/useTableOrderingSettings';

const DineInAutoConfirm = ({ items, srv, isDisableAutoConfirm, handleOpenAutoConfirmModal, type }) => {
  const { t } = useTranslation();
  const { loadingSettings } = useSelector(({ tableOrdering }) => tableOrdering);
  const { autoConfirmDineIn, handleSubmit, handleConfirm, loading } = useDineInAutoConfirm(items, srv);
  const { settingsDineIn, handleDineInSubmit, handleDineInChange, selectedOptions } = useTableOrderingSettings();

  return (
    <>
      <FormProvider {...settingsDineIn}>
        <form
          onSubmit={settingsDineIn.handleSubmit(handleDineInSubmit)}
          style={{
            width: '100%',
            mt: 3,
          }}
        >
          {loadingSettings && selectedOptions === SETTINGS_TABLE_OPTIONS.DINE_IN && <Preloader overlay={true} />}
          <SwitcherWrapper
            sx={{ ml: 2, mt: 2 }}
          >
            <CustomSwitch
              onChange={handleDineInChange}
              name="isEnable"
              checked={settingsDineIn.getValues()?.isEnable}
              label={t('table_ordering.dine_in_switcher')}
            />
          </SwitcherWrapper>
        </form>
      </FormProvider>
      <Divider />
      <FormProvider {...autoConfirmDineIn}>
        <form
          onSubmit={autoConfirmDineIn.handleSubmit(handleSubmit)}
          style={{
            width: '100%',
            mt: 3,
          }}
        >
          {loading && <Preloader overlay={true} />}
          <SwitcherWrapper
            sx={{ ml: 2, mt: 2 }}
            onClick={isDisableAutoConfirm ? handleOpenAutoConfirmModal : () => {} }
          >
            <CustomSwitch
              disabled={isDisableAutoConfirm || !settingsDineIn.getValues()?.isEnable}
              name="orderConfirm.dine_in.autoConfirm"
              checked={autoConfirmDineIn.getValues()?.orderConfirm?.dine_in?.autoConfirm}
              label={t(`Auto-confirm ${type} order`)}
              onChange={(event) => handleConfirm(event)}
            />
          </SwitcherWrapper>
        </form>
      </FormProvider>
    </>
  );
};

export default memo(DineInAutoConfirm);
