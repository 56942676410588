import { styled, Typography, Grid, Box, Link as HyperLink } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
export const SignUpTitle = styled(Typography)({
  fontSize: '32px',
  fontWeight: 600,
  color: '#000000',
  textAlign: 'center',
});

export const LoginFormGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'start',
    marginTop: theme.spacing(0),
  },
}));

export const LoginFormLogo = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(7),
  width: '161px',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export const LoginFormErrorMessage = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '450px',
  margin: '0 auto',
  minHeight: '74px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.5),
  },
}));

export const ForgotPasswordGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  margin: theme.spacing(0, 0, 1, 0),
}));

export const ForgotPassword = styled(Link)(({ theme }) => ({
  color: '#2D7FF9',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '27px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '18.2px',
  },
}));

export const SignUpLink = styled(Link)(({ theme }) => ({
  color: '#2D7FF9',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '27px',
  textDecoration: 'underline',
  '&:hover': {
    color: '#2D7FF9',
    textDecoration: 'underline',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '18.2px',
  },
}));

export const SignUpText = styled(Typography)(({ theme }) => ({
  color: '#6C6C6C',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '27px',
  '&:hover': {
    color: '#6C6C6C',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '18.2px',
  },
}));

export const LoginButton = styled(LoadingButton)(({ theme }) => ({
  background: '#2D7FF9',
  color: '#FFFFFF',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '18.75px',
  width: '100%',
  boxShadow: 'none',
  height: '40px',
  '&:hover': {
    background: '#2D7FF9',
    color: '#FFFFFF',
    boxShadow: 'none',
  },
}));

export const SignUpGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  gap: '5px',
  justifyContent: 'center',
  margin: theme.spacing(9, 0, 1, 0),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(4, 0, 4, 0),
  },
}));

export const FooterAppContent = styled(Grid)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

export const FooterLogin = styled(Grid)(({ theme }) => ({
  position: 'relative',
}));

export const FooterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  position: 'absolute',
  bottom: '-180px',
  '@media (min-height: 500px) and (max-height: 790px)': {
    bottom: '-80px',
  },
  '@media (min-height: 791px) and (max-height: 890px)': {
    bottom: '-120px',
  },
  [theme.breakpoints.down(899)]: {
    position: 'inherit',
    marginTop: theme.spacing(3),
  },
}));

export const FooterPhoneBox = styled(HyperLink)(({ theme }) => ({
  color: '#0F1214',
  fontSize: '14px',
  fontWeight: 400,
  textDecoration: 'none',
}));

export const FooterCopyWrite = styled(Box)(({ theme }) => ({
  color: '#929398',
  fontSize: '12px',
  lineHeight: '22px',
  fontWeight: 400,
}));

export const MainLoginBlock = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '450px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.5),
  },
}));

export const CopyRight = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));
