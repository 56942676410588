import { memo } from 'react';
import { TableRow } from '@mui/material';
import Currency from 'components/Currency';
import { useTranslation } from 'react-i18next';
import { sumTaxesAndFees } from 'utils/globalUtils';

const OrderTaxAndFees = ({ data }) => {
  const { t } = useTranslation();

  const styles = {
    paddingLeft: '16px',
    fontSize: '14px',
  };

  const stylesValue = {
    fontSize: '14px',
    textAlign: 'right',
    padding: ' 5px 0.5rem',
  };

  return (
    <>
      {data?.charges?.tax || data?.charges?.tax === 0 ? (
        <TableRow>
          <td style={styles}>{t('Tax')}</td>
          <td style={stylesValue}>
            <Currency sx={{ fontSize: '14px' }} value={data?.charges?.tax} />
          </td>
        </TableRow>
      ) : (
        <></>
      )}
      {data?.charges?.servicingFee && Object.keys(data?.charges?.servicingFee)?.length > 0 && sumTaxesAndFees(data) ? (
        <TableRow>
          <td style={styles}>{t('Processing Fee')}</td>
          <td style={stylesValue}>
            <Currency sx={{ fontSize: '14px' }} value={sumTaxesAndFees(data)} />
          </td>
        </TableRow>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(OrderTaxAndFees);
