import InfiniteScroll from 'react-infinite-scroll-component';
import { Typography } from '@mui/material';
import {
  ChildContainerItem,
  InfiniteScrollList,
  InfiniteScrollListItem,
  InfiniteScrollMain,
  SearchField,
} from './styles';
import { LabelName } from 'pages/Report/styles';
import usePermission from 'hooks/permission';
import * as all from 'constants/permissions';
import { useTranslation } from 'react-i18next';

const LocationListItems = (props) => {
  const {
    brandLocationData,
    brandLocationChange,
    handleLocationChange,
    selectedLocations,
    fetchMoreData,
    selectedLocation,
    selectedBrand,
    selectedLocationId,
    hasMoreLocation,
    onKeyDown,
  } = props;
  const checkPermission = usePermission();
  const { t } = useTranslation();
  const activeLocation = (item) => {
    if (
      (selectedLocation && item.businessId === selectedLocation?.value?.businessId) ||
      (selectedLocation && item.businessId === selectedLocation?.businessId)
    ) {
      return 'active-location';
    } else if (item.businessId === selectedLocationId) {
      return 'active-location';
    } else if (
      !selectedLocation &&
      !selectedLocationId &&
      selectedBrand?.value?.locations[0]?.businessId === item.businessId
    ) {
      return 'active-location';
    }
    return '';
  };

  const mappedLocations = (classifications) => {
    const name = classifications.find((item) => item?.shortName)?.shortName;
    return name ? `(${name})` : '';
  };

  return (
    <ChildContainerItem>
      <LabelName>{t('reports_settings.all_locations')}</LabelName>
      <SearchField
        onKeyDown={onKeyDown}
        disabled={checkPermission(all['SUPER'])}
        multiline
        onChange={(e) => handleLocationChange(e)}
        placeholder={t('reports_settings.all_locations_...')}
        value={brandLocationChange}
        variant="outlined"
        size="small"
      />
      <InfiniteScrollMain id="scrollableDiv">
        <InfiniteScroll
          className={'location-scroll'}
          dataLength={selectedBrand?.value?.locations?.length}
          next={fetchMoreData}
          hasMore={hasMoreLocation}
          scrollableTarget="scrollableDiv"
          height={205}
          style={{
            border: '1px solid #eee',
            borderRadius: ' 4px',
          }}
        >
          <InfiniteScrollList>
            {!brandLocationData?.length && (
              <InfiniteScrollListItem sx={{ textAlign: 'center', color: '#c7c4c4' }}>No options</InfiniteScrollListItem>
            )}
            {brandLocationData?.map((item, index) => {
              const locationDetails = `${item.locationName} ${item?.disabled ? '(Disabled)' : ''} ${
                !item?.verified ? '(Pending)' : ''
              }`;
              const classificationsText =
                item?.classifications?.length > 0 ? `${mappedLocations(item?.classifications)}` : '';
              const typographyContent = checkPermission(all['SUPER'])
                ? `${locationDetails} ${classificationsText}`
                : locationDetails;
              return (
                <InfiniteScrollListItem
                  onClick={() => selectedLocations(item, item?.businessId)}
                  className={activeLocation(item)}
                  key={`${item.locationName}-${index}`}
                >
                  <Typography>{typographyContent}</Typography>
                  <Typography sx={{ fontSize: '12px' }}>{item.address}</Typography>
                </InfiniteScrollListItem>
              );
            })}
          </InfiniteScrollList>
        </InfiniteScroll>
      </InfiniteScrollMain>
    </ChildContainerItem>
  );
};

export default LocationListItems;
