export function convertBrand(list) {
  return list.map((item) => ({
    value: item,
    label: item.brandName,
    name: item.brandName,
  }));
}

export function convertBrandById(list) {
  const selectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));
  const filteredList = list.filter((item) => item._id === selectedBrand?.id);
  return filteredList.map((item) => ({
    value: item,
    label: item.brandName,
    name: item.brandName,
  }));
}
export function convertLocationById(list) {
  const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
  const filteredList = list.filter((item) => item?.value === selectedLocation?.value?._id);
  return filteredList.map((item) => ({
    address: item?.address,
    businessId: item?.businessId,
    companyName: item?.companyName,
    label: item?.label,
    name: item?.name,
    value: item?.value,
  }));
}
