import { styled, Box, Button, Typography, TableCell, DialogContent, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export const ChargeBackDisputeButton = styled(LoadingButton)(({ theme }) => ({
  color: '#ffffff',
  background: '#E21508',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '16.41px',
  borderRadius: '6px',
  width: '185px',
  height: '40px',
  marginLeft: theme.spacing(2),
  '&:hover': {
    color: '#ffffff',
    background: '#E21508',
    fontSize: '14px',
  },
  '&:disabled': {
    background: '#e4e4e4',
    cursor: 'not-allowed',
    fontSize: '14px',
  },
}));

export const ChargeBackCancelButton = styled(Button)(() => ({
  color: '#626A7A',
  background: '#ffffff',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16.41px',
  borderRadius: '6px',
  width: '100px',
  height: '40px',
  '&:hover': {
    background: '#ffffff',
    color: '#626A7A',
    fontSize: '14px',
  },
}));

export const ReasonTitle = styled(Typography)(({ theme }) => ({
  color: '#49454F',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '0.25px',
  paddingBottom: theme.spacing(2),
}));

export const StatusContainer = styled(Box)(({ theme }) => ({
  width: 'max-content',
  borderRadius: '4px',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: theme.spacing(0.5, 1.5, 0.5, 1),
}));

export const StatusName = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '16.41px',
}));

export const StatusContainerDescription = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: '4px',
  height: 'auto',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
  padding: theme.spacing(2),
  textAlign: 'left',
  marginTop: theme.spacing(2),
  '& > img': {
    paddingTop: '2px',
  },
}));

export const StatusMessage = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#5A5A5A',
}));

export const StatusTotal = styled('span')(() => ({
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '20px',
  color: '#0F1214',
}));

export const TableCellStyle = styled(TableCell)(() => ({
  borderBottom: 'none !important',
  fontSize: '1rem',
  padding: '0.5rem',
}));

export const ModalChargeBackHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const ModalChargeBackProductName = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#0F1214',
  letterSpacing: '0.5px',
}));

export const ModalChargeBackCurrencyWrapper = styled(Box)(() => ({
  display: 'flex',
  marginRight: '13px',
}));

export const ModalChargeBackCurrencyQuantity = styled(Typography)(() => ({
  width: '100%',
  textAlign: 'end',
  marginRight: '10px',
}));

export const ModalConfirmText = styled(Typography)(() => ({
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#49454F',
  letterSpacing: '0.25px',
}));

export const ContactInfoWrapper = styled(Box)(() => ({
  textAlign: 'left',
}));

export const ContactInfoWrapperTitle = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '15px',
}));

export const ContactInfoWrapperDesc = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '15px',
}));

export const DeliveryInfoBlock = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const DialogContentBox = styled(DialogContent)(({ theme }) => ({
  width: '100%',
  marginTop: '148px',
  textAlign: 'center',
  padding: theme.spacing(0),
}));

export const DialogTextSubProvider = styled(Typography)({
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '32px',
  color: '#49454F',
  margin: '32px auto',
  maxWidth: '580px',
  textAlign: 'center',
});

export const DialogTextProvider = styled(Typography)({
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '32px',
  color: '#1D1B20',
  marginTop: '32px',
});
export const DialogActionsContentBox = styled(DialogActions)(({ theme }) => ({
  minHeight: '102px',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  padding: theme.spacing(0),
}));

export const DialogActionsButton = styled(Button)({
  margin: '0 auto',
  textAlign: 'center',
  width: '164px',
  height: '54px',
  borderRadius: '6px',
  color: 'white',
  background: '#2D7FF9',
  boxShadow: 'none',
  fontWeight: 700,
  fontSize: '16px',
  '&:hover': {
    color: 'white',
    background: '#2D7FF9',
    boxShadow: 'none',
  },
});

export const NotAvailableContent = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16.41px',
  color: '#5A5A5A',
});

export const NotAvailableBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
  minHeight: '373px',
  borderTop: '1px solid #0000001F',
});

export const NotBlock = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
});

export const NotOrderNumberContent = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16.41px',
  color: '#5A5A5A',
});
