import { memo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  RealTimeBlock,
  ValidationInnerTitle,
  ValidationItem,
  ValidationMessageTitle,
} from 'redesign/AccountTab/styles';

const RealTimeValidation = ({ validation }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 3 }}>
      <ValidationMessageTitle>{t('Your password must be:')}</ValidationMessageTitle>
      <RealTimeBlock sx={{ mt: 1, ml: 2 }}>
        {validation.map((item) => {
          return (
            <ValidationItem key={item.key}>
              {item.error ? (
                <img src={item?.errorIcon} alt="error" />
              ) : item.success ? (
                <img src={item?.successIcon} alt="success" />
              ) : (
                <img src={item?.middleIcon} alt="middle" />
              )}
              <ValidationInnerTitle>{t(item.message)}</ValidationInnerTitle>
            </ValidationItem>
          );
        })}
      </RealTimeBlock>
    </Box>
  );
};

export default memo(RealTimeValidation);
