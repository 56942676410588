import { Dialog, Button, DialogTitle, DialogContent, DialogActions, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { FormProvider } from 'react-hook-form';
import CustomMobilePhone from 'components/MobilePhone/CustomMobilePhone';
import { useTranslation } from 'react-i18next';

import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
const DialogAddSelectItem = ({ open, handleClose, handleClickSave, modalBrandFormMethods }) => {
  const { t } = useTranslation();

  const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperComponent={DraggableDialogWrapper}
    >
      <Box sx={{ background: '#fff', width: '508px' }}>
        <CustomDialogTitle
          sx={{ fontSize: '20px', fontWeight: 700, cursor: 'grab' }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {t('settings.add_new_brand')}
        </CustomDialogTitle>
        <FormProvider {...modalBrandFormMethods}>
          <form onSubmit={modalBrandFormMethods.handleSubmit(handleClickSave)}>
            <DialogContent sx={{ p: 2 }}>
              <CustomTextInput
                name="customBrandName"
                sx={{ width: '100%', mb: 2, mt: 1, height: '64px' }}
                label={t('settings.brand_name')}
              />
              <CustomMobilePhone
                name="brandPhone"
                sx={{ width: '100%', height: '64px' }}
                label={t('settings.phone_number')}
              />
            </DialogContent>
            <DialogActions sx={{ cursor: 'grab' }}>
              <DialogActions>
                <Button
                  id="dialogAddSelectCancel"
                  variant="contained"
                  sx={{
                    background: '#e3e3e3',
                    color: '#000',
                    '&:hover': {
                      background: '#eeeff0',
                    },
                  }}
                  onClick={handleClose}
                >
                  {t('orders.cancel')}
                </Button>
                <Button id="dialogAddSelectSave" variant="contained" type="submit">
                  {t('orders.save')}
                </Button>
              </DialogActions>
            </DialogActions>
          </form>
        </FormProvider>
      </Box>
    </Dialog>
  );
};
export default DialogAddSelectItem;
