import { styled, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';

export const TowFactorImageBlock = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(2),
}));

export const TowFactorContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 3, -1),
  textAlign: 'center',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}));

export const TowFactorContainerText = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 3, 0),
  textAlign: 'center',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}));

export const TowFactorResendContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 3, 2),
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const TowFactorText = styled(Typography)(({ theme }) => ({
  width: '100%',
  maxWidth: '300px',
}));

export const TowFactorLink = styled(LoadingButton)(({ theme }) => ({
  color: '#2D7FF9',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '27px',
  textDecoration: 'none',
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '18.2px',
  },
  '&: hover': {
    backgroundColor: 'transparent',
  },
}));
