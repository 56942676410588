import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { PAUSE_RESUME_STATUSES } from 'constants/resumePause';
import { useTranslation } from 'react-i18next';
import ApplyAllBusiness from 'components/PauseResume/ApplyAllBusiness';
import Divider from '@mui/material/Divider';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import { PauseResumeBackButton } from './styles';
import {
  busyProvider,
  resumeProvider,
  stopProvider,
  updateStopResumeOrders,
} from 'redux/actions/providers';
import PauseResumeModal from './PauseResumeModal';
import { LoadingButton } from '@mui/lab';
import Preloader from 'components/Preloader';
import PauseResumeProviderStep from 'components/PauseResume/PauseResumeStepper/PauseResumeProviderStep';
import PauseResumeSelectProvider from 'components/PauseResume/PauseResumeStepper/PauseResumeSelectProvider';
import { PauseResumeContext } from 'context/pauseResumeContext';
import useProviderConfig from 'hooks/useProviderConfig';

const MuiDialog = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { providers } = useProviderConfig();
  const { setCheckedProvider, setSelectAll, checkedProvider } = useContext(PauseResumeContext);
  const { stopResumeOrders, modalTitle } = useSelector(({ providers }) => providers);
  const [forAllBusinesses, setForAllBusinesses] = useState(false);
  const {
    data,
    showModal,
    loading,
    statuses: { status },
  } = stopResumeOrders;
  const [currentStep, setCurrentStep] = useState(0);

  const checkHasPausedProvider = () => {
    return stopResumeOrders?.statuses?.details.find(
      (item) =>
        checkedProvider?.includes(providers[item?.provider]?.id) &&
        item?.provider_status === PAUSE_RESUME_STATUSES.PAUSED
    );
  };

  const currentPausedProviders = useMemo(() => {
    return stopResumeOrders?.statuses?.details
      .filter((item) => {
        const providerData = providers[item?.provider];

        if (Array.isArray(providerData)) {
          return providerData.some(
            (provider) =>
              checkedProvider?.includes(provider.id) && item?.provider_status === PAUSE_RESUME_STATUSES.PAUSED
          );
        }

        return checkedProvider?.includes(providerData?.id) && item?.provider_status === PAUSE_RESUME_STATUSES.PAUSED;
      })
      .flatMap((item) => {
        const providerData = providers[item?.provider];
        return Array.isArray(providerData) ? providerData : [providerData];
      });
  }, [stopResumeOrders, providers, checkedProvider]);

  const handleClose = () => {
    setCheckedProvider([]);
    setSelectAll(false);
    dispatch(
      updateStopResumeOrders({
        showModal: false,
      })
    );
    setCurrentStep(0);
    dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
    setForAllBusinesses(false);
  };

  useEffect(() => {
    if (showModal) {
      setCurrentStep(0);
      setForAllBusinesses(false);
    }
  }, [showModal]);

  useEffect(() => {
    if (currentStep === 1) {
      dispatch(
        updateStopResumeOrders({
          data: {
            ...stopResumeOrders.data,
            action: PAUSE_RESUME_STATUSES.RECEIVING,
          },
        })
      );
    }
  }, [currentStep]);

  const handleStartStop = () => {
    if (data.action === PAUSE_RESUME_STATUSES.PAUSED) {
      setSelectAll(false);
      Object.keys(providers).forEach((provider) => {
        if (checkedProvider.indexOf(providers[provider]?.id) !== -1) {
          const srv = providers[provider]?.srv === 'website' ? 'food2' : providers[provider]?.srv;
          return dispatch(stopProvider(forAllBusinesses, srv));
        }
      });
    } else if (data.action === PAUSE_RESUME_STATUSES.BUSY) {
      setSelectAll(false);
      Object.keys(providers).forEach((provider) => {
        if (checkedProvider.indexOf(providers[provider]?.id) !== -1) {
          const srv = providers[provider]?.srv === 'website' ? 'food2' : providers[provider]?.srv;
          return dispatch(busyProvider(forAllBusinesses, srv));
        }
      });
    } else {
      setSelectAll(false);
      Object.keys(providers).forEach((provider) => {
        if (checkedProvider.indexOf(providers[provider]?.id) !== -1) {
          const srv = providers[provider]?.srv === 'website' ? 'food2' : providers[provider]?.srv;
          return dispatch(resumeProvider(forAllBusinesses, srv));
        }
      });
    }
    setCheckedProvider([]);
  };

  const handleConfirm = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  const handleCloseStep = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const handleSubmit = () => handleStartStop();


  const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 24,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ fontSize: '38px', color: '#000000', opacity: '80%' }} />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{
        sx: { width: '764px', height: '670px', maxWidth: '100%', background: '#FAFAFA' },
      }}
    >
      {loading && <Preloader />}
      <CustomDialogTitle id="alert-dialog-title" onClose={handleClose} sx={{ fontSize: '24px', fontWeight: '550' }}>
        {`${modalTitle} ${t('orders.store_status')}` || t('orders.store_status')}
      </CustomDialogTitle>
      <Divider />
      <DialogContent sx={{ width: '100%' }}>
        {currentStep === 0 && (
          <>
            <PauseResumeProviderStep />
          </>
        )}
        {currentStep === 1 && <PauseResumeSelectProvider showModal={showModal} />}
        {currentStep === 2 && (
          <PauseResumeModal
            checkHasPausedProvider={checkHasPausedProvider}
            currentPausedProviders={currentPausedProviders}
          />
        )}
        {currentStep === 3 && (
          <ApplyAllBusiness status={data?.action} duration={data.duration} setForAllBusinesses={setForAllBusinesses} />
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between', p: 2, cursor: 'grab' }}>
        <>
          <Button
            id="pauseResumeCancel"
            sx={{ width: '192px', height: '54px', borderRadius: '6px' }}
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            {t('orders.cancel')}
          </Button>
          <Box>
            {currentStep !== 0 && (
              <PauseResumeBackButton variant="outlined" onClick={handleCloseStep}>
                {t('sign_up.back')}
              </PauseResumeBackButton>
            )}
            <LoadingButton
              sx={{ width: currentStep === 0 ? '300px' : '192px', height: '54px', borderRadius: '6px' }}
              variant="contained"
              loading={loading}
              disabled={
                (currentStep === 1 && checkedProvider?.length === 0) ||
                (currentStep === 2 &&
                  data.action === PAUSE_RESUME_STATUSES.BUSY &&
                  status === PAUSE_RESUME_STATUSES.PAUSED &&
                  checkHasPausedProvider())
              }
              onClick={currentStep !== 3 ? handleConfirm : handleSubmit}
            >
              {currentStep !== 3 ? t('settings.continue') : t('settings.save')}
            </LoadingButton>
          </Box>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default MuiDialog;
