import { SET_PRINTERS_LIST, SET_PRINTERS_LIST_LOADING } from 'redux/actions/types';
const initialState = {
  list: [],
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PRINTERS_LIST:
      return {
        ...state,
        list: payload.data,
      };
    case SET_PRINTERS_LIST_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};
