import { useEffect, useState } from 'react';

export const useProviderConfirm = (data, items) => {
  const [deliveryAutoConfirm, setDeliveryAutoConfirm] = useState(false);
  const [pickUpAutoConfirm, setPickUpAutoConfirm] = useState(false);

  useEffect(() => {
    const findItem = items.find((item) => item?.srv === data?.srv);
    if (findItem && findItem?.hasOwnProperty('orderConfirm')) {
      if (findItem?.orderConfirm?.delivery) {
        setDeliveryAutoConfirm(findItem?.orderConfirm?.delivery?.autoConfirm);
      }
      if (findItem?.orderConfirm?.pickup) {
        setPickUpAutoConfirm(findItem?.orderConfirm?.pickup?.autoConfirm);
      }
    }
  }, [items, data]);

  return {
    deliveryAutoConfirm,
    pickUpAutoConfirm,
    setPickUpAutoConfirm,
    setDeliveryAutoConfirm,
  };
};
