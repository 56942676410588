import styled from '@emotion/styled';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Typography } from '@mui/material';

export const ButtonFooter = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
  alignItems: 'center',
});
export const SaveButtonFooter = styled(LoadingButton)({
  width: '71px',
  height: '36px',
  padding: '16px 32px',
  background: '#FF8A00',
  color: '#FFF',
  fontSize: '14px',
  fontWeight: 600,
  borderRadius: '6px',
  boxShadow: 'none',
  '&:hover': {
    background: '#FF8A00',
    boxShadow: 'none',
  },
});
export const CancelButtonFooter = styled(Button)({
  width: '85px',
  height: '36px',
  padding: '16px 32px',
  color: '#929398',
  fontSize: '14px',
  fontWeight: 600,
  '&:hover': {
    color: '#929398',
    boxShadow: 'none',
  },
});

export const AccordionContentTitle = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  fontWeight: 400,
  color: ' #3C4350',
  lineHeight: '28px',
  width: '100%',
  margin: theme.spacing(1.2, 0),
}));

export const AccordionEditIcon = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginRight: '8px',
});
export const AccordionContentWrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});
