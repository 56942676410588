import { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { PAUSE_RESUME_STATUSES, PAUSE_RESUME_STATUSES_LABEL } from 'constants/resumePause';

const useCountDownForProvider = (statuses, callback) => {
  const { t } = useTranslation();
  const [pauseTimer, handlePauseTimer] = useState(statuses?.paused_until);

  useEffect(() => {
    if (statuses?.paused_until) {
      handlePauseTimer(statuses?.paused_until);
    }
  }, [statuses?.paused_until]);

  useEffect(() => {
    let timerMs = statuses?.paused_until ? statuses?.paused_until * 1000 - Date.now() : 1000;
    let interval;
    if (statuses?.paused_until) {
      interval = setInterval(() => {
        timerMs -= 1000;
        let duration = moment.duration(timerMs, 'milliseconds');
        handlePauseTimer(duration);
      }, 1000);
      if (pauseTimer?._milliseconds <= 0) {
        clearInterval(interval);
        callback();
      }
    }
    return () => clearInterval(interval);
  }, [pauseTimer, statuses?.paused_until, callback, statuses?.provider_status]);

  const duration = useMemo(() => {
    let dur = pauseTimer;
    if (!dur && statuses?.provider_status !== PAUSE_RESUME_STATUSES.RECEIVING) return '';
    if (statuses?.provider_status === PAUSE_RESUME_STATUSES.RECEIVING) return t(PAUSE_RESUME_STATUSES_LABEL.RECEIVING);
    if (dur > 700000000) {
      return t('store_status_notification.permanently_paused_title');
    } else if (statuses?.provider_status === PAUSE_RESUME_STATUSES.BUSY) {
      return `${t(PAUSE_RESUME_STATUSES_LABEL.BUSY)} ${
        dur?.hours() > 0 ? `${dur?.hours().toString().padStart(2, 0)} :` : ''
      } ${dur.minutes().toString().padStart(2, 0)} : ${dur.seconds().toString().padStart(2, 0)}`;
    } else if (statuses?.provider_status === PAUSE_RESUME_STATUSES.PARTIAL) {
      return t('Partially Accepting');
    } else {
      return `${t(PAUSE_RESUME_STATUSES_LABEL.PAUSED)} ${
        dur?.hours() > 0 ? `${dur?.hours()?.toString()?.padStart(2, 0)} :` : ''
      } ${dur?.minutes()?.toString()?.padStart(2, 0)} : ${dur?.seconds()?.toString()?.padStart(2, 0)}`;
    }
  }, [pauseTimer, statuses?.provider_status, t]);

  return { duration };
};
export default useCountDownForProvider;
