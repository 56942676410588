import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Preloader from 'components/Preloader';
import { Hours, SpecialDates } from 'components/Settings';
import { ImageUpload } from 'components/FormFields';
import {
  applyGoogleBusinessLink,
  getBusinessSettings,
  getCuisines,
  getTimeZoneList,
  updateBusinessSettings,
  updatePinCode,
  getBusinessClassification,
  getLocationName,
  getBrandName,
  updateLocationName,
  updateBrandName,
} from 'redux/actions/business';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import LabelRequired from 'components/FormFields/LabelRequired';
import CheckIcon from '@mui/icons-material/Check';
import ChatGptIcon from 'assets/icons/chatGptIcon.svg';
import Linkedin from 'assets/icons/linkedin.png';
import Facebook from 'assets/icons/facebook.png';
import Instagram from 'assets/icons/instagram.png';
import Twitter from 'assets/icons/twitter.png';
import Yelp from 'assets/icons/yelp.png';
import Google from 'assets/icons/google.png';
import OptImage from 'assets/images/opt.svg';
import { saveAs } from 'file-saver';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import CustomTextInput from 'components/Forms/CustomTextInput';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import GooglAutocomplete from 'components/FormFields/GoogleAutocomplate';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import useQr from 'hooks/useQr';
import { businessInfo, businessInfoIsNotSingle } from './businessSchemas';
import { onWheelPreventChangeNumberField } from 'utils/menu';
import DialogChatGptDesc from 'components/MenuNew/FormView/DialogChatGptDesc';
import { TEXTS_BUSINESS_CHAT_GPT } from 'constants/chatGpt';
import CustomMobilePhone from 'components/MobilePhone/CustomMobilePhone';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import { useTranslation } from 'react-i18next';
import instance from 'services/api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import { BusinessSettingsTitle } from './BusinessSettings/styles';
import BusinessDialog from 'components/Dialog/BusinessDialog';
import { LoadingButton } from '@mui/lab';
import { isAsapSwitcherTurnOff, isAsapSwitcherTurnOn } from '../config';
import * as all from 'constants/permissions';
import usePermission from 'hooks/permission';
import OrderBufferTime from './OrderBufferTime';
import usePrepTimeModal from 'pages/Settings/ConfirmModal/hooks/usePrepTimeModal';
import ConfirmPrepTimeDialog from 'pages/Settings/ConfirmModal/ConfirmPrepTimeDialog';

const initialHours = {
  sun: { opened: true, hours: [{ start: '09:00', end: '21:00' }] },
  mon: { opened: true, hours: [{ start: '09:00', end: '21:00' }] },
  tue: { opened: true, hours: [{ start: '09:00', end: '21:00' }] },
  wed: { opened: true, hours: [{ start: '09:00', end: '21:00' }] },
  thu: { opened: true, hours: [{ start: '09:00', end: '21:00' }] },
  fri: { opened: true, hours: [{ start: '09:00', end: '21:00' }] },
  sat: { opened: true, hours: [{ start: '09:00', end: '21:00' }] },
};

const linksInitialValues = { facebook: '', instagram: '', linkedIn: '', x: '', yelp: '', google: '' };
const Business = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classificationRef = useRef(null);
  const { qr, qrUrl } = useQr(true);
  const checkPermission = usePermission();
  const isLoadingQr = useQr(true).loading;
  const {
    business: { timeZone, cuisineType, businessClassification },
  } = useSelector((business) => business);
  const plusSymbol = '+';
  let btnName = {
    opt_out: t('settings.opt_out'),
    opt_in: t('settings.opt_in'),
  };
  const { isTypeSingle } = isPermissionWithCompanyType();
  const { currentBusiness } = useSelector(({ business }) => business);
  const businessBundle = currentBusiness.billingInfo?.plan?.name;
  const [addressFieldError, handleAddressFieldError] = useState(false);
  const [holidaySpecialHoursError, setHolidaySpecialHoursError] = useState(false);
  const [addressFieldsAreDirty, setAddressFieldsAreDirty] = useState(false);
  const [model, setModel] = useState({});
  const [loading, setLoading] = useState(true);
  const [workingHours, setWorkingHours] = useState(initialHours);
  const [specialDates, setSpecialDates] = useState([]);
  const [addressInfo, setAddressInfo] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [checked, setChecked] = useState(false);
  const [openChatGptGeneratePopup, setOpenChatGptGeneratePopup] = useState(false);
  const [currentBusinessPopularItems, setCurrentBusinessPopularItems] = useState([]);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [checkedGoogleBusiness, setCheckedGoogleBusiness] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loadBtn, setBtnModal] = useState(false);
  const [asapChecked, setAsapChecked] = useState(false);
  const [serverPrepTime, setServerPrepTime] = useState(0);
  const [serverPrepTimeSchedule, setServerPrepTimeSchedule] = useState(0);
  const [currentBrandName, setCurrentBrandName] = useState('');
  const [currentLoactionName, setCurrentLocationName] = useState('');

  useEffect(() => {
    if (currentBrandName && currentLoactionName) {
      const values = businessFormMethods.getValues();
      reset({
        ...values,
        brandName: currentBrandName,
        locationName: currentLoactionName,
      });
    }
  }, [currentBrandName, currentLoactionName]);

  const formRef = useRef();
  const businessFormMethods = useForm({
    defaultValues: model,
    mode: 'all',
    resolver: yupResolver(!isTypeSingle ? businessInfoIsNotSingle : businessInfo),
  });

  const {
    reset,
    getValues,
    watch,
    formState: { isSubmitted, isSubmitting },
  } = businessFormMethods;
  const phoneFieldWatcher = watch('phone');
  const settingsPinCode = watch('settings.pinCode');

  const {
    openConfirmModal,
    handleCloseConfirmModal,
    handleConfirm
  } = usePrepTimeModal(watch, businessFormMethods, serverPrepTime);

  const checkPhoneNumbers = (phone, supportPhone) => {
    let phoneNumber = phone?.replace(/[^0-9]/g, '');
    let supportPhoneNumber = supportPhone?.replace(/[^0-9]/g, '');
    setChecked(Boolean(phoneNumber === supportPhoneNumber));
  };

  const hasOwnProp = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

  useEffect(() => {
    if (model?.phone && checked) {
      businessFormMethods.setValue('supportPhone', getValues().phone);
    }
  }, [phoneFieldWatcher, checked, model]);

  const prepSelectOptions = useMemo(() => {
    return model?.settings?.asap?.disableAsap ? isAsapSwitcherTurnOn : isAsapSwitcherTurnOff;
  }, [model?.settings?.asap?.disableAsap]);

  const nameOfField = useMemo(() => {
    return model?.settings?.asap?.disableAsap ? 'settings.asap.qty' : 'defaultPrepTime';
  }, [model?.settings?.asap?.disableAsap]);

  const resetPrepTimeAsap = (newModel, currentDefaultPrepTime, schedulePrepTime) => {
    if (newModel?.settings?.asap?.disableAsap) {
      const findPrepTimeSchedule = isAsapSwitcherTurnOn.find((item) => item?.value === Number(schedulePrepTime));
      return {
        ...newModel,
        settings: {
          ...newModel.settings,
          asap: {
            ...newModel.settings.asap,
            qty: findPrepTimeSchedule?.value ?? isAsapSwitcherTurnOn[0]?.value,
          },
        },
      };
    } else {
      const findPrepTimeOff = isAsapSwitcherTurnOff.find((item) => item?.value === currentDefaultPrepTime);
      return {
        ...newModel,
        defaultPrepTime: findPrepTimeOff?.value ?? '',
      };
    }
  };

  const handleChangeAsap = (event, serverPrepTime, serverPrepTimeScheduleValue) => {
    setModel((currentModel) => {
      const updatedAsapSettings = {
        ...currentModel,
        settings: {
          ...currentModel.settings,
          asap: {
            ...currentModel.settings.asap,
            disableAsap: event.target.checked,
          },
        },
      };
      return resetPrepTimeAsap(updatedAsapSettings, serverPrepTime, serverPrepTimeScheduleValue);
    });
    setAsapChecked(event.target.checked);
  };

  useEffect(() => {
    if (Object.keys(model).length) {
      const modelCopy = { ...model };
      if (!model?.supportPhone) {
        modelCopy['supportPhone'] = modelCopy.phone;
        setChecked(true);
      } else {
        checkPhoneNumbers(modelCopy?.supportPhone, modelCopy.phone);
      }
      if (!modelCopy?.metadata || !modelCopy?.metadata?.communicationEmail) {
        modelCopy['metadata'] = { communicationEmail: '' };
      }
      reset({ ...modelCopy, brandName: currentBrandName, locationName: currentLoactionName }, { keepDirty: false });
    }
  }, [model, reset]);

  const handleSocialLinks = (socials) => {
    const links = { ...linksInitialValues };
    socials?.forEach((social) => {
      const [linkKey, linkValue] = Object.entries(social)[0];
      if (linkKey === 'instagram' || linkKey === 'x') {
        links[linkKey] = social?.handle ?? '';
      } else {
        links[linkKey] = linkValue;
      }
    });
    return links;
  };

  useEffect(() => {
    dispatch(getTimeZoneList());
    dispatch(getCuisines());
    dispatch(getBusinessClassification());
    getBusinessSettings().then((data) => {
      setServerPrepTime(data?.defaultPrepTime);
      setServerPrepTimeSchedule(data?.settings.asap?.qty);
      const currentModel = resetPrepTimeAsap(data, data?.defaultPrepTime, data?.settings.asap?.qty);
      if (hasOwnProp(currentModel, 'googleFeedEnabled')) {
        setCheckedGoogleBusiness(currentModel?.googleFeedEnabled);
      } else setCheckedGoogleBusiness(false);
      const links = handleSocialLinks(currentModel.links);
      setSpecialDates(currentModel.specialDates);
      setWorkingHours(currentModel.workingHours);
      setModel({
        ...currentModel,
        timezone: currentModel.timezone.timeZoneId,
        links: links,
        classifications: data?.classifications[0]?.name,
      });
      setAsapChecked(currentModel.settings.asap.disableAsap);
      dispatch(getLocationName(data?.locationId)).then((location) => {
        setCurrentLocationName(location?.data?.data?.locationName);
      });
      dispatch(getBrandName(data?.brandId)).then((brand) => {
        setCurrentBrandName(brand?.data?.data?.brandName);
      });
      setLoading(false);
    });
  }, []);

  // useEffect(() => {
  //   const businessId = JSON.parse(localStorage.getItem('businessInfo'))?.id || '';
  //   if (businessId) {
  //     return instance
  //       .get(`/products/popular`)
  //       .then((res) => {
  //         if (res?.data?.data?.length) {
  //           const newArray = res?.data?.data?.map((item) => item.name);
  //           setCurrentBusinessPopularItems(newArray || []);
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, []);

  useEffect(() => {
    const businessId = JSON.parse(localStorage.getItem('businessInfo'))?.id || '';
    if (businessId) {
      return instance
        .get(`/products/popular`)
        .then((res) => {
          if (res?.data?.data?.length) {
            const newArray = res?.data?.data?.map((item) => item.name);
            setCurrentBusinessPopularItems(newArray || []);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const getAddressFields = (addressInfo) => {
    setAddressInfo(addressInfo);
    setAddressFieldsAreDirty(true);
    businessFormMethods.setValue('addressInfo.city', addressInfo.city || getValues().addressInfo.city || '');
    businessFormMethods.setValue('addressInfo.state', addressInfo.state || getValues().addressInfo.state || '');
    businessFormMethods.setValue('addressInfo.zipCode', addressInfo.zipCode || getValues().addressInfo.zipCode || '');
    businessFormMethods.setValue('addressInfo.fullAddress', addressInfo.fullAddress || '');
    businessFormMethods.setValue('addressInfo.address', addressInfo.address || '');
    businessFormMethods.setValue('addressInfo.address1', addressInfo.address1 || '');
    businessFormMethods.setValue('address1', addressInfo.address1 || getValues().addressInfo.address1 || '');
    businessFormMethods.setValue(
      'addressInfo.address2',
      addressInfo.address2 || getValues().addressInfo.address2 || ''
    );
    businessFormMethods.setValue('address', addressInfo.address || getValues().addressInfo.address || '');
    model.addressInfo.address1 = addressInfo.address1 || getValues().addressInfo.address1 || '';
  };

  const scrollToErrorField = () => {
    var headerOffset = 75;
    if (getValues()?.settings?.asap?.disableAsap && !getValues()?.settings?.asap?.qty) {
      businessFormMethods.setError('settings.asap.qty', { type: 'custom', message: 'field_is_required_' });
    } else {
      businessFormMethods.clearErrors('settings.asap.qty');
    }
    if (!getValues()?.settings?.asap?.disableAsap && !getValues().defaultPrepTime) {
      businessFormMethods.setError('defaultPrepTime', { type: 'custom', message: 'field_is_required_' });
    } else {
      businessFormMethods.clearErrors('defaultPrepTime');
    }
    const elements = document.getElementsByClassName('MuiOutlinedInput-input');
    for (let i = 0; i < elements.length; i++) {
      const inValid = elements[i].getAttribute('aria-invalid');
      if (
        inValid == 'true' ||
        !getValues().cuisineType ||
        (getValues()?.settings?.asap?.disableAsap && !getValues()?.settings?.asap?.qty) ||
        (!getValues()?.settings?.asap?.disableAsap && !getValues().defaultPrepTime)
      ) {
        const elementPosition = formRef.current?.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
        break;
      }
    }
  };

  const handleValidSubmit = useCallback(
    (values, _, checked = false) => {
      if (
        Object.keys(businessFormMethods.formState.errors)?.length ||
        (values?.settings?.asap?.disableAsap && !values?.settings?.asap?.qty) ||
        (!values?.settings?.asap?.disableAsap && !values.defaultPrepTime)
      ) {
        scrollToErrorField();
        return;
      }

      if (checkPermission(all['SUPER']) && !values?.classifications) {
        classificationRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        businessFormMethods.setError('classifications', { type: 'custom', message: 'field_is_required_' });
        return;
      }
      businessFormMethods.clearErrors('settings.asap.qty', { keepDirty: false });
      businessFormMethods.clearErrors('defaultPrepTime', { keepDirty: false });
      let newClassifications = [];
      const currentClassification = businessClassification.find((option) => option.name === values?.classifications);
      if (currentClassification) {
        businessFormMethods.clearErrors('classifications', { keepDirty: false });
        newClassifications.push(currentClassification?._id);
      }
      setIsSubmit(true);
      if (holidaySpecialHoursError) {
        setLoading(false);
        return;
      } else {
        setLoading(true);
        const validDates = [];
        specialDates.forEach((i) => {
          if (i.date) {
            validDates.push(i);
          }
        });
        if (specialDates.length && specialDates.length !== validDates.length) {
          return;
        }
        if (values.supportPhone === plusSymbol) {
          values.supportPhone = null;
        }
        if (checked && values.settings.pinCode) {
          dispatch(updatePinCode(values.settings.pinCode));
        }
        const timeZoneObj = timeZone.find((item) => item?.timeZoneId === values.timezone);
        const newAddressInfo = {
          ...values.addressInfo,
          ...addressInfo,
        };
        const linksForSubmit = [];
        values.links &&
          Object.keys(values.links)?.forEach((key) => {
            if (key === 'instagram' || key === 'x') {
              linksForSubmit.push({ [key]: values.links[key], handle: values.links[key] ?? '' });
            } else {
              linksForSubmit.push({ [key]: values.links[key] });
            }
          });

        values.defaultPrepTime = Number(values.defaultPrepTime);
        values.metadata = {
          ...values.metadata,
          place_id: addressInfo?.placeId,
        };
        const finalModel = {
          // ...model,
          ...values,
          workingHours,
          specialDates: validDates,
          timezone: timeZoneObj,
          ...newAddressInfo,
          links: linksForSubmit,
          classifications: newClassifications,
        };

        finalModel.address2 = values.address2;
        finalModel.addressInfo = {
          ...finalModel.addressInfo,
          ...newAddressInfo,
        };

        finalModel.addressInfo.zipCode = values.addressInfo.zipCode;
        finalModel.addressInfo.city = values.addressInfo.city;
        finalModel.addressInfo.state = values.addressInfo.state;
        finalModel.addressInfo.address2 = values.addressInfo.address2;
        finalModel.addressInfo.address1 = values.addressInfo.address1 || getValues()?.addressInfo?.address1;
        finalModel.addressInfo.address = getValues()?.addressInfo?.address || finalModel.addressInfo.address1;

        if (finalModel.addressInfo.address1 || finalModel.addressInfo.address2) {
          finalModel.addressInfo.fullAddress = `${finalModel.addressInfo.address1 || getValues().addressInfo.address} ${values.addressInfo.address2 || finalModel.addressInfo.address2
            }, `;
        }
        if (finalModel.addressInfo.city) {
          finalModel.addressInfo.fullAddress += `${values.addressInfo.city}, `;
        }
        if (finalModel.addressInfo.state || finalModel.addressInfo.zipCode) {
          finalModel.addressInfo.fullAddress += `${values.addressInfo.state} ${values.addressInfo.zipCode} ${addressInfo.country ? ',' : ''
            } `;
        }
        if (addressInfo.country) {
          finalModel.addressInfo.fullAddress += `${addressInfo.country || values.addressInfo.country}`;
        }
        setAddressFieldsAreDirty(false);
        const location = finalModel.locationName;
        const brand = finalModel.brandName;
        delete finalModel.locationName;
        delete finalModel.brandName;
        dispatch(updateBusinessSettings(finalModel))
          .then((response) => {
            setServerPrepTimeSchedule(Number(response?.settings.asap?.qty));
            setServerPrepTime(response?.defaultPrepTime);
            setLoading(false);
            setIsSubmit(false);
            const links = handleSocialLinks(response.links);
            const currentModel = resetPrepTimeAsap(response, response?.defaultPrepTime, response?.settings.asap?.qty);

            let currentClassificationsItem = {};
            // refactor this part
            if (!hasOwnProp(response?.classifications[0], 'name')) {
              currentClassificationsItem = businessClassification.find(
                (item) => item._id === finalModel?.classifications[0]
              );
            }



            setModel({
              ...finalModel,
              phone: currentModel?.phone,
              supportPhone: currentModel?.supportPhone || currentModel?.phone,
              timezone: values.timezone,
              links,
              classifications: currentClassificationsItem?.name ?? currentModel?.classifications[0]?.name,
            });
            reset(
              {
                ...currentModel,
                timezone: values.timezone,
                links,
                classifications: currentClassificationsItem?.name ?? currentModel?.classifications[0]?.name,
              },
              { keepDirty: false }
            );
            if (currentModel?.phone && currentModel?.supportPhone) {
              checkPhoneNumbers(currentModel?.phone, currentModel?.supportPhone);
            }
          })
          .catch(() => {
            setIsSubmit(false);
            setLoading(false);
          });
        dispatch(updateLocationName(finalModel.locationId, { locationName: location }));
        dispatch(updateBrandName(finalModel?.brandId, { companyName: brand }));
        // .finnaly(() => {
        //   setIsSubmit(false);
        //   setLoading(false);
        // });
      }
    },
    [
      setAddressFieldsAreDirty,
      addressFieldsAreDirty,
      dispatch,
      model,
      isSubmit,
      holidaySpecialHoursError,
      workingHours,
      specialDates,
      addressInfo,
      businessClassification,
    ]
  );

  useEffect(() => {
    const businessId = JSON.parse(localStorage.getItem('businessInfo'))?.id || '';
    if (businessId) {
      return instance
        .get(`/products/popular`)
        .then((res) => {
          if (res?.data?.data?.length) {
            const newArray = res?.data?.data?.map((item) => item.name);
            setCurrentBusinessPopularItems(newArray || []);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    scrollToErrorField();
  }, [isSubmitting]);

  const onChangeAddressInput = (address) => {
    // setAddressFieldsAreDirty(true);
    const exist = !address;
    handleAddressFieldError(exist);
    businessFormMethods.setValue('address', address || '');
    businessFormMethods.setValue('addressInfo.address1', address || addressInfo.address1 || '');
    model.addressInfo.address1 = address || addressInfo.address1 || '';
  };

  const handleChangeEnablePinCode = ({ target: { checked } }) => {
    if (!checked) {
      dispatch(updatePinCode({ enable: checked, code: '' }));
    }
    businessFormMethods.setValue('settings.pinCode', { enable: checked, code: '' }, { shouldDirty: true });
  };

  const submitPinCode = () => {
    if (String(settingsPinCode?.code)?.length !== 4) {
      return;
    }
    dispatch(updatePinCode(settingsPinCode)).then(() => {
      businessFormMethods.setValue('settings.pinCode', settingsPinCode, { shouldDirty: true });
      delete businessFormMethods.formState.dirtyFields.settings;
    });
  };

  const handleCheck = (event) => {
    if (event) {
      setChecked(event.target.checked);
      if (model?.phone && event.target.checked) {
        businessFormMethods.setValue('supportPhone', getValues().phone);
      }
    }
  };

  const handleOpenGeneratePopup = () => {
    setOpenChatGptGeneratePopup(true);
  };

  const handleCopy = () => {
    setShowCopyMessage(true);
    navigator?.clipboard?.writeText(qrUrl || `https://food.orders.co/${currentBusiness.settings.url}`);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 2000);
  };

  const downloadQr = () => {
    saveAs(qr, `${currentBusiness.settings.url}-QR.jpg`); // Put your image url here.
  };

  const handleOpenClick = (event) => {
    setBtnModal(true);
    applyGoogleBusinessLink({ submit: !checkedGoogleBusiness })
      .then(() => {
        setOpenModal(true);
        setBtnModal(false);
      })
      .finally(() => setBtnModal(false));
    event.stopPropagation();
  };
  const handleCloseModal = () => setOpenModal(false);

  return (
    <ErrorBoundaryWrapper>
      <Box>
        <ConfirmPrepTimeDialog
          handleClose={handleCloseConfirmModal}
          isOpen={openConfirmModal}
          handleConfirm={handleConfirm}
        />
        <ConfirmPromptModal
          handleConfirm={businessFormMethods.handleSubmit(handleValidSubmit)}
          submitData={businessFormMethods.getValues()}
          hasUnsavedChanges={
            (businessFormMethods.formState.isDirty &&
              Object.keys(businessFormMethods.formState.dirtyFields).filter(
                (key) => businessFormMethods.formState.dirtyFields[key]
              )?.length) ||
            addressFieldsAreDirty
          }
          formState={businessFormMethods}
          setAddressFieldsAreDirty={setAddressFieldsAreDirty}
        />
        <DialogChatGptDesc
          open={openChatGptGeneratePopup}
          formMethods={businessFormMethods}
          setOpenChatGptGeneratePopup={setOpenChatGptGeneratePopup}
          textData={TEXTS_BUSINESS_CHAT_GPT}
          popularItems={currentBusinessPopularItems}
        />
        <Typography sx={{ my: 2 }} variant="h5">
          {t('settings.business_information')}
        </Typography>
        {loading ? (
          <Preloader />
        ) : (
          <FormProvider {...businessFormMethods}>
            <form
              ref={formRef}
              onSubmit={businessFormMethods.handleSubmit(handleValidSubmit, (err) => console.log(err, 'err'))}
            >
              <Grid container spacing={2} columns={{ xs: 12 }}>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>{t('settings.business_logo')}</InputLabel>
                    <ImageUpload
                      setFormValue={businessFormMethods.setValue}
                      initialValue={model?.avatar}
                      name="avatar"
                      model={model}
                      setModel={setModel}
                      className="d-block mb-3"
                      size={100}
                      removable
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4}>
                  <FormGroup>
                    <InputLabel>{t('settings.cover_photo')}</InputLabel>
                    <ImageUpload
                      setFormValue={businessFormMethods.setValue}
                      initialValue={model?.image}
                      name="image"
                      model={model}
                      setModel={setModel}
                      className="d-block mb-3"
                      size={200}
                      removable
                      isBackground={true}
                      height={'450'}
                      width={'800'}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={8}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {qr && businessBundle !== 'App Bundle' && (
                      <>
                        <FormGroup>
                          <InputLabel>{t('website_settings.qr_code')}</InputLabel>
                          <Box sx={{ width: '228px', position: 'relative' }}>
                            {isLoadingQr ? (
                              <Preloader overlay={true} />
                            ) : qr ? (
                              <Box sx={{ height: '228px', width: '100%' }}>
                                <img src={qr} alt="Live Chat" style={{ width: '100%', height: '100%' }} />
                              </Box>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </FormGroup>
                        <Box
                          sx={{
                            position: 'relative',
                            ml: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Button
                            id="businessDownload"
                            sx={{
                              color: '#626A7A',
                              borderRadius: '6px',
                              border: ' 1px solid rgba(98, 106, 122, 0.50)',
                            }}
                            variant="outlined"
                            startIcon={<DownloadIcon />}
                            onClick={downloadQr}
                          >
                            {t('Download QR')}
                          </Button>

                          <Button
                            id="businessDownloadURL"
                            sx={{
                              color: '#626A7A',
                              borderRadius: '6px',
                              border: ' 1px solid rgba(98, 106, 122, 0.50)',
                              mt: 2,
                            }}
                            onClick={handleCopy}
                            variant="outlined"
                            startIcon={<ContentCopyIcon />}
                          >
                            <Typography
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '250px',
                                fontWeight: 500,
                                fontSize: '0.875rem',
                              }}
                            >
                              {qrUrl || `https://food.orders.co/${currentBusiness.settings.url}`}
                            </Typography>
                          </Button>
                          <Box
                            sx={{
                              position: 'absolute',
                              bottom: '-42px',
                              mt: 1,
                              background: '#626A7A',
                              padding: '4px 12px',
                              borderRadius: '6px',
                            }}
                            className={`message-container ${showCopyMessage ? 'show fade-in' : ''}`}
                          >
                            <Typography color="white" variant="body2" className="message-text">
                              {t('Copied')}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <CustomTextInput
                    sx={{ width: '100%', mb: 2 }}
                    name="companyName"
                    label={t('settings.business_name')}
                    disabled={!checkPermission(all['SUPER'])}
                  />
                </Grid>
                <Grid item xs={4} sx={{ height: '72px' }}>
                  <CustomTextInput
                    sx={{ width: '100%', mb: 2 }}
                    name="companyShortName"
                    label={
                      <LabelTooltip
                        required
                        value={t('settings.business_short_name')}
                        id="brandNameTooltip"
                        tooltip="Should contain only Latin characters and numbers
                    and be 5 to 13 characters long."
                      />
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextInput
                    sx={{ width: '100%', mb: 2 }}
                    name="title"
                    label={<Box>{t('settings.business_title')}</Box>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <GooglAutocomplete
                    onChangeAddressInput={onChangeAddressInput}
                    getAddressFields={getAddressFields}
                    name="addressInfo.address1"
                    initialValue={model.addressInfo.address1 || ''}
                    inputValue={model.addressInfo.address1 || ''}
                    label={t('settings.business_address')}
                    placeholder=""
                    required={true}
                    formSubmitted={isSubmitted}
                    disabled={!checkPermission(all['SUPER'])}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    name="addressInfo.address2"
                    label={
                      <Box>
                        {t('settings.business_address')}
                        {` `}2
                      </Box>
                    }
                    disabled={!checkPermission(all['SUPER'])}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    name="addressInfo.city"
                    label={t('settings.city')}
                    disabled={!checkPermission(all['SUPER'])}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    name="addressInfo.state"
                    label={t('settings.state')}
                    disabled={!checkPermission(all['SUPER'])}
                  />
                </Grid>
                <Grid item xs={3}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    name="addressInfo.zipCode"
                    label={t('settings.zip_code')}
                    disabled={!checkPermission(all['SUPER'])}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    name="locationName"
                    label={t('settings.locationName')}
                    disabled={!checkPermission(all['SUPER'])}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    name="brandName"
                    label={t('settings.brandName')}
                    disabled={!checkPermission(all['SUPER'])}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography sx={{ fontWeight: 400, mt: 2, fontSize: '24px' }}>
                    {t('settings.preparation_time')}
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ width: 'max-content' }}
                      control={
                        <Switch
                          onChange={(event) => handleChangeAsap(event, serverPrepTime, serverPrepTimeSchedule)}
                          checked={asapChecked}
                        />
                      }
                      label={t('settings.disabled_asap')}
                    />
                  </FormGroup>
                  <Grid item xs={6}>
                    <CustomTextInput
                      sx={{ width: '100%' }}
                      inputProps={{
                        type: 'number',
                      }}
                      select={true}
                      name={nameOfField}

                      label={
                        <LabelTooltip
                          required
                          id="defaultPrepTimeTooltip"
                          value={t('settings.preparation_time')}
                          tooltip="Shared with Delivery Services/ Website to created Customer ETA. Be sure to provide sufficient time for order preparation."
                        />
                      }
                    >
                      {prepSelectOptions.map((option) => (
                        <MenuItem key={option.key} value={option.value}>
                          {option?.name}
                        </MenuItem>
                      ))}
                    </CustomTextInput>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12}>
                  <OrderBufferTime formMethods={businessFormMethods} />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography sx={{ fontWeight: 400, mt: 2, fontSize: '24px' }}>
                    {t('settings.communication_information')}
                  </Typography>
                  <Typography>{t('settings.used_performance_reports')}</Typography>
                </Grid>
                <Grid item md={12} xs={6}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    name="metadata.communicationEmail"
                    label={<LabelRequired>{t('sign_in.communication_email')}</LabelRequired>}
                  />
                </Grid>
                <div className="business-divider" />
                <Grid item xs={4}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    select={true}
                    name="timezone"
                    label={<LabelRequired>{t('settings.time_zone')}</LabelRequired>}
                  >
                    {timeZone
                      .sort((a, b) => (a.timeZoneName > b.timeZoneName ? 1 : -1))
                      .map((option) => (
                        <MenuItem key={option.timeZoneId} value={option.timeZoneId}>
                          {`${option?.timeZoneName}  (${option?.timeZoneId})`}
                        </MenuItem>
                      ))}
                  </CustomTextInput>
                </Grid>
                <Grid item xs={4}>
                  <CustomMobilePhone
                    sx={{ width: '100%' }}
                    name="phone"
                    label={<LabelRequired>{t('settings.business_phone_number')}</LabelRequired>}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomMobilePhone
                    disabled={checked}
                    sx={{ width: '100%' }}
                    name="supportPhone"
                    label={t('settings.business_support_phone_number')}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={checked} onChange={handleCheck} />}
                      label={t('settings.same_business_phone_number')}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    inputProps={{
                      type: 'url',
                      placeholder: 'e.g. https://website.com',
                    }}
                    name="website"
                    label={
                      <LabelTooltip
                        id="websiteTooltip"
                        value={t('settings.business_website')}
                        tooltip="Enter your existing website domain here for public viewing."
                      />
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    name="id"
                    disabled={true}
                    label={t('settings.businessId')}
                  />
                </Grid>
                <Grid item xs={4} ref={classificationRef}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    name="classifications"
                    select={true}
                    disabled={!checkPermission(all['SUPER'])}

                    label={
                      !checkPermission(all['SUPER']) ? (
                        t('settings.business_classification')
                      ) : (
                        <LabelRequired>{t('settings.business_classification')}</LabelRequired>
                      )
                    }
                  >
                    {businessClassification.map((option) => (
                      <MenuItem key={option._id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </CustomTextInput>
                </Grid>
                <Grid item xs={6}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    inputProps={{
                      type: 'email',
                    }}
                    name="email"
                    label={
                      <LabelTooltip
                        required={false}
                        id="businessEmailTooltip"
                        value={t('settings.business_email')}
                        tooltip="Publicly visible on your website for business inquiries."
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextInput
                    disabled={true}
                    sx={{ width: '100%' }}
                    name="restaurantMailbox"
                    label={t('settings.restaurant_mailbox')}
                  />
                </Grid>
                {isTypeSingle && (
                  <>
                    <Grid item xs={6}>
                      <CustomTextInput
                        sx={{ width: '100%' }}
                        select={true}
                        name="cuisineType"
                        label={<LabelRequired>{t('settings.cuisine_type')}</LabelRequired>}
                      >
                        {cuisineType.map((option) => (
                          <MenuItem key={option._id} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </CustomTextInput>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTextInput
                        sx={{ width: '100%' }}
                        inputProps={{
                          type: 'number',
                          step: 'any',
                        }}
                        name="tax"
                        label={<LabelRequired>{t('settings.tax_rate')} (%)</LabelRequired>}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          mb: 1,
                        }}
                      >
                        <Button
                          id="businessAI"
                          variant="contained"
                          onClick={handleOpenGeneratePopup}
                          sx={{
                            background: '#F4F4F4',
                            padding: '12px 16px',
                            boxShadow: 0,
                            '&:hover': {
                              background: '#F4F4F4',
                              boxShadow: 0,
                            },
                          }}
                        >
                          <Typography
                            fontSize={14}
                            mr={1}
                            sx={{
                              textTransform: 'uppercase',
                              color: '#0F1214',
                            }}
                            fontWeight={700}
                          >
                            {t('settings.generate_with_AI')}
                          </Typography>
                          <Box component="img" src={ChatGptIcon} />
                        </Button>
                      </Box>

                      <CustomTextInput
                        sx={{ width: '100%' }}
                        multiline={true}
                        rows={3}
                        inputProps={{
                          type: 'textarea',
                        }}
                        name="about"

                        label={
                          <LabelTooltip
                            id="aboutBusinessTooltip"
                            value={t('settings.about_business')}
                            tooltip="Public visible description about your business and cuisine. “About Us”"
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ mb: 2 }}>
                      <Typography sx={{ fontSize: '16px', fontWeight: 'bold', mb: 2 }}>
                        {t('settings.social_media_links')}
                      </Typography>
                      <CustomTextInput
                        sx={{ width: '100%', mb: 2 }}
                        name="links.x"
                        label={t('settings.social_X')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img src={Twitter} alt="Twitter" width="24" height="24" />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomTextInput
                        sx={{ width: '100%', mb: 2 }}
                        name="links.linkedIn"
                        label={t('settings.social_linkedIn')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img src={Linkedin} alt="LinkedIn" width="24" height="24" />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomTextInput
                        sx={{ width: '100%', mb: 2 }}
                        name="links.instagram"
                        label={t('settings.social_instagram')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img width="24" height="24" src={Instagram} alt="Instagram" />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomTextInput
                        sx={{ width: '100%', mb: 2 }}
                        name="links.facebook"
                        label={t('settings.social_facebook')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img width="24" height="24" src={Facebook} alt="Facebook" />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography sx={{ fontSize: '16px', fontWeight: 'bold', mb: 2 }}>
                        {t('settings.review_business_links')}
                      </Typography>
                      <CustomTextInput
                        sx={{ width: '100%', mb: 2 }}
                        name="links.yelp"
                        label={t('settings.social_Yelp')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img width="24" height="24" src={Yelp} alt="Facebook" />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomTextInput
                        sx={{ width: '100%', mb: 2 }}
                        name="links.google"
                        label={t('settings.social_Google')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img width="24" height="24" src={Google} alt="Facebook" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{
                        mt: 2,
                        borderTop: '1px solid #c5c6c6',
                        borderBottom: '1px solid #c5c6c6',
                        '.Mui-disabled': {
                          backgroundColor: '#c5c6c6 !important',
                          color: 'white',
                        },
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          {...businessFormMethods.register('settings.pinCode.enable')}
                          sx={{ width: 'max-content' }}
                          control={<Switch onChange={handleChangeEnablePinCode} checked={settingsPinCode.enable} />}
                          label={t('settings.enable_change_code')}
                        />
                      </FormGroup>

                      {settingsPinCode.enable && (
                        <TextField
                          size="small"
                          sx={{ ml: '50px', pr: '0 !important' }}
                          type="number"
                          helperText={
                            t(businessFormMethods.formState.errors?.settings?.pinCode?.code?.message) ||
                            t('settings.enter_4_digit_code')
                          }
                          {...businessFormMethods.register('settings.pinCode.code')}
                          onWheel={onWheelPreventChangeNumberField}
                          inputProps={{
                            maxLength: 4,
                            sx: { pr: 0 },
                            onInput: (e) => {
                              if (e.target.value.length > 4) {
                                e.target.value = e.target.value.slice(0, 4);
                              }
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disableFocusRipple={false}
                                  onClick={submitPinCode}
                                  color="primary"
                                  disabled={
                                    String(settingsPinCode?.code?.length) < 4 ||
                                    !businessFormMethods.formState.dirtyFields?.settings?.pinCode?.code
                                  }
                                  variant="contained"
                                  sx={{
                                    background: (theme) => theme.palette.primary.main,
                                    borderRadius: 0,
                                    color: 'white',
                                    mr: '-14px',
                                    '&:hover': {
                                      backgroundColor: '#03a9f4',
                                    },
                                  }}
                                  edge={false}
                                >
                                  <CheckIcon />{' '}
                                  {String(settingsPinCode?.code?.length) < 4 ||
                                    (businessFormMethods.formState.dirtyFields?.settings?.pinCode?.code && (
                                      <Typography sx={{ ml: 1 }}>{t('settings.submit_code')}</Typography>
                                    ))}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        borderBottom: '1px solid #c5c6c6',
                        '.Mui-disabled': {
                          color: 'white',
                        },
                      }}
                    >
                      <BusinessSettingsTitle>{t('settings.google_business_settings')} </BusinessSettingsTitle>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                        <FormGroup
                          sx={{ mt: 2 }}
                          checked={checkedGoogleBusiness}
                          onChange={() => { }}
                          name={`googleFeedEnabled`}
                        >
                          <FormControlLabel
                            control={<Switch checked={checkedGoogleBusiness} disabled={true} />}
                            label={t('settings.enable_google_business_link')}
                          />
                        </FormGroup>
                        <LoadingButton
                          loading={loadBtn}
                          onClick={() => handleOpenClick()}
                          sx={{
                            color: '#2D7FF9',
                            mt: 1,
                            border: '1px solid #2D7FF9',
                            width: '110px',
                            borderRadius: '6px',
                          }}
                          variant="outlined"
                        >
                          {checkedGoogleBusiness ? t('settings.opt_out') : t('settings.opt_in')}
                        </LoadingButton>
                      </Box>
                    </Grid>
                    {openModal && (
                      <BusinessDialog
                        handleClose={handleCloseModal}
                        icon={OptImage}
                        open={openModal}
                        description={t('settings.opt_description', {
                          btnName: checkedGoogleBusiness ? btnName.opt_out : btnName.opt_in,
                        })}
                        title={checkedGoogleBusiness ? t('settings.opt_out') : t('settings.opt_in')}
                      />
                    )}
                    <Grid item xs={12}>
                      <Hours businessModel={businessFormMethods} data={workingHours} onChange={setWorkingHours} />
                    </Grid>
                    <Grid item xs={12}>
                      <SpecialDates
                        isSubmitted={isSubmit}
                        data={specialDates}
                        businessModel={businessFormMethods}
                        setHolidaySpecialHoursError={setHolidaySpecialHoursError}
                        onChange={setSpecialDates}
                        timezone={model?.timezone}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <div className="business-footer">
                <Button id="businessUpdate" variant="contained" color="primary" type="submit">
                  {t('website_settings.update')}
                </Button>
              </div>
            </form>
          </FormProvider>
        )}
      </Box>
    </ErrorBoundaryWrapper>
  );
};

export default Business;
