import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { get } from 'lodash';
import { phoneValidator } from 'redux/actions/user';
import { useTranslation } from 'react-i18next';

const CustomMobilePhone = ({
  name,
  id,
  sx = {
    width: '100%',
    pb: 2,
  },
  children,
  label = '',
  inputProps,
  hidden,
  onChange,
  disabled,
  InputProps,
  size = 'small',
  labelProps = {
    shrink: true,
  },
  variant = 'outlined',
  ...params
}) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext();
  const { errors } = formState;
  let parsedError = get(errors, name);

  const handleBlur = (e) => {
    const { value } = e.target;
    if (!parsedError?.message && value?.trim()?.length) {
      phoneValidator({
        phoneNumber: value,
      });
    } else {
      return null;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      {...params}
      render={({ field }) => {
        return (
          <TextField
            {...field}
            size={size}
            variant={variant}
            hidden={hidden}
            error={!!parsedError || params.error || false}
            helperText={parsedError ? t(parsedError.message) : t(params.helperText) || ''}
            label={label}
            inputRef={field.ref}
            disabled={disabled}
            InputProps={InputProps}
            sx={sx}
            inputProps={{
              ...inputProps,
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
            onChange={onChange || field.onChange}
            onBlur={(e) => handleBlur(e) || field.onBlur(e)}
            InputLabelProps={labelProps}
          >
            {children}
          </TextField>
        );
      }}
    />
  );
};

export default CustomMobilePhone;
