import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

const initialData = { website: {}, total: {} };

const StackedChart = ({ data = initialData }) => {
  // const { providers } = useProviderConfig();

  const chartData = useMemo(
    () => ({
      labels: Object.keys(data.website || data.food2),
      datasets: [
        {
          label: 'Website',
          backgroundColor: 'rgba(221, 113, 93, 1.00)',
          data: Object.values(data.website || data.food2),
        },
        {
          label: 'Total',
          backgroundColor: 'rgba(61, 163, 232, 1.00)',
          data: Object.values(data.total),
        },
      ],
    }),
    [data]
  );
  return <Bar data={chartData} options={options} />;
};

const options = {
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  legend: {
    position: 'bottom',
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
};

export default StackedChart;
