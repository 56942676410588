import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import useProviderConfig from 'hooks/useProviderConfig';
import { PauseResumeContext } from 'context/pauseResumeContext';
import { StyledProviderTitle } from '../ProviderStatusPopover/styles';
import ProviderStatusItem from '../ProviderStatusPopover/ProviderStatusItem';

const PauseResumeProviderStep = () => {
  const { t } = useTranslation();
  const { providers } = useProviderConfig();
  const { filteredProviders, iconStyle } = useContext(PauseResumeContext);
  const businessLabel = JSON.parse(localStorage.getItem('businessInfo'))?.label;
  return (
    <>
      <StyledProviderTitle>
        {t('pause_resume.provider_status_title')} {businessLabel?.split(' - ')[0]}
      </StyledProviderTitle>
      <>
        {filteredProviders.map((item) => {
          const { logoUrl, label } = providers[item?.provider];
          return (
            <React.Fragment key={label}>
              <ProviderStatusItem
                logoUrl={logoUrl}
                label={label}
                iconStyle={iconStyle(item?.provider_status)}
                showDivider={false}
                item={item}
              />
            </React.Fragment>
          );
        })}
      </>
    </>
  );
};
export default PauseResumeProviderStep;
