import { forwardRef } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { OrdersList } from 'components/Order';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GroupOrderDialog({ title, open, handleClose, data, onSelect, handleCloseSubOrderDialog }) {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '100%',
            },
          },
        }}
      >
        <DialogTitle sx={{ pt: 3 }}>
          <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '24px', fontWeight: 400, lineHeight: '32px', color: '#1D1B20' }}>
              {title}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: '100%' }}>
            <OrdersList isSubOrder={true} data={data} onSelect={onSelect} />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ pt: 2, pb: 2, pl: 3, pr: 3 }}>
          <Button
            sx={{
              background: 'primary',
              '&:hover': { background: 'primary' },
              width: '54px',
            }}
            onClick={handleClose}
            variant="contained"
            color="primary"
          >
            {t('settings.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
