import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import TextField from '@mui/material/TextField';
import StripeInput from './StripeInput';
import { useTranslation } from 'react-i18next';

// import CustomTextInput from 'components/Forms/CustomTextInput';

export const StripeTextField = (props) => {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    ...other
  } = props;

  return (
    <TextField
      fullWidth
      size="small"
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      error={error}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
        },
        inputComponent: StripeInput,
      }}
      helperText={error ? labelErrorMessage : helperText}
      {...other}
    />
  );
};

export function StripeTextFieldNumber(props) {
  const { t } = useTranslation();

  return <StripeTextField label={t('sign_up.credit_card_number')} stripeElement={CardNumberElement} {...props} />;
}

export function StripeTextFieldExpiry(props) {
  const { t } = useTranslation();

  return <StripeTextField label={t('sign_up.expires')} stripeElement={CardExpiryElement} {...props} />;
}

export function StripeTextFieldCVC(props) {
  const { t } = useTranslation();
  return <StripeTextField label={t('sign_up.cvc_code')} stripeElement={CardCvcElement} {...props} />;
}
