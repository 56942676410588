import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MODIFIED_LOG_OUT } from 'constants/user';
import { ModalOpenStatusEnum, SET_PRODUCT_EVENTS_NEXT_LOCATION } from 'components/ConfirmProductActions/constants';
import useQuery from '../../hooks/useQuery';

export const ConfirmPromptModal = ({
  handleConfirm,
  submitData,
  hasUnsavedChanges,
  formState,
  isLoading = false,
  message = 'confirm_modal.message',
  title = 'confirm_modal.title',
  isFormError = false,
  notHookForm = false,
  setAddressFieldsAreDirty,
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const mode = query.get('mode');
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [newLocation, setNewLocation] = useState(null);
  const [oldLocation, setOldLocation] = useState(null);
  const { modifiedLogOut } = useSelector((state) => state.user);
  const { productEvents } = useSelector((store) => store);

  const handleNavigation = (nextLocation) => {
    let cancel = localStorage.getItem('cancel');
    let isError = localStorage.getItem('isFormError');

    if (cancel !== null && Boolean(cancel) && !history.location.search) {
      localStorage.removeItem('cancel');
      setOpen(false);
      if (!hasUnsavedChanges) {
        dispatch({ type: MODIFIED_LOG_OUT, payload: false });
        history.push(`${history.location.pathname}${history.location.search}`);
      }
      return false;
    }

    if (isError !== null && Boolean(isError)) {
      localStorage.removeItem('isFormError');
      setOpen(false);
      history.push(`${history.location.pathname}${history.location.search}`);
      return false;
    }

    if (modifiedLogOut && hasUnsavedChanges) {
      dispatch({ type: MODIFIED_LOG_OUT, payload: 'opened' });
      setOpen(true);
      return false;
    }

    setNewLocation(nextLocation);

    if (nextLocation?.state === 'skip') {
      setOpen(false);
      return true;
    }

    if (productEvents.modalStatus === ModalOpenStatusEnum.cancel || nextLocation?.state?.cancel) {
      setNewLocation(`${history.location.pathname}${history.location.search}`);
    }

    if (
      (hasUnsavedChanges ||
        (productEvents.eventData?.length && productEvents.modalStatus === ModalOpenStatusEnum.cancel)) &&
      !isLoading &&
      !nextLocation?.state?.cancel
    ) {
      if (
        history.location.pathname !== nextLocation.pathname ||
        history.location?.search !== nextLocation.search ||
        oldLocation
      ) {
        if (!nextLocation?.state?.goBack) {
          setOpen(true);
          return false;
        }
      }
    } else {
      setOpen(false);
      return true;
    }
  };

  useEffect(() => {
    const unblock = history.block(handleNavigation);
    return () => {
      localStorage.removeItem('cancel');
      unblock();
    };
  }, [
    hasUnsavedChanges,
    setOldLocation,
    oldLocation,
    productEvents.modalStatus,
    history,
    modifiedLogOut,
    handleNavigation,
  ]);

  const handleSubmit = (e) => {
    setOldLocation(null);
    try {
      dispatch({
        type: SET_PRODUCT_EVENTS_NEXT_LOCATION,
        payload: mode === 'create' ? `/menu/overview` : `${newLocation.pathname}${newLocation.search}`,
      });
      localStorage.removeItem('cancel');
      const isError = formState && Object.keys(formState?.formState?.errors);
      if (isError?.length && isFormError) {
        localStorage.setItem('isFormError', true);
        if (notHookForm) {
          handleConfirm(formState?.getValues() || submitData, e, true);
        } else {
          handleConfirm();
        }
        setOpen(false);
        return;
      }

      if (!isFormError && isError?.length) {
        localStorage.setItem('isFormError', true);
        if (history.location.pathname?.includes('/settings/business')) {
          if (notHookForm) {
            handleConfirm(formState?.getValues() || submitData, e, true);
          } else {
            handleConfirm();
          }
        }
        setOpen(false);
        return;
      }

      if (isFormError) {
        localStorage.setItem('isFormError', true);
        if (notHookForm) {
          handleConfirm(formState?.getValues() || submitData, e, true);
        }
        handleConfirm();
        setOpen(false);
        return;
      }

      if (history.location.search) {
        if (notHookForm) {
          if (!formState) {
            handleConfirm(submitData, e, true);
            history.block(true);

            if (localStorage.getItem('SET_MODAL_ACTIONS') !== ModalOpenStatusEnum.opened) {
              history.push(`${newLocation.pathname}${newLocation.search}`);
            }
            dispatch({ type: MODIFIED_LOG_OUT, payload: false });
          } else {
            handleConfirm(formState?.getValues() || submitData, e, true).finally(() => {
              history.block(true);

              if (localStorage.getItem('SET_MODAL_ACTIONS') !== ModalOpenStatusEnum.opened) {
                history.push(`${newLocation.pathname}${newLocation.search}`);
              }
              dispatch({ type: MODIFIED_LOG_OUT, payload: false });
            });
          }
        } else {
          handleConfirm().finally(() => {
            history.block(true);
            if (!Object.keys(formState.formState.errors)?.length) {
              if (localStorage.getItem('SET_MODAL_ACTIONS') !== ModalOpenStatusEnum.opened) {
                history.push(`${newLocation.pathname}${newLocation.search}`);
              }
            }
            dispatch({ type: MODIFIED_LOG_OUT, payload: false });
          });
        }
      } else {
        if (notHookForm) {
          handleConfirm(formState?.getValues() || submitData, e, true);
        } else {
          handleConfirm().finally(() => {
            if (!Object.keys(formState.formState.errors)?.length) {
              setTimeout(() => {
                history.block(true);
                if (localStorage.getItem('SET_MODAL_ACTIONS') !== ModalOpenStatusEnum.opened) {
                  history.push(`${newLocation.pathname}${newLocation.search}`);
                }
              }, 500);
            } else {
              localStorage.setItem('isFormError', true);
              setOpen(false);
              if (localStorage.getItem('SET_MODAL_ACTIONS') !== ModalOpenStatusEnum.opened) {
                history.push(`${history.location.pathname}${history.location.search}`);
              }
            }
          });
        }
        dispatch({ type: MODIFIED_LOG_OUT, payload: false });
      }
    } catch (error) {
      history.block();
    }
    setOpen(false);
  };

  const handleDiscard = () => {
    if (setAddressFieldsAreDirty) {
      setAddressFieldsAreDirty(false);
    }
    dispatch({ type: MODIFIED_LOG_OUT, payload: false });
    try {
      history.block(true);
      history.push({
        pathname: newLocation.pathname,
        search: newLocation.search,
      });
    } catch (error) {
      history.block();
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('cancel', true);
    if (history?.location?.pathname.includes('/menu')) {
      formState?.reset(formState?.getValues() || submitData, { keepDirty: true });
      setOldLocation(`${history.location.pathname}${history.location.search}`);
      dispatch({ type: MODIFIED_LOG_OUT, payload: false });
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg">
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '24px',
          fontWeight: 400,
          textAlign: 'center',
          color: '#3D4350',
          mt: 3,
          pl: 3,
          pr: 3,
        }}
      >
        {t(title)}
        <IconButton sx={{ position: 'absolute', top: 16, right: 12 }} aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Typography>
      <DialogTitle sx={{ p: 0, pl: 3, pr: 3 }}>
        <Typography sx={{ fontSize: '15px', fontWeight: 400, color: '#49454F', mt: 1 }}>{t(message)}</Typography>
      </DialogTitle>
      <DialogActions
        sx={{
          display: 'flex',
          mt: 3,
          pt: 2,
          pb: 2,
          pl: 3,
          pr: 3,
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Button
          id="confirmCancel"
          sx={{ borderRadius: '6px', ml: 2, color: '#626A7A', fontSize: 14, fontWeight: 500 }}
          onClick={handleClose}
        >
          {t('confirm_modal.cancel')}
        </Button>
        <Button
          id="confirmDiscard"
          onClick={handleDiscard}
          sx={{
            borderRadius: '6px',
            background: 'none',
            color: '#626A7A',
            fontSize: 14,
            border: '1px solid rgba(98, 106, 122, 0.50)',
            boxShadow: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          variant="contained"
        >
          {t('confirm_modal.discard')}
        </Button>
        <Button
          id="confirmSave"
          onClick={handleSubmit}
          sx={{
            borderRadius: '6px',
            boxShadow: 0,
            fontSize: 14,
            border: '1px solid #1976d2',
          }}
          variant="contained"
        >
          {t('confirm_modal.save_and_leave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPromptModal;
