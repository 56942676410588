import { useEffect } from 'react';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LabelRequired from 'components/FormFields/LabelRequired';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import CustomTextInput from 'components/Forms/CustomTextInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { v4 as uuidv4 } from 'uuid';
import { PRINTER_TYPES } from 'constants/printers';
import { useTranslation } from 'react-i18next';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';

const cells = [
  { name: 'name_', flex: 2 },
  { name: 'type_', flex: 1 },
  { name: 'ip_address', flex: 2 },
  { name: 'port_', flex: 1 },
  { name: 'ticket_font_size_', flex: 1 },
  { name: '', flex: 1 },
  { name: '', flex: 1 },
];

const PrinterItem = ({ handleShowPrinter, mode, handleSubmit, printerModel, initialValues }) => {
  const { t } = useTranslation();
  const schemaPrinterItem = yup.object().shape({
    name: yup.string().required('field_is_required_'),
    ipAddress: yup.string().when(['type', 'innerMacAddress'], {
      is: (type, innerMacAddress) => type != 'internal' && !innerMacAddress,
      then: yup
        .string()
        .matches(
          '^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
          'this_field_is_invalid_'
        )
        .required('field_is_required_'),
    }),
    ticketStyle: yup.string().required('field_is_required_'),
    port: yup
      .string()
      .nullable()
      .when(['type', 'innerMacAddress'], {
        is: (type, innerMacAddress) => type != 'internal' && !innerMacAddress,
        then: yup.string().required('field_is_required_'),
      }),

    type: yup.string().required('field_is_required_'),
    _id: yup.string(),
  });
  const printerItemFormModel = useForm({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(schemaPrinterItem),
  });
  const { watch, reset, getValues, setValue, formState, resetField, register } = printerItemFormModel;
  watch((data, { name, type }) => {
    if (name === 'type') {
      resetField('ipAddress', '');
      resetField('port', '');
      setValue('port', '');
      setValue('ipAddress', '');
    }
  });

  useEffect(() => {
    if (printerModel.type === 'internal') {
      reset({ ...printerModel, ipAddress: '', port: '' });
    } else {
      reset({ ...printerModel });
    }
  }, [printerModel, mode]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(printerModel, { keepValues: false });
    }
  }, [formState, reset, handleShowPrinter]);

  const handleClose = (e) => {
    return handleShowPrinter(false);
  };

  const handleValidSubmit = (values) => {
    const dataObj = {
      ...printerModel,
      ...values,
    };
    handleSubmit(dataObj);
  };

  return (
    <Box>
      <Typography
        sx={{
          my: 2,
        }}
        variant="h5"
      >{`${mode} ${t('settings.printer_manually')}`}</Typography>
      <Paper
        sx={{
          position: 'relative',
          '& .MuiTableHead-root': {
            backgroundColor: (theme) => theme.palette.action.hover,
            '& .MuiTableCell-root': {},
          },
        }}
      >
        <Box onClick={handleClose}>
          <CloseOutlinedIcon sx={{ position: 'absolute', top: 5, right: 5, fontSize: 25, cursor: 'pointer' }} />
        </Box>
        <FormProvider {...printerItemFormModel}>
          <form onSubmit={printerItemFormModel.handleSubmit(handleValidSubmit)}>
            <ConfirmPromptModal
              handleConfirm={printerItemFormModel.handleSubmit(handleValidSubmit)}
              submitData={printerItemFormModel.getValues()}
              hasUnsavedChanges={printerItemFormModel.formState.isDirty}
              formState={printerItemFormModel}
            />
            <Table className="edit-table">
              <TableHead className="edit-thead">
                <TableRow
                  sx={{
                    display: 'flex',
                  }}
                >
                  {cells.map((item) => (
                    <TableCell
                      key={uuidv4()}
                      sx={{
                        flex: item.flex,
                      }}
                    >
                      {t(item.name)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    display: 'flex',
                    height: '84px',
                  }}
                >
                  <TableCell
                    sx={{
                      flex: 2,
                    }}
                  >
                    <TextField hidden name="_id" value={printerItemFormModel.getValues('_id')} />
                    <CustomTextInput
                      sx={{ width: '100%' }}
                      // inputProps={{
                      //   sx: { width: '100%' },
                      // }}
                      name="name"
                      label={<LabelRequired>{t('Name')}</LabelRequired>}
                    />
                    <TextField hidden name="status" value={printerItemFormModel.getValues('status')} />
                  </TableCell>
                  <TableCell
                    sx={{
                      flex: 1,
                    }}
                  >
                    <CustomTextInput
                      sx={{ width: '100%' }}
                      select={true}
                      name="type"
                      label={<LabelRequired>{t('Type')}</LabelRequired>}
                    >
                      {Object.keys(PRINTER_TYPES).map((item) => {
                        return <MenuItem value={item.toLowerCase()}>{item}</MenuItem>;
                      })}
                    </CustomTextInput>
                  </TableCell>
                  <TableCell
                    sx={{
                      flex: 2,
                    }}
                  >
                    <CustomTextInput
                      sx={{ width: '100%' }}
                      disabled={getValues('type') === 'internal'}
                      name="ipAddress"
                      label={
                        getValues('type') == !'internal' || getValues('innerMacAddress') ? (
                          t('settings.ip_address')
                        ) : (
                          <LabelRequired>{t('settings.ip_address')}</LabelRequired>
                        )
                      }
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      flex: 1,
                    }}
                  >
                    <CustomTextInput
                      sx={{ width: '100%' }}
                      inputProps={{
                        // sx: { width: '100%' },
                        type: !getValues('innerMacAddress') ? 'number' : 'string',
                        step: 'any',
                        min: '0',
                      }}
                      disabled={getValues('type') === 'internal'}
                      name="port"
                      label={
                        getValues('type') === 'internal' || getValues('innerMacAddress') ? (
                          t('settings.port')
                        ) : (
                          <LabelRequired>{t('settings.port')}</LabelRequired>
                        )
                      }
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      flex: 1,
                    }}
                  >
                    <CustomTextInput
                      sx={{ width: '100%' }}
                      select={true}
                      name="ticketStyle"
                      label={t('settings.ticket_font_size')}
                    >
                      <MenuItem value="regular">{t('settings.regular')}</MenuItem>
                      <MenuItem value="large">{t('settings.large')}</MenuItem>
                    </CustomTextInput>
                  </TableCell>
                  <TableCell
                    sx={{
                      flex: 1,
                    }}
                  >
                    <FormGroup name="default">
                      <FormControlLabel
                        {...register('default')}
                        control={<Checkbox name="default" defaultChecked={printerModel.default} />}
                        label={t('settings.default')}
                      />
                    </FormGroup>
                  </TableCell>

                  <TableCell
                    sx={{
                      flex: 1,
                    }}
                  >
                    <div className="d-flex justify-content-end align-items-center">
                      <Button
                        id="printerItemSave"
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="mx-2 px-4"
                      >
                        {t('settings.save')}
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </form>
        </FormProvider>
      </Paper>
    </Box>
  );
};

export default PrinterItem;
