import styled from '@emotion/styled';
import { Box, List, ListItem, TextField } from '@mui/material';

export const MainContainer = styled(Box)({
  flexDirection: 'column',
});

export const ChildContainer = styled(Box)({
  width: '100%',
  padding: 16,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  minHeight: '300px',
});

export const ChildContainerItem = styled(Box)({
  width: '350px',
  position: 'relative',
});

export const SearchField = styled(TextField)({
  // maxWidth: '300px',
  width: '100%',
});

export const InfiniteScrollMain = styled(Box)({
  maxHeight: '210px',
  overflowY: 'hidden',
  position: 'relative',
  paddingBottom: '4px',
  paddingTop: '4px',
  boxSizing: 'border-box',
});

export const InfiniteScrollList = styled(List)({
  // width: '300px',
  maxHeight: '205px',
  // overflow: 'auto',
  // border: '1px solid #eee',
  // borderRadius: '4px',
});

export const InfiniteScrollListItem = styled(ListItem)({
  cursor: 'default',
  display: 'block',
  fontSize: 'inherit',
  width: '100%',
  userSelect: 'none',
  padding: '8px 12px',
  boxSizing: 'border-box',
});
