import { styled, Box, Typography, Paper, Link } from '@mui/material';

export const PaperWrap = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: 'calc(100vh - 180px)',
  alignItems: 'center',
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  maxWidth: 512,
}));

export const TitleUpdatedPlan = styled(Typography)(({ theme }) => ({
  color: '#3C4350',
  fontWeight: 500,
  fontSize: 32,
  lineHeight: '32px',
  textAlign: 'center',
  marginTop: 32,
  marginBottom: 16,
}));

export const DescUpdatedPlan = styled(Typography)(({ theme }) => ({
  lineHeight: '22px',
  textAlign: 'center',
}));

export const PhoneContainer = styled(Box)(({ theme }) => ({
  marginTop: 24,
  display: 'flex',
  justifyContent: 'center',
}));

export const PhoneUpdatedPlan = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  color: '#2D7FF9',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  marginLeft: 3,
  textDecoration: 'none',
  '&:hover': {
    color: '#2D7FF9',
    textDecoration: 'underline',
  },
}));
