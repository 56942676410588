import {
  ADD_TABLE_TO,
  GET_TABLE_TO,
  UPDATE_TABLE_TO,
  DELETE_TABLE_TO,
  ADD_SECTION_TO,
  GET_SECTION_TO,
  UPDATE_SECTION_TO,
  DELETE_SECTION_TO,
  GET_SETTINGS_TO,
  CREATE_BUSINESS_TO,
  SET_LOADING_TO,
  SET_IS_BEEPING,
  LAST_CREATED_TABLE_TO,
  LAST_CREATED_SECTION_TO,
  SET_LOADING_SETTINGS_TO,
  LAST_CREATED_EMPLOYEE_TO,
  ADD_EMPLOYEE_TO,
  GET_EMPLOYEE_TO,
  UPDATE_EMPLOYEE_TO,
  DELETE_EMPLOYEE_TO,
  GET_JOBS_TO,
  LAST_CREATED_JOB_TO,
  ADD_JOBS_TO,
  DELETE_JOB_TO,
  UPDATE_JOB_TO,
} from 'redux/actions/types';

const initialState = {
  business: {},
  tables: [],
  sections: [],
  settings: {},
  employees: [],
  jobs: [],
  loading: false,
  isBeeping: false,
  isSectionEmpty: false,
  lastCreatedTable: null,
  lastCreatedSection: null,
  lastCreatedEmployee: null,
  lastCreatedJob: null,
  loadingSettings: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_JOBS_TO:
      return { ...state, jobs: payload };
    case ADD_JOBS_TO:
      return { ...state, jobs: payload };
    case DELETE_JOB_TO:
      return { ...state, jobs: payload };
    case UPDATE_JOB_TO:
      return { ...state, jobs: payload };
    case LAST_CREATED_JOB_TO:
      return { ...state, lastCreatedJob: payload };
    case LAST_CREATED_EMPLOYEE_TO:
      return { ...state, lastCreatedEmployee: payload };
    case ADD_EMPLOYEE_TO:
      return { ...state, employees: payload };
    case GET_EMPLOYEE_TO:
      return { ...state, employees: payload };
    case DELETE_EMPLOYEE_TO:
      return { ...state, employees: payload };
    case UPDATE_EMPLOYEE_TO:
      return { ...state, employees: payload };
    case LAST_CREATED_TABLE_TO:
      return { ...state, lastCreatedTable: payload };
    case LAST_CREATED_SECTION_TO:
      return { ...state, lastCreatedSection: payload };
    case ADD_TABLE_TO:
      return { ...state, tables: payload };
    case GET_TABLE_TO:
      return { ...state, tables: payload };
    case DELETE_TABLE_TO:
      return { ...state, tables: payload };
    case UPDATE_TABLE_TO:
      return { ...state, tables: payload };
    case ADD_SECTION_TO:
      return {
        ...state,
        sections: payload,
      };
    case GET_SECTION_TO:
      return { ...state, sections: payload, isSectionEmpty: Boolean(payload?.length === 0) };
    case DELETE_SECTION_TO:
      return { ...state, sections: payload };
    case UPDATE_SECTION_TO:
      return { ...state, sections: payload };
    case CREATE_BUSINESS_TO:
      return { ...state, business: payload };
    case GET_SETTINGS_TO:
      return { ...state, settings: payload };
    case SET_LOADING_TO:
      return { ...state, loading: payload };
    case SET_LOADING_SETTINGS_TO:
      return { ...state, loadingSettings: payload };
    case SET_IS_BEEPING:
      return { ...state, isBeeping: payload };
    default:
      return state;
  }
};
