import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Box, FormControlLabel, FormGroup, Grid, MenuItem, Switch, Typography } from '@mui/material';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelTooltip from 'components/FormFields/LabelTooltip';

const OrderBufferTime = ({ formMethods }) => {
  const { t } = useTranslation();
  const labelProps = { style: { pointerEvents: 'auto' } };
  const { setValue, getValues } = formMethods;
  const orderBufferTime = getValues()?.settings?.orderBufferTime;

  const ORDER_BUFFER_TIME = [
    {
      id: uuidv4(),
      name: t('No buffer'),
      value: 0,
    },
    {
      id: uuidv4(),
      name: t('10 minutes'),
      value: 10,
    },
    {
      id: uuidv4(),
      name: t('15 minutes'),
      value: 15,
    },
    {
      id: uuidv4(),
      name: t('20 minutes'),
      value: 20,
    },
    {
      id: uuidv4(),
      name: t('30 minutes'),
      value: 30,
    },
    {
      id: uuidv4(),
      name: t('45 minutes'),
      value: 45,
    },
  ];

  const ORDER_BUFFER_DATA = {
    deliveryOrderBufferTime: {
      ...orderBufferTime?.deliveryOrderBufferTime,
      options: ORDER_BUFFER_TIME,
    },
    pickUpOrderBufferTime: {
      ...orderBufferTime?.pickUpOrderBufferTime,
      options: ORDER_BUFFER_TIME,
    },
  };

  const renderFields = (key, name, label) => {
    return (
      <CustomTextInput
        sx={{ width: '100%' }}
        inputProps={{
          type: 'number',
        }}
        select={true}
        name={`settings.orderBufferTime.${key}.${name}`}
        labelProps={{
          labelProps,
          shrink: true,
        }}
        label={<LabelTooltip required id={`${key}_buffer_time`} value={label} />}
      >
        {ORDER_BUFFER_DATA[key]?.options?.map((option) => (
          <MenuItem key={option.key} value={option.value}>
            {option?.name}
          </MenuItem>
        ))}
      </CustomTextInput>
    );
  };

  const handleChange = (event, key) => {
    const { checked } = event.target;
    setValue(`settings.orderBufferTime.${key}.enabled`, checked, { shouldDirty: true });
  };

  return Object.keys(ORDER_BUFFER_DATA)?.map((key, index) => {
    return (
      <Box key={key} maxWidth={639}>
        <FormGroup>
          <FormControlLabel
            sx={{ width: 'max-content' }}
            control={
              <Switch onChange={(event) => handleChange(event, key)} checked={ORDER_BUFFER_DATA[key]?.enabled} />
            }
            label={
              key.includes('delivery') ? t('Enable Delivery Order Buffer Time') : t('Enable Pick-Up Order Buffer Time')
            }
          />
          <Typography mt={1} mb={3.5} color="#929398">
            {key.includes('delivery')
              ? t(
                  'Set the specific times when your restaurant accepts delivery orders. This helps streamline delivery operations and ensures customers know when they can expect their orders to be accepted and delivered.'
                )
              : t(
                  'Define the windows during which pick-up orders can be placed. This ensures that customers can schedule pickups at convenient times and helps your staff manage order flow efficiently.'
                )}
          </Typography>
        </FormGroup>
        {ORDER_BUFFER_DATA[key]?.enabled ? (
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              gap: 2,
              mb: 2,
              maxWidth: '100%',
            }}
          >
            {renderFields(key, 'startTimeMin', t('First Order buffer time'))}
            {renderFields(key, 'endTimeMin', t('Last Order buffer time'))}
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    );
  });
};

export default OrderBufferTime;
