import useQuery from 'hooks/useQuery';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { applyCouponCode } from 'redux/actions/userAuthV2';
import { PaymentPeriodicityContext } from 'context/paymentPeriodicityContext';

export const useApplyCoupon = (
  setCouponValue,
  setIsApplay,
  showSubscription,
  checkNewPlans,
  selectedPlan,
  couponValue
) => {
  const { periodicity } = useContext(PaymentPeriodicityContext);
  const location = useLocation();
  const query = useQuery();
  const businessId = query.get('businessId');
  const legalEntityId = query.get('legalEntityId');
  const userId = query.get('userId');
  const [error, setError] = useState('');
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [setupFree, setSetupFree] = useState(Boolean(couponValue) || false);
  const [discountedSetupFeeAmount, setDiscountedSetupFeeAmount] = useState(0);
  const handleChange = (event) => {
    setCouponValue(event.target.value);
  };

  useEffect(() => {
    let timer;
    let time = 5000;
    if (successMessage || error) {
      timer = setTimeout(() => {
        setSuccessMessage('');
        setError('');
      }, time);
    }
    return () => clearTimeout(timer);
  }, [successMessage, error]);

  const applyCoupon = () => {
    setLoadingCoupon(true);
    setDiscountedSetupFeeAmount(0);
    return applyCouponCode({ oneTimeSetupFeeCouponCode: couponValue })
      .then((res) => {
        setLoadingCoupon(false);
        setIsApplay(true);
        setCouponValue(res?.data?.data?.couponCode);
        setError('');
        setSuccessMessage('Employee ID successfully applied.');
        if (location.pathname === '/signup/billingInfo') {
          let url = `${location.pathname}?businessId=${businessId}&legalEntityId=${legalEntityId}&userId=${userId}&planId=${selectedPlan}&term=${periodicity}`;
          if (res?.data?.data?.couponCode) {
            url += `&couponValue=${res?.data?.data?.couponCode}&isApply=true`;
          }
          window.history.pushState({ path: url }, '', url);
        }
        if (location.pathname === '/invitationLocation' && showSubscription && checkNewPlans) {
          localStorage.setItem('currentCoupon', res?.data?.data?.couponCode);
        }
        if (res?.data?.data?.actions[0]?.value) {
          setSetupFree(res?.data?.data?.actions[0]?.value);
          setDiscountedSetupFeeAmount(0);
        } else if (!res?.data?.data?.actions[0]?.value && !res?.data?.data.hasOwnProperty('discountedSetupFeeAmount')) {
          setSetupFree(res?.data?.data?.actions[0]?.value);
          setDiscountedSetupFeeAmount(0);
        } else if (!res?.data?.data?.actions[0]?.value && res?.data?.data.hasOwnProperty('discountedSetupFeeAmount')) {
          setSetupFree(true);
          setDiscountedSetupFeeAmount(res?.data?.data?.discountedSetupFeeAmount || 0);
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
        setSuccessMessage('');
        setLoadingCoupon(false);
        setIsApplay(false);
        setCouponValue('');
        setSetupFree(false);
        localStorage.removeItem('currentCoupon');
        if (location.pathname === '/signup/billingInfo') {
          let url = `${location.pathname}?businessId=${businessId}&legalEntityId=${legalEntityId}&userId=${userId}&planId=${selectedPlan}&term=${periodicity}`;
          window.history.pushState({ path: url }, '', url);
        }
      });
  };

  return {
    handleChange,
    applyCoupon,
    loadingCoupon,
    setupFree,
    setSetupFree,
    error,
    successMessage,
    discountedSetupFeeAmount,
  };
};
