import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Grid from '@mui/material/Grid';
import { getMenusV2 } from 'redux/actions/Menu/menuV2';
import Overview from './Overview';
import MenuLinkDrawer from './MenuLinkDrawer';
import CategoriesList from './Categories/CategoriesList';
import Products from './Products/Products';
import Modifiers from './Modifiers/Modifiers';
import { getModifiersAll } from 'redux/actions/Menu/modifiers';
import useQuery from 'hooks/useQuery';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { FormTranslateProvider } from 'context/formTranslation';
import PopularItems from './PopularItems/PopularItems';
import ConfirmProductActions from 'components/ConfirmProductActions';
import { createCachingEvent } from 'components/ConfirmProductActions/service';
import {
  EventStatusesEnum,
  EventTypeEnum,
  ModalOpenStatusEnum,
  SET_MODAL_ACTIONS,
  SET_PRODUCT_EVENTS,
} from 'components/ConfirmProductActions/constants';
import { SET_MENUS_AVAILABLE_V2 } from 'redux/actions/types';
import { menuAvailableStyles } from 'utils/menu';
import usePartialFreeze from 'hooks/usePartialFreeze';

const MenuNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const { menu, loading } = useSelector(({ menu }) => menu);
  const { productEvents, user, menu: mainMenu } = useSelector((store) => store);
  const { modifiersAll } = menu;
  const query = useQuery();
  const catId = query.get('cid');
  const mode = query.get('mode');
  const userId = user?.data?._id || '';
  localStorage.removeItem('redirectUrl');
  usePartialFreeze();

  useEffect(() => {
    dispatch(getMenusV2()).then((res) => {
      const isAvailable = res?.availabilityStatus.hasOwnProperty('isAvailable');
      dispatch({
        type: SET_MENUS_AVAILABLE_V2,
        payload: isAvailable ? res?.availabilityStatus?.isAvailable : !isAvailable,
      });
      if (!res?.availabilityStatus?.isAvailable && isAvailable) {
        createCachingEvent({
          uuid: uuidv4(),
          status: EventStatusesEnum.created,
          type: EventTypeEnum.alert,
          metadata: {
            menuIds: [],
            source: 'menu',
          },
          tag: 'menuDownload',
          editorId: userId,
          acknowledgement: 'manual',
          serviceSource: 'mms',
        }).then((res) => {
          const { data } = res;
          dispatch({
            type: SET_PRODUCT_EVENTS,
            payload: [data?.data],
          });
          dispatch({
            type: SET_MODAL_ACTIONS,
            payload: ModalOpenStatusEnum.opened,
          });
        });
      }
    });
    if (!modifiersAll) {
      dispatch(getModifiersAll());
    }
    if (mode != 'edit') {
      history.push(pathname);
    }
  }, []);

  return (
    <ErrorBoundaryWrapper>
      {productEvents.eventData[0]?.tag === 'menuDownload' && (
        <ConfirmProductActions formData={null} handleSubmit={() => {}} />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sx={menuAvailableStyles(mainMenu?.menuAvailable)}>
          <FormTranslateProvider>
            <Switch>
              <Route exact path="/menu/overview" component={Overview} />
              <Route exact path="/menu/categories" component={CategoriesList} />
              <Route exact path="/menu/items" component={Products} />
              <Route exact path="/menu/popularItems" component={PopularItems} />
              <Route path="/menu/modifierGroups" component={Modifiers} />
              <Redirect to="/menu/overview" />
            </Switch>
          </FormTranslateProvider>
        </Grid>
        <MenuLinkDrawer />
      </Grid>
    </ErrorBoundaryWrapper>
  );
};

export default MenuNew;
