import { Button } from 'reactstrap';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
const ConfirmModal = ({
  size,
  title,
  onClose,
  message,
  className,
  cancelText,
  confirmText,
  cancelColor,
  confirmColor,
  buttonsComponent,
  hideCloseIcon = false,
  URL,
}) => {
  const redirectUrl = (path) => window.location.replace(path);

  let buttonsContent = (
    <>
      {cancelText && (
        <Button id="confirmModalCancel" color={cancelColor} onClick={() => (URL ? redirectUrl(URL) : onClose(false))}>
          {cancelText}
        </Button>
      )}{' '}
      {}
      <Button id="confirmModal" color={confirmColor} onClick={() => onClose(true)}>
        {confirmText}
      </Button>
    </>
  );

  if (buttonsComponent) {
    const CustomComponent = buttonsComponent;
    buttonsContent = <CustomComponent onClose={onClose} />;
  }
  return (
    <Dialog
      open={true}
      size={size}
      onClose={() => onClose(false)}
      className={`confirm-modal ${className}`}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
          },
        },
      }}
    >
      <DialogContent>
        {!hideCloseIcon && (
          <button id="closeIcon" className="close" onClick={() => onClose(false)}>
            <i className="icon icon-x-circle" />
          </button>
        )}
        {!!title && <h3>{title}</h3>}
        {!!message && <div className="body-color mt-3">{message}</div>}
      </DialogContent>
      <DialogActions>{buttonsContent}</DialogActions>
    </Dialog>
  );
};

ConfirmModal.defaultProps = {
  message: 'Are you sure?',
  title: 'Are you sure?',
  confirmText: 'Ok',
  cancelText: '',
  confirmColor: 'primary',
  cancelColor: '',
  className: '',
  buttonsComponent: null,
  size: null,
};

export default ConfirmModal;
