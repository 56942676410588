import instance from 'services/api';
import { toast } from 'react-toastify';
import { ADD_JOBS_TO, DELETE_JOB_TO, GET_JOBS_TO, LAST_CREATED_JOB_TO, SET_LOADING_TO, UPDATE_JOB_TO } from '../types';

export const getJobsOrderingTO = (uuid) => (dispatch, getState) => {
  const lastCreatedJobsId = getState().tableOrdering.lastCreatedJob;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .get(`/cloud-pos/job`, {
      headers: {
        businessUuid: uuid,
      },
    })
    .then((response) => {
      const data = response?.data?.data;
      if (lastCreatedJobsId) {
        const index = data.findIndex((job) => job?._id === lastCreatedJobsId);
        if (index !== -1) {
          const [lastCreatedJob] = data.splice(index, 1);
          data.unshift(lastCreatedJob);
        }
      }
      dispatch({ type: GET_JOBS_TO, payload: data });
      dispatch({ type: SET_LOADING_TO, payload: false });
      return data;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const createJobTO = (body) => (dispatch, getState) => {
  const jobs = getState().tableOrdering.jobs;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .post(`/cloud-pos/job`, body)
    .then((response) => {
      const data = response?.data?.data;
      const lastCreatedJobId = response?.data?.data?._id || null;
      const filterJobs = jobs.filter((job) => job?._id !== data?._id);
      filterJobs.push(data);
      dispatch({ type: ADD_JOBS_TO, payload: filterJobs || [] });
      dispatch({ type: LAST_CREATED_JOB_TO, payload: lastCreatedJobId });
      dispatch({ type: SET_LOADING_TO, payload: false });
      dispatch(getJobsOrderingTO(body?.businessUuid));
      return data;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const deleteJobTO = (jobId, uuid) => (dispatch, getState) => {
  const jobs = getState().tableOrdering.jobs;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .delete(`/cloud-pos/job/${jobId}`)
    .then(() => {
      const filterJobs = jobs.filter((job) => job?._id !== jobId);
      dispatch({ type: DELETE_JOB_TO, payload: filterJobs });
      dispatch({ type: SET_LOADING_TO, payload: false });
      dispatch(getJobsOrderingTO(uuid));
      return filterJobs;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const updateJobTO = (dataReq) => (dispatch, getState) => {
  const jobs = getState().tableOrdering.jobs;
  const JobId = dataReq?.id;
  delete dataReq?.id;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .patch(`/cloud-pos/job/${JobId}`, dataReq)
    .then((response) => {
      const data = response?.data?.data;
      const findIndex = jobs.findIndex((job) => {
        return job._id === JobId;
      });
      if (findIndex !== -1) {
        jobs[findIndex] = data;
      }
      dispatch({ type: UPDATE_JOB_TO, payload: jobs || [] });
      toast.success('Job has been update  successfully!');
      dispatch({ type: SET_LOADING_TO, payload: false });
      dispatch(getJobsOrderingTO(dataReq.businessUuid));
      return data;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};
