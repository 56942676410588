import { useCallback } from 'react';
import { useSelector } from 'react-redux';

function usePermission() {
  const { business } = useSelector(({ business }) => {
    return {
      business,
    };
  });
  const checkPermission = useCallback(
    (key) => {
      if (!(business && business.currentBusiness?.roles)) return false;
      return (business.currentBusiness.roles.value & key) === key;
    },
    [business]
  );

  return checkPermission;
}
export default usePermission;
