import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getQr } from 'redux/actions/user';
import { getQrForSuperAdmin } from 'redux/actions/user';
import { isPermissionWithCompanyType } from './useCompanyType';

function useQr(forAdmin = false) {
  const [qr, setQr] = useState('');
  const [qrUrl, setQrUrl] = useState('');
  const { currentBusiness } = useSelector(({ business }) => business);
  const { isTypeSingle } = isPermissionWithCompanyType();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isTypeSingle) {
      setLoading(true);
      if (forAdmin) {
        getQrForSuperAdmin(currentBusiness._id)
          .then((data) => {
            setQr(data.url);
            setQrUrl(data.qrUrl || '');
          })
          .finally(() => setLoading(false));
      } else {
        getQr()
          .then((data) => {
            setQr(data.url);
            setQrUrl(data.qrUrl || '');
          })
          .finally(() => setLoading(false));
      }
    }
  }, []);
  return { qr, loading, qrUrl };
}

export default useQr;
