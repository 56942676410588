import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Jobs from 'pages/TableOrdering/Jobs/Jobs';
import Employees from 'pages/TableOrdering/Employees/Employees';

export const routes = [
  { id: 'SETTINGS_USERS_EMPLOYEES', path: '/employees', name: 'Employees', component: Employees },
  { id: 'SETTINGS_USERS_JOBS', path: '/job', name: 'Jobs', component: Jobs },
];

const useTabState = (defaultPath) => {
  const history = useHistory();
  const currentSubTab = localStorage.getItem('currentSubTab');
  const [value, setValue] = useState(currentSubTab || `${history.location.pathname}${defaultPath}`);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('currentSubTab', newValue);
  };

  useEffect(() => {
    localStorage.setItem('currentSubTab', value);
  }, [value]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('currentSubTab');
    };
  }, []);

  return { value, handleChange };
};
export default useTabState;
