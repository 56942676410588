import { Box, Grid, Typography } from '@mui/material';
import CustomCheckbox from 'components/Forms/CustomCheckbox';
import { useTranslation } from 'react-i18next';

const CheckBoxDefault = () => {
  const { t } = useTranslation();
  return (
    <Grid item>
      <CustomCheckbox
        sx={{
          mb: 0,
        }}
        name="preselectOptions.defaultSelection"
        label={t('default_selections_tab.Enable Pre-Selection')}
      />
      <Box>
        <Typography>
          {t(
            'default_selections_tab.Select this option to set default modifiers for this item. Customers will see these options pre-selected when viewing the item.'
          )}
        </Typography>
      </Box>
    </Grid>
  );
};

export default CheckBoxDefault;
