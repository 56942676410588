/**
 * Maximum order pickup time limited by provider, minutes
 */
export const PROVIDERS_MAX_PICKUP_TIME = {
  grubhub: 120,
  ubereats: 120,
  doordash: 120,
};

/**
 * Min pickup time for any business, minutes
 */
export const MIN_PICKUP_TIME = 5;
