import { useState, useContext, useEffect } from 'react';
import { Box, FormControlLabel, Radio, ListItemIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  PauseResumeAllBusiness,
  PauseResumeAllBusinessList,
  PauseResumeAllBusinessListItemText,
  PauseResumeBigTitle,
  PauseResumeDots,
  PauseResumeSingleBusiness,
  PauseResumeSubTitle,
} from './styles';
import { PauseResumeStatusTitle, PauseResumeStatusDescription } from 'constants/resumePause';
import { useSelector } from 'react-redux';
import { CHOOSE_STORE, PauseResumeContext } from 'context/pauseResumeContext';
import VirtualizedList from 'components/PauseResume/VitualList';

const ApplyAllBusiness = ({ status, duration, setForAllBusinesses }) => {
  const { t } = useTranslation();
  const { setCheckedBusiness, setSelectAllBusiness } = useContext(PauseResumeContext);
  const { allLocations } = useSelector(({ business }) => business);
  let currentSelectedBusiness = JSON.parse(localStorage.getItem('businessInfo')) || '';
  let splitBusinessName = currentSelectedBusiness?.label.split('-')[0] || '';
  const [selectedValue, setSelectedValue] = useState(CHOOSE_STORE.ONLY_ONE_STORE);
  const [businessIds, setBusinessIds] = useState([]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setForAllBusinesses(Boolean(event.target.value === CHOOSE_STORE.ALL_STORE));
    if (event.target.value === CHOOSE_STORE.ONLY_ONE_STORE) {
      setCheckedBusiness([]);
    }
  };

  useEffect(() => {
    return () => {
      setCheckedBusiness([]);
      setSelectAllBusiness(false);
      allLocations.slice(1);
    };
  }, []);

  useEffect(() => {
    // let hasKey = allLocations.some((item) => item?.businessId === businessSelectAllObject.businessId);
    // if (!hasKey) {
    //   allLocations.unshift(businessSelectAllObject);
    // }
    const extractBusinessIds = () => {
      const ids = allLocations.map((item) => item.businessId);
      setBusinessIds(ids);
    };
    extractBusinessIds();
  }, [allLocations]);

  function secondsToMinutes(seconds) {
    let minute = Math.floor(seconds / 60);
    if (minute >= 60 && !isNaN(minute)) {
      return `${Math.floor(minute / 60)} ${t('general.hour')}(${t('general.s')})`;
    }
    return `${minute} ${t('general.mins')}`;
  }

  return (
    <Box>
      <PauseResumeBigTitle>
        {t('pause_resume.changing_operation')}
        {`: ${t(PauseResumeStatusTitle[status])}`}
      </PauseResumeBigTitle>
      {duration === 'permanent' && <PauseResumeSubTitle>{`${t('your_store_available_again')}`}</PauseResumeSubTitle>}
      {duration === 'today' && <PauseResumeSubTitle>{`${t('your_store_receive_new_today')}`}</PauseResumeSubTitle>}
      {duration === 'permanent' || duration === 'today' ? (
        <></>
      ) : (
        <PauseResumeSubTitle>{`${t(PauseResumeStatusDescription[status]?.description, {
          time: secondsToMinutes(duration),
        })}`}</PauseResumeSubTitle>
      )}
      <PauseResumeSubTitle>{`${t(PauseResumeStatusDescription[status]?.subDescription)}`}</PauseResumeSubTitle>
      <Box sx={{ display: 'flex', gap: '80px', mt: '32px' }}>
        <Box>
          <FormControlLabel
            value="onlyOneStore"
            name="onlyOneStore"
            onChange={handleChange}
            control={<Radio checked={selectedValue === CHOOSE_STORE.ONLY_ONE_STORE} />}
            label={t('pause_resume.only_one_store')}
          />
          <PauseResumeSingleBusiness disablePadding>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemIcon sx={{ minWidth: '15px' }}>
                <PauseResumeDots />
              </ListItemIcon>
              <PauseResumeAllBusinessListItemText primary={splitBusinessName} />
            </Box>
          </PauseResumeSingleBusiness>
        </Box>
        <Box>
          <FormControlLabel
            value="allStore"
            name="allStore"
            onChange={handleChange}
            control={<Radio checked={selectedValue === CHOOSE_STORE.ALL_STORE} />}
            label={t('pause_resume.only_more_store')}
          />
          <PauseResumeAllBusiness>
            <PauseResumeAllBusinessList disablePadding>
              <VirtualizedList list={allLocations} businessIds={businessIds} disabled={true} />
            </PauseResumeAllBusinessList>
          </PauseResumeAllBusiness>
        </Box>
      </Box>
    </Box>
  );
};
export default ApplyAllBusiness;
