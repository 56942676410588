export const classificationOptions = [
  {
    value: 'dairyFree',
    name: 'Dairy Free',
    _id: 'dairyFree',
  },
  {
    value: 'dessert',
    name: 'Dessert',
    _id: 'dessert',
  },
  {
    value: 'drink',
    name: 'Drink',
    _id: 'drink',
  },
  {
    value: 'glutenFree',
    name: 'Gluten Free',
    _id: 'glutenFree',
  },
  {
    value: 'notForBiker',
    name: 'Not for Biker',
    _id: 'notForBiker',
  },
  {
    value: 'spicy',
    name: 'Spicy',
    _id: 'spicy',
  },
  {
    value: 'vegan',
    name: 'Vegan',
    _id: 'vegan',
  },
  {
    value: 'vegetarian',
    name: 'Vegetarian',
    _id: 'vegetarian',
  },
  {
    value: 'alcohol',
    name: 'Alcohol',
    _id: 'alcohol',
  },
  {
    value: 'lowFat',
    name: 'Low Fat',
    _id: 'lowFat',
  },
  {
    value: 'nutFree',
    name: 'Nut Free',
    _id: 'nutFree',
  },
  {
    value: 'rawFoodWarning',
    name: 'Raw Food Warning',
    _id: 'rawFoodWarning',
  },
  {
    value: 'sodiumWarning',
    name: 'Sodium Warning',
    _id: 'sodiumWarning',
  },
  {
    value: 'specialty',
    name: 'Specialty',
    _id: 'specialty',
  },
];
