import { PRINTER_TYPES } from 'constants/printers';
import instance from '../../services/api';
import { SET_PRINTERS_LIST, SET_PRINTERS_LIST_LOADING } from 'redux/actions/types';

export const getPrintersList = () => (dispatch) => {
  dispatch({
    type: SET_PRINTERS_LIST_LOADING,
    payload: true,
  });
  try {
    instance
      .get('/printer/list')
      .then(({ data: { data } }) => {
        return dispatch({
          type: SET_PRINTERS_LIST,
          payload: {
            data,
          },
        });
      })
      .finally(() => {
        dispatch({
          type: SET_PRINTERS_LIST_LOADING,
          payload: false,
        });
      });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addPrinter = (body) => (dispatch) => {
  body.status = true;
  if (body.type === PRINTER_TYPES.epson.toLowerCase() || body.type === PRINTER_TYPES.other.toLowerCase()) {
    body.templateType = 'react-native-thermal-receipt-printer';
  }
  try {
    return instance.post('/printer', body).then(({ data: { data } }) => {
      return dispatch(getPrintersList());
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updatePrinter = (body) => (dispatch) => {
  try {
    return instance.put(`/printer/${body._id}`, body).then(() => {
      return dispatch(getPrintersList());
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deletePrinter = (id) => (dispatch) => {
  try {
    return instance.delete(`/printer/${id}`).then(() => {
      return dispatch(getPrintersList());
    });
  } catch (err) {
    return Promise.reject(err);
  }
};
