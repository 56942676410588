import { useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import { GOOGLE_API_KEY } from 'constants/config';
import { usePlacesWidget } from 'react-google-autocomplete';
import { geocodeByAddress } from 'react-google-places-autocomplete';
import { collectGoogleQueryData } from 'utils/soldOut';
import { useTranslation } from 'react-i18next';

export default function GooglAutocomplete({
  name,
  required,
  isSignUp = false,
  readOnly = false,
  initialValue = '',
  getAddressFields = () => {},
  formSubmitted = false,
  onChangeAddressInput = () => {},
  inputValue = '',
  isValidGoogleValue = () => {},
  label,
  isError,
  setAddressPlaceId = () => {},
  ...props
}) {
  const { t } = useTranslation();

  const [value, setValue] = useState(inputValue || initialValue);
  const [error, handleError] = useState('');
  const [notFoundAddress, setNotFoundAddress] = useState('');

  const handleChangeInput = (e) => {
    const { value } = e.currentTarget;
    const autocomplete = new window.google.maps.places.AutocompleteService();
    autocomplete.getPredictions({ input: value }, (predictions) => {
      if (!predictions?.length && value.length) {
        setNotFoundAddress(t('no_address_found_'));
      } else {
        setNotFoundAddress('');
      }
    });

    onChangeAddressInput(value);
    if (required) {
      if (!value) {
        isValidGoogleValue(false);
        handleError(t('field_is_required_'));
      } else {
        isValidGoogleValue(true);
        handleError('');
      }
    }
    setValue(value);
  };

  useEffect(() => {
    onChangeAddressInput(value);
    if (required) {
      if (formSubmitted) {
        if (!value) {
          isValidGoogleValue(false);
          handleError(t('field_is_required_'));
        } else {
          isValidGoogleValue(true);
          handleError('');
        }
      }
    }
  }, [readOnly, formSubmitted, value]);

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: (places) => {
      const data = collectGoogleQueryData(places);
      getAddressFields(isSignUp ? { addressInfo: data } : data);
      setValue(data.address1);
      setAddressPlaceId(data?.placeId ?? '');
    },
    options: {
      types: ['geocode'],
      componentRestrictions: { country: 'US' },
      language: 'en', // Consolidated language property here
    },
  });

  const handlesBlur = (event) => {
    geocodeByAddress(event.target.value)
      .then(() => {
        handleError('');
        // const data = collectGoogleQueryData(results[0]);
        // getAddressFields(isSignUp ? { addressInfo: data } : data);
        // setAddressPlaceId(data?.placeId ?? '');
        // setValue(data.address1);
      })
      .catch(() => handleError(t('the_correct_address')));
  };

  useEffect(() => {
    handleError('');
    setValue(inputValue || initialValue);
  }, [inputValue, initialValue, readOnly]);

  return (
    <Box sx={{ position: 'relative', opacity: readOnly ? 0.5 : 1 }}>
      <TextField
        onBlur={handlesBlur}
        error={Boolean(error || isError) && !readOnly}
        helperText={!readOnly ? error || isError : ''}
        fullWidth
        inputRef={ref}
        id="billingAddress"
        size="small"
        name={name}
        label={label}
        onChange={handleChangeInput}
        disabled={readOnly}
        inputProps={{
          sx: { width: '100%' },
          value: value,
        }}
        InputLabelProps={{ shrink: true }}
        {...props}
      />
      {notFoundAddress && !error && !isError ? (
        <Box
          sx={{
            width: '100%',
            position: 'absolute',
            left: '0px',
            p: 1,
            color: '#999696',
          }}
          className="pac-container"
        >
          {notFoundAddress}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}
