import { ErrorBoundary } from 'react-error-boundary';
import Button from '@mui/material/Button';
import { Typography, Box } from '@mui/material';
import ErrorImage from 'assets/images/errorImage.svg';
import { useTranslation } from 'react-i18next';

function ErrorFallback({ error, resetErrorBoundary, sx = {} }) {
  const { t } = useTranslation();

  return (
    <Box sx={{ ...sx, textAlign: 'center', mt: '336px' }}>
      <img src={ErrorImage} />
      <Typography sx={{ fontSize: '20px', m: 2, fontWeight: 'bold' }}>{t('settings.ooops!')}</Typography>
      <Button id="tryAgainn" variant="contained" onClick={resetErrorBoundary}>
        {t('settings.try_again')}
      </Button>
    </Box>
  );
}

const ErrorBoundaryWrapper = ({ sx = {}, children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} sx={sx} />
      )}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={(err) => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div>{children}</div>
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
