import React from 'react';
import { useSelector } from 'react-redux';
import { DialogContent, MenuItem, Box, Divider } from '@mui/material';
import {
  ModalChargeBackCurrencyQuantity,
  ModalChargeBackCurrencyWrapper,
  ModalChargeBackHeader,
  ModalChargeBackProductName,
  ReasonTitle,
} from './styles';
import LabelRequired from 'components/FormFields/LabelRequired';
import { DEFAULT_REASON_LIST, OTHER_REASON } from '../constants';
import DialogInfoContent from './DialogInfoContent';
import Currency from 'components/Currency';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { useTranslation } from 'react-i18next';

const DialogReasonContent = ({ handleChange, selectedReason = '', handleStatusSwitch, currentStatus }) => {
  const { t } = useTranslation();
  const { chargeBack } = useSelector((store) => store);
  const { selected } = chargeBack;

  return (
    <DialogContent sx={{ p: 0, minHeight: selectedReason === OTHER_REASON.OTHER ? '400px' : '200px' }}>
      <DialogInfoContent>
        {selected?.chargebackItems?.length ? (
          selected?.chargebackItems.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <ModalChargeBackHeader>
                  <ModalChargeBackProductName>{item.name}</ModalChargeBackProductName>
                  <ModalChargeBackCurrencyWrapper>
                    <ModalChargeBackCurrencyQuantity>{`x${item?.quantity}`}</ModalChargeBackCurrencyQuantity>
                    <Currency sx={{ color: '#E21508' }} value={item?.price} isMargin={true} discount={true} />
                  </ModalChargeBackCurrencyWrapper>
                </ModalChargeBackHeader>
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
      </DialogInfoContent>
      <Box sx={{ pl: 2, pr: 2, pb: 2 }}>{handleStatusSwitch(currentStatus, selected?.chargeTotal, selected, true)}</Box>

      <Divider />
      <Box sx={{ p: 2 }}>
        <ReasonTitle>{t('chargeBack.reason_title')}</ReasonTitle>
        <CustomTextInput
          onChange={handleChange}
          select={true}
          name="reason"
          label={<LabelRequired>{t('chargeBack.reason')}</LabelRequired>}
          sx={{ width: '100%', mb: 3 }}
        >
          {DEFAULT_REASON_LIST(t).map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                {item.value}
              </MenuItem>
            );
          })}
        </CustomTextInput>
        {selectedReason === OTHER_REASON.OTHER && (
          <Box>
            <CustomTextInput
              sx={{ width: '100%', mb: 3 }}
              rows={4}
              name="message"
              multiline={true}
              label={<LabelRequired>{t('chargeBack.message')}</LabelRequired>}
            />
          </Box>
        )}
      </Box>
    </DialogContent>
  );
};

export default DialogReasonContent;
