import instance from 'services/api';
import { toast } from 'react-toastify';
import { GET_SETTINGS_TO, SET_LOADING_SETTINGS_TO, SET_LOADING_TO } from '../types';

export const getBusinessSettingsTO = () => (dispatch) => {
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .get(`/table-ordering/settings`)
    .then((response) => {
      dispatch({ type: GET_SETTINGS_TO, payload: response?.data?.data });
      dispatch({ type: SET_LOADING_TO, payload: false });
      return response?.data?.data;
    })
    .catch((err) => {
      toast.error(err?.message || err?.response?.message || 'Something went wrong please try again later');
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};
export const updateBusinessSettingsTO = (configs, type) => (dispatch) => {
  dispatch({ type: SET_LOADING_SETTINGS_TO, payload: true });
  return instance
    .patch(`/table-ordering/settings/${type}`, { configs: configs })
    .then((response) => {
      dispatch({ type: GET_SETTINGS_TO, payload: response?.data?.data });
      dispatch({ type: SET_LOADING_SETTINGS_TO, payload: false });
      return response?.data?.data;
    })
    .catch((err) => {
      toast.error(err?.message || err?.response?.message || 'Something went wrong please try again later');
      dispatch({ type: SET_LOADING_SETTINGS_TO, payload: false });
      return Promise.reject(err);
    });
};

export const sendQrTO = (data) => {
  return instance
    .post(`business/send-qr/${data?.businessId}`, data.body)
    .then(({ data: { data } }) => {
      if (data) {
        toast.success(data?.message || 'Send email has been successfully');
      }
      return data;
    })
    .catch((err) => {
      toast.error(err?.message || err?.response?.message || 'Something went wrong please try again later');
      return Promise.reject(err);
    });
};
