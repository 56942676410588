import InfiniteScroll from 'react-infinite-scroll-component';
import {
  ChildContainerItem,
  InfiniteScrollList,
  InfiniteScrollListItem,
  InfiniteScrollMain,
  SearchField,
} from './styles';
import { LabelName } from 'pages/Report/styles';
import usePermission from 'hooks/permission';
import * as all from 'constants/permissions';
import { useTranslation } from 'react-i18next';

const BrandListItems = (props) => {
  const {
    handleBrandChange,
    brandChange,
    brandData,
    fetchMoreData,
    selectBrand,
    selectedBrand,
    selectedId,
    hasMore,
    onKeyDown,
  } = props;
  const checkPermission = usePermission();
  const { t } = useTranslation();

  const activeBrand = (item) => {
    if (selectedId === item?._id || item?._id === selectedBrand?.value?._id || item?._id === selectedBrand?._id) {
      return 'active-brand';
    } else return '';
  };

  return (
    <ChildContainerItem>
      <LabelName>{t('reports_settings.all_brands')}</LabelName>
      <SearchField
        onKeyDown={onKeyDown}
        disabled={checkPermission(all['SUPER'])}
        onChange={(e) => handleBrandChange(e)}
        placeholder={t('reports_settings.all_brands_...')}
        value={brandChange}
        variant="outlined"
        size="small"
      />
      <InfiniteScrollMain id="scrollableDiv">
        <InfiniteScroll
          dataLength={brandData?.length}
          next={fetchMoreData}
          hasMore={hasMore}
          scrollableTarget="scrollableDiv"
          height={205}
          style={{
            border: '1px solid #eee',
            borderRadius: ' 4px',
          }}
        >
          <InfiniteScrollList>
            {!brandData?.length && (
              <InfiniteScrollListItem sx={{ textAlign: 'center', color: '#c7c4c4' }}>No options</InfiniteScrollListItem>
            )}
            {brandData?.map((item, index) => {
              return (
                <InfiniteScrollListItem
                  onClick={() => selectBrand(item, item?.value?._id)}
                  className={activeBrand(item)}
                  sx={{
                    color:
                      selectedId === item._id || (!selectedId && item._id === selectedBrand?.value?._id)
                        ? '#fff'
                        : 'inherit',
                    backgroundColor:
                      selectedId === item._id || (!selectedId && item._id === selectedBrand?.value?._id)
                        ? 'rgb(38, 132, 255)'
                        : 'initial',
                    '&:hover': {
                      backgroundColor:
                        selectedId === item._id || (!selectedId && item._id === selectedBrand?.value?._id)
                          ? 'rgb(38, 132, 255)'
                          : 'rgb(222, 235, 255)',
                    },
                  }}
                  key={`${item.brandName}-${index}`}
                >
                  {item.brandName}
                </InfiniteScrollListItem>
              );
            })}
          </InfiniteScrollList>
        </InfiniteScroll>
      </InfiniteScrollMain>
    </ChildContainerItem>
  );
};

export default BrandListItems;
