export const NAME_ENUMS = {
  min: 'min',
  max: 'max',
  minT: 'minTotalNumberOfModifierItems',
  maxT: 'maxTotalNumberOfModifierItems',
  minTg: 'minTotalNumberOfModifierGroups',
  maxTg: 'maxTotalNumberOfModifierGroups',
  minAnySingle: 'minAnySingleModifierItems',
  maxAnySingle: 'maxAnySingleModifierItems',
  free: 'free',
  maxQ: 'maxAllowedQuantity',
  minQ: 'minAllowedQuantity',
};
export const LESS_MSG = 'menu.less_msg';
export const GREATER_MSG = 'menu.greater_msg';
export const OPTIONS_MSG = 'menu.options_msg';
