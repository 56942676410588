import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Grid, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSales } from 'components/NewHome/Sales/hooks/useSales';
import ReportHeader from 'components/NewHome/Headers/ReportHeader';
import { ReportingGrowthArea } from 'components/NewHome/GrowthDisplay/growthDisplay';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = () => {
  return {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    scales: {
      'y-axis-orders': {
        type: 'linear',
        position: 'left',
      },
      'y-axis-sales': {
        type: 'linear',
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };
};

const Sales = () => {
  const { t } = useTranslation();
  const { reportSales, totalOne, totalTwo, totalCountOne, totalCountTwo } = useSales();

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item sm={12} md={6} lg={6} xl={6}>
        <Card sx={{ p: 2 }}>
          <ReportHeader
            title={'Orders'}
            subtitle={'Total Orders for this Period'}
            description={'Total Orders for this Period'}
          />
          <ReportingGrowthArea
            showDollar={false}
            vs={'Previous Period'}
            total1={totalCountOne}
            total2={totalCountTwo}
          />
          <Line data={reportSales[0].chartData} options={options()} />
          <a
            style={{
              fontSize: '14px',
              backgroundColor: 'rgba(242, 242, 242, 1)',
              borderRadius: '6px',
              color: '#000',
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '12px',
              fontWeight: 500,
            }}
            href="/reports"
          >
            {t('reports_settings.VIEW MORE')}
          </a>
        </Card>
      </Grid>
      <Grid item sm={12} md={6} lg={6} xl={6}>
        <Card sx={{ p: 2 }}>
          <ReportHeader
            title={'Sales'}
            subtitle={'Total Sales for this Period'}
            description={'Total Sales for this Period'}
          />
          <ReportingGrowthArea showDollar={true} vs={'Previous Period'} total1={totalOne} total2={totalTwo} />
          <Line data={reportSales[1].chartData} options={options()} />
          <a
            style={{
              fontSize: '14px',
              backgroundColor: 'rgba(242, 242, 242, 1)',
              borderRadius: '6px',
              color: '#000',
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '12px',
              fontWeight: 500,
            }}
            href="/reports"
          >
            {t('reports_settings.VIEW MORE')}
          </a>
        </Card>
      </Grid>
    </Grid>
  );
};
export default Sales;
