import instance, { instanceTableOrdering } from 'services/api';
import { toast } from 'react-toastify';
import {
  ADD_TABLE_TO,
  CREATE_BUSINESS_TO,
  DELETE_TABLE_TO,
  GET_TABLE_TO,
  SET_LOADING_TO,
  UPDATE_TABLE_TO,
  LAST_CREATED_TABLE_TO,
} from '../types';
import { getTableOrderingSectionTO } from './sections';

export const createBusinessTO = (body) => (dispatch) => {
  return instanceTableOrdering
    .post(`/business`, body)
    .then(({ data: { data } }) => {
      dispatch({ type: CREATE_BUSINESS_TO, payload: data });
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getTableOrderingTO = () => (dispatch, getState) => {
  const lastCreatedTableId = getState().tableOrdering.lastCreatedTable;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .get(`/table-ordering/tables`)
    .then((response) => {
      const data = response?.data?.data?.data;
      if (lastCreatedTableId) {
        const index = data.findIndex((table) => table._id === lastCreatedTableId);
        if (index !== -1) {
          const [lastCreatedTable] = data.splice(index, 1);
          data.unshift(lastCreatedTable);
        }
      }
      dispatch({ type: GET_TABLE_TO, payload: data });
      dispatch({ type: SET_LOADING_TO, payload: false });
      return data;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const createTableTO = (body) => (dispatch, getState) => {
  const tables = getState().tableOrdering.tables;
  const sections = getState().tableOrdering.sections;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .post(`/table-ordering/tables`, body)
    .then((response) => {
      const data = response?.data?.data?.data;
      const lastCreatedTableId = response?.data?.data?.data?._id || null;
      const section = sections?.data?.find((item) => item._id === data.section);
      if (data?.section) {
        data.section = {
          _id: section?._id,
          name: section?.name,
        };
      }
      const filterTabled = tables.filter((table) => table._id !== data._id);
      filterTabled.push(data);
      dispatch({ type: ADD_TABLE_TO, payload: filterTabled });
      dispatch({ type: LAST_CREATED_TABLE_TO, payload: lastCreatedTableId });
      toast.success('Create table has been successfully!');
      dispatch({ type: SET_LOADING_TO, payload: false });
      dispatch(getTableOrderingTO(body?.businessId));
      dispatch(getTableOrderingSectionTO(body?.businessId));
      return data;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const deleteTableTO = (businessId, tableId) => (dispatch, getState) => {
  const tables = getState().tableOrdering.tables;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .delete(`/table-ordering/tables/${tableId}`)
    .then(() => {
      const filterTabled = tables.filter((table) => table._id !== tableId);
      dispatch({ type: DELETE_TABLE_TO, payload: filterTabled });
      toast.success('Delete table has been successfully!');
      dispatch({ type: SET_LOADING_TO, payload: false });
      dispatch(getTableOrderingTO(businessId));
      dispatch(getTableOrderingSectionTO(businessId));
      return filterTabled;
    })
    .catch((err) => {
      toast.error('Delete table failed!');
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const updateTableTO = (dataReq) => (dispatch, getState) => {
  const tables = getState().tableOrdering.tables;
  const sections = getState().tableOrdering.sections;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .patch(`/table-ordering/tables/${dataReq.tableId}`, dataReq.body)
    .then((response) => {
      const data = response?.data?.data?.data;
      const section = sections?.find((item) => item._id === data.section);
      if (data?.section) {
        data.section = {
          _id: section?._id,
          name: section?.name,
        };
      }
      const findIndex = tables.findIndex((table) => {
        return table._id === dataReq.tableId;
      });
      if (findIndex !== -1) {
        tables[findIndex] = data;
      }
      dispatch({ type: UPDATE_TABLE_TO, payload: tables });
      toast.success('Update table has been successfully!');
      dispatch({ type: SET_LOADING_TO, payload: false });
      dispatch(getTableOrderingTO(dataReq.businessId));
      dispatch(getTableOrderingSectionTO(dataReq.businessId));
      return data;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};
