import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getChargeBackReport } from 'redux/actions/chargeBack';
import { useReportPeriods } from 'components/NewHome/hooks/useReportPeriods';
import { processChargeBackReport } from 'components/NewHome/ChargeBack/processChargeBackReport';
import { ReportingFilterDefaultValues, ReportType } from 'components/NewHome/constants/constatnts';
import { processSelectFilter } from 'components/NewHome/helpers/helpers';

const initialData = {
  ubereats: {
    won: [],
    lost: [],
    pending: [],
    chargeback: [],
    wonTotal: 0,
    lostTotal: 0,
    pendingTotal: 0,
    total: 0,
  },
  doordash: {
    won: [],
    lost: [],
    pending: [],
    chargeback: [],
    wonTotal: 0,
    lostTotal: 0,
    pendingTotal: 0,
    total: 0,
  },
};
export const DEFAULT_TIMEZONE = 'America/Los_Angeles';
export const useHomeReport = () => {
  const { uuid } = useParams();
  const user = useSelector(({ user }) => user.data);
  const [loading, setLoading] = useState(true);
  const { periods, query, currentBusiness } = useReportPeriods();
  const [reportsChargeBack, setReportsChargeBack] = useState(initialData);

  const handleGetReports = useCallback(() => {
    if (currentBusiness && Object.keys(currentBusiness).length > 0) {
      const selectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));
      const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
      const data = {
        type: ReportType.CHARGEBACK_DISPUTE_REPORT,
        brandIds: [selectedBrand?.value?._id],
        locationIds: [selectedLocation?.value?._id || selectedBrand?.value?.locations[0]?._id],
        days_of_week: ReportingFilterDefaultValues.days_of_week,
        providers: ReportingFilterDefaultValues.providers,
      };
      const homeReportFilters = processSelectFilter(
        periods[query.period]?.label,
        currentBusiness?.timezone.timeZoneId,
        data
      );
      setLoading(true);
      getChargeBackReport(homeReportFilters)
        .then(({ data: { data } }) => {
          setReportsChargeBack(processChargeBackReport(data));
        })
        .finally(setLoading(false));
    }
  }, [periods, query.period, currentBusiness]);
  useEffect(() => {
    const id = user?._id || uuid;
    if (id) {
      handleGetReports(id, uuid);
    }
  }, [handleGetReports, user, uuid]);

  return {
    reportsChargeBack,
    loading,
  };
};
