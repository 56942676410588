import { useTranslation } from 'react-i18next';
import { FormControlLabel, Grid, InputAdornment, Switch, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import LabelRequired from 'components/FormFields/LabelRequired';
import CustomTextInput from 'components/Forms/CustomTextInput';

const LimitProviderFee = ({
  showErrors,
  handleChangeLimitProvider,
  itemIndex,
  websiteFormMethods,
  limitProviderFeeEnable,
}) => {
  const { t } = useTranslation();
  const { getValues, setValue } = websiteFormMethods;
  const inHouseDelivery = getValues('deliveryBy')?.[0]?.deliveryService === 'self';

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setValue(name, checked, { shouldDirty: true });
  };

  return !inHouseDelivery ? (
    <Grid item>
      <FormControlLabel
        sx={{ width: 'max-content', my: 0 }}
        control={
          <Switch
            checked={getValues(`deliveryFlatFeeSettings.deliveryRateSettings[${itemIndex}].providerLimitFeeEnabled`)}
            onChange={handleChange}
          />
        }
        label={t('flat_fee.limit_provider_fee')}
        name={`deliveryFlatFeeSettings.deliveryRateSettings[${itemIndex}].providerLimitFeeEnabled`}
      />
      {limitProviderFeeEnable ? (
        <CustomTextInput
          name={`deliveryFlatFeeSettings.deliveryRateSettings[${itemIndex}].providerLimitedFee`}
          label={<LabelRequired>{t('flat_fee.delivery_fee')}</LabelRequired>}
          inputProps={{ type: 'number' }}
          onChange={handleChangeLimitProvider}
          onBlur={handleChangeLimitProvider}
          InputProps={{
            startAdornment: showErrors('providerLimitedFee', itemIndex) ? (
              <Tooltip title={showErrors('providerLimitedFee', itemIndex)} placement="top" arrow>
                <InfoIcon sx={{ color: '#d32f2f', fontSize: '21px' }} />
              </Tooltip>
            ) : (
              <InputAdornment position="start">$</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={t('flat_fee.flat_rate_fee_creating_this')}>
                  <HelpOutlineIcon fontSize={'small'} />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={{ width: '150px' }}
        />
      ) : (
        <></>
      )}
    </Grid>
  ) : (
    <></>
  );
};

export default LimitProviderFee;
