import { Box, Typography } from '@mui/material';

export const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });
export const addNewOption = async (inputValue, targetData) => {
  await sleep(1000);
  const customData = {
    name: targetData.name,
    nativeId: targetData._id,
    mappedData: [],
    externalId: '',
    _id: new Date(),
  };

  const newOption = {
    label: (
      <Box>
        <Typography sx={{ fontWeight: 'bold' }}>{targetData.name}</Typography>
        <Typography sx={{ fontSize: '14px' }}>{`#${targetData._id}`}</Typography>
      </Box>
    ),
    value: customData,
  };

  // x.unshift(customData);

  return newOption;
};

export const loadOptions = (locationsList) => async (search, prevOptions) => {
  // x = locationsList;
  const options = locationsList.map((item) => ({
    value: item,
    label: (
      <Box>
        <Typography sx={{ fontWeight: 'bold' }}>{item.name}</Typography>
        <Typography sx={{ fontSize: '14px' }}>{`#${item.externalId}`}</Typography>
      </Box>
    ),
    name: `${item.externalId} ${item.name}`,
  }));

  options.unshift({
    value: 'new',
    label: 'Add as new',
    name: 'Add as new',
  });

  await sleep(300);

  let filteredOptions = [];
  if (!search) {
    filteredOptions = options;
  } else {
    const searchLower = search.toLowerCase();

    filteredOptions = options.filter(({ name }) => name.toLowerCase().includes(searchLower));
  }

  const hasMore = filteredOptions.length > prevOptions.length + 10;
  const slicedOptions = filteredOptions.slice(prevOptions.length, prevOptions.length + 10);

  return {
    options: slicedOptions,
    hasMore,
  };
};
