import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PROVIDER_CONNECTED } from 'constants/providerStatuses';
import {
  StyledProviderRenderActionsConnected,
  StyledProviderRenderActionsConnectedPickUp,
  StyledProviderRenderTableOrdering
} from './styles';
import { Box } from '@mui/material';
import { PROVIDER_LIST } from '../NewHome/constants/constatnts';

const AutoConfirmInfo = ({ providers, data }) => {
  const { t } = useTranslation();
  return (
    <>
      {providers[data.srv]?.srv === PROVIDER_LIST.tableOrdering ? (
        <>
          {providers[data.srv]?.options?.showSettingsButton && data?.status === PROVIDER_CONNECTED ? (
            <Box sx={{ mt: 1, pb: 1 }}>
              <StyledProviderRenderTableOrdering>
                {t('Delivery Order Auto-Confirm:')}&nbsp;
                {`${data?.orderConfirm?.delivery?.autoConfirm ? t('On') : t('Off')}`}
              </StyledProviderRenderTableOrdering>
              <StyledProviderRenderTableOrdering>
                {t('Dine In Order Auto-Confirm:')}&nbsp;
                {`${data?.orderConfirm?.dine_in?.autoConfirm ? t('On') : t('Off')}`}
              </StyledProviderRenderTableOrdering>
              <StyledProviderRenderTableOrdering>
                {t('Take Out Order Auto-Confirm:')}&nbsp;
                {`${data?.orderConfirm?.take_out?.autoConfirm ? t('On') : t('Off')}`}
              </StyledProviderRenderTableOrdering>
            </Box>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {providers[data.srv]?.options?.showSettingsButton && data?.status === PROVIDER_CONNECTED ? (
            <>
              <StyledProviderRenderActionsConnectedPickUp>
                {t('Pick-up Order Auto-Confirm:')}&nbsp;
                {`${data?.orderConfirm?.pickup?.autoConfirm ? t('On') : t('Off')}`}
              </StyledProviderRenderActionsConnectedPickUp>
              <StyledProviderRenderActionsConnected>
                {t('Delivery Order Auto-Confirm:')}&nbsp;
                {`${data?.orderConfirm?.delivery?.autoConfirm ? t('On') : t('Off')}`}
              </StyledProviderRenderActionsConnected>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default memo(AutoConfirmInfo);
