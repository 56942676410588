export const SOLD_OUT_ENUM = {
  availability: 'menu.available',
  soldOutToday: 'menu.sold_out_for_today',
  soldOutPermanent: 'menu.sold_out_until',
};

export const SOLD_OUT_STATUS = {
  AVAILABILITY: 'available',
  SOLD_OUT_TODAY: 'soldOutToday',
  SOLD_OUT_PERMANENT: 'soldOutPermanent',
};

export const SUBSCRIPTION_TYPE = {
  BUNDLE: 'bundle',
  ADDON: 'addon',
};

export const AI_DISPATCH_NAME = {
  DISPATCH: 'DISPATCH',
  AI_DISPATCH: 'AI DISPATCH',
};
