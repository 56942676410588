import { v4 as uuidv4 } from 'uuid';
import { formatNumber } from 'helpers/formPrice';

export const DEFAULT_PRICE_DELIVERY_RADIUS = 7.5;
export const DEFAULT_DELIVERY_RATE_SETTINGS = {
  id: uuidv4(),
  deliveryRadiusFrom: 0,
  deliveryRadiusTo: 15,
  deliveryPrice: formatNumber(DEFAULT_PRICE_DELIVERY_RADIUS),
  providerLimitFeeEnabled: false,
  providerLimitedFee: formatNumber(DEFAULT_PRICE_DELIVERY_RADIUS),
};
