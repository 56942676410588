import React, { useRef, useState, useCallback, memo, useEffect, Fragment, forwardRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItem,
  Button,
  Alert,
  TextField,
  FormControlLabel,
  Radio,
  ListItemText,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { changeOrderStatus } from 'redux/actions/orders';
import { rejectOrder } from 'redux/actions/orders';
import { getTypeText } from 'services/helpers';
import ConfirmOrderModal from 'components/Order/OrderConfirmModal/index';
import Preloader from 'components/Preloader';
import OrderDate from 'components/Order/OrderDate';
import Currency from 'components/Currency';
import { ORDER_GROUP, ORDER_STATUS, ORDERS_FILTER_STATUS_HISTORY } from 'constants/config';
import { groupByCategory, sumTaxesAndFees } from 'utils/globalUtils';
import { useTranslation } from 'react-i18next';
import useProviderConfig from 'hooks/useProviderConfig';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OrderStatusChange from 'components/Order/OrderStatusChange/OrderStatusChange';
import ChargeBackInfo from 'components/Chargeback/Details/ChargeBackInfo';
import ChargeBackActions from 'components/Chargeback/Details/ChargeBackActions';
import ChargeBackTotalSections from 'components/Chargeback/Details/ChargeBackTotalSections';
import EmptyOrder from 'components/Chargeback/Details/EmptyOrder';
import GroupOrderDialog from 'components/Dialog/GroupOrderDialog';
import DispatchDeliveryDetails from 'components/Order/OrderDeliverDetails/DispatchDeliveryDetails';
import { DISPATCH_DELIVERY_CARRIER } from 'components/Order/OrderDeliverDetails/hooks/useDeliveryDispatchDetails';
import { AI_DISPATCH_NAME } from 'constants/enumSoldOut';
import OrderTaxAndFees from 'components/Order/OrderTaxAndFees';
import OrderPaymentDetails from './OrderPaymentDetails';
import { PAUSE_RESUME_STATUSES_LABEL } from 'constants/resumePause';

const ListStyle = styled(ListItem)(({ theme }) => ({
  backgroundColor: '#fff',
  borderBottom: '1px solid rgba(0,0,0,.125)',
  paddingTop: 0,
  paddingBottom: 0,
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const TableCellStyle = withStyles({
  root: {
    borderBottom: 'none !important',
    fontSize: '1rem',
    padding: '0.5rem',
    paddingLeft: 10,
    paddingBottom: '2px !important',
  },
})(TableCell);

const OrderView = ({ data: backData, business, orderId, loading, disabledActions, handleSelect }) => {
  const data = backData;
  const printRef = useRef();
  const frameHeight = useRef(0);
  const { currentBusiness } = useSelector(({ business }) => business);
  const tzId = currentBusiness?.timezone ? currentBusiness.timezone.timeZoneId : 'America/Los_Angeles';
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const goToScreen = useCallback((i) => setSelectedIndex(i), []);

  useEffect(() => {
    if (data) {
      goToScreen(0);
    }
  }, [data, goToScreen]);

  const handlePrint = useCallback((type) => {
    printRef.current.classList.remove('ticket');
    printRef.current.classList.remove('receipt');
    printRef.current.classList.add(type);
    window.print();
  }, []);

  const productsData = groupByCategory(backData?.products);

  return (
    <Box id="order-view" className="aside-view shadow-sm">
      {disabledActions && <Preloader overlay />}
      {data ? (
        <Box sx={{ width: '100%', overflow: 'auto' }}>
          <Box
            sx={{ height: '100%' }}
            hidden={selectedIndex !== 0}
            className={`tab-panel order-print-screen ${selectedIndex === 0 ? 'active' : ''} `}
          >
            <MainScreen
              data={data}
              orderId={orderId}
              productsData={productsData}
              goToScreen={goToScreen}
              business={business}
              setFrameHeight={(h) => (frameHeight.current = h)}
              ref={printRef}
              tzId={tzId}
              disabledActions={disabledActions}
              handleSelect={handleSelect}
            />
          </Box>
          <Box
            hidden={selectedIndex !== 1}
            sx={{ width: '100%', overflow: 'auto' }}
            className={`tab-pane ${selectedIndex === 1 ? 'active' : ''} `}
          >
            <PrintScreen tzId={tzId} data={data} onPrint={handlePrint} goToScreen={goToScreen} />
          </Box>
        </Box>
      ) : loading ? (
        <Preloader overlay />
      ) : (
        <Box color="#6c757d" textAlign="center" p={5}>
          <i className="h1 icon icon-orders"></i>
          <Typography variant="h5" fontWeight={500}>
            {/* Select order */}
            {t('order_view.select_order')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const OrderDetails = memo(
  ({ provider, number, beReadyTime, isScheduled, tzId, type, business, modified, createdAt }) => {
    const { t } = useTranslation();
    const { providers } = useProviderConfig();

    return (
      <>
        {(business[0]?.title || business[0]?.companyName) && (
          <>
            <div className="details-title">
              <span className="details-text font-weight-bold">{business[0]?.title || business[0]?.companyName}</span>
              <Typography fontSize="14px">{business[0]?.addressInfo?.fullAddress}</Typography>
            </div>
          </>
        )}

        <div className="detail-info-message">
          {modified && (
            <Alert
              className="order-alert details-text"
              sx={{
                justifyContent: 'center',
              }}
              icon={false}
              severity="error"
            >
              {t('orders_history.modified')}
            </Alert>
          )}
        </div>
        <div className="details-type">
          <span className="text-left">{getTypeText(type, t)}</span>
        </div>
        <div className="aside-view-detailers">
          <img src={providers[provider]?.logoUrl} alt="print" width="50" height="50" />
          <div className="detailers">
            <p># {number}</p>
            <OrderDate
              date={createdAt}
              tzId={business[0].timezone.timeZoneId}
              label={t('order_view.order_received')}
              showIcon={false}
            />
            <OrderDate date={beReadyTime} tzId={business[0].timezone.timeZoneId} />
          </div>
        </div>
        <>
          {isScheduled && (
            <Alert className="order-alert" severity="info">
              {t('order_view.scheduled_order_at')}&nbsp;
              {moment
                .utc(beReadyTime)
                .tz(business[0].timezone.timeZoneId || tzId)
                .format('hh:mm A - ddd, MMM Do')}
            </Alert>
          )}
        </>
      </>
    );
  }
);

const ContactInfo = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div>
      <strong> {t('batch_report.contact_info')}</strong>
      <p>{[data.address1, data.address2, data.name, data.phone, data.instruction].filter((i) => !!i).join('\n')}</p>
    </div>
  );
};

const PaymentInfo = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div>
      <strong> {t('order_view.payment')}</strong>
      <p className={!data.paid && data.status.status !== 'picked_up' ? 'p-text-warning' : ''}>
        {data.paid ? (
          t('order_view.payed_order')
        ) : data.status.status === 'picked_up' ? (
          'order_view.paid_in_storet'
        ) : (
          <>
            <span>{t('order_view.payment_due')} </span>
            <strong>{parseFloat(data.charges.total).toFixed(2)}</strong>
          </>
        )}
      </p>
    </div>
  );
};

const DeliveryInfo = ({ data, orderId }) => {
  const { providers } = useProviderConfig();

  const { t } = useTranslation();

  const getDeliveryName = (providerServiceData) => {
    if (providerServiceData?.deliveryInfo?.quote?.providerService) {
      return providerServiceData?.deliveryInfo?.quote?.providerService.toUpperCase() === AI_DISPATCH_NAME.DISPATCH
        ? AI_DISPATCH_NAME.AI_DISPATCH
        : providerServiceData?.deliveryInfo?.quote?.providerService.toUpperCase() || '';
    } else {
      return providerServiceData?.delivery?.deliveryCarrier.toUpperCase() === AI_DISPATCH_NAME.DISPATCH
        ? AI_DISPATCH_NAME.AI_DISPATCH
        : providerServiceData?.delivery?.deliveryCarrier.toUpperCase() || '';
    }
  };

  const getDeliveryNameBySuccessCase = (providerServiceName) => {
    return providerServiceName === AI_DISPATCH_NAME.DISPATCH ? AI_DISPATCH_NAME.AI_DISPATCH : providerServiceName;
  };

  return (
    <div className="order-delivery-info">
      {(data.delivery && data.provider === providers?.food2?.srv && data.delivery?.deliveryAddress) ||
        ((!data.delivery?.deliveryByProvider || data.provider === providers?.doocado?.srv) &&
          data.delivery?.deliveryAddress) ? (
        <Alert
          className="order-alert-success"
          sx={{
            justifyContent: 'center',
          }}
          variant="outlined"
          icon={false}
          severity="success"
        >
          {data.delivery?.deliveryCarrier === 'self'
            ? t('website_settings.in_house_delivery').toUpperCase()
            : data.delivery?.deliveryCarrier === 'postmates'
              ? t('orders.delivery_uber_direct').toUpperCase()
              : `${t('order_view.delivery_by')} ${' '} ${getDeliveryName(data)}`}
        </Alert>
      ) : (
        <Alert
          className="order-alert-success"
          sx={{
            justifyContent: 'center',
          }}
          variant="outlined"
          severity="success"
          icon={false}
        >
          {data.delivery?.deliveryCarrier === 'self' ? (
            t('website_settings.in_house_delivery').toUpperCase()
          ) : (
            <>
              {t('order_view.delivery_by')}
              {` `}
              {data?.provider?.toUpperCase() === providers.food2.srv
                ? data?.provider?.toUpperCase()
                : getDeliveryNameBySuccessCase(data?.delivery?.deliveryCarrier.toUpperCase())}
            </>
          )}
        </Alert>
      )}
      {data.status?.status !== ORDER_STATUS.unconfirmed.value &&
        data.status?.status !== ORDER_STATUS.rejected.value &&
        data?.delivery?.deliveryCarrier?.toUpperCase() === DISPATCH_DELIVERY_CARRIER.DISPATCH && (
          <DispatchDeliveryDetails orderId={orderId || data?._id} />
        )}
      <center>
        <strong> {t('order_view.delivery_info')}</strong>
        <br />
        <br />

        <p> {t('order_view.name_contact')}</p>
        <p>{`${data.contactInfo.name} ${data.contactInfo.phone}`}</p>
        {data.delivery && data.delivery.deliveryAddress ? (
          <>
            <br />
            <p>{t('order_view.address')}:</p>
            {data.delivery.deliveryAddress.address1 ? <p>{data.delivery.deliveryAddress.address1}</p> : null}
            {data.delivery.deliveryAddress.address2 ? <p>{data.delivery.deliveryAddress.address2}</p> : null}
            {data.delivery.deliveryAddress.city ||
              data.delivery.deliveryAddress.state ||
              data.delivery.deliveryAddress.zipCode ? (
              <p>
                {data.delivery.deliveryAddress.city} {data.delivery.deliveryAddress.state}{' '}
                {data.delivery.deliveryAddress.zipCode}
              </p>
            ) : null}
          </>
        ) : null}
        {data?.delivery?.deliveryInstructions ? (
          <>
            <p>{t('order_view.delivery_instruction')}:</p>
            <p>{data.delivery.deliveryInstructions}</p>
          </>
        ) : null}
        {data.referenceCode && data.delivery ? (
          <>
            <br />
            <p>{t('order_view.delivery_instruction')}</p>
            <br />
            {data?.delivery?.deliveryCarrier ? (
              <i>
                <p>
                  {t('order_view.delivery_by')} {data?.delivery?.deliveryCarrier}
                </p>
              </i>
            ) : null}
            <i>
              <p>
                {t('order_view.reference_code')}: {data.referenceCode}
              </p>
            </i>
          </>
        ) : null}
      </center>
    </div>
  );
};

const PrintScreen = ({ data, onPrint, goToScreen, tzId }) => {
  const { t } = useTranslation();

  const [type, setType] = useState('ticket');
  const handlePrint = () => {
    onPrint(type);
  };
  return (
    <div className="aside-view-tab">
      <div className="aside-view-head">
        <Button id="orderView" color="info" onClick={() => goToScreen(0)}>
          <i className="icon icon-arrow-left"></i>
        </Button>
        <h6 category="h6">{t('order_view.print')}</h6>
      </div>
      <div className="aside-view-body">
        <OrderDetails
          business={data.business}
          number={data.number}
          provider={data.provider}
          beReadyTime={data.beReadyTime}
          createdAt={data.createdAt}
          tzId={tzId}
          type={data.type}
          modified={data.modified}
        />
        <List
          sx={{
            border: '1px solid rgba(0,0,0,.125)',
            borderRadius: '6px',
          }}
        >
          <ListStyle>
            <FormControlLabel
              value="ticket"
              name="type"
              onChange={() => setType('ticket')}
              control={<Radio checked={type === 'ticket'} />}
              label={t('order_view.order_ticket')}
            />
          </ListStyle>
          <ListStyle>
            <FormControlLabel
              value="receipt"
              name="type"
              onChange={() => setType('receipt')}
              control={<Radio checked={type === 'receipt'} />}
              label={t('order_view.order_receipt')}
            />
          </ListStyle>
        </List>
      </div>
      <div className="aside-view-actions">
        <Button id="orderViewPrint" variant="contained" color="primary" onClick={() => handlePrint()}>
          {t('order_view.print')}
        </Button>
      </div>
    </div>
  );
};

const MainScreen = forwardRef(
  ({ data, productsData, business, goToScreen, orderId, tzId, disabledActions, handleSelect }, ref) => {
    const { providers } = useProviderConfig();
    const [rejectModal, setRejectModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isDisabledModal, setIsDisabledModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [status, setStatus] = useState('');
    const dispatch = useDispatch();
    const { currentBusiness } = useSelector(({ business }) => business);
    const { selected } = useSelector(({ chargeBack }) => chargeBack);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const sumTips = Number(data.charges?.tipInfo?.kitchen || 0) + Number(data?.charges?.tipInfo?.driver || 0);
    const history = useHistory();
    const isChargeBack = history.location.pathname.includes('chargeback');
    const isChargeBackNew = history.location.pathname === '/chargeback/new';
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const [openSubOrderModal, setOpenSubOrderModal] = useState(false);
    const [subOrders, setSubOrders] = useState([]);

    const isOrderGroup = data.orderGroup === ORDER_GROUP.group;
    const isSubOrder = data.orderGroup === ORDER_GROUP.suborder;

    useEffect(() => {
      setRejectModal(false);
    }, [data._id]);

    const handleConfirmOrder = useCallback(
      ({ prepTime, minute }) => {
        setLoading(true);
        dispatch(
          changeOrderStatus(data._id, {
            status,
            beReadyTime: prepTime,
            prepTime: minute,
            quoteId: data.quoteId,
            state: status,
          })
        )
          .catch((err) => {
            toast.error(err.response.message);
          })
          .finally(() => {
            setLoading(false);
            setIsOpen(false);
          });
        // }
        //  else {
        //   const orderBusiness = data?.business[0];
        //   return confirmOrder(data._id, data.provider, orderBusiness.restaurantMailbox, {
        //     lastStatus: status,
        //     lastState: status,
        //     restaurantMailbox: orderBusiness.restaurantMailbox,
        //     orderNumber: data.number,
        //     oco_external_id: data.oco_external_id,
        //     prepTime: minute,
        //     provider: data.provider,
        //     defaultPrepTime: orderBusiness?.defaultPrepTime || 0,
        //     beReadyTime: prepTime,
        //   })
        //     .then(() =>
        //       dispatch(changeOrderStatus(data._id, { status, prepTime: minute })).then(() => {
        //         dispatch(changeOrderStatus(data._id, { status: 'in_progress' }));
        //       })
        //     )
        //     .finally(() => {
        //       // setTimeout(() => {
        //       setLoading(false);

        //       // }, 1000);
        //       setIsOpen(false);
        //     });
        // }
      },
      [data, status, business]
    );

    const handleChangeStatus = useCallback(
      (status) => {
        if (status === 'confirmed') {
          setIsOpen(true);
          setIsDisabledModal(false);
          setStatus(status);
        } else {
          setLoading(true);
          dispatch(changeOrderStatus(data._id, { status })).finally(() => {
            setLoading(false);
          });
        }
      },
      [data]
    ); // Ashot - cause of order list issues

    const handleReject = () => {
      setRejectModal(true);
    };

    const handleSelectSubOrder = (rowData) => {
      handleCloseSubOrderDialog();
      handleSelect(rowData);
    };

    const handleOpenSubOrders = () => {
      if (isOrderGroup) {
        setOpenSubOrderModal(true);
        data.suborders.map((subOrderItem) => {
          subOrderItem.providerName = data.providerName;
          subOrderItem.business = data.business;
          return subOrderItem;
        });
        setSubOrders(data.suborders);
      } else {
        setOpenSubOrderModal(false);
      }
    };

    const handleCloseSubOrderDialog = () => {
      setOpenSubOrderModal(false);
      setSubOrders([]);
    };

    const cancelOrder = (message) => {
      if (data.provider === providers?.ezcater?.srv) {
        toast.error(t('settings.ezCater_cancel'));
        return;
      }
      let statusValue = ORDER_STATUS.rejected.value;
      setLoading(true);

      const dataModel = { status: statusValue, cancellationReason: message, message };
      if (data.status.status === ORDER_STATUS.in_progress.value) {
        statusValue = ORDER_STATUS.canceled.value;
        dataModel.status = ORDER_STATUS.canceled.value;
      }

      if (data.status.status === ORDER_STATUS.completed.value) {
        statusValue = ORDER_STATUS.closed.value;
        dataModel.status = ORDER_STATUS.closed.value;
      }
      if (data.provider === providers?.website?.srv) {
        return dispatch(changeOrderStatus(data._id, dataModel))
          .then(() => {
            setLoading(false);
          })
          .finally(() => setLoading(false));
      }
      const orderBusiness = data?.business[0];

      return rejectOrder(data._id, data.provider, orderBusiness.restaurantMailbox, {
        lastStatus: statusValue,
        lastState: statusValue,
        restaurantMailbox: orderBusiness?.restaurantMailbox,
        orderNumber: data.number,
        oco_external_id: data.oco_external_id,
        provider: data.provider,
        defaultPrepTime: orderBusiness.defaultPrepTime,
        cancellation_reason: message || 'Restaurant is too Busy',
        cancellationReason: message || 'Restaurant is too Busy',
      })
        .then((data) => setLoading(false))
        .catch((err) => {
          toast.error(err?.response?.message);
          return err;
        })
        .finally(() => setLoading(false));
    };

    const renderFooter = useCallback(
      (status, type) => {
        switch (status) {
          case 'unconfirmed':
            return (
              <>
                <Button
                  id="orderViewReject"
                  disabled={isLoading || disabledActions}
                  color="error"
                  variant="outlined"
                  onClick={handleReject}
                >
                  {t('order_view.reject')}
                </Button>
                <Button
                  id="orderViewConfirmed"
                  disabled={isLoading || disabledActions}
                  color="primary"
                  variant="contained"
                  onClick={() => handleChangeStatus('confirmed')}
                >
                  {t('order_view.confirm_order')}
                </Button>
              </>
            );
          // case 'confirmed':
          //   return (
          //     <>
          //       <Button disabled={isLoading || isDisabled} variant="outlined" color="error" onClick={handleReject}>
          //         Drop
          //       </Button>
          //       <Button
          //         disabled={isLoading || isDisabled}
          //         variant="contained"
          //         color="primary"
          //         onClick={() => handleChangeStatus('in_progress')}
          //       >
          //         Processing
          //       </Button>
          //     </>
          //   );
          case 'scheduled':
          case 'in_progress':
            return (
              <>
                <Button
                  id="orderViewCancelOrder"
                  disabled={isLoading || disabledActions}
                  variant="outlined"
                  color="error"
                  outline
                  onClick={handleReject}
                >
                  {t('order_view.cancel_order')}
                </Button>
                <Button
                  id="orderViewForPickup"
                  disabled={isLoading || disabledActions}
                  variant="contained"
                  color="primary"
                  onClick={() => handleChangeStatus('ready_for_pickup')}
                >
                  {t('order_view.ready_order')}
                </Button>
              </>
            );
          case 'ready_for_pickup':
            return (
              <>
                <Button
                  id="orderViewRejected"
                  disabled={isLoading || disabledActions}
                  variant="outlined"
                  color="error"
                  onClick={handleReject}
                >
                  {t('order_view.reject')}
                </Button>
                <Button
                  id="orderViewPickedUp"
                  disabled={isLoading || disabledActions}
                  variant="contained"
                  color="primary"
                  onClick={() => handleChangeStatus('picked_up')}
                >
                  {t('order_view.picked_up')}
                </Button>
              </>
            );
          case 'picked_up':
            return type === 'delivery' ? (
              <Button
                id="orderViewDelivered"
                disabled={isLoading || disabledActions}
                color="success"
                onClick={() => handleChangeStatus('delivered')}
              >
                {t('order_view.delivered')}
              </Button>
            ) : null;
          case 'unknown':
            return (
              <Box
                sx={{
                  color: '#ac001d',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  background: '#f6f6f6',
                  width: '300px',
                  fontWeight: 500,
                }}
              >
                {t('order_view.oder_status_is_time_constraints')}
              </Box>
            );
          case 'rejected_by_orders_co':
            return (
              <Box
                sx={{
                  color: '#ac001d',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  background: '#f6f6f6',
                  width: '300px',
                  fontWeight: 500,
                }}
              >
                {t('order_view.order_has_rejected_provider')}
              </Box>
            );

          case 'completed':
            return (
              <Button
                id="orderViewIssueWithOrder"
                // disabled={isLoading || disabledActions}
                variant="outlined"
                color="error"
                outline
                onClick={handleReject}
              >
                {t('order_view.issue_with_order')}
              </Button>
            );
          case ORDERS_FILTER_STATUS_HISTORY?.expired?.value:
            return (
              <Box
                sx={{
                  color: '#ac001d',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  background: '#f6f6f6',
                  width: '300px',
                  fontWeight: 500,
                }}
              >
                {t('order_view.rejected_by_orders_co_status')}
              </Box>
            );
          default:
            return null;
        }
      },
      [handleChangeStatus, disabledActions, isLoading]
    );

    if (rejectModal) {
      return (
        <RejectModal
          status={data.status.status}
          isLoading={isLoading}
          toggle={setRejectModal}
          onSubmit={cancelOrder}
          phone={data.contactInfo.phone}
          disabledActions={disabledActions}
        />
      );
    }

    return (
      <div className="aside-view-tab">
        <div className="aside-view-head">
          <h6 category="h6">{getTypeText(data.type, t)}</h6>

          {currentBusiness?.roles?.name === 'super' ? (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  {isOrderGroup && (
                    <Button variant="text" onClick={handleOpenSubOrders}>
                      {t('Details')}
                    </Button>
                  )}

                  <Button color="info" onClick={() => goToScreen(1)}>
                    <i className="icon icon-printer"></i>
                  </Button>
                </Box>
                {!isChargeBack ? (
                  <IconButton sx={{ ml: 1 }} disableRipple onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Box>
              <OrderStatusChange open={open} id={id} anchorEl={anchorEl} handleClose={handleClose} data={data} />
            </>
          ) : (
            <Box>
              {isOrderGroup && (
                <Button variant="text" onClick={handleOpenSubOrders}>
                  {t('Details')}
                </Button>
              )}

              <Button color="info" onClick={() => goToScreen(1)}>
                <i className="icon icon-printer"></i>
              </Button>
            </Box>
          )}
        </div>
        <ChargeBackInfo />
        {isChargeBack && !selected?.orderData ? (
          <EmptyOrder />
        ) : (
          <div className="aside-view-body p-0" ref={ref}>
            {data.status.status === 'rejected' ||
              data.status.status === ORDER_STATUS.closed.value ||
              data.status.status === 'canceled' ? (
              <Box className="details-title reason-msg" sx={{ backgroundColor: '#C20009 !important' }}>
                <Typography fontSize="14px" fontWeight={600} color="#fff">
                  {data.status.status.toUpperCase()}: {data.status.cancellationReason}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
            <OrderDetails
              tzId={data.business[0].timezone.timeZoneId || tzId}
              number={data.number}
              provider={data.provider}
              beReadyTime={data.beReadyTime}
              createdAt={data.createdAt}
              isScheduled={data.isScheduled}
              business={data.business}
              type={data.type}
              modified={data.modified}
            />
            <GroupOrderDialog
              title={t('Group Order')}
              description={t('Group Order')}
              open={openSubOrderModal}
              handleClose={handleCloseSubOrderDialog}
              data={subOrders}
              onSelect={handleSelectSubOrder}
            />
            {!!data.referenceCode && (
              <div>
                <p>
                  <i>
                    {t('order_view.reference_code')}: {data.referenceCode}
                  </i>
                </p>
              </div>
            )}
            {!!data.contactInfo && <ContactInfo data={data.contactInfo} />}
            {!!data && !isSubOrder ? <PaymentInfo data={data} /> : ''}
            {data.type === 'delivery' && !isSubOrder ? <DeliveryInfo data={data} orderId={orderId} /> : null}
            {!!data.specialInstructions && (
              <div className="">
                <strong>{t('order_view.notes')}:</strong>
                <p>{data.specialInstructions}</p>
              </div>
            )}

            <Table sx={{ p: 0 }} size="small">
              <TableBody>
                {Object.keys(productsData)?.map((keys) => {
                  return (
                    <Box key={keys}>
                      <TableRow>
                        <TableCellStyle
                          sx={{
                            borderTop: '1px solid rgba(0, 0, 0, 0.4)  !important',
                            paddingLeft: '40px !important',
                          }}
                        >
                          <Typography fontWeight={700}>{productsData[keys]?.categoryName?.toUpperCase()}</Typography>
                        </TableCellStyle>
                        <TableCellStyle
                          sx={{ borderTop: '1px solid rgba(0, 0, 0, 0.4)  !important', paddingTop: '0 !important' }}
                        ></TableCellStyle>
                      </TableRow>
                      {productsData[keys]?.items?.map((items, index) => {
                        const { name, instructions, price, modifier, quantity, _id } = items;
                        return (
                          <Fragment key={index}>
                            <TableRow>
                              <TableCellStyle sx={{ width: '100%', paddingTop: '0 !important' }}>
                                <Box display="flex">
                                  <Typography minWidth={30} fontWeight={700}>
                                    {quantity}x
                                  </Typography>
                                  <Typography fontWeight={700}>{name}</Typography>
                                </Box>

                                {!!instructions && (
                                  <div>
                                    <strong>{t('order_view.notes')}:</strong>
                                    <p>{instructions}</p>
                                  </div>
                                )}
                              </TableCellStyle>
                              <TableCellStyle sx={{ textAlign: 'right' }} className="price-col">
                                <Currency value={price} />
                              </TableCellStyle>
                            </TableRow>
                            {!!modifier?.length && (
                              <TableRow>
                                <TableCellStyle
                                  colSpan="3"
                                  component="td"
                                  sx={{
                                    padding: '0 !important',
                                    borderTop: 0,
                                  }}
                                >
                                  <Table sx={{ m: 0 }} size="small">
                                    <TableBody>
                                      {modifier.map((item, key) => {
                                        return (
                                          <Fragment key={key + 1}>
                                            <TableRow className="order-modifiers">
                                              <TableCellStyle
                                                sx={{
                                                  padding: '4px 10px 0 40px !important',
                                                }}
                                                component="td"
                                              >
                                                {item.name}
                                              </TableCellStyle>
                                            </TableRow>
                                            <TableRow>
                                              <TableCellStyle
                                                component="td"
                                                sx={{ p: 0, paddingBottom: '5px !important' }}
                                              >
                                                <Table>
                                                  <TableBody>
                                                    {item?.list.map((list) => (
                                                      <TableRow key={list._id} className="order-modifiers">
                                                        <TableCellStyle
                                                          component="td"
                                                          sx={{
                                                            width: list.isFree ? 'inherit' : '100%',
                                                            padding: '2px 10px 0 30px !important',
                                                          }}
                                                        >
                                                          <Box display="flex" alignItems="center">
                                                            {list?.count ? (
                                                              <Typography
                                                                mr={1}
                                                                fontWeight={500}
                                                              >{`${list?.count}x `}</Typography>
                                                            ) : (
                                                              <></>
                                                            )}
                                                            <Typography
                                                              sx={{
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis',
                                                                overflow: 'hidden',
                                                                maxWidth: '200px',
                                                                mb: 0,
                                                                mr: 1,
                                                              }}
                                                            >{`${list?.name}`}</Typography>
                                                            {list.freeCount ? (
                                                              <Typography fontWeight={500}>{`(${list.freeCount} ${t(
                                                                'order_view.free'
                                                              )})`}</Typography>
                                                            ) : (
                                                              ''
                                                            )}
                                                          </Box>
                                                        </TableCellStyle>
                                                        <TableCellStyle
                                                          component="td"
                                                          sx={{ textAlign: 'right', padding: '0!important' }}
                                                          className="price-col"
                                                        >
                                                          <Currency
                                                            sx={{ fontWeight: 400, strong: { fontWeight: 400 } }}
                                                            value={list.price}
                                                          />
                                                        </TableCellStyle>
                                                      </TableRow>
                                                    ))}
                                                  </TableBody>
                                                </Table>
                                              </TableCellStyle>
                                            </TableRow>
                                          </Fragment>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableCellStyle>
                              </TableRow>
                            )}
                          </Fragment>
                        );
                      })}
                    </Box>
                  );
                })}
              </TableBody>
            </Table>
            {!isSubOrder && (
              <Table
                sx={{
                  mt: 2,
                  borderTop: '1px solid #c5d9e8',
                  borderTopWidth: '2px',
                }}
                className="total-table"
                size="small"
              >
                <TableBody>
                  <TableRow>
                    <TableCellStyle component="td">{t('order_view.subtotal')}</TableCellStyle>
                    <TableCellStyle
                      component="td"
                      sx={{
                        textAlign: 'right',
                      }}
                    >
                      <Currency value={data.charges.subTotal} />
                    </TableCellStyle>
                  </TableRow>
                  {!isSubOrder && (data.charges.couponAmount || data?.charges?.discountAmount) ? (
                    <TableRow>
                      <TableCellStyle component="td">
                        {t('Discount')}&nbsp;
                        {data?.charges?.discountDetails?.couponTitle
                          ? `(${data.charges.discountDetails.couponTitle})`
                          : data?.charges?.discountDetails?.couponName
                            ? `(${data.charges.discountDetails.couponName})`
                            : data?.charges?.discountDetails?.discountCode
                              ? `(${data?.charges?.discountDetails?.discountCode})`
                              : data?.promotionInfo?.couponTitle ?
                                `(${data?.promotionInfo?.couponTitle})` :
                                data?.promotionInfo?.couponName ? `(${data?.promotionInfo?.couponName})` : ''}
                      </TableCellStyle>
                      <TableCellStyle
                        component="td"
                        sx={{
                          textAlign: 'right',
                        }}
                      >
                        <Currency
                          target="brackets"
                          value={(data.charges.couponAmount || 0) + (data?.charges?.discountAmount || 0)}
                        />
                      </TableCellStyle>
                    </TableRow>
                  ) : null}
                  {!isSubOrder &&
                  ((data.type === 'delivery' && (!data.delivery?.deliveryByProvider || data.charges.deliveryFee)) ||
                    (data.provider === providers?.doocado?.srv && data.type === 'delivery')) ? (
                    <TableRow>
                      <TableCellStyle component="td"> {t('order_view.delivery_fee')}</TableCellStyle>
                      <TableCellStyle
                        component="td"
                        sx={{
                          textAlign: 'right',
                        }}
                      >
                        <Currency value={data.charges.deliveryFee} />
                      </TableCellStyle>
                    </TableRow>
                  ) : null}
                  {!isSubOrder && Number(data.charges.discount) > 0 ? (
                    <TableRow>
                      <TableCellStyle component="td">{t('order_view.cashback_used')}</TableCellStyle>{' '}
                      <TableCellStyle
                        component="td"
                        sx={{
                          textAlign: 'right',
                        }}
                      >
                        <Currency target="brackets" value={data.charges.discount} />
                      </TableCellStyle>
                    </TableRow>
                  ) : null}
                  {sumTips > 0 && (
                    <>
                      <TableRow>
                        <TableCellStyle component="td">{t('order_view.tip')}</TableCellStyle>

                        <TableCellStyle
                          component="td"
                          sx={{
                            textAlign: 'right',
                          }}
                        >
                          <Currency value={sumTips || 0} />
                        </TableCellStyle>
                      </TableRow>
                      {Number(data.charges?.tipInfo?.kitchen) ? (
                        <TableRow>
                          <TableCell sx={{ fontSize: '14px', borderBottom: 'none !important' }} component="td">
                            {t('order_view.tips_kitchen')}
                          </TableCell>
                          <TableCell
                            component="td"
                            sx={{
                              fontSize: '14px',
                              textAlign: 'right',
                              padding: '0.5rem',
                              borderBottom: 'none !important',
                              '> p': {
                                fontSize: '14px',
                              },
                            }}
                          >
                            <Currency value={data.charges?.tipInfo?.kitchen} />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      )}

                      {data.type === 'delivery' && Number(data.charges?.tipInfo?.driver) ? (
                        <TableRow>
                          <TableCell sx={{ fontSize: '14px', borderBottom: 'none !important' }} component="td">
                            {t('order_view.tips_driver')}
                          </TableCell>
                          <TableCell
                            component="td"
                            sx={{
                              borderBottom: 'none !important',
                              textAlign: 'right',
                              fontSize: '14px',
                              padding: '0.5rem',
                              '> p': {
                                fontSize: '14px',
                              },
                            }}
                          >
                            <Currency value={data.charges?.tipInfo?.driver} />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <TableRow>
                    <TableCellStyle component="td">{t('order_view.taxes')}</TableCellStyle>
                    <TableCellStyle
                      component="td"
                      sx={{
                        textAlign: 'right',
                      }}
                    >
                      {data?.provider === providers?.food2?.srv ? (
                        <Currency value={Number(data.charges.tax) + sumTaxesAndFees(data)} />
                      ) : data?.provider === 'table_ordering' ? (
                        <Currency value={Number(data.charges.tax) + sumTaxesAndFees(data)} />
                      ) : (
                        <Currency value={data.charges.tax + (data.charges.processingFee || 0)} />
                      )}
                    </TableCellStyle>
                  </TableRow>
                  <OrderTaxAndFees data={data} />
                  <TableRow className="total-row">
                    <TableCellStyle component="td">
                      <strong
                        style={{
                          fontSize: '20px',
                          fontWeight: 500,
                        }}
                      >
                        {t('order_view.total')}
                      </strong>
                    </TableCellStyle>
                    <TableCell
                      component="td"
                      className={isSubOrder ? 'total-view' : ''}
                      sx={{
                        textAlign: 'right',
                        padding: '6px 7px',
                        '.MuiTableCell-root': {
                          borderBottom: '1px solid transparent !important',
                        },
                      }}
                    >
                      <Currency
                        sx={{
                          fontSize: '20px',
                          fontWeight: 500,
                        }}
                        value={data.charges.total}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
            {!isSubOrder && (
              <>
                {data.status.status === ORDER_STATUS.rejected.value ||
                data.status.status === ORDER_STATUS.canceled.value ||
                data.status.status === ORDER_STATUS.closed.value ? (
                  <Alert
                    className="order-alert-warning"
                    sx={{
                      justifyContent: 'center',
                    }}
                    variant="outlined"
                    severity="warning"
                    icon={false}
                  >
                    {`${t(ORDER_STATUS[data.status.status].name).toUpperCase()}  ORDER`}
                  </Alert>
                ) : data.paid ? (
                  <Alert className="order-alert-success" severity="success">
                    {t('order_view.paid').toUpperCase()}
                  </Alert>
                ) : (
                  <>
                    <Alert
                      className="order-alert-warning"
                      sx={{
                        justifyContent: 'center',
                      }}
                      variant="outlined"
                      severity="warning"
                      icon={false}
                    >
                      {t('order_view.payment_due_')} <strong>{parseFloat(data.charges.total).toFixed(2)}</strong>
                    </Alert>
                  </>
                )}
              </>
            )}
            {data?.payment && !isSubOrder ? <OrderPaymentDetails data={data} /> : <></>}
          </div>
        )}
        {isChargeBack ? (
          <>
            <ChargeBackTotalSections />
            {isChargeBackNew ? <ChargeBackActions /> : <></>}
          </>
        ) : (
          <div
            className={`aside-view-actions ${data.status.status === 'rejected_by_orders_co'
              ? 'rejected-ordersCo-container'
              : data.status.status === 'unknown'
                ? 'unknown-container'
                : ''
              }`}
          >
            {!isOrderGroup && renderFooter(data.status.status, data.type)}
          </div>
        )}
        {isOpen && (
          <ConfirmOrderModal
            currentBusinessId={currentBusiness._id}
            tzId={data.business[0]?.timezone?.timeZoneId || tzId}
            orderBusinessId={data.business[0]._id}
            loading={isLoading}
            setIsDisabledModal={setIsDisabledModal}
            isDisabled={isDisabledModal}
            orderCreationTime={data.placedAt}
            handleSubmit={handleConfirmOrder}
            isOpen={isOpen}
            isScheduled={data.isScheduled}
            beReadyTime={data.beReadyTime}
            closeModal={() => setIsOpen(false)}
            defaultPrepTime={
              data?.metadata?.prepTime ||
              data?.orderMetaData?.business?.defaultPrepTime ||
              data.business[0].defaultPrepTime
            }
            metaDataAdditionalPrepTime={data?.metadata?.additionalPrepTime || 0}
            provider={data.provider}
            isBusy={Boolean(data?.metadata?.currentStatus?.toUpperCase() === PAUSE_RESUME_STATUSES_LABEL.BUSY)}
          />
        )}
      </div>
    );
  }
);

const RejectModal = ({ toggle, onSubmit, phone, isLoading, disabledActions, status }) => {
  const [rejectText, setRejectText] = useState('');
  const [openOther, setOpenOther] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (!rejectText) {
      setShowErrorMessage(true);
    } else {
      onSubmit(rejectText).then(() => {
        toggle(false);
      });
    }
  };

  const rejectTexts = useMemo(() => {
    const unconfirmedOrderResons = [
      t('order_view.reject_reason_busy'),
      t('order_view.reject_reason_closed'),
      t('order_view.reject_reason_unable'),
      t('order_view.reject_reason_other'),
    ];
    const processingOrderResons = [
      t('order_view.reject_reason_canceled_by_provider'),
      t('order_view.reject_reason_busy'),
      t('order_view.reject_reason_unable'),
    ];
    const completedOrderResons = [
      t('order_view.reject_reason_driver'),
      t('order_view.reject_reason_canceled_by_provider'),
      t('order_view.reject_reason_unable'),
      t('order_view.reject_reason_out_of_stock'),
    ];
    switch (status) {
      case ORDER_STATUS.unconfirmed.value:
        return unconfirmedOrderResons;
      case ORDER_STATUS.in_progress.value:
        return processingOrderResons;
      case ORDER_STATUS.completed.value:
        return completedOrderResons;
      default:
        return [];
    }

    // return ['Restaurant is too Busy', 'Restaurant is Closed', 'Unable to fulfill the order'];
  }, [status]);

  const submitButtonText = useMemo(() => {
    const unconfirmedOrderResons = t('order_view.reject');
    const processingOrderResons = t('order_view.cancel_order');
    const completedOrderResons = t('order_view.submit');
    switch (status) {
      case 'unconfirmed':
        return unconfirmedOrderResons;
      case 'in_progress':
        return processingOrderResons;
      case 'completed':
        return completedOrderResons;
      default:
        return 'Reject';
    }

    // return ['Restaurant is too Busy', 'Restaurant is Closed', 'Unable to fulfill the order'];
  }, [status]);

  // const OTHER_TEXT = 'Other';
  const handleSelect = (e, selectVal, item) => {
    if (item === 'Other') {
      setRejectText('');
      setOpenOther(true);
    } else {
      setRejectText(rejectTexts[selectVal]);
      setOpenOther(false);
    }
  };

  return (
    <Box className="aside-view-tab">
      <Box className="aside-view-head">
        <Typography fontSize="1rem" variant="h6">
          {/* Reject */}
          {status === ORDER_STATUS.unconfirmed.value
            ? t('order_view.reject_order_header')
            : status === ORDER_STATUS.completed.value
              ? t('order_view.close_order_header')
              : t('order_view.cancel_order_header')}
        </Typography>
        <Button
          id="orderViewToggle"
          color="info"
          onClick={() => {
            toggle(false);
          }}
        >
          <i className="icon icon-x"></i>
        </Button>
      </Box>

      <Box
        className="aside-view-body"
        sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <Box>
          {(isLoading || disabledActions) && <Preloader overlay />}
          <Typography sx={{ mt: 2 }} fontSize="20px" fontWeight={600} variant="h6">
            {status === ORDER_STATUS.in_progress.value
              ? t('order_view.cancel_order_title')
              : status === ORDER_STATUS.completed.value
                ? t('order_view.close_order_title')
                : t('order_view.reject_order_title')}
          </Typography>
          <Typography fontSize="16px" fontWeight={500}>
            {status === ORDER_STATUS.unconfirmed.value
              ? t('order_view.reject_order_subtitle')
              : status === ORDER_STATUS.completed.value
                ? t('order_view.close_order_subtitle')
                : t('order_view.It’s_good_cancelation')}
          </Typography>
          <List sx={{ mt: 4 }}>
            {rejectTexts.map((item, index) => {
              const selectedReason = rejectText === rejectTexts[index];

              return (
                <>
                  <Divider />
                  <ListItem
                    className={`${selectedReason ? 'custom-select-reason-main' : ''}`}
                    sx={{ display: 'block', cursor: 'pointer' }}
                    selected={selectedReason}
                    onClick={(e) => {
                      handleSelect(e, index, item);
                    }}
                  >
                    <ListItemText
                      className={`${selectedReason ? 'custom-select-reason' : ''}`}
                      sx={{ textAlign: 'center', cursor: 'pointer' }}
                      primary={item}
                    />
                  </ListItem>
                </>
              );
            })}
            {/* <>
              <Divider />
              <ListItem
                className={`${openOther ? 'custom-select-reason-main' : ''}`}
                sx={{ display: 'block' }}
                selected={openOther}
                onClick={() => {
                  setOpenOther(true);
                  setRejectText('');
                }}
              >
                <ListItemText
                  className={`${openOther ? 'custom-select-reason' : ''}`}
                  sx={{ textAlign: 'center', cursor: 'pointer' }}
                  primary={OTHER_TEXT}
                />
              </ListItem>
            </> */}
          </List>
          {openOther ? (
            <TextField
              sx={{
                mt: 2,
              }}
              fullWidth={true}
              multiline
              row="4"
              type="text"
              value={rejectText}
              InputLabelProps={{ shrink: true }}
              label={t('Description')}
              onChange={({ target }) => setRejectText(target.value)}
            />
          ) : (
            <></>
          )}
        </Box>
        <Box>
          {showErrorMessage && (
            <Typography sx={{ textAlign: 'center' }} color="#d32f2f">
              {t('order_view.please_select_reason')}
            </Typography>
          )}
          <Typography className="my-2">{t('order_view.reject_order_call')}</Typography>
          <Typography component="a" href={`tel:${phone}`}>
            <i className="icon icon-phone mr-2"></i>
            {phone}
          </Typography>
        </Box>
      </Box>

      <Box className="aside-view-actions">
        <Button id="orderViewButtonText" color="error" variant="outlined" block="true" onClick={handleSubmit}>
          {submitButtonText}
        </Button>
      </Box>
    </Box>
  );
};

export default OrderView;
