import { styled } from '@mui/material/styles';
import { Button, Box, ListItem, DialogTitle } from '@mui/material';

export const StyledListItem = styled(ListItem)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  };
});

export const StyledButton = styled(Button)(({ theme }) => {
  return {
    marginTop: `${theme.spacing(2)}`,
    marginLeft: `${theme.spacing(1)}`,
    border: 'none',
    outline: 'none',
    color: '#626A7A',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      border: 'none',
      outline: 'none',
      color: '#626A7A',
      fontSize: '14px',
      fontWeight: 500,
      background: '#FAFAFA',
    },
  };
});
export const StyledSaveButton = styled(Button)(({ theme }) => {
  return {
    marginTop: `${theme.spacing(2)}`,
    marginLeft: `${theme.spacing(1)}`,
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 500,
    width: '192px',
    height: '56px',
    '&:hover': {
      color: '#FFFFFF',
      fontSize: '14px',
      fontWeight: 500,
    },
  };
});

export const StyledImg = styled('img')(({ theme }) => {
  return {
    width: '32px !important',
  };
});

export const StyledBoxLocWrapper = styled(Box)(({ theme }) => {
  return {
    position: 'relative',
  };
});

export const StyledBoxLocInnerWrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    height: '22px',
    marginLeft: theme.spacing(1),
    borderRadius: '100px',
    cursor: 'pointer',
    justifyContent: 'center',
  };
});

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => {
  return {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '32px',
    color: '#1D1B20',
    marginTop: theme.spacing(2),
  };
});
export const StyledDialogFieldBox = styled(Box)(({ theme }) => {
  return {
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '358px',
    margin: theme.spacing(0, 3),
  };
});

export const StyledDialogFooterBox = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    bottom: '24px',
    width: '100%',
  };
});

export const StyledDialogFooterInnerBox = styled(Box)(({ theme }) => {
  return {
    margin: theme.spacing(1, 2, 0, 2),
    display: 'flex',
    justifyContent: 'end',
  };
});
