import { useMemo, memo } from 'react';
import { ORDERS_FILTER_STATUS, getPeriods } from 'constants/config';
import { Select, FormControl, MenuItem, Box } from '@mui/material';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import { useTranslation } from 'react-i18next';
// import { t } from 'react-i18next';

const OrdersFilter = ({ query, onSelect }) => {
  const { t } = useTranslation();

  const { status, period } = query;
  const { isTypeSingle } = isPermissionWithCompanyType();
  const periods = useMemo(() => getPeriods(), []);

  const handleChangeFilter = ({ target: { name, value } }) => {
    return onSelect(name, value);
  };

  return (
    <Box sx={{ px: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: '16px' }}>
        <FormControl size="small" sx={{ minWidth: 150 }}>
          <Select
            labelId="status"
            id="status"
            name="status"
            variant="outlined"
            placeholder="Status"
            value={status}
            onChange={handleChangeFilter}
          >
            {Object.values(ORDERS_FILTER_STATUS).map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {t(item.name)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ width: 200 }}>
          <Select
            labelId="period"
            id="period"
            name="period"
            value={period}
            placeholder="Period"
            onChange={handleChangeFilter}
          >
            {Object.keys(periods).map((key) => {
              return (
                <MenuItem key={key} value={key}>
                  {t(periods[key].label)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

      </Box>
    </Box>
  );
};
export default memo(OrdersFilter);
