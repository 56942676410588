export const PROVIDER_NO_CONNECTED = 1;
export const PROVIDER_CONNECTED = 2;
export const PROVIDER_PENDING = 3;
export const PROVIDER_PENDING_DISCONNECT = 4;

export const PROVIDER_STATE_ACTIVE = 1;
export const PROVIDER_STATE_INACTIVE = 2;
export const PROVIDER_STATUS_ACTIVE = 2;
export const PROVIDER_STATE_PENDING = 3;
export const PROVIDER_STATE_PENDING_INACTIVE = 4;

export const PROVIDER_NOT_CONFIRM_ORDER = 1;
export const PROVIDER_AUTO_CONFIRM_ORDER = 2;

export const getProviderStatus = (status) => {
  switch (status) {
    case PROVIDER_CONNECTED:
      return 'settings.connected';
    case PROVIDER_NO_CONNECTED:
      return 'settings.not_connected';
    case PROVIDER_PENDING:
      return 'help.connecting';
    case PROVIDER_PENDING_DISCONNECT:
      return 'settings.disconnecting';
    default:
      return null;
  }
};

export const getProviderState = (status) => {
  switch (status) {
    case PROVIDER_STATE_ACTIVE:
      return 'header.active';
    case PROVIDER_STATE_INACTIVE:
      return '_inactive';
    case PROVIDER_STATE_PENDING:
      return '_activating';
    case PROVIDER_STATE_PENDING_INACTIVE:
      return '_deactivating';
    default:
      return null;
  }
};
export const getProviderConfirmOrder = (status) => {
  switch (status) {
    case PROVIDER_AUTO_CONFIRM_ORDER:
      return 'Auto Confirm Orders';
    case PROVIDER_NOT_CONFIRM_ORDER:
      return 'Auto Confirm Orders';
    default:
      return null;
  }
};
