import * as moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from 'components/NewHome/ChargeBack/hooks/useHomeReport';

export const ReportType = {
  SALES_BY_HOUR: 'SALES_BY_HOUR',
  SALES_BY_DATE: 'SALES_BY_DATE',
  CHARGEBACK_DISPUTE_REPORT: 'CHARGEBACK_DISPUTE_REPORT',
  TOP_SELLERS: 'TOP_SELLERS',
  SALES_BY_PROVIDER: 'SALES_BY_PROVIDER',
};
export const DayOfWeek = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
};
export const PROVIDER_LIST = {
  ubereats: 'ubereats',
  grubhub: 'grubhub',
  doordash: 'doordash',
  doordashCartwheel: 'doordash_cartwheel',
  doocado: 'doocado',
  deliverycom: 'deliverycom',
  grubhubv2: 'grubhubv2',
  grubhubCartwheel: 'grubhub_cartwheel',
  postmates: 'postmates',
  website: 'website',
  tableOrdering: 'table_ordering',
  grubhub_cartwheel: 'grubhub_cartwheel',
  doordash_cartwheel: 'doordash_cartwheel',
  boston_market: 'boston_market',
  wix: 'wix',
  nash: 'nash',
  ally: 'ally',
  uber: 'uber',
  cartwheel: 'cartwheel',
  ezcater: 'ezcater',
  fetchme: 'fetchme',
  self: 'self',
  food2: 'food2',
  burq: 'burq',
};
export const ReportingFilterDefaultValues = {
  type: ReportType.SALES_BY_HOUR,
  start_date: moment.tz(DEFAULT_TIMEZONE).format('YYYY-MM-DD'),
  end_date: moment.tz(DEFAULT_TIMEZONE).format('YYYY-MM-DD'),
  days_of_week: [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
    DayOfWeek.SATURDAY,
    DayOfWeek.SUNDAY,
  ],
  brandIds: [],
  locationIds: [],
  providers: [
    PROVIDER_LIST.website,
    PROVIDER_LIST.food2,
    PROVIDER_LIST.ubereats,
    PROVIDER_LIST.postmates,
    PROVIDER_LIST.doordash,
    PROVIDER_LIST.grubhub,
    PROVIDER_LIST.grubhubv2,
    PROVIDER_LIST.grubhub_cartwheel,
    PROVIDER_LIST.doocado,
    PROVIDER_LIST.deliverycom,
    PROVIDER_LIST.tableOrdering,
    PROVIDER_LIST.fetchme,
    PROVIDER_LIST.wix,
    PROVIDER_LIST.doordash_cartwheel,
    PROVIDER_LIST.boston_market,
  ],
};
