import { SOLD_OUT_ENUM, SOLD_OUT_STATUS } from './enumSoldOut';

export const availabilityOptions = [
  {
    label: SOLD_OUT_ENUM.availability,
    value: SOLD_OUT_STATUS.AVAILABILITY,
  },
  {
    label: SOLD_OUT_ENUM.soldOutToday,
    value: SOLD_OUT_STATUS.SOLD_OUT_TODAY,
  },
  {
    label: SOLD_OUT_ENUM.soldOutPermanent,
    value: SOLD_OUT_STATUS.SOLD_OUT_PERMANENT,
  },
];
