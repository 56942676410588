import styled from '@emotion/styled';
import { Button, Popover, Box } from '@mui/material';

export const PopoverWrapper = styled(Popover)({
  '.MuiPaper-root': {
    boxShadow: 'none',
    width: '760px',
    overflowY: 'hidden',
  },
});

export const BusinessButton = styled(Button)(() => ({
  minWidth: '180px',
  maxWidth: '540px',
  height: '40px',
  display: 'flex',
  justifyContent: 'space-between',
  border: '1px solid #E7E7E7',
  color: '#919191',
  textAlign: 'left',
}));

export const BoxWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down(1050)]: {
    width: '100%',
  },
}));
