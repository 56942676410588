import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import AccountInfo from './AccountInfo';
import StepAuthorization from './StepAuthorization';
import { removeItemAfterSignUp } from 'utils/soldOut';
import AuthorizationBillingInfo from './AuthorizationBilling';
import AuthorizationServiceInfo from './AuthorizationServiceInfo';
import AddLocationSuccess from '../../AddLocation/AddLocationSuccess';
import { SIGN_UP_FLOW_AUTHORIZATION } from 'constants/availableButtons';
import { AuthorizationStepsBox, AuthorizationStepsSubTitle, AuthorizationStepsTitle, StepperSlider } from '../styles';

const AuthorizationStepsWrapper = () => {
  const [active, setActive] = useState(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_APPROVE);
  const [availableSteps, setAvailableSteps] = useState(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_BILLING_INFO);
  const [companyName, setCompanyName] = useState('');

  const isAvailableStepCallback = useCallback((planId, company) => {
    planId
      ? setAvailableSteps(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SERVICE_INFO)
      : setAvailableSteps(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_BILLING_INFO);
    setCompanyName(company);
  }, []);

  useEffect(() => {
    return () => {
      removeItemAfterSignUp();
    };
  }, []);

  return (
    <Box sx={{ mt: 10, mb: 5 }}>
      <Box className="signup-wizard my-3">
        <AuthorizationStepsBox
          sx={{ display: active === SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SUCCESS ? 'none' : 'block' }}
        >
          <AuthorizationStepsTitle>Location onboarding wizard</AuthorizationStepsTitle>
          <AuthorizationStepsSubTitle>
            You are adding a New Location for <strong>{companyName}</strong> brand. Please follow the steps below to
            complete your onboarding process
          </AuthorizationStepsSubTitle>
        </AuthorizationStepsBox>
        <Grid container spacing={2} sx={{ maxWidth: '800px' }}>
          <Grid item xs={12}>
            <StepperSlider
              sx={{ display: active === SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SUCCESS ? 'none' : '' }}
              value={active}
              step={1}
              min={1}
              max={availableSteps}
              aria-label="Default"
              valueLabelDisplay="off"
            />
            {active === SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_APPROVE && (
              <StepAuthorization
                availableSteps={availableSteps}
                setActive={setActive}
                active={active}
                isAvailableStepCallback={isAvailableStepCallback}
              />
            )}
            {active === SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_ACCOUNT_INFO && (
              <AccountInfo availableSteps={availableSteps} setActive={setActive} active={active} />
            )}
            {active === SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SERVICE_INFO && (
              <AuthorizationServiceInfo availableSteps={availableSteps} setActive={setActive} active={active} />
            )}
            {active === SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_BILLING_INFO && (
              <AuthorizationBillingInfo availableSteps={availableSteps} setActive={setActive} active={active} />
            )}
            {active === SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SUCCESS && <AddLocationSuccess />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AuthorizationStepsWrapper;
