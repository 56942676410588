import { useSelector } from 'react-redux';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, Divider } from '@mui/material';
import DialogHeader from 'components/Chargeback/Modal/DialogHeader';
import DialogReasonContent from 'components/Chargeback/Modal/DialogReasonContent';
import { ChargeBackCancelButton, ChargeBackDisputeButton } from './styles';

const DisputeModal = ({
  open,
  handleClose,
  selectedReason,
  handleChange,
  formMethods,
  handleFormSubmit,
  handleStatusSwitch,
  handleStatus,
  loadingButton,
}) => {
  const { t } = useTranslation();
  const { chargeBack } = useSelector((store) => store);
  const { selected } = chargeBack;
  let currentStatus = handleStatus(selected?.status);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '538px',
          },
        },
      }}
    >
      <FormProvider {...formMethods}>
        <form autoComplete="off" onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <DialogHeader handleClose={handleClose} title={`Dispute - Order: #${selected?.orderNumber}`} />
          <DialogReasonContent
            handleChange={handleChange}
            selectedReason={selectedReason}
            handleStatusSwitch={handleStatusSwitch}
            currentStatus={currentStatus}
          />
          <Divider />
          <DialogActions sx={{ pt: 2, pb: 2, pl: 3, pr: 2 }}>
            <ChargeBackCancelButton onClick={handleClose}> {t('chargeBack.cancel')}</ChargeBackCancelButton>
            <ChargeBackDisputeButton
              loading={loadingButton}
              sx={{ width: '92px' }}
              type="submit"
              disabled={!formMethods.formState.isDirty && !formMethods.formState.isValid}
            >
              {t('chargeBack.dispute')}
            </ChargeBackDisputeButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default DisputeModal;
