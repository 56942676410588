import * as yup from 'yup';

export const formSchema = yup.object().shape({
  reason: yup.string().required('This field is required'),
  message: yup.string(),
  description: yup.string(),
});

export const formSchemaMessage = yup.object().shape({
  reason: yup.string().required('This field is required'),
  message: yup.string().required('This field is required'),
  description: yup.string(),
});
