import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useQuery from './useQuery';
import { getCurrentBusiness } from 'redux/actions/business';

const useUpdateBusiness = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const restaurantMailbox = query.get('restaurantmailbox');
  const alreadyLoaded = localStorage.getItem('alreadyLoaded');
  const oldRestaurantMailbox = localStorage.getItem('restaurantmailbox');
  const searchParams = new URLSearchParams(history.location.search);
  const { filterBrandList, currentBusiness } = useSelector((state) => state.business);

  useEffect(() => {
    if (restaurantMailbox) {
      searchParams.delete('pid');
      searchParams.delete('cid');
      searchParams.delete('mode');
      history.push({ pathname: history.location.pathname, search: searchParams.toString() });
    }
    filterBrandList?.forEach((brand) => {
      brand?.locations?.forEach((location) => {
        if (location.restaurantMailbox !== oldRestaurantMailbox) {
          localStorage.removeItem('alreadyLoaded');
        }
      });
    });

    if (filterBrandList && restaurantMailbox && !alreadyLoaded) {
      localStorage.setItem('restaurantmailbox', JSON.stringify(restaurantMailbox));
      localStorage.removeItem('currentTab');
      filterBrandList?.forEach((brand) => {
        brand?.locations?.forEach((location) => {
          if (location.restaurantMailbox === restaurantMailbox) {
            const modelSelectedLocation = {
              value: location,
              label: location?.locationName,
              name: `${location?.locationName}`,
              disabled: location?.disabled,
              verified: location?.verified,
            };
            const modelSelectedBrand = {
              id: brand._id,
              value: brand,
              label: brand?.brandName,
              name: `${brand?.brandName}`,
            };
            localStorage.setItem('selectedLocation', JSON.stringify(modelSelectedLocation));
            localStorage.setItem('selectedBrand', JSON.stringify(modelSelectedBrand));
            localStorage.setItem('alreadyLoaded', true);
            window.location.reload();
            dispatch(getCurrentBusiness(location?.businessId));
            localStorage.setItem(
              'businessInfo',
              JSON.stringify({
                label: ``,
                id: location?.businessId,
              })
            );
          }
        });
      });
    }
  }, [currentBusiness]);
};

export default useUpdateBusiness;
