import {
  SET_CURRENT_BUSINESSES,
  SET_TIMEZONE,
  SET_AVAILABILITY_CUSTOM,
  SET_CURRENT_BUSINESSES_DISABLE,
  SET_CURRENT_BUSINESSES_ENABLE,
  SET_CUISINES,
  SET_ALL_BRAND,
  SET_LOCATIONS_LIST,
  SET_CURRENT_BRAND,
  COLLECT_BUSINESS_IDS_FOR_REPORT,
  COLLECT_BUSINESS_IDS_FOR_HISTORY,
  IS_LOCATION_UNSELECT,
  SET_IS_LOADING,
  SET_CURRENT_SELECT_BRANDS,
  CLICK_APPLY_FILTER,
  SET_CURRENT_SELECT_LOCATIONS,
  SET_LOCATION_FOR_BRANDS,
  COLLECT_BRAND_BUSINESS_IDS_FOR_REPORT,
  COLLECT_BRAND_BUSINESS_IDS_FOR_HISTORY,
  SET_ALL_SELECTED_BRANDS,
  SET_ALL_SELECTED_LOCATION,
  SET_SHOW_ACTIVE_BUSINESS,
  ALL_BRANDS_WITHOUT_FILTER,
  ALL_BRANDS_OF_FILTER,
  SET_BUSINESS_CLASSIFICATION,
  SET_ALL_BUSINESS_GROUPS,
  SET_SELECTED_LOCATIONS,
  SET_SELECTED_BRANDS,
  ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP,
  ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP,
} from 'redux/actions/types';
import {
  convertAllLocationList,
  convertLocationListForAsyncLoadOption,
} from 'utils/convertLocationListForAsyncLoadOption';

import { convertToCorrectBusinessGroupFormat } from 'utils/business';

const initialState = {
  currentBusiness: {},
  currentSelectedBrand: {},
  timeZone: [],
  availability: [],
  cuisineType: [],
  brandList: [],
  loadingBrand: false,
  locationIdsForReport: [],
  brandIdsForReport: [],
  locationIdsForHistory: [],
  brandIdsForHistory: [],
  filterBrandList: [],
  unSelect: false,
  locationsList: [],
  isBrandLoading: false,
  currentSelectedBrands: [],
  currentSelectedLocations: [],
  applyFilter: false,
  allLocations: [],
  showOnlyActiveBusinesses:
    localStorage.getItem('showActiveBusiness') === null
      ? true
      : Boolean(Number(localStorage.getItem('showActiveBusiness'))),
  allBrandList: [],
  allBrandListFiltered: [],
  businessClassification: [],
  allBusinessGroups: [],
  selectedBrands: [],
  selectedLocations: [],
  allBrandsDependsOnBusinessGroup: [],
  allLocationsDependsOnBusinessGroup: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BUSINESS_CLASSIFICATION:
      return { ...state, businessClassification: payload };
    case SET_ALL_SELECTED_BRANDS:
      return { ...state, allSelectedBrand: payload };
    case SET_ALL_SELECTED_LOCATION:
      return { ...state, allSelectedLocation: payload };
    case CLICK_APPLY_FILTER:
      return { ...state, applyFilter: payload };
    case SET_CURRENT_SELECT_BRANDS:
      return { ...state, currentSelectedBrands: payload };
    case SET_CURRENT_SELECT_LOCATIONS:
      return { ...state, currentSelectedLocations: payload };
    case SET_IS_LOADING:
      return { ...state, isBrandLoading: payload };
    case COLLECT_BUSINESS_IDS_FOR_REPORT:
      return { ...state, locationIdsForReport: payload };
    case COLLECT_BUSINESS_IDS_FOR_HISTORY:
      return { ...state, locationIdsForHistory: payload };
    case COLLECT_BRAND_BUSINESS_IDS_FOR_REPORT:
      return { ...state, brandIdsForReport: payload };
    case COLLECT_BRAND_BUSINESS_IDS_FOR_HISTORY:
      return { ...state, brandIdsForHistory: payload };
    case IS_LOCATION_UNSELECT:
      return { ...state, unSelect: payload };
    case SET_CURRENT_BUSINESSES:
      return { ...state, currentBusiness: payload };
    case SET_CURRENT_BRAND:
      localStorage.setItem('selectedBrand', JSON.stringify({
        value: payload,
        label: payload.brandName,
        name: `${payload.brandName}`,
        id: payload?._id,
      }));
      return {
        ...state, currentSelectedBrand: {
          value: payload,
          label: payload.brandName,
          name: `${payload.brandName}`,
          id: payload?._id,
        }
      };
    case SET_CURRENT_BUSINESSES_DISABLE:
      return {
        ...state,
        currentBusiness: {
          ...state.currentBusiness,
          disabled: true,
        },
      };
    case SET_CURRENT_BUSINESSES_ENABLE:
      return {
        ...state,
        currentBusiness: {
          ...state.currentBusiness,
          disabled: false,
        },
      };
    case SET_CUISINES:
      return {
        ...state,
        cuisineType: payload,
      };
    case SET_TIMEZONE:
      return { ...state, timeZone: payload };
    case SET_AVAILABILITY_CUSTOM:
      return { ...state, availability: payload };
    case SET_ALL_BRAND:
      return { ...state, brandList: payload, filterBrandList: payload };
    case SET_LOCATIONS_LIST:
      return { ...state, locationsList: convertLocationListForAsyncLoadOption(payload) };
    case SET_LOCATION_FOR_BRANDS:
      return { ...state, allLocations: convertAllLocationList(payload) };
    case SET_SHOW_ACTIVE_BUSINESS:
      return {
        ...state,
        showOnlyActiveBusinesses: payload,
      };
    case ALL_BRANDS_WITHOUT_FILTER:
      return {
        ...state,
        allBrandList: payload,
      };
    case ALL_BRANDS_OF_FILTER:
      return {
        ...state,
        allBrandListFiltered: payload,
      };
    case SET_SELECTED_LOCATIONS:
      return { ...state, selectedLocations: payload };
    case SET_SELECTED_BRANDS:
      return { ...state, selectedBrands: payload };
    case SET_ALL_BUSINESS_GROUPS:
      return { ...state, allBusinessGroups: convertToCorrectBusinessGroupFormat(payload) };
    case ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP:
      return { ...state, allBrandsDependsOnBusinessGroup: payload };
    case ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP:
      return { ...state, allLocationsDependsOnBusinessGroup: payload };
    default:
      return state;
  }
};
