import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'helpers/formPrice';
import { Typography, Box, Popover } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { SubscriptionContext } from 'context/subscriptionPlanConfigContext';
import { useSubscriptionPlanChargeDateMessage } from 'components/ApplyCoupon/useSubscriptionPlanChargeDateMessage';
import { PaymentPeriodicityContext } from 'context/paymentPeriodicityContext';
import { PaymentPeriodicityEnum } from 'constants/config';

const SubscriptionPlanChargeDateMessage = ({
  selectedPlanData = null,
  selectedPlan,
  selectedPlanPrice,
  isBrand = false,
}) => {
  const { t } = useTranslation();
  const { periodicity } = useContext(PaymentPeriodicityContext);
  const { freeTrialIsEnabled, freeTrialPeriod } = useContext(SubscriptionContext);
  const {
    paymentDate,
    handlePopoverOpen,
    handlePopoverClose,
    open,
    selectedPrice,
    anchorEl,
  } = useSubscriptionPlanChargeDateMessage(selectedPlanData, selectedPlanPrice, selectedPlan, freeTrialPeriod);

  if (freeTrialIsEnabled && !isBrand) {
    return (
      <Box sx={{ display: selectedPlan ? 'flex' : 'none' }}>
        <Typography>
          {t('header.i_agree_charged')} {` `} <strong>{formatPrice(selectedPrice)} </strong>
          {periodicity === PaymentPeriodicityEnum.monthly
            ? `monthly, ${t('header.starting_on')} `
            : `annually, ${t('header.starting_on')} `}
          <strong>{paymentDate.format('MMMM Do, YYYY')}</strong>
          {t('header.trail_period_message', { day: freeTrialPeriod })}
          <Typography
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            sx={{ display: 'inline-block' }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <HelpOutlineIcon />
          </Typography>
        </Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
            marginLeft: '20px',
            marginTop: '-100px',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1, maxWidth: '320px', fontSize: '16px' }}>
            {t("settings.the_30-day_free_trial_applies_regardless_of_chosenon_1st_month's_payment.", {
              day: freeTrialPeriod,
            })}
          </Typography>
        </Popover>
      </Box>
    );
  } else if (!isBrand && !freeTrialIsEnabled) {
    return (
      <Box sx={{ display: selectedPlan ? 'flex' : 'none' }}>
        <Typography>
          {t('header.i_agree_charged')} {` `} <strong>{formatPrice(selectedPrice)} </strong>
          {periodicity === PaymentPeriodicityEnum.monthly ? 'monthly.' : 'annually.'}
        </Typography>
      </Box>
    );
  } else if (isBrand) {
    return (
      <Box sx={{ display: selectedPlan ? 'flex' : 'none' }}>
        <Typography>
          {t('header.i_agree_charged')} {` `} <strong>{formatPrice(selectedPrice)} </strong>
          {periodicity === PaymentPeriodicityEnum.monthly ? (
            <>
              {`monthly, ${t('header.starting_on')} `}
              <strong>{paymentDate.format('MMMM Do, YYYY')}</strong>
            </>
          ) : (
            'annually.'
          )}
        </Typography>
      </Box>
    );
  }
};

export default SubscriptionPlanChargeDateMessage;
