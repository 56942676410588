import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import CloseIconMui from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { classificationOptions } from 'constants/classifications';
import CustomTextInput from 'components/Forms/CustomTextInput';
import MultiSelectChips from 'components/Forms/MultiSelectChips';
import CustomCheckbox from 'components/Forms/CustomCheckbox';
import { GREATER_MSG, LESS_MSG, NAME_ENUMS } from 'constants/products';
import { useMatchMobile } from 'hooks/useMatchMobile';
import { useTranslation } from 'react-i18next';
import LocalizationPopover from 'components/LocalizationPopover';
import CustomSwitch from 'components/Forms/CustomSwitch';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useSelector } from 'react-redux';
import { useLocalizationPopover } from 'components/LocalizationPopover/hooks/useLocalizationPopover';
import InfoForCategory from 'assets/icons/infoForCategory.svg';
import dateIssue from 'assets/icons/dateIssue.svg';

const ProductForm = ({ connectedProviders, data, errorData, setErrorData, setDisable, productFormMethods }) => {
  const {
    open,
    handleClose,
    handleClickPopover,
    languageLimit,
    currentSelectedField,
    currentSelectedLabel,
    inputRef,
  } = useLocalizationPopover();
  const { currentBusiness } = useSelector(({ business }) => business);
  const {
    menu: { categories },
  } = useSelector(({ menu }) => menu);
  const { t } = useTranslation();

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault(currentBusiness.timezone?.timeZoneId);
  const startDate = data.popularStartDate
    ? dayjs(data.popularStartDate).tz(currentBusiness.timezone?.timeZoneId)['$d']
    : dayjs(new Date()).tz(currentBusiness.timezone?.timeZoneId)['$d'];

  const endDate = data.popularEndDate
    ? dayjs(data.popularEndDate).tz(currentBusiness.timezone?.timeZoneId)['$d']
    : dayjs(new Date()).tz(currentBusiness.timezone?.timeZoneId).add(6, 'month')['$d'];

  const [dateValue, setDateValue] = useState([startDate, endDate]);
  const [inputValue, setInputValue] = useState('');
  const { isMatchMobile } = useMatchMobile();
  const { setValue, getValues, reset, setError, clearErrors } = productFormMethods;
  const [startDateError, handleStartDateError] = useState(false);
  const [endDateError, handleEndDateError] = useState(false);
  const {
    watch,
    formState: { isDirty },
  } = productFormMethods;
  const permanent = watch('isPermanentlyPopular');

  const handleAddItem = (event) => {
    const newValue = event.target.value;
    if ((event.key === ',' || event.key === 'Enter') && newValue !== '') {
      productFormMethods.setValue('ingredients', [newValue, ...productFormMethods.getValues().ingredients], {
        shouldDirty: true,
      });
      setInputValue('');
    }
  };

  // const onChangeDatePicker = (value) => {
  //   // dayjs(value[0]).startOf('day').valueOf()
  //   setDateValue(value);
  //   setValue('popularStartDate', value[0], {
  //     shouldDirty: true,
  //   });
  //   setValue('popularEndDate', value[1], {
  //     shouldDirty: true,
  //   });
  //
  //   if (!value[0] && !permanent) {
  //     handleStartDateError(t('help.field_is_required'));
  //   } else {
  //     if (new Date(value[0]) == 'Invalid Date') {
  //       handleStartDateError(t('the_date_format_is_invalid_'));
  //     } else if (new Date(value[0]).getTime() > new Date(value[1]).getTime() && value[1]) {
  //       handleStartDateError(t('the_start_dates_'));
  //     } else {
  //       handleStartDateError('');
  //     }
  //   }
  //
  //   if (!value[1] && !permanent) {
  //     handleEndDateError(t('help.field_is_required'));
  //   } else {
  //     if (new Date(value[1]) == 'Invalid Date') {
  //       handleEndDateError(t('the_date_format_is_invalid_'));
  //     } else if (new Date(value[1]).getTime() < new Date(value[0]).getTime() && value[0]) {
  //       handleEndDateError(t('the_end_date_cannot_start_date_'));
  //     } else {
  //       handleEndDateError('');
  //     }
  //   }
  //
  //   reset(
  //     {
  //       ...data,
  //       popularStartDate: value[0],
  //       popularEndDate: value[1],
  //     },
  //     { keepDirty: true }
  //   );
  // };

  const handleChangeStartDate = (value) => {
    setValue('popularStartDate', value, {
      shouldDirty: true,
    });
    setDateValue([value, dateValue[1]]);
    if (!value && !permanent) {
      handleStartDateError(t('help.field_is_required'));
    } else {
      if (new Date(value) == 'Invalid Date') {
        handleStartDateError(t('the_date_format_is_invalid_'));
      } else if (
        new Date(value).getTime() > new Date(productFormMethods.getValues('popularEndDate')).getTime() &&
        productFormMethods.getValues('popularEndDate')
      ) {
        handleStartDateError(t('the_start_dates_'));
      } else {
        handleStartDateError('');
      }
    }
  };

  const handleChangeEndDate = (value) => {
    setValue('popularEndDate', value, {
      shouldDirty: true,
    });
    setDateValue([dateValue[0], value]);
    if (!value && !permanent) {
      handleEndDateError(t('help.field_is_required'));
    } else {
      if (new Date(value) == 'Invalid Date') {
        handleEndDateError(t('the_date_format_is_invalid_'));
      } else if (
        new Date(value).getTime() < new Date(productFormMethods.getValues('popularStartDate')).getTime() &&
        productFormMethods.getValues('popularStartDate')
      ) {
        handleEndDateError(t('the_end_date_cannot_start_date_'));
      } else {
        handleEndDateError('');
      }
    }
  };

  useEffect(() => {
    if (data.isPopular) {
      if (!data.popularEndDate) {
        setDateValue([dateValue[0], dayjs(new Date()).tz(currentBusiness.timezone?.timeZoneId).add(6, 'month')['$d']]);
        setValue('popularEndDate', dayjs(new Date()).add(6, 'month').tz(currentBusiness.timezone?.timeZoneId)['$d']);
      }

      if (!data.popularStartDate) {
        setDateValue([dayjs(new Date()).tz(currentBusiness.timezone?.timeZoneId)['$d'], dateValue[1]]);
        setValue('popularStartDate', dayjs(new Date()).tz(currentBusiness.timezone?.timeZoneId)['$d']);
      }
    }
  }, [data, currentBusiness]);

  useEffect(() => {
    if (permanent) {
      handleEndDateError('');
      handleStartDateError('');
    }
    if (productFormMethods.formState.isSubmitting) {
      if (!data.popularStartDate && !permanent && !startDate) {
        handleStartDateError(t('help.field_is_required'));
      } else {
        if (new Date(data.popularStartDate) == 'Invalid Date') {
          handleStartDateError(t('the_date_format_is_invalid_'));
        } else if (
          new Date(data.popularStartDate).getTime() > new Date(data.popularEndDate).getTime() &&
          data.popularEndDate
        ) {
          handleStartDateError(t('the_start_dates_'));
        } else {
          handleStartDateError('');
        }
      }

      if (!data.popularEndDate && !permanent && !endDate) {
        handleEndDateError(t('help.field_is_required'));
      } else {
        if (new Date(data.popularEndDate) == 'Invalid Date') {
          handleEndDateError(t('the_date_format_is_invalid_'));
        } else if (
          new Date(data.popularEndDate).getTime() < new Date(data.popularStartDate).getTime() &&
          data.popularStartDate
        ) {
          handleEndDateError(t('the_end_date_cannot_start_date_'));
        } else {
          handleEndDateError('');
        }
      }
    }
  }, [data, productFormMethods.formState, permanent, startDate, endDate]);

  useEffect(() => {
    if (data.isPopular) {
      if (startDateError || endDateError) {
        setDisable(!isDirty);
      } else {
        setDisable(false);
      }
    }
  }, [endDateError, startDateError]);

  const validateNumber = (value, name) => {
    const reg = /^\d+$/;
    if (reg.test(value) && value) {
      // clearErrors(name);
      return true;
    } else {
      if (value) {
        setErrorData({ [name]: { message: `Please enter a valid number ` } });
        setError(name, { message: `Please enter a valid number ` });
        return false;
      }
    }
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const allowedKeyCodes = [46, 8, 9, 27, 13, 110, 107, 109];
    if (allowedKeyCodes.indexOf(keyCode) === -1 && (e.shiftKey || keyCode < 48 || keyCode > 57)) {
      e.preventDefault();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event?.target;
    setValue(name, value, { shouldDirty: true });
    if (!value) {
      clearErrors(name);
      if (errorData?.hasOwnProperty(name)) {
        delete errorData[name];
      }
    }
    if (
      NAME_ENUMS.minQ === name ||
      NAME_ENUMS.maxQ === name ||
      NAME_ENUMS.minT === name ||
      NAME_ENUMS.maxT === name ||
      NAME_ENUMS.minTg === name ||
      NAME_ENUMS.maxTg === name
    ) {
      if (validateNumber(value, name)) {
        const minQ = Number(getValues().minAllowedQuantity);
        const maxQ = Number(getValues().maxAllowedQuantity);
        const minT = Number(getValues().minTotalNumberOfModifierItems);
        const maxT = Number(getValues().maxTotalNumberOfModifierItems);
        const minTg = Number(getValues().minTotalNumberOfModifierGroups);
        const maxTg = Number(getValues().maxTotalNumberOfModifierGroups);

        if (value) {
          if (Number(value) < 0 || value?.includes('-') || value?.includes('+')) {
            // setError(name, { message: `Please enter a valid number ` });
            // setErrorData({ [name]: { message: `Please enter a valid number ` } });
            return;
          }
          if (Number(value) === 0) {
            setError(name, { message: `${t('menu.min_number')}` });
            setErrorData({ [name]: { message: `${t('menu.min_number')}` } });
            return;
          }
        }

        switch (name) {
          case NAME_ENUMS.minQ:
            if (maxQ && minQ > maxQ) {
              setError(name, { message: `${t(GREATER_MSG)} ${maxQ}` });
              setErrorData({ [name]: { message: `${t(GREATER_MSG)} ${maxQ}` } });
            } else if (value > 100000) {
              setError(name, { message: `${t('menu.max_number')}` });
              setErrorData({ [name]: { message: `${t('menu.max_number')}` } });
            } else {
              clearErrors(NAME_ENUMS.maxQ);
              clearErrors(name);
              if (errorData?.hasOwnProperty(NAME_ENUMS.maxQ)) {
                delete errorData[NAME_ENUMS.maxQ];
              }
              if (errorData?.hasOwnProperty(name)) {
                delete errorData[name];
              }
            }
            break;
          case NAME_ENUMS.maxQ:
            if (maxQ && minQ > maxQ) {
              setError(name, { message: `${t(LESS_MSG)} ${minQ}` });
              setErrorData({ [name]: { message: `${t(LESS_MSG)} ${minQ}` } });
            } else if (value > 100000) {
              setError(name, { message: `${t('menu.max_number')}` });
              setErrorData({ [name]: { message: `${t('menu.max_number')}` } });
            } else {
              clearErrors(NAME_ENUMS.minQ);
              clearErrors(name);
              if (errorData?.hasOwnProperty(NAME_ENUMS.minQ)) {
                delete errorData[NAME_ENUMS.minQ];
              }
              if (errorData?.hasOwnProperty(name)) {
                delete errorData[name];
              }
            }
            break;
          case NAME_ENUMS.minT:
            if (maxT && minT > maxT) {
              setError(name, { message: `${t(GREATER_MSG)} ${maxT}` });
              setErrorData({ [name]: { message: `${t(GREATER_MSG)} ${maxT}` } });
            } else if (value > 100000) {
              setError(name, { message: `${t('menu.max_number')}` });
              setErrorData({ [name]: { message: `${t('menu.max_number')}` } });
            } else {
              clearErrors(NAME_ENUMS.maxT);
              clearErrors(name);
              if (errorData?.hasOwnProperty(NAME_ENUMS.maxT)) {
                delete errorData[NAME_ENUMS.maxT];
              }
              if (errorData?.hasOwnProperty(name)) {
                delete errorData[name];
              }
            }
            break;
          case NAME_ENUMS.maxT:
            if (minT && minT > maxT) {
              setError(name, { message: `${t(LESS_MSG)} ${minT}` });
              setErrorData({ [name]: { message: `${t(LESS_MSG)} ${minT}` } });
            } else if (value > 100000) {
              setError(name, { message: `${t('menu.max_number')}` });
              setErrorData({ [name]: { message: `${t('menu.max_number')}` } });
            } else {
              clearErrors(NAME_ENUMS.minT);
              clearErrors(name);
              if (errorData?.hasOwnProperty(NAME_ENUMS.minT)) {
                delete errorData[NAME_ENUMS.minT];
              }
              if (errorData?.hasOwnProperty(name)) {
                delete errorData[name];
              }
            }
            break;
          case NAME_ENUMS.minTg:
            if (maxTg && minTg > maxTg) {
              setError(name, { message: `${t(GREATER_MSG)} ${maxTg}` });
              setErrorData({ [name]: { message: `${t(GREATER_MSG)} ${maxTg}` } });
            } else if (value > 100000) {
              setError(name, { message: `${t('menu.max_number')}` });
              setErrorData({ [name]: { message: `${t('menu.max_number')}` } });
            } else {
              clearErrors(NAME_ENUMS.maxTg);
              clearErrors(name);
              if (errorData?.hasOwnProperty(NAME_ENUMS.maxTg)) {
                delete errorData[NAME_ENUMS.maxTg];
              }
              if (errorData?.hasOwnProperty(name)) {
                delete errorData[name];
              }
            }
            break;
          case NAME_ENUMS.maxTg:
            if (minTg && minTg > maxTg) {
              setError(name, { message: `${t(LESS_MSG)} ${minTg}` });
              setErrorData({ [name]: { message: `${t(LESS_MSG)} ${minTg}` } });
            } else if (value > 100000) {
              setError(name, { message: `${t('menu.max_number')}` });
              setErrorData({ [name]: { message: `${t('menu.max_number')}` } });
            } else {
              clearErrors(NAME_ENUMS.minTg);
              clearErrors(name);
              if (errorData?.hasOwnProperty(NAME_ENUMS.minTg)) {
                delete errorData[NAME_ENUMS.minTg];
              }
              if (errorData?.hasOwnProperty(name)) {
                delete errorData[name];
              }
            }
            break;
          default:
            break;
        }
      }
    }
  };

  const handleBlur = () => {
    if (Object.keys(productFormMethods.formState.errors)?.length) {
      return;
    }
  };

  const connectedCount = useMemo(() => {
    return (
      Object.values(connectedProviders || {})?.filter((item, index) => {
        return item;
      }).length - 1
    );
  }, [connectedProviders]);

  return (
    <>
      <Box sx={{ px: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CustomTextInput
              inputProps={{ type: 'number' }}
              min="0"
              name="proteins"
              label={t('menu.proteins')}
              size="small"
              sx={{ width: '100%', mt: 1 }}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInput
              inputProps={{ type: 'number' }}
              min="0"
              name="carbs"
              label={t('menu.carbs')}
              size="small"
              sx={{ width: '100%', mt: 1 }}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInput
              inputProps={{ type: 'number' }}
              min="0"
              name="calories"
              label={t('menu.calories')}
              size="small"
              sx={{ width: '100%', mt: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectChips
              sx={{ width: '100%', mt: 2 }}
              name="tags"
              label={t('menu.classification')}
              options={classificationOptions}
              multiple
              defaultValues={data.tags || []}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="ingredients"
              render={({ field }) => {
                const { ref, onChange } = field;
                if (typeof field.value === 'object' && field.value?.find((item) => item?.name)) {
                  field.value =
                    field.value.map((item) => {
                      if (item?._id) {
                        return item?.name;
                      } else {
                        return item;
                      }
                    }) || [];
                } else {
                  field.value = field.value ? field.value : '';
                }

                return (
                  <Autocomplete
                    {...field}
                    multiple
                    size="small"
                    id="multiple-limit-tags"
                    options={[]}
                    freeSolo
                    clearIcon={
                      <CloseIconMui
                        fontSize="small"
                        onClick={() => {
                          productFormMethods.setValue('ingredients', [], { shouldDirty: true });
                        }}
                      />
                    }
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    onKeyDown={handleAddItem}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => {
                        return (
                          <Chip
                            variant="outlined"
                            sx={{
                              background: '#ebebeb',
                              border: 'none',
                            }}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );
                      });
                    }}
                    renderInput={(params) => {
                      params.inputProps.value = inputValue.replace(',', '');
                      return (
                        <TextField
                          InputProps={{
                            rows: 3,
                          }}
                          onChange={(event) => {
                            const valueWithComma = event.target.value.split(',');
                            if (valueWithComma.length > 1 && event.target.value !== ',') {
                              productFormMethods.setValue(
                                'ingredients',
                                [...valueWithComma, ...productFormMethods.getValues().ingredients],
                                {
                                  shouldDirty: true,
                                }
                              );
                              setInputValue('');
                            } else {
                              setInputValue(event.target.value);
                            }
                          }}
                          {...params}
                          inputRef={ref}
                          InputLabelProps={{ shrink: true }}
                          label={t('menu.type_your_ingredients')}
                        />
                      );
                    }}
                    sx={{ width: '100%', mr: 2 }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box onClick={(event) => handleClickPopover(event, 'kitchenInstruction', 'menu.kitchen_instruction')}>
              <CustomTextInput
                inputProps={{ type: 'text' }}
                name="kitchenInstruction"
                label={t('menu.kitchen_instruction')}
                size="small"
                multiline={true}
                rows={3}
                sx={{ width: '100%', mt: 1 }}
                InputProps={{
                  endAdornment: (
                    <LocalizationPopover
                      values={productFormMethods.getValues()}
                      id={data._id}
                      required={false}
                      field="kitchenInstruction"
                      label={currentSelectedLabel}
                      open={open}
                      handleClose={handleClose}
                      languageLimit={languageLimit}
                      currentSelectedField={currentSelectedField}
                      productFormMethods={productFormMethods}
                    />
                  ),
                  inputRef: inputRef,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              inputProps={{ type: 'text' }}
              name="recipe"
              label={t('menu.recipe')}
              size="small"
              multiline={true}
              rows={3}
              sx={{ width: '100%', mt: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomCheckbox
              checked={!data.onItsOwn}
              disabled={true}
              sx={{ mt: 2 }}
              label={t('menu.only_used_modifier_option')}
              name="onItsOwn"
            />
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        spacing={2}
        style={
          {
            // opacity: 0.6,
            // pointerEvents: 'none',
          }
        }
        className="item-quantity"
        sx={{ px: 4 }}
      >
        <Grid item xs={6} sx={{ height: isMatchMobile ? 70 : 65 }}>
          <CustomTextInput
            name="minAllowedQuantity"
            label={t('menu.min_allowed_quantity_label')}
            size="small"
            sx={{ width: '100%', mt: 1 }}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6} sx={{ height: isMatchMobile ? 70 : 65 }}>
          <CustomTextInput
            name="maxAllowedQuantity"
            label={t('menu.max_allowed_quantity_label')}
            size="small"
            sx={{ width: '100%', mt: 1 }}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6} sx={{ height: isMatchMobile ? 70 : 65 }}>
          <CustomTextInput
            name="minTotalNumberOfModifierGroups"
            label={t('menu.min_total_number_modifier_group_label')}
            size="small"
            sx={{ width: '100%', mt: 1 }}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6} sx={{ height: isMatchMobile ? 70 : 65 }}>
          <CustomTextInput
            name="maxTotalNumberOfModifierGroups"
            label={t('menu.max_total_number_modifier_group_label')}
            size="small"
            sx={{ width: '100%', mt: 1 }}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6} sx={{ height: isMatchMobile ? 70 : 65 }}>
          <CustomTextInput
            name="minTotalNumberOfModifierItems"
            label={t('menu.min_total_number_modifier_label')}
            size="small"
            sx={{ width: '100%', mt: 1 }}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={6} sx={{ height: isMatchMobile ? 70 : 65 }}>
          <CustomTextInput
            name="maxTotalNumberOfModifierItems"
            label={t('menu.max_total_number_modifier_label')}
            size="small"
            sx={{ width: '100%', mt: 1 }}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>

      {data.onItsOwn ? (
        <Grid container spacing={2} sx={{ px: 4, mt: 0.5 }}>
          <Grid item xs={12} className="">
            <CustomSwitch name="isPopular" checked={data.isPopular} label={t('menu.make_item_popular')} />
          </Grid>
          {data.isPopular ? (
            <>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <FormControl sx={{ width: '100%' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box>
                      {startDateError && (
                        <Tooltip title={startDateError} placement="top" arrow>
                          <img
                            width={16}
                            height={16}
                            alt="Date Issue Icon"
                            src={dateIssue}
                            style={{ top: '12px', position: 'absolute', zIndex: 1, left: '13.5px' }}
                          />
                        </Tooltip>
                      )}
                      <DatePicker
                        sx={{
                          width: '100%',
                          mb: 1,
                          pointerEvents: permanent ? 'none' : 'auto',
                          input: { padding: `8px 0 8px ${startDateError ? '35px' : '12px'}` },
                          fieldset: { borderColor: startDateError ? '#E21508 !important' : '' },
                        }}
                        name="popularStartDate"
                        value={dateValue[0]}
                        disabled={permanent}
                        onChange={handleChangeStartDate}
                        label={
                          <span
                            style={{ backgroundColor: '#FFF' }}
                            className={`${startDateError ? 'text-danger' : ''}`}
                          >
                            {t('website_settings.start_date')}
                          </span>
                        }
                      />
                    </Box>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <FormControl sx={{ width: '100%' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box>
                      {endDateError && (
                        <Tooltip title={endDateError} placement="top" arrow>
                          <img
                            width={16}
                            height={16}
                            alt="Date Issue Icon"
                            src={dateIssue}
                            style={{ top: '12px', position: 'absolute', zIndex: 1, left: '13.5px' }}
                          />
                        </Tooltip>
                      )}
                      <DatePicker
                        sx={{
                          width: '100%',
                          mb: 1,
                          pointerEvents: permanent ? 'none' : 'auto',
                          input: { padding: `8px 0 8px ${endDateError ? '35px' : '12px'}` },
                          fieldset: { borderColor: endDateError ? '#E21508 !important' : '' },
                        }}
                        name="popularEndDate"
                        value={dateValue[1]}
                        disabled={permanent}
                        onChange={handleChangeEndDate}
                        label={
                          <span style={{ backgroundColor: '#FFF' }} className={`${endDateError ? 'text-danger' : ''}`}>
                            {t('website_settings.end_date')}
                          </span>
                        }
                      />
                    </Box>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item sx={12} lg={12} xl={12} md={12}>
                <CustomCheckbox name="isPermanentlyPopular" label={t('menu.permanent')} />
              </Grid>
            </>
          ) : null}
        </Grid>
      ) : null}

      {/* TODO: Will open after backen  finished update product category*/}

      {currentBusiness?.roles?.name === 'super' && data.onItsOwn && (
        <Box
          sx={{
            opacity: connectedCount ? 0.6 : 1,
            pointerEvents: connectedCount ? 'none' : 'auto',
          }}
        >
          <Divider sx={{ mt: 4 }} />
          <Box sx={{ p: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography fontWeight={500} lineHeight="18.75px">
                  {t('menu.item_category')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomTextInput
                  sx={{ width: '100%' }}
                  select={true}
                  name="category"
                  InputLabelProps={{ shrink: true }}
                  label={t('menu.primary_category')}
                >
                  {categories?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </CustomTextInput>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ width: '100%' }}
                  select={true}
                  name="category"
                  disabled={true}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  label={t('menu.other_categories')}
                ></TextField>
              </Grid>
              {connectedCount ? (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      background: 'rgba(146, 147, 152, 0.08)',
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '4px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                    >
                      <img
                        src={InfoForCategory}
                        style={{
                          marginTop: '2px',
                        }}
                      />
                      <Typography
                        fontSize={14}
                        lineHeight="20px"
                        sx={{
                          color: 'rgba(15, 18, 20, 0.6)',
                          marginLeft: '12px',
                        }}
                      >
                        {t('menu.categoryDisableDesc')}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProductForm;
