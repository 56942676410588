import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { disputeSchema } from './disputeSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { changeDisputeSettings } from 'redux/actions/business';

const defaultDisputeBusinessSettings = {
  enabled: false,
  autoDispute: false,
  emailNotification: false,
};
export const useDispute = () => {
  const dispatch = useDispatch();
  const { currentBusiness, isBrandLoading } = useSelector(({ business }) => business);
  const [loading, setLoading] = useState(false);
  const [checkedDispute, setCheckedDispute] = useState(defaultDisputeBusinessSettings.enabled);
  const [checkedAutoDispute, setCheckedAutoDispute] = useState(defaultDisputeBusinessSettings.autoDispute);
  const [checkEmailNotification, setCheckEmailNotification] = useState(defaultDisputeBusinessSettings.autoDispute);
  const businessDisputeSettings = useForm({
    defaultValues: defaultDisputeBusinessSettings,
    mode: 'all',
    resolver: yupResolver(disputeSchema),
  });

  const setCurrentBusinessSettings = (business) => {
    if (business?.settings && business?.settings?.disputeSettings) {
      const isEnabled = business?.settings?.disputeSettings?.hasOwnProperty('enabled')
        ? business?.settings?.disputeSettings?.enabled
        : defaultDisputeBusinessSettings.enabled;
      const isAutoDispute = business?.settings?.disputeSettings?.hasOwnProperty('autoDispute')
        ? business?.settings?.disputeSettings?.autoDispute
        : defaultDisputeBusinessSettings.autoDispute;
      const isEmailNotification = business?.settings?.disputeSettings?.hasOwnProperty('emailNotification')
        ? business?.settings?.disputeSettings?.emailNotification
        : defaultDisputeBusinessSettings.emailNotification;
      businessDisputeSettings.reset({
        enabled: isEnabled,
        autoDispute: isAutoDispute,
        emailNotification: isEmailNotification,
      });
      setCheckedDispute(isEnabled);
      setCheckedAutoDispute(isAutoDispute);
      setCheckEmailNotification(isEmailNotification);
    } else {
      businessDisputeSettings.reset({
        enabled: defaultDisputeBusinessSettings.enabled,
        autoDispute: defaultDisputeBusinessSettings.autoDispute,
        emailNotification: defaultDisputeBusinessSettings.emailNotification,
      });
      setCheckedDispute(defaultDisputeBusinessSettings.enabled);
      setCheckedAutoDispute(defaultDisputeBusinessSettings.autoDispute);
      setCheckEmailNotification(defaultDisputeBusinessSettings.emailNotification);
    }
  };

  useEffect(() => {
    if (currentBusiness) {
      setCurrentBusinessSettings(currentBusiness);
    }
  }, [currentBusiness]);

  const handleChangeDispute = (event) => {
    setCheckedDispute(event.target.checked);
    businessDisputeSettings.setValue('enabled', event.target.checked, { shouldDirty: true });
  };

  const handleChangeAutoDispute = (event) => {
    setCheckedAutoDispute(event.target.checked);
    businessDisputeSettings.setValue('autoDispute', event.target.checked, { shouldDirty: true });
  };

  const handleChangeEmailNotification = (event) => {
    setCheckEmailNotification(event.target.checked);
    businessDisputeSettings.setValue('emailNotification', event.target.checked, { shouldDirty: true });
  };

  const handleDisputeSettingsSubmit = (values) => {
    setLoading(true);
    const data = {
      enabled: values.enabled,
      autoDispute: !values.enabled ? values.enabled : values.autoDispute,
      emailNotification: !values.enabled ? values.enabled : values.emailNotification,
    };
    dispatch(changeDisputeSettings(data))
      .then((res) => {
        setCurrentBusinessSettings(res?.data?.data);
        toast.success('Dispute settings changed successfully!');
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || 'Dispute settings failed!');
      })
      .finally(() => setLoading(false));
  };

  return {
    businessDisputeSettings,
    isBrandLoading,
    handleDisputeSettingsSubmit,
    checkedAutoDispute,
    checkedDispute,
    handleChangeDispute,
    handleChangeAutoDispute,
    checkEmailNotification,
    handleChangeEmailNotification,
    loading,
  };
};
