import {
  GooglePay,
  GooglePayBox,
  GooglePayLink,
  GooglePayLinkLargeText,
  GooglePayLinkSmallText,
  GooglePayLinkWrapper,
} from './styles';
import GooglePlayIcon from 'assets/images/Playstore.png';
import { useTranslation } from 'react-i18next';
const GooglePayButton = () => {
  const { t } = useTranslation();
  const handleClick = (url) => window.open(url, '_blank');

  return (
    <GooglePay
      startIcon={<img src={GooglePlayIcon} alt={'GooglePay'} />}
      onClick={() => handleClick('https://play.google.com/store/apps/details?id=co.orders.ordermanager.lite&pli=1')}
    >
      <GooglePayLink
        href="https://play.google.com/store/apps/details?id=co.orders.ordermanager.lite&pli=1"
        color="inherit"
        target={'_blank'}
      >
        <GooglePayLinkWrapper>
          <GooglePayLinkSmallText>{t('sign_in.googlePlaySmallText')}</GooglePayLinkSmallText>
          <GooglePayBox>
            <GooglePayLinkLargeText>{t('sign_in.googlePlayLargeText')}</GooglePayLinkLargeText>
            <GooglePayLinkLargeText>{t('sign_in.googlePlayLargeTextPlay')}</GooglePayLinkLargeText>
          </GooglePayBox>
        </GooglePayLinkWrapper>
      </GooglePayLink>
    </GooglePay>
  );
};
export default GooglePayButton;
