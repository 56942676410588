import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ButtonWrap } from './styles';

const ActionsFlat = ({ indexItem, flatFeeFields, id }) => {
  const onAdd = () => {
    if (flatFeeFields?.fields?.[0]) {
      flatFeeFields.append(flatFeeFields?.fields?.[0]);
    }
  };

  const onDelete = (index) => {
    const findIndex = flatFeeFields?.fields?.findIndex((it) => it.id === index);
    if (findIndex !== -1) {
      flatFeeFields.remove(findIndex);
    }
  };

  return indexItem === 0 ? (
    <ButtonWrap id="productFormAddIcon" onClick={onAdd}>
      <AddIcon />
    </ButtonWrap>
  ) : (
    <ButtonWrap id="productFormDeleteIcon" onClick={() => onDelete(id)}>
      <DeleteOutlineIcon />
    </ButtonWrap>
  );
};

export default ActionsFlat;
