import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useHistory, useLocation } from 'react-router-dom';
import Private from 'components/Private';
import { useTranslation } from 'react-i18next';

const FormFooter = ({ isDisable, mode }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const disabled = mode === 'edit' ? !isDisable : false;

  return (
    <Box
      sx={{
        py: 3,
        px: 4,
        borderTop: 1,
        borderColor: 'divider',
      }}
    >
      <Private permission="MENU_EDIT">
        <Button id="formFooterSave" disabled={disabled} type="submit" variant="contained" sx={{ mr: 1 }}>
          {mode === 'edit' ? t('website_settings.update') : t('settings.save')}
        </Button>
      </Private>
      <Button id="formFooterCancel" onClick={() => history.push(location.pathname)}>
        {t('orders.cancel')}
      </Button>
    </Box>
  );
};

export default FormFooter;
