import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import {
  DialogActionsButton,
  DialogActionsContentBox,
  DialogContentBox,
  DialogTextProvider,
  DialogTextSubProvider,
} from 'components/Confirm/styles';

const InformationModal = ({ open, handleClose, icon, iconName, title, description }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{
        sx: { width: '764px', height: '670px', maxWidth: '100%', background: '#FAFAFA' },
      }}
    >
      <DialogContentBox>
        <img src={icon} alt={iconName} />
        <DialogTextProvider>{t(title)}</DialogTextProvider>
        <DialogTextSubProvider>{`${t(description)}`}</DialogTextSubProvider>
      </DialogContentBox>
      <DialogActionsContentBox>
        <DialogActionsButton variant="contained" onClick={handleClose}>
          {t('settings.ok')}
        </DialogActionsButton>
      </DialogActionsContentBox>
    </Dialog>
  );
};

export default InformationModal;
