import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useFilteredProviders = () => {
  const { items } = useSelector(({ providers }) => providers);

  const filteredProviders = useMemo(() => {
    const activeGroups = {};

    const filteredItems = items.filter((item) => item.srv);


    filteredItems.forEach((provider) => {
      const srv = provider?.srv;
      const isInGroup = Object.keys(activeGroups)?.some((group) => activeGroups[group]?.includes(srv));
      if (!isInGroup) {
        activeGroups[srv] = [srv];
      }
    });
    return activeGroups;
  },[items])

  return {
    filteredProviders
  }
}