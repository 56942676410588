import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import PinCode from 'components/PauseResume/VerificationCodeModal';
import { Dialog, DialogContent, IconButton, Divider, DialogTitle } from '@mui/material';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';

const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ fontSize: '38px', color: '#000000', opacity: '80%' }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const PauseResumePinCode = ({ showPinModal, handleClosePinModal, pinRef, onCompleteCode }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={showPinModal}
      onClose={handleClosePinModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{
        sx: { width: '764px', height: '670px', maxWidth: '100%', background: '#FAFAFA' },
      }}
    >
      <CustomDialogTitle id="alert-dialog-title" onClose={handleClosePinModal} sx={{ fontSize: '24px', fontWeight: '550' }}>
        {t('orders.store_status')}
      </CustomDialogTitle>
      <Divider />
      <DialogContent sx={{ width: '100%' }}>
        <PinCode pinRef={pinRef} onCompleteCode={onCompleteCode} />
      </DialogContent>
    </Dialog>
  )
}

export default memo(PauseResumePinCode)