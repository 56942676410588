import { useContext, useState } from 'react';
import { PaymentPeriodicityContext } from 'context/paymentPeriodicityContext';
import { PaymentPeriodicityEnum } from 'constants/config';
import { trialVersionDate } from 'utils/soldOut';

export const useSubscriptionPlanChargeDateMessage = (
  selectedPlanData = null,
  selectedPlanPrice,
  selectedPlan,
  freeTrialPeriod
) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const { periodicity } = useContext(PaymentPeriodicityContext);
  const selected = selectedPlanData?.find((item) => item._id === selectedPlan) || null;
  const selectedPrice =
    (periodicity === PaymentPeriodicityEnum.monthly && selected ? selected?.priceMonthly : selected?.priceYearly) ||
    selectedPlanPrice;

  const paymentDate = trialVersionDate(freeTrialPeriod);
  return {
    paymentDate,
    handlePopoverOpen,
    handlePopoverClose,
    open,
    selectedPrice,
    anchorEl,
    setAnchorEl,
  };
};
