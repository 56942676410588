import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuEmptyImg from 'assets/images/menu.png';
import { useTranslation } from 'react-i18next';

const MenuEmpty = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={MenuEmptyImg} />
      <Typography sx={{ fontSize: '16px', fontWeight: '400', mt: 4 }}>
        {t('menu.select_item_from_the_review')}
      </Typography>
      <Typography sx={{ fontSize: '14px', fontWeight: '300', mt: 1, textAlign: 'center' }}>
        {t('menu.reduce_your_busywork_next_level')}
      </Typography>
    </Box>
  );
};

export default MenuEmpty;
