import { Box, Tab, Tabs, Typography } from '@mui/material';
import { PaymentPeriodicityEnum } from 'constants/config';
import useQuery from 'hooks/useQuery';
// import { PaymentPeriodicityContext } from 'context/paymentPeriodicityContext';
import { useEffect, useState } from 'react';
import { getUserBilling } from 'redux/actions/user';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

const SubscriptionPlanPeriodicityTabs = ({
  handleChangePeriod,
  periodicity,
  setLoading = () => {},
  setLoadingBrand = () => {},
  isDisabled = false,
  selectedPlan = '',
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [value, setValue] = useState(1);
  const query = useQuery();
  const businessId = query.get('businessId');
  const legalEntityId = query.get('legalEntityId');
  const userId = query.get('userId');
  const planId = query.get('planId');
  const periodicityParam = query.get('term');

  // const history = useHistory();
  // const query = useQuery();
  // const businessId = query.get('businessId');
  // const legalEntityId = query.get('legalEntityId');
  // const userId = query.get('userId');
  // const planId = query.get('planId');

  const isInBilling = location.pathname.includes('settings/billing');
  const isInAddBrand = location.pathname.includes('addBrand');
  const isInSignUp = location.pathname.includes('signup/billingInfo');

  const handleSetTab = (value) => {
    setValue(Number(value));
    if (value) {
      handleChangePeriod(PaymentPeriodicityEnum.yearly);
    } else {
      handleChangePeriod(PaymentPeriodicityEnum.monthly);
    }
  };

  useEffect(() => {
    if (isInSignUp && periodicityParam) {
      handleSetTab(periodicityParam === PaymentPeriodicityEnum.monthly ? 0 : 1);
    } else {
      handleSetTab(periodicity === PaymentPeriodicityEnum.monthly ? 0 : 1);
    }
  }, []);

  useEffect(() => {
    if (isDisabled) {
      handleSetTab(0);
    } else {
      if (!periodicityParam) {
        handleSetTab(1);
      } else {
        if (periodicityParam !== PaymentPeriodicityEnum.monthly) {
          handleSetTab(1);
        }
      }
    }
    // if (!isInBilling && !isInAddBrand) {
    // if (!isInSignUp) {
    //   if (isDisabled) {
    //     handleSetTab(0);
    //   } else {
    //     handleSetTab(1);
    //   }
    // } else {
    //   if (isDisabled) {
    //     handleSetTab(0);
    //   } else {
    //     if (periodicityParam !== PaymentPeriodicityEnum.monthly) {
    //       handleSetTab(1);
    //     }
    //   }
    // }
    // }
  }, [isDisabled]);

  useEffect(() => {
    if (isInAddBrand) {
      setLoadingBrand(true);
      setLoading(true);
      getUserBilling().then(({ data: { data } }) => {
        handleSetTab(data.paymentTerm === PaymentPeriodicityEnum.monthly ? 0 : 1);
        setLoading(false);
        setTimeout(() => {
          setLoadingBrand(false);
        }, 300);
      });
    }
  }, []);

  const handleChangeTab = (e, newValue) => {
    if (isInSignUp) {
      const periodicityParam = newValue ? PaymentPeriodicityEnum.yearly : PaymentPeriodicityEnum.monthly;
      let url =
        window.origin +
        history.location.pathname +
        `?businessId=${businessId}&legalEntityId=${legalEntityId}&userId=${userId}&term=${periodicityParam}`;
      if (planId || selectedPlan) {
        url += `&planId=${planId || selectedPlan}`;
      }
      window.history.pushState({ path: url }, '', url);
    }
    handleSetTab(newValue);
  };

  const disableTab = isInBilling || isInAddBrand || isDisabled;

  if (disableTab) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        my: 4,
      }}
    >
      <Tabs
        sx={{
          textTransform: 'none',
          background: '#f1f1f1',

          borderRadius: '8px',
          color: 'black',
          padding: 0.5,
          '& .MuiTab-root': {
            textTransform: 'none',
          },
          '& .Mui-selected': {
            background: 'white',
            borderRadius: '8px',
            color: disableTab ? '#747474' : 'black',
            fontWeight: 'bold',
          },
          '& .MuiTabs-indicator': { display: 'none' },
        }}
        value={value}
        onChange={handleChangeTab}
        centered
      >
        <Tab disabled={disableTab} label={t('sign_up.pay_monthly')} />
        <Tab disabled={disableTab} label={t('sign_up.pay_yearly')} />
      </Tabs>
      <Typography sx={{ my: 2, fontWeight: 'bold' }}>
        {t('settings.pay_yearly')}
        <Box component={'span'} sx={{ color: 'green' }}>
          {t('settings.save_17%')}
        </Box>
      </Typography>
    </Box>
  );
};

export default SubscriptionPlanPeriodicityTabs;
