import { useMemo, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { useSelector, useDispatch } from 'react-redux';
import Preloader from 'components/Preloader';
import StyledDataGrid from 'components/DataGridTable';
import { getPopularItems } from 'redux/actions/Menu/products';
import FormProductPage from './FormProductPage';
import useQuery from 'hooks/useQuery';
import LinkedProviderPopover from 'components/MenuNew/LinkedProviderPopover';
import CheckActive from 'assets/icons/checkbox_active.svg';
import CheckInactive from 'assets/icons/checkbox_inactive.svg';
import { filteringSoldOut } from 'utils/soldOut';
import Private from 'components/Private';
import { SOLD_OUT_ENUM, SOLD_OUT_STATUS } from 'constants/enumSoldOut';
import { useTranslation } from 'react-i18next';

const PopularItems = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const query = useQuery();
  const entityType = query.get('entityType');
  const mode = query.get('mode');
  const dispatch = useDispatch();
  const { menu, loading } = useSelector(({ menu }) => menu);
  const { productsAll } = menu;
  const { currentBusiness } = useSelector(({ business }) => business);
  const [popularItems, setPopularItems] = useState('');
  const [pageSize, setPageSize] = useState(5);
  const columns = useMemo(
    () => [
      {
        field: 'image',
        headerName: t('menu.image'),
        sortable: false,
        filterable: false,
        editable: false,
        disableColumnMenu: true,
        renderCell: ({ value }) => {
          return (
            <Avatar
              sx={{ bgcolor: '#E2E2E2', width: '40px', height: '40px' }}
              alt={''}
              src={`${value?.url}?tr=w-48,h-48`}
            >
              <Icon
                sx={{
                  color: '#323232',
                }}
                component={ImageOutlinedIcon}
              />
            </Avatar>
          );
        },
      },
      {
        field: 'name',
        headerName: t('Name'),
        sortable: true,
        flex: 1,
        editable: false,
        filterable: true,
        renderCell: ({ row }) => {
          const { name, id, _id, category } = row;

          return (
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                history.push({
                  search: `?entityType=item&pid=${_id}&cid=${category?._id || category || uuidv4()}&mode=edit`,
                  state: { ...row },
                });
              }}
            >
              <Typography
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                    textUnderlineOffset: '3px',
                  },
                }}
              >
                {name}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'price',
        headerName: t('menu.base_price'),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: true,
        renderCell: ({ value }) => {
          return `$ ${value}`;
        },
      },
      {
        field: 'createdAt',
        headerName: t('menu.created_at'),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: true,
        renderCell: ({ value }) => {
          return value ? moment(value).format('MM-DD-YYYY') : '';
        },
      },
      {
        field: 'updatedAt',
        headerName: t('menu.updated_at'),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: true,
        renderCell: ({ value }) => {
          return value ? moment(value).format('MM-DD-YYYY') : '';
        },
      },
      {
        field: 'connectedProviders',
        headerName: t('menu.providers'),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: true,
        renderCell: ({ row }) => {
          const { connectedProviders, _id, category, onItsOwn } = row;
          return (
            <Private permission="MENU_EDIT">
              <LinkedProviderPopover
                disabled={!onItsOwn}
                categoryId={category?.id || category}
                targetConnectedProviders={
                  category?.id || category ? category?.connectedProviders : menu.connectedProviders
                }
                connectedProviders={connectedProviders}
                id={_id}
                target="item"
                targetData={row}
              />
            </Private>
          );
        },
      },
      {
        field: 'category',
        headerName: t('menu.category'),
        sortable: true,
        flex: 1,
        editable: false,
        filterable: true,
        renderCell: ({ row }) => {
          const { category } = row;
          return <Typography>{category?.name}</Typography>;
        },
      },
      {
        field: 'onItsOwn',
        headerName: t('menu.only_used_modifier_option'),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: true,
        renderCell: ({ row }) => {
          const { onItsOwn } = row;
          return <img src={!onItsOwn ? CheckActive : CheckInactive} alt="ONLINE_ICON" />;
        },
      },
      {
        field: 'soldOut',
        headerName: t('menu.availability').charAt(0).toUpperCase() + t('menu.availability').slice(1),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: true,
        renderCell: ({ row }) => {
          let nameSoldOut = SOLD_OUT_ENUM.availability;
          switch (filteringSoldOut(row, currentBusiness, moment)) {
            case SOLD_OUT_STATUS.SOLD_OUT_TODAY:
              nameSoldOut = SOLD_OUT_ENUM.soldOutToday;
              break;
            case SOLD_OUT_STATUS.SOLD_OUT_PERMANENT:
              nameSoldOut = SOLD_OUT_ENUM.soldOutPermanent;
              break;
            default:
              break;
          }
          return (
            <Tooltip title={t(nameSoldOut) || ''} placement="top">
              <Box
                sx={{
                  display: 'block',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: '180px',
                }}
              >
                {t(nameSoldOut)}
              </Box>
            </Tooltip>
          );
        },
      },
    ],
    [productsAll, t]
  );

  useEffect(() => {
    dispatch(getPopularItems()).then(({ data }) => {
      setPopularItems(data.data);
    });
  }, [mode]);

  const handlePageChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  // const createProduct = () => {
  //   history.push({
  //     search: `?entityType=item$pid=${uuidv4()}&cid=${uuidv4()}&mode=create`,
  //   });
  // };

  if (entityType) {
    return <FormProductPage />;
  }
  if (loading) {
    return <Preloader overlay />;
  }
  return (
    <Grid container spacing={2}>
      {/*{loading && <Preloader overlay />}*/}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
          <Typography variant="h5">{t('popular_items')}</Typography>
          {/* <Private permission="MENU_EDIT">
            <Button disabled onClick={createProduct} size="small" variant="outlined">
              + {t('menu.new_item')}
            </Button>
          </Private> */}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mt: 2 }}>
          <StyledDataGrid
            getRowId={(row) => row._id}
            autoHeight={true}
            hideFooterSelectedRowCount={true}
            isEditable={false}
            rows={popularItems || []}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={handlePageChange}
            rowsPerPageOptions={[5, 10, 15, 50, 100]}
            hideFooterPagination={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default PopularItems;
