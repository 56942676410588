import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Divider, DialogActions, DialogContent, DialogContentText, Slide } from '@mui/material';
import { ChargeBackCancelButton, ChargeBackDisputeButton, ModalConfirmText } from './styles';
import DialogHeader from './DialogHeader';
import Currency from 'components/Currency';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmModal({ title, description, open, handleClose, handleConfirmModal, loadingButton }) {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '538px',
            },
          },
        }}
      >
        <DialogHeader handleClose={handleClose} title={title} isConfirm={false} />
        <DialogContent sx={{ pt: 0, pl: 2 }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}
          >
            <ModalConfirmText>{t('chargeBack.accept_message')}</ModalConfirmText>
            <Currency sx={{ color: '#E21508' }} discount={true} value={description} /> ?
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
          <ChargeBackCancelButton onClick={handleClose}>{t('chargeBack.cancel')}</ChargeBackCancelButton>
          <ChargeBackDisputeButton
            loading={loadingButton}
            sx={{
              background: '#FF8A00',
              width: '92px',
              '&:hover': {
                background: '#FF8A00',
              },
            }}
            onClick={handleConfirmModal}
          >
            {t('chargeBack.accept')}
          </ChargeBackDisputeButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
