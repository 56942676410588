import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
export const ReportingGrowthArea = ({ vs, total1 = 0, total2 = 0, showDollar = false, providerLabel = '' }) => {
  const diff = useMemo(() => {
    return total1 - total2;
  }, [total1, total2]);
  return (
    <>
      <Box>
        <Typography variant={'h6'} sx={{ ml: 1 }}>
          {showDollar
            ? `$${Math.abs(total1).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : Math.abs(total1).toLocaleString('en-US')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography>
          {diff ? (
            total1 > total2 ? (
              <ArrowDropUpIcon color={'success'} />
            ) : (
              <ArrowDropDownIcon color={'error'} />
            )
          ) : (
            <ArrowDropUpIcon color={'success'} />
          )}
        </Typography>
        <Typography
          sx={{
            color: diff >= 0 ? 'green' : 'red',
          }}
        >
          {showDollar && '$'}
          {showDollar
            ? Math.abs(diff).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : Math.abs(diff).toLocaleString('en-US')}{' '}
          {diff ? (total2 ? ` (${Math.ceil((Math.abs(diff) / total2) * 100) || 0} %)` : '(∞)') : '(0%)'}
        </Typography>
        <Typography sx={{ ml: 1 }}>vs. {vs}</Typography>
      </Box>
      {providerLabel && <Typography sx={{ ml: 1 }}>{providerLabel}</Typography>}
    </>
  );
};
