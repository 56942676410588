import { memo } from 'react';
import { objEnum } from '../utils';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { DETAILED_ORDER_REPORT_FIELDS, numberFields, styles } from '../hooks/useTableReport';

const ReportTotal = ({ headers, totals, transformTableType }) => {
  const { t } = useTranslation();

  const columns = headers.map((header) => ({
    field: header?.field,
    headerName: numberFields.includes(header?.field) ? header?.headerName : '',
    flex: 1,
    hide: Boolean(
      transformTableType === t(`${objEnum.DETAILED_ORDER_REPORT}`) &&
      DETAILED_ORDER_REPORT_FIELDS.includes(header?.headerName)
    ),
    align: 'right',
    headerAlign: 'right',
    filterable: false,
    renderCell: (params) => {
      const isNumberField = numberFields.includes(header?.field);
      return isNumberField
        ? `$${totals[params.field]?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
        : '';
    },
  }));

  const rows = [
    headers?.reduce((acc, header) => {
      acc[header?.field] = totals[header?.field] || '';
      return acc;
    }, { id: 1 }),
  ];

  return (
    <Box sx={{ ...styles }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        hideFooter
        autoHeight
        density="compact"
      />
    </Box>
  );
};

export default memo(ReportTotal);
