import { useState, memo } from 'react';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  AccordionContentTitle,
  AccordionContentWrapper,
  AccordionEditIcon,
} from 'redesign/Shared/styles';
const AccordionItem = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&::before': {
    display: 'none',
  },
  marginTop: '24px',
  borderRadius: '6px',
}));
const AccordionSummaryItem = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
  },
}));
const TabWrapper = ({
  children,
  title,
  expandedPanel,
  hideEditButton = false,
  handleEdit = () => {},
  id,
}) => {
  const [expanded, setExpanded] = useState(true);
  const handleChange = (panel) => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false);

  return (
    <AccordionItem
      sx={{ boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30)' }}
      expanded={expanded === expandedPanel}
      onChange={handleChange(expandedPanel)}
    >
      <AccordionSummaryItem
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${expandedPanel}d-content`}
        id={`${expandedPanel}d-header`}
      >
        <AccordionContentWrapper>
          <AccordionContentTitle>{title}</AccordionContentTitle>
          {!hideEditButton && (
            <AccordionEditIcon
              onClick={(event) => {
                handleEdit(event, id);
                setExpanded(true);
              }}
            >
              <EditOutlinedIcon style={{ color: '#929398' }} />
            </AccordionEditIcon>
          )}
        </AccordionContentWrapper>
      </AccordionSummaryItem>
      <Divider />
      <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
    </AccordionItem>
  );
};
export default memo(TabWrapper);
