import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from 'assets/icons/TableDelete.png';
import { useDispatch, useSelector } from 'react-redux';
import { confirm } from 'components/MenuNew/ConfirmModal';
import { LAST_CREATED_TABLE_TO } from 'redux/actions/types';
import { MAX_ITEM_COUNT_IN_TABLE, MODE } from 'pages/TableOrdering/config';
import { getTableOrderingSectionTO } from 'redux/actions/TableOrdering/sections';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { createTableTO, deleteTableTO, getTableOrderingTO, updateTableTO } from 'redux/actions/TableOrdering/tables';
import { isTableOrderingProvider } from '../../../utils/checkAccessDenied';
import { isPermissionWithCompanyType } from '../../../hooks/useCompanyType';
import IconButton from '@mui/material/IconButton';

const defaultValues = {
  tableNumber: '',
  seats: '',
  section: {
    _id: '',
    name: '',
  },
};

export const SEATS = [
  { name: '1', value: '1' },
  { name: '2', value: '2' },
  { name: '3', value: '3' },
  { name: '4', value: '4' },
  { name: '5', value: '5' },
  { name: '6', value: '6' },
  { name: '7', value: '7' },
  { name: '8', value: '8' },
  { name: '9', value: '9' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
  { name: '12', value: '12' },
  { name: '12+', value: '13' },
];


export const useTableOrderingTable = () => {
  const { t } = useTranslation();
  const tableFormSchema = yup.object().shape({
    tableNumber: yup.string().required('field_is_required_'),
    section: yup.object().shape({
      _id: yup.string().required('field_is_required_'),
      name: yup.string(),
    }),
    seats: yup.string().required('field_is_required_'),
  });
  const dispatch = useDispatch();
  const providers = useSelector(({ providers }) => providers);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const location = useLocation();
  const { isTypeSingle } = isPermissionWithCompanyType();
  const { currentBusiness } = useSelector(({ business }) => business);
  const { tables = [], loading, lastCreatedTable } = useSelector(({ tableOrdering }) => tableOrdering);
  const { sections = [], isSectionEmpty } = useSelector(({ tableOrdering }) => tableOrdering);
  const [isEmptyModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(MAX_ITEM_COUNT_IN_TABLE);
  const [mode, setMode] = useState(MODE.CREATE);
  const handleCloseInfoModal = () => setOpenInfoModal(false);
  const handleOpenInfoModal = () => setOpenInfoModal(true);
  useEffect(() => {
    if (!isEmptyModalOpen) {
      if (providers?.items?.length > 0 && isTypeSingle) {
        if (isTableOrderingProvider(providers?.items)) {
          if (isSectionEmpty && sections?.length === 0 && location.pathname.includes('/table-ordering')) {
            handleOpenInfoModal();
            setIsModalOpen(true);
          }
        }
      }
    }
  }, [sections, isSectionEmpty, providers, isTypeSingle, isEmptyModalOpen]);

  useEffect(() => {
    if (currentBusiness?._id) {
      dispatch(getTableOrderingTO());
      dispatch(getTableOrderingSectionTO());
    }
  }, [currentBusiness?._id, location]);

  const formMethods = useForm({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(tableFormSchema),
  });

  const tableColumns = [
    {
      field: 'tableNumber',
      headerName: t('Number'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'section',
      headerName: t('Section'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: ({ row }) => {
        if (row.section) {
          return row.section.name;
        }
      },
    },
    {
      field: 'seats',
      headerName: t('Seats'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      id: 'tools',
      field: 'Tools',
      type: 'actions',
      width: 100,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <IconButton aria-label="delete" onClick={() => editTable(params?.row)}>
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => deleteTable(params?.row)}>
              <img src={DeleteIcon} alt="delete icon" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const tableFilter = tables?.map((table) => ({
    id: table._id,
    tableNumber: table.tableNumber,
    section: table.section || '',
    seats: !table.seats ? '' : table.seats,
  }));

  useEffect(() => {
    return () => {
      handleCloseInfoModal();
      setIsModalOpen(false);
      dispatch({ type: LAST_CREATED_TABLE_TO, payload: null });
    };
  }, []);

  const deleteTable = async (data) => {
    const confirmed = await confirm({
      title: t('Delete table'),
      message: t('Are you sure you want to delete this table?'),
      cancelText: t('orders.cancel'),
      confirmText: t('table_ordering.yes_delete'),
      confirmColor: 'primary',
      background: 'rgba(226, 21, 8, 1)',
    });

    if (confirmed) {
      dispatch(deleteTableTO(currentBusiness?._id, data?.id));
    }
  };

  const editTable = (data) => {
    setOpen(true);
    setMode(MODE.EDIT);
    formMethods.reset(data);
  };

  const createTable = (values) => {
    const requestData = {
      tableNumber: values.tableNumber,
    };
    requestData.seats = Number(values.seats) || 0;
    if (values.section?._id) {
      requestData.section = values.section._id;
    }
    formMethods.clearErrors();
    dispatch(createTableTO(requestData)).then(() => {
      handleCancel();
    });
  };

  const updateTable = (values) => {
    const requestData = {
      businessId: currentBusiness?._id || '',
      tableId: values.id,
      body: {
        tableNumber: values.tableNumber,
        seats: Number(values.seats) || 0,
      },
    };
    if (values.section?._id) {
      requestData.body.section = values.section._id;
    }
    formMethods.clearErrors();
    dispatch(updateTableTO(requestData)).then(() => {
      handleCancel();
    });
  };

  const handleSubmit = (values) => {
    if (open && mode === MODE.EDIT) {
      updateTable(values);
    } else {
      createTable(values);
    }
  };

  const handleCancel = () => {
    formMethods.reset(defaultValues);
    formMethods.clearErrors();
    setOpen(false);
    setTimeout(() => {
      setMode(MODE.CREATE);
    }, 300);
  };

  return {
    handleSubmit,
    handleCancel,
    formMethods,
    tableColumns,
    tableFilter,
    loading,
    open,
    setOpen,
    mode,
    sections,
    isSectionEmpty,
    openInfoModal,
    handleCloseInfoModal,
    rowsPerPage,
    setRowsPerPage,
    lastCreatedTable,
  };
};
