import styled from '@emotion/styled';
import { Typography, Box, Button } from '@mui/material';

export const UserEmailName = styled(Typography)({
  color: '#929398',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '12px',
  letterSpacing: '0.15px',
  paddingBottom: '12px',
});

export const UserEmail = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
});
export const ChangePassword = styled(Button)({
  color: '#FF8A00',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  textTransform: 'uppercase',
});
export const ValidationMessageTitle = styled(Typography)({
  color: '#0F1214',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.15px',
});
export const ValidationInnerTitle = styled(Typography)({
  color: '#929398)',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.15px',
  padding: '4px 0',
});
export const ValidationItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

export const RealTimeBlock = styled(Box)(({ theme }) => ({
  width: '380px',
  [theme.breakpoints.down('md')]: {
    width: '318px',
  },
}));
