import { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import qs from 'qs';
import { getReports } from 'redux/actions/orders';
import StackedChart from 'components/Charts/StackedChart';
import LineChart from 'components/Charts/LineChart';
import PieChart from 'components/Charts/PieChart';
import BarChart from 'components/Charts/BarChart';
import { getReportsFilterPeriods } from 'constants/config';
import Preloader from 'components/Preloader';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

const initialData = {
  ordersByServices: { website: 0 },
  revenue: { website: {} },
  totalOrders: { website: {} },
  websiteOrder: { website: {}, total: {} },
};

const Home = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { uuid } = useParams();
  const { period } = qs.parse(search, { ignoreQueryPrefix: true });
  const [reports, setReports] = useState(initialData);
  const [loading, setLoading] = useState(true);
  const user = useSelector(({ user }) => user.data);
  const { currentBusiness } = useSelector(({ business }) => business);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault(currentBusiness.timezone?.timeZoneId);
  const periods = useMemo(() => getReportsFilterPeriods(currentBusiness.timezone?.timeZoneId), [
    currentBusiness,
    period,
  ]);
  const query = useMemo(() => {
    return {
      period: periods[period] ? period : 'week',
    };
  }, [periods, search]);

  const handleGetReports = useCallback(
    (id) => {
      if (currentBusiness && Object.keys(currentBusiness).length > 0) {
        const { start, end } = periods[query.period];
        if (periods[query.period] === periods.day) {
          const convertEnding = dayjs(+new Date(moment(new Date())))
            .tz(currentBusiness.timezone?.timeZoneId)
            .valueOf();
          setLoading(true);
          getReports(id, start, convertEnding, query.period, currentBusiness?._id)
            .then(({ data: { data } }) => {
              setReports(data);
            })
            .finally(setLoading(false));
        } else {
          setLoading(true);
          getReports(id, start, end, query.period, currentBusiness?._id)
            .then(({ data: { data } }) => {
              setReports(data);
            })
            .finally(setLoading(false));
        }
      }
    },
    [periods, query.period, currentBusiness]
  );

  useEffect(() => {
    const id = user?._id || uuid;
    if (id) {
      handleGetReports(id, uuid);
    }
  }, [handleGetReports, user, uuid]);

  // const handleFilterChange = (type, value) => {
  //   history.replace({ search: qs.stringify({ ...query, [type]: value }) });
  // };

  return (
    <ErrorBoundaryWrapper>
      <div className={uuid ? 'p-4' : ''}>
        {/* <Box>
        <Private permission="HOME_REPORT_FILTER">
          <ReportsFilter query={query} onSelect={handleFilterChange} />
        </Private>
      </Box> */}
        {loading ? (
          <Preloader className="p-5" />
        ) : (
          <Grid spacing={2} container columns={12}>
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <h6> {t('Orders')}</h6>
                  <div className="chart-container">
                    <LineChart data={reports.totalOrders} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <h6> {t('home.orders_by_services')}</h6>
                  <div className="chart-container">
                    <PieChart data={reports.ordersByServices} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <h6>{t('home.revenue')}</h6>
                  <div className="chart-container">
                    <BarChart data={reports.revenue} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <h6>{t('home.website_total_orders')}</h6>
                  <div className="chart-container">
                    <StackedChart data={reports.websiteOrder} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default Home;
