import { useState } from 'react';
import { confirmPassword } from 'redux/actions/user';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import CustomTextInput from 'components/Forms/CustomTextInput';
import Typography from '@mui/material/Typography';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const ResetPassword = () => {
  const history = useHistory();
  const { token } = useParams();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();

  const schemaResetPassword = yup.object().shape({
    password: yup
      .string()
      .min(6, 'this_field_min_6_characters_')
      .max(255, 'this_field_max_255_characters_')
      .required('field_is_required_'),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'password_does_not_match_')
      .required('field_is_required_'),
  });

  const ResetPasswordFormMethods = useForm({
    defaultValues: { password: '', repeatPassword: '' },
    mode: 'all',
    resolver: yupResolver(schemaResetPassword),
  });

  const handleValidSubmit = (values) => {
    setSubmitted(true);
    dispatch(confirmPassword({ ...values, token }))
      .then(() => {
        history.goBack('/login');
      })
      .catch(() => {
        setSubmitted(false);
      });
  };

  return (
    <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="auth-box shadow">
        <FormProvider {...ResetPasswordFormMethods}>
          <form noValidate autoComplete="off" onSubmit={ResetPasswordFormMethods.handleSubmit(handleValidSubmit)}>
            <Grid container>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography align="center" variant="h4">
                  {t('settings.new_password')}
                </Typography>
              </Grid>

              <Grid item sx={{ mb: 2 }} xs={12}>
                <CustomTextInput
                  sx={{ width: '100%' }}
                  inputProps={{
                    type: 'password',
                  }}
                  name="password"
                  label={t('sign_in.password')}
                />
              </Grid>

              <Grid item sx={{ mb: 2 }} xs={12}>
                <CustomTextInput
                  sx={{ width: '100%' }}
                  inputProps={{
                    type: 'password',
                  }}
                  name="repeatPassword"
                  label={t('settings.confirm_password')}
                />
              </Grid>
              <Grid item sx={{ mb: 2 }} xs={12}></Grid>
              <Button id="resetPassView" variant="contained" color="primary" type="submit" disabled={submitted} block="true">
                {t('order_view.submit')}
              </Button>
              <Grid item sx={{ mb: 2 }} xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Link to="/login">{t('_login_.login')}</Link>
                  <Link to="/signup">{t('_login_.signup')}</Link>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
    </Box>
  );
};

export default ResetPassword;
