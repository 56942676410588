import { memo } from 'react';
import { List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { Box } from '@mui/material';
import { PauseResumeAllBusinessListItem } from './styles';
// import { PauseResumeContext } from 'context/pauseResumeContext';

const VirtualizedList = ({ list, disabled, businessIds }) => {
  // const {
  //   setCheckedBusiness,
  //   checkedBusiness,
  //   setSelectAllBusiness,
  //   selectAllBusiness,
  //   businessSelectAllObject,
  // } = useContext(PauseResumeContext);

  // const handleToggleBusiness = (businessId) => {
  //   if (businessId === businessSelectAllObject.businessId) {
  //     setCheckedBusiness(businessIds);
  //     if (!selectAllBusiness) {
  //       setCheckedBusiness(businessIds);
  //       setSelectAllBusiness(true);
  //     } else {
  //       setCheckedBusiness([]);
  //       setSelectAllBusiness(false);
  //     }
  //   } else {
  //     const currentIndex = checkedBusiness.indexOf(businessId);
  //     const newChecked = [...checkedBusiness];
  //     if (currentIndex === -1) {
  //       newChecked.push(businessId);
  //     } else {
  //       newChecked.splice(currentIndex, 1);
  //     }
  //     setSelectAllBusiness(newChecked.length === list.length);
  //     setCheckedBusiness(newChecked);
  //   }
  // };

  const rowRenderer = ({ index, key, style }) => {
    return (
      <Box
        key={key}
        style={style}
        sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
        // onClick={!disabled ? () => handleToggleBusiness(list[index]?.businessId) : () => {}}
      >
        {/*<Checkbox*/}
        {/*  edge="start"*/}
        {/*  disabled={true}*/}
        {/*  disableRipple*/}
        {/*  checked={*/}
        {/*    list[index]?.businessId === businessSelectAllObject.businessId*/}
        {/*      ? checkedBusiness?.indexOf(list[index]?.businessId) !== -1 && selectAllBusiness*/}
        {/*      : checkedBusiness?.indexOf(list[index]?.businessId) !== -1*/}
        {/*  }*/}
        {/*/>*/}
        <PauseResumeAllBusinessListItem>{list[index]?.companyName}</PauseResumeAllBusinessListItem>
      </Box>
    );
  };
  return (
    <>
      <List width={350} height={300} rowCount={list.length} rowHeight={44} data={list} rowRenderer={rowRenderer} />
    </>
  );
};
export default memo(VirtualizedList);
