import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { ChargeBackInfoSection, ChargeBackViewSubTitle, STYLES } from './styles';
import { CHARGE_BACK_STATUS, DEFAULT_TIMEZONE } from 'components/Chargeback/constants';
import alertImage from 'assets/icons/chargeBack/alert_icon.png';
import holdImage from 'assets/icons/chargeBack/hold_circle.png';
import scheduleImage from 'assets/icons/chargeBack/schedule_circle.png';
import approvedImage from 'assets/icons/chargeBack/approve_circle.png';
import cancelImage from 'assets/icons/chargeBack/cancel_circle.png';
import blackAlertImage from 'assets/icons/chargeBack/black_alert_circle.png';
import solvedByProviderImage from 'assets/icons/chargeBack/solved_by_provider_circle.png';
import refundedImage from 'assets/icons/chargeBack/refunded_circle.png';
import createImage from 'assets/icons/chargeBack/create_circle.png';

const ChargeBackInfo = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { chargeBack, business } = useSelector((store) => store);
  const { selected } = chargeBack;
  const { currentBusiness } = business;
  const isChargeBack = history.location.pathname.includes('chargeback');

  const infoObj = useMemo(() => {
    switch (selected?.status?.name) {
      case CHARGE_BACK_STATUS.CREATED:
        return {
          message: t('chargeBack.createdMessage', {
            price: `$${Math.abs(selected?.chargeTotal || 0)}`,
            date: selected?.lastDayToDispute
              ? moment(selected?.lastDayToDispute)
                  ?.tz(currentBusiness?.timezone.timeZoneId || DEFAULT_TIMEZONE)
                  .format('MMMM DD, YYYY')
              : '',
          }),
          icon: createImage,
          statusInfoText: STYLES.statusInfoTextCreated,
          background: STYLES.backgroundCreated,
        };
      case CHARGE_BACK_STATUS.HOLD:
        return {
          message: t('chargeBack.holdMessage'),
          icon: holdImage,
          statusInfoText: STYLES.statusInfoTextHold,
          background: STYLES.backgroundHold,
        };
      case CHARGE_BACK_STATUS.DISPUTED:
        return {
          message: t('chargeBack.disputeMessage', {
            price: `$${Math.abs(selected?.chargeTotal || 0)}`,
          }),
          icon: scheduleImage,
          statusInfoText: STYLES.statusInfoTextDisputed,
          background: STYLES.backgroundDisputed,
        };
      case CHARGE_BACK_STATUS.DISPUTE_APPROVED:
        return {
          message: t('chargeBack.disputeApprovedMessage', {
            price: `$${Math.abs(selected?.refundTotal || selected?.chargeTotal || 0)}`,
          }),
          icon: approvedImage,
          statusInfoText: STYLES.statusInfoTextDisputeApproved,
          background: STYLES.backgroundDisputeApproved,
        };
      case CHARGE_BACK_STATUS.REJECTED:
        return {
          message: t('chargeBack.rejectedMessage', {
            price: `$${Math.abs(selected?.chargeTotal || 0)}`,
            date: selected?.lastDayToDispute
              ? moment(selected?.lastDayToDispute)
                  ?.tz(currentBusiness?.timezone.timeZoneId || DEFAULT_TIMEZONE)
                  .format('MMMM DD, YYYY')
              : '',
          }),
          icon: cancelImage,
          statusInfoText: STYLES.statusInfoTextRejected,
          background: STYLES.backgroundRejected,
        };
      case CHARGE_BACK_STATUS.EXPIRED:
      case CHARGE_BACK_STATUS.UNKNOWN:
      case CHARGE_BACK_STATUS.DISPUTE_NOT_ALLOWED:
        return {
          message: t('chargeBack.expiredMessage', {
            price: `$${Math.abs(selected?.chargeTotal || 0)}`,
            date: selected?.lastDayToDispute
              ? moment(selected?.lastDayToDispute)
                  ?.tz(currentBusiness?.timezone.timeZoneId || DEFAULT_TIMEZONE)
                  .format('MMMM DD, YYYY')
              : '',
          }),
          icon: blackAlertImage,
          statusInfoText: STYLES.statusInfoTextExpired,
          background: STYLES.backgroundExpired,
        };
      case CHARGE_BACK_STATUS.SOLVED_BY_PROVIDER:
        return {
          message: t('chargeBack.solvedByProviderMessage'),
          icon: solvedByProviderImage,
          statusInfoText: STYLES.statusInfoTextExpired,
          background: STYLES.backgroundRefunded,
        };
      case CHARGE_BACK_STATUS.ACCEPTED:
        return {
          message: t('chargeBack.approvedMessage', {
            price: `$${Math.abs(selected?.chargeTotal || 0)}`,
            date: selected?.lastDayToDispute
              ? moment(selected?.lastDayToDispute)
                  ?.tz(currentBusiness?.timezone.timeZoneId || DEFAULT_TIMEZONE)
                  .format('MMMM DD, YYYY')
              : '',
          }),
          icon: cancelImage,
          statusInfoText: STYLES.statusInfoTextRejected,
          background: STYLES.backgroundRejected,
        };
      case CHARGE_BACK_STATUS.REFUNDED:
        return {
          message: t('chargeBack.refundedMessage', {
            price: `$${Math.abs(selected?.refundTotal || selected?.chargeTotal || 0)}`,
            date: selected?.lastDayToDispute
              ? moment(selected?.lastDayToDispute)
                  ?.tz(currentBusiness?.timezone.timeZoneId || DEFAULT_TIMEZONE)
                  .format('MMMM DD, YYYY')
              : '',
          }),
          icon: refundedImage,
          statusInfoText: STYLES.statusInfoTextRefunded,
          background: STYLES.backgroundRefunded,
        };
      default:
        return alertImage;
    }
  }, [
    selected?.chargeTotal,
    selected?.lastDayToDispute,
    selected?.refundTotal,
    selected?.status?.name,
    currentBusiness?.timezone.timeZoneId,
    t,
  ]);

  return (
    isChargeBack && (
      <ChargeBackInfoSection>
        <ChargeBackViewSubTitle
          sx={{
            marginTop: 2,
          }}
        >
          {t('chargeBack.details.chargebackInfo')}
        </ChargeBackViewSubTitle>
        <Box
          sx={{
            ...infoObj.background,
            borderRadius: '4px',
            my: '14px',
          }}
          display="flex"
          alignItems="center"
          gap={1}
          px={1}
          py={0.5}
        >
          <Box component="img" src={infoObj.icon} sx={STYLES.statusIcon} />
          <Typography>
            {selected?.status?.status?.displayName} -{' '}
            {selected?.status?.name === CHARGE_BACK_STATUS.CREATED
              ? moment
                  .utc(selected?.requestDate || selected?.createdAt)
                  .tz(currentBusiness?.timezone.timeZoneId || DEFAULT_TIMEZONE)
                  .format('LT - ddd MMM Do')
              : moment
                  .utc(selected?.status?.updatedAt || selected?.updatedAt)
                  .tz(currentBusiness?.timezone.timeZoneId || DEFAULT_TIMEZONE)
                  .format('LT - ddd MMM Do')}
          </Typography>
        </Box>
        <Box
          sx={{
            ...infoObj.background,
            borderRadius: '4px',
            my: '14px',
          }}
          display="flex"
          gap={2}
          p={2}
        >
          <Box component="img" src={infoObj.icon} sx={STYLES.statusIcon} />
          <Typography>{infoObj.message}</Typography>
        </Box>
        <ChargeBackViewSubTitle
          sx={{
            marginTop: 2,
          }}
        >
          {t('chargeBack.details.orderDetails')}
        </ChargeBackViewSubTitle>
      </ChargeBackInfoSection>
    )
  );
};

export default ChargeBackInfo;
