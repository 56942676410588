// src/hooks/useSetupInterceptorAccessDenied.js
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import instance from '../services/api';
import { checkAccessDenied } from 'utils/checkAccessDenied';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const useSetupInterceptorAccessDenied = () => {
  const history = useHistory();

  useEffect(() => {
    const interceptor = instance.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response?.status;
        const resBaseURL = error.response?.config?.baseURL;

        if (resBaseURL === baseURL && status === 403) {
          checkAccessDenied(history);
        }
        return Promise.reject(error);
      }
    );

    // Cleanup interceptor on unmount
    return () => {
      instance.interceptors.response.eject(interceptor);
    };
  }, [history]);
};

export default useSetupInterceptorAccessDenied;
