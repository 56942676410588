import { memo } from 'react';
import Private from 'components/Private';
import { useDispatch } from 'react-redux';
import * as all from 'constants/permissions';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import {
  StyledButton,
  StyledProviderCurrentSettings,
  StyledProviderHeader,
  StyledProviderImageHeader,
  StyledProviderPopover,
  StyledProviderRenderActions,
  StyledProviderStoreIdTitle,
  StyledProviderStoreIdTitleBox,
  StyledProviderTitle,
  StyledProviderTitleWrapper,
  StyledProviderWrapper,
} from './styles';
import { useProviderList } from 'hooks/useProviderList';
import { connectProviderLocation } from 'redux/actions/providers';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Paper, Tooltip, IconButton, Divider, Button, Popover, Box } from '@mui/material';
import {
  PROVIDER_CONNECTED,
  PROVIDER_NO_CONNECTED,
  PROVIDER_PENDING,
  PROVIDER_PENDING_DISCONNECT,
} from 'constants/providerStatuses';
import { LoadingButton } from '@mui/lab';
import AutoConfirmInfo from 'components/Providers/AutoConfirmInfo';

const FORCE_SYNC_PROVIDERS_SRV = [
  'grubhub',
  'grubhubv2',
  'wix',
  'doordash',
  'deliverycom',
  'table_ordering',
  'ezcater',
  'website',
  'food2',
  'ubereats',
];

const ProviderList = ({
  data,
  onConnect,
  isDisabled,
  onDisconnect,
  target,
  id,
  stopResumeOrders,
  handleOpen,
  setCollectProviderData,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    handleActivate,
    handleDisconnect,
    checkPermission,
    handleOpenURl,
    providers,
    handleClick,
    handleClose,
    open,
    openId,
    anchorEl,
    handleSettings,
    loading,
    connectionLoading,
    setSelectedProvider,
    selectedProvider,
    forceMenuSync,
    loadingProviderSync,
    handleClickEyeIcon,
    anchorElWebUrl,
    handleCloseWebUrl,
    openWebId,
    openWebUrl,
  } = useProviderList(stopResumeOrders, onDisconnect, data, id);

  const handleClickProvider = (data, providers) => {
    if (data.srv === providers?.ubereats?.srv) {
      handleOpen(true);
      setCollectProviderData({
        ...data,
        label: providers[data?.srv]?.label,
      });
      dispatch({ type: 'EMPTY_PROVIDER_CONNECT_DATA_RESPONSE' });
    } else {
      if (
        data.srv !== providers?.food2.srv &&
        data.srv !== providers?.table_ordering.srv &&
        data.srv !== providers?.pos?.srv
      ) {
        if (
          data.srv === providers?.grubhub?.srv ||
          data.srv === providers?.grubhubv2?.srv ||
          data.srv === providers?.doordash?.srv
        ) {
          handleOpen(true);
          setCollectProviderData({
            ...data,
            label: providers[data?.srv]?.label,
          });
        } else {
          dispatch({ type: 'EMPTY_PROVIDER_CONNECT_DATA_RESPONSE' });
          onConnect(data);
        }
      } else {
        setSelectedProvider(data.srv);
        dispatch(connectProviderLocation({ provider: data.srv, data }));
      }
    }
  };
  const getAllowProviderSync = (srv) => {
    return FORCE_SYNC_PROVIDERS_SRV.includes(srv);
  };

  const renderActions = () => {
    switch (data.status) {
      case PROVIDER_NO_CONNECTED:
        return (
          <StyledProviderRenderActions>
            <LoadingButton
              loading={
                (connectionLoading && selectedProvider === providers?.food2.srv) ||
                (connectionLoading && selectedProvider === providers?.table_ordering.srv)
              }
              id="providerItemConnect"
              color="primary"
              variant="outlined"
              disabled={isDisabled && data.name === target?.name && PROVIDER_CONNECTED === data.status}
              size="sm"
              onClick={() => handleClickProvider(data, providers)}
            >
              {t('website_settings.connect')}
            </LoadingButton>
          </StyledProviderRenderActions>
        );
      case PROVIDER_PENDING:
        return (
          <StyledProviderRenderActions>
            {checkPermission(all['SUPER']) ? (
              <Button color="primary" variant="outlined" size="sm" onClick={() => handleActivate(data)}>
                {t('settings.pending_activate')}
              </Button>
            ) : (
              <b className="text-muted">{t('settings.pending_connect')}</b>
            )}
          </StyledProviderRenderActions>
        );
      case PROVIDER_PENDING_DISCONNECT:
        return (
          <StyledProviderRenderActions>
            <b className="text-muted">{t('settings.pending_disconnect')}</b>
          </StyledProviderRenderActions>
        );
      default:
        return null;
    }
  };
  return (
    <>
      {providers[data?.srv] && (
        <Paper sx={{ minHeight: '152px' }}>
          <StyledProviderWrapper>
            <StyledProviderHeader>
              <StyledProviderImageHeader>
                <img src={providers[data.srv]?.logoUrl} alt="provider" width="100%" height="100%" className="rounded" />
              </StyledProviderImageHeader>
              <StyledProviderTitleWrapper>
                <StyledProviderTitle>
                  {checkPermission(all['SUPER'])
                    ? `${providers[data.srv]?.label} (${data.srv})`
                    : providers[data.srv]?.label}
                </StyledProviderTitle>
                {data?.storId && (
                  <Tooltip title={`ID: ${data?.storId}`} placement="top">
                    <StyledProviderStoreIdTitleBox>
                      <StyledProviderStoreIdTitle>{t('providers_settings.id')}</StyledProviderStoreIdTitle>
                      <StyledProviderStoreIdTitle>{data?.storId}</StyledProviderStoreIdTitle>
                    </StyledProviderStoreIdTitleBox>
                  </Tooltip>
                )}
              </StyledProviderTitleWrapper>
            </StyledProviderHeader>
            <StyledProviderCurrentSettings>
              {providers[data.srv]?.options?.showSettingsButton && (
                <>
                  {data?.metadata?.previewUrl ? (
                    <Tooltip title={'View online'} placement={'top'}>
                      <IconButton aria-label="preview" onClick={() => handleClickEyeIcon(data?.metadata?.previewUrl)}>
                        <RemoveRedEyeOutlinedIcon style={{ fontSize: '24px' }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <IconButton aria-label="more" onClick={handleClick}>
                <MoreVertIcon style={{ fontSize: '24px' }} />
              </IconButton>
            </StyledProviderCurrentSettings>
          </StyledProviderWrapper>
          <Divider />
          {providers[data.srv]?.options?.showConnectButton && <>{renderActions()}</>}
          <AutoConfirmInfo providers={providers} data={data} />
          <Popover
            id={openId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <StyledProviderPopover>
              {/*{data.status === PROVIDER_CONNECTED && getAllowProviderSync(data.srv) && checkPermission(all['SUPER']) && (*/}
              {/*  <StyledButton*/}
              {/*    loadingPosition="center"*/}
              {/*    loading={loadingProviderSync}*/}
              {/*    variant="text"*/}
              {/*    startIcon={*/}
              {/*      <MenuBookOutlinedIcon*/}
              {/*        style={{*/}
              {/*          color: loadingProviderSync ? 'rgba(0, 0, 0, 0.26)' : '#929398',*/}
              {/*          width: '24px',*/}
              {/*          height: '24px',*/}
              {/*        }}*/}
              {/*      />*/}
              {/*    }*/}
              {/*    onClick={() => forceMenuSync(data.srv)}*/}
              {/*    disabled={loadingProviderSync}*/}
              {/*  >*/}
              {/*    Force menu sync*/}
              {/*  </StyledButton>*/}
              {/*)}*/}

              <StyledButton
                disabled={
                  data.status !== PROVIDER_CONNECTED ||
                  !providers[data.srv]?.options?.showSettingsButton ||
                  loadingProviderSync
                }
                loading={false}
                variant="text"
                startIcon={
                  <SettingsOutlinedIcon
                    style={{
                      color: loadingProviderSync ? 'rgba(0, 0, 0, 0.26)' : '#929398',
                      width: '24px',
                      height: '24px',
                    }}
                  />
                }
                onClick={handleSettings}
              >
                Settings
              </StyledButton>
              {data.status === PROVIDER_CONNECTED && (
                <Box>
                  <Private permission="SETTINGS_ADMIN">
                    <StyledButton
                      loading={loading}
                      disabled={loading || loadingProviderSync}
                      variant="text"
                      startIcon={
                        <LinkOffOutlinedIcon
                          style={{
                            color: loading || loadingProviderSync ? 'rgba(0, 0, 0, 0.26)' : '#929398',
                            width: '24px',
                            height: '24px',
                          }}
                        />
                      }
                      onClick={handleDisconnect}
                    >
                      {t('website_settings.disconnect')}
                    </StyledButton>
                  </Private>
                </Box>
              )}
            </StyledProviderPopover>
          </Popover>
        </Paper>
      )}
    </>
  );
};
export default memo(ProviderList);
