import * as React from 'react';

const StripeInput = React.forwardRef(function StripeInput(props, ref) {
  const { component: Component, options, ...other } = props;
  const [mountNode, setMountNode] = React.useState();

  React.useImperativeHandle(
    ref,
    () => ({
      focus: () => mountNode.focus(),
    }),
    [mountNode]
  );

  return <Component onReady={setMountNode} {...other} />;
});

export default StripeInput;
