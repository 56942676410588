export const confirmable = (cb, title = 'Are You sure?', description = '', okText = 'OK', cancelText = 'Cancel') => {
  // return prompt(
  //   title,
  //   description,
  //   [
  //     {
  //       text: cancelText,
  //       style: 'cancel',
  //     },
  //     {
  //       text: okText,
  //       onClick: () => {
  //         cb();
  //       },
  //     },
  //   ],
  //   { cancelable: false }
  // );
};

export const getTypeText = (type, t) => {
  const types = {
    delivery: t('website_settings.delivery'),
    pickup: t('website_settings.pickup'),
    curbsidepickup: t('orders_history.curbside_pickup'),
    curbsidePickup: t('orders_history.curbside_pickup'),
    dine_in: t('orders_history.dine_in'),
    take_out: t('orders_history.take_out'),
  };
  return types[type];
};

export const reduceByKey = (arr, key) => {
  return arr.reduce((acc, i) => ({ ...acc, [i[key]]: i }), {});
};

export const arrayReRange = (arr, oldIndex, newIndex) => {
  const data = [...arr];
  const [removed] = data.splice(oldIndex, 1);
  data.splice(newIndex, 0, removed);
  return data;
};
