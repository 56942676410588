import { useState, useCallback, useEffect } from 'react';
import getCroppedImg from 'components/ImageUpolad/utils/cropImage';
import { uploadAttachments } from 'redux/actions/file';
import { MIN_IMAGE_UPLOAD_HEIGHT, MIN_IMAGE_UPLOAD_WIDTH } from 'constants/file';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
export const IMode = {
  LEFT: 'left',
  RIGHT: 'right',
};
export const useBusinessPhotoCrop = (
  photoURL,
  imageOptions,
  setImageSrc,
  setFormValue,
  handleClose,
  isCoverPhoto,
  setValue,
  name,
  error,
  setError
) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isBusiness = history.location.pathname.includes('/settings/business');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const onCropChange = useCallback(
    (crop) => {
      setCrop(crop);
      if (croppedAreaPixels) {
        displayCroppedSize(croppedAreaPixels);
      }
    },
    [croppedAreaPixels]
  );
  const onZoomChange = useCallback(
    (zoom) => {
      setZoom(zoom);
      if (croppedAreaPixels) {
        displayCroppedSize(croppedAreaPixels);
      }
    },
    [croppedAreaPixels]
  );
  const displayCroppedSize = (croppedAreaPixels) => {
    const width = croppedAreaPixels.width;
    const height = croppedAreaPixels.height;
    if (!isBusiness && (width < MIN_IMAGE_UPLOAD_WIDTH || height < MIN_IMAGE_UPLOAD_HEIGHT)) {
      const convertedMessage = `${t('settings.current_upload_min_size')}`;
      setErrorMessage(convertedMessage);
    } else {
      setErrorMessage('');
    }
  };
  useEffect(() => {
    if (error && !isBusiness) {
      const convertedMessage = `${t('settings.current_upload_min_size')}`;
      setErrorMessage(convertedMessage);
    } else {
      setErrorMessage('');
      setError(false);
    }
  }, [error]);
  const onZoomChangeManualChange = (zoom) => {
    setZoom(zoom);
    if (croppedAreaPixels) {
      displayCroppedSize(croppedAreaPixels);
    }
  };
  const proceedWithUpload = (croppedImgFile) => {
    const formData = new FormData();
    formData.append('image', croppedImgFile);
    uploadAttachments(formData)
      .then(({ image }) => {
        if (image.url) {
          setValue(image);
          if (name === 'avatar') {
            setFormValue('avatar', image, { shouldDirty: true });
          } else {
            setFormValue('image', image, { shouldDirty: true });
          }
          handleClose();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleCropWithoutValidation = (croppedImgFile) => {
    setErrorMessage('');
    const reader = new FileReader();
    reader.readAsDataURL(croppedImgFile);
    proceedWithUpload(croppedImgFile);
  };
  const cropImage = async () => {
    try {
      setIsLoading(true);
      const { file } = await getCroppedImg(photoURL, croppedAreaPixels, rotation);
      const croppedImgFile = new File([file], imageOptions.name, {
        lastModified: Date.now(),
        type: imageOptions.type,
      });
      if (croppedImgFile) {
        handleCropWithoutValidation(croppedImgFile);
      }
    } catch (error) {
      console.log(error, 'ERROR:');
    }
  };
  const handleRotate = (value, mode) => {
    if (mode === IMode.LEFT && rotation >= 90) {
      setRotation((prevState) => prevState - value);
    } else if (mode === IMode.RIGHT && rotation <= 360) {
      setRotation((prevState) => prevState + value);
    }
  };
  return {
    handleRotate,
    cropImage,
    cropComplete,
    zoom,
    setZoom,
    crop,
    setCrop,
    rotation,
    setRotation,
    loading,
    errorMessage,
    setErrorMessage,
    onCropChange,
    onZoomChange,
    onZoomChangeManualChange,
  };
};
