import { memo } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { routes } from 'pages/Settings/EmployeesAndJobs/useTabState';
import useTabState from 'pages/Settings/EmployeesAndJobs/useTabState';

const TabContent = ({ value, selectedValue, children }) =>
  value === selectedValue ? <Box sx={{ pt: 2 }}>{children}</Box> : null;

const EmployeesAndJobs = () => {
  const { t } = useTranslation();

  const { value, handleChange } = useTabState(routes[0].path);

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        variant="scrollable"
        scrollButtons="auto"
        selectionFollowsFocus
        value={value}
        onChange={handleChange}
      >
        {routes.map(({ id, path, name }) => (
          <Tab key={id} value={`/settings/employeesAndJobs${path}`} label={t(name)} />
        ))}
      </Tabs>
      {routes.map(({ id, path, component: Component }) => (
        <TabContent key={id} value={`/settings/employeesAndJobs${path}`} selectedValue={value}>
          <Component />
        </TabContent>
      ))}
    </Box>
  );
};

export default memo(EmployeesAndJobs);
