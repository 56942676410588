import holdImage from 'assets/icons/chargeBack/hold_circle.png';
import scheduleImage from 'assets/icons/chargeBack/schedule_circle.png';
import approvedImage from 'assets/icons/chargeBack/approve_circle.png';
import cancelImage from 'assets/icons/chargeBack/cancel_circle.png';
import blackAlertImage from 'assets/icons/chargeBack/black_alert_circle.png';
import solvedByProviderImage from 'assets/icons/chargeBack/solved_by_provider_circle.png';
import refundedImage from 'assets/icons/chargeBack/refunded_circle.png';
import createImage from 'assets/icons/chargeBack/create_circle.png';

export const SET_CHARGE_BACK = 'SET_CHARGE_BACK';
export const SET_CHARGE_BACK_LOADING = 'SET_CHARGE_BACK_LOADING';
export const SET_CHARGE_SELECTED = 'SET_CHARGE_SELECTED';
export const SET_CHARGE_SELECTED_LOADING = 'SET_CHARGE_SELECTED_LOADING';
export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const OTHER_REASON = {
  OTHER: 'Other',
};

export const ROUTES = (t) => [
  { id: 'CHARGE_BACK_NEW', plan: '', path: '/new', name: t('chargeBack.new') },
  {
    id: 'CHARGE_BACK_PROCESSING',
    plan: '',
    path: '/processing',
    name: t('chargeBack.processing'),
  },
  // { id: 'CHARGE_BACK_HOLD', plan: '', path: '/hold', name: t('chargeBack.hold') },
  {
    id: 'CHARGE_BACK_COMPLETED',
    plan: '',
    path: '/completed',
    name: t('chargeBack.completed'),
  },
];

export const CHARGE_BACK_STATUS = {
  CREATED: 'created',
  DISPUTED: 'disputed',
  DISPUTE_APPROVED: 'dispute-approved',
  HOLD: 'hold',
  REFUNDED: 'refunded',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  EXPIRED: 'expired',
  SOLVED_BY_PROVIDER: 'solved-by-provider',
  UNKNOWN: 'unknown',
  DISPUTE_NOT_ALLOWED: 'dispute-not-allowed',
  DENIED: 'denied',
  DECLINED: 'declined',
};

export const DEFAULT_REASON_LIST_VALUE_ENUM = {
  CUSTOMER_MADE_A_MISTAKE: 'chargeBack.customer_made_a_mistake',
  REFUND_AMOUNT_IS_INCORRECT: 'chargeBack.refund_amount_is_incorrect',
  ITEM_PREPARED_AS_REQUESTED: 'chargeBack.item_prepared_as_requested',
  ITEM_NOT_PICKED_UP: 'chargeBack.item_not_picked_up',
  ORDER_PICKED_UP_LATE: 'chargeBack.order_picked_up_late',
  ORDER_HANDLED_IMPROPERLY: 'chargeBack.order_handled_improperly',
  Other: 'chargeBack.other',
};

export const DEFAULT_REASON_LIST_KEY_ENUM = {
  customer_made_a_mistake: 'customer_made_a_mistake',
  refund_amount_is_incorrect: 'refund_amount_is_incorrect',
  item_not_picked_up: 'item_not_picked_up',
  item_prepared_as_requested: 'item_prepared_as_requested',
  order_picked_up_late: 'order_picked_up_late',
  order_handled_improperly: 'order_handled_improperly',
  other: 'other',
};

export const DEFAULT_REASON_LIST = (t) => [
  {
    index: 1,
    value: t(DEFAULT_REASON_LIST_VALUE_ENUM.CUSTOMER_MADE_A_MISTAKE),
    key: DEFAULT_REASON_LIST_KEY_ENUM.customer_made_a_mistake,
  },
  {
    index: 2,
    value: t(DEFAULT_REASON_LIST_VALUE_ENUM.REFUND_AMOUNT_IS_INCORRECT),
    key: DEFAULT_REASON_LIST_KEY_ENUM.refund_amount_is_incorrect,
  },
  {
    index: 3,
    value: t(DEFAULT_REASON_LIST_VALUE_ENUM.ITEM_PREPARED_AS_REQUESTED),
    key: DEFAULT_REASON_LIST_KEY_ENUM.item_prepared_as_requested,
  },
  {
    index: 4,
    value: t(DEFAULT_REASON_LIST_VALUE_ENUM.ITEM_NOT_PICKED_UP),
    key: DEFAULT_REASON_LIST_KEY_ENUM.item_not_picked_up,
  },
  {
    index: 5,
    value: t(DEFAULT_REASON_LIST_VALUE_ENUM.ORDER_PICKED_UP_LATE),
    key: DEFAULT_REASON_LIST_KEY_ENUM.order_picked_up_late,
  },
  {
    index: 6,
    value: t(DEFAULT_REASON_LIST_VALUE_ENUM.ORDER_HANDLED_IMPROPERLY),
    key: DEFAULT_REASON_LIST_KEY_ENUM.order_handled_improperly,
  },
  {
    index: 7,
    value: t(DEFAULT_REASON_LIST_VALUE_ENUM.Other),
    key: DEFAULT_REASON_LIST_KEY_ENUM.other,
  },
];

export const StatusEnum = {
  CREATED: 'CREATED',
  DISPUTED: 'DISPUTED',
  DISPUTE_APPROVED: 'DISPUTE_APPROVED',
  HOLD: 'HOLD',
  EXPIRED: 'EXPIRED',
  DENIED: 'DENIED',
  DECLINED: 'DECLINED',
  REJECTED: 'REJECTED',
  REFUNDED: 'REFUNDED',
  ACCEPTED: 'ACCEPTED',
  SOLVED_BY_PROVIDER: 'SOLVED_BY_PROVIDER',
  UNKNOWN: 'UNKNOWN',
  DISPUTE_NOT_ALLOWED: 'DISPUTE_NOT_ALLOWED',
};

export const initialDataModel = {
  reason: '',
  note: '',
  description: '',
};

export const checkChargeBackState = (pathname) => {
  let splitState = 'new';
  if (pathname.includes('chargeback/hold')) {
    splitState = 'hold';
  } else if (pathname.includes('chargeback/processing')) {
    splitState = 'processing';
  } else if (pathname.includes('chargeback/completed')) {
    splitState = 'completed';
  } else {
    splitState = 'new';
  }

  return splitState;
};

export const STATUS_LIST_CHARGE_BACK = {
  CREATED: {
    name: 'Created',
    icon: createImage,
    color: 'rgba(226, 21, 8, 1)',
    background: 'rgba(226, 21, 8, 0.08)',
    type: 'CREATED',
  },
  DISPUTED: {
    name: 'Disputed',
    icon: scheduleImage,
    color: 'rgba(255, 138, 0, 1)',
    background: 'rgba(255, 138, 0, 0.08)',
    type: 'DISPUTED',
  },
  DISPUTE_APPROVED: {
    name: 'Dispute-Approved',
    icon: approvedImage,
    color: '#00984F',
    background: 'rgba(0, 152, 79, 0.08)',
    type: 'DISPUTE_APPROVED',
  },
  HOLD: {
    name: 'Hold',
    icon: holdImage,
    color: 'rgba(255, 138, 0, 1)',
    background: 'rgba(255, 138, 0, 0.08)',
    type: 'HOLD',
  },
  EXPIRED: {
    name: 'Expired',
    icon: blackAlertImage,
    color: 'rgba(15, 18, 20, 1)',
    background: 'rgba(15, 18, 20, 0.08)',
    type: 'EXPIRED',
  },
  DENIED: {
    name: 'Denied',
    icon: cancelImage,
    color: 'rgba(226, 21, 8, 1)',
    background: 'rgba(226, 21, 8, 0.08)',
    type: 'DENIED',
  },
  DECLINED: {
    name: 'Declined',
    icon: cancelImage,
    color: 'rgba(226, 21, 8, 1)',
    background: 'rgba(226, 21, 8, 0.08)',
    type: 'DECLINED',
  },
  REJECTED: {
    name: 'Rejected',
    icon: cancelImage,
    color: 'rgba(226, 21, 8, 1)',
    background: 'rgba(226, 21, 8, 0.08)',
    type: 'REJECTED',
  },
  REFUNDED: {
    name: 'Refunded',
    icon: refundedImage,
    color: '#00984F',
    background: 'rgba(0, 152, 79, 0.08)',
    type: 'REFUNDED',
  },
  ACCEPTED: {
    name: 'Accepted',
    icon: cancelImage,
    color: 'rgba(226, 21, 8, 1)',
    background: 'rgba(226, 21, 8, 0.08)',
    type: 'ACCEPTED',
  },
  SOLVED_BY_PROVIDER: {
    name: 'Solved by provider',
    icon: solvedByProviderImage,
    color: '#00984F',
    background: 'rgba(0, 152, 79, 0.08)',
    type: 'SOLVED_BY_PROVIDER',
  },
  UNKNOWN: {
    name: 'Unknown',
    icon: blackAlertImage,
    color: 'rgba(15, 18, 20, 1)',
    background: 'rgba(15, 18, 20, 0.08)',
    type: 'UNKNOWN',
  },
  DISPUTE_NOT_ALLOWED: {
    name: 'Dispute not allowed',
    icon: blackAlertImage,
    color: 'rgba(15, 18, 20, 1)',
    background: 'rgba(15, 18, 20, 0.08)',
    type: 'DISPUTE_NOT_ALLOWED',
  },
};
