import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs, Hidden } from '@mui/material';
import { useIsWidthDown } from 'hooks/mediaQuery';
import { ROUTES, SET_CHARGE_SELECTED } from './constants';
import { getChargeBackList } from 'redux/actions/chargeBack';

const HeaderTabs = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const mdDown = useIsWidthDown('md');
  const [value, setValue] = useState(history.location.pathname);

  const handleChange = (event, newValue) => {
    dispatch({ type: SET_CHARGE_SELECTED, payload: null });
    dispatch(getChargeBackList(event?.target?.textContent?.toLowerCase()));
    setValue(newValue);
    history.push(newValue);
  };

  return (
    <Hidden mdDown={mdDown}>
      <Box sx={{ width: '100%', borderTop: '1px solid #0000001F' }}>
        <Box sx={{ width: '100%' }}>
          <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: '#2D7FF9',
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            selectionFollowsFocus
            value={value}
            onChange={handleChange}
          >
            {ROUTES(t).map(({ id, path, name }, index) => (
              <Tab
                sx={{
                  '&.Mui-selected': { color: '#3C4350' },
                }}
                key={id || path}
                value={`/chargeback${path}`}
                label={name}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </Hidden>
  );
};
export default HeaderTabs;
