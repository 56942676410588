import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
function DraggableDialogWrapper(props) {
  return (
    <Draggable handle=".MuiDialogTitle-root" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
export default DraggableDialogWrapper;
