import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { StripeTextFieldNumber, StripeTextFieldExpiry, StripeTextFieldCVC } from './CommonTextFields';

export default function CreditCardDetail({ setIsDirtyCard = () => {}, handleCardError = () => {} }) {
  const [state, setState] = React.useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: null,
    expiredError: null,
    cvcError: null,
  });

  const onElementChange = (field, errorField) => ({ complete, error = { message: null } }) => {
    setIsDirtyCard({
      [field]: true,
    });
    setState({ ...state, [field]: complete, [errorField]: error.message });
  };

  const { cardNumberError, expiredError, cvcError } = state;
  useEffect(() => {
    if (
      cardNumberError != null ||
      expiredError != null ||
      cvcError != null ||
      !state.expiredComplete ||
      !state.cardNumberComplete ||
      !state.cvcComplete
    ) {
      handleCardError(
        cardNumberError ||
          expiredError ||
          cvcError ||
          ((!state.expiredComplete || !state.cardNumberComplete || !state.cvcComplete) &&
            'Payment information is required')
      );
    } else {
      handleCardError(false);
    }
  }, [state]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <StripeTextFieldNumber
          error={Boolean(cardNumberError)}
          labelErrorMessage={cardNumberError}
          onChange={onElementChange('cardNumberComplete', 'cardNumberError')}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeTextFieldExpiry
          error={Boolean(expiredError)}
          labelErrorMessage={expiredError}
          onChange={onElementChange('expiredComplete', 'expiredError')}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeTextFieldCVC
          error={Boolean(cvcError)}
          labelErrorMessage={cvcError}
          onChange={onElementChange('cvcComplete', 'cvcError')}
        />
      </Grid>
    </Grid>
  );
}
