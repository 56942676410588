import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';

export const MainContainer = styled(Box)({
  // borderTop: '1px solid rgb(0 0 0 / 12%)',
  borderBottom: '1px solid rgb(0 0 0 / 12%)',
  // paddingTop: 32,
  paddingBottom: 32,
  'p.Mui-error': {
    display: 'none',
  },
});

export const MainRows = styled(Box)({
  display: 'flex',
  gap: 16,
  marginBottom: 24,
  alignItems: 'center',
  width: '100%',
});

export const ButtonWrap = styled(Button)({
  minWidth: 30,
  height: 30,
  color: 'rgba(146, 147, 152, 1)',
});
