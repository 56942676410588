import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { signInTowFactor } from 'redux/actions/user';
import { yupResolver } from '@hookform/resolvers/yup';
export const TOW_FACTOR_BLOCK_MESSAGE = 'Your account blocked. Please try again later.';
export const TOW_FACTOR_BLOCK_MESSAGE_CLOSE = 'Two factor authentication code has expired. Please log in again.';

const defaultValuesVerify = {
  twoFactorAuthenticationCode: '',
};
const schemaVerify = yup
  .object()
  .shape({
    twoFactorAuthenticationCode: yup.string().required('This field is required'),
  })
  .required();

const useTowFactor = (open, towFactorKey, handleClose) => {
  const dispatch = useDispatch();
  const [block, setBlock] = useState(false);
  const [loading, setLoading] = useState(false);

  const loginInMethod = useForm({
    mode: 'all',
    defaultValues: defaultValuesVerify,
    resolver: yupResolver(schemaVerify),
  });

  useEffect(() => {
    if (!open) {
      loginInMethod.clearErrors();
      loginInMethod.reset(defaultValuesVerify);
    }
  }, [loginInMethod, open]);

  const handleValidSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await dispatch(signInTowFactor(data));
      setLoading(false);
      if (res?.data?.data?.token) {
        handleClose();
      }
      return res;
    } catch (e) {
      setBlock(Boolean(TOW_FACTOR_BLOCK_MESSAGE === e?.response?.data?.message));
      if (TOW_FACTOR_BLOCK_MESSAGE_CLOSE === e?.response?.data?.message) {
        setBlock(false);
        handleClose();
      }
      setLoading(false);
      return false;
    }
  };

  const handleSubmit = (values) => {
    values['key'] = towFactorKey;
    handleValidSubmit(values).then();
  };

  return {
    loginInMethod,
    handleSubmit,
    loading,
    block,
  };
};

export default useTowFactor;
