import { useCallback, useState } from 'react';
import { MIN_PICKUP_TIME } from './constants';

/**
 * Hook for managing pickup time using validation
 *
 * @param {number} defaultPrepTime
 * @param {number} maxTime
 * @returns
 */
export function usePickupTime(defaultPrepTime, maxTime, isScheduled) {
  const [pickupTime, setPickupTime] = useState(defaultPrepTime);

  const handleChangePickupTime = useCallback(
    (newValue) => {
      const validatedTime = getValidatedPickupTime(newValue, isScheduled ? 0 : MIN_PICKUP_TIME, maxTime);
      setPickupTime(validatedTime);
    },
    [isScheduled, maxTime]
  );

  return [pickupTime, handleChangePickupTime];
}

/**
 * Pickup time validation helper
 *
 * @param {number} currentTime
 * @param {number} minTime
 * @param {number} maxTime
 * @returns
 */
export function getValidatedPickupTime(currentTime, minTime = MIN_PICKUP_TIME, maxTime) {
  let newValue = currentTime;

  if (currentTime <= minTime) {
    newValue = minTime;
  } else if (newValue >= maxTime) {
    newValue = maxTime;
  }

  return newValue;
}
