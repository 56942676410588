import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import useProviderConfig from 'hooks/useProviderConfig';
import { PAUSE_RESUME_STATUSES, PAUSE_RESUME_STATUSES_LABEL } from 'constants/resumePause';

export function useProviderTimer(providers) {
  const { t } = useTranslation();
  const { providers: providerConfig } = useProviderConfig();
  const [updatedProviders, setUpdatedProviders] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);

      const updated = providers.map((provider) => {
        let updatedProvider = { ...provider };

        if (updatedProvider?.paused_until) {
          const timeLeft = updatedProvider.paused_until - now;

          if (timeLeft <= 0) {
            updatedProvider.provider_status = PAUSE_RESUME_STATUSES.RECEIVING;
            updatedProvider.paused_until = null;
            updatedProvider.immutable_until = null;
            updatedProvider.time_remaining = t(`${PAUSE_RESUME_STATUSES_LABEL.RECEIVING}`);
          } else {
            const hours = String(Math.floor(timeLeft / 3600)).padStart(2, '0');
            const minutes = String(Math.floor((timeLeft % 3600) / 60)).padStart(2, '0');
            const seconds = String(timeLeft % 60).padStart(2, '0');

            const timerMs = updatedProvider.paused_until * 1000 - Date.now();
            const duration = moment.duration(timerMs, 'milliseconds');

            if (duration > 700000000) {
              updatedProvider.time_remaining = t(`store_status_notification.permanently_paused_title`);
            } else {
              updatedProvider.time_remaining = `${updatedProvider.provider_status || ''} ${
                hours !== '00' ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`
              }`;
            }
          }
        } else {
          const isProviderUnsupported =
            !providerConfig[updatedProvider?.provider]?.providerStatusSupported ||
            (updatedProvider.provider_status === PAUSE_RESUME_STATUSES.NA && !updatedProvider.paused_until);

          if (isProviderUnsupported) {
            updatedProvider.provider_status = 'N/A';
            updatedProvider.paused_until = null;
            updatedProvider.immutable_until = null;
            updatedProvider.time_remaining = 'N/A';
          } else {
            updatedProvider.provider_status = PAUSE_RESUME_STATUSES.RECEIVING;
            updatedProvider.paused_until = null;
            updatedProvider.immutable_until = null;
            updatedProvider.time_remaining = t(`${PAUSE_RESUME_STATUSES_LABEL.RECEIVING}`);
          }
        }

        return updatedProvider;
      });

      setUpdatedProviders([...updated]);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [providers, providerConfig]);

  return updatedProviders;
}
