import { Box, Typography } from '@mui/material';
import Currency from 'components/Currency';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { capitalizedWord } from 'utils/globalUtils';

const OrderPaymentDetails = ({ data }) => {
  const { t } = useTranslation();
  const createdAt = data?.payment?.payment_method_details?.created_at;

  const convertPayment = (method) => {
    const PAYMENTS = {
      google_pay: 'Google Pay',
      apple_pay: 'Apple Pay',
      cash: 'Cash',
      card: 'Card',
      credit: 'External Card',
      reward: 'Reward',
      coupon: 'Coupon',
    };
    const resultWithoutSymbol = method?.charAt(0)?.toUpperCase() + method?.slice(1);
    return PAYMENTS[method] || resultWithoutSymbol;
  };

  return (
    <Box className="payment-container">
      <Box>
        <Box display="flex" justifyContent="space-between">
          {data?.payment?.payment_method_details?.card?.wallet?.type ? (
            <Typography className="payment-title">
              {`${t('orders.Payments')}: ${t('orders.by')} `}
              {convertPayment(data?.payment?.payment_method_details?.card?.wallet?.type)}
            </Typography>
          ) : (
            <>
              {data?.payment?.payment_method_details?.card?.brand ||
              data?.payment?.payment_method_details?.card?.last4 ? (
                <Typography className="payment-title">
                  {`${t('orders.Payments')}: ${t('orders.by')} `}
                  {t('Card')}
                </Typography>
              ) : (
                <></>
              )}
            </>
          )}
          {data?.payment?.payment_method_details?.card?.wallet?.type ||
          data?.payment?.payment_method_details?.card?.brand ||
          data?.payment?.payment_method_details?.card?.last4 ? (
            <Typography className="payment-title">
              <Currency
                sx={{
                  '& strong': {
                    fontWeight: 400,
                  },
                }}
                value={data.charges.total}
              />
            </Typography>
          ) : (
            <></>
          )}
        </Box>
        {data?.payment?.payment_method_details?.card?.brand ? (
          <Typography className="cart-number">
            {`${t('orders.Card Type')}: `}
            {`${capitalizedWord(data?.payment?.payment_method_details?.card?.brand || '')}`}
          </Typography>
        ) : (
          <></>
        )}

        {data?.payment?.payment_method_details?.card?.last4 ? (
          <Typography className="cart-number">
            {`${t('orders.Card no')}: `}
            {`XXXX XXXX XXXX ${data?.payment?.payment_method_details?.card?.last4}`}
          </Typography>
        ) : (
          <></>
        )}
        {createdAt ? (
          <Typography className="cart-number-title">
            {`${t('orders.Date')}: `}{' '}
            {moment(createdAt)
              .tz(data?.orderMetaData?.business?.timezone || 'America/Los_Angeles')
              .format('hh:mm A - ddd, MMM Do')}
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default OrderPaymentDetails;
