import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Statuses from 'constants/providerStatuses';
import { getReportListForHome } from 'redux/actions/reports';
import { getPreviousPeriod, processSelectFilter } from 'components/NewHome/helpers/helpers';
import { useReportPeriods } from 'components/NewHome/hooks/useReportPeriods';
import { ReportingFilterDefaultValues, ReportType } from 'components/NewHome/constants/constatnts';

const useProviderReport = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const providers = useSelector(({ providers }) => providers);
  const { periods, query, currentBusiness } = useReportPeriods();
  const [reportBySalesProvider, setReportBySalesProvider] = useState([]);

  const filteredReports = useMemo(() => {
    return reportBySalesProvider?.filter((report) => {
      const itemKey = report?.srv === 'website' ? 'food2' : report?.srv;
      return providers?.items?.some(
        (provider) =>
          provider?.srv === itemKey &&
          provider?.status === Statuses.PROVIDER_CONNECTED &&
          provider?.state === Statuses.PROVIDER_STATE_ACTIVE
      );
    });
  }, [reportBySalesProvider, providers]);
  const handleGetReports = useCallback(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        const selectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));
        const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
        const data = {
          days_of_week: ReportingFilterDefaultValues.days_of_week,
          providers: ReportingFilterDefaultValues.providers,
          type: ReportType.SALES_BY_PROVIDER,
          brandIds: [selectedBrand?.value?._id],
          locationIds: [selectedLocation?.value?._id || selectedBrand?.value?.locations[0]?._id],
        };
        const homeReportFilters = processSelectFilter(
          periods[query.period]?.label,
          currentBusiness?.timezone.timeZoneId,
          data
        );
        const previousPeriod = getPreviousPeriod(homeReportFilters.start_date, homeReportFilters.end_date);
        const previousData = {
          days_of_week: ReportingFilterDefaultValues.days_of_week,
          providers: ReportingFilterDefaultValues.providers,
          type: ReportType.SALES_BY_PROVIDER,
          start_date: previousPeriod.prevStartDate,
          end_date: previousPeriod.prevEndDate,
          brandIds: [selectedBrand?.value?._id],
          locationIds: [selectedLocation?.value?._id || selectedBrand?.value?.locations[0]?._id],
        };
        const response = await dispatch(getReportListForHome(homeReportFilters));
        const previousResponse = await dispatch(getReportListForHome(previousData));

        response.forEach((res) => {
          const found = previousResponse.find((item) => item.provider?.toLowerCase() === res.provider?.toLowerCase());
          if (found) {
            res['prevTotalSales'] = parseFloat(found?.total_sales?.replace(/[$,]/g, ''));
            res['prevTotalOrders'] = found?.total_orders;
          }
        });
        if (isMounted) {
          await setReportBySalesProvider(response);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [currentBusiness, query.period, periods]);

  useEffect(() => {
    handleGetReports();
  }, [handleGetReports]);

  return {
    loading,
    filteredReports,
  };
};
export default useProviderReport;
