import { useState, useEffect, useContext, memo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { getPlans, getPlansUser, getUserBilling } from 'redux/actions/user';
import SelectedIcon from 'assets/icons/selected-icon.svg';
import Check from 'assets/icons/subscriptionIcon.svg';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { List, ListItem } from '@mui/material';
import { getSubscriptionForBrand, getSubscriptionForSpecialLocation } from 'redux/actions/userAuthV2';
import CircularProgress from '@mui/material/CircularProgress';
import { SUBSCRIPTION_TYPE } from 'constants/enumSoldOut';
import { PaymentPeriodicityContext } from 'context/paymentPeriodicityContext';
import { PaymentPeriodicityEnum } from 'constants/config';
import { formatPrice } from 'helpers/formPrice';
import SubscriptionPlanPeriodicityTabs from './SubscriptionPlanPeriodicityTabs';
import useQuery from 'hooks/useQuery';
import { useTranslation } from 'react-i18next';
import { SubscriptionContext } from 'context/subscriptionPlanConfigContext';

const SubscriptionPlans = ({
  handleSelectedPlan = () => {},
  getSelectedPlanPrice = () => {},
  selectedPlan,
  disabled = false,
  required = true,
  filterable = false,
  isSubmitted,
  target = '',
  type = SUBSCRIPTION_TYPE.BUNDLE,
  addonPlan,
  hadlePlanChoose = () => {},
  selectedPlanPrice,
  setSelectedPlanData = () => {},
  setCheckNewPlans = () => {},
  isSpecialBrand,
  couponValue = '',
  isSpecialLocation,
}) => {
  const { t } = useTranslation();
  const { setFreeTrialPeriod, setSetupFee, setSetupFeeEnabled, setFreeTrialIsEnabled } = useContext(
    SubscriptionContext
  );
  const history = useHistory();
  const [plan, getPlan] = useState([]);
  const [disablePeriodicity, setDisablePeriodicity] = useState(false);
  const { periodicity, setPeriodicity } = useContext(PaymentPeriodicityContext);
  const query = useQuery();
  const businessId = query.get('businessId');
  const legalEntityId = query.get('legalEntityId');
  const userId = query.get('userId');
  const planId = query.get('planId');
  const term = query.get('term');
  const isApplyValue = query.get('isApply');
  const [loading, setLoading] = useState(true);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const location = useLocation();
  const isAuth = localStorage.getItem('accessToken');
  const isInBilling = location.pathname === '/settings/billing';
  const isInSignupBilling = location.pathname === '/signup/billingInfo';

  const handleGetCurrentSetupFeeValue = (id) => {
    let currentSetupFee = plan.find((item) => item?._id === id);
    if (currentSetupFee && currentSetupFee?.configs?.length) {
      let currentConfig = currentSetupFee?.configs.find((item) => item.paymentTerm === periodicity);
      setSetupFee(currentConfig?.setupFeeAmount || 0);
      let enabled = currentConfig?.setupFeeEnabled ? 1 : 0;
      setSetupFeeEnabled(Number(enabled));
      setFreeTrialIsEnabled(currentConfig?.freeTrialEnabled);
      setFreeTrialPeriod(currentConfig?.freeTrialPeriod);
      localStorage.setItem('currentSetupFee', currentConfig?.setupFeeAmount || 0);
      localStorage.setItem('enabledSetupFee', enabled);
      localStorage.setItem('freeTrialEnabled', currentConfig?.freeTrialEnabled);
    }
  };

  useEffect(() => {
    if (isInBilling) {
      setPeriodicity(null);
    }

    if ((isInBilling && target === 'signup') || isInSignupBilling) {
      handleGetCurrentSetupFeeValue(planId);
      const planFind = plan.every((item) => item?.priceMonthly && !item?.priceYearly);
      const showTerm = planFind ? PaymentPeriodicityEnum.monthly : term;
      let url =
        window.origin +
        history.location.pathname +
        `?businessId=${businessId}&legalEntityId=${legalEntityId}&userId=${userId}&term=${showTerm}`;
      if (planId) {
        url += `&planId=${planId}`;
      }
      if (couponValue) {
        url += `&couponValue=${couponValue}`;
      }
      if (isApplyValue) {
        url += `&isApply=${isApplyValue}`;
      }
      window.history.pushState({ path: url }, '', url);
    }
  }, [plan]);

  const getCheckPlans = (plans) => {
    plans()
      .then(({ data: { data } }) => {
        checkNonHiddenHaveYearly(data);
        if (target === 'signup') {
          const filtered = data.filter((item) => item._id === selectedPlan)[0];
          if (filtered) {
            const price = periodicity === PaymentPeriodicityEnum.monthly ? filtered.priceMonthly : filtered.priceYearly;
            getSelectedPlanPrice(price);
          }
          setSelectedPlanData(data);
          if (filterable) {
            getPlan(data.filter((item) => item._id === selectedPlan));
          } else {
            getPlan(data);
          }
        } else {
          getUserBilling().then((res) => {
            // data.forEach((element) => {
            //   if (res.data.data.planPrice) {
            //     element.planPrice = isYearly ? res.data.data.plan.priceYearly : res.data.data.plan.priceMonthly;
            //   }
            // });
            const filtered = data.filter((item) => item._id === selectedPlan)[0];
            const price = periodicity === PaymentPeriodicityEnum.monthly ? filtered.priceMonthly : filtered.priceYearly;
            if (filtered) {
              getSelectedPlanPrice(price);
            }
            if (filterable) {
              getPlan(data.filter((item) => item._id === selectedPlan));
            } else {
              getPlan(data);
            }
          });
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const checkNonHiddenHaveYearly = (data) => {
    // const dataWIthoutHidden = data.filter((planItem) => !planItem.hidden);
    const isAllPlansHavePriceYearly = data.every((item) => item.priceYearly !== undefined);
    if (isAllPlansHavePriceYearly) {
      setCheckNewPlans(true);
      setDisablePeriodicity(false);
    } else {
      setCheckNewPlans(false);
      setDisablePeriodicity(true);
    }
  };

  useEffect(() => {
    if (type === SUBSCRIPTION_TYPE.ADDON) {
      getPlan([addonPlan]);
      setLoading(false);
    } else {
      if (target === 'brand') {
        setLoading(true);
        getSubscriptionForBrand(isSpecialBrand)
          .then(({ data: { data } }) => {
            checkNonHiddenHaveYearly(data);
            const filtered = data.filter((item) => item._id === selectedPlan)[0];

            if (filtered) {
              const price =
                periodicity === PaymentPeriodicityEnum.monthly ? filtered.priceMonthly : filtered.priceYearly;
              getSelectedPlanPrice(price);
            }
            filterable ? getPlan(data.filter((item) => item._id === selectedPlan)) : getPlan(data);
            setTimeout(() => {
              setLoading(false);
            }, 300);
          })
          .catch(() =>
            setTimeout(() => {
              setLoading(false);
            }, 300)
          );
      } else if (isSpecialLocation && target === 'location') {
        setLoading(true);
        getSubscriptionForSpecialLocation(isSpecialLocation)
          .then(({ data: { data } }) => {
            checkNonHiddenHaveYearly(data);
            const filtered = data.filter((item) => item._id === selectedPlan)[0];

            if (filtered) {
              const price =
                periodicity === PaymentPeriodicityEnum.monthly ? filtered.priceMonthly : filtered.priceYearly;
              getSelectedPlanPrice(price);
            }
            filterable ? getPlan(data.filter((item) => item._id === selectedPlan)) : getPlan(data);
            setTimeout(() => {
              setLoading(false);
            }, 300);
          })
          .catch(() =>
            setTimeout(() => {
              setLoading(false);
            }, 300)
          );
      } else {
        setLoading(true);
        if (isInBilling && isAuth) {
          getCheckPlans(getPlansUser);
        } else {
          getCheckPlans(getPlans);
        }
      }
    }
  }, []);

  const handleChangePlan = (event) => {
    event.preventDefault();
    event.currentTarget.dataset.id && localStorage.setItem('currentPlan', event.currentTarget.dataset.id);
    handleGetCurrentSetupFeeValue(event.currentTarget.dataset.id);
    hadlePlanChoose(event.currentTarget.dataset.id);
    handleSelectedPlan(event.currentTarget.dataset.id);
    getSelectedPlanPrice(event.currentTarget.dataset.price);
  };

  useEffect(() => {
    if (selectedPlan) {
      handleGetCurrentSetupFeeValue(selectedPlan);
    }
  }, [periodicity, plan, selectedPlan]);

  const handleChangePeriod = (value) => {
    setPeriodicity(value);
  };

  const isYearly = periodicity === PaymentPeriodicityEnum.yearly;

  const renderSelectedPlan = (data) => {
    if ((!periodicity && isInBilling && target !== 'signup') || loadingBrand) {
      return <CircularProgress size={25} />;
    }
    if (
      (isYearly ? plan.priceYearly : plan.priceMonthly) !== (isYearly ? data.priceYearly : data.priceMonthly) &&
      isYearly
        ? plan.priceYearly
        : plan.priceMonthly && data._id === selectedPlan
    ) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                textDecoration: 'line-through',
                background: '#E3E3E3',
                fontSize: '14px',
                borderRadius: '27px',
                padding: '6px 16px',
                color: '#000000',
                fontWeight: 700,
                width: 'max-content',
              }}
            >
              {formatPrice(isYearly ? data.priceMonthly * 12 : data.priceMonthly)}
            </Typography>
            <Box component="span" sx={{ fontSize: '48px', color: '#E3E3E3', mx: 1 }}>
              /
            </Box>
            <Typography
              sx={{
                color: '#2D7FF9',
                fontSize: '32px',
                fontWeight: 500,
              }}
            >
              {formatPrice(isYearly ? plan.priceYearly : plan.priceMonthly)}
            </Typography>
          </Box>
          <Box sx={{ width: '100%', display: 'flex' }}>
            <Typography sx={{ fontSize: '14px', color: '#000000', fontWeight: 700, mt: 0.5 }}>
              {PaymentPeriodicityEnum[periodicity]}
            </Typography>

            <Typography sx={{ fontSize: '14px', ml: 5 }} variant="subtitle2">
              {t('settings.price_for_you')}
            </Typography>
          </Box>
        </Box>
      );
    } else {
      return !isYearly ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
          <Typography
            variant="h3"
            sx={{
              fontSize: '32px',
              // fontFamily: 'Roboto, Medium',
              // color: '#000000',
              fontWeight: 500,
              width: 'max-content',
            }}
          >
            {formatPrice(data.priceMonthly)}
          </Typography>

          <Typography
            sx={{ width: 'max-content', fontSize: '14px', color: '#000000', fontWeight: 700, mt: 0.5 }}
            variant="subtitle2"
          >
            /{t(PaymentPeriodicityEnum[periodicity])}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: 'max-content' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box>
              <Typography
                sx={{
                  textDecoration: 'line-through',
                  background: '#E3E3E3',
                  fontSize: '14px',
                  borderRadius: '27px',
                  padding: '6px 16px',
                  color: '#000000',
                  fontWeight: 700,
                  width: 'max-content',
                }}
              >
                {formatPrice(data.priceMonthly * 12)}
              </Typography>
            </Box>
            <Box component="span" sx={{ fontSize: '48px', color: '#E3E3E3', mx: 1 }}>
              /
            </Box>
            <Typography
              sx={{
                color: '#2D7FF9',
                fontSize: '32px',
                fontWeight: 500,
              }}
            >
              {formatPrice(data.priceYearly)}
            </Typography>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '14px', color: '#000000', fontWeight: 700, padding: 0 }}>
              /{PaymentPeriodicityEnum[periodicity]}
            </Typography>
            <Box component="span" sx={{ mx: 3, fontSize: '48px', color: '#E3E3E3' }} />

            <Typography sx={{ fontSize: '14px' }} variant="subtitle2">
              {t('settings.price_for_you')}
            </Typography>
          </Box>
        </Box>
      );
    }
  };

  const gridSizeDesctop = isInBilling ? 3 : 4;
  return (
    <div className="">
      {loading && (
        <Box sx={{ textAlign: 'center', margin: '0 auto', paddingTop: '200px' }}>
          <CircularProgress style={{ width: '45px', height: '45px' }} />
        </Box>
      )}
      <Box>
        {plan?.length || (isInBilling && !loading) ? (
          <SubscriptionPlanPeriodicityTabs
            setLoading={setLoading}
            setLoadingBrand={setLoadingBrand}
            handleChangePeriod={handleChangePeriod}
            periodicity={periodicity}
            isDisabled={disablePeriodicity}
            selectedPlan={selectedPlan}
          />
        ) : (
          <></>
        )}
      </Box>
      {!loading && (
        <Grid spacing={2} container>
          {plan.map((item) => {
            const findConfig = item?.configs?.find((item) => item?.paymentTerm === periodicity);
            if (!findConfig) {
              return;
            }
            return (
              <Grid item key={item._id} lg={gridSizeDesctop} md={gridSizeDesctop} sm={6}>
                <Card
                  sx={{
                    maxWidth: '329px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    background: '#F4F4F4',
                    boxShadow: '0 0 0 transparent',
                    p: 2,
                  }}
                >
                  <CardHeader
                    sx={{ color: '#181818', p: 0 }}
                    title={
                      <Box>
                        <Typography align="left" variant="h4" sx={{ fontWeight: 700, fontSize: '20px' }}>
                          {item.name}
                        </Typography>
                        {item.type === 'addon' && (
                          <Typography align="left" sx={{ fontWeight: 400, fontSize: '14px', mt: 1 }}>
                            {t('settings.additional_brand')}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                  <CardContent sx={{ p: 0 }}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        alignItems: 'left',
                      }}
                    >
                      <Box sx={{ my: 2 }}>{renderSelectedPlan(item)}</Box>
                      <List disablePadding sx={{ mt: 2, minHeight: '230px' }}>
                        {item.options.map((op, index) => (
                          <ListItem
                            key={index}
                            sx={{ color: '#000000', fontWeight: 500, fontSize: '15px', p: 0, pb: 2 }}
                          >
                            <img src={Check} alt="check" style={{ marginRight: '10px' }} />
                            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
                              {op.includes('&amp;') ? op.replace('&amp;', '&') : op}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </CardContent>
                  <CardActions disableSpacing sx={{ p: 0 }}>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                      }}
                    >
                      <Button
                        id="changePlan"
                        sx={{ width: '100%', background: '#2D7FF9', padding: '10px', boxShadow: '0 0 0 transparent' }}
                        color={selectedPlan !== item._id ? 'primary' : 'secondary'}
                        variant="contained"
                        data-id={item._id}
                        data-price={
                          periodicity === PaymentPeriodicityEnum.monthly ? item.priceMonthly : item.priceYearly
                        }
                        disabled={disabled}
                        onClick={handleChangePlan}
                      >
                        {selectedPlan !== item._id ? (
                          <span className="">{t('settings.select')}</span>
                        ) : (
                          <>
                            <img src={SelectedIcon} alt="SELECTED-ICON" />
                            <span className="ml-2">{t('settings.selected')}</span>
                          </>
                        )}
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}

          {isSubmitted && required && !selectedPlan && (
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '12px' }} color="error">
                {t('header.subscription_plan_required')}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default memo(SubscriptionPlans);
