import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from 'assets/icons/closeIconSvg.svg';
import CloseIconMui from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { useDispatch } from 'react-redux';
import {
  generateDescription,
  getDescriptionLimits,
  getGeneratedIngredients,
  getIngredientsLimits,
} from 'redux/actions/Menu/products';
import useQuery from 'hooks/useQuery';
import { SET_INGREDIENTS_V2 } from 'redux/actions/types';
import { CHAT_GPT_ENUM } from 'constants/chatGpt';
import { generationBusinessDescription, generationBusinessLimits } from 'redux/actions/business';
import { useTranslation } from 'react-i18next';

import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import extractNamesFromArrayOfObjects from 'utils/extractNamesFromArrayOfObjects';

const DialogChatGptDesc = ({
  open,
  setOpenChatGptGeneratePopup,
  formMethods,
  textData,
  popularItems,
  ingredients = [],
}) => {
  const { t } = useTranslation();
  const generateBtn = t('settings.generate_AI');
  const reGenerateBtn = t('settings.regenerate_AI');
  const [generateLoading, setGenerateLoading] = useState(false);
  const [autoGenerateLoading, setAutoGenerateLoading] = useState(false);
  const query = useQuery();
  const productId = query.get('pid');
  const dispatch = useDispatch();
  const chatGptScheme = yup.object().shape({
    [textData.formName.name3]: yup.string(),
    [textData.formName.name2]: yup.string().max(2000, t('context_2000_characters')),
  });

  const [inputValue, setInputValue] = useState('');
  const [chatGptData, setChatGptData] = useState(null);
  const [limits, setLimits] = useState({
    ingredients:
      textData.target === CHAT_GPT_ENUM.PRODUCT
        ? {
            limit: 3,
            count: 3,
          }
        : null,
    description: {
      limit: 3,
      count: 3,
    },
  });
  const chatGptModel = useForm({
    mode: 'all',
    defaultValues: {
      [textData.formName.name1]: textData.target === CHAT_GPT_ENUM.PRODUCT ? [] : popularItems || [],
      [textData.formName.name2]: textData.target === CHAT_GPT_ENUM.PRODUCT ? '' : '',
      [textData.formName.name3]: '',
    },
    resolver: yupResolver(chatGptScheme),
  });

  useEffect(() => {
    if (textData.target === CHAT_GPT_ENUM.PRODUCT) {
      if (ingredients?.length > 0) {
        let filteredIngredients = extractNamesFromArrayOfObjects(ingredients);
        setChatGptData({ ...chatGptData, ingredients: filteredIngredients });
        dispatch({ type: SET_INGREDIENTS_V2, payload: filteredIngredients });
        chatGptModel.setValue(textData.formName.name1, filteredIngredients, { shouldDirty: false });
      } else {
        setChatGptData({ ...chatGptData, ingredients: [] });
        dispatch({ type: SET_INGREDIENTS_V2, payload: [] });
        chatGptModel.setValue(textData.formName.name1, [], { shouldDirty: false });
      }
      getIngredientsLimits(productId).then(({ data: { data } }) => {
        setLimits((prevProps) => {
          return {
            ingredients: {
              limit: data.limit,
              count: data.count,
            },
            description: {
              limit: prevProps.description.limit,
              count: prevProps.description.count,
            },
          };
        });
      });
      getDescriptionLimits(productId).then(({ data: { data } }) => {
        setLimits((prevProps) => {
          return {
            ingredients: {
              limit: prevProps.ingredients.limit,
              count: prevProps.ingredients.count,
            },
            description: {
              limit: data.limit,
              count: data.count,
            },
          };
        });
      });
    } else {
      generationBusinessLimits().then(({ data: { data } }) => {
        setLimits({
          ingredients: null,
          description: {
            limit: data.limit,
            count: data.count,
          },
        });
      });
    }
  }, [open, productId, textData.target]);

  const handleCloseGeneratePopup = () => {
    setOpenChatGptGeneratePopup(false);
    dispatch({ type: SET_INGREDIENTS_V2, payload: [] });
    chatGptModel.reset();
    chatGptModel.clearErrors();
    setInputValue('');
    setChatGptData(null);
    setInputValue('');
  };

  useEffect(() => {
    let currentValues = chatGptModel.getValues();
    if (popularItems?.length && textData.target !== CHAT_GPT_ENUM.PRODUCT) {
      chatGptModel.reset({
        ...currentValues,
        [textData.formName.name1]: popularItems || [],
        [textData.formName.name2]: '',
      });
    } else if (textData.target !== CHAT_GPT_ENUM.PRODUCT) {
      chatGptModel.reset({
        ...currentValues,
        [textData.formName.name1]: [] || [],
        [textData.formName.name2]: '',
      });
    }
  }, [popularItems, textData.target, open]);
  const handleChatGptGenerate = () => {
    if (Object.keys(chatGptModel.formState.errors)?.length) {
      return;
    }
    const values = chatGptModel.getValues();

    const body = {
      [textData.formName.name1]: values[textData.formName.name1],
      [textData.formName.name2]: values[textData.formName.name2],
    };
    setGenerateLoading(true);
    if (textData.target === CHAT_GPT_ENUM.PRODUCT) {
      generateDescription(productId, body)
        .then(({ data: { data } }) => {
          setChatGptData({ description: data });
          setGenerateLoading(false);
          setLimits({
            ...limits,
            description: {
              limit: data.limit,
              count: data.count,
            },
          });
          chatGptModel.setValue('productDescription', data.productDescription, { shouldDirty: true });
        })
        .catch(() => {
          setGenerateLoading(false);
        });
    } else {
      generationBusinessDescription(body)
        .then(({ data: { data } }) => {
          setChatGptData({ description: data });
          setGenerateLoading(false);
          setLimits({
            ...limits,
            description: {
              limit: data.limit,
              count: data.count,
            },
          });
          chatGptModel.setValue(textData.formName.name3, data[textData.formName.name3], { shouldDirty: true });
        })
        .catch(() => {
          setGenerateLoading(false);
        });
    }
  };

  const handleAutogenerate = () => {
    setAutoGenerateLoading(true);
    getGeneratedIngredients(productId)
      .then(({ data: { data } }) => {
        dispatch({ type: SET_INGREDIENTS_V2, payload: data[textData.formName.name1] });
        setChatGptData({ ...chatGptData, ingredients: data });
        setAutoGenerateLoading(false);
        setLimits({
          ...limits,
          ingredients: {
            limit: data.limit,
            count: data.count,
          },
        });
        chatGptModel.setValue(textData.formName.name1, data[textData.formName.name1], { shouldDirty: true });
      })
      .catch(() => {
        setAutoGenerateLoading(false);
      });
  };

  const handleUseText = () => {
    if (textData.target === CHAT_GPT_ENUM.PRODUCT) {
      formMethods.setValue('description', chatGptModel.getValues().productDescription, {
        shouldDirty: true,
      });
      formMethods.setValue('ingredients', chatGptModel.getValues().productIngredients, {
        shouldDirty: true,
      });
    } else {
      formMethods.setValue('about', chatGptModel.getValues()[textData.formName.name3], {
        shouldDirty: true,
      });
    }

    chatGptModel.reset();
    chatGptModel.clearErrors();
    setInputValue('');
    setChatGptData(null);
    setOpenChatGptGeneratePopup(false);
  };

  // useEffect(() => {
  //   const popularItems = JSON.parse(localStorage.getItem('popularItems'));
  //   const context = JSON.parse(localStorage.getItem('context'));
  //   chatGptModel.reset({
  //     [textData.formName.name3]: popularItems,
  //     [textData.formName.name2]: context,
  //     [textData.formName.name3]: '',
  //   });
  // }, []);

  const handleAddItem = (event) => {
    const newValue = event.target.value;
    if ((event.key === ',' || event.key === 'Enter') && newValue !== '') {
      chatGptModel.setValue(textData.formName.name1, [newValue, ...chatGptModel.getValues()[textData.formName.name1]], {
        shouldDirty: true,
      });
      setInputValue('');
    }
  };

  return (
    <Dialog
      sx={{
        p: 4,
        width: '100%',
      }}
      PaperComponent={DraggableDialogWrapper}
      maxWidth="md"
      open={open}
    >
      <FormProvider {...chatGptModel}>
        <Box p={4} sx={{ width: '763px', display: 'flex', flexDirection: 'column', cursor: 'grab' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} sx={{ cursor: 'grab' }}>
            <Typography color="#050505" lineHeight="32px" fontSize={24} fontWeight={700}>
              {t(textData.title)}
            </Typography>
            <Box component="img" role="button" src={CloseIcon} alt="close" onClick={handleCloseGeneratePopup} />
          </Box>
          <Box mb={4} sx={{ cursor: 'grab' }}>
            <Typography color="#929398" lineHeight="21px" fontSize={14}>
              {t(textData.desc)}
            </Typography>
          </Box>
          <DialogContent sx={{ padding: 0, cursor: 'auto' }}>
            <Typography color="#050505" lineHeight="26px" fontSize={16} fontWeight={700} mb={2}>
              {t(textData.formTitle.name1)}
            </Typography>
            <Box display="flex">
              <Controller
                name={textData.formName.name1}
                render={({ field }) => {
                  const { ref, onChange } = field;
                  field.value = field.value ? field.value : '';
                  return (
                    <Autocomplete
                      {...field}
                      multiple
                      id="multiple-limit-tags"
                      options={[]}
                      freeSolo
                      clearIcon={
                        <CloseIconMui
                          fontSize="small"
                          onClick={() => {
                            setChatGptData(null);
                            chatGptModel.setValue(textData.formName.name1, [], { shouldDirty: true });
                            chatGptModel.resetField(textData.formName.name1);
                          }}
                        />
                      }
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      onKeyDown={handleAddItem}
                      renderTags={(value, getTagProps) => {
                        return value.map((option, index) => {
                          return (
                            <Chip
                              variant="outlined"
                              sx={{
                                background: '#ebebeb',
                                border: 'none',
                              }}
                              label={option}
                              {...getTagProps({ index })}
                            />
                          );
                        });
                      }}
                      renderInput={(params) => {
                        params.inputProps.value = inputValue.replace(',', '');
                        return (
                          <TextField
                            InputProps={{
                              rows: 3,
                            }}
                            onChange={(event) => setInputValue(event.target.value)}
                            inputRef={ref}
                            autoFocus
                            {...params}
                            placeholder={t(textData.formPlaceholder.name1)}
                          />
                        );
                      }}
                      sx={{
                        width: '100%',
                        maxWidth: limits.ingredients ? '470px' : '100%',
                        minHeight: '128px',
                        mr: limits.ingredients ? 2 : 1,
                        fieldset: { maxHeight: '126px', minHeight: '126px', overflow: 'hidden' },
                        '.MuiOutlinedInput-root': {
                          maxHeight: '128px',
                          position: 'initial',
                          overflow: 'auto',
                        },
                        'MuiFormControl-root': {
                          maxHeight: '128px',
                          minHeight: '128px',
                          overflow: 'auto',
                        },
                      }}
                    />
                  );
                }}
              />
              {limits.ingredients ? (
                <Box>
                  <Button
                    id="ingredientsLimit"
                    variant="contained"
                    disabled={limits?.ingredients?.count === limits?.ingredients?.limit || autoGenerateLoading}
                    sx={{ minWidth: 210, padding: '16px' }}
                    onClick={handleAutogenerate}
                  >
                    <Typography fontSize={14} fontWeight={700}>
                      {autoGenerateLoading ? (
                        <CircularProgress
                          size={15}
                          sx={{
                            color: '#fff',
                          }}
                        />
                      ) : limits?.ingredients?.limit - limits?.ingredients?.count === limits?.ingredients?.limit ||
                        limits?.ingredients?.count === limits?.ingredients?.limit ? (
                        generateBtn
                      ) : (
                        reGenerateBtn
                      )}
                    </Typography>
                  </Button>
                  <Box display="flex" alignItems="center" mt={2}>
                    <Typography color="#0F1214" fontWeight={700} lineHeight="19px" fontSize={12} mr={0.5}>
                      {limits?.ingredients?.limit - limits?.ingredients?.count} of {limits?.ingredients?.limit}
                    </Typography>
                    <Typography color="#0F1214" lineHeight="19px" fontSize={12}>
                      {t('settings.attempts_left_within_hours')}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Typography mt={4} color="#050505" lineHeight="26px" fontSize={16} fontWeight={700} mb={2}>
              {t(textData.formTitle.name2)}
            </Typography>
            <Box display="flex">
              <CustomTextInput
                inputProps={{
                  placeholder: t(textData.formPlaceholder.name2),
                }}
                InputProps={{
                  fullWidth: true,
                  rows: 3,
                }}
                multiline
                name={textData.formName.name2}
                sx={{
                  width: '100%',
                  maxWidth: limits.ingredients ? '470px' : '100%',
                  mb: 3,
                  mr: limits.ingredients ? 2 : 1,
                  textarea: {
                    padding: '5px 16px 16px 5px',
                    height: '90px',
                  },
                }}
              />
            </Box>
            <Typography mt={4} color="#050505" lineHeight="26px" fontSize={16} fontWeight={700} mb={2}>
              {t(textData.formTitle.name3)}
            </Typography>
            <Box display="flex">
              <CustomTextInput
                inputProps={{
                  placeholder: t(textData.formPlaceholder.name3),
                }}
                InputProps={{
                  fullWidth: true,
                  rows: 3,
                }}
                multiline
                name={textData.formName.name3}
                sx={{
                  width: '100%',
                  maxWidth: '470px',
                  mb: 3,
                  mr: 2,
                  textarea: {
                    padding: '5px 16px 16px 5px',
                    height: '90px',
                  },
                }}
              />
              <Box>
                <Button
                  id="chatGptGenerate"
                  variant="contained"
                  onClick={handleChatGptGenerate}
                  sx={{ minWidth: 210, padding: '16px' }}
                  disabled={
                    textData.target === CHAT_GPT_ENUM.PRODUCT
                      ? limits.description.count === limits.description.limit ||
                        generateLoading ||
                        (!chatGptData?.ingredients?.[textData.formName.name1]?.length &&
                          !chatGptModel.getValues()[textData.formName.name1]?.length)
                      : limits.description.count === limits.description.limit ||
                        generateLoading ||
                        !chatGptModel.getValues(textData.formName.name1)?.length
                  }
                >
                  <Typography fontSize={14} fontWeight={700}>
                    {generateLoading ? (
                      <CircularProgress
                        size={15}
                        sx={{
                          color: '#fff',
                        }}
                      />
                    ) : limits.description.limit - limits.description.count === limits.description.limit ||
                      limits.description.count === limits.description.limit ? (
                      generateBtn
                    ) : (
                      reGenerateBtn
                    )}
                  </Typography>
                </Button>
                <Box display="flex" alignItems="center" mt={2}>
                  <Typography color="#0F1214" fontWeight={700} lineHeight="19px" fontSize={12} mr={0.5}>
                    {limits.description.limit - limits.description.count} of {limits.description.limit}
                  </Typography>
                  <Typography color="#0F1214" lineHeight="19px" fontSize={12}>
                    {t('settings.attempts_left_within_hours')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              position: 'sticky',
              bottom: 0,
              zIndex: 3,
              background: '#fff',
              width: '100%',
            }}
          >
            <Box sx={{ cursor: 'grab', width: '100%' }}>
              <Button
                id="chatGptGenerateSettings"
                variant="contained"
                sx={{
                  width: '100%',
                  padding: '10px 32px',
                  mt: 1,
                  fontWeight: 700,
                  fontSize: 14,
                  background: '#ebebeb',
                  color: '#000',
                  '&:hover': {
                    background: '#ebebeb',
                  },
                }}
                disabled={
                  !chatGptData?.description?.[textData.formName.name3] &&
                  !chatGptModel.getValues()[textData.formName.name1]
                }
                onClick={handleUseText}
              >
                {t('settings.use')}
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  );
};
export default DialogChatGptDesc;
