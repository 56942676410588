// Configurations Table.

// Formatting title.
let formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

// Column Title Table Report.
export const columnTitles = {
  business_name: {
    id: 'business_name',
    label: 'Business Name',
    minWidth: 170,
    align: 'left',
  },
  provider: {
    id: 'provider',
    label: 'Provider',
    minWidth: 170,
    align: 'left',
    format: (value) => value.charAt(0).toUpperCase() + value.slice(1),
  },
  subtotal_sales: {
    id: 'subtotal_sales',
    label: 'Sales Subtotal',
    minWidth: 170,
    align: 'right',
    format: (value) => formatter.format(value),
  },
  total_delivery: {
    id: 'total_delivery',
    label: 'Total Delivery',
    minWidth: 170,
    align: 'right',
    format: (value) => formatter.format(value),
  },
  total_discounts: {
    id: 'total_discounts',
    label: 'Total Discounts',
    minWidth: 170,
    align: 'right',
    format: (value) => formatter.format(value),
  },
  total_orders: {
    id: 'total_orders',
    label: 'Orders Quantity',
    minWidth: 170,
    align: 'right',
  },
  total_sales: {
    id: 'total_sales',
    label: 'Total Sales',
    minWidth: 170,
    align: 'right',
    format: (value) => formatter.format(value),
  },
  total_tax: {
    id: 'total_tax',
    label: 'Total Tax',
    minWidth: 170,
    align: 'right',
    format: (value) => formatter.format(value),
  },
  total_tips: {
    id: 'total_tips',
    label: 'Total Tips',
    minWidth: 170,
    align: 'right',
    format: (value) => formatter.format(value),
  },
  total_qty: {
    id: 'total_qty',
    label: 'Quantity',
    minWidth: 170,
    align: 'right',
  },
  total_amount: {
    id: 'total_amount',
    label: 'Total Amount',
    minWidth: 170,
    align: 'right',
    format: (value) => formatter.format(value),
  },
  total_pickups_qty: {
    id: 'total_pickups_qty',
    label: 'Pickups Quantity',
    minWidth: 170,
    align: 'right',
    // format: (value) => value.toLocaleString('en-US'),
  },
  total_delivery_qty: {
    id: 'total_delivery_qty',
    label: 'Delivery Quantity',
    minWidth: 170,
    align: 'right',
    // format: (value) => value.toLocaleString('en-US'),
  },
  hour: {
    id: 'hour',
    label: 'Hours of Day',
    minWidth: 170,
    align: 'left',
  },
  date: {
    id: 'date',
    label: 'Date',
    minWidth: 170,
    align: 'left',
    // format: (value) => value.toFixed(2).toLocaleString('en-US'),
  },
  average_sale: {
    id: 'average_sale',
    label: 'Average Sales',
    minWidth: 170,
    align: 'left',
    format: (value) => formatter.format(value),
  },
  item_name: {
    id: 'item_name',
    label: 'Product',
    minWidth: 170,
    align: 'left',
  },
};

// export const FOOD2LABEL = 'FOOD2'.toUpperCase();
// export const REPORT_FOOD2LABEL = 'FOOD2'.toUpperCase();
// export const TABLE_ORDERING_LABEL = 'Table Ordering'.toUpperCase();

// All Days.
// export const allDays = ['monday_', 'tuesday_', 'wednesday_', 'thursday_', 'friday_', 'saturday_', 'sunday_'];

// export const allDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export const allDays = [
  { value: 'MONDAY', label: 'monday_' },
  { value: 'TUESDAY', label: 'tuesday_' },
  { value: 'WEDNESDAY', label: 'wednesday_' },
  { value: 'THURSDAY', label: 'thursday_' },
  { value: 'FRIDAY', label: 'friday_' },
  { value: 'SATURDAY', label: 'saturday_' },
  { value: 'SUNDAY', label: 'sunday_' },
];
