import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const DialogHeader = ({ title, handleClose, isConfirm = false }) => {
  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ fontSize: '24px', fontWeight: 400, ml: isConfirm ? 0 : '-8px' }}>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 8,
          right: isConfirm ? 16 : 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
};
export default DialogHeader;
