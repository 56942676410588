import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { updateUserData } from 'redux/actions/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { schemaUserInfo, userDefaultsSchema } from 'redesign/AccountTab/schema/schema';

export const useAccountInformation = (disabled) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const user = useSelector(({ user }) => user.data);

  const accountInformationFormMethods = useForm({
    defaultValues: userDefaultsSchema,
    mode: 'all',
    resolver: yupResolver(schemaUserInfo),
  });

  useEffect(() => {
    if (user) {
      let emptyState = disabled ? '-' : '';
      accountInformationFormMethods.reset(
        {
          firstName: user?.firstName || emptyState,
          lastName: user?.lastName || emptyState,
          phone: user?.phone || emptyState,
        },
        { keepDirty: false }
      );
    }
  }, [user, accountInformationFormMethods, disabled]);

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(updateUserData(values))
      .then(() => {})
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  return {
    handleSubmit,
    loading,
    accountInformationFormMethods,
  };
};
