import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import { Grid, Button, Box } from '@mui/material';
import instance from '../../../services/api';
import DialogTitle from '@mui/material/DialogTitle';
import { yupResolver } from '@hookform/resolvers/yup';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useForm, FormProvider } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import CustomTextInput from 'components/Forms/CustomTextInput';
import CustomMobilePhone from 'components/MobilePhone/CustomMobilePhone';
import { useTranslation } from 'react-i18next';

const BrandRegister = ({ showModal, handleCloseModal, currentBusiness }) => {
  const { t } = useTranslation();
  const schemaLocationModal = yup.object().shape({
    brandName: yup.string().required('field_is_required_').trim(),
    phone: yup.string().test('phone', 'phone_number_10_numbers', (value) => {
      const valueLength = value.replace(/[^\w\s]/gi, '').replaceAll(' ', '').length;
      let tested = true;
      tested = !(valueLength < 10 && valueLength !== 0);
      return tested;
    }),
  });
  const [loading, setLoading] = useState(false);
  const locationModalFormMethods = useForm({
    defaultValues: { brandName: '', phone: '' },
    mode: 'all',
    resolver: yupResolver(schemaLocationModal),
  });
  const { resetField } = locationModalFormMethods;
  const handleValidSubmit = (values) => {
    setLoading(true);
    values['brandPhone'] = values.phone || '';
    values['metadata'] = {
      ...currentBusiness?.metadata,
      communicationEmail: currentBusiness?.metadata?.communicationEmail || '',
      addressPlaceId: currentBusiness?.metadata?.addressPlaceId || '',
    };
    delete values.phone;
    return instance
      .post(`/brand/servicing/for-no-servicing-business`, values)
      .then(() => {
        handleCloseModal(true);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };
  const closeModal = () => {
    handleCloseModal();
    resetField('brandName', { defaultValue: '' });
    resetField('phone', { defaultValue: '' });
    setLoading(false);
  };
  useEffect(() => {
    if (!showModal) {
      closeModal();
    }
  }, [showModal]);

  return (
    <Dialog
      PaperProps={{ sx: { width: '454px', maxWidth: '100%', background: '#FAFAFA' } }}
      open={showModal}
      onClose={closeModal}
      className="brandLocationsModal"
    >
      <DialogTitle toggle={closeModal}>{t('settings.add_new_brand')}</DialogTitle>
      <FormProvider {...locationModalFormMethods}>
        <form onSubmit={locationModalFormMethods.handleSubmit(handleValidSubmit)}>
          <DialogContent>
            <Grid container columns={{ xs: 12 }} spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ height: '56px' }}>
                  <CustomTextInput
                    sx={{ width: '100%', mb: 1 }}
                    inputProps={{
                      type: 'string',
                    }}
                    name="brandName"
                    label={t('settings.brand_name')}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ height: '56px' }}>
                  <CustomMobilePhone
                    sx={{ width: '100%', mb: 1 }}
                    inputProps={{
                      type: 'string',
                    }}
                    name="phone"
                    label={t('settings.phone_number')}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="border-none">
            <Button
              id="brandLocationsModalCancel"
              variant="outlined"
              onClick={closeModal}
              outline
              type="button"
              className="ml-2"
            >
              <span className="">{t('orders.cancel')}</span>
            </Button>
            <Button
              id="brandLocationsModalSave"
              variant="contained"
              disabled={!locationModalFormMethods.formState.isValid || loading}
              type="submit"
              color="primary"
              className="ml-2"
            >
              {loading && <CircularProgress style={{ width: '25px', height: '25px' }} />}
              {!loading && <span className="">{t('orders.save')}</span>}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default BrandRegister;
