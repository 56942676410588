import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import instance from 'services/api';
import { InviteLocationTitle } from './styles';
import InvitationEmail from './InvitationEmail';
import { useTranslation } from 'react-i18next';

const InvitationLocation = () => {
  const [active, setActive] = useState(0);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!data.length) {
      instance.get(`/brand/all?isServicingBrand=1`).then((res) => {
        setData(res.data.data);
      });
    }
  }, [data]);

  const handleStepChange = (step) => setActive(step);

  return (
    <Box className="signup-wizard my-5">
      <Box className="wizard-header" sx={{ marginTop: '104px' }}>
        <InviteLocationTitle>{t('sign_up.new_location_wizard')}</InviteLocationTitle>
      </Box>
      {active === 0 && <InvitationEmail handleStepChange={handleStepChange} data={data} setData={setData} />}
    </Box>
  );
};

export default InvitationLocation;
