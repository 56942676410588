import { memo } from 'react';
import * as all from 'constants/permissions';
import usePermissionPlan from 'hooks/permissionPlan';

const PrivatePlan = ({ permission, children }) => {
  const checkPermissionPlan = usePermissionPlan();
  return !permission || checkPermissionPlan(all[permission]) ? children : null;
};

export default memo(PrivatePlan);
