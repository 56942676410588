import { ReportingGrowthArea } from 'components/NewHome/GrowthDisplay/growthDisplay';
import {
  ReportProviderImageWrapper,
  ReportProviderInfoWrapper,
  ReportProviderWrapper,
} from 'components/NewHome/styles';

const GrowthDisplayProvider = ({ item, totalCountOne, totalCountTwo, isOrders }) => {
  return (
    <ReportProviderWrapper>
      <ReportProviderImageWrapper>
        <img src={item?.logoUrl} alt={item?.label || ''} />
      </ReportProviderImageWrapper>
      <ReportProviderInfoWrapper>
        <ReportingGrowthArea
          providerLabel={item?.label}
          total1={totalCountOne}
          total2={totalCountTwo}
          vs={'Previous Period'}
          showDollar={!isOrders}
        />
      </ReportProviderInfoWrapper>
    </ReportProviderWrapper>
  );
};
export default GrowthDisplayProvider;
