import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  ListItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { useDispatch } from 'react-redux';
import { getAvailablaLanguages } from 'redux/actions/business';
import { useMatchMobile } from 'hooks/useMatchMobile';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import { prioritizeEnglish } from 'components/LocalizationPopover/hooks/useLocalizationDialog';

const AddLanguageDialog = ({
  savedLanguages,
  handleClose,
  open,
  handleSubmitDialog,
  loading,
  selectedValue,
  setSelectedValue,
  setGenerateTranslation,
}) => {
  const { isMatchMobile } = useMatchMobile('sm');
  // const { currentBusiness } = useSelector(({ business }) => business);
  const [submitted, setSubmitted] = useState(false);
  const [languages, setLanguages] = useState([]);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAvailablaLanguages()).then(({ data }) => {
      setLanguages(prioritizeEnglish(data.data));
    });
  }, []);

  const handleChangeGenerateTranslation = ({ target }) => {
    setGenerateTranslation(target.checked);
  };

  const handleChange = (event, value, reason, details) => {
    setSelectedValue(value);
  };

  const handleAddLanguage = () => {
    setSubmitted(true);
    if (selectedValue?._id) {
      handleSubmitDialog();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={isMatchMobile}
      PaperComponent={DraggableDialogWrapper}
      sx={{
        '& .MuiDialogContent-root': {
          pt: 2,
        },
      }}
    >
      {loading && <Preloader overlay />}
      <DialogTitle sx={{ cursor: 'grab' }}>{t('settings_advanced.add_new_language')}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '400px', height: '350px', pt: 2 }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={languages}
            getOptionDisabled={(option) => {
              return !!savedLanguages.find((lngItem) => lngItem.code === option.code);
            }}
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(props, option, state) => (
              <ListItem {...props}>
                {option.image.url && (
                  <Box sx={{ width: '32px', mr: 2 }}>
                    <img style={{ width: '100%' }} src={option.image.url} alt={option.name} />
                  </Box>
                )}
                {`${option.name} (${option.code})`}
              </ListItem>
            )}
            // getOptionDisabled={(option) => option === timeSlots[0] || option === timeSlots[2]}
            renderInput={(params) => (
              <TextField
                {...params}
                error={submitted && !selectedValue?._id}
                helperText={!selectedValue?._id && submitted ? t('help.field_is_required') : null}
                InputLabelProps={{
                  shrink: true,
                }}
                label={t('settings_advanced.languages')}
              />
            )}
            onChange={handleChange}
          />
          <FormGroup sx={{ mt: 2 }} onChange={handleChangeGenerateTranslation}>
            <FormControlLabel control={<Checkbox />} label={t('settings_advanced.automatically_generate_ai')} />
          </FormGroup>
          {/* <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end' }}>
            <Button variant="contained" sx={{ mr: 2 }}>
              Save
            </Button>
            <Button variant="outlined">Cancel</Button>
          </Box> */}
        </Box>
      </DialogContent>
      <DialogActions sx={{ cursor: 'grab' }}>
        <Button id="addLanguageDialogCancel" variant="outlined" sx={{ width: '100px' }} onClick={handleClose}>
          {t('orders.cancel')}
        </Button>
        <Button id="addLanguageDialogSave" variant="contained" sx={{ width: '100px' }} onClick={handleAddLanguage}>
          {t('orders.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLanguageDialog;
