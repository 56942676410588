import { useMemo } from 'react';
import qs from 'qs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useSelector } from 'react-redux';
import timezone from 'dayjs/plugin/timezone';
import { useLocation } from 'react-router-dom';
import { getReportsFilterPeriodsHome } from 'constants/config';

export const useReportPeriods = () => {
  const { search } = useLocation();
  const { currentBusiness } = useSelector(({ business }) => business);
  const { period } = qs.parse(search, { ignoreQueryPrefix: true });
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault(currentBusiness?.timezone?.timeZoneId);
  const periods = useMemo(() => {
    if (currentBusiness && currentBusiness?.timezone && currentBusiness?.timezone?.timeZoneId) {
      return getReportsFilterPeriodsHome(currentBusiness.timezone.timeZoneId);
    }
    return [];
  }, [currentBusiness, period]);
  const query = useMemo(() => {
    return {
      period: periods[period] ? period : 'week',
    };
  }, [periods, search]);
  return {
    periods,
    query,
    dayjs,
    currentBusiness,
  };
};
