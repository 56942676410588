import { useEffect } from 'react';

const HubSpotChatPage = () => {
  useEffect(() => {
    const script = document.createElement(`script`);
    script.setAttribute('src', '//js.hs-scripts.com/23274786.js');
    script.setAttribute('async', '');
    script.setAttribute('defer', '');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', 'hs-script-loader');
    document.querySelector('body').appendChild(script);
    window.HubSpotConversations?.widget?.open();
  }, [window.HubSpotConversations]);

  return <></>;
};

export default HubSpotChatPage;
