import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonFooter, CancelButtonFooter, SaveButtonFooter } from 'redesign/Shared/styles';
const ActionFooter = ({ onCancel, id, loading, disabled = false, saveBtn = 'Save', onClick = () => {} }) => {
  const { t } = useTranslation();
  return (
    <ButtonFooter>
      <CancelButtonFooter
        variant="text"
        type="text"
        onClick={(event) => {
          onCancel(event, id);
        }}
      >
        {t('Cancel')}
      </CancelButtonFooter>
      <SaveButtonFooter
        onClick={onClick}
        variant="contained"
        type="submit"
        loading={loading}
        disabled={disabled || loading}
      >
        {t(saveBtn)}
      </SaveButtonFooter>
    </ButtonFooter>
  );
};
export default memo(ActionFooter);
