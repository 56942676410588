export const DAYS = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};
export const DEFAULT_OPEN = '09:00';
export const DEFAULT_CLOSE = '19:00';

export const HOURS = (() => {
  const a = [];
  for (let i = 0; i < 24; i++) {
    const hour = String(i).padStart(2, '0');
    a.push(hour);
  }
  return a;
})();

export const MINUTES = (() => {
  const a = [];
  for (let j = 0; j < 4; j++) {
    const min = String(j * 15).padStart(2, '0');
    a.push(min);
  }
  a.push(59);

  return a;
})();
