import { useState, useEffect, createContext, useContext, useRef, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getPeriods } from 'constants/config';
import io from 'socket.io-client';

const defaultValue = {};
const SocketContext = createContext(defaultValue);

let connection;
const connect = () => {
  if (!connection) {
    connection = io.connect(process.env.REACT_APP_SOCKET_BASE_URL, {
      reconnectionAttempts: Infinity,
      reconnectionDelayMax: 5000,
      transports: ['websocket'],
      reconnectionDelay: 5000,
      reconnection: true,
    });
  }
  return connection;
};

const SocketProvider = ({ children }) => {
  const [connected, setConnected] = useState();
  const socketRef = useRef(connect());
  const dispatch = useDispatch();

  const emitters = useRef({});
  const periods = useMemo(() => getPeriods(), []);

  // useEffect(() => {
  //   const { start, end } = periods.last7d;
  //   dispatch({
  //     type: SET_ORDERS_PARAMS,
  //     payload: {
  //       status: 'all',
  //       end,
  //       start,
  //     },
  //   });
  //   dispatch(getOrders());
  // }, []);

  useEffect(() => {
    const socket = socketRef.current;
    socket.off('disconnect').on('disconnect', () => {
      setConnected(false);
      console.log('Socket disconnected!');
    });

    socket.off('connect').on('connect', () => {
      setConnected(true);
      console.log('Socket connected!');
    });

    socket.off('reconnect').on('reconnect', () => {
      setConnected(true);
      Object.keys(emitters.current).forEach((event) => {
        socket.emit(event, emitters.current[event]);
      });
      console.log('Socket reconnected!');
    });

    socket.on('connect_error', () => {
      setConnected(false);
      console.log('Socket error!');
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // useEffect(() => {
  //   // Getting last created orders
  //   if (connected && orderList.length) {
  //     instance.get(`/orders/missing`, {
  //       params: { lastDate: orderList[0].createdAt },
  //     });
  //   }
  // }, [connected]);

  const subscribe = useCallback((event, cb) => {
    console.log('Added listener: ', event);
    socketRef.current.on(event, cb);
  }, []);

  const unsubscribe = useCallback((event, cb) => {
    console.log('Removed listener: ', event);
    socketRef.current.removeListener(event, cb);
  }, []);

  const eventEmit = useCallback((event, data) => {
    // if (!emitters.current[event]) {
    emitters.current[event] = data;
    console.log('Event Emit: ', event, data);
    socketRef.current.emit(event, data);
    // }
  }, []);

  return (
    <SocketContext.Provider value={{ socket: socketRef.current, connected, subscribe, unsubscribe, eventEmit }}>
      {connected !== undefined && !connected && (
        <div className="socket-overlay">
          <h5 className="text-light m-0">
            Please refresh the page. If the issue persists please call:{' '}
            <a href="tel:+18555001070" className="text-light">
              +1 (855) 500-1070
            </a>
          </h5>
        </div>
      )}
      {children}
    </SocketContext.Provider>
  );
};

function useSocket() {
  const context = useContext(SocketContext);
  if (context === defaultValue) {
    throw new Error('useSocket must be used within SocketProvider');
  }
  return context;
}

export { useSocket, SocketProvider };
