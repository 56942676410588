import styled from '@emotion/styled';
import { Typography, DialogActions, Button, DialogContent, Link, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const ConfirmDialogAction = styled(DialogActions)({
  justifyContent: 'space-between',
  padding: '24px',
  cursor: 'grab',
});

export const ConfirmDialogCancelButton = styled(Button)({
  width: '202px',
  height: '54px',
  borderRadius: '8px',
  fontWeight: 700,
  fontSize: '16px',
});

export const ConfirmDialogConfirmButton = styled(LoadingButton)({
  width: '305px',
  height: '54px',
  borderRadius: '8px',
  fontWeight: 700,
  fontSize: '16px',
});

export const ConfirmDialogContentTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: '24px',
  lineHeight: '22px',
  letterSpacing: '0.25',
  marginTop: '16px',
});

export const ConfirmDialogContentDescription = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.25',
  marginTop: '24px',
});

export const ConfirmDialogContentSubDescription = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.25',
  marginTop: '24px',
});

export const DialogTextProvider = styled(Typography)({
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '32px',
  color: '#1D1B20',
  marginTop: '32px',
});

export const DialogTextSubProvider = styled(Typography)({
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '32px',
  color: '#49454F',
  margin: '32px auto',
  maxWidth: '680px',
  textAlign: 'center',
});

export const DialogTextSubProviderLink = styled(Link)({
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '32px',
  color: '#49454F',
  margin: '24px auto',
  maxWidth: '580px',
  textAlign: 'center',
  textDecorationColor: '#0F1214',
  '&:hover': {
    color: '#307ff9',
    textDecorationColor: '#307ff9',
    textDecoration: 'underline',
  },
});

export const DialogTextSubBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  margin: '0 auto',
  width: '280px',
});

export const DialogContentBox = styled(DialogContent)({
  width: '100%',
  marginTop: '148px',
  textAlign: 'center',
  padding: 0,
});

export const DialogActionsContentBox = styled(DialogActions)({
  minHeight: '102px',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  padding: 0,
});

export const DialogActionsButton = styled(Button)({
  margin: '0 auto',
  textAlign: 'center',
  width: '164px',
  height: '54px',
  borderRadius: '6px',
});

export const DialogActionsProductButton = styled(Button)({
  textAlign: 'center',
  width: '200px',
  height: '54px',
  borderRadius: '6px',
  color: '#626A7A',
  fontWeight: 700,
  border: '1px solid #626A7A',
  background: 'white',
  '&:hover': {
    color: '#626A7A',
    fontWeight: 700,
    border: '1px solid #626A7A',
    background: 'white',
  },
});

export const DialogActionsProductConfirmButton = styled(Button)({
  textAlign: 'center',
  width: '134px',
  height: '54px',
  borderRadius: '6px',
});

export const DialogActionsProductBox = styled(DialogActions)({
  minHeight: '102px',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
});
