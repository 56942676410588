import { Box } from '@mui/material';
import { PopoverWrapper } from './styles';

const WrapperList = ({ id, open, anchorEl, handleClose, children }) => {
  return (
    <PopoverWrapper
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{ sx: { border: '1px solid #D8D9DC', borderRadius: '4px', mt: 1 } }}
    >
      <Box>{children}</Box>
    </PopoverWrapper>
  );
};

export default WrapperList;
