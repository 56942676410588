import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const usePartialFreeze = () => {
  const { loading } = useSelector(({ menu }) => menu);
  const { loading: providerLoading } = useSelector(({ providers }) => providers);
  useEffect(() => {
    if (loading || providerLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [loading, providerLoading]);
};

export default usePartialFreeze;
