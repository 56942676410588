import { useCallback, useEffect, useState } from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import { StyledBox } from './styled';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';

/**
 * Increase/decrease value for one click
 */
const STEP = 5;

/**
 * Increase pickup time preventing message for business
 */
const INCREASE_TIME_WARNING = 'order_view.increase_time_warning';

/**
 * Decrease pickup time preventing message for business
 */
const DECREASE_TIME_WARNING = 'order_view.decrease_time_warning';

/**
 * Increase/decrease buttons for pickup time management
 *
 * @param {object} props
 * @param {number} props.pickupTime
 * @param {number} props.defaultPrepTime
 * @param {number} props.minTime
 * @param {number} props.maxTime
 * @param {number} props.changePickupTime
 * @param {boolean} props.isScheduled
 * @returns
 */
const PickupTimeControls = ({
  pickupTime,
  defaultPrepTime,
  minTime,
  maxTime,
  changePickupTime,
  isScheduled,
  isBusy,
  additional_prep_time,
  loading,
}) => {
  const { t } = useTranslation();
  const [warning, setWarning] = useState('');
  useEffect(() => {
    if (!isBusy ? pickupTime > defaultPrepTime : pickupTime > defaultPrepTime + additional_prep_time / 60) {
      setWarning(t(INCREASE_TIME_WARNING));
    }

    if (isBusy && pickupTime < defaultPrepTime + additional_prep_time / 60) {
      setWarning(t(DECREASE_TIME_WARNING));
    }

    if (isBusy && pickupTime === defaultPrepTime + additional_prep_time / 60) {
      setWarning('');
    }
    if (pickupTime < defaultPrepTime && !isScheduled) {
      setWarning(t(DECREASE_TIME_WARNING));
    }

    if (pickupTime > 0 && isScheduled) {
      setWarning(t(INCREASE_TIME_WARNING));
    } else if (pickupTime === 0) {
      setWarning('');
    }

    if (pickupTime === defaultPrepTime && !isBusy && !isScheduled) {
      setWarning('');
    }
  }, [pickupTime, defaultPrepTime, isBusy, additional_prep_time]);

  const decreasePickupTime = useCallback(() => {
    const newValue = pickupTime - STEP;
    changePickupTime(newValue);
  }, [pickupTime, changePickupTime]);

  const increasePickupTime = useCallback(() => {
    const newValue = pickupTime + STEP;
    changePickupTime(newValue);
  }, [pickupTime, changePickupTime]);

  return (
    <>
      <StyledBox>
        <IconButton color="primary" disabled={Boolean(pickupTime <= minTime)} onClick={decreasePickupTime}>
          <RemoveIcon />
        </IconButton>
        {!loading && <Typography fontSize="24px">{pickupTime} min</Typography>}

        <IconButton color="primary" disabled={Boolean(pickupTime >= maxTime)} onClick={increasePickupTime}>
          <AddIcon />
        </IconButton>
      </StyledBox>
      <Box sx={{ mb: 3, height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {/* <Fade in={!!warning.toString()}> */}
        <Typography fontSize={'14px'} align="center">
          {warning}
        </Typography>
        {/* </Fade> */}
      </Box>
    </>
  );
};

export default PickupTimeControls;
