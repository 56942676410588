import { SET_EVENT_LIST, SET_EVENT_DEFAULT, SET_EVENT_LIST_LOADING, SET_EVENT_ACTIONS } from 'redux/actions/types';

const initialState = {
  eventList: [],
  eventDefault: [],
  eventActions: [],
  loading: false,
};

const eventReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EVENT_LIST:
      return {
        ...state,
        eventList: payload.data,
      };
    case SET_EVENT_DEFAULT:
      return {
        ...state,
        eventDefault: payload.data,
      };
    case SET_EVENT_ACTIONS:
      return {
        ...state,
        eventActions: payload.data,
      };
    case SET_EVENT_LIST_LOADING:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};

export default eventReducer;
