export const PRINTER_TYPES = {
  internal: 'internal',
  epson: 'epson',
  epson_40: 'epson_40',
  epson_48: 'epson_48',
  epson_220: 'epson_220',
  other: 'other',
  star: 'star',
  starsp700: 'starsp700',
};
