import { styled, Typography, Box, Slider, ListItem } from '@mui/material';

export const InviteLocationTitle = styled(Typography)({
  fontSize: '32px',
  fontWeight: 700,
  color: '#000000',
});

export const AuthorizationStepsBox = styled(Box)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: 700,
  textAlign: 'center',
  marginBottom: theme.spacing(3),
}));

export const AuthorizationStepsTitle = styled(Typography)({
  fontSize: '32px',
  fontWeight: 700,
  color: '#000000',
});

export const AuthorizationStepsSubTitle = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
});

export const StepperSlider = styled(Slider)(({ theme }) => ({
  '.MuiSlider-rail': {
    background: '#E3E3E3',
  },
  color: '#2D7FF9',
  height: 3,
  paddingTop: theme.spacing(2, 0),
  '& .MuiSlider-thumb': {
    height: 22,
    width: 22,
    backgroundColor: '#2D7FF9',
    border: '1px solid #2D7FF9',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
}));

export const AuthorizationStepsDescTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  paddingTop: theme.spacing(3),
}));

export const AuthorizationStepsDescSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  paddingTop: theme.spacing(2),
}));

export const AuthorizationListItem = styled(ListItem)(({ theme }) => ({
  display: 'list-item',
  padding: theme.spacing(1, 0, 0, 0),
}));
