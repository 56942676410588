import { useState } from 'react';
import Preloader from 'components/Preloader';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Stack, Typography } from '@mui/material';
import AddEditModal from 'components/Coupons/AddEditModal';
import { useCouponsActiveTableColumns, useCouponsInactiveTableColumns, useCouponsData } from 'hooks/useCoupons';
import { addCoupon, deleteCoupon, updateCoupon } from 'redux/actions/coupons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { confirm } from 'components/MenuNew/ConfirmModal';
import { MarketingTypes } from 'components/Coupons/types';

const Coupons = () => {
  const { t } = useTranslation();

  const [showModal, hanldeShowModal] = useState(false);
  const [currentRowData, handleCurrentRowData] = useState(null);
  const { activeCoupons, expiredCoupons, isLoading } = useCouponsData();
  const dispatch = useDispatch();
  const handleEdit = (rowData) => {
    handleCurrentRowData({
      ...rowData,
      discountType: rowData?.amount ? 'amount' : 'percent',
      discount: rowData?.amount || rowData?.percent,
      useForMarketing: Boolean(rowData.type === MarketingTypes.MARKETING),
    });
    hanldeShowModal(true);
  };

  const handleDelete = async (body) => {
    const confirmed = await confirm({
      title: 'confirm_modal.coupon',
      message: `confirm_modal.are_you_sure_coupon`,
      cancelText: 'confirm_modal.no',
      confirmText: 'confirm_modal.yes',
      confirmColor: 'primary',
      hideCloseIcon: true,
      onClose: () => {},
    });
    if (confirmed) {
      dispatch(deleteCoupon(body));
    }
  };

  const columnsActive = useCouponsActiveTableColumns(handleDelete, handleEdit);
  const columnsInactive = useCouponsInactiveTableColumns(handleDelete, handleEdit);

  const handleSubmit = (values) => {
    if (values.id) {
      dispatch(updateCoupon(values)).then((data) => {
        hanldeShowModal(false);
      });
    } else {
      dispatch(addCoupon(values)).then((data) => {
        hanldeShowModal(false);
      });
    }
  };

  const handleCancel = () => {
    hanldeShowModal(false);
  };

  const handleAddNewCoupon = () => {
    hanldeShowModal(true);
    handleCurrentRowData(null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isLoading && <Preloader overview={true} />}
        <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography sx={{ mt: 2 }} variant="h6">
            {t('website_settings.active_coupons')}
          </Typography>
          <Button
            id="couponsAdd"
            onClick={handleAddNewCoupon}
            variant="contained"
            color="primary"
            type="button"
            disabled={false}
          >
            <span className="icon icon-plus"></span>

            <span className="">{t('website_settings.add_coupon')} </span>
          </Button>
        </Stack>
        <Box
          sx={{
            my: 2,
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
            },
            '& .MuiDataGrid-row	': {
              '&:nth-of-type(odd)': {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            },
            '& .MuiDataGrid-sortIcon': {
              color: 'white',
            },
            '& .MuiDataGrid-filterIcon': {
              color: 'white',
            },
            '& .MuiDataGrid-menuIcon	': {
              '& .MuiButtonBase-root': { color: 'white' },
            },
          }}
        >
          <DataGrid
            autoHeight={true}
            hideFooterPagination={true}
            hideFooterSelectedRowCount={true}
            isEditable={false}
            rows={activeCoupons}
            columns={columnsActive}
          />
          <Typography sx={{ my: 2 }} variant="h6">
            {t('website_settings.inactive_expired_coupons')}
          </Typography>
          <DataGrid
            sx={{ mt: 2 }}
            autoHeight={true}
            hideFooterPagination={true}
            hideFooterSelectedRowCount={true}
            isEditable={false}
            rows={expiredCoupons}
            columns={columnsInactive}
          />
        </Box>
        {showModal && (
          <AddEditModal
            loading={isLoading}
            data={currentRowData}
            showModal={showModal}
            onConfirm={handleSubmit}
            onCancel={handleCancel}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Coupons;
