import { Box, Divider } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { StyledList, StyledListItem, StyledListItemIcon, StyledListItemText } from './styles';

const ProviderStatusItem = ({ logoUrl, iconStyle, label, showDivider = false, currentDuration, showTime = false }) => {
  return (
    <StyledList>
      <Box>
        <StyledListItem>
          <StyledListItemIcon>
            <FiberManualRecordIcon sx={iconStyle} />
            <img style={{ width: '32px', height: '32px' }} src={logoUrl} alt={logoUrl} />
          </StyledListItemIcon>
          {showTime ? (
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <StyledListItemText sx={{ pl: 1 }} primary={label} />
              {currentDuration ? (
                <>
                  <StyledListItemText primary="|" />
                  <StyledListItemText primary={currentDuration?.toUpperCase()} />
                </>
              ) : (
                <></>
              )}
            </Box>
          ) : (
            <StyledListItemText sx={{ pl: 1 }} primary={label} />
          )}
        </StyledListItem>
        {showDivider && <Divider />}
      </Box>
    </StyledList>
  );
};
export default ProviderStatusItem;
