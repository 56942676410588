import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';
import { removeItemAfterSignUp } from 'utils/soldOut';
import { SignUpPathEnum } from 'constants/signUpPath';
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import MuiDialog from 'components/Dialog';

const ActionButtons = ({
  active,
  id,
  disabled = false,
  loading,
  title = 'settings.next',
  hideBackButton = false,
  hideProgressButton = false,
  externalPlanId = '',
  externalToken = '',
  corporateBusiness = '',
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isOpenProgress, setOpenProgress] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const progressUrl = `${window.location.origin}${window.location.pathname}${window.location.search}`;
  const STYLES = {
    widthModal: {
      width: '500px',
    },
    description: {
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 400,
      marginBottom: '16px',
      color: '#49454F',
    },
    title: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      color: '#1d1b20',
    },
    copyText: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    cancelBtn: {
      color: '#626A7A',
      marginRight: '16px',
    },
    // actionContainer: {
    //   borderTop: '1px solid #e0e0e0',
    // },
  };

  const handleOpenProgress = () => {
    setOpenProgress(true);
  };

  const closeProgress = () => {
    setOpenProgress(false);
  };

  const handleCopy = () => {
    setShowCopyMessage(true);
    navigator?.clipboard?.writeText(progressUrl);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 2000);
  };

  const handleBack = useCallback(() => {
    switch (history.location.pathname) {
      case SignUpPathEnum.ACCOUNT_INFO:
        removeItemAfterSignUp();
        history.push('/login');
        break;
      case SignUpPathEnum.LEGAL_INFO:
        window.scrollTo(0, 0);
        if (externalPlanId !== null && externalToken !== null && corporateBusiness !== null) {
          history.replace(
            `${SignUpPathEnum.ACCOUNT_INFO}?plan=${externalPlanId}&token=${externalToken}&corporateBusiness=${corporateBusiness}`
          );
        } else {
          history.replace(`${SignUpPathEnum.ACCOUNT_INFO}`);
        }
        break;
      case SignUpPathEnum.BILLING_INFO:
        window.scrollTo(0, 0);
        if (externalPlanId !== null && externalToken !== null && corporateBusiness !== null) {
          history.replace(
            `${SignUpPathEnum.LEGAL_INFO}?plan=${externalPlanId}&token=${externalToken}&corporateBusiness=${corporateBusiness}`
          );
        } else {
          history.replace(`${SignUpPathEnum.LEGAL_INFO}`);
        }
        break;
      default:
        history.replace(SignUpPathEnum.ACCOUNT_INFO);
    }
  }, [history.location.pathname, externalPlanId, externalToken, corporateBusiness]);
  return (
    <Box>
      {isOpenProgress && (
        <MuiDialog
          isOpen={isOpenProgress}
          dialogTitle={t('sign_up.modal_title')}
          description={t('sign_up.modal_desc')}
          cancelText={t('sign_up.modal_cancel')}
          confirmText={t('sign_up.modal_submit')}
          showCloseIcon={true}
          onCancel={closeProgress}
          onConfirm={closeProgress}
          target="progress"
          maxWidth="lg"
          styles={{
            ...STYLES,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Tooltip title={progressUrl} placement="top-start">
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  marginRight: '15px',
                  color: '#212121',
                }}
              >
                {progressUrl}
              </Typography>
            </Tooltip>
            <ContentCopyIcon onClick={handleCopy} sx={{ color: '#929398', cursor: 'pointer' }} />
            <Box
              sx={{
                position: 'absolute',
                background: '#929398',
                padding: '4px 12px',
                borderRadius: '2px',
                right: '20px',
              }}
              className={`message-container ${showCopyMessage ? 'show fade-in' : ''}`}
            >
              <Typography color="white" variant="body2" className="message-text">
                {t('sign_up.modal_copy')}
              </Typography>
            </Box>
          </Box>
        </MuiDialog>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'flex-end' }}>
        {!hideBackButton && (
          <Button id={id} onClick={handleBack} disabled={loading} sx={{ mr: 1, color: '#929398' }}>
            {active === 1 ? t('orders.cancel') : t('sign_up.back')}
          </Button>
        )}
        {!hideProgressButton && (
          <Button
            disabled={loading}
            id={id}
            onClick={handleOpenProgress}
            color="primary"
            variant="outlined"
            sx={{ mr: 1 }}
          >
            {t('sign_up.save_progress')}
          </Button>
        )}
        <Button id={id} disabled={loading || disabled} color="primary" type="submit" variant="contained">
          {!loading && t(title)}
          {loading && <CircularProgress style={{ width: '25px', height: '25px' }} />}
        </Button>
      </Box>
    </Box>
  );
};

export default ActionButtons;
