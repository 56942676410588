import InfoIcon from './images/informational.png';
import AlertIcon from './images/alert.png';
import CriticalIcon from './images/critical.png';
import ErrorIcon from './images/error.png';
import ProcessingIcon from './images/processing.png';

export const SET_PRODUCT_EVENTS = 'SET_PRODUCT_EVENTS';
export const SET_MODAL_ACTIONS = 'SET_MODAL_ACTIONS';
export const SET_PRODUCT_EVENTS_NEXT_LOCATION = 'SET_PRODUCT_EVENTS_NEXT_LOCATION';

export const EventStatusesEnum = {
  created: 'created',
  confirmed: 'confirmed',
  canceled: 'canceled',
  showed: 'showed',
};

export const EventTypeEnum = {
  alert: 'alert',
  informational: 'informational',
  warning: 'warning',
  error: 'error',
  critical: 'critical',
  processing: 'processing',
  notice: 'notice',
};

export const EventTypeIcons = {
  alert: AlertIcon,
  informational: InfoIcon,
  warning: AlertIcon,
  error: ErrorIcon,
  critical: CriticalIcon,
  processing: ProcessingIcon,
  notice: InfoIcon,
};

export const EventActionEnum = {
  ok: 'ok',
  closed: 'closed',
  confirm: 'confirmed',
  canceled: 'canceled',
  leave: 'leave',
  showed: 'showed',
};

export const ButtonTexts = {
  warning: ['Cancel and review', 'Confirm'],
  notice: ['Cancel', 'Leave'],
  information: ['Ok'],
  alert: ['Ok'],
};

export const EventServiceSourceEnum = {
  partners: 'partners',
};

export const ModalOpenStatusEnum = {
  closed: 'closed',
  opened: 'opened',
  cancel: 'cancel',
  close: 'close',
  confirm: 'confirm',
};
