import { SET_REPORT_LIST, SET_REPORT_LOADING, SET_REPORT_FILTERS } from 'redux/actions/types';
import instance from '../../services/api';
import { toast } from 'react-toastify';
import { handleTypeName } from 'pages/Report/utils';
import moment from 'moment';
import i18n from 'i18n';

export const adaptData = (data, providers, isReporting) => {
  const objEnum = {
    SALES_BY_DATE: 'SALES_BY_DATE',
    TOP_SELLERS: 'TOP_SELLERS',
    SUMMARY: 'SUMMARY',
    SALES_BY_PROVIDER: 'SALES_BY_PROVIDER',
    SALES_BY_HOUR: 'SALES_BY_HOUR',
    DETAILED_ORDER_REPORT: 'DETAILED_ORDER_REPORT',
    CUSTOMER_REPORT: 'CUSTOMER_REPORT',
    SUBSCRIBER_REPORT: 'SUBSCRIBER_REPORT',
    CHARGEBACK_DISPUTE_REPORT: 'CHARGEBACK_DISPUTE_REPORT',
  };

  const objDays = {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY',
  };
  const providersData = [];
  if (!isReporting) {
    data.providers.forEach((item) => {
      const groups = providers?.providerConfig?.groups[item];
      if (groups?.length) {
        providersData.push(...groups);
      }
    });
  }

  const adaptedWeeks = data.days_of_week.map((item) => objDays[item]);

  const adaptedProviders = providersData;
  data.start_date = moment(data.start_date).format('YYYY-MM-DD');
  data.end_date = moment(data.end_date).format('YYYY-MM-DD');
  return {
    ...data,
    type: objEnum[data.type],
    days_of_week: adaptedWeeks,
    providers: adaptedProviders,
  };
};

export function extractUniqueKeys(data) {
  const uniqueKeys = new Set(); // Set to hold unique keys
  data?.forEach((item) => {
    Object.keys(item)?.forEach((key) => {
      uniqueKeys.add(key); // Add each key to the Set
    });
  });

  return Array.from(uniqueKeys); // Convert Set to Array to return the keys
}

export const getReportList = (body) => (dispatch, getState) => {
  const {
    business: { locationIdsForReport, brandIdsForReport },
    providers,
  } = getState();
  const adaptedBody = adaptData(body, providers, body?.isReporting);
  adaptedBody.brandIds = brandIdsForReport;
  adaptedBody.locationIds = locationIdsForReport;
  dispatch({ type: SET_REPORT_LOADING, payload: true });
  if (body?.isReporting) {
    adaptedBody.providers = body.providers
  }
  delete body?.isReporting;
  return instance
    .post(`/reports/statistics/v2`, adaptedBody)
    .then((data) => {
      const headerKeys = extractUniqueKeys(data?.data?.data?.body);
      dispatch({
        type: SET_REPORT_LIST,
        payload: {
          data: headerKeys || [],
        },
      });
      dispatch({
        type: SET_REPORT_LIST,
        payload: {
          data: data?.data?.data?.body || [],
        },
      });
      dispatch({ type: SET_REPORT_LOADING, payload: false });
    })
    .catch((err) => {
      toast.error(err?.message);
      dispatch({ type: SET_REPORT_LOADING, payload: false });
      return Promise.reject(err);
    });
};

export const getReportListWithPayload = (payload) => {
  return instance
    .post(`/reports/statistics/v2`, payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const processData = (data) => {
  return data.map((item) => {
    const { refundTotal, reason, ...rest } = item;
    return { ...rest, refundTotal, reason };
  });
};

export const getChargeBackReportList = (body) => (dispatch, getState) => {
  const {
    business: { locationIdsForReport, brandIdsForReport },
    providers,
  } = getState();
  const adaptedBody = adaptData(body, providers, body?.isReporting);
  if (body?.isReporting) {
    adaptedBody.providers = body.providers
  }
  adaptedBody.brandIds = brandIdsForReport;
  adaptedBody.locationIds = locationIdsForReport;
  delete body?.isReporting;
  dispatch({ type: SET_REPORT_LOADING, payload: true });
  return instance
    .post(`/reports/chargeback`, adaptedBody)
    .then((data) => {
      const copyData = [...data?.data?.data];
      copyData.forEach((item) => {
        item['orderTotal'] = item?.nativeOrderTotal ?? item?.orderTotal;
        Object.keys(item).forEach((key) => {
          if (key === 'chargebackItems') {
            delete item[key];
          }
          if (key === 'provider' || key === 'state' || key === 'status') {
            item[key] = item[key].name;
          }
        });
        delete item.nativeOrderTotal;
      });
      const updatedData = processData(copyData);
      dispatch({
        type: SET_REPORT_LIST,
        payload: {
          data: updatedData || [],
        },
      });
      dispatch({ type: SET_REPORT_LOADING, payload: false });
    })
    .catch((err) => {
      toast.error(err?.message);
      dispatch({ type: SET_REPORT_LOADING, payload: false });
      return Promise.reject(err);
    });
};

export const getReportFilter = () => (dispatch, getState) => {
  const {
    user: { data },
  } = getState();

  try {
    return instance.get(`${process.env.REACT_APP_REPORTING_URL}/filter/list/${data._id}/`).then((res) => {
      dispatch({
        type: SET_REPORT_FILTERS,
        payload: {
          data: res.data.body,
        },
      });
      return res.data.body;
    });
  } catch (err) {
    toast.error(data.data.message);
    return Promise.reject(err);
  }
};

export const setReportFilter = (body, name) => (dispatch, getState) => {
  const {
    user: { data },
  } = getState();
  dispatch({ type: SET_REPORT_LOADING, payload: true });
  const adaptedBody = {
    user_id: data._id,
    name: name,
    data: adaptData(body),
  };

  return instance
    .post(`${process.env.REACT_APP_REPORTING_URL}/filter/`, adaptedBody)
    .then((res) => {
      const {
        data: {
          body: { data, message },
        },
      } = res;
      dispatch({ type: SET_REPORT_LOADING, payload: false });
      dispatch(getReportFilter());
      toast.success(message);
      return body;
    })
    .catch(() => {
      dispatch({ type: SET_REPORT_LOADING, payload: false });
    });
};

export const deleteCustomReport = (id) => (dispatch, getState) => {
  const {
    user: { data },
    reports: { filterList },
  } = getState();
  dispatch({ type: SET_REPORT_LOADING, payload: true });
  // try {
  return instance
    .delete(`${process.env.REACT_APP_REPORTING_URL}/filter/${id}`)
    .then(() => {
      dispatch(getReportFilter());
      dispatch({
        type: SET_REPORT_FILTERS,
        payload: {
          data: filterList.filter((item) => item.id !== id),
        },
      });
    })
    .then(() => {
      dispatch({ type: SET_REPORT_LOADING, payload: false });
    })
    .catch((err) => {
      dispatch({ type: SET_REPORT_LOADING, payload: false });
      toast.error(err);
      return Promise.reject(err);
    });
  // } catch (err) {
  //   dispatch({ type: SET_REPORT_LOADING, payload: false });
  //   return Promise.reject(err);
  // }
};

export const setExportCSV = (body) => (_, getState) => {
  const {
    business: { allSelectedBrand, allSelectedLocation },
    providers,
  } = getState();
  const adaptedBody = adaptData(body, providers, body?.isReporting);
  if (body?.isReporting) {
    adaptedBody.providers = body.providers
  }
  delete adaptedBody.brandIds;
  delete adaptedBody.locationIds;
  delete body?.isReporting;
  let businesses = [];
  allSelectedBrand.forEach((item) => {
    item?.value?.locations.forEach((location) => {
      const foundLocations = allSelectedLocation.find((elem) => elem?.value === location?._id);
      if (foundLocations) {
        const foundBusinessId = businesses.find((bus) => bus?.id === location?.businessId);
        if (!foundBusinessId) {
          businesses.push({ id: location?.businessId, companyName: location.companyName });
        }
      }
    });
  });
  adaptedBody.businesses = businesses;
  try {
    return instance.post(`${process.env.REACT_APP_REPORTING_URL}/statistics/csv`, adaptedBody).then(({ data }) => {
      const { type } = body;
      const blob = new Blob([data]);
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob, {
        type: 'text/plain',
      });
      a.download = `${i18n.t(handleTypeName(type) || 'download')}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setExportChargeBackCSV = (body) => (_, getState) => {
  const {
    business: { locationIdsForReport, brandIdsForReport },
    providers,
  } = getState();
  const adaptedBody = adaptData(body, providers, body?.isReporting);
  if (body?.isReporting) {
    adaptedBody.providers = body.providers
  }
  adaptedBody.brandIds = brandIdsForReport;
  adaptedBody.locationIds = locationIdsForReport;
  delete body?.isReporting;
  const typeForChargeBack = 'Chargeback Report';
  try {
    return instance.post(`/reports/chargeback/csv`, adaptedBody).then(({ data }) => {
      const blob = new Blob([data]);
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob, {
        type: 'text/plain',
      });
      a.download = `${i18n.t(typeForChargeBack || 'download')}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getReportListForHome = (body) => (dispatch, getState) => {
  const { providers } = getState();
  const adaptedBody = adaptData(body, providers);
  const selectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));
  const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
  adaptedBody.brandIds = selectedBrand?.value?._id;
  adaptedBody.locationIds = selectedLocation?.value?._id || selectedBrand?.value?.locations[0]?._id;
  dispatch({ type: SET_REPORT_LOADING, payload: true });
  return instance
    .post(`/reports/statistics/v2`, adaptedBody)
    .then((data) => {
      dispatch({
        type: SET_REPORT_LIST,
        payload: {
          data: data?.data?.data?.body || [],
        },
      });
      dispatch({ type: SET_REPORT_LOADING, payload: false });
      return data?.data?.data?.body;
    })
    .catch((err) => {
      toast.error(err?.message);
      dispatch({ type: SET_REPORT_LOADING, payload: false });
      return Promise.reject(err);
    });
};
