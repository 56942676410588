export const tutorial = {
  videos: [
    // {
    //   id: '1',
    //   provider: 'youtube',
    //   name: 'Step 1: New Customer Sign up',
    //   title: 'Step 1: New Customer Sign up',
    //   description:
    //     "Interested in Orders.co but don't know how to begin? This video is a quick guide on how to create an account and get started on the journey to Mastering Online Ordering.",
    //   link: 'https://www.youtube.com/embed/TgwPDOKUgsE',
    //   sort: 1,
    // },
    {
      id: '1',
      provider: 'youtube',
      name: 'Step 1: First Login & Business Info',
      title: 'Step 1: First Login & Business Info',
      description:
        'Step 1: The First Login & Business Info Tutorial is for new users whose accounts have just been activated. A quick guide on where to go and what needs to do.',
      link: 'https://www.youtube.com/embed/OUFWKR57rH0',
      sort: 1,
    },
    {
      id: '2',
      provider: 'youtube',
      name: 'Step 2: Ordering Website Setup & Stripe Merchant Services',
      title: 'Step 2: Ordering Website Setup & Stripe Merchant Services',
      description:
        'Step 2: A quick walkthrough of how to navigate ordering website settings & setup of a Stripe merchant service account.',
      link: 'https://www.youtube.com/embed/S8GfwtAbjLw',
      sort: 2,
    },
    // {
    //   id: '4',
    //   provider: 'youtube',
    //   name: 'Step 4: How to Connect App Providers',
    //   title: 'Step 4: How to Connect App Providers',
    //   description: 'Step 4: a quick guide on how to integrate delivery providers into your Orders.co suite.',
    //   link: 'https://www.youtube.com/embed/2NByj5OWunQ',
    //   sort: 4,
    // },
    {
      id: '3',
      provider: 'youtube',
      name: 'Step 3: Creating your Master Menu Basics',
      title: 'Step 3: Creating your Master Menu Basics',
      description:
        'Step 3: Walks you through the basics of building a Master Menu from Scratch. Everything from uploading pictures to adding item modifiers is included!',
      link: 'https://www.youtube.com/embed/nmAKnOA-Y9A',
      sort: 3,
    },
    {
      id: '4',
      provider: 'youtube',
      name: 'Step 4: Linking Your Menu to Providers',
      title: 'Step 4: Linking Your Menu to Providers',
      description:
        'Step 4: Explains the process of pushing your newly formed Master Menu to various platforms and making service-specific customizations.',
      link: 'https://www.youtube.com/embed/w12MnZSzt7Y',
      sort: 4,
    },
    {
      id: '5',
      provider: 'youtube',
      name: 'Step 5: Managing incoming orders',
      title: 'Step 5: Managing incoming orders',
      description: 'Step 5: Explains how to manage your orders.',
      link: 'https://www.youtube.com/embed/f9gDvIHlMRU',
      sort: 5,
    },
  ],
  documents: [],
};
