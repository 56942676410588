import styled from '@emotion/styled';
import { Button, Link, Box, Typography, Grid } from '@mui/material';
export const GooglePay = styled(Button)(({ theme }) => ({
  width: '160px',
  minHeight: '50px',
  background: '#000000',
  color: '#FFFFFF',
  border: '1px solid #A6A6A6',
  borderRadius: '6px',
  marginLeft: theme.spacing(2),
  '&:hover': {
    background: '#000000',
    color: '#FFFFFF',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(1),
    width: '100%',
  },
}));

export const AppPay = styled(Button)(({ theme }) => ({
  width: '160px',
  minHeight: '50px',
  background: '#000000',
  color: '#FFFFFF',
  border: '1px solid #A6A6A6',
  borderRadius: '6px',
  '&:hover': {
    background: '#000000',
    color: '#FFFFFF',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const MainGrid = styled(Grid)(({ theme }) => ({
  overflow: 'hidden',
}));

export const StoreButtonsWrapper = styled(Box)(({ theme }) => ({
  paddingTop: '20vh',
  margin: '0 auto',
  width: '100%',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(0),
  },
}));

export const StoreText = styled(Typography)(({ theme }) => ({
  fontSize: '36px',
  fontWeight: 700,
  lineHeight: '44px',
  color: '#FFFFFF',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    lineHeight: '18.2px',
    fontWeight: 400,
    color: '#929398',
  },
}));

export const StoreTextSecond = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px',
  color: '#FFFFFF',
  marginBottom: theme.spacing(5.5),
}));

export const GooglePayLink = styled(Link)(() => ({
  textDecoration: 'none',
}));

export const GooglePayLinkWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
}));

export const GooglePayLinkSmallText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: '12.13px',
  fontSize: '10px',
  color: '#FFFFFF',
  textAlign: 'left',
}));

export const GooglePayLinkSmallTextApp = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  lineHeight: '12.13px',
  fontSize: '10px',
  color: '#FFFFFF',
  textAlign: 'left',
  textTransform: 'lowercase',
  '&::first-letter': {
    textTransform: 'capitalize',
  },
}));

export const GooglePayLinkLargeText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  lineHeight: '12.13px',
  fontSize: '18px',
  color: '#FFFFFF',
  textTransform: 'lowercase',
  '&::first-letter': {
    textTransform: 'capitalize',
  },
}));

export const GooglePayBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '5px',
}));
