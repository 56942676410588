import { styled } from '@mui/system';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

export const TabContext = styled(Box)`
  display: flex;
  flex-flow: wrap;
  width: 100%;
`;

export const StyledTab = styled(Tab)`
  border: none;
  background: #f2f2f2;
  border-radius: 3px;
  margin-right: 8px;
  height: 30px;
  &:hover {
    background-color: #f8f9fa;
  }

  &.Mui-selected {
    background: #ffffff;
    color: #333333;
    border: none;
    &.MuiTabs-indicator {
      display: none;
    }
  }
`;

export const TabsList = styled(Tabs)`
  background-color: #f2f2f2;
  padding: 4px;
  background: #f2f2f2;
  border-radius: 3px;
  width: 100%;
  .MuiTabs-indicator {
    display: none;
  }
`;
