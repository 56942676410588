import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useProviderConfig from 'hooks/useProviderConfig';
import { Card, Grid, Typography, Divider } from '@mui/material';
import useProviderReport from 'components/NewHome/ProviderReport/hooks/useProviderReport';
import GrowthDisplayProvider from 'components/NewHome/GrowthDisplay/GrowthDisplayProvider';
const ProviderReport = () => {
  const { providers } = useProviderConfig();
  const { filteredReports } = useProviderReport();
  const { t } = useTranslation();
  const filteredList = useMemo(() => {
    return filteredReports.map((item) => {
      return providers[item?.srv] ? { ...item, ...providers[item?.srv] } : item;
    });
  }, [providers, filteredReports]);
  return (
    <>
      {filteredList && filteredList?.length > 0 && (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item sm={12} md={6} lg={6} xl={6}>
            <Card sx={{ p: 2 }}>
              <Typography sx={{ fontSize: '18px', color: '#000', lineHeight: '24px', fontWeight: 500 }}>
                {t('reports_settings.Orders by Provider')}
              </Typography>
              {filteredList.map((item, index) => {
                return (
                  <React.Fragment key={item.id}>
                    <GrowthDisplayProvider
                      item={item}
                      totalCountOne={item?.total_orders}
                      totalCountTwo={item?.prevTotalOrders}
                      isOrders={true}
                    />
                    {index !== filteredList?.length - 1 ? <Divider /> : null}
                  </React.Fragment>
                );
              })}
            </Card>
          </Grid>
          <Grid item sm={12} md={6} lg={6} xl={6}>
            <Card sx={{ p: 2 }}>
              <Typography sx={{ fontSize: '18px', color: '#000', lineHeight: '24px', fontWeight: 500 }}>
                {t('reports_settings.Sales by Provider')}
              </Typography>
              {filteredList.map((item, index) => {
                const salesValue = parseFloat(item?.total_sales.replace(/[$,]/g, ''));
                return (
                  <React.Fragment key={item.id}>
                    <GrowthDisplayProvider
                      item={item}
                      totalCountOne={salesValue}
                      totalCountTwo={item?.prevTotalSales}
                      isOrders={false}
                    />
                    {index !== filteredList?.length - 1 ? <Divider /> : null}
                  </React.Fragment>
                );
              })}
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default ProviderReport;
