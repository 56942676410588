export default function extractNamesFromArrayOfObjects(arr) {
  if (!arr.every((obj) => typeof obj === 'object')) {
    return arr;
  }
  if (!Array.isArray(arr) || arr.length === 0) {
    return [];
  }

  return arr.filter((obj) => obj.hasOwnProperty('name')).map((obj) => obj.name);
}
