import { useEffect, useState } from 'react';
import instance from 'services/api';
import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const PopularItemDirectory = () => {
  const { t } = useTranslation();
  const [values, setValues] = useState([]);
  useEffect(() => {
    const businessId = JSON.parse(localStorage.getItem('businessInfo'))?.id || '';
    if (businessId) {
      return instance
        .get(`/products/popular`)
        .then((res) => {
          if (res?.data?.data?.length) {
            const newArray = res?.data?.data?.map((item) => item.name).join(', ');
            setValues(newArray);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);
  return (
    <Box sx={{ mt: 4 }}>
      <Typography sx={{ fontWeight: 700, fontSize: '20px' }}>{t('popular_items')}</Typography>
      <TextField
        disabled={true}
        name="popularItem"
        InputLabelProps={{ shrink: true }}
        label={t('popular_items')}
        sx={{ mt: 1, width: '100%' }}
        multiline={true}
        value={values || ''}
        rows={4}
      />
    </Box>
  );
};
export default PopularItemDirectory;
