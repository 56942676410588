import { styled, Typography, Button } from '@mui/material';

export const CreateLocationTitle = styled(Typography)({
  fontSize: '32px',
  fontWeight: 700,
  color: '#000000',
});

export const BackButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  background: '#E3E3E3',
  color: '#000000',
  '&:hover': { backgroundColor: '#E3E3E3' },
}));

export const CreateLocationSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  color: '#000000',
}));
