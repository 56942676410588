import React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function OverlayOrders({ newOrders, modifiedOrders, canceledOrders, openOrders, setOverlay }) {
  const { t } = useTranslation();
  return (
    <div
      className={
        canceledOrders.length > 0
          ? 'cancel-order-overlay'
          : modifiedOrders.length > 0
          ? 'update-order-overlay'
          : 'new-orders-overlay'
      }
    >
      <Button id="overlayOrders" close onClick={() => setOverlay(false)}>
        <i className="icon icon-x" />
      </Button>
      <div className={'ordersCount'}>
        {canceledOrders.length > 0 ? (
          <div className={`order-section ${modifiedOrders.length > 0 || newOrders.length > 0 ? 'mr-5' : ''}`}>
            <span className="cancel-order-count">{canceledOrders.length || ''}</span>
            <p>
              {t('order_view.canceled')}&nbsp;
              {newOrders.length > 0 ? <br /> : null}
              {t('order_view.order')}
              {canceledOrders.length > 1 ? 's' : ''}
            </p>
          </div>
        ) : null}
        {modifiedOrders.length > 0 ? (
          <div className={`order-section ${canceledOrders.length > 0 || newOrders.length > 0 ? 'mr-5' : ''}`}>
            <span
              className={
                canceledOrders.length > 0
                  ? 'cancel-order-count'
                  : modifiedOrders.length > 0
                  ? 'modified-order-count'
                  : 'new-order-count'
              }
            >
              {modifiedOrders.length}
            </span>
            <p>
              {t('orders_history.updated')}
              {newOrders.length > 0 ? <br /> : null} {t('order_view.order')}
              {modifiedOrders.length > 1 ? 's' : ''}
            </p>
          </div>
        ) : null}
        {newOrders.length > 0 ? (
          <div className="order-section m-0">
            <span
              className={
                canceledOrders.length
                  ? 'cancel-order-count'
                  : modifiedOrders.length
                    ? 'modified-order-count'
                    : 'new-orders-count'
              }
            >
              {newOrders.length}
            </span>
            <p>
              {t('orders_history.new')}
              {modifiedOrders.length > 0 ? <br /> : null} {t('order_view.order')}
              {modifiedOrders.length > 0 && newOrders.length > 1 ? 's' : ''}
            </p>
          </div>
        ) : null}
      </div>
      <Button id="overlayOrdersShow" color="light" onClick={openOrders}>
        {t('orders.show_order')}
        {modifiedOrders.length + newOrders.length > 1 ? 's' : ''}{' '}
      </Button>
    </div>
  );
}

export default OverlayOrders;
