import { useEffect, useState } from 'react';
import { FormControl, Box, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, InputLabel } from '@mui/material';
import { ORDERS_FILTER_STATUS_HISTORY } from 'constants/config';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 240,
    },
  },
};

const StatusesSelect = ({ show, requestData, setRequestData }) => {
  const { t } = useTranslation();

  const currentStatus = JSON.parse(localStorage.getItem('requestData'));
  const statues = [];
  currentStatus && Object.values(currentStatus?.statuses).forEach((item) => statues?.push(item?.charAt(0).toUpperCase() + item?.slice(1)));
  const [selected, setSelected] = useState({
    statuses: currentStatus?.statuses
      ? statues
      : Object.values(ORDERS_FILTER_STATUS_HISTORY).map((item) => t(item.name)),
  });
  const statusesFilter = Object.values(ORDERS_FILTER_STATUS_HISTORY).map((item) => t(item.name));
  useEffect(() => {
    if (!requestData.statuses.length) {
      requestData.statuses.push(...selected.statuses.map((status) => t(status).toLowerCase()));
      setRequestData({
        ...requestData,
        statuses: statusesFilter.map((status) => t(status).toLowerCase()),
      });
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('requestData')),
        ...requestData,
      });
    }
  }, [requestData, selected.statuses, setRequestData, statusesFilter]);

  const handleChange = (event, child) => {
    const { name, value } = event.target;
    if (name === 'statuses' && child.props.value === 'checkAll') {
      if (selected?.statuses?.length === statusesFilter.length) {
        setRequestData({
          ...requestData,
          statuses: [],
        });
        return setSelected({
          ...selected,
          statuses: [],
        });
      }
      setRequestData({
        ...requestData,
        statuses: statusesFilter.map((status) => status.toLowerCase()),
      });
      requestData.statuses = statusesFilter.map((status) => status.toLowerCase());
      JSON.stringify({ ...JSON.parse(localStorage.getItem('requestData')), ...requestData });
      return setSelected({ ...selected, statuses: [...statusesFilter] });
    }

    if (!value?.length) {
      // requestData.statuses = statusesFilter.map((status) => status.toLowerCase());
      setRequestData({
        ...requestData,
        statuses: [],
      });
      JSON.stringify({ ...JSON.parse(localStorage.getItem('requestData')), ...requestData });
    } else {
      setRequestData({
        ...requestData,
        statuses: [...value.map((status) => status.toLowerCase())],
      });
      JSON.stringify({ ...JSON.parse(localStorage.getItem('requestData')), ...requestData });
    }

    setRequestData({
      ...requestData,
      statuses: [...value.map((status) => status.toLowerCase())],
    });
    setSelected({
      ...selected,
      [name]: value,
    });
    JSON.stringify({ ...JSON.parse(localStorage.getItem('requestData')), ...requestData });
  };
  return (
    <FormControl size="small" sx={{ minWidth: show ? 240 : 200, width: show ? 240 : 200, ml: 0, mt: 1 }}>
      <Box className="history-status">
        <InputLabel id="statuses">{t('settings.status_*')}</InputLabel>
        <Select
          labelId="statuses"
          id="statuses"
          name="statuses"
          sx={{ width: show ? 240 : 200, pl: 1 }}
          MenuProps={MenuProps}
          multiple
          value={selected.statuses}
          onChange={handleChange}
          input={
            <OutlinedInput
              error={selected?.statuses?.length === 0}
              id="select-statuses"
              placeholder="Status"
              label={t('settings.status_*')}
            />
          }
          renderValue={(selected) => (
            <>
              {selected?.length && selected[0]}
              {selected?.length > 1 && <span className="font-16 render-title">( + {selected?.length - 1} other )</span>}
            </>
          )}
        >
          {statusesFilter.length > 1 && (
            <MenuItem value="checkAll">
              <Checkbox
                name="allCheck"
                checked={selected.statuses.length === statusesFilter.length}
                value={selected.statuses.length}
              />
              <ListItemText primary={t('general.all')} />
            </MenuItem>
          )}
          {statusesFilter.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox
                checked={selected.statuses.indexOf(name) > -1 || selected.statuses.includes('Rejected_by_orders_co')}
              />
              <ListItemText primary={t(name)} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      {selected.statuses.length === 0 && <span className="select-error">{t('field_is_required_')}</span>}
    </FormControl>
  );
};

export default StatusesSelect;
