import React, { memo } from 'react';
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import CustomSwitch from 'components/Forms/CustomSwitch';
import { SwitcherWrapper } from 'pages/TableOrdering/styles';
import useTakeOutAutoConfirm from 'pages/Providers/TableOrdering/hooks/useTakeOutAutoConfirm';
import { SETTINGS_TABLE_OPTIONS, useTableOrderingSettings } from 'pages/TableOrdering/hooks/useTableOrderingSettings';

const TakeOutAutoConfirm = ({ items, srv, isDisableAutoConfirm, handleOpenAutoConfirmModal, type }) => {
  const { t } = useTranslation();
  const { loadingSettings } = useSelector(({ tableOrdering }) => tableOrdering);
  const { settingsTakeOut, handleTakeOutSubmit, handleTakeOutChange, selectedOptions } = useTableOrderingSettings();
  const { autoConfirmTakeOut, handleSubmit, handleConfirm, loading } = useTakeOutAutoConfirm(items, srv);

  return (
    <>
      <FormProvider {...settingsTakeOut}>
        <form
          onSubmit={settingsTakeOut.handleSubmit(handleTakeOutSubmit)}
          style={{
            width: '100%',
            mt: 3,
          }}
        >
          {loadingSettings && selectedOptions === SETTINGS_TABLE_OPTIONS.TAKE_OUT && <Preloader overlay={true} />}
          <SwitcherWrapper
            sx={{ ml: 2, mt: 2 }}
          >
            <CustomSwitch
              onChange={handleTakeOutChange}
              name="isEnable"
              checked={settingsTakeOut.getValues()?.isEnable}
              label={t('table_ordering.takeout_switcher')}
            />
          </SwitcherWrapper>
        </form>
      </FormProvider>
      <Divider />
      <FormProvider {...autoConfirmTakeOut}>
        <form
          onSubmit={autoConfirmTakeOut.handleSubmit(handleSubmit)}
          style={{
            width: '100%',
            mt: 3,
          }}
        >
          {loading && <Preloader overlay={true} />}
          <SwitcherWrapper
            sx={{ ml: 2, mt: 2 }}
            onClick={isDisableAutoConfirm ? handleOpenAutoConfirmModal : () => {}}
          >
            <CustomSwitch
              disabled={isDisableAutoConfirm || !settingsTakeOut.getValues()?.isEnable}
              name="orderConfirm.take_out.autoConfirm"
              checked={autoConfirmTakeOut.getValues()?.orderConfirm?.take_out?.autoConfirm}
              label={t(`Auto-confirm ${type} order`)}
              onChange={(event) => handleConfirm(event)}
            />
          </SwitcherWrapper>
        </form>
      </FormProvider>
    </>
  );
};

export default memo(TakeOutAutoConfirm);
