import { useTranslation } from 'react-i18next';
import { ChargeBackPriceSection, ChargeBackViewSubTitle } from './styles';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

const ChargeBackTotalSections = () => {
  const { t } = useTranslation();
  const { chargeBack } = useSelector((store) => store);
  const { selected } = chargeBack;

  return (
    <>
      <ChargeBackPriceSection>
        <Box display="flex" justifyContent="space-between">
          <ChargeBackViewSubTitle>{t('chargeBack.details.chargeback')}:</ChargeBackViewSubTitle>
          <Typography
            fontSize={16}
            lineHeight="18.75px"
            fontWeight={600}
            color={selected?.chargeTotal > 0 ? '#E21508' : 'initial'}
          >
            {selected?.chargeTotal > 0
              ? `-$${selected?.chargeTotal?.toFixed(2)}`
              : `$${selected?.chargeTotal?.toFixed(2)}`}
          </Typography>
        </Box>
      </ChargeBackPriceSection>
      {selected?.refundTotal ? (
        <ChargeBackPriceSection>
          <Box display="flex" justifyContent="space-between">
            <ChargeBackViewSubTitle>{t('chargeBack.details.refunded')}:</ChargeBackViewSubTitle>
            <Typography fontSize={16} lineHeight="18.75px" fontWeight={600} color="#00984F">
              ${selected?.refundTotal?.toFixed(2)}
            </Typography>
          </Box>
        </ChargeBackPriceSection>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChargeBackTotalSections;
