import instance from 'services/api';
import { reduceByKey } from 'services/helpers';
import { largeIcons } from 'services/serviceIcons';
import { toast } from 'react-toastify';
import { getCategoryProductsV2 } from 'redux/actions/Menu/products';
import { ModalOpenStatusEnum, SET_MODAL_ACTIONS, SET_PRODUCT_EVENTS } from 'components/ConfirmProductActions/constants';

export const getMenus = () => (dispatch) => {
  dispatch({ type: 'MENU_REQ' });
  return instance
    .get('/menu')
    .then(({ data: { data } }) => {
      dispatch({
        type: 'MENU_RES',
        payload: {
          loading: false,
          tabs: data.map((item) => ({
            image: largeIcons[item.type],
            name: item.name,
            id: item.type,
            url: `/menu/${item.type}${item.categories.length ? `/${item.categories[0]._id}` : ''}`,
          })),
          menus: data.reduce(
            (acc, menu) => ({
              ...acc,
              [menu.type]: {
                ...menu,
                categories: reduceByKey(menu.categories, '_id'),
              },
            }),
            {}
          ),
        },
      });
      return data;
    })
    .catch((err) => {
      dispatch({ type: 'MENU_ERR' });
      return Promise.reject(err);
    });
};

export const deleteMenu = (key) => (dispatch) => {
  dispatch({ type: 'MENU_REQ' });
  return instance
    .delete(`/menu/${key}`)
    .then(({ data: { data } }) => {
      // dispatch({ type: 'MENU_DELETE_RES', payload: key });
      return data;
    })
    .catch((err) => {
      dispatch({ type: 'MENU_ERR' });
      return Promise.reject(err);
    });
};

export const addMenuProduct = (body) => (dispatch) => {
  return instance
    .post('/products', body)
    .then((res) => {
      const {
        data: { data },
      } = res;
      // toast.success('Product successfully added!');
      if (res?.data?.events) {
        dispatch({ type: SET_PRODUCT_EVENTS, payload: res?.data?.events });
      }
      return data;
    })
    .catch((err) => {
      // toast.error('Fail to add product!');
      return Promise.reject(err);
    });
};

export const updateMenuProduct = (_id, body, currentCategory) => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();

  dispatch({ type: 'UPDATE_PRODUCT_REQ' });
  return instance
    .put(`/products/${_id}`, body)
    .then((res) => {
      const {
        data: { data },
      } = res;
      dispatch({ type: SET_PRODUCT_EVENTS, payload: res?.data?.events });
      if (res?.data?.events?.length) {
        localStorage.setItem('SET_MODAL_ACTIONS', ModalOpenStatusEnum.opened);
        dispatch({ type: SET_MODAL_ACTIONS, payload: ModalOpenStatusEnum.opened });
      }
      menu.bulkInfo.isSaved = false;
      data.bulkInfo.isSaved = false;
      if (body.category !== currentCategory) {
        if (body.category) {
          dispatch(getCategoryProductsV2(body.category));
        }
        if (currentCategory) {
          dispatch(getCategoryProductsV2(currentCategory));
        }
      } else if (body.category) {
        dispatch(getCategoryProductsV2(body.category));
      }
      // TODO
      // if (body.category !== currentCategory) {
      //   if (body.category) {
      //     dispatch(getCategoryProductsV2(body.category));
      //   }

      //   if (currentCategory) {
      //     dispatch(getCategoryProductsV2(currentCategory));
      //   }
      //   // const newMenu = moveProduct(currentCategory, body.category, body._id, menu.categories);
      //   // dispatch({ type: SET_MENU_CATEGORIES_V2, payload: newMenu });
      // }
      // if (body.category) {
      //   dispatch(getCategoryProductsV2(body.category));
      // }
      dispatch({ type: 'UPDATE_PRODUCT_RES' });
      // toast.success('Product successfully updated!');
      return { data, events: res?.data?.events };
    })
    .catch((err) => {
      dispatch({ type: 'UPDATE_PRODUCT_ERR' });

      // toast.error('Fail to updated product!');
      return Promise.reject(err);
    });
};

export const deleteMenuProduct = (id, params) => (dispatch) => {
  return instance
    .delete(`/products/${id}`, { data: params })
    .then(() => {
      // toast.success('Product successfully deleted!');
      return id;
    })
    .catch((err) => {
      // toast.error('Fail to delete product!');
      return Promise.reject(err);
    });
};

export const addModifier = (body) => () => {
  return instance
    .post('/modifier', body)
    .then(({ data: { data } }) => data)
    .catch((err) => {
      // toast.error('Fail to add modifier!');
      return Promise.reject(err);
    });
};

export const downloadMenu = (body, provider) => (dispatch) => {
  return instance
    .post(`/menu/sync/${provider}`, body)
    .then(({ data: { data } }) => {
      // toast.success('Menu successfully downloaded!');

      return data;
    })
    .catch((err) => {
      // toast.error('Download failed!');

      return Promise.reject(err);
    });
};

export const updateMenuSettings = (body, provider) => (dispatch) => {
  return instance
    .put(`/menu/${provider}`, body)
    .then(({ data: { data } }) => {
      return data;
    })
    .catch((err) => {
      // toast.error('Updated failed!');
      return Promise.reject(err);
    });
};

export const pushMenu = (body) => (dispatch) => {
  return instance
    .post('/menu/push', body)
    .then(({ data: { data } }) => {
      dispatch(getCategoryProductsV2());
      return data;
    })
    .catch((err) => {
      // toast.error('Link failed!');
      return Promise.reject(err);
    });
};

export const pushCategory = (body) => {
  return instance
    .post('/menu/category/push', body)
    .then(({ data: { data } }) => {
      body.isLinked ? toast.success('Category successfully unlinked!') : toast.success('Category successfully linked!');

      return data;
    })
    .catch((err) => {
      // toast.error('Link failed!');
      return Promise.reject(err);
    });
};

export const pushProduct = (body) => {
  return instance
    .post('/menu/product/push', body)
    .then(({ data: { data } }) => {
      body.isLinked ? toast.success('Product successfully unlinked!') : toast.success('Product successfully linked!');

      return data;
    })
    .catch((err) => {
      toast.error('Link failed!');
      return Promise.reject(err);
    });
};

export const deAuthorize = (body) => {
  return instance
    .post('/payment/deauthorize', body)
    .then(({ data: { data } }) => {
      toast.success('Successfully Deauthorize!');
      return data;
    })
    .catch((err) => {
      toast.error('Deauthorize failed!');
      return Promise.reject(err);
    });
};

export const getMenusSettings = () => {
  return instance.get('/menu/settings').then(({ data: { data } }) => {
    return data;
  });
};

// export const reorderProducts = (data) => (dispatch) => {
//   return instance
//     .put(
//       `products/sort`,
//       data.map((_id, sortIndex) => ({ _id, sortIndex }))
//     )
//     .then(() => dispatch(getMenus()));
// };
