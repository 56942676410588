import { useEffect, useState } from 'react';
import { FormControl, Box, Select, MenuItem, Checkbox, ListItemText, OutlinedInput, InputLabel } from '@mui/material';
import { t } from 'i18next';
import useProviderConfig from 'hooks/useProviderConfig';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 240,
    },
  },
};

const ProviderSelect = ({ show, requestData, setRequestData, filteredList }) => {
  const { providers: configProviders } = useProviderConfig();
  const allProviders = Object.keys(filteredList);
  const providers = [];
  Object.values(requestData?.providers).forEach((item) => {
    allProviders.map((provider) => {
      let lowerCase = provider.toLowerCase();
      if (lowerCase === item) {
        providers.push(provider);
      }
    });
  });

  const [selected, setSelected] = useState({
    providers: requestData?.providers ? providers : allProviders,
  });
  useEffect(() => {
    if (!requestData.providers.length) {
      requestData.providers.push(...selected.providers.map((provider) => provider.toLowerCase()));
      // setRequestData({
      //   ...requestData,
      //   providers: allProviders.map((provider) => provider.toLowerCase()),
      // });
      JSON.stringify({ ...JSON.parse(localStorage.getItem('requestData')), ...requestData });
    }
  }, [requestData, selected.providers, setRequestData]);

  const handleChange = (event, child) => {
    const { name, value } = event.target;
    if (name === 'providers' && child.props.value === 'checkAll') {
      if (selected.providers.length === allProviders.length) {
        setRequestData({
          ...requestData,
          providers: [],
        });
        JSON.stringify({ ...JSON.parse(localStorage.getItem('requestData')), ...requestData });
        return setSelected({
          ...selected,
          providers: [],
        });
      }
      setRequestData({
        ...requestData,
        providers: allProviders.map((provider) => provider.toLowerCase()),
      });
      requestData.providers = allProviders.map((provider) => provider.toLowerCase());
      JSON.stringify({ ...JSON.parse(localStorage.getItem('requestData')), ...requestData });
      return setSelected({ ...selected, providers: [...allProviders] });
    }

    if (!value?.length) {
      setRequestData({
        ...requestData,
        providers: [],
      });
      JSON.stringify({ ...JSON.parse(localStorage.getItem('requestData')), ...requestData });
    } else {
      setRequestData({
        ...requestData,
        providers: [...value.map((provider) => provider.toLowerCase())],
      });
      JSON.stringify({ ...JSON.parse(localStorage.getItem('requestData')), ...requestData });
    }

    setRequestData({
      ...requestData,
      providers: [...value.map((provider) => provider.toLowerCase())],
    });

    setSelected({
      ...selected,
      [name]: value,
    });
    JSON.stringify({ ...JSON.parse(localStorage.getItem('requestData')), ...requestData });
  };
  return (
    <FormControl size="small" sx={{ minWidth: show ? 240 : 200, width: show ? 240 : 200 }}>
      <Box className="history-provider">
        <InputLabel id="provider-select">{t('reports_settings.providers_*')}</InputLabel>
        <Select
          sx={{ width: show ? 240 : 200, pl: 1 }}
          name="providers"
          labelId="provider-select"
          id="provider-checkbox"
          MenuProps={MenuProps}
          multiple
          value={selected.providers}
          onChange={handleChange}
          input={
            <OutlinedInput
              error={selected.providers.length === 0}
              id="select-provider"
              placeholder="Providers"
              label={t('reports_settings.providers_*')}
            />
          }
          renderValue={(selected) => {
            const providerValue = configProviders[selected[0]]?.label?.toUpperCase();
            return (
              <>
                {selected.length && providerValue}
                {selected.length > 1 && <span className="font-16 render-title">( + {selected.length - 1} other )</span>}
              </>
            );
          }}
        >
          {allProviders.length > 1 && (
            <MenuItem value="checkAll">
              <Checkbox
                name="allCheck"
                checked={selected.providers.length === allProviders.length}
                value={selected.providers.length}
              />
              <ListItemText primary={t('general.all')} />
            </MenuItem>
          )}
          {allProviders.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selected.providers.indexOf(name) > -1} />
              <ListItemText primary={configProviders?.[name]?.label?.toUpperCase() || ''} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      {selected.providers.length === 0 && <span className="select-error">{t('field_is_required_')}</span>}
    </FormControl>
  );
};

export default ProviderSelect;
