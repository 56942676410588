import { PROVIDERS_MAX_PICKUP_TIME } from './constants';

/**
 * Define max pickup time depending on the current provider
 *
 * @param {number} defaultPrepTime
 * @param {string} provider
 * @returns {number}
 */
export function getPickupMaxTimeByProvider(defaultPrepTime, provider) {
  switch (provider) {
    case 'grubhub':
      return PROVIDERS_MAX_PICKUP_TIME.grubhub;
    case 'ubereats':
    case 'doordash':
      return PROVIDERS_MAX_PICKUP_TIME[provider];
    case 'website':
    default:
      return 120;
  }
}
