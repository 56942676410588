import { useCallback, useEffect, useMemo, useState } from 'react';
import useProviderConfig from 'hooks/useProviderConfig';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PROVIDER_AUTO_CONFIRM_ORDER, PROVIDER_NOT_CONFIRM_ORDER } from 'constants/providerStatuses';
import { getProviders, updateProviderConfirmOrder, updateServiceFeesMenu } from 'redux/actions/providers';

export const useProviderSettings = (
  serviceFeeFormMethods,
  type,
  deliveryAutoConfirm,
  pickUpAutoConfirm,
  setPickUpAutoConfirm,
  setDeliveryAutoConfirm
) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { providers } = useProviderConfig();
  const [loadingAutoConfirm, setLoading] = useState(false);
  const { currentBusiness } = useSelector(({ business }) => business);
  const { items, providerId, providersSettings, loading } = useSelector(({ providers }) => providers);
  const [data, setData] = useState(location?.state?.data);
  const [id, setId] = useState({});
  const [credentialTablet, setCredentialTablet] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openModalProviderSettings, setOpenModalProviderSettings] = useState(false);
  const { register, watch, reset } = serviceFeeFormMethods;

  const TYPES = {
    pick_up: 'pickup',
    delivery: 'delivery',
  };

  const convertNameByProvider = (providerName) => {
    return providers[data?.srv]?.label === providers?.food2?.label ? providers[data?.srv]?.label : providerName;
  };

  const handleConfirmOrderChange = useCallback(
    (event, currentType) => {
      setLoading(true);
      const { checked } = event.target;
      const findItem = items?.find((item) => item?.srv === data?.srv);
      const orderConfirm = {
        delivery: {
          autoConfirm: currentType === 'delivery' ? checked : findItem?.orderConfirm?.delivery?.autoConfirm || false,
        },
        pickup: {
          autoConfirm: currentType === 'pick-up' ? checked : findItem?.orderConfirm?.pickup?.autoConfirm || false,
        },
      };
      setPickUpAutoConfirm(orderConfirm?.pickup?.autoConfirm);
      setDeliveryAutoConfirm(orderConfirm?.delivery?.autoConfirm);
      dispatch(
        updateProviderConfirmOrder({
          id: providerId,
          confirmOrder: checked ? PROVIDER_AUTO_CONFIRM_ORDER : PROVIDER_NOT_CONFIRM_ORDER,
          provider: data?.srv,
          orderConfirm: orderConfirm,
        })
      )
        .then(() => {
          return dispatch(getProviders());
        })
        .finally(() => setLoading(false));
    },
    [dispatch, providerId, data, items]
  );

  const handleApplyServiceFee = (values, type) => {
    const body = {
      ...values,
      menuId: '',
      provider: data?.srv === providers?.food2?.srv ? providers?.website?.srv : data?.srv,
      type: TYPES[type],
    };

    dispatch(updateServiceFeesMenu(body));
    setOpenModalProviderSettings(false);
  };

  const handleApplyServiceFeeCancel = () => {
    setOpenModalProviderSettings(false);
  };

  const checked = useMemo(() => {
    const newData = items.find((item) => item?.srv === data?.srv);
    return newData?.confirmOrder === PROVIDER_AUTO_CONFIRM_ORDER;
  }, [data?.srv, items]);

  const showFee = watch('enabled');

  useEffect(() => {
    if (location?.state?.data && location?.state?.id) {
      items.forEach((provider) => {
        if (provider?.srv === providers.doordash.srv) {
          setCredentialTablet(provider?.tabletAuth || {});
        }
      });
      setData(location?.state?.data);
      setId(location?.state?.id);
    } else {
      Object.keys(providers).forEach((item) => {
        if (location.pathname.includes(item)) {
          if (items.length > 0) {
            items.forEach((provider) => {
              if (provider?.srv === item) {
                setCredentialTablet(provider?.tabletAuth || {});
                setData(provider);
              }
            });
          }
        }
      });
    }
  }, [location?.state, setData, setId, items]);

  useEffect(() => {
    if (providersSettings[data?.srv]) {
      if (type === 'delivery' && providersSettings[data?.srv]?.feeInfo) {
        reset(providersSettings[data?.srv].feeInfo);
      } else {
        if (providersSettings[data?.srv]?.pickupFeeInfo) {
          reset(providersSettings[data?.srv].pickupFeeInfo);
        }
      }
      // reset({
      //   ...providersSettings[data?.srv].feeInfo,
      //   percent:
      //     providersSettings[data?.srv].feeInfo.percent === null ? '' : providersSettings[data?.srv].feeInfo.percent,
      // });
    }
  }, [data?.srv, providersSettings]);

  const handleOpenModal = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const isDisableAutoConfirm = useMemo(() => {
    return (
      data?.srv === providers?.doordash_cartwheel?.srv ||
      data?.srv === providers?.grubhub_cartwheel?.srv ||
      currentBusiness?.roles?.name !== 'super'
    );
  }, [data]);

  const isDisableServiceFee = useMemo(() => {
    return data?.srv === providers?.ezcater?.srv;
  }, [data]);

  const showConfirmButton = useMemo(() => {
    return providers[data?.srv]?.label === providers?.food2?.label;
  }, [providers, data]);

  return {
    currentBusiness,
    loading,
    data,
    id,
    credentialTablet,
    openModal,
    openModalProviderSettings,
    setOpenModalProviderSettings,
    convertNameByProvider,
    handleConfirmOrderChange,
    handleApplyServiceFeeCancel,
    handleApplyServiceFee,
    isDisableServiceFee,
    isDisableAutoConfirm,
    handleOpenModal,
    handleClose,
    checked,
    register,
    history,
    providers,
    serviceFeeFormMethods,
    showFee,
    TYPES,
    showConfirmButton,
    loadingAutoConfirm,
  };
};
