// src/components/ReportView.jsx

import { useState, useCallback, useEffect, useRef } from 'react';
import {
  TabContent,
  TabPane,
  CustomInput,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import instance from 'services/api';
import moment from 'moment';
import Currency from 'components/Currency'; // Ensure correct path
import Preloader from 'components/Preloader'; // Ensure correct path
import ContactInfo from 'components/ContactInfo'; // Ensure correct path
import Private from 'components/Private'; // Ensure correct path
import { Box, Button, Typography, Drawer, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { confirm } from 'components/MenuNew/ConfirmModal';
import useProviderConfig from 'hooks/useProviderConfig';
import { printElement } from 'utils/printUtils'; // Import the updated print utility
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import { Refresh } from '@mui/icons-material';

const ReportView = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const reportViewRef = useRef(null);
  const { t } = useTranslation();
  const [batchReportType, setBatchReportType] = useState('x');
  const [isLoading, setIsLoading] = useState(false);

  const { providers } = useProviderConfig();

  useEffect(() => {
    if (showReport) {
      // Fetch the initial report when showReport is true
      fetchReport('x');
    }
  }, [showReport]);

  const fetchReport = async (type) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await instance.get(`/reports/${type}`);
      setReport(response.data.data);
    } catch (err) {
      console.error('Error fetching report:', err);
      setError('Failed to load the report. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchReportOnClick = useCallback(() => {
    setIsLoading(true);
    fetchReport(batchReportType).finally(() => setIsLoading(false));
  }, []);

  const toggleShowReport = useCallback(() => {
    setShowReport((prev) => !prev);
  }, []);

  const goToScreen = useCallback((i) => setSelectedIndex(i), []);

  const handlePrint = useCallback(
    async (batchReportType) => {
      setIsLoading(true);
      setError(null);
      try {
        await fetchReport(batchReportType);
        // Delay to ensure the DOM updates before printing
        setTimeout(() => {
          if (reportViewRef.current) {
            printElement(
              'batchReport',
              {
                sx: {
                  // Set max-width to 440px and center the content
                  maxWidth: '440px',
                  margin: '0 auto',
                  'box-sizing': 'border-box',
                },
              },
              () => {
                toggleShowReport(); // Close the Drawer after printing
              }
            );
          }
        }, 100); // Adjust the delay as needed
      } catch (err) {
        console.error('Error fetching report:', err);
        setError('Failed to load the report. Please try again.');
      } finally {
        setIsLoading(false);
      }
    },
    [toggleShowReport]
  );

  const PrintScreen = () => {
    const handleSelectReportType = async () => {
      const confirmed =
        batchReportType === 'x'
          ? true
          : await confirm({
              title: 'confirm_modal.print',
              message: 'confirm_modal.are_you_sure',
              cancelText: 'confirm_modal.no',
              confirmText: 'confirm_modal.yes',
              confirmColor: 'primary',
            });
      if (confirmed) {
        await handlePrint(batchReportType);
      }
    };

    return (
      <Box className="aside-view-tab">
        {/* Header with Back Arrow and Title */}
        <Box
          className="aside-view-head"
          display="flex"
          alignItems="center"
          padding="16px"
        >
          <IconButton onClick={() => goToScreen(0)} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 'bold' }}
          >
            {t('Print')}
          </Typography>
        </Box>

        {/* Report Type Selection */}
        <Box className="aside-view-body" padding="16px">
          <ListGroup>
            <ListGroupItem>
              <CustomInput
                type="radio"
                id="x"
                value="x"
                name="typeReport"
                checked={batchReportType === 'x'}
                onChange={() => setBatchReportType('x')}
                label="X Report"
              />
            </ListGroupItem>
            <ListGroupItem>
              <CustomInput
                type="radio"
                id="z"
                value="z"
                name="typeReport"
                checked={batchReportType === 'z'}
                onChange={() => setBatchReportType('z')}
                label="Z Report"
              />
            </ListGroupItem>
          </ListGroup>
        </Box>

        {/* Print Action */}
        <Box className="aside-view-actions" padding="16px">
          <Button
            id="reportViewPrint"
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleSelectReportType}
            fullWidth
            startIcon={<PrintIcon />}
          >
            {isLoading ? t('Loading...') : t('Print')}
          </Button>
        </Box>
      </Box>
    );
  };

  const MainScreen = () => {
    const printBox = useRef();

    const openPrint = () => {
      printBox.current.scrollTo(0, 0);
      goToScreen(1);
    };

    return (
      <Private permission="BATCH_REPORT">
        {showReport && (
          <Box className="aside-view-tab">
            {/* Header Section with Back Arrow */}
            <Box
              className="aside-view-head"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="16px"
              sx={{ backgroundColor: 'transparent' }} // Remove background color
            >
              <IconButton onClick={toggleShowReport} aria-label="back">
                <ArrowBackIcon />
              </IconButton>
              <IconButton onClick={fetchReportOnClick} aria-label="back">
                <Refresh />
              </IconButton>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {t('BATCH REPORT')}
              </Typography>
              <IconButton
                id="reportViewOpenPrint"
                onClick={openPrint}
                variant="outlined"
                color="primary"
                aria-label={t('Print Batch Report')}
              >
                <PrintIcon />
              </IconButton>
            </Box>

            {/* Body Section */}
            <Box
              id="batchReport"
              className="aside-view-body"
              ref={printBox}
              padding="16px"
              sx={{ overflowX: 'auto' }}
            >
              {report?.report && Object.values(report.report).length ? (
                <Box ref={reportViewRef} className="printEnabled">
                  {/* Print Header */}
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: 'bold' }}
                  >
                    {t('Printout')}{' '}
                    {Object.values(report.report)[0].reportModel.printoutType}
                  </Typography>

                  {/* All Businesses Section */}
                  {Object.values(report.report).length > 1 && (
                    <>
                      <Box className="section">
                        <Typography
                          variant="h6"
                          className="section-title"
                          gutterBottom
                          sx={{ fontWeight: 'bold' }}
                        >
                          {t('All Businesses')}
                        </Typography>
                        {report.all_business.totalsByProviders.length
                          ? report.all_business.totalsByProviders.map(
                              (
                                {
                                  ordersCount,
                                  providerName,
                                  salesTotal,
                                  adjustmentsFees,
                                  tax,
                                  subTotalPrice,
                                },
                                index
                              ) => (
                                <Box
                                  className="provider-summary"
                                  key={`${index}-${providerName}`}
                                  mb={3}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    sx={{ fontWeight: 'bold' }}
                                  >
                                    {providerName}
                                  </Typography>
                                  <table className="report-printout-table">
                                    <tbody>
                                      <tr>
                                        <td>{t('Subtotal')}:</td>
                                        <td>
                                          <Currency value={subTotalPrice} />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('Tax')}:</td>
                                        <td>
                                          <Currency value={tax} />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('Adjustments & Fees')}:</td>
                                        <td>
                                          <Currency value={adjustmentsFees} />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('Total')}:</td>
                                        <td>
                                          <Currency value={salesTotal} />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('Orders')}:</td>
                                        <td>
                                          <strong>{ordersCount}</strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Box>
                              )
                            )
                          : null}

                        {/* Website Total Values */}
                        {report.all_business.websiteTotalValues && (
                          <>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{ fontWeight: 'bold' }}
                            >
                              {providers[
                                report.all_business.websiteTotalValues
                                  .providerName
                              ]?.label || 'Orders.co'}
                            </Typography>
                            <table className="report-printout-table">
                              <tbody>
                                <tr>
                                  <td>{t('Subtotal')}:</td>
                                  <td>
                                    <Currency
                                      value={
                                        report.all_business.websiteTotalValues
                                          .subTotalPrice
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('Tax')}:</td>
                                  <td>
                                    <Currency
                                      value={
                                        report.all_business.websiteTotalValues
                                          .tax
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('Adjustments & Fees')}:</td>
                                  <td>
                                    <Currency
                                      value={
                                        report.all_business.websiteTotalValues
                                          .adjustmentsFees
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('Total')}:</td>
                                  <td>
                                    <Currency
                                      value={
                                        report.all_business.websiteTotalValues
                                          .salesTotal
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t('Orders')}:</td>
                                  <td>
                                    <strong>
                                      {
                                        report.all_business.websiteTotalValues
                                          .ordersCount
                                      }
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}

                        {/* Aggregate Totals */}
                        <Box mt={4}>
                          <Typography
                            variant="h6"
                            className="aggregate-totals"
                            gutterBottom
                            sx={{ fontWeight: 'bold' }}
                          >
                            {t('Summary (All)')}
                          </Typography>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={1}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {t('Subtotal')}:
                            </Typography>
                            <Currency
                              value={
                                report.all_business.totalValues.subTotalPrice
                              }
                            />
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={1}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {t('Tax')}:
                            </Typography>
                            <strong>
                              <Currency
                                value={report.all_business.totalValues.tax}
                              />
                            </strong>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={1}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {t('Adjustments & Fees')}:
                            </Typography>
                            <strong>
                              <Currency
                                value={
                                  report.all_business.totalValues
                                    .adjustmentsFees
                                }
                              />
                            </strong>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={1}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {t('Total')}:
                            </Typography>
                            <strong>
                              <Currency
                                value={
                                  report.all_business.totalValues.salesTotal
                                }
                              />
                            </strong>
                          </Box>
                          <Box display="flex" justifyContent="space-between">
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {t('Orders')}:
                            </Typography>
                            <strong>
                              {report.all_business.totalValues.ordersCount}
                            </strong>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}

                  {/* Creating report for each business */}
                  {Object.values(report.report).map(
                    ({ reportModel, businessId }, index) => {
                      const {
                        totalsByProviders,
                        websiteTotalValues,
                        totalValues,
                        businessName,
                        startDate,
                        endDate,
                      } = reportModel;
                      return (
                        <Box
                          key={`${index}-${businessId}`}
                          className="section"
                          mb={4}
                        >
                          {/* Business Header */}
                          <Box mb={1}>
                            <Typography
                              variant="h6"
                              className="section-title"
                              gutterBottom
                              sx={{ fontWeight: 'bold' }}
                            >
                              {businessName}
                            </Typography>
                          </Box>

                          {/* Date Information */}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            mb={2}
                          >
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 'bold', color: '#424242' }}
                              >
                                {t('batch_report.start_date')}:
                              </Typography>
                              <Typography variant="body2">
                                {moment(startDate).format(
                                  'hh:mm A - ddd, MMM Do'
                                )}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 'bold', color: '#424242' }}
                              >
                                {t('batch_report.end_date')}:
                              </Typography>
                              <Typography variant="body2">
                                {moment(endDate).format(
                                  'hh:mm A - ddd, MMM Do'
                                )}
                              </Typography>
                            </Box>
                          </Box>

                          {/* Contact Information */}
                          <ContactInfo report={reportModel} />

                          {/* Provider Summaries */}
                          {totalsByProviders?.map(
                            (
                              {
                                ordersCount,
                                providerName,
                                salesTotal,
                                tax,
                                subTotalPrice,
                                adjustmentsFees,
                              },
                              index
                            ) => (
                              <Box
                                className="provider-summary"
                                key={`${index}-${providerName}`}
                                mb={3}
                              >
                                <Typography
                                  variant="subtitle1"
                                  gutterBottom
                                  sx={{ fontWeight: 'bold' }}
                                >
                                  {providerName}
                                </Typography>
                                <table className="report-printout-table">
                                  <tbody>
                                    <tr>
                                      <td>{t('Subtotal')}:</td>
                                      <td>
                                        <Currency value={subTotalPrice} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t('Tax')}:</td>
                                      <td>
                                        <Currency value={tax} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t('Adjustments & Fees')}:</td>
                                      <td>
                                        <Currency value={adjustmentsFees} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t('Total')}:</td>
                                      <td>
                                        <Currency value={salesTotal} />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t('Orders')}:</td>
                                      <td>
                                        <strong>{ordersCount}</strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Box>
                            )
                          )}

                          {/* Website Total Values */}
                          {websiteTotalValues && (
                            <Box className="provider-summary" mb={3}>
                              <Typography
                                variant="subtitle1"
                                gutterBottom
                                sx={{ fontWeight: 'bold' }}
                              >
                                {websiteTotalValues.providerName}
                              </Typography>
                              <table className="report-printout-table">
                                <tbody>
                                  <tr>
                                    <td>{t('Subtotal')}:</td>
                                    <td>
                                      <Currency
                                        value={websiteTotalValues.subTotalPrice}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>{t('Tax')}:</td>
                                    <td>
                                      <Currency
                                        value={websiteTotalValues.tax}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>{t('Adjustments & Fees')}:</td>
                                    <td>
                                      <Currency
                                        value={
                                          websiteTotalValues.adjustmentsFees
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>{t('Total')}:</td>
                                    <td>
                                      <Currency
                                        value={websiteTotalValues.salesTotal}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>{t('Orders')}:</td>
                                    <td>
                                      <strong>
                                        {websiteTotalValues.ordersCount}
                                      </strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Box>
                          )}

                          {/* Aggregate Totals */}
                          <Box>
                            <Typography
                              variant="h6"
                              className="aggregate-totals"
                              gutterBottom
                              sx={{ fontWeight: 'bold' }}
                            >
                              {t('Summary (All)')}
                            </Typography>
                            <Box display="flex" justifyContent="space-between">
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 'bold' }}
                              >
                                {t('Subtotal')}:
                              </Typography>
                              <Currency value={totalValues.subTotalPrice} />
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 'bold' }}
                              >
                                {t('Tax')}:
                              </Typography>
                              <strong>
                                <Currency value={totalValues.tax} />
                              </strong>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 'bold' }}
                              >
                                {t('Adjustments & Fees')}:
                              </Typography>
                              <strong>
                                <Currency value={totalValues.adjustmentsFees} />
                              </strong>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 'bold' }}
                              >
                                {t('Total')}:
                              </Typography>
                              <strong>
                                <Currency value={totalValues.salesTotal} />
                              </strong>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 'bold' }}
                              >
                                {t('Orders')}:
                              </Typography>
                              <strong>{totalValues.ordersCount}</strong>
                            </Box>
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Box>
              ) : isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={4}
                >
                  <Preloader />
                  <Typography variant="body1" sx={{ marginLeft: '10px' }}>
                    {t('Preparing your report. Please wait...')}
                  </Typography>
                </Box>
              ) : error ? (
                <Box className="text-danger text-center py-5">{error}</Box>
              ) : (
                <Box className="text-muted text-center py-5">
                  {t('There are no new transactions to report.')}
                </Box>
              )}
            </Box>

            {/* Removed Footer "Done" Button */}
            {/*
            <Box className="aside-view-actions" display="flex" justifyContent="flex-end" padding="16px">
              <Button
                id="reportViewMenuDone"
                variant="contained"
                color="primary"
                onClick={toggleShowReport}
                aria-label={t('Done')}
              >
                {t('Done')}
              </Button>
            </Box>
            */}
          </Box>
        )}
      </Private>
    );
  };

  return (
    <>
      <Button
        id="ordersFiltersBatchReport"
        variant="outlined"
        color="primary"
        onClick={toggleShowReport}
        sx={{ height: '40px', width: '140px', whiteSpace: 'nowrap' }}
      >
        {t('BATCH REPORT')}
      </Button>

      {/* Drawer Component */}
      {showReport && (
        <Drawer
          anchor="right" // You can change this to "left" if desired
          open={showReport}
          onClose={toggleShowReport}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': {
              width: '100%', // Full width on smaller screens
              maxWidth: '480px', // Maximum width of 480px
              boxSizing: 'border-box',
              padding: '0', // Remove default padding
            },
          }}
        >
          <Box role="presentation" sx={{ height: '100%', overflow: 'auto' }}>
            <TabContent activeTab={selectedIndex}>
              <TabPane
                tabId="0"
                className={`order-print-screen ${
                  selectedIndex === 0 ? 'active' : ''
                }`}
              >
                <MainScreen />
              </TabPane>
              <TabPane
                tabId="1"
                className={`${selectedIndex === 1 ? 'active' : ''}`}
              >
                <PrintScreen />
              </TabPane>
            </TabContent>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default ReportView;
