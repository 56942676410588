import instance from '../../services/api';
import { SET_CATEGORY_PRODUCTS_V2, SET_LOADING, SET_MENU_CATEGORIES_V2, SET_PAGINATION_V2 } from './types';

export const getMenusV2 = () => (dispatch, getState) => {
  const {
    menu: { menus },
  } = getState();
  return instance
    .get('/menu/v2')
    .then(({ data: { data } }) => {
      const newTabs = [...data];
      const menuData = {};
      // Promise.all(
      data.map((menu, index) => {
        return getMenuCategoriesV2(menu)
          .then((categories) => {
            const prevCategories = menus[menu.type]?.categories;
            const categoriesByKey = {};
            categories.forEach((item) => {
              if (prevCategories && prevCategories[item._id]?.products) {
                item.products = prevCategories[item._id].products;
              }
              categoriesByKey[item._id] = item;
            });
          })
          .then(() => {
            if (Object.keys(menuData).length === data.length) {
              dispatch({
                type: SET_MENU_CATEGORIES_V2,
                payload: {
                  menus: menuData,
                },
              });
            }
          });
      });
      // );
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getMenuCategoriesV2 = (menu) => {
  const { id } = menu;
  try {
    return instance.get(`/menu/v2/${id}`).then(({ data: { data } }) => {
      return data;
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getCategoryProductsV2 = () => (dispatch, getState) => {
  const { menu } = getState();
  const { params, paginationConfig, menus } = menu;
  const { skip, limit } = paginationConfig;
  const { activeMenuId, activeCategoryId } = params;
  const categoryData = {
    ...menus,
  };
  if (!activeCategoryId || !categoryData[activeMenuId]?.categories[activeCategoryId]) {
    return dispatch({ type: SET_LOADING, payload: false });
  }
  if (!categoryData?.products?.length) {
    dispatch({ type: SET_LOADING, payload: true });
  }

  return instance
    .get(`/categories/v2/${activeCategoryId}?skip=${skip}&limit=${limit}&sortDirection=ASC&products=true`)
    .then(({ data: { data } }) => {
      data.products.forEach((item) => {
        if (!item.hasOwnProperty('isOnePrice')) {
          item.isOnePrice = true;
        }
      });
      if (categoryData[activeMenuId].categories[activeCategoryId]) {
        categoryData[activeMenuId].categories[activeCategoryId].products = data.products;
      }
      dispatch({
        type: SET_PAGINATION_V2,
        payload: {
          count: Math.ceil(data.count / limit),
        },
      });
      dispatch({
        type: SET_CATEGORY_PRODUCTS_V2,
        payload: { baseData: categoryData },
      });
      return data;
    })
    .finally(() => dispatch({ type: SET_LOADING, payload: false }))
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getCategoryProductsV2ByCategoryId = ({ selectedCategory, selectedMenu }) => (dispatch, getState) => {
  const { menu } = getState();
  const skip = 0;
  const limit = 1000;
  const { menus } = menu;
  const categoryData = {
    ...menus,
  };
  if (!selectedCategory || !selectedMenu || !categoryData) {
    return;
  }
  if (!categoryData?.products?.length) {
    dispatch({ type: SET_LOADING, payload: true });
  }
  return instance
    .get(`/categories/v2/${selectedCategory}?skip=${skip}&limit=${limit}&sortDirection=ASC&products=true`)
    .then(({ data: { data } }) => {
      categoryData[selectedMenu].categories[selectedCategory] &&
        (categoryData[selectedMenu].categories[selectedCategory].products = data?.products);
      dispatch({
        type: SET_PAGINATION_V2,
        payload: {
          count: Math.ceil(data.count / limit),
        },
      });
      dispatch({
        type: SET_CATEGORY_PRODUCTS_V2,
        payload: { baseData: categoryData },
      });
      return data;
    })
    .finally(() => dispatch({ type: SET_LOADING, payload: false }))
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getMenuFieldTranslations = (id, field) => (dispatch) => {
  try {
    return instance.get(`/language/${id}?field=${field}`).then(({ data: { data } }) => {
      return data;
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const translationsByAi = (data) => (dispatch) => {
  try {
    return instance.post(`/languages/translate/text`, data).then(({ data: { data } }) => {
      return data;
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateMenuFieldTranslations = (body, id, field) => (dispatch) => {
  try {
    return instance.put(`/language/${id}?field=${field}`, body).then(({ data: { data } }) => {
      return data;
    });
  } catch (err) {
    return Promise.reject(err);
  }
};
