export const TEXTS_PRODUCT_CHAT_GPT = {
  target: 'product',
  title: 'ai.ai_assisted_description_generator',
  desc: 'ai.harness_power_ai_your_products',
  formName: {
    name1: 'productIngredients',
    name2: 'context',
    name3: 'productDescription',
  },
  formTitle: {
    name1: 'ai.ingredients',
    name2: 'context_',
    name3: 'schedule.description',
  },
  formPlaceholder: {
    name1: 'ai.add_your_ingredients_separated',
    name2: 'ai.add_your_context_here',
    name3: 'ai.the_generated_description_here',
  },
};

export const TEXTS_BUSINESS_CHAT_GPT = {
  target: 'business',
  title: 'AI_assisted_generator',
  desc: 'harness_power_AI_description_business',
  formName: {
    name1: 'popularItems',
    name2: 'context',
    name3: 'description',
  },
  formTitle: {
    name1: 'your_popular_items',
    name2: 'context_',
    name3: 'description_',
  },
  formPlaceholder: {
    name1: 'add_your_popular_separated',
    name2: 'add_your_context_here',
    name3: 'the_generated_description_show',
  },
};

export const CHAT_GPT_ENUM = {
  PRODUCT: 'product',
  BUSINESS: 'business',
};
