export const processChargeBackReport = (chargeBackList) => {
  const reportChargeBackDisplay = {
    ubereats: {
      won: [],
      lost: [],
      pending: [],
      chargeback: [],
      wonTotal: 0,
      lostTotal: 0,
      pendingTotal: 0,
      total: 0,
    },
    doordash: {
      won: [],
      lost: [],
      pending: [],
      chargeback: [],
      wonTotal: 0,
      lostTotal: 0,
      pendingTotal: 0,
      total: 0,
    },
  };
  chargeBackList.forEach((chargeBack) => {
    const provider = chargeBack.provider.name.toLocaleLowerCase().replace(' ', '');
    switch (chargeBack.status.name) {
      case 'Won':
        reportChargeBackDisplay[provider].won.push(chargeBack);
        reportChargeBackDisplay[provider].wonTotal += chargeBack.refundTotal || 0;
        break;
      case 'Lost':
        reportChargeBackDisplay[provider].lost.push(chargeBack);
        reportChargeBackDisplay[provider].lostTotal += chargeBack.chargeTotal || 0;
        break;
      default:
        reportChargeBackDisplay[provider].pending.push(chargeBack);
        reportChargeBackDisplay[provider].pendingTotal += chargeBack.chargeTotal || chargeBack.refundTotal || 0;
        break;
    }
    reportChargeBackDisplay[provider].total += chargeBack.chargeTotal || chargeBack.refundTotal || 0;
  });
  return reportChargeBackDisplay;
};
