import i18n from 'i18next';
// import Backend from 'i18next-http-backend';
import Languagedetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationsEN from 'locales/en/translation.json';
// import translationsRU from 'locales/ru/translation.json';
import translationsCHINA from 'locales/china/translation.json';
import translationsSPAIN from 'locales/es/translation.json';

i18n
  // .use(Backend)
  .use(Languagedetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    returnEmptyString: false,

    // detection: {
    //   order: ["queryString", "cookie"],
    //   cache: ["cookie"],
    // },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: translationsEN, // Load the default translations
      },
      // ru: {
      //   translation: translationsRU,
      // },
      china: {
        translation: translationsCHINA,
      },
      spain: {
        translation: translationsSPAIN,
      },
    },
  });
// i18n.services.formatter.add("DATE_LONG", (value, lng, _option) => {
//   return DateTime.fromJSDate(value)
//     .setLocale(lng)
//     .toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS);
// });
export default i18n;
