export const objEnum = {
  SALES_BY_DATE: 'sales_date_',
  TOP_SELLERS: 'reports_settings.top_seller_items',
  SUMMARY: 'summary_report_',
  SALES_BY_PROVIDER: 'detailed_sales_provider_',
  SALES_BY_HOUR: 'reports_settings.sales_by_hour',
  DETAILED_ORDER_REPORT: 'detailed_order_report_',
  CUSTOMER_REPORT: 'customer_report_',
  SUBSCRIBER_REPORT: 'reports_settings.subscriber_report',
  CHARGEBACK_DISPUTE_REPORT: 'reports_settings.dispute_report',
};
// {
//   SALES_BY_DATE: 'Sales By Date',
//   TOP_SELLERS: 'Top Seller Items',
//   SUMMARY: 'Summary report',
//   SALES_BY_PROVIDER: 'Detailed sales by Provider',
//   SALES_BY_HOUR: 'Sales by Hour',
// }
const reportTypes = [];

export const handleTypeName = (reportTypeName) => {
  return objEnum[reportTypeName];
};
