import axios from 'axios';
import store from '../store';
import { toast } from 'react-toastify';
import { companyTypesEnum } from '../constants/companyTypes';
import { SignUpPathEnum } from '../constants/signUpPath';
const temporaryConfigUrl = ['/auth-v2/validate-one-time-setup-fee-coupon-code'];

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const instanceTableOrdering = axios.create({
  baseURL: process.env.REACT_APP_TABLE_ORDERING_SERVICE,
});

export const authInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_AUTH,
});

export const authInstanceV2 = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_AUTH_V2,
});

export const gatewayInstance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_URL,
});

export const reportingInstance = axios.create({
  baseURL: process.env.REACT_APP_REPORTING_URL,
});

export const checkToasterSuccessPermission = (response) => {
  if (window.location.pathname.includes('/signup')) {
    const companyType = localStorage.getItem('companyType');
    if (companyType && companyType === companyTypesEnum.SINGLE) {
      if (
        window.location.pathname.includes(SignUpPathEnum.ACCOUNT_INFO) ||
        window.location.pathname.includes(SignUpPathEnum.LEGAL_INFO)
      ) {
        return null;
      } else {
        return toast.success(response.data.message);
      }
    } else if (companyType && companyType !== companyTypesEnum.SINGLE) {
      if (window.location.pathname.includes(SignUpPathEnum.ACCOUNT_INFO)) {
        return null;
      } else {
        return toast.success(response.data.message);
      }
    }
  } else if (window.location.pathname.includes('/location/invitation')) {
    return null;
  } else {
    if (response?.data?.url?.includes('/brand') && response?.data?.url.includes('/location')) {
      return null;
    } else {
      return toast.success(response.data.message);
    }
  }
};
const selectedBrand = JSON.parse(localStorage.getItem('selectedBrand'))?.value?.locations[0]?.businessId;
const businessId = JSON.parse(localStorage.getItem('businessInfo'))?.id || selectedBrand;

export const setInstance = (token, business_id = businessId) => {
  localStorage.setItem('accessToken', token);
  // const businessId = JSON.parse(localStorage.getItem('businessInfo'))?.id;
  reportingInstance.defaults.headers['app-v'] = `0.1.0`;
  reportingInstance.defaults.headers.Authorization = `Bearer ${token}`;
  reportingInstance.defaults.headers.business = business_id;

  gatewayInstance.defaults.headers['app-v'] = `0.1.0`;
  gatewayInstance.defaults.headers.Authorization = `Bearer ${token}`;
  gatewayInstance.defaults.headers.business = business_id;

  instance.defaults.headers['app-v'] = `0.1.0`;
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  instance.defaults.headers.business = business_id;

  authInstance.defaults.headers['app-v'] = `0.1.0`;
  authInstance.defaults.headers.Authorization = `Bearer ${token}`;
  authInstance.defaults.headers.business = business_id;

  authInstanceV2.defaults.headers['app-v'] = `0.1.0`;
  authInstanceV2.defaults.headers.Authorization = `Bearer ${token}`;
  authInstanceV2.defaults.headers.business = business_id;

  instanceTableOrdering.defaults.headers['app-v'] = `0.1.0`;
  instanceTableOrdering.defaults.headers.Authorization = `Bearer ${token}`;
  instanceTableOrdering.defaults.headers.business = businessId;
};

export const removeInstance = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('business');
  localStorage.removeItem('type');
  localStorage.removeItem('requestData');
  localStorage.removeItem('SET_CHECKED_PROVIDER');
  localStorage.removeItem('currentBrand');
  localStorage.removeItem('currentTab');
  localStorage.removeItem('email');
  localStorage.removeItem('showActiveBusiness');
  localStorage.removeItem('redirectUrl');
  instance.defaults.headers.Authorization = '';
};

const storedToken = localStorage.getItem('accessToken');

if (storedToken) {
  setInstance(storedToken);
}

instance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      checkToasterSuccessPermission(response);
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch({ type: 'USER_LOGOUT' });
      removeInstance();
    }
    if (error.config.method !== 'get' && error.response?.data?.message) {
      let message = error.response.data.message;
      if (error.response.data.errors && !temporaryConfigUrl.includes(error.config.url)) {
        message += `\n ${Object.values(error.response.data.errors)
          .map(({ msg }) => msg)
          .join(`\n`)}`;
      }
      if (error.config.url !== '/auth/login' && !temporaryConfigUrl.includes(error.config.url)) {
        toast.error(message);
      }
    }
    return Promise.reject(error);
  }
);

authInstance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.success(response.data.message);
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch({ type: 'USER_LOGOUT' });
      removeInstance();
    }
    if (error.config.method !== 'get' && error.response?.data?.message) {
      let message = error.response.data.message;
      if (error.response.data.errors && error.response.status !== 401) {
        message += `\n ${Object.values(error.response.data.errors)
          .map(({ msg }) => msg)
          .join(`\n`)}`;
      }
      if (error.config.url !== '/auth/login' && error.response.status !== 401) {
        toast.error(message);
      }
    }
    return Promise.reject(error);
  }
);

authInstanceV2.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.success(response.data.message);
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch({ type: 'USER_LOGOUT' });
      removeInstance();
    }
    if (error.config.method !== 'get' && error.response?.data?.message) {
      let message = error.response.data.message;
      if (error.response.data.errors) {
        message += `\n ${Object.values(error.response.data.errors)
          .map(({ msg }) => msg)
          .join(`\n`)}`;
      }
      if (error.config.url !== '/auth/login') {
        toast.error(message);
      }
    }
    return Promise.reject(error);
  }
);

reportingInstance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.success(response.data.message);
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch({ type: 'USER_LOGOUT' });
      removeInstance();
    }
    if (error.config.method !== 'get' && error.response?.data?.message) {
      let message = error.response.data.message;
      if (error.response.data.errors) {
        message += `\n ${Object.values(error.response.data.errors)
          .map(({ msg }) => msg)
          .join(`\n`)}`;
      }
      if (error.config.url !== '/auth/login') {
        toast.error(message);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
