import React, { useContext, useMemo } from 'react';
import { Popover } from '@mui/material';
import ProviderStatusItem from './ProviderStatusItem';
import useProviderConfig from 'hooks/useProviderConfig';
import { StyledButton, StyledButtonBox } from './styles';
import { PauseResumeContext } from 'context/pauseResumeContext';
import { useProviderTimer } from 'components/PauseResume/hooks/useProviderTimer';
import { PAUSE_RESUME_STATUSES } from 'constants/resumePause';

const ProviderStatusPopover = ({ handleOpenModal = null, id }) => {
  const { providers } = useProviderConfig();
  const { isOpen, anchorEl, handlePopoverClose, filteredProviders, iconStyle } = useContext(PauseResumeContext);
  const updatedProviders = useProviderTimer(filteredProviders);

  const isAllStatusNA = useMemo(() => {
    return updatedProviders.every(
      (provider) => provider.provider_status === PAUSE_RESUME_STATUSES.NA
    );
  }, [updatedProviders]);

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <>
        {updatedProviders.map((item) => {
          const { logoUrl, label } = providers[item?.provider];
          return (
            <React.Fragment key={label}>
              <ProviderStatusItem
                logoUrl={logoUrl}
                label={label}
                iconStyle={iconStyle(item?.provider_status)}
                showDivider={true}
                item={item}
                showTime={true}
                currentDuration={item?.time_remaining}
              />
            </React.Fragment>
          );
        })}
        {isAllStatusNA ? (
          <></>
        ) : (
          <StyledButtonBox onClick={handleOpenModal ? handleOpenModal : null}>
            <StyledButton variant={'outlined'} type={'button'}>
              See More
            </StyledButton>
          </StyledButtonBox>
        )}
      </>
    </Popover>
  );
};
export default ProviderStatusPopover;
