import { useCallback, useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import AuthorizationButtons from './ActionButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { billingInfoFormValidationSchema } from './schema';
import { SignUpBillingMessages } from 'constants/signUpPath';
import SubscriptionPlans from 'components/SubscriptionPlans';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { SIGN_UP_FLOW_AUTHORIZATION } from 'constants/availableButtons';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { removeItemAfterSignUp, trialVersionDate } from 'utils/soldOut';
import { Box, Typography } from '@mui/material';
import GooglAutocomplete from 'components/FormFields/GoogleAutocomplate';
import { signupBillingAfterCallStripeV2 } from 'redux/actions/userAuthV2';
import CreditCardDetail from 'components/CreditCardDetails/CreditCardDetail';
import { PaymentPeriodicityContext } from 'context/paymentPeriodicityContext';
import ApplyCoupon from 'components/ApplyCoupon/ApplyCoupon';
import AcceptRequirements from 'components/ApplyCoupon/AcceptRequirements';
import { useTranslation } from 'react-i18next';
import { SubscriptionContext } from 'context/subscriptionPlanConfigContext';

const billingInfoInitialData = { addressInfo: {} };

const AuthorizationBilling = ({ availableSteps, active, setActive }) => {
  const { t } = useTranslation();

  const stripe = useStripe();
  const element = useElements();
  const [loading, setLoading] = useState(false);
  const [cardError, handleCardError] = useState(false);
  const [selectedPlanPrice, getSelectedPlanPrice] = useState('');
  const [selectedPlan, handleSelectedPlan] = useState('');
  // const [acceptCheckBox, handleAcceptCheckbox] = useState(false);
  // const [errorAccept, handleErrorAccept] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(true);
  const { periodicity } = useContext(PaymentPeriodicityContext);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [couponValue, setCouponValue] = useState('');
  const [isApply, setIsApplay] = useState(false);
  const { setUpFeeEnabled } = useContext(SubscriptionContext);
  const [checkNewPlans, setCheckNewPlans] = useState(false);
  const [acceptAgreeToReceiveOrderCommunication, setAcceptAgreeToReceiveOrderCommunication] = useState(false);
  const [acceptAgreeToReceiveOrderCommunicationError, setAcceptAgreeToReceiveOrderCommunicationError] = useState(false);
  const [agreeToPayRecurringCharges, setAgreeToPayRecurringCharges] = useState(false);
  const [agreeToPayRecurringChargesError, setAgreeToPayRecurringChargesError] = useState(false);
  const [agreeToPaySetupFee, setAgreeToPaySetupFee] = useState(false);
  const [agreeToPaySetupFeeError, setAgreeToPaySetupFeeError] = useState(false);
  const billingFormMethods = useForm({
    defaultValues: billingInfoInitialData,
    mode: 'all',
    resolver: yupResolver(billingInfoFormValidationSchema),
  });

  const {
    formState: { isSubmitted },
  } = billingFormMethods;
  const currentModel = billingFormMethods.getValues();

  const getAddressFieldsHandle = (addressInfo) => {
    const model = { ...currentModel, addressInfo: { ...currentModel.addressInfo, ...addressInfo } };
    billingFormMethods.reset(model, {
      keepIsSubmitted: true,
      keepDirty: true,
      keepDefaultValues: true,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeAddressInput = (address) => {
    billingFormMethods.setValue('address', address || '');
  };

  const stripeFormSubmit = useCallback(
    (values) => {
      // if (!acceptCheckBox) {
      //   setLoading(true);
      //   return handleErrorAccept(true);
      // }
      setLoading(true);
      if (cardError) {
        setLoading(false);
        return toast.error(cardError);
      }
      if (!stripe) {
        setLoading(false);
        return toast.error(SignUpBillingMessages.DOESNT_LOAD);
      }
      values['oneTimeSetupFeeCouponCode'] = couponValue || '';
      setLoading(true);
      const ids = JSON.parse(localStorage.getItem('currentInvitationService'));
      const info = JSON.parse(localStorage.getItem('accountResponse'));
      const inviter = JSON.parse(localStorage.getItem('approvedInvitationInfo'));
      const stripeAddress = {
        address_city: values?.addressInfo?.city || '',
        address_line1: values?.addressInfo?.address1 || '',
        address_line2: values?.addressInfo?.address2 || '',
        address_country: values?.addressInfo?.country || '',
        address_state: values?.addressInfo?.state || '',
        address_zip: values?.addressInfo?.zipCode || '',
      };
      stripe
        .createToken(element.getElement('cardNumber'), stripeAddress)
        .then((res) => {
          if (res.error) {
            return toast.error(res.error.message);
          }
          const {
            token: { id, created },
            token,
          } = res;

          if (token && id && created) {
            let data = {
              stripeToken: token.id,
              plan: selectedPlan,
              legalEntityId: ids?.legalEntityId,
              businessId: ids?.businessId,
              userId: info?.userId,
              inviterUserId: inviter?.userId,
              paymentTerm: periodicity,
              oneTimeSetupFeeCouponCode: isApply ? values.oneTimeSetupFeeCouponCode : '' || '',
              employeeNumber: isApply ? values.oneTimeSetupFeeCouponCode : '' || '',
              agreeToPaySetupFee: Number(setUpFeeEnabled) === 1 ? agreeToPaySetupFee : false,
              agreeToPayRecurringCharges: agreeToPayRecurringCharges,
              agreeToReceiveOrderCommunication: acceptAgreeToReceiveOrderCommunication,
              metadata: {
                addressPlaceId: currentModel.addressInfo?.placeId,
              },
            };
            return signupBillingAfterCallStripeV2(data)
              .then((response) => {
                removeItemAfterSignUp();
                setActive(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SUCCESS);
                toast.success(response?.data?.message);
              })
              .finally(() => setLoading(false));
          } else {
            return toast.error(SignUpBillingMessages.REQUIRE_CARD);
          }
        })
        .catch((err) => {
          return toast.error(err.message);
        })
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    },
    [
      stripe,
      cardError,
      // acceptCheckBox,
      selectedPlan,
      couponValue,
      agreeToPayRecurringCharges,
      acceptAgreeToReceiveOrderCommunication,
      agreeToPaySetupFee,
      isApply,
      checkNewPlans,
      periodicity,
      setUpFeeEnabled,
    ]
  );

  // const handleAcceptChange = ({ target }) => {
  //   handleAcceptCheckbox(target.checked);
  //   !target.checked ? handleErrorAccept(true) : handleErrorAccept(false);
  // };
  const isValidGoogleValue = useCallback((isValid) => {
    return setNextDisabled(isValid);
  }, []);

  const paymentDate = trialVersionDate();

  //newFunctions For accepting
  const handleAcceptAgreeToReceiveOrderCommunication = ({ target }) => {
    setAcceptAgreeToReceiveOrderCommunication(target.checked);
    !target.checked
      ? setAcceptAgreeToReceiveOrderCommunicationError(true)
      : setAcceptAgreeToReceiveOrderCommunicationError(false);
  };

  const handleAcceptAgreeToPayRecurringCharges = ({ target }) => {
    setAgreeToPayRecurringCharges(target.checked);
    !target.checked ? setAgreeToPayRecurringChargesError(true) : setAgreeToPayRecurringChargesError(false);
  };

  const handleAcceptSetupFree = ({ target }) => {
    setAgreeToPaySetupFee(target.checked);
    !target.checked ? setAgreeToPaySetupFeeError(true) : setAgreeToPaySetupFeeError(false);
  };

  return (
    <>
      <FormProvider {...billingFormMethods}>
        <form autoComplete="off" onSubmit={billingFormMethods.handleSubmit(stripeFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ mt: 2, mb: 2, fontSize: 20, fontWeight: 'bold' }}>
                {t('sign_up.choose_subscription_plan')}
              </Typography>
              <Box sx={{ minHeight: 400 }}>
                <SubscriptionPlans
                  required={true}
                  isSubmitted={isSubmitted}
                  getSelectedPlanPrice={getSelectedPlanPrice}
                  handleSelectedPlan={handleSelectedPlan}
                  selectedPlanPrice={selectedPlanPrice}
                  selectedPlan={selectedPlan}
                  setSelectedPlanData={setSelectedPlanData}
                  target="signup"
                  setCheckNewPlans={setCheckNewPlans}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ mt: 2, mb: 1, fontSize: 20, fontWeight: 'bold', color: '#000000' }}>
                {t('sign_up.payment_details')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CreditCardDetail handleCardError={handleCardError} />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ mt: 2, fontSize: 20, fontWeight: 'bold', color: '#000000' }}>
                {t('settings.billing_address')}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <GooglAutocomplete
                isValidGoogleValue={isValidGoogleValue}
                onChangeAddressInput={onChangeAddressInput}
                getAddressFields={getAddressFieldsHandle}
                name="addressInfo.address1"
                initialValue={currentModel?.addressInfo?.address1 || ''}
                inputValue={currentModel?.addressInfo?.address1 || ''}
                label={t('order_view.address')}
                placeholder=""
                required={true}
                formSubmitted={billingFormMethods.isSubmitted}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                defaultValue={currentModel.addressInfo?.address2 || ''}
                name="addressInfo.address2"
                label={t('order_view.address2')}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                defaultValue={currentModel.city || ''}
                name="addressInfo.city"
                label={t('settings.city')}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                defaultValue={currentModel.state || ''}
                name="addressInfo.state"
                label={t('settings.state')}
              />
              <CustomTextInput hidden name="addressInfo.country" />
            </Grid>
            <Grid item md={4} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                defaultValue={currentModel.zipCode || ''}
                name="addressInfo.zipCode"
                label={t('settings.zip_code')}
              />
            </Grid>
            {selectedPlan && selectedPlan !== 'null' && checkNewPlans ? (
              <Grid item xs={12}>
                <ApplyCoupon
                  couponValue={couponValue}
                  setCouponValue={setCouponValue}
                  setIsApplay={setIsApplay}
                  handleAcceptSetupFree={handleAcceptSetupFree}
                  acceptAgreeToPaySetupFeeCharges={agreeToPaySetupFee}
                  acceptAgreeToPaySetupFee={agreeToPaySetupFeeError}
                  checkNewPlans={checkNewPlans}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              <AcceptRequirements
                handleAcceptAgreeToReceiveOrderCommunication={handleAcceptAgreeToReceiveOrderCommunication}
                acceptAgreeToReceiveOrderCommunication={acceptAgreeToReceiveOrderCommunication}
                acceptAgreeToReceiveOrderCommunicationError={acceptAgreeToReceiveOrderCommunicationError}
                selectedPlan={selectedPlan}
                handleAcceptAgreeToPayRecurringCharges={handleAcceptAgreeToPayRecurringCharges}
                agreeToPayRecurringCharges={agreeToPayRecurringCharges}
                agreeToPayRecurringChargesError={agreeToPayRecurringChargesError}
                selectedPlanData={selectedPlanData}
                selectedPlanPrice={selectedPlanPrice}
                checkNewPlans={checkNewPlans}
              />
            </Grid>
            <Grid item xs={12}>
              {!setUpFeeEnabled ? (
                <AuthorizationButtons
                  steps={availableSteps}
                  setActive={setActive}
                  active={active}
                  loading={loading}
                  title={t('order_view.submit')}
                  disabled={
                    !billingFormMethods.formState.isValid ||
                    !selectedPlan ||
                    (checkNewPlans && (!acceptAgreeToReceiveOrderCommunication || !agreeToPayRecurringCharges)) ||
                    !acceptAgreeToReceiveOrderCommunication
                  }
                />
              ) : (
                <AuthorizationButtons
                  steps={availableSteps}
                  setActive={setActive}
                  active={active}
                  loading={loading}
                  title={t('order_view.submit')}
                  disabled={
                    !billingFormMethods.formState.isValid ||
                    !nextDisabled ||
                    !selectedPlan ||
                    (checkNewPlans &&
                      (!acceptAgreeToReceiveOrderCommunication ||
                        !agreeToPayRecurringCharges ||
                        !agreeToPaySetupFee)) ||
                    !acceptAgreeToReceiveOrderCommunication
                  }
                />
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default AuthorizationBilling;
