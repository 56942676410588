import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import Login from 'pages/Auth/Login';
import Signup from 'pages/Auth/Signup/index';
import UserSignup from 'pages/Auth/UserSignup';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import HubSpotChatPage from 'pages/HubSpotChatPage';
import { Box } from '@mui/material';
import RedirectionRoute from 'pages/Auth/RedirectionRoute';

function Auth() {
  const history = useHistory();

  useEffect(() => {
    document.querySelector('[name="viewport"]').content = 'initial-scale=1,shrink-to-fit=yes';
  }, []);

  useEffect(() => {
    document.body.style.background = history.location.pathname === '/login' ? 'white' : '#f6f7fb';
  }, [history.location.pathname]);

  if (history.location.pathname.includes('/help/chat')) {
    return (
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Switch>
          <Route path="/help/chat/:businessId" component={HubSpotChatPage} />
        </Switch>
      </Box>
    );
  }

  return (
    <ErrorBoundaryWrapper>
      <main className={history.location.pathname === '/login' ? 'auth-main' : 'app-main auth-main'}>
        <div className="auth-content">
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password/:token" component={ResetPassword} />
            <Route exact path="/complete-signup/:token" component={UserSignup} />
            <RedirectionRoute path="*" component={Login} to="/login" /> {/* Redirection Route */}

          </Switch>
        </div>
      </main>
    </ErrorBoundaryWrapper>
  );
}
export default Auth;
