import { useEffect, useState } from 'react';

const usePrepTimeModal = (watch, businessFormMethods, serverPrepTime) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const defaultPrepTimeWatcher = watch('defaultPrepTime');

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    businessFormMethods.setValue('defaultPrepTime', serverPrepTime, { keepDirty: false });
  };

  const handleConfirm = () => setOpenConfirmModal(false);

  useEffect(() => {
    const isNotEqual = businessFormMethods.getValues()?.defaultPrepTime !== serverPrepTime;
    const isDataAvailable = serverPrepTime && Object.keys(businessFormMethods.getValues()).length > 0;
    if (isNotEqual && isDataAvailable) {
      setOpenConfirmModal(true);
    }
  },[defaultPrepTimeWatcher])

  return {
    openConfirmModal,
    handleCloseConfirmModal,
    handleConfirm,
  }
}
export default usePrepTimeModal;