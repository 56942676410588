import { toast } from 'react-toastify';
import instance from 'services/api';
import { SET_ACTIVE_COUPONS, SET_EXPIRED_COUPONS, SET_COUPON_LOADING } from 'redux/actions/types';
import moment from 'moment';

// const defaultErrorMessage = 'Something went wrong. Please try again.';

const couponeModelConvert = (body) => {
  return {
    ...body,
    startDate: moment(body.startDate).format('YYYY-MM-DD'),
    endDate: moment(body.endDate).format('YYYY-MM-DD'),
  };
};

export const getCoupons = (body) => (dispatch) => {
  dispatch({ type: SET_COUPON_LOADING, payload: true });
  return instance
    .post(`/promotion/coupons-get`, body)
    .then((res) => {
      if (body.active) {
        dispatch({ type: SET_ACTIVE_COUPONS, payload: res.data.data });
      } else {
        dispatch({ type: SET_EXPIRED_COUPONS, payload: res.data.data });
      }
      return res.data;
    })
    .catch((err) => {
      // toast.error(defaultErrorMessage);
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({ type: SET_COUPON_LOADING, payload: false });
    });
};

export const addCoupon = (body) => (dispatch) => {
  dispatch({ type: SET_COUPON_LOADING, payload: true });
  return instance
    .post(`/promotion/coupon-create`, couponeModelConvert(body))
    .then((res) => {
      dispatch(getCoupons({ active: true }));
      dispatch(getCoupons({ active: false }));
      toast.success(res.message);
      return res.data;
    })
    .catch((err) => {
      // toast.error(defaultErrorMessage);
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({ type: SET_COUPON_LOADING, payload: false });
    });
};

export const updateCoupon = (body) => (dispatch) => {
  dispatch({ type: SET_COUPON_LOADING, payload: true });
  return instance
    .patch(`/promotion/coupon-update/${body.id}`, couponeModelConvert(body))
    .then((res) => {
      dispatch(getCoupons({ active: true }));
      dispatch(getCoupons({ active: false }));
      toast.success(res.message);
      return res.data;
    })
    .catch((err) => {
      // toast.error(defaultErrorMessage);
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({ type: SET_COUPON_LOADING, payload: false });
    });
};

export const deleteCoupon = (body) => (dispatch) => {
  dispatch({ type: SET_COUPON_LOADING, payload: true });
  return instance
    .delete(`/promotion/coupon-delete/${body.id}`)
    .then(() => {
      dispatch(getCoupons({ active: true }));
      dispatch(getCoupons({ active: false }));
    })
    .catch((err) => {
      // toast.error(defaultErrorMessage);
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({ type: SET_COUPON_LOADING, payload: false });
    });
};
