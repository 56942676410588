import { Typography, styled, Grid } from '@mui/material';

export const StyledLabelForWebsite = styled(Typography)(({ theme }) => ({
  color: '#0F1214',
  fontSize: '16px',
  fontWeight: '18.75px',
}));

export const MinOrderAmountWebsiteGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(4),
}));

export const MinOrderDividerBoxGrid = styled(Grid)(({ theme }) => ({
  width: '336px',
  paddingTop: theme.spacing(3),
}));
