import { styled, Typography, Box, RadioGroup } from '@mui/material';

export const TypeTitle = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 500,
  color: 'rgba(15, 18, 20, 1)',
  marginBottom: theme.spacing(2),
}));

export const TypeTitleSecond = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
  color: 'rgba(15, 18, 20, 1)',
  marginBottom: theme.spacing(2),
}));

export const FullModeWrapper = styled(Box)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: '6px',
}));

export const FullModeInnerWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));

export const PaymentStyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  '& label > span': {
    display: 'flex',
    alignItems: 'center',
    // padding: 0,
  },
  '& label': {
    margin: 0,
  },
}));
