import { useEffect, useState } from 'react';
import instance from 'services/api';
import { LoadingButton } from '@mui/lab';
import Switch from '@mui/material/Switch';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import { priceTransform } from 'helpers/formPrice';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Grid, MenuItem, Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { businessSettingsSchema } from './businessSettingsSchema';
import { BusinessSettingsTitle, BusinessSettingsSubTitle } from './styles';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';

const googleFeedCategory = [{ id: 'restaurant', name: 'Restaurant' }];

const defaultBusinessSettings = {
  googleFeedEnabled: false,
  googleFeedCategory: 'restaurant',
  googleFeedDeliveryFee: null,
  googleFeedDeliveryFeeRangeStart: null,
  googleFeedDeliveryFeeRangeEnd: null,
  googleFeedServiceFee: null,
};
const BusinessSettings = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [businessLink, setBusinessLink] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [lastSelected] = useState(JSON.parse(localStorage.getItem('businessInfo')));
  const [currentBusinessSettings, setCurrentBusinessSettings] = useState({});
  const { currentBusiness, isBrandLoading } = useSelector(({ business }) => business);
  const businessSettings = useForm({
    defaultValues: defaultBusinessSettings,
    mode: 'all',
    resolver: yupResolver(businessSettingsSchema),
  });

  const currentBusinessModelConverter = (model) => {
    return {
      googleFeedEnabled: model?.googleFeedEnabled || false,
      googleFeedCategory: model?.googleFeedCategory || 'restaurant',
      googleFeedDeliveryFee:
        model?.googleFeedDeliveryFee >= 0 && model?.googleFeedDeliveryFee !== null
          ? Number(model?.googleFeedDeliveryFee)
          : null,
      googleFeedDeliveryFeeRangeStart:
        model?.googleFeedDeliveryFeeRangeStart >= 0 && model?.googleFeedDeliveryFeeRangeStart !== null
          ? Number(model?.googleFeedDeliveryFeeRangeStart)
          : null,
      googleFeedDeliveryFeeRangeEnd:
        model?.googleFeedDeliveryFeeRangeEnd >= 0 && model?.googleFeedDeliveryFeeRangeEnd !== null
          ? Number(model?.googleFeedDeliveryFeeRangeEnd)
          : null,
      googleFeedServiceFee:
        model?.googleFeedServiceFee >= 0 && model?.googleFeedServiceFee !== null
          ? Number(model?.googleFeedServiceFee)
          : null,
    };
  };

  const handleChangeBusinessLink = (event) => {
    setBusinessLink(event.target.checked);
    businessSettings.setValue('googleFeedEnabled', event.target.checked, { shouldDirty: true });
    setDisabledButton(false);
    setInputDisabled(!event.target.checked);
    setIsSubmit(false);
  };

  function updateZeroValueProperties(obj) {
    let modifiedObj = { ...obj };

    for (let key in modifiedObj) {
      if (Number(modifiedObj[key]) === 0 && modifiedObj[key] !== null && !modifiedObj[key]) {
        modifiedObj[key] = Number(modifiedObj[key]).toFixed(2);
      }
    }

    return modifiedObj;
  }

  useEffect(() => {
    if (currentBusiness) {
      let currentBusinessModel = currentBusinessModelConverter(currentBusiness);
      if (
        !currentBusiness.hasOwnProperty('googleFeedEnabled') &&
        !currentBusiness.hasOwnProperty('googleFeedCategory') &&
        !currentBusiness.hasOwnProperty('googleFeedDeliveryFee') &&
        !currentBusiness.hasOwnProperty('googleFeedDeliveryFeeRangeStart') &&
        !currentBusiness.hasOwnProperty('googleFeedDeliveryFeeRangeEnd') &&
        !currentBusiness.hasOwnProperty('googleFeedServiceFee')
      ) {
        setInputDisabled(true);
        setBusinessLink(currentBusiness?.googleFeedEnabled);
        businessSettings.reset(defaultBusinessSettings, {
          keepDefaultValues: false,
          isDirty: false,
        });
        setCurrentBusinessSettings(currentBusinessModel);
      } else {
        let modifiedModel = updateZeroValueProperties(currentBusinessModel);
        modifiedModel['googleFeedEnabled'] = currentBusinessModel?.googleFeedEnabled;
        businessSettings.reset(modifiedModel, {
          keepDefaultValues: false,
          isDirty: false,
        });
        setBusinessLink(currentBusiness?.googleFeedEnabled);
        setInputDisabled(!currentBusiness?.googleFeedEnabled);
        setCurrentBusinessSettings(modifiedModel);
      }
    }
  }, [currentBusiness]);

  const handleBusinessSettingsSubmit = (values) => {
    setIsSubmit(true);
    setLoading(true);
    let currentModel = businessSettings.getValues();
    businessSettings.reset(currentModel, {
      keepDefaultValues: false,
      isDirty: false,
    });

    let data = currentBusinessModelConverter(values);
    return instance
      .patch(`/business/google-feed/${lastSelected?.id}`, data)
      .then((response) => {
        let data = response?.data?.data;
        setBusinessLink(data?.googleFeedEnabled);
        setCurrentBusinessSettings({
          googleFeedEnabled: data?.googleFeedEnabled,
          googleFeedCategory: data?.googleFeedCategory,
          googleFeedDeliveryFee: Number(data?.googleFeedDeliveryFee),
          googleFeedDeliveryFeeRangeStart: Number(data?.googleFeedDeliveryFeeRangeStart),
          googleFeedDeliveryFeeRangeEnd: Number(data?.googleFeedDeliveryFeeRangeEnd),
          googleFeedServiceFee: Number(data?.googleFeedServiceFee),
        });
        setLoading(false);
        return response?.data;
      })
      .catch(() => setLoading(false));
  };

  const handelCancel = () => {
    setBusinessLink(currentBusinessSettings?.googleFeedEnabled);
    if (!currentBusinessSettings?.googleFeedEnabled) {
      setInputDisabled(true);
    } else {
      setInputDisabled(false);
    }
    let data = currentBusinessModelConverter(currentBusinessSettings);
    let modifiedModel = updateZeroValueProperties(data);
    businessSettings.reset(modifiedModel);
    setDisabledButton(true);
  };

  const handleChange = (event) => {
    const value = priceTransform(event.target.value);
    businessSettings.setValue(event.target.name, value, { shouldDirty: true });
    setDisabledButton(false);
    setIsSubmit(false);
  };

  return (
    <>
      {isBrandLoading && <Preloader />}
      {!isBrandLoading && (
        <FormProvider {...businessSettings}>
          <ConfirmPromptModal
            handleConfirm={businessSettings.handleSubmit(handleBusinessSettingsSubmit)}
            submitData={businessSettings.getValues()}
            hasUnsavedChanges={businessSettings.formState.isDirty}
            formState={businessSettings}
          />
          <form onSubmit={businessSettings.handleSubmit(handleBusinessSettingsSubmit)}>
            <Grid container>
              <Grid item xs={12}>
                <BusinessSettingsTitle>{t('settings.google_business_settings')} </BusinessSettingsTitle>
                <BusinessSettingsSubTitle>{t('settings.google_business_link')}</BusinessSettingsSubTitle>
              </Grid>
              <Grid item xs={12}>
                <FormGroup checked={businessLink} onChange={handleChangeBusinessLink} name={`googleFeedEnabled`}>
                  <FormControlLabel
                    control={<Switch checked={businessLink} />}
                    label={t('settings.enable_google_business_link')}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <CustomTextInput
                  sx={{ width: '100%', mt: 2, maxWidth: '50%', opacity: inputDisabled ? 0.5 : 1 }}
                  select={true}
                  name="googleFeedCategory"
                  label={t('settings.select_google_feed_category')}
                  disabled={inputDisabled}
                >
                  {googleFeedCategory.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </CustomTextInput>
              </Grid>
              <Grid item xs={12}>
                <CustomTextInput
                  sx={{ width: '100%', mt: 2, maxWidth: '50%', opacity: inputDisabled ? 0.5 : 1 }}
                  size="small"
                  name="googleFeedDeliveryFee"
                  label={t('settings.google_feed_delivery_fee')}
                  disabled={inputDisabled}
                  onChange={handleChange}
                  inputProps={{ type: 'number' }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextInput
                  sx={{ width: '100%', mt: 2, maxWidth: '50%', opacity: inputDisabled ? 0.5 : 1 }}
                  size="small"
                  name="googleFeedServiceFee"
                  label={t('settings.google_feed_service_fee')}
                  disabled={inputDisabled}
                  onChange={handleChange}
                  inputProps={{ type: 'number' }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextInput
                  sx={{ width: '100%', mt: 2, maxWidth: '50%', opacity: inputDisabled ? 0.5 : 1 }}
                  size="small"
                  name="googleFeedDeliveryFeeRangeStart"
                  label={t('settings.google_feed_delivery_start')}
                  disabled={inputDisabled}
                  onChange={handleChange}
                  inputProps={{ type: 'number' }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextInput
                  sx={{ width: '100%', mt: 2, maxWidth: '50%', opacity: inputDisabled ? 0.5 : 1 }}
                  size="small"
                  name="googleFeedDeliveryFeeRangeEnd"
                  label={t('settings.google_feed_delivery_end')}
                  disabled={inputDisabled}
                  onChange={handleChange}
                  inputProps={{ type: 'number' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: '100%', maxWidth: '50%', display: 'flex', justifyContent: 'end' }}>
                  <Button
                    id="businessSettingsCancel"
                    disabled={disabledButton || isSubmit || loading}
                    variant="outlined"
                    color="primary"
                    sx={{ width: '200px', mt: 2, alignItems: 'right', p: 1 }}
                    onClick={handelCancel}
                  >
                    {t('orders.cancel')}
                  </Button>
                  <LoadingButton
                    id="businessSettingsLoading"
                    disabled={disabledButton || isSubmit || loading}
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ width: '200px', mt: 2, alignItems: 'right', ml: 2, p: 1 }}
                    loading={loading}
                  >
                    {t('orders.save')}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
    </>
  );
};
export default BusinessSettings;
