import { memo, useCallback, useMemo } from 'react';
import { Table, CustomInput } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, CardActions, Typography } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '@mui/material/Button';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import OfficeHours from './Hours/OfficeHours';
import { DAYS } from 'constants/hours';
import { useTranslation } from 'react-i18next';
// import { t } from 'i18next';
import i18n from 'i18n';

const SpecialHours = ({
  onChange,
  data,
  isSubmitted,
  setHolidaySpecialHoursError = () => {},
  timezone,
  businessModel,
}) => {
  const handleAdd = () => {
    onChange((prev) => [...prev, { date: '', opened: true, hours: [{ start: '09:00', end: '11:59' }] }]);
    businessModel.setValue(
      'specialDates',
      [...data, { date: '', opened: true, hours: [{ start: '09:00', end: '11:59' }] }],
      { shouldDirty: true }
    );
  };
  const { t } = useTranslation();

  const handleDelete = (i) => {
    setHolidaySpecialHoursError(false);
    onChange((prev) => {
      const newArr = [...prev];
      newArr.splice(i, 1);
      return newArr;
    });
    data.splice(i, 1);
    businessModel.setValue('specialDates', [...data], { shouldDirty: true });
  };

  const handleChange = (index) => (date) => {
    onChange((prev) => prev.map((d, i) => (i === index ? date : d)));
    businessModel.setValue(
      'specialDates',
      data.map((d, i) => (i === index ? date : d)),
      { shouldDirty: true }
    );
  };

  return (
    <div className="mt-4">
      <h5>{t('settings.special_hours')}</h5>
      <Card className="holiday-hours">
        <CardContent className="p-0">
          <Table className="hours-table holiday-hours">
            <tbody>
              {data.map((item, index) => {
                if (!item.date.length) {
                  setHolidaySpecialHoursError(true);
                }
                return (
                  <FormRow
                    key={index}
                    item={item}
                    isSubmitted={isSubmitted}
                    index={index}
                    setHolidaySpecialHoursError={setHolidaySpecialHoursError}
                    onChange={handleChange(index)}
                    onDelete={() => handleDelete(index)}
                    timezone={timezone}
                  />
                );
              })}
            </tbody>
          </Table>
        </CardContent>
        <CardActions className="d-flex">
          <Button id="specialDatesAddDate" variant="outlined" color="success" onClick={handleAdd}>
            {t('settings.add_date')}
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

const FormRow = ({
  onChange,
  onDelete,
  item,
  index,
  timezone,
  isSubmitted,
  setHolidaySpecialHoursError = () => {},
}) => {
  const { t } = useTranslation();
  const { currentBusiness } = useSelector(({ business }) => business);

  const onDateChange = (value) => {
    if (!value) {
      setHolidaySpecialHoursError(true);
    } else {
      setHolidaySpecialHoursError(false);
    }
    onChange({ ...item, date: value.toLocaleDateString() });
  };

  const toggleDay = (opened) => {
    if (!opened) {
      onChange({ ...item, hours: [{ start: '00:00', end: '23:59' }], opened });
    } else {
      onChange({ ...item, hours: [{ start: '09:00', end: '23:59' }], opened });
    }
  };

  function onChangeStart(day, index, value) {
    const time = moment(value).format('HH:mm');
    const obj = { ...item, hours: item.hours.map((hr, i) => (i === index ? { ...hr, start: time } : hr)) };
    onChange(obj);
  }

  function onChangeEnd(day, index, value) {
    const time = moment(value).format('HH:mm');
    const obj = { ...item, hours: item.hours.map((hr, i) => (i === index ? { ...hr, end: time } : hr)) };
    onChange(obj);
  }

  const onAddHour = () => {
    onChange({ ...item, hours: [...item.hours, { start: '13:00', end: '21:00' }] });
  };

  const onRemoveHour = (day, i) => {
    const newArr = [...item.hours];
    newArr.splice(i, 1);
    onChange({
      ...item,
      hours: newArr,
    });
  };

  // function parseDate(str, format, locale) {
  //   const parsed = dateFnsParse(str, format, new Date(), { locale });
  //   if (DateUtils.isDate(parsed)) {
  //     return parsed;
  //   }
  //   return undefined;
  // }
  //
  // function formatDate(date, format, locale) {
  //   return dateFnsFormat(date, format, { locale });
  // }
  const FORMAT = 'MM/dd/yyyy';

  const handleOpenFullDay = useCallback(
    (value, day) => {
      Object.keys(DAYS).forEach((dayItem, indexDay) => {
        if (day === dayItem && value) {
          if (item.hours.length > 1) {
            item.hours[dayItem].splice(1, 1);
          }
          item.hours[0].start = '00:00';
          item.hours[0].end = '23:59';
        }
      });
      onChange(item);
    },
    [item]
  );

  const diffByHoursDuration = (start, end) => {
    const timeByMomentDate = moment(start, 'HH:mm:ss a');
    const endime = moment(end, 'HH:mm:ss a');

    const startIsBefore = timeByMomentDate.isBefore(endime);
    return startIsBefore;
  };
  const nextDayLabel = useMemo(() => {
    const { hours } = item;

    const labels = {};
    hours.forEach((hourItem, index) => {
      if (item.date) {
        const dayIndex = Object.keys(DAYS).findIndex(
          (dayItem) => dayItem === moment(item.date).format('ddd').toLocaleLowerCase()
        );
        const nextDay = Object.keys(DAYS)[dayIndex + 1] || Object.keys(DAYS)[0];
        const nextDayCapitalize = nextDay.charAt(0).toUpperCase() + nextDay.slice(1);
        const hourStartByIndex = hourItem.start;
        const hourEndByIndex = hourItem.end;
        const durationByHours = diffByHoursDuration(hourStartByIndex, hourEndByIndex);
        if (!durationByHours && nextDay) {
          labels[`label${index}`] = `(${nextDayCapitalize})`;
        }
      }
    });

    return labels;
  }, [item, i18n.language]);

  const memoNewDateByTimeZoneId = useMemo(() => {
    if (currentBusiness && timezone) {
      let now = new Date().toLocaleString('en-US', {
        timeZone: timezone,
      });
      return new Date(moment(now).tz(timezone));
    }
  }, [currentBusiness, timezone]);

  const selectedDayDefault = useMemo(() => {
    if (currentBusiness && timezone) {
      let now = new Date().toLocaleString('en-US', {
        timeZone: timezone,
      });
      return new Date(moment(now).tz(timezone));
    }
  }, [currentBusiness, timezone]);

  const day = moment(item.data).format('ddd');
  return (
    <tr>
      <td className="days-col w-85">
        <div className="">
          <Box flexDirection="column">
            <DatePicker
              selected={item.date ? new Date(item.date) : selectedDayDefault}
              onChange={(date) => onDateChange(date)}
              dateFormat={FORMAT}
              value={item.date ? new Date(item.date) : ''}
              minDate={memoNewDateByTimeZoneId}
              placeholderText={'MM/DD/YYYY'}
            />
            {!item.date?.length && isSubmitted && (
              <Typography sx={{ fontSize: '13px' }} color="error">
                {t('help.field_is_required')}
              </Typography>
            )}
          </Box>
        </div>
      </td>
      <td className="days-col">
        <div className="d-flex align-items-center justify-content-between text-muted">
          <div className="mr-2">
            <small className="font-weight-bold">{item.opened ? t('settings.open') : t('orders.closed')}</small>
          </div>
          <CustomInput
            className="date-holiday"
            type="switch"
            id={'day_' + index}
            checked={item.opened}
            onChange={({ target: { checked } }) => toggleDay(checked)}
          />
        </div>
      </td>
      <td className="hours-col">
        <div className="day-hours">
          <Box key={index} sx={{ width: '300px', justifyContent: 'space-between', display: 'flex' }}>
            <OfficeHours
              day={day.toLocaleLowerCase()}
              onChangeStart={onChangeStart}
              onChangeEnd={onChangeEnd}
              removeTime={onRemoveHour}
              // onClick={() => onRemoveHour(index)}
              handleOpenFullDay={handleOpenFullDay}
              times={item.hours}
              checked={item.opened}
              fullDayChecked={item.hours.length < 2 && item.hours[0]?.start == '00:00' && item.hours[0]?.end == '23:59'}
              nextDayLabel={nextDayLabel}
            />
            {/* <IconButton size="sm" disabled={item.hours.length === 1} onClick={() => onRemoveHour(index)}>
                  <i className="icon icon-trash-2"></i>
                </IconButton>

                <Box flexDirection="column">
                  <TimePicker
                    use12Hours
                    style={{ width: '120px' }}
                    showSecond={false}
                    defaultValue={defaultValueStart}
                    onChange={(value) => onChangeStart(index, value)}
                    minuteStep={5}
                    onAmPmChange={() => {}}
                    inputReadOnly
                  />
                </Box>

                <Box flexDirection="column">
                  <TimePicker
                    use12Hours
                    style={{ width: '120px' }}
                    showSecond={false}
                    defaultValue={defaultValueEnd}
                    onAmPmChange={() => {}}
                    onChange={(value) => onChangeEnd(index, value)}
                    minuteStep={5}
                    inputReadOnly
                  />
                </Box> */}
          </Box>
        </div>
      </td>
      <td className="action-col">
        <Button
          id="specialDatesDate"
          variant="contained"
          size="sm"
          color="primary"
          onClick={onAddHour}
          disabled={item.hours.length >= 2}
        >
          <i className="icon icon-plus"></i> {t('settings.add')}
        </Button>
      </td>
      <td className="action-col">
        <Button id="specialDatesDelete" variant="contained" size="sm" color="primary" onClick={onDelete}>
          <i className="icon icon-trash-2"></i>
        </Button>
      </td>
    </tr>
  );
};
export default memo(SpecialHours);
