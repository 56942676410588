import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { getProvidersSelector } from 'redux/selectors';
import { updateProviderConfirmOrder } from 'redux/actions/providers';
import { PROVIDER_AUTO_CONFIRM_ORDER, PROVIDER_NOT_CONFIRM_ORDER } from 'constants/providerStatuses';

const autoConfirmDeliverySchema = yup.object().shape({
  orderConfirm: yup.object().shape({
    delivery: yup.object().shape({
      autoConfirm: yup.boolean(),
    }),
  }),
});

const autoConfirmDeliveryDefaultValue = {
  orderConfirm: {
    delivery: {
      autoConfirm: false,
    },
  },
};

const useDeliveryAutoConfirm = (items, srv) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { providerId } = useSelector(getProvidersSelector);

  const autoConfirmDelivery = useForm({
    defaultValues: autoConfirmDeliveryDefaultValue,
    mode: 'all',
    resolver: yupResolver(autoConfirmDeliverySchema),
  });

  useEffect(() => {
    const findProviderConfirm = items.find((item) => item?.srv === srv);
    if (findProviderConfirm) {
      if (findProviderConfirm?.orderConfirm?.delivery) {
        autoConfirmDelivery.reset({
          orderConfirm: {
            delivery: {
              autoConfirm: findProviderConfirm?.orderConfirm?.delivery?.autoConfirm,
            },
          },
        });
      }
    }
  }, [items, srv]);

  const handleSubmit = () => {};

  const handleConfirm = useCallback((event) => {
    const { checked } = event.target;
    setLoading(true);
    dispatch(
      updateProviderConfirmOrder(
        {
          id: providerId,
          confirmOrder: checked ? PROVIDER_AUTO_CONFIRM_ORDER : PROVIDER_NOT_CONFIRM_ORDER,
          provider: srv,
          orderConfirm: {
            delivery: {
              autoConfirm: checked,
            },
          },
        },
        false
      )
    )
      .then(() => {})
      .finally(() => setLoading(false));
  }, []);

  return {
    autoConfirmDelivery,
    handleSubmit,
    handleConfirm,
    loading,
  };
};

export default useDeliveryAutoConfirm;
