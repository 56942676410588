import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Paper, Typography, Chip, Button } from '@mui/material';
import { getEmployers, deleteEmployer } from 'redux/actions/user';
import userStatuses from 'constants/userStatuses';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import InviteUsers from 'components/Settings/InviteUsers';
import Preloader from 'components/Preloader';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import instance from 'services/api';
import { useTranslation } from 'react-i18next';
import { confirm } from 'components/MenuNew/ConfirmModal';

const UsersRoles = () => {
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data } = useSelector(({ user }) => user);
  const [opts, setOpts] = useState([]);
  const userData = data;
  const handleGetUsers = useCallback(() => {
    getEmployers()
      .then((data) => {
        const withIdUsers = data.map((item) => ({
          ...item,
          id: item._id,
        }));
        setUsers(withIdUsers);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    handleGetUsers();
  }, [handleGetUsers]);

  useEffect(() => {
    instance.get('/users/roles').then(({ data: { data } }) => {
      setOpts(data);
    });
  }, []);

  const handleDelete = async (user) => {
    const confirmed = await confirm({
      title: t('providers_settings.are_you_sure'),
      message: `${t('settings.do_you_want_remove')}${``} ${user.firstName || user.email} ${t(
        'settings.from_your_employers'
      )}`,
      cancelText: t('orders.cancel'),
      confirmText: t('general.yes'),
      confirmColor: 'primary',
    });
    if (confirmed) {
      deleteEmployer(user._id).then(() => setUsers((prev) => prev.filter((i) => i._id !== user.id)));
    }
  };

  const columns = useMemo(() => [
    {
      field: 'roles',
      headerName: t('settings.roles'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: ({ value }) => {
        return <Typography sx={{ fontWeight: 'bold' }}>{value.name || ''}</Typography>;
      },
    },
    {
      field: 'email',
      headerName: t('Email'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: false,
    },
    {
      field: 'firstName',
      headerName: t('Name'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const { row } = params;
        const { firstName, lastName } = row;
        return `${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
      },
    },

    {
      field: 'phone',
      headerName: t('Phone'),
      sortable: false,
      filterable: false,
      flex: 1,
      editable: false,
    },
    {
      field: 'status',
      headerName: t('settings.status'),
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params) => {
        const user = params.row;
        return <Chip size="small" label={userStatuses[user.status].label} color={userStatuses[user.status].color} />;
      },
    },

    {
      field: 'actions',
      headerName: t('settings.tools'),
      type: 'actions',
      flex: 1,
      getActions: (params) => {
        const findIndex = opts?.findIndex((item) => item.id === data?.role);
        const disabledDelRole = opts
          ?.map((role, indexRole) => {
            if (indexRole < findIndex && role.label === params.row.roles.name) {
              return params.row.id;
            }
          })
          .filter((rowId) => rowId);
        const disabled = disabledDelRole.some((rowId) => rowId === params.row.id) || params.row.email === data.email;
        return [
          <Box sx={{ cursor: 'pointer' }} disabled={disabled}>
            <Button id="usersRolesDelete" disabled={disabled} onClick={() => handleDelete(params.row)}>
              <DeleteIcon color="link" />
            </Button>
          </Box>,
        ];
      },
    },
  ]);
  return (
    <ErrorBoundaryWrapper>
      <div className="website-setting">
        <Box justifyContent="space-between" display="flex" alignItems="center">
          <Typography variant="h5" sx={{ my: 2 }}>
            <LabelTooltip
              id="usersRoles"
              value={t('settings.user_role_settings')}
              tooltip="Create employee accounts with manager and user roles."
            />
          </Typography>
          <InviteUsers onSuccess={handleGetUsers} opts={opts} />
        </Box>
        <Paper>
          <Box
            sx={{
              my: 2,
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: 'white',
              },
              '& .MuiDataGrid-row	': {
                '&:nth-of-type(odd)': {
                  backgroundColor: (theme) => theme.palette.action.hover,
                },
              },
              '& .MuiDataGrid-sortIcon': {
                color: 'white',
              },
              '& .MuiDataGrid-filterIcon': {
                color: 'white',
              },
              '& .MuiDataGrid-menuIcon	': {
                '& .MuiButtonBase-root': { color: 'white' },
              },
            }}
          >
            <DataGrid
              autoHeight={true}
              hideFooterPagination={true}
              hideFooterSelectedRowCount={true}
              isEditable={false}
              rows={users}
              columns={columns}
              loading={loading}
              components={{
                LoadingOverlay: Preloader,
              }}
            />
          </Box>
        </Paper>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default UsersRoles;
