import {
  USER_LOADING,
  USER_LOADED,
  USER_LOGOUT,
  IS_LOGIN_USER,
  GET_ALL_BUSINESS_LIST,
  SUBSCRIPTION_PLANS,
  CURRENT_SUBSCRIPTION_PLAN,
  MODIFIED_LOG_OUT,
} from 'constants/user';
const initialUser = () => {
  const token = window.localStorage.getItem('accessToken');
  return { authorized: !!token };
};

const initialState = {
  data: null,
  loading: true,
  authorized: false,
  ...initialUser(),
  isLogin: false,
  allBusinessList: [],
  subscriptionPlans: [],
  currentSubscriptionPlan: null,
  modifiedLogOut: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BUSINESS_LIST:
      return { ...state, allBusinessList: action.payload };
    case USER_LOADING:
      return { ...state, loading: action.payload };
    case USER_LOADED:
      return {
        ...state,
        ...action.payload,
      };
    case USER_LOGOUT:
      return { ...state, authorized: false };
    case IS_LOGIN_USER:
      return { ...state, isLogin: action.payload };
    case SUBSCRIPTION_PLANS:
      return {
        ...state,
        subscriptionPlans: action.payload,
      };
    case CURRENT_SUBSCRIPTION_PLAN:
      return {
        ...state,
        currentSubscriptionPlan: action.payload,
      };
    case MODIFIED_LOG_OUT:
      return {
        ...state,
        modifiedLogOut: action.payload,
      };
    default:
      return state;
  }
};
