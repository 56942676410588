import { useTranslation } from 'react-i18next';
import callImage from 'assets/icons/phone-login.svg';
import PackageVersion from 'components/PackageVersion/PackageVersion';
import { CopyRight, FooterBox, FooterCopyWrite, FooterPhoneBox } from 'pages/Auth/Signup/styles';
const LoginContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex justify-content-center">
        <FooterBox>
          <CopyRight>
            <div className="content-img">
              <img src={callImage} alt="PHONE-LOGIN" />
            </div>
            <FooterPhoneBox href={'tel:855-500-1070'}>855-500-1070</FooterPhoneBox>
          </CopyRight>
          <FooterCopyWrite>
            {`${t('sign_in.copy_write')},`} <PackageVersion />
          </FooterCopyWrite>
        </FooterBox>
      </div>
    </>
  );
};

export default LoginContent;
