import { tutorial } from 'constants/tutorial';
import { Typography, CardHeader, Card, Grid, CardContent } from '@mui/material';

const Tutorials = () => {
  localStorage.removeItem('redirectUrl');
  return (
    <Card>
      <CardContent>
        <Grid container columns={12} spacing={2}>
          {tutorial.videos.map((item) => (
            <Grid item lg="4" md="6" className="tutorial-item" key={item.id}>
              <Card>
                <CardContent>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      className="embed-responsive-item"
                      src={item.link}
                      allowFullScreen
                      title={item.title}
                    ></iframe>
                  </div>
                  <CardHeader tag="h6" className="mb-2 text-muted mt-3">
                    {item.title}
                  </CardHeader>
                  <Typography>{item.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Tutorials;
