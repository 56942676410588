import { useState, memo, useCallback, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import CustomTextInput from 'components/Forms/CustomTextInput';
import Popover from '@mui/material/Popover';
import ListItemText from '@mui/material/ListItemText';
import { Typography, List, ListItem, Button, Grid } from '@mui/material';
import { CONTEXT_TYPES } from 'constants/menu';
import Icon from '@mui/material/Icon';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { APP_BUNDLE } from 'constants/permissions';
import { useTranslation } from 'react-i18next';
import useProviderConfig from 'hooks/useProviderConfig';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';

const PriceOverridePopover = ({
  priceOverride,
  itemIndex,
  connectedProviders,
  target,
  providerIndex,
  handleReset = () => {},
  handleClean = () => {},
  onChangePrice,
  errors = {},
  isSubmitted,
  productFormMethods,
  isEdit = false,
  formProduct = null,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { providers } = useProviderConfig();
  const [disabledPriceField, handleDisablePriceField] = useState({});
  const { changeBasePriceData } = useSelector(({ menu }) => menu);
  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const billingPlan = useSelector(({ business }) => business);
  const currentPlan = billingPlan?.currentBusiness?.plan?.value;

  const showTheWebsite = Number(currentPlan) !== Number(APP_BUNDLE);
  const filteredPrice = useMemo(() => {
    return priceOverride?.filter(
      (item) => item.context_type === CONTEXT_TYPES?.provider && connectedProviders?.[item.context_value]
    );
  }, [priceOverride, connectedProviders]);

  const initialSetLocked = useCallback(() => {
    filteredPrice?.forEach((el) => {
      if (el.context_value !== 'main') {
        Object.assign(disabledPriceField, { [el.context_value]: el.hasOwnProperty('locked') ? el.locked : true });
        handleDisablePriceField(disabledPriceField);
      }
    });
  }, [filteredPrice]);

  useEffect(() => {
    initialSetLocked();
  }, [priceOverride]);

  useEffect(() => {
    if (changeBasePriceData.isConfirm) {
      initialSetLocked();
    }
  }, [priceOverride, changeBasePriceData.isConfirm]);

  useEffect(() => {
    if (isSubmitted) {
      handleDisablePriceField({});
    }
  }, [isSubmitted]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const getIndex = useCallback(
    (context_value) => {
      const index =
        priceOverride?.length &&
        priceOverride?.findIndex((item) => {
          return item.context_value === context_value;
        });
      return index;
    },
    [priceOverride]
  );

  const haveConnected = useMemo(() => {
    if (connectedProviders) {
      return Object.values(connectedProviders).filter((item) => item).length > 1;
    }
    return false;
  }, [connectedProviders, priceOverride]);

  const handleSave = () => {
    setAnchorEl(null);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleToReset = () => {
    handleClosePopover();
    handleReset();
  };
  const handleToClean = () => {
    handleClosePopover();
    handleClean();
    initialSetLocked();
    productFormMethods.clearErrors();
  };

  const handleEnableChangePrice = (data, lock, item, index) => {
    handleDisablePriceField({ ...disabledPriceField, [data]: !disabledPriceField[data] });
    if (target === 'productFormPricePopover') {
      productFormMethods.setValue(`priceOverride[${getIndex(item.context_value)}].locked`, !disabledPriceField[data], {
        shouldDirty: true,
      });
    }

    if (target === 'productFormSizeTypePricePopover') {
      if (itemIndex || itemIndex === 0) {
        productFormMethods.setValue(
          `sizeTypeModifiers[0].list[${itemIndex}].priceOverride[${getIndex(item.context_value)}].locked`,
          !disabledPriceField[data],
          { shouldDirty: true }
        );
      }
    }

    if (target === 'modGroupPricePopover') {
      if ((itemIndex || itemIndex === 0) && (index || index === 0)) {
        productFormMethods.setValue(`list[${itemIndex}].priceOverride[${index}].locked`, !disabledPriceField[data], {
          shouldDirty: true,
        });
      }
    }

    if (target === 'modGroupSizeTypePricePopover') {
      if ((itemIndex || itemIndex === 0) && (index || index === 0) && (providerIndex || providerIndex === 0)) {
        productFormMethods.setValue(
          `list[${itemIndex}].priceOverride[${providerIndex}].priceOverride[${index}].locked`,
          !disabledPriceField[data],
          { shouldDirty: true }
        );
      }
    }
  };

  const findIndex = priceOverride?.findIndex((item) => item.context_value === providers.ubereats.srv);
  const checkError = Object.keys(errors).length;
  const sizeTypeModifiersErr = errors?.sizeTypeModifiers?.[0]?.list[itemIndex]?.priceOverride;
  const productFormPriceErr = target === 'productFormPricePopover' && errors?.priceOverride?.[findIndex];
  const errorColorForUber = checkError && (sizeTypeModifiersErr || productFormPriceErr) ? '#d32f2f' : '#3D4350';

  const showLockIcon = target === 'productFormPricePopover' || target === 'productFormSizeTypePricePopover';

  return (
    <Box>
      <Box
        onClick={handleClickPopover}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '22px',
          ml: 1,
          borderRadius: '100px',
          cursor: 'pointer',
          justifyContent: 'center',
        }}
      >
        <Icon
          sx={{
            color: errorColorForUber,
            fontSize: '14px',
          }}
          component={SettingsOutlinedIcon}
        />
      </Box>

      <Popover
        id={'simple-popover'}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper>
          <List>
            <ListItem
              key={uuidv4()}
              sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                pb: 2,
                px: 3,
              }}
            >
              <Typography sx={{ color: 'rgba(15, 18, 20, 1)', fontSize: '14px', fontWeight: 500 }}>
                {t('menu.providers')}
              </Typography>
            </ListItem>

            {haveConnected && isEdit ? (
              filteredPrice?.map((item, index) => {
                if (item.context_value === 'website' && !showTheWebsite && billingPlan.currentBusiness?.billingInfo) {
                  return;
                }
                let name = '';
                let namePickup = '';
                if (target === 'productFormPricePopover') {
                  name = `priceOverride[${getIndex(item.context_value)}].price`;
                  namePickup = `priceOverride[${getIndex(item.context_value)}].pickupPrice`;
                }

                if (target === 'productFormSizeTypePricePopover') {
                  if (itemIndex || itemIndex === 0) {
                    name = `sizeTypeModifiers[0].list[${itemIndex}].priceOverride[${getIndex(
                      item.context_value
                    )}].price`;
                    namePickup = `sizeTypeModifiers[0].list[${itemIndex}].priceOverride[${getIndex(
                      item.context_value
                    )}].pickupPrice`;
                  }
                }

                if (target === 'modGroupPricePopover') {
                  name = `list[${itemIndex}].priceOverride[${index}].price`;
                  namePickup = `list[${itemIndex}].priceOverride[${index}].pickupPrice`;
                }

                if (target === 'modGroupSizeTypePricePopover') {
                  name = `list[${itemIndex}].priceOverride[${providerIndex}].priceOverride[${index}].price`;
                  namePickup = `list[${itemIndex}].priceOverride[${providerIndex}].priceOverride[${index}].pickupPrice`;
                }

                return (
                  item.context_value != 'main' && (
                    <ListItem
                      key={item.id || item._id || uuidv4()}
                      sx={{
                        mx: 0,
                        px: 3,
                        py: 1.5,
                      }}
                    >
                      <Grid container maxWidth={514} spacing={2}>
                        <Grid item xs={5}>
                          <Box display="flex" alignItems="center">
                            <img width="32px" src={providers?.[item?.context_value]?.logoUrl} />
                            <Typography sx={{ color: '#3D4350', fontSize: '12px', fontWeight: 400, ml: '12px' }}>
                              {providers[item?.context_value].label}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <CustomTextInput
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            name={namePickup}
                            label={t('pickup_price')}
                            size="small"
                            inputProps={{ type: 'number' }}
                            disabled={disabledPriceField[item.context_value]}
                            onChange={onChangePrice}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <CustomTextInput
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            name={name}
                            label={t('delivery_price')}
                            size="small"
                            inputProps={{ type: 'number' }}
                            disabled={disabledPriceField[item.context_value]}
                            onChange={onChangePrice}
                          />
                        </Grid>
                        <Grid item xs={1} alignItems="center" display="flex" pl="0 !important">
                          {showLockIcon && (
                            <Box
                              sx={{ width: '20px', ml: 1, cursor: 'pointer' }}
                              onClick={() => handleEnableChangePrice(item.context_value, true, item, index)}
                            >
                              {disabledPriceField[item.context_value] ? (
                                <LockIcon sx={{ color: 'rgba(146, 147, 152, 1)' }} />
                              ) : (
                                <LockOpenIcon sx={{ color: 'rgba(146, 147, 152, 1)' }} />
                              )}
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </ListItem>
                  )
                );
              })
            ) : (
              <ListItemText
                sx={{
                  py: 1,
                  px: 3,
                }}
                primary="There is no any connected provider"
              />
            )}
            <ListItem sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}`, px: 3, pt: 1.5 }}>
              <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex' }}>
                  <Button id="priceOverrideClose" disabled={!haveConnected} onClick={handleSave} variant="contained">
                    <CheckIcon />
                  </Button>
                  <Button
                    id="priceOverrideToClean"
                    disabled={!haveConnected}
                    onClick={handleToClean}
                    sx={{ background: '#F2F2F2', color: 'black', ml: 1 }}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
              </Box>
            </ListItem>
          </List>
        </Paper>
      </Popover>
    </Box>
  );
};

export default memo(PriceOverridePopover);
