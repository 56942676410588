import styled from '@emotion/styled';
import { Typography, IconButton, Box, TextField } from '@mui/material';

export const BusinessSettingsTitle = styled(Typography)({
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '8px',
});

export const BusinessSettingsSubTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: '500',
  color: '#6E757C',
  marginBottom: '16px',
});

export const BusinessSettingsAllTitle = styled(Typography)({
  fontSize: '20px',
  fontWeight: '500',
  marginBottom: '8px',
});

export const LinkIconMain = styled(Box)({
  cursor: 'initial',
  padding: 8,
  '&:hover': {
    background: 'none',
    color: '#777779',
  },
});

export const CopyMain = styled(IconButton)({
  background: '#2D7FF9',
  borderRadius: 'inherit',
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
  color: '#fff',
  width: '56px',
  zIndex: 9,
  '&:hover': {
    background: '#1f58ae',
  },
});

export const CopyMsgContainer = styled(Box)({
  position: 'absolute',
  background: 'rgb(97 97 97 / 90%)',
  padding: '4px 8px 4px 8px',
  borderRadius: '4px',
  left: '-25px',
  top: '-35px',
});

export const CopyMsg = styled(Typography)({
  fontWeight: 500,
  fontSize: 10,
  color: '#fff',
  lineHeight: '14px',
  minWidth: '95px',
});

export const TriangleDown = styled(Box)({
  position: 'absolute',
  top: '22px',
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: 0,
  height: 0,
  borderLeft: '10px solid transparent',
  borderRight: '10px solid transparent',
  borderTop: '10px solid rgb(97 97 97 / 90%)',
});

export const TextFieldWrap = styled(TextField)({
  width: '368px',
  '.MuiOutlinedInput-root': {
    padding: 0,
    input: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'rgb(0 0 0 / 90%)',
      letterSpacing: '0.15px',
      lineHeight: '24px',
      marginRight: '10px',
    },
  },
});
