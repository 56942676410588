import instance from '../../services/api';
import { SET_EVENT_LIST, SET_EVENT_DEFAULT, SET_EVENT_LIST_LOADING, SET_EVENT_ACTIONS } from 'redux/actions/types';

export const getEventsList = () => (dispatch) => {
  dispatch({
    type: SET_EVENT_LIST_LOADING,
    payload: true,
  });
  try {
    instance
      .get('/event/list')
      .then(({ data: { data } }) => {
        return dispatch({
          type: SET_EVENT_LIST,
          payload: {
            data,
          },
        });
      })
      .finally(() => {
        dispatch({
          type: SET_EVENT_LIST_LOADING,
          payload: false,
        });
      });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getEventDefault = () => (dispatch) => {
  try {
    return instance.get('/event/default/v2').then(({ data: { data } }) => {
      // Need to dispatch real data in the feature
      dispatch(setEventActions(data[0].actions));
      dispatch({
        type: SET_EVENT_DEFAULT,
        payload: {
          data,
        },
      });
      return data;
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setEventActions = (data) => (dispatch) => {
  try {
    // return instance.get('/event/actions').then(({ data: { data } }) => {
    return dispatch({
      type: SET_EVENT_ACTIONS,
      payload: {
        data,
      },
    });
    // });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createEvent = (body) => (dispatch) => {
  const adaptedEventModel = {
    ...body,
    action: body.action._id,
  };
  try {
    return instance.post('/event', body).then(({ data: { data } }) => {
      return dispatch(getEventsList());
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateEvent = (body) => (dispatch) => {
  try {
    return instance.put(`/event/${body._id}`, body).then(({ data: { data } }) => {
      return dispatch(getEventsList(data));
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteEvent = (id) => (dispatch) => {
  try {
    return instance.delete(`/event/${id}`).then(({ data: { data } }) => {
      return dispatch(getEventsList());
    });
  } catch (err) {
    return Promise.reject(err);
  }
};
