import { Box, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  DialogActionsButtonWrap,
  DialogActionsWrap,
  DialogContentWrap, DialogTitleBlock,
  DialogTitleWrap, MainText, SecondText
} from 'components/ChangeBasePriseDialog/styles';
import InfoIcon from 'assets/images/informational.png';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';

const ConfirmPrepTimeDialog = ({ isOpen, handleClose, handleConfirm }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-content"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      sx={{
        '.MuiPaper-root': {
          maxWidth: '764px',
        },
      }}
    >
      <DialogTitleBlock>
        <Box component="img" src={InfoIcon} alt="info" />
        <DialogTitleWrap>{t('Preparation Time Update')}</DialogTitleWrap>
      </DialogTitleBlock>
      <DialogContentWrap>
         <MainText>{t(`Updating the preparation time will apply this change across all connected providers.`)}</MainText>
        <SecondText>{t(`Please ensure this update is accurate before proceeding.`)}</SecondText>
      </DialogContentWrap>
      <DialogActionsWrap>
        <DialogActionsButtonWrap id="cancel" onClick={handleClose}>
          {t('changeBasePriseDialog.cancel')}
        </DialogActionsButtonWrap>
        <DialogActionsButtonWrap
          variant="contained"
          onClick={handleConfirm}
        >
          {t('changeBasePriseDialog.confirm')}
        </DialogActionsButtonWrap>
      </DialogActionsWrap>
    </Dialog>
  );
};

export default ConfirmPrepTimeDialog;
