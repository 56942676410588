import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

function DraggableDialogWrapper(props) {
  return (
    <Draggable handle="#draggable-dialog-content" cancel={'[class*="MuiDialog-container"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default DraggableDialogWrapper;
