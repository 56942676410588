import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const TitleBufferTime = styled(Typography)({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#929398',
  maxWidth: '610px',
});
