import {
  ModalOpenStatusEnum,
  SET_MODAL_ACTIONS,
  SET_PRODUCT_EVENTS,
  SET_PRODUCT_EVENTS_NEXT_LOCATION,
} from '../constants';

const initialState = {
  eventData: [],
  modalStatus: ModalOpenStatusEnum.closed,
  nextLocation: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PRODUCT_EVENTS:
      return {
        ...state,
        eventData: payload || [],
      };
    case SET_MODAL_ACTIONS:
      return { ...state, modalStatus: payload };
    case SET_PRODUCT_EVENTS_NEXT_LOCATION:
      return { ...state, nextLocation: payload };
    default:
      return state;
  }
};
