import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { resendCode } from 'redux/actions/user';

const useTowFactorModalActions = () => {
  const dispatch = useDispatch();
  const [loadingResend, setLoadingResend] = useState(false);
  const [open, setOpen] = useState(false);
  const [towFactorKey, setTowFactorKey] = useState('');
  const [resetDuration, setResetDuration] = useState(false);
  const [timerFinished, setTimerFinished] = useState(false);
  const [email, setEmail] = useState('');

  const onTimerEnd = () => setTimerFinished(true);

  const handleClose = () => {
    setTowFactorKey('');
    setResetDuration(true);
    setOpen(false);
    setEmail('');
    setTimeout(() => {
      setTimerFinished(false);
    }, 300);
  };

  const handleResetTimer = () => {
    setLoadingResend(true);
    dispatch(resendCode({ email: email }))
      .then((response) => {
        setTowFactorKey(response?.data?.key);
        setResetDuration(!resetDuration);
        setTimeout(() => {
          setTimerFinished(false);
        }, 300);
      })
      .catch(() => setTowFactorKey(''))
      .finally(() => setLoadingResend(false));
  };

  return {
    towFactorKey,
    setTowFactorKey,
    open,
    setOpen,
    handleClose,
    timerFinished,
    resetDuration,
    onTimerEnd,
    handleResetTimer,
    setEmail,
    loadingResend,
  };
};
export default useTowFactorModalActions;
