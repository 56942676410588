import { styled, Typography, Box, Button } from '@mui/material';

export const STYLES = {
  backgroundCreated: {
    backgroundColor: '#e2150814',
  },
  backgroundHold: {
    backgroundColor: '#ff8a0014',
  },
  backgroundDisputed: {
    backgroundColor: '#ff8a0014',
  },
  backgroundDisputeApproved: {
    backgroundColor: '#00984f14',
  },
  backgroundExpired: {
    backgroundColor: '#0f121414',
  },
  backgroundRejected: {
    backgroundColor: '#e2150814',
  },
  backgroundRefunded: {
    backgroundColor: '#00984f14',
  },
  statusInfoTextCreated: {
    color: '#E21508',
    fontSize: 12,
    marginLeft: 4,
    fontWeight: 500,
  },
  statusInfoTextCreatedRow: {
    color: '#E21508',
    fontSize: 14,
    marginLeft: 1,
    fontWeight: 500,
  },
  statusInfoTextHold: {
    color: '#FF8A00',
    fontSize: 12,
    marginLeft: 4,
    fontWeight: '500',
  },
  statusInfoTextHoldRow: {
    color: '#FF8A00',
    fontSize: 14,
    marginLeft: 1,
    fontWeight: '500',
  },
  statusInfoTextDisputed: {
    color: '#FF8A00',
    fontSize: 12,
    marginLeft: 4,
    fontWeight: '500',
  },
  statusInfoTextDisputedRow: {
    color: '#FF8A00',
    fontSize: 14,
    marginLeft: 1,
    fontWeight: '500',
  },
  statusInfoTextDisputeApproved: {
    color: '#00984F',
    fontSize: 12,
    marginLeft: 4,
    fontWeight: '500',
  },
  statusInfoTextDisputeApprovedRow: {
    color: '#00984F',
    fontSize: 14,
    marginLeft: 1,
    fontWeight: '500',
  },
  statusInfoTextExpired: {
    color: '#0F1214',
    fontSize: 12,
    marginLeft: 4,
    fontWeight: '500',
  },
  statusInfoTextExpiredRow: {
    color: '#0F1214',
    fontSize: 14,
    marginLeft: 1,
    fontWeight: '500',
  },
  statusInfoTextRejected: {
    color: '#E21508',
    fontSize: 12,
    marginLeft: 4,
    fontWeight: '500',
  },
  statusInfoTextRejectedRow: {
    color: '#E21508',
    fontSize: 14,
    marginLeft: 1,
    fontWeight: '500',
  },
  statusInfoTextRefunded: {
    color: '#00984F',
    fontSize: 12,
    marginLeft: 4,
    fontWeight: '500',
  },
  statusInfoTextRefundedRow: {
    color: '#00984F',
    fontSize: 14,
    marginLeft: 1,
    fontWeight: '500',
  },
  statusIcon: {
    width: 20,
    height: 20,
  },
};

export const ChargeBackViewSubTitle = styled(Typography)({
  color: '#0F1214',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  fontWeight: 500,
});

export const ChargeBackViewMain = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between !important',
  flexDirection: 'column',
});

export const ChargeBackViewHeader = styled(Box)({
  padding: '24px 18px',
  boxShadow: '0px -1px 6px 0px #00000026',
  display: 'flex',
  justifyContent: 'start',
});

export const ChargeBackViewContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: '100%',
});

export const ChargeBackInfoSection = styled(Box)({
  paddingLeft: 16,
  paddingRight: 16,
});

export const ChargeBackDetailsSection = styled(Box)({
  padding: 16,
  borderBottom: '1px solid #0000001F',
});

export const ChargeBackPriceSection = styled(Box)({
  padding: '16px 24px',
  borderTop: '1px solid #0000001F',
});

export const ChargeBackViewFooter = styled(Box)({
  padding: '16px 18px',
  boxShadow: '0px -1px 6px 0px #00000026',
  gap: '16px',
  display: 'flex',
});

export const ChargeBackAccept = styled(Button)({
  color: '#626A7A',
  borderColor: '#626a7a45',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: '#626a7a45',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
  padding: '8px 66px',
});

export const ChargeBackViewDispute = styled(Button)({
  background: '#E21508',
  padding: '8px 66px',
  boxShadow: 'initial',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#E21508',
  },
});

export const StatusContainer = styled(Box)(({ theme }) => ({
  width: 'max-content',
  borderRadius: '4px',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: theme.spacing(0.5, 1.5, 0.5, 1),
}));

export const StatusName = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '16.41px',
}));

export const StatusContainerDescription = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: '4px',
  height: 'auto',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
  padding: theme.spacing(2),
  textAlign: 'left',
  marginTop: theme.spacing(2),
  '& > img': {
    paddingTop: '2px',
  },
}));

export const StatusMessage = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#5A5A5A',
}));

export const StatusTotal = styled('span')(() => ({
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '20px',
  color: '#0F1214',
}));
