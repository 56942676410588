import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import Modifiers from './Modifiers';
import CheckBoxDefault from './CheckBoxDefault';

const DefaultSelections = ({ productFormMethods }) => {
  const { formProduct } = useSelector((store) => store.menu);
  const preselectOptions = productFormMethods?.watch('preselectOptions');

  return (
    <Grid container px={4} flexDirection="column">
      <CheckBoxDefault />
      {formProduct?.sizeTypeModifiers?.map((modifier) => {
        return (
          <>
            <Modifiers
              type="size"
              formMethods={productFormMethods}
              preselectOptions={preselectOptions}
              modId={modifier?._id}
            />
          </>
        );
      })}
      {formProduct?.modifiers?.map((modifier) => {
        return (
          <>
            <Modifiers formMethods={productFormMethods} preselectOptions={preselectOptions} modId={modifier?._id} />
          </>
        );
      })}
    </Grid>
  );
};

export default DefaultSelections;
