import useProviderConfig from 'hooks/useProviderConfig';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

const LineChart = ({ data }) => {
  const { providers } = useProviderConfig();
  const chartData = useMemo(() => {
    const keys = Object.keys(data);
    if (providers) {
      return {
        labels: keys.length ? Object.keys(data[keys[0]]) : [],
        datasets: keys.map((key) => {
          return {
            label: providers[key]?.label,
            fill: false,
            lineTension: 0.5,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointRadius: 1,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointHitRadius: 10,

            data: Object.values(data[key]),
            ...providers[key]?.options,
          };
        }),
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  stepSize: 1,
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            position: 'bottom',
          },
        },
      };
    }
  }, [data, providers]);

  return <Line data={chartData} options={chartData.options} />;
};

// const providers = {
//   website: {
//     label: 'Website',
//     options: {
//       backgroundColor: 'rgba(221, 113, 93,0.4)',
//       borderColor: 'rgba(221, 113, 93,1)',
//       pointBorderColor: 'rgba(221, 113, 93,1)',
//       pointHoverBackgroundColor: 'rgba(221, 113, 93,1)',
//       pointHoverBorderColor: 'rgba(221, 113, 93,1)',
//     },
//   },
//   postmates: {
//     label: 'Postmates',
//     options: {
//       backgroundColor: 'rgba(61, 163, 232,0.4)',
//       borderColor: 'rgba(61, 163, 232,1)',
//       pointBorderColor: 'rgba(61, 163, 232,1)',
//       pointHoverBackgroundColor: 'rgba(61, 163, 232,1)',
//       pointHoverBorderColor: 'rgba(61, 163, 232,1)',
//     },
//   },
//   ubereats: {
//     label: 'UberEats',
//     options: {
//       backgroundColor: 'rgba(122, 184, 83,0.4)',
//       borderColor: 'rgba(122, 184, 83,1)',
//       pointBorderColor: 'rgba(122, 184, 83,1)',
//       pointHoverBackgroundColor: 'rgba(122, 184, 83,1)',
//       pointHoverBorderColor: 'rgba(122, 184, 83,1)',
//     },
//   },
//   doordash: {
//     label: 'Doordash',
//     options: {
//       backgroundColor: 'rgba(249, 54, 44,0.4)',
//       borderColor: 'rgba(249, 54, 44,1)',
//       pointBorderColor: 'rgba(249, 54, 44,1)',
//       pointHoverBackgroundColor: 'rgba(249, 54, 44,1)',
//       pointHoverBorderColor: 'rgba(249, 54, 44,1)',
//     },
//   },
//   grubhub: {
//     label: 'Grubhub',
//     options: {
//       backgroundColor: 'rgba(75,192,192,0.4)',
//       borderColor: 'rgba(75,192,192,1)',
//       pointBorderColor: 'rgba(75,192,192,1)',
//       pointHoverBackgroundColor: 'rgba(75,192,192,1)',
//       pointHoverBorderColor: 'rgba(75,192,192,1)',
//     },
//   },
//   doocado: {
//     label: 'Doocado',
//     options: {
//       backgroundColor: 'rgba(75,192,192,0.4)',
//       borderColor: 'rgba(75,192,192,1)',
//       pointBorderColor: 'rgba(75,192,192,1)',
//       pointHoverBackgroundColor: 'rgba(75,192,192,1)',
//       pointHoverBorderColor: 'rgba(75,192,192,1)',
//     },
//   },
//   deliverycom: {
//     label: 'Deliverycom',
//     options: {
//       backgroundColor: 'rgba(33,150,243, 0.8)',
//       borderColor: '#2196f3',
//       hoverBackgroundColor: '#2196f3',
//       hoverBorderColor: '#2196f3)',
//     },
//   },
//   grubhubv2: {
//     label: 'GrubHub v2',
//     options: {
//       backgroundColor: 'rgb(33,150,120,0.8)',
//       borderColor: '#219678',
//       hoverBackgroundColor: '#219678',
//       hoverBorderColor: '#219678)',
//     },
//   },
//   table_ordering: {
//     label: 'Table Ordering',
//     options: {
//       backgroundColor: 'rgba(240,142,53, 0.8)',
//       borderColor: '#F08E35',
//       hoverBackgroundColor: '#F08E35',
//       hoverBorderColor: '#F08E35)',
//     },
//   },
//   ezcater: {
//     label: 'EzCater',
//     options: {
//       backgroundColor: 'rgba(20,142,52,0.6)',
//       borderColor: '#148e34',
//       hoverBackgroundColor: '#148e34',
//       hoverBorderColor: '#148e34',
//     },
//   },
//   fetchme: {
//     label: 'FetchMe',
//     options: {
//       backgroundColor: 'rgba(153,147,70,0.9)',
//       borderColor: '#999346',
//       hoverBackgroundColor: '#999346',
//       hoverBorderColor: '#999346',
//     },
//   },
//   food2: {
//     label: 'WebsiteV2',
//     options: {
//       backgroundColor: 'rgba(153,147,70,0.9)',
//       borderColor: '#999346',
//       hoverBackgroundColor: '#999346',
//       hoverBorderColor: '#999346',
//     },
//   },
//   grubhub_cartwheel: {
//     label: 'Grubhub',
//     options: {
//       backgroundColor: 'rgba(75,192,192, 0.8)',
//       borderColor: 'rgba(75,192,192,1)',
//       hoverBackgroundColor: 'rgba(75,192,192, 1)',
//       hoverBorderColor: 'rgba(75,192,192,1)',
//     },
//   },
//   doordash_cartwheel: {
//     label: 'Doordash',
//     options: {
//       backgroundColor: 'rgba(249, 54, 44, 0.8)',
//       borderColor: 'rgba(249, 54, 44,1)',
//       hoverBackgroundColor: 'rgba(249, 54, 44, 1)',
//       hoverBorderColor: 'rgba(249, 54, 44,1)',
//     },
//   },
// };
export default LineChart;
