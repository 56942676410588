import instance from 'services/api';

import {
  SET_CATEGORY_PRODUCTS_V2,
  SET_MENU_CURRENT_MODIFIER,
  SET_MENU_MODIFIERS_ALL,
  SET_MENU_MODIFIERS_LOADING,
} from '../types';

export const getModifiersAll = (isOnePrice, name) => (dispatch) => {
  dispatch({ type: SET_MENU_MODIFIERS_LOADING, payload: true });
  return instance
    .get(`/modifier?isOnePrice=${!!isOnePrice}&skip=0&limit=10`)
    .then(({ data: { data } }) => {
      const initialData = ['title', 'min', 'max', 'free'];
      data.forEach((item) => {
        initialData.forEach((name) => {
          if (!item[name]) {
            item[name] = '';
          }
        });
      });
      dispatch({ type: SET_MENU_MODIFIERS_ALL, payload: data });
      return data;
    })
    .finally(() => {
      dispatch({ type: SET_MENU_MODIFIERS_LOADING, payload: false });
    });
};

export const getModifierDataById = (modifierId) => (dispatch) => {
  return instance
    .get(`/modifier/${modifierId}`)
    .then(({ data: { data } }) => {
      // dispatch({ type: SET_MENU_MODIFIERS_ALL, payload: data });
      dispatch({ type: SET_MENU_CURRENT_MODIFIER, payload: data });
      return data;
    })
    .catch((err) => {
      // toast.error('Fail to get modifier!');
      return Promise.reject(err);
    });
};

export const addModifier = (body) => () => {
  return instance
    .post('/modifier', body)
    .then(({ data: { data } }) => data)
    .catch((err) => {
      // toast.error('Fail to add modifier!');
      return Promise.reject(err);
    });
};

export const groupModifier = (data, productId) => {
  return instance.post('/modifier/group', {
    modifierId: data,
    productId,
  });
};

export const cloneModifier = (data, productId) => (dispatch) => {
  return instance
    .post('/modifier/group', {
      data: data,
      productId,
    })
    .then(({ data: { data } }) => {
      return data;
    })
    .catch((err) => {
      // toast.error('Fail to clone modifier!');
      return Promise.reject(err);
    });
};

export const changeModifiers = (productId, modifiersData) => (dispatch) => {
  return instance
    .post('/modifier/group', {
      modifierId: modifiersData.map((i) => i?._id),
      productId,
    })
    .then(({ data }) => {
      const copyOfModifiersData = [...modifiersData];
      const sizeTypeModifier = copyOfModifiersData.find((modItem) => modItem?.tags?.includes('size'));
      copyOfModifiersData.forEach((modifierDataItem) => {
        if (modifierDataItem?.isOnePrice === false && sizeTypeModifier) {
          sizeTypeModifier.list.forEach((sizeModItem) => {
            modifierDataItem.list.forEach((modifierListItem) => {
              const dependentFromSizeTypeItem = modifierListItem.priceOverride.find(
                (priceOverrideItem) => priceOverrideItem.context_value === sizeModItem._id
              );
              if (!dependentFromSizeTypeItem) {
                modifierListItem.priceOverride.push({
                  context_type: 'MOD_ITEM',
                  context_value: sizeModItem._id,
                  name: sizeModItem.name,
                  price: modifierListItem.price,
                });
              }
            });
          });
          dispatch(editModifierV2(modifierDataItem));
          // editModifier => editModifierV2
        }
      });
      return data;
    })
    .catch((err) => {
      // toast.error('Fail to insert modifier!');
      return Promise.reject(err);
    });
};

export const editModifierV2 = (body, productModifiers) => (dispatch) => {
  return instance
    .put(`/modifier/v2/${body._id}`, body)
    .then(({ data: { data } }) => data)
    .catch((err) => {
      // toast.error('Fail to update modifier!');
      return Promise.reject(err);
    });
};

export const editModifier = (body) => (dispatch) => {
  return instance
    .put(`/modifier/${body._id}`, body)
    .then(({ data: { data } }) => data)
    .catch((err) => {
      // toast.error('Fail to update modifier!');
      return Promise.reject(err);
    });
};

export const deleteModifier = (id) => (dispatch) => {
  return instance
    .delete(`/modifier/${id}`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      // toast.error('Fail to delete modifier!');

      return Promise.reject(err);
    });
};

export const reorderModifiers = (data, categoryId, productId) => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();
  const { categories } = menu;
  let targetCategoryIndex = categories.findIndex((item) => item._id === categoryId);
  if (targetCategoryIndex !== -1) {
    let targetProductIndex = categories[targetCategoryIndex]?.products?.findIndex((item) => item._id === productId);
    if (targetProductIndex !== -1) {
      let mySortedArray = data.map((x) =>
        categories[targetCategoryIndex].products[targetProductIndex].modifiers.find((y) => y._id === x._id)
      );
      categories[targetCategoryIndex].products[targetProductIndex].modifiers = mySortedArray;
      dispatch({
        type: SET_CATEGORY_PRODUCTS_V2,
        payload: categories,
      });
      return instance.put(`/modifier/sort`, {
        productId,
        sort: data.map((item, sortIndex) => ({ _id: item._id, sortIndex })),
      });
    }
  }
};

export const createModifier = (data) => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();
  const copyModifier = [...menu.modifiersAll];
  const found = copyModifier.find((item) => {
    return item._id === data._id;
  });

  if (!found) {
    dispatch({ type: SET_MENU_MODIFIERS_ALL, payload: [...copyModifier, data] });
  }
};

export const updateModifierById = (data) => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();
  const { modifiersAll } = menu;
  const copyModifiers = [...modifiersAll];
  const findIndex = copyModifiers.findIndex((item) => item._id === data._id);
  if (findIndex != -1) {
    modifiersAll[findIndex] = data;
  }
};

export const addInModifiersAll = (data) => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();
  const { modifiersAll } = menu;
  const copyModifiers = [...modifiersAll];
  const findIndex = copyModifiers.findIndex((item) => item._id === data._id);
  if (findIndex == -1) {
    modifiersAll.push(data);
  }
};
