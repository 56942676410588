import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useCouponsData } from 'hooks/useCoupons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CheckActive from 'assets/icons/checkbox_active.svg';
import CheckInactive from 'assets/icons/checkbox_inactive.svg';
import { MarketingTypes } from 'components/Coupons/types';

const PopularItemCoupon = () => {
  const { t } = useTranslation();
  const { activeCoupons, expiredCoupons, isLoading } = useCouponsData(true);
  const [activeForMarketingCouponList, setActiveForMarketingCouponList] = useState([]);

  const genericColumns = [
    {
      field: 'couponName',
      headerName: t('website_settings.coupone_name'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'couponTitle',
      headerName: t('website_settings.coupone_title'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'couponCode',
      headerName: t('website_settings.coupon_code'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'amount',
      headerName: t('website_settings.amount_percent'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: ({ row, value }) => {
        return row.percent ? (
          <Typography sx={{ textAlign: 'right', width: '100%' }}>{`${row.percent}%`}</Typography>
        ) : (
          <Typography sx={{ textAlign: 'right', width: '100%' }}>{`$${row.amount}`}</Typography>
        );
      },
    },
    {
      field: 'startDate',
      headerName: t('website_settings.start_date_'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return moment(value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'endDate',
      headerName: t('website_settings.end_date_'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return moment(value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'freeDelivery',
      headerName: t('website_settings.free_delivery'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        if (row?.type && row?.type === 'system') {
          return <img src={CheckInactive} alt="ONLINE_ICON" />;
        } else if (row?.type && row?.type === 'marketing') {
          return <img src={CheckActive} alt="ONLINE_ICON" />;
        }
      },
    },
  ];

  useEffect(() => {
    if (activeCoupons?.length || expiredCoupons?.length) {
      let filteredActiveCoupon = activeCoupons.filter((item) => item?.type === MarketingTypes.MARKETING);
      setActiveForMarketingCouponList(filteredActiveCoupon);
    }
  }, [activeCoupons, expiredCoupons]);
  return (
    <Grid container spacing={2} sx={{ mt: 4 }}>
      <Grid item xs={12}>
        <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography sx={{ mt: 2 }} variant="h6">
            {t('website_settings.coupons_or_marketing')}
          </Typography>
        </Stack>
        <Box
          sx={{
            my: 2,
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
            },
            '& .MuiDataGrid-row	': {
              '&:nth-of-type(odd)': {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            },
            '& .MuiDataGrid-sortIcon': {
              color: 'white',
            },
            '& .MuiDataGrid-filterIcon': {
              color: 'white',
            },
            '& .MuiDataGrid-menuIcon	': {
              '& .MuiButtonBase-root': { color: 'white' },
            },
          }}
        >
          <DataGrid
            autoHeight={true}
            hideFooterPagination={true}
            hideFooterSelectedRowCount={true}
            isEditable={false}
            rows={activeForMarketingCouponList}
            columns={genericColumns}
            loading={isLoading}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
export default PopularItemCoupon;
