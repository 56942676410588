import { set } from 'lodash';
import { useEffect } from 'react';
import { LESS_MSG, NAME_ENUMS } from 'constants/products';

const useMinAndMaxOptions = (
  setIsDirty,
  setIsChanged,
  formState,
  updateFormState,
  formErrors,
  updateFormErrors,
  list,
  max
) => {
  const handleChangeMultipleOptions = (e) => {
    const checked = e.target.checked;
    const newData = set(formState, 'canSelectMultipleOptions', checked);
    if (setIsDirty) {
      setIsDirty(true);
    }
    if (setIsChanged) {
      setIsChanged(true);
    }
    if (checked) {
      const calculateMax =
        !formState.hasOwnProperty('max') || formState?.max === '' || formState?.max === null
          ? Number(formState?.list?.length) * (formState?.maxAnySingleModifierItems || 2)
          : Number(formState?.max) * Number(formState?.maxAnySingleModifierItems || 2);
      const calculateMin =
        !formState.hasOwnProperty('min') || formState?.min === '' || formState?.min === null
          ? 0
          : Number(formState?.min) * Number(formState?.minAnySingleModifierItems || 1);
      const data = {
        ...newData,
        minAnySingleModifierItems: formState?.minAnySingleModifierItems ? formState?.minAnySingleModifierItems : 1,
        maxAnySingleModifierItems: formState?.maxAnySingleModifierItems ? formState?.maxAnySingleModifierItems : 2,
        maxTotalNumberOfModifierItems: formState?.max === 0 ? 20 : calculateMax,
        minTotalNumberOfModifierItems: calculateMin,
      };
      if (data?.minTotalNumberOfModifierItems > data?.maxTotalNumberOfModifierItems) {
        let copyFormErrors = { ...formErrors };
        copyFormErrors = set(formErrors, 'maxTotalNumberOfModifierItems', {
          error: true,
          message: `${LESS_MSG} ${data?.minTotalNumberOfModifierItems}`,
        });
        updateFormErrors({ ...copyFormErrors });
      }
      set(formState, 'minAnySingleModifierItems', data?.minAnySingleModifierItems);
      set(formState, 'maxAnySingleModifierItems', data?.maxAnySingleModifierItems);
      set(formState, 'maxTotalNumberOfModifierItems', data?.maxTotalNumberOfModifierItems);
      set(formState, 'minTotalNumberOfModifierItems', data?.minTotalNumberOfModifierItems);
      updateFormState({
        ...data,
      });
    } else {
      const data = {
        ...newData,
        minAnySingleModifierItems: null,
        maxAnySingleModifierItems: null,
        minTotalNumberOfModifierItems: null,
        maxTotalNumberOfModifierItems: null,
      };
      set(formState, 'minAnySingleModifierItems', data?.minAnySingleModifierItems);
      set(formState, 'maxAnySingleModifierItems', data?.maxAnySingleModifierItems);
      set(formState, 'minTotalNumberOfModifierItems', data?.minTotalNumberOfModifierItems);
      set(formState, 'maxTotalNumberOfModifierItems', data?.maxTotalNumberOfModifierItems);
      updateFormState({ ...newData });
      let copyFormErrors = { ...formErrors };
      copyFormErrors = set(formErrors, 'minAnySingleModifierItems', { error: false, message: '' });
      copyFormErrors = set(formErrors, 'maxAnySingleModifierItems', { error: false, message: '' });
      copyFormErrors = set(formErrors, 'minTotalNumberOfModifierItems', { error: false, message: '' });
      copyFormErrors = set(formErrors, 'maxTotalNumberOfModifierItems', { error: false, message: '' });
      updateFormErrors({ ...copyFormErrors });
    }
  };

  function resolveErrors(obj) {
    if (obj) {
      Object.keys(obj)?.forEach((key) => {
        const value = obj[key];
        // Check if value is an object and has the 'error' key
        if (value && typeof value === 'object' && 'error' in value) {
          if (value?.error === true) {
            // Check if error is true and change it to false
            value.error = false;
            value.message = '';
          }
        }
        // If the value is an array or an object, recursively resolve errors
        if ((typeof value === 'object' && !Array.isArray(value)) || Array.isArray(value)) {
          resolveErrors(value);
        }
      });
      return obj;
    }
  }

  useEffect(() => {
    let copyFormErrors = { ...formErrors };
    const clearErrors = resolveErrors(copyFormErrors);
    if (clearErrors) {
      updateFormErrors({ ...clearErrors });
    }
  }, []);

  const handleChangeFieldMaxTotalNumberOfModifierItems = (event) => {
    // Update the state for maxTotalNumberOfModifierItems
    const newData = set(formState, 'maxTotalNumberOfModifierItems', event.target.value);
    updateFormState({ ...newData });
    setIsDirty(true);
    // Initialize copyFormErrors
    let copyFormErrors = { ...formErrors };

    if (!event.target.value || Number(event.target.value) === 0) {
      copyFormErrors = set(copyFormErrors, NAME_ENUMS.maxT, {
        error: true,
        message: 'menu.less_msg ',
      });
      updateFormErrors({ ...copyFormErrors });
      return;
    }

    // Check if max is at least the min value
    if (
      Number(newData?.minTotalNumberOfModifierItems) <= Number(event.target.value) &&
      (newData?.minTotalNumberOfModifierItems === '' || newData?.minTotalNumberOfModifierItems == null)
    ) {
      copyFormErrors = set(copyFormErrors, NAME_ENUMS.maxT, {
        error: false,
        message: '',
      });
    } else if (
      Number(newData?.minTotalNumberOfModifierItems) <= Number(event.target.value) &&
      newData?.minTotalNumberOfModifierItems >= 0
    ) {
      copyFormErrors = set(copyFormErrors, NAME_ENUMS.maxT, {
        error: false,
        message: '',
      });
      copyFormErrors = set(copyFormErrors, NAME_ENUMS.minT, {
        error: false,
        message: '',
      });
    } else {
      const minT = Number(newData?.minTotalNumberOfModifierItems);
      copyFormErrors = set(copyFormErrors, NAME_ENUMS.maxT, {
        error: true,
        message: `menu.less_msg ${minT}`,
      });
    }
    // Update the error state in a single place
    updateFormErrors({ ...copyFormErrors });
  };

  const handleChangeFieldMinTotalNumberOfModifierItems = (event) => {
    // Update the state for minTotalNumberOfModifierItems
    const newData = set(formState, 'minTotalNumberOfModifierItems', event.target.value);
    updateFormState({ ...newData });
    setIsDirty(true);
    // Initialize copyFormErrors
    let copyFormErrors = { ...formErrors };

    if (event.target.value === '') {
      copyFormErrors = set(copyFormErrors, NAME_ENUMS.minT, {
        error: true,
        message: 'menu.less_msg',
      });
      updateFormErrors({ ...copyFormErrors });
      return;
    }

    // Validate the relation between min and max total number of modifier items
    if (Number(newData?.minTotalNumberOfModifierItems) <= Number(newData?.maxTotalNumberOfModifierItems)) {
      copyFormErrors = set(copyFormErrors, NAME_ENUMS.minT, {
        error: false,
        message: '',
      });
      copyFormErrors = set(copyFormErrors, NAME_ENUMS.maxT, {
        error: false,
        message: '',
      });
    } else {
      const maxT = Number(formState.maxTotalNumberOfModifierItems);
      copyFormErrors = set(copyFormErrors, NAME_ENUMS.minT, {
        error: true,
        message: `menu.greater_msg ${maxT}`,
      });
    }

    // Update the error state in a single place
    updateFormErrors({ ...copyFormErrors });
  };

  return {
    handleChangeMultipleOptions,
    handleChangeFieldMaxTotalNumberOfModifierItems,
    handleChangeFieldMinTotalNumberOfModifierItems,
  };
};
export default useMinAndMaxOptions;
