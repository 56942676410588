import {
  AppPay,
  GooglePayBox,
  GooglePayLink,
  GooglePayLinkLargeText,
  GooglePayLinkSmallTextApp,
  GooglePayLinkWrapper,
} from './styles';
import ApplePlayIcon from 'assets/images/Apple.png';
import { useTranslation } from 'react-i18next';

const AppStoreButton = () => {
  const { t } = useTranslation();
  const handleClick = (url) => window.open(url, '_blank');
  return (
    <AppPay
      startIcon={<img src={ApplePlayIcon} alt={'ApplePlay'} />}
      onClick={() => handleClick('https://apps.apple.com/am/app/orders-co-manager/id6471839111')}
    >
      <GooglePayLink
        href="https://apps.apple.com/am/app/orders-co-manager/id6471839111"
        color="inherit"
        target={'_blank'}
      >
        <GooglePayLinkWrapper>
          <GooglePayLinkSmallTextApp>{t('sign_in.appStoreSmallText')}</GooglePayLinkSmallTextApp>
          <GooglePayBox>
            <GooglePayLinkLargeText>{t('sign_in.appStoreText')}</GooglePayLinkLargeText>
            <GooglePayLinkLargeText>{t('sign_in.appStoreTextSecond')}</GooglePayLinkLargeText>
          </GooglePayBox>
        </GooglePayLinkWrapper>
      </GooglePayLink>
    </AppPay>
  );
};

export default AppStoreButton;
