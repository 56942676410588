import { v4 as uuidv4 } from 'uuid';

export const isAsapSwitcherTurnOff = [
  { key: 1, name: '5 minutes', value: 5 },
  { key: 2, name: '10 minutes', value: 10 },
  { key: 3, name: '15 minutes', value: 15 },
  { key: 4, name: '20 minutes', value: 20 },
  { key: 5, name: '25 minutes', value: 25 },
  { key: 6, name: '30 minutes', value: 30 },
  { key: 7, name: '35 minutes', value: 35 },
  { key: 8, name: '40 minutes', value: 40 },
  { key: 9, name: '45 minutes', value: 45 },
  { key: 10, name: '50 minutes', value: 50 },
  { key: 11, name: '55 minutes', value: 55 },
];

export const isAsapSwitcherTurnOn = [
  { key: 1, name: '1 hour', value: 60 },
  { key: 2, name: '2 hours', value: 120 },
  { key: 3, name: '4 hours', value: 240 },
  { key: 4, name: '6 hours', value: 360 },
  { key: 5, name: '8 hours', value: 480 },
  { key: 6, name: '10 hours', value: 600 },
  { key: 7, name: '12 hours', value: 720 },
  { key: 8, name: '14 hours', value: 840 },
  { key: 9, name: '16 hours', value: 960 },
  { key: 10, name: '18 hours', value: 1080 },
  { key: 11, name: '20 hours', value: 1200 },
  { key: 12, name: '22 hours', value: 1320 },
  { key: 14, name: '1 day', value: 1440 },
  { key: 15, name: '2 days', value: 2880 },
  { key: 16, name: '3 days', value: 4320 },
  { key: 17, name: '4 days', value: 5760 },
  { key: 18, name: '5 days', value: 7200 },
  { key: 19, name: '6 days', value: 8640 },
  { key: 120, name: '7 days', value: 10080 },
];

export const deliveryBufferText = `
Set the specific times when your restaurant accepts delivery orders. This helps streamline delivery operations and ensures customers know when they can expect their orders to be accepted and delivered.`;

export const pickUpBufferText = `Define the windows during which pick-up orders can be placed. This ensures that customers can schedule pickups at convenient times and helps your staff manage order flow efficiently.`;

export const ORDER_BUFFER_TIME = [
  {
    id: uuidv4(),
    name: 'No buffer',
    value: 0,
  },
  {
    id: uuidv4(),
    name: '10 minutes',
    value: 10,
  },
  {
    id: uuidv4(),
    name: '15 minutes',
    value: 15,
  },
  {
    id: uuidv4(),
    name: '20 minutes',
    value: 20,
  },
  {
    id: uuidv4(),
    name: '30 minutes',
    value: 30,
  },
  {
    id: uuidv4(),
    name: '45 minutes',
    value: 45,
  },
];
