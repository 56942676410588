import { NavLink, useHistory } from 'react-router-dom';
import SuccessIng from 'assets/images/success.png';
import { useTranslation } from 'react-i18next';

const AddLocationSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className="wizard-finish-brand">
      <div className="checkImg">
        <img src={SuccessIng} alt="Congratulations" style={{ width: 180, height: 140 }} />
      </div>
      <div className="titleCongrats">
        <h2 className="my-2">{t('add_location.Congratulations_first_step_to')}</h2>
        <h3>{t('add_location.order_mastery')} !</h3>
      </div>
      <p className="text-muted">
        {t('add_location.expect_next_24_48_hours')} <br />
        {t('add_location.if_you_contact_us_at')}&nbsp;
        <a href="tel:+18555001070">855-500-1070</a>
      </p>
      {!history.location.pathname.includes('/location/invitation') && (
        <NavLink to="/settings/brands">{t('add_location.back_brands_locations_list')}</NavLink>
      )}
    </div>
  );
};

export default AddLocationSuccess;
