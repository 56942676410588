import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuList, MenuItem, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Preloader from 'components/Preloader';

const ShowModifierOptions = ({ loading, currentModifier, currentProduct }) => {
  const currentProductSizeTypeModifiersLists = currentProduct?.sizeTypeModifiers[0]?.list || [];
  const { t } = useTranslation();
  const filteredOptionsListForCurrentProduct = currentModifier.list;
  const defaultCount = 4;
  const [showCount, setShowCount] = useState(defaultCount);
  const [showBtnTxt, setShowBtnTxt] = useState(t('menu.show_more'));

  const handleShowMore = useCallback(() => {
    if (showBtnTxt.includes('more')) {
      setShowCount(currentModifier?.list?.length);
      setShowBtnTxt(t('menu.show_less'));
    } else {
      setShowCount(4);
      setShowBtnTxt(t('menu.show_more'));
    }
  }, [currentModifier?.list?.length, showBtnTxt, t]);

  const renderMenuItem = (item) => {
    const foundPriceOverrideName = item.priceOverride?.find((priceOverride) => priceOverride?.name);
    const priceOverrideList = item.priceOverride.filter((priceOverride) => {
      const found = currentProductSizeTypeModifiersLists?.find(
        (listItem) => listItem._id === priceOverride.context_value
      );
      return priceOverride?.name && found;
    });

    return (
      <MenuItem
        sx={{
          color: '#0F1214',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          padding: '8px 16px 8px 45px',
          cursor: 'default',
          maxWidth: '800px',
        }}
        key={item?._id}
      >
        {item.name}{' '}
        <Box display="flex" ml={1.5}>
          {currentModifier.isOnePrice ? (
            <Typography
              sx={{
                color: '#929398',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                mr: 0.5,
              }}
            >
              <Box component="span" mr={0.5}>
                <FiberManualRecordIcon sx={{ fontSize: 5 }} />
              </Box>
              {`${Number(item.price).toFixed(2)} ${foundPriceOverrideName?.name ? ',' : ''}`}
            </Typography>
          ) : (
            priceOverrideList?.map((it, index) => {
              return (
                <Typography
                  sx={{
                    color: '#929398',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    mr: 0.5,
                  }}
                >
                  {`${it.name} : ${Number(it.price).toFixed(2)} ${index === priceOverrideList?.length - 1 ? '' : ','}`}
                </Typography>
              );
            })
          )}
        </Box>
      </MenuItem>
    );
  };

  return (
    <Box>
      {loading && <Preloader overlay={true} />}
      <MenuList autoFocusItem sx={{ p: 0, height: '100%', overflowY: 'auto' }}>
        {currentModifier?.list?.map((item, index) => {
          if (index < showCount && showBtnTxt.includes('more')) {
            return renderMenuItem(item);
          } else if (!showBtnTxt.includes('more')) {
            return renderMenuItem(item);
          }
        })}
        {currentModifier?.list?.length > defaultCount ? (
          <MenuItem
            onClick={handleShowMore}
            sx={{ textAlign: 'center', paddingTop: '11px', paddingBottom: '11px', justifyContent: 'center' }}
          >
            <Box
              component="button"
              sx={{
                background: 'none',
                border: 'none',
              }}
              type="button"
            >
              <Typography fontSize={14} fontStyle="normal" fontWeight={500} color="#0F1214">
                {showBtnTxt}
              </Typography>
            </Box>
          </MenuItem>
        ) : null}
      </MenuList>
    </Box>
  );
};

export default ShowModifierOptions;
