import * as yup from 'yup';

export const businessSettingsSchema = yup.object().shape({
  googleFeedEnabled: yup.boolean(),
  googleFeedCategory: yup.string(),
  googleFeedDeliveryFee: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(),
  googleFeedDeliveryFeeRangeStart: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(),
  googleFeedDeliveryFeeRangeEnd: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(),
  googleFeedServiceFee: yup
    .number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(),
});
